import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MusicNoteIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7 11H6V4.1a.848.848 0 0 1 .681-.83l5.31-1.17A.837.837 0 0 1 13 2.921V9h-1V3.124l-5 1.1zm-.11-6.755z"
          fill="currentColor"
        />
        <path
          d="M4.5 14a2.5 2.5 0 0 1 0-5h2a.5.5 0 0 1 .5.5v2A2.5 2.5 0 0 1 4.5 14zm0-4A1.5 1.5 0 1 0 6 11.5V10zM10.5 13a2.5 2.5 0 0 1 0-5h2a.5.5 0 0 1 .5.5v2a2.5 2.5 0 0 1-2.5 2.5zm0-4a1.5 1.5 0 1 0 1.5 1.5V9z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M19 32h-2V10.575a2.156 2.156 0 0 1 1.735-2.111l17.713-3.886A2.12 2.12 0 0 1 39 6.654V28h-2V6.654a.115.115 0 0 0-.044-.093.116.116 0 0 0-.1-.025l-17.713 3.886a.16.16 0 0 0-.143.153z"
          fill="currentColor"
        />
        <path
          d="M12 44a7 7 0 0 1 0-14h6a1 1 0 0 1 1 1v6a7.008 7.008 0 0 1-7 7zm0-12a5 5 0 1 0 5 5v-5zM32 40a7 7 0 0 1 0-14h6a1 1 0 0 1 1 1v6a7.008 7.008 0 0 1-7 7zm0-12a5 5 0 1 0 5 5v-5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11 17H9V5.876a1.579 1.579 0 0 1 1.271-1.546l9.773-2.922a1.589 1.589 0 0 1 1.386.3A1.557 1.557 0 0 1 22 2.916V14h-2V3.5l-9 2.7zm-.214-10.741l-.035.01a.14.14 0 0 0 .035-.01z"
          fill="currentColor"
        />
        <path
          d="M7 23a4 4 0 0 1 0-8h3a1 1 0 0 1 1 1v3a4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2v-2zM18 20a4 4 0 0 1 0-8h3a1 1 0 0 1 1 1v3a4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2v-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MusicNoteIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MusicNoteIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MusicNoteIcon;
