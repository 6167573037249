import * as React from 'react';
import { TypeaheadProps, Typeahead } from 'react-unity';
import { Field, FieldProps } from './Field';
import GroupService from '../../../services/GroupsService';
import Group from '../../../models/Group';
import ReadonlyField from './ReadonlyField';

interface GroupLookupProps extends FieldProps { 
	label: string;
	placeholder?: string;
	note?: string;
	readonly?: boolean;
}

interface GroupLookupState {
	results: Group[];
	groupText: string;
	loading: boolean;
}

export default class GroupLookup extends Field<TypeaheadProps, GroupLookupProps, GroupLookupState> {
	groupService: GroupService;

	constructor(props) {
		super(props);
		this.state = {
			results: [],
			groupText: '',
			loading: false
		};

		this.groupService = new GroupService();
	}

	componentDidMount() {
		this.resetInputText(this.props.value);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.resetInputText(this.props.value);
		}
	}

	resetInputText = (newValue) => {
		this.setState({
			groupText: newValue ?? '',
			results: []
		});
	};

	handleTextChange = async (e) => {
		const inputText = e.target.value;
		this.resetInputText(inputText);
		this.props.onChange(null);
		const searchText = inputText.trim();
		if (searchText.length >= 3) {
			this.setState({ loading: true });
			const response = await this.groupService.groupLookup(searchText);
			this.setState({ 
				results: response,
				loading: false
			});
		}
	};

	render() {
		const renderedResults =
			(this.state.results.length !== 0 || this.state.groupText?.length < 3 || this.state.loading
				?
				this.state.results.map((group) => (
					<Typeahead.Item key={group.id} value={group.id}>
						<Typeahead.Item.Suggestion>
							<strong>{group.displayName}</strong> ({group.id})
						</Typeahead.Item.Suggestion>
					</Typeahead.Item>
				)) :
				<Typeahead.Item value={0}>
					<Typeahead.Item.Suggestion aria-disabled>
						<i>No results found.</i>
					</Typeahead.Item.Suggestion>
				</Typeahead.Item>);
		
		return (
			!this.props.disabled ?
				<Typeahead
					fieldProps={
						{
							label: this.props.label,
							note: this.nonValidMessage() || this.props.note,
							error: !this.isValid(),
							disabled: this.props.disabled,
						}
					}
					inputProps={
						{
							onChange: this.handleTextChange,
							value: this.state.groupText,
							placeholder: this.props.placeholder,
							loading: this.state.loading,
						}
					}
					onChange={(event, value) => {
						const obj = this.state.results.find(o => o.id === value);
						this.props.onChange(obj);
						this.setState({ groupText: obj?.displayName });
					}}
				>
					{renderedResults}
				</Typeahead>
				:
				<ReadonlyField
					label={this.props.label}
					text={this.state.groupText}
				/>
		);
	}
}