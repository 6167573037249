import AWSPortOpeningRule from '../../entities/AWSPortOpeningRule';

export default class SubmitAWSPortOpeningRequestRule {

	action!: string;

	operation!: string;

	sourceIPs!: string;

	destinationIPs!: string;

	destinationURLs?: string;

	destinationPorts!: string;

	description!: string;

	protocol!: string;

	service!: string;

	region!: string;

	ruleName: string;

	constructor(rule: AWSPortOpeningRule) {
		Object.assign(this, rule);
		this.action = rule.action?.name;
		this.operation = rule.operation?.name;
		this.region = rule.region?.name;
		this.ruleName = rule.name;
	}
}