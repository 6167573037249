import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import VerticalHeaderBodyBand from "./VerticalHeaderBodyBand";
import VerticalHeaderBodySearch from "./VerticalHeaderBodySearch";
import VerticalHeaderBodyNavigation from "./VerticalHeaderBodyNavigation";

function VerticalHeaderBody(props) {
  const { children, className, isMobileMenuOpen, onMobileMenuClick } = props;

  return (
    <div
      className={clsx(
        "em-c-header__nav-container",
        "em-js-nav-panel",
        { "em-is-active": isMobileMenuOpen },
        className
      )}
    >
      {React.Children.toArray(children).map(child =>
        React.cloneElement(child, {
          isMobileMenuOpen
        })
      )}
    </div>
  );
}

VerticalHeaderBody.Search = VerticalHeaderBodySearch;
VerticalHeaderBody.Band = VerticalHeaderBodyBand;
VerticalHeaderBody.Navigation = VerticalHeaderBodyNavigation;

VerticalHeaderBody.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node,
  /** Automatically passed by the VerticalHeader to indicate if the mobile menu is open */
  isMobileMenuOpen: PropTypes.bool,
  /** Automatically passed by the VerticalHeader to change if the mobile menu is open */
  onMobileMenuClick: PropTypes.func
};

VerticalHeaderBody.defaultProps = {
  className: "",
  children: null
};

export default VerticalHeaderBody;
