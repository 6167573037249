import { AlertVariant } from 'react-unity';
import FormModel from '../../utils/FormModel';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import DoNotDecryptRequestService from '../../services/DoNotDecryptRequestService';
import { IStateForm, AbstractFormState, AbstractForm } from '../common/AbstractForm';
import { TenantLabel } from '../../models/enums/TenantLabel';
import UserLookupObject from '../../models/UserLookupObject';
import DoNotDecryptRule from '../../models/entities/DoNotDecryptRule';
import { LinkTreeItem } from '../common/LinkTree';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import { Environment } from '../../models/enums/Environment';
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest';
import { Location, Params } from 'react-router-dom';

interface EndModal extends AlertBanner {
	redirectionLink?: string;
}

export interface AbstractDNDRFormProps {
	params?: Params<string>;
	navigate?: any;
	location?: Location;
 }

export interface IDNDRStateForm extends IStateForm {
	environment?: Environment;
	contact?: UserLookupObject | null;
	isEmergency: boolean;
	incidentNumber?: string;
	customerCoordination?: boolean;
	businessJustification: string;
	comments?: string;
	ruleList: DoNotDecryptRule[];
	// custodianApproval: boolean;
	//sevenDayExemption: boolean;
	
}


export interface AbstractDNDRFormState<T> extends AbstractFormState<T> {
	subscription?: SubscriptionRequest;
	operationInProgress: boolean;
	endModal: EndModal;
	submissionWarned: boolean;
	submissionAlert: AlertBanner;
}

export abstract class AbstractDNDRForm<
	Props extends AbstractDNDRFormProps,
	State extends AbstractDNDRFormState<IDNDRStateForm>
	> extends AbstractForm<Props, State> {

	subscriptionRequestService: SubscriptionRequestsService;

	doNotDecryptRequestService: DoNotDecryptRequestService;
	constructor(props) {
		super(props);
		this.subscriptionRequestService = new SubscriptionRequestsService();
		this.doNotDecryptRequestService = new DoNotDecryptRequestService(); 
	}

	async getSubscription(id) {
		if (!id) return null;
		const subscription = new SubscriptionRequest(await this.subscriptionRequestService.getById(id));
		return subscription;
	}

	getLinkTree = (): LinkTreeItem[] => {
		return [
			{ text: 'Subscriptions', to: '/subscriptions' },
			{ text: this.state.subscription?.createdSubscriptionName, to: `/subscriptions/${this.state.subscription?.id}` },
			{ text: 'DPI (Deep Packet Inspection) Exception Requests', to: `/subscriptions/${this.state.subscription?.id}/doNotDecryptRequestsHistory` }
		];
	};

	initFormModel() {
		this.formModel = new FormModel({
			environment: {
				getValue: () => this.state.stateForm.environment?.value,
				validation: {
					required: () => this.state.subscription?.tenant.id === TenantLabel.ExxonMobilTest
				}
			},
			contactId: {
				getValue: () => this.state.stateForm.contact?.id,
			},
			emergency: {
				getValue: () => this.state.stateForm.isEmergency,
				validation: {
					required: true,
				},
			},
			incidentNumber: {
				getValue: () => this.state.stateForm.incidentNumber,
				validation: {
					required: () => this.state.stateForm.isEmergency,
					rules: [
						{
							assert: () => this.state.stateForm.incidentNumber?.trim().length > 0,
							message: 'The incident number is not valid.',
						},
					],
				},
			},
			businessJustification: {
				getValue: () => this.state.stateForm.businessJustification,
				validation: {
					required: true,
					rules: [
						{
							assert: () =>
								this.state.stateForm.businessJustification?.trim().length > 4,
							message: 'Justification must contain at least 5 characters.',
						},
					],
				},
			},
			customerCoordination: {
				getValue: () => this.state.stateForm.customerCoordination
			},
			// custodianApproval: {
			// 	getValue: () => this.state.stateForm.custodianApproval,
			// 	validation: {
			// 		required: true,
			// 	},
			// },
			doNotDecryptRules: {
				getValue: () => this.state.stateForm.ruleList,
				validation: {
					required: true
				}
			},
			comments: {
				getValue: () => this.state.stateForm.comments,
			},
			// sevenDayExemption:{
			// 	getValue: () => this.state.stateForm.sevenDayExemption,
			// 	validation: {
			// 		required: true
			// 	}
			// },
		});
	}

	stateFormHandler = () => this.getStateFormHandlers();

	getStateFormHandlers() {
		return {
			environment: {
				value: this.state.stateForm.environment,
				validation: this.formModel.fields.environment.validation,
				onChange: (env) => {
					this.handleStateFormChange('environment', env);
				}
			},
			contact: {
				value: this.state.stateForm.contact?.userPrincipalName,
				validation: this.formModel.fields.contactId.validation,
				onChange: (value) => {
					this.handleStateFormChange('contact', value);
				}
			},
			// custodianApproval: {
			// 	label:'Send to subscription Custodian for approval?',
			// 	value: this.state.stateForm.custodianApproval?.toString(),
			// 	validation: this.formModel.fields.custodianApproval.validation,
			// 	onChange: (event) => {
			// 		this.handleStateFormChange('custodianApproval', event.target.value === 'true');
			// 	},
			// 	note:'Use this to specify the subscription custodian as the approver when the subscription owner is not available.'
			// },
			emergency: {
				value: this.state.stateForm.isEmergency,
				validation: this.formModel.fields.emergency.validation,
				onChange: (event) => {
					this.setState({
						submissionWarned: false,
					});
					this.handleStateFormChange(['isEmergency', 'incidentNumber'], [event.target.value === 'true', '']);
				}
			},
			incidentNumber: {
				value: this.state.stateForm.incidentNumber,
				validation: this.formModel.fields.incidentNumber.validation,
				onChange: (event) => {
					this.handleStateFormChange('incidentNumber', event.target.value);
				}
			},
			businessJustification: {
				value: this.state.stateForm.businessJustification,
				validation: this.formModel.fields.businessJustification.validation,
				onChange: (event) => {
					this.handleStateFormChange('businessJustification', event.target.value);
				}
			},
			customerCoordination: {
				label:'Customer Coordination Required?',
				options: [
					{ value: 'true', text: 'Yes' },
					{ value: 'false', text: 'No' },
				],
				note: 'If yes, the MSP will coordinate the timing of the change with the requestor. If no, the MSP will implement the change as soon as possible.',
				value: this.state.stateForm.customerCoordination,
				validation: this.formModel.fields.customerCoordination.validation,
				onChange: (event) => {
					this.handleStateFormChange('customerCoordination', event.target.value === 'true');
				}
			},
			ruleset: {
				rules: this.state.stateForm.ruleList,
				onChange: (rules: DoNotDecryptRule[]) => {
					this.handleStateFormChange('ruleList', rules);
				}
			},
			comments: {
				value: this.state.stateForm.comments || '',
				validation: this.formModel.fields.comments.validation,
				onChange: (event) => {
					this.handleStateFormChange('comments', event.target.value);
				}
			},
			// sevenDayExemption: {
			// 	label:'Does this request need a 7 day exemption?',
			// 	value: this.state.stateForm.sevenDayExemption?.toString(),
			// 	validation: this.formModel.fields.sevenDayExemption.validation,
			// 	onChange: (event) => {
			// 		this.handleStateFormChange('sevenDayExemption', event.target.value === 'true');
					
			// 	},
			// 	note:'Use this to specify if the DPI (Deep Packet Inspection) Exception Request is needed only for a trial period of 7 days.'
			// },
		};
	}

	handleEndModal = (text: string, variant: AlertVariant, timeout: number, link: string) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
				redirectionLink: link
			}
		},
		() => {
			setTimeout(() => {
				this.endAndRedirect();
			}, timeout);
		});
	};

	endAndRedirect = () => {
		const link = this.state.endModal.redirectionLink;
		this.setState({
			endModal: { visible: false }
		},
		() => {
			this.props.navigate(link);
		});
	};

	setSubmissionAlertInProgress = (message) => {
		window.scroll(0, 0);
		this.setState({
			operationInProgress: true,
			submissionAlert: {
				visible: true,
				text: message
			}
		});
	};

	setSubmissionAlertError = (message) => {
		this.setState({
			submissionAlert: {
				visible: true,
				text: message,
				variant: 'error'
			}
		});
	};

	handleSubmissionAlertClose = () => {
		this.setState({
			submissionAlert: {
				visible: false
			}
		});
	};

	requiresApproverVerification = (selectedScope: string) => {
		return true;
	};

	//eslint-disable-next-line class-methods-use-this
	stateFormFromRequest(dndr: DoNotDecryptRequest): IDNDRStateForm {
		return {
			environment: dndr.environment,
			contact: !!dndr.contactUser &&
				UserLookupObject.fromUser(dndr.contactUser),
			isEmergency: dndr.isEmergency,
			incidentNumber: dndr.incidentNumber,
			businessJustification: dndr.businessJustification,
			customerCoordination: dndr.customerCoordination,
			ruleList: dndr.doNotDecryptRules,
			comments: dndr.comments,
			// custodianApproval: dndr.custodianApproval,
			//sevenDayExemption: dndr.sevenDayExemption, 
		};
	}
}
