import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChartPieIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M6.5 16a6.5 6.5 0 0 1 0-13H7v1h-.5A5.5 5.5 0 1 0 12 9.5V9h1v.5A6.508 6.508 0 0 1 6.5 16z"
          fill="currentColor"
        />
        <path
          d="M15.5 7h-6a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 .5-.5A6.508 6.508 0 0 1 16 6.5a.5.5 0 0 1-.5.5zM10 6h4.978A5.509 5.509 0 0 0 10 1.022z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M22.03 44.5a19.523 19.523 0 0 1-2.132-.118A18.539 18.539 0 0 1 3.616 28.1 18.505 18.505 0 0 1 22 7.5a1 1 0 0 1 1 1V25h16.5a1 1 0 0 1 1 1 18.527 18.527 0 0 1-6.162 13.785A18.283 18.283 0 0 1 22.03 44.5zM21 9.53A16.5 16.5 0 1 0 38.47 27H22a1 1 0 0 1-1-1z"
          fill="currentColor"
        />
        <path
          d="M44 21H28a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1 17.019 17.019 0 0 1 17 17 1 1 0 0 1-1 1zm-15-2h13.967A15.024 15.024 0 0 0 29 5.033z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11 23A10.134 10.134 0 0 1 1 13 10.134 10.134 0 0 1 11 3h1v2h-1a8.209 8.209 0 0 0-8 8 8.209 8.209 0 0 0 8 8 8.209 8.209 0 0 0 8-8v-1h2v1a10.134 10.134 0 0 1-10 10z"
          fill="currentColor"
        />
        <path
          d="M23 10h-8a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1 8.8 8.8 0 0 1 9 9 1 1 0 0 1-1 1zm-7-2h5.936A6.782 6.782 0 0 0 16 2.064z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChartPieIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChartPieIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChartPieIcon;
