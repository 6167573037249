import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TileDescription = props => {
  const { className, children, ...other } = props;

  return (
    <span className={clsx("em-c-tile__desc", className)} {...other}>
      {children}
    </span>
  );
};

TileDescription.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

TileDescription.defaultProps = {
  className: "",
  children: null
};

export default TileDescription;
