import * as React from 'react';
import UserLookup from '../../common/form-controls/UserLookup';
import FieldHandler from '../../../models/interfaces/FieldHandler';

interface OwnerInputProps extends FieldHandler<string> { }

interface OwnerInputState { }

export class OwnerInput extends React.Component<OwnerInputProps, OwnerInputState> {
	render() {
		return (
			<UserLookup
				id="owner-lookup"
				label="EMIT Owner"
				isSubscriptionOwner
				noteLink='https://gotocloud.xom.cloud/subscription-owner-learning-path/'
				note={!this.props.hideNotes && 'Limited to Approved Subscription Owners, managed by the Modern Applications & Platforms team. '}
				{...this.props}
			/>
		
			
		);
	}
}