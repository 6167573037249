import { Enumeration } from '../../../utils/AppEnum';
import { DoNotDecryptRequestsDirection } from './DoNotDecryptRequestsDirection';

export class RuleRegion extends Enumeration<RuleRegion> {

    constructor();
    constructor(name: string, value: number);
    constructor(name: string, value: number, dndrDirections: DoNotDecryptRequestsDirection[])

    constructor(name?: string, value?: number, dndrDirections?: DoNotDecryptRequestsDirection[]) {
        super(name, value);
        this.dndrDirections = dndrDirections ?? [];
    }

    public dndrDirections: DoNotDecryptRequestsDirection[];

    public static America = new RuleRegion('America', 0);

    public static EAME = new RuleRegion('EAME', 1);

    public static AP = new RuleRegion('AP', 2);

    public static USSC = new RuleRegion('USSC', 3, new DoNotDecryptRequestsDirection().list());

    public static USE1 = new RuleRegion('USE1', 4, new DoNotDecryptRequestsDirection().list());

    public static ASST = new RuleRegion('ASST', 5, new DoNotDecryptRequestsDirection().list());

    public static ASEA = new RuleRegion('ASEA', 6, [DoNotDecryptRequestsDirection.SubscriptionToOnPrem]);

    public static WW = new RuleRegion('WW', 7, [DoNotDecryptRequestsDirection.SubscriptionToInternet]);

    public listByDirection = (dndrDirection: DoNotDecryptRequestsDirection): RuleRegion[] =>
        this.list().filter(region => region.dndrDirections.includes(dndrDirection))

}
