import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const StopSignIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.4 16H5.6a.5.5 0 0 1-.354-.146l-4.1-4.1A.5.5 0 0 1 1 11.4V5.6a.5.5 0 0 1 .146-.354l4.1-4.1A.5.5 0 0 1 5.6 1h5.8a.5.5 0 0 1 .354.146l4.1 4.1A.5.5 0 0 1 16 5.6v5.8a.5.5 0 0 1-.146.354l-4.1 4.1A.5.5 0 0 1 11.4 16zm-5.592-1h5.385L15 11.192V5.808L11.192 2H5.808L2 5.808v5.385z"
          fill="currentColor"
        />
        <path
          d="M10.571 14H6.429a.5.5 0 0 1-.354-.146l-2.929-2.929A.5.5 0 0 1 3 10.571V6.429a.5.5 0 0 1 .146-.354l2.929-2.929A.5.5 0 0 1 6.429 3h4.143a.5.5 0 0 1 .354.146l2.929 2.929a.5.5 0 0 1 .145.354v4.143a.5.5 0 0 1-.146.354l-2.929 2.929a.5.5 0 0 1-.354.145zm-3.935-1h3.729L13 10.364V6.636L10.364 4H6.636L4 6.636v3.729z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M33 48H15a2.269 2.269 0 0 1-1.527-.586L.586 34.526A2.27 2.27 0 0 1 0 33V14.888a1.986 1.986 0 0 1 .586-1.414L13.473.586A2.269 2.269 0 0 1 15 0h18a2.269 2.269 0 0 1 1.527.586l12.887 12.888A2.27 2.27 0 0 1 48 15v18a2.27 2.27 0 0 1-.586 1.526L34.527 47.414A2.269 2.269 0 0 1 33 48zm-18.1-2.01c.024 0 .073.01.1.01h18a.472.472 0 0 0 .163-.041L46 33.112c-.019.014 0-.07 0-.112V15a.472.472 0 0 0-.041-.162L33.113 2c.016.022-.072 0-.113 0H15a.472.472 0 0 0-.163.041L2 14.888V33a.472.472 0 0 0 .041.162L14.887 46c-.005-.008.001-.01.013-.01z"
          fill="currentColor"
        />
        <path
          d="M30.627 42H17.373a1.986 1.986 0 0 1-1.414-.586l-9.373-9.372A2.013 2.013 0 0 1 6 30.627V17.373a2.013 2.013 0 0 1 .586-1.415l9.373-9.372A1.986 1.986 0 0 1 17.373 6h13.254a1.986 1.986 0 0 1 1.414.586l9.373 9.372A2.013 2.013 0 0 1 42 17.373v13.254a2.013 2.013 0 0 1-.586 1.415l-9.373 9.372a1.986 1.986 0 0 1-1.414.586zM17.373 8L8 17.372v13.255L17.373 40h13.254L40 30.628V17.373L30.627 8z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16.1 24H7.9a2.11 2.11 0 0 1-1.481-.613l-5.8-5.8A2.079 2.079 0 0 1 0 16.1V7.9a2.08 2.08 0 0 1 .615-1.485l5.8-5.8A2.11 2.11 0 0 1 7.9 0h8.2a2.11 2.11 0 0 1 1.481.613l5.8 5.8A2.079 2.079 0 0 1 24 7.9v8.2a2.08 2.08 0 0 1-.615 1.482l-5.8 5.8A2.11 2.11 0 0 1 16.1 24zM7.9 2L2.028 7.83 2 16.1l5.83 5.87 8.27.03 5.87-5.83L22 7.9 16.1 2z"
          fill="currentColor"
        />
        <path
          d="M14.5 20H9.635a2.119 2.119 0 0 1-1.509-.626L4.69 15.938a2.123 2.123 0 0 1-.625-1.509V9.57a2.123 2.123 0 0 1 .624-1.508l3.438-3.437A2.118 2.118 0 0 1 9.635 4H14.5a2.118 2.118 0 0 1 1.5.625l3.44 3.437a2.122 2.122 0 0 1 .625 1.509v4.859a2.121 2.121 0 0 1-.624 1.508L16 19.375a2.118 2.118 0 0 1-1.5.625zM9.635 6a.131.131 0 0 0-.093.038L6.1 9.476a.134.134 0 0 0-.039.095v4.859a.135.135 0 0 0 .04.1l3.436 3.436a.132.132 0 0 0 .098.034H14.5a.133.133 0 0 0 .095-.039l3.437-3.437a.134.134 0 0 0 .039-.095V9.57a.135.135 0 0 0-.04-.1L14.59 6.039A.133.133 0 0 0 14.5 6z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

StopSignIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

StopSignIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default StopSignIcon;
