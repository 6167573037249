import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CaretLeftIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg viewBox="0 0 16 16">
        <path
          d="M11 3.269v8.758a.66.66 0 0 1-1.033.545l-6.4-4.379a.66.66 0 0 1 0-1.089l6.4-4.379A.66.66 0 0 1 11 3.269z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M34.849 10.378v26.539a2 2 0 0 1-3.129 1.651L12.323 25.3a2 2 0 0 1 0-3.3L31.72 8.728a2 2 0 0 1 3.129 1.65z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M17 5.013v13.27a1 1 0 0 1-1.565.825l-9.7-6.635a1 1 0 0 1 0-1.651l9.7-6.635A1 1 0 0 1 17 5.013z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CaretLeftIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CaretLeftIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CaretLeftIcon;
