import * as React from 'react';
import { Modal, Button, ButtonProps, XFilledIcon, Grid } from 'react-unity';
import './ConfirmModal.css';
import FormModel from '../../../utils/FormModel';
import { IValidation } from '../../../utils/Validation';
import TextAreaField from '../form-controls/TextareaField';

interface ModalButtonProps {
	label?: string;
	props?: ButtonProps;
}

interface ConfirmModalProps {
	title: string;
	question: any;
	confirmButton?: ModalButtonProps;
	cancelButton?: ModalButtonProps;
	confirmationField?: {
		label: string;
		value: string;
		validation: IValidation;
		onChange: (...args: any[]) => any;
	};
	visible: boolean;
	onConfirm: () => any;
	onCancel: () => any;
}

export default class ConfirmModal extends React.Component<ConfirmModalProps, {}> {
	form: FormModel;

	constructor(props: ConfirmModalProps) {
		super(props);

		if (this.props.confirmationField) {
			this.form = new FormModel({
				confirmationField: {
					getValue: () => this.props.confirmationField.value,
					validation: this.props.confirmationField.validation
				}
			});
		}
	}

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>{this.props.title}</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onCancel}>
							<XFilledIcon />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body id="confirm-modal-body">
						<p>{this.props.question}</p>
						{this.props.confirmationField &&
							<TextAreaField
								label={this.props.confirmationField.label}
								value={this.props.confirmationField.value}
								validation={this.props.confirmationField.validation}
								onChange={this.props.confirmationField.onChange}
							/>}
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button
									variant="secondary"
									onClick={this.props.onCancel}
									{...this.props.cancelButton?.props}
								>
									{this.props.cancelButton?.label || 'Cancel'}
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button
									variant="primary"
									onClick={this.props.onConfirm}
									disabled={this.form ? !this.form?.isValid() : false}
									{...this.props.confirmButton?.props}
								>
									{this.props.confirmButton?.label || 'Confirm'}
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}
