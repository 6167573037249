import React from 'react';
import { Container, Grid } from 'react-unity';
import SelectField from '../../common/form-controls/SelectField';
import UserLookup from '../../common/form-controls/UserLookup';

const SubscriptionEditModalTableRow = ({ form, organizationOptions, organizationOptionsL5, handleL3Change, handleL4Change, handleL5Change, stateFormHandler }) => {	
	return (
		<Container>
			<Grid variant="2-up">
				<Grid.Item>
					<SelectField
						label="L3 Organization"
						options={organizationOptions(null)}
						className="full-width" 
						value={form.organizationL3.value}
						onChange={handleL3Change}
					/>
				</Grid.Item>
				<Grid.Item>
					<SelectField
						label="L4 Organization"
						className="full-width"
						options={organizationOptions(form.organizationL3.value)}
						disabled={
							organizationOptions(form.organizationL3.value).length < 1
							||
							!form.organizationL3.value
						}
						value={form.organizationL4.value}
						{...stateFormHandler().organizationL4}
						onChange={(e) => handleL4Change(e)}
					/>
				</Grid.Item>
			</Grid>
			<Grid variant="halves">
				<Grid.Item>
					<SelectField
						label="L5 Organization"
						className="full-width"
						options={organizationOptionsL5(form.organizationL4.value)}
						disabled={
							organizationOptions(form.organizationL4.value).length < 1
							||
							!form.organizationL4.value
						}
						value={form.organizationL5.value}
						onChange={(e) => handleL5Change(e)}
					/>
				</Grid.Item>
			</Grid>
			<Grid>
				<Grid.Item>
					<UserLookup
						id="owner-lookup"
						label="Emit Owner"
						isSubscriptionOwner
						{...stateFormHandler().owner}
					/>
				</Grid.Item>
				<Grid.Item>
					<UserLookup
						id="custodian-lookup"	
						label="Custodian"
						isSubscriptionOwner
						{...stateFormHandler().custodian}
					/>
				</Grid.Item>
				<Grid.Item>
					<UserLookup
						id="custodian-lookup"	
						label="Custodian Two"
						isSubscriptionOwner
						{...stateFormHandler().custodianTwo}
					/>
				</Grid.Item>
			</Grid>
		</Container>
	);
};

export default SubscriptionEditModalTableRow;
