import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CaretUpIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg viewBox="0 0 16 16">
        <path
          d="M12.379 10H3.621a.66.66 0 0 1-.545-1.033l4.379-6.4a.66.66 0 0 1 1.089 0l4.379 6.4A.66.66 0 0 1 12.379 10z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M37.269 33.849H10.731A2 2 0 0 1 9.08 30.72l13.269-19.4a2 2 0 0 1 3.3 0l13.271 19.4a2 2 0 0 1-1.651 3.129z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.635 16H5.365a1 1 0 0 1-.825-1.565l6.635-9.7a1 1 0 0 1 1.651 0l6.635 9.7A1 1 0 0 1 18.635 16z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CaretUpIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CaretUpIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CaretUpIcon;
