import * as React from 'react';
import {CheckboxDropdown, Field, SelectProps} from 'react-unity';
import './MultipleSelectField.css';
import { Option } from '../../../utils/Option';
import { AbstractSelectableProps, AbstractSelectableState, AbstractSelectable } from './AbstractSelectable';



interface Props extends AbstractSelectableProps {
	fullWidth?: boolean;
}

export default class MultipleSelectField
	extends AbstractSelectable<SelectProps, Props, AbstractSelectableState> {
	
	constructor(props) {
		super(props);
		this.state = {
			options: []
		}
	}



	render() {
		return (
			<Field
			error={!this.isValid()}
			valid={this.isValid()}>
			<Field.Label> {this.props.label} </Field.Label>
			<Field.Body>
			<CheckboxDropdown
				{...this.props}
				label={this.props.value}
				buttonProps={{className:'checkbox-btn'}}				
			>
				{this.state.options.map((op: Option) => (
					<CheckboxDropdown.Item key={op.value} label={op.text} inputProps={{value:op.value}} >
									
					</CheckboxDropdown.Item>
				))}
			</CheckboxDropdown>
			</Field.Body>
			<Field.Note> {this.nonValidMessage() || this.props.note} </Field.Note>
			</Field>
		);
	}
}
