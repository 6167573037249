import React from 'react';
import { Table, Button } from 'react-unity';

interface SubAdminConsentTableRowProps {
	displayName: string;
	type: string;
	onGrantAdminConsent: any;
}

const SubAdminConsentTableRow = ({ displayName, type, onGrantAdminConsent }: SubAdminConsentTableRowProps) => {
	const handleConsent = () => {
		onGrantAdminConsent();
	};
	return (
		<Table.Body.Row>
			<Table.Body.Cell>
				<div className="small-margin">{displayName}</div>
			</Table.Body.Cell>
			<Table.Body.Cell>
				<div className="small-margin">{type}</div>
			</Table.Body.Cell>
			<Table.Body.Cell>
				<Button onClick={handleConsent}>Grant Admin Consent</Button>
			</Table.Body.Cell>
		</Table.Body.Row>
	);
};

export default SubAdminConsentTableRow;
