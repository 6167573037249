import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ClockIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 15A6.5 6.5 0 1 1 15 8.5 6.508 6.508 0 0 1 8.5 15zm0-12A5.5 5.5 0 1 0 14 8.5 5.507 5.507 0 0 0 8.5 3z"
          fill="currentColor"
        />
        <path
          d="M10.6 11.307L8.146 8.854A.5.5 0 0 1 8 8.5V6h1v2.293l2.307 2.307z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 45a21 21 0 1 1 21-21 21.024 21.024 0 0 1-21 21zm0-40a19 19 0 1 0 19 19A19.022 19.022 0 0 0 24 5z"
          fill="currentColor"
        />
        <path
          d="M24 40a16 16 0 1 1 16-16 16.018 16.018 0 0 1-16 16zm0-30a14 14 0 1 0 14 14 14.016 14.016 0 0 0-14-14z"
          fill="currentColor"
        />
        <path
          d="M30.979 32.914l-7.7-7.93a1 1 0 0 1-.282-.7V13.5h2v10.383l7.414 7.638z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"
          fill="currentColor"
        />
        <path
          d="M15 17.414l-3.707-3.707A1 1 0 0 1 11 13V5h2v7.586L16.414 16z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ClockIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ClockIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ClockIcon;
