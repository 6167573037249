import * as React from 'react';
import { Grid } from 'react-unity';
import { DelegatedApproverInput } from './DelegatedApproverInput';
import ToggleField from '../../common/form-controls/ToggleField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import './ApproverDelegationInput.css';

interface ApproverDelegationInputProps {
	hasApproverDelegation: FieldHandler<boolean | null>;
	disabled?: boolean;
    scope?: string;
    delegatedApprover: any;
	isRequired: boolean;
}

interface ApproverDelegationInputState {}

export class ApproverDelegationInput extends React.Component<ApproverDelegationInputProps, ApproverDelegationInputState> {

	render() {
		return (
			<Grid variant="halves">
				<Grid.Item className="margin-bottom-2m">
					<ToggleField
						id="has-delegated-approver"
						label="Send to alternate DOAG approver?"
						options={!this.props.disabled? [
							{ value: 'true', text: 'Yes' },
							{ value: 'false', text: 'No' },
						]:
						this.props.hasApproverDelegation.value?
							[{ value: 'true', text: 'Yes' }]
							:
							[{ value: 'false', text: 'No' }]
						}
						{...this.props.hasApproverDelegation}
						value={this.props.hasApproverDelegation.value?.toString()}
						disabled={this.props.disabled}
						validation={{ required: this.props.isRequired }}
					/>
				</Grid.Item>
				{ this.props.hasApproverDelegation.value && (
					<Grid.Item>
						<DelegatedApproverInput
							{...this.props.delegatedApprover}
                            disabled={this.props.disabled}
                            scope={this.props.scope}
						/>
					</Grid.Item>
				)}
			</Grid>
		);
	}
}