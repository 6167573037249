import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CommentConversationIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 13.786a.5.5 0 0 1-.333-.127L13.309 12H8.5A1.5 1.5 0 0 1 7 10.5v-3A1.5 1.5 0 0 1 8.5 6h6A1.5 1.5 0 0 1 16 7.5v5.786a.5.5 0 0 1-.5.5zM8.5 7a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h5a.5.5 0 0 1 .333.127L15 12.169V7.5a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M1.5 15.21a.5.5 0 0 1-.5-.5V3.5A1.5 1.5 0 0 1 2.5 2h11A1.5 1.5 0 0 1 15 3.5V5h-1V3.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v10.013l2.4-2.369A.5.5 0 0 1 4.75 11H6v1H4.955l-3.1 3.066a.5.5 0 0 1-.355.144z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M47 42.714a1 1 0 0 1-.689-.276L40.6 37H23a2 2 0 0 1-2-2V20a2 2 0 0 1 2-2h23a2 2 0 0 1 2 2v21.714a1 1 0 0 1-1 1zM23 20v15h18a1 1 0 0 1 .689.276L46 39.381V20zM6 11h26v2H6zM6 17h14v2H6zM6 23h10v2H6z"
          fill="currentColor"
        />
        <path
          d="M1 43a1 1 0 0 1-1-1V6a3 3 0 0 1 3-3h36a3 3 0 0 1 3 3v11h-2V6a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v33.586l8.293-8.293A1 1 0 0 1 11 31h9v2h-8.586l-9.707 9.707A1 1 0 0 1 1 43zM40 26a1 1 0 1 1-1 1 1 1 0 0 1 1-1m0-1a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM34 26a1 1 0 1 1-1 1 1 1 0 0 1 1-1m0-1a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
        <path
          d="M28 26a1 1 0 1 1-1 1 1 1 0 0 1 1-1m0-1a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M23 22.857a1 1 0 0 1-.689-.276L19.6 20h-8.1a1.5 1.5 0 0 1-1.5-1.5v-7a1.5 1.5 0 0 1 1.5-1.5h11a1.5 1.5 0 0 1 1.5 1.5v10.357a1 1 0 0 1-1 1zM12 18h8a1 1 0 0 1 .689.276L22 19.524V12H12zm10.5-6z"
          fill="currentColor"
        />
        <path
          d="M1 19.929a1 1 0 0 1-1-1V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v3h-2V5H2v11.539l2.284-2.251a1 1 0 0 1 .7-.288H8v2H5.4l-3.7 3.641a1 1 0 0 1-.7.288z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CommentConversationIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CommentConversationIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CommentConversationIcon;
