import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FaceSmileIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 16A7.5 7.5 0 1 1 16 8.5 7.508 7.508 0 0 1 8.5 16zm0-14A6.5 6.5 0 1 0 15 8.5 6.508 6.508 0 0 0 8.5 2z"
          fill="currentColor"
        />
        <path
          d="M6.5 8A1.5 1.5 0 1 1 8 6.5 1.5 1.5 0 0 1 6.5 8zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM10.5 8A1.5 1.5 0 1 1 12 6.5 1.5 1.5 0 0 1 10.5 8zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM8.5 12.3a5.532 5.532 0 0 1-3.653-1.466l.706-.708A4.588 4.588 0 0 0 8.5 11.3a4.537 4.537 0 0 0 2.946-1.173l.707.707A5.532 5.532 0 0 1 8.5 12.3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 45a21 21 0 1 1 21-21 21.024 21.024 0 0 1-21 21zm0-40a19 19 0 1 0 19 19A19.022 19.022 0 0 0 24 5z"
          fill="currentColor"
        />
        <path
          d="M17 23a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM31 23a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM24 35a16.208 16.208 0 0 1-10.707-4.293l1.414-1.414A14.423 14.423 0 0 0 24 33a14.318 14.318 0 0 0 9.293-3.707l1.414 1.414A16.208 16.208 0 0 1 24 35z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11.5 24A11.5 11.5 0 1 1 23 12.5 11.513 11.513 0 0 1 11.5 24zm0-21a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 11.5 3z"
          fill="currentColor"
        />
        <path
          d="M8 11a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM15 11a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM11.5 17.5a8.645 8.645 0 0 1-5.707-2.293l1.414-1.414A6.712 6.712 0 0 0 11.5 15.5a6.607 6.607 0 0 0 4.293-1.707l1.414 1.414A8.645 8.645 0 0 1 11.5 17.5z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FaceSmileIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FaceSmileIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FaceSmileIcon;
