import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import MediaBlockBody from "./MediaBlockBody";
import MediaBlockMedia from "./MediaBlockMedia";

function getSizeClass(size) {
  switch (size) {
    case "small":
      return "em-c-media-block--small";
    case "tiny":
      return "em-c-media-block--tiny";
    default:
      return "";
  }
}

/**
 * Versatile container for image, heading, and text
 *
 * @visibleName Media block
 * */
const MediaBlock = props => {
  const { size, className, children, ...other } = props;

  return (
    <div
      className={clsx("em-c-media-block", getSizeClass(size), className)}
      {...other}
    >
      {children}
    </div>
  );
};

MediaBlock.Body = MediaBlockBody;
MediaBlock.Media = MediaBlockMedia;

MediaBlock.propTypes = {
  /** Size of the  image */
  size: PropTypes.oneOf(["default", "small", "tiny"]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

MediaBlock.defaultProps = {
  size: "default",
  className: "",
  children: null
};

export default MediaBlock;
