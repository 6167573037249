import User from '../User';
import AWSPortOpeningRequest from './AWSPortOpeningRequests';
import { VirtualPrivateCloud } from './AzureResources/VirtualPrivateCloud';

export default class AWSAccount {

	account!: AccountBasic;

	owner!: User;

	vpc!: VirtualPrivateCloud[];

	awsPortOpeningRequests?: AWSPortOpeningRequest[];

	constructor(obj: any) {
		Object.assign(this, obj);
		this.account = new AccountBasic(obj.account);
	}

	canSubmitPortOpeningRequests(): boolean {
		return this.canHavePortOpeningRequests();
	}

	private canHavePortOpeningRequests(): boolean {
		return true;
	}
}

export class AccountBasic {
	id!: string;

	name!: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}
