import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TankCar = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M14.8,12L14.8,12c0.3,0,1.2,0,1.2-4.5C16,3,15.2,3,14.8,3H1.2C0.8,3,0,3,0,7.5C0,10.5,0.4,12,1.2,12h0
		C0.5,12.2,0,12.8,0,13.5C0,14.3,0.7,15,1.5,15S3,14.3,3,13.5c0-0.7-0.5-1.3-1.2-1.5l2.4,0C3.5,12.2,3,12.8,3,13.5
		C3,14.3,3.7,15,4.5,15S6,14.3,6,13.5c0-0.7-0.5-1.3-1.2-1.5l6.4,0c-0.7,0.1-1.2,0.7-1.2,1.5c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5
		c0-0.7-0.5-1.3-1.2-1.5l2.4,0c-0.7,0.1-1.2,0.7-1.2,1.5c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5C16,12.8,15.5,12.2,14.8,12z M2,13.5
		C2,13.8,1.8,14,1.5,14S1,13.8,1,13.5S1.2,13,1.5,13S2,13.2,2,13.5z M5,13.5C5,13.8,4.8,14,4.5,14S4,13.8,4,13.5S4.2,13,4.5,13
		S5,13.2,5,13.5z M12,13.5c0,0.3-0.2,0.5-0.5,0.5S11,13.8,11,13.5s0.2-0.5,0.5-0.5S12,13.2,12,13.5z M12,6h-2V4h2V6z M1,7.5
		C1,5.7,1.2,4.4,1.4,4H9v5h1V7h2v2h1V4h1.7C14.8,4.4,15,5.7,15,7.5s-0.2,3.1-0.3,3.5H1.4C1.2,10.7,1,9.4,1,7.5z M14.5,14
		c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S14.8,14,14.5,14z"
          />
          <rect x="5" y="1" fill="currentColor" width="6" height="1" />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M41.5,32c-1.9,0-3.5,1.6-3.5,3.5c0,0.2,0,0.3,0.1,0.5h-2.1c0-0.2,0.1-0.3,0.1-0.5c0-1.9-1.6-3.5-3.5-3.5
		S29,33.6,29,35.5s1.6,3.5,3.5,3.5c1,0,1.9-0.4,2.5-1.1V38h4v-0.1c0.6,0.7,1.5,1.1,2.5,1.1c1.9,0,3.5-1.6,3.5-3.5S43.4,32,41.5,32z
		 M32.5,37c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S33.3,37,32.5,37z M41.5,37c-0.8,0-1.5-0.7-1.5-1.5
		s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S42.3,37,41.5,37z"
          />
          <path
            fill="currentColor"
            d="M15.5,32c-1.9,0-3.5,1.6-3.5,3.5c0,0.2,0,0.3,0.1,0.5H9.9c0-0.2,0.1-0.3,0.1-0.5c0-1.9-1.6-3.5-3.5-3.5
		S3,33.6,3,35.5S4.6,39,6.5,39c1,0,1.9-0.4,2.5-1.1V38h4v-0.1c0.6,0.7,1.5,1.1,2.5,1.1c1.9,0,3.5-1.6,3.5-3.5S17.4,32,15.5,32z
		 M6.5,37C5.7,37,5,36.3,5,35.5S5.7,34,6.5,34S8,34.7,8,35.5S7.3,37,6.5,37z M15.5,37c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5
		s1.5,0.7,1.5,1.5S16.3,37,15.5,37z"
          />
          <path
            fill="currentColor"
            d="M45,13H30v-1.9C30,9.9,29.2,9,28.1,9h-8.3c-1,0-1.9,0.9-1.9,2.1V13H3c-2.7,0-3,6.6-3,9.5
		c0,1.6,0.1,9.3,3,9.5l42,0c2.7,0,3-6.6,3-9.5S47.7,13,45,13z M39,22h-3v-3h3V22z M39,17h-3v-2h3V17z M20,11.1
		C20,11.1,20,11,20,11.1l8-0.1c0,0,0,0.1,0,0.1V13h-8V11.1z M44.8,30H3.3C2.8,29.5,2,26.9,2,22.5c0-4.4,0.7-7,1.2-7.5H34v11h2v-2h3
		v2h2V15h3.8c0.4,0.5,1.2,3.1,1.2,7.5S45.3,29.5,44.8,30z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M22.2,17.1c0.6-0.1,1.8-0.6,1.8-6.1C24,5,22.6,5,22,5H2c-0.5,0-2,0-2,6.1c0,3.8,0.6,5.9,1.8,6
		C0.8,17.4,0,18.3,0,19.5C0,20.9,1.1,22,2.5,22S5,20.9,5,19.5c0-1.1-0.7-2.1-1.8-2.4l3.5,0c-1,0.3-1.8,1.3-1.8,2.4
		C5,20.9,6.1,22,7.5,22s2.5-1.1,2.5-2.5c0-1.1-0.7-2.1-1.8-2.4l7.5,0c-1,0.3-1.8,1.3-1.8,2.4c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5
		c0-1.1-0.7-2.1-1.8-2.4l3.5,0c-1,0.3-1.8,1.3-1.8,2.4c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C24,18.4,23.2,17.4,22.2,17.1z M7.5,20
		C7.2,20,7,19.8,7,19.5S7.2,19,7.5,19S8,19.2,8,19.5S7.8,20,7.5,20z M16.5,20c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5
		s0.5,0.2,0.5,0.5S16.8,20,16.5,20z M16,7h2v2h-2V7z M2.4,7H14v6h2v-2h2v2h2V7h1.6c0.2,0.5,0.4,1.9,0.4,4.1s-0.2,3.5-0.4,4.1H2.5
		c-0.2-0.6-0.5-2-0.5-4.1C2,8.9,2.2,7.5,2.4,7z M2.5,20C2.2,20,2,19.8,2,19.5S2.2,19,2.5,19S3,19.2,3,19.5S2.8,20,2.5,20z M21.5,20
		c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S21.8,20,21.5,20z"
          />
          <rect x="8" y="2" fill="currentColor" width="8" height="2" />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TankCar.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TankCar.defaultProps = {
  size: "medium",

  className: ""
};

export default TankCar;
