import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretLeftIcon } from "../../Icons";

/** PagerPrev is the pager button to navigate to the previous page */
const PagerPrev = props => {
  const { disabled, onChange, className, children, ...other } = props;

  return (
    <li
      className={clsx(
        "em-c-pagination__item",
        { "em-u-clickable": !disabled },
        className
      )}
      onClick={!disabled ? onChange : () => {}}
      {...other}
    >
      <div
        className={clsx("em-c-pagination__link", "em-c-pager__item", {
          "em-is-disabled": disabled
        })}
      >
        <CaretLeftIcon
          className="em-c-pagination__icon em-pagination-icon"
          size="small"
        />
        <span>{children}</span>
      </div>
    </li>
  );
};

PagerPrev.propTypes = {
  disabled: PropTypes.bool,
  /** function emitted on change, returns this pages inner value */
  onChange: PropTypes.func,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** child content,i.e. the text in the pager */
  children: PropTypes.node
};

PagerPrev.defaultProps = {
  disabled: false,
  onChange: () => {},
  className: "",
  children: null
};

export default PagerPrev;
