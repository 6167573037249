import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const GasolineBottle = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M10.4,6.4L9.6,5.6L8.3,7H5.7L4.4,5.6L3.6,6.4L5,7.7v2.6l-1.4,1.4l0.7,0.7L5.7,11h2.6l1.4,1.4l0.7-0.7L9,10.3V7.7L10.4,6.4z
		 M8,10H6V8h2V10z"
          />
          <path
            fill="currentColor"
            d="M11.4,3.8c-0.6-0.7-1.3-1.3-1.5-1.6c0,0,0,0,0,0c0,0,0,0,0,0l-2-2C7.8,0.1,7.6,0,7.5,0H3.2H3.1H1.5C0.7,0,0,0.7,0,1.5v13
		C0,15.3,0.7,16,1.5,16h10.9c0.9,0,1.6-0.7,1.6-1.5V7c0-0.1,0-0.1,0-0.2C13.8,6.4,13.1,5.6,11.4,3.8z M8.3,2H4.8L4.1,1h3.2L8.3,2z
		 M13,14.5c0,0.3-0.3,0.5-0.6,0.5H1.5C1.2,15,1,14.8,1,14.5v-13C1,1.2,1.2,1,1.5,1H3l1.1,1.8C4.2,2.9,4.3,3,4.5,3h4.8
		c0.3,0.3,0.7,0.8,1.4,1.5c0.8,0.8,2.1,2.2,2.4,2.6V14.5z"
          />
          <path
            fill="currentColor"
            d="M15.9,3L13,0.1c-0.2-0.2-0.5-0.2-0.7,0l-1.2,1.2C11.1,1.4,11,1.5,11,1.7s0.1,0.3,0.1,0.4L14,4.9C14.1,5,14.2,5,14.3,5
		s0.3,0,0.4-0.1l1.2-1.2C15.9,3.6,16,3.5,16,3.3C16,3.2,15.9,3.1,15.9,3z M14.3,3.8l-2.1-2.1l0.5-0.5l2.1,2.1L14.3,3.8z"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M32.7,17.7l-1.4-1.4L27.6,20H16.4l-3.7-3.7l-1.4,1.4l3.7,3.7v11.2l-3.7,3.7l1.4,1.4l3.7-3.7h11.2l3.7,3.7l1.4-1.4L29,32.6
		V21.4L32.7,17.7z M27,32H17V22h10V32z"
          />
          <path
            fill="currentColor"
            d="M42.1,17.9c-0.2-0.1-0.3-0.3-0.4-0.4L30.3,6.3c0,0,0,0,0,0l-5.5-5.9C24.5,0.1,24.3,0,24,0H10H5C2.8,0,1,1.8,1,4v40
		c0,2.2,1.8,4,4,4h35c2.2,0,4-1.8,4-4V21C44,19.7,43,18.7,42.1,17.9z M27.3,6H15.5l-3.3-4h11.4L27.3,6z M42,44c0,1.2-0.8,2-2,2H5
		c-1.2,0-2-0.8-2-2V4c0-1.2,0.8-2,2-2h4.5l4.7,5.6C14.4,7.9,14.7,8,15,8h14.1l11.1,10.9c0.2,0.2,0.3,0.3,0.5,0.5
		c0.5,0.5,1.2,1.1,1.2,1.6V44z"
          />
          <path
            fill="currentColor"
            d="M46.7,9.8l-8.5-8.5c-0.4-0.4-1-0.4-1.4,0l-3.5,3.5c-0.4,0.4-0.4,1,0,1.4l8.5,8.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
		l3.5-3.5C47.1,10.8,47.1,10.2,46.7,9.8z M42.5,12.6l-7.1-7.1l2.1-2.1l7.1,7.1L42.5,12.6z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M15.7,9.7l-1.4-1.4L12.6,10H8.4L6.7,8.3L5.3,9.7L7,11.4v4.1l-1.7,1.7l1.4,1.4L8.3,17h4.3l1.7,1.7l1.4-1.4L14,15.6v-4.2
		L15.7,9.7z M12,15H9v-3h3V15z"
          />
          <path
            fill="currentColor"
            d="M20,8.8c-0.1-0.1-0.2-0.1-0.2-0.2l-5.1-5.2c-0.1-0.1-0.2-0.1-0.3-0.2l-2.5-2.8C11.8,0.1,11.5,0,11.2,0H4.8
		C4.7,0,4.6,0,4.6,0H2.5C1.1,0,0,1.2,0,2.5v19C0,22.9,1.1,24,2.5,24h16c1.4,0,2.5-1.1,2.5-2.5v-11C21,9.7,20.4,9.1,20,8.8z M11.7,3
		H7.5L6.8,2h3.9L11.7,3z M19,21.5c0,0.3-0.2,0.5-0.5,0.5h-16C2.2,22,2,21.8,2,21.5v-19C2,2.3,2.2,2,2.5,2h2l1.7,2.6
		C6.4,4.9,6.7,5,7,5h6.6l4.8,4.9c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.3,0.3,0.3V21.5z"
          />
          <path
            fill="currentColor"
            d="M23.7,4.5l-4.2-4.2c-0.4-0.4-1-0.4-1.4,0l-1.8,1.8c-0.4,0.4-0.4,1,0,1.4l4.2,4.2C20.7,7.9,21,8,21.2,8s0.5-0.1,0.7-0.3
		L23.7,6C24.1,5.6,24.1,4.9,23.7,4.5z M21.2,5.6l-2.8-2.8l0.4-0.4l2.8,2.8L21.2,5.6z"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

GasolineBottle.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

GasolineBottle.defaultProps = {
  size: "medium",

  className: ""
};

export default GasolineBottle;
