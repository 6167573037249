import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const NewspaperIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 15a1.5 1.5 0 0 1-1.5-1.5v-11a.5.5 0 0 0-.5-.5h-10a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5H11v1H1.5A1.5 1.5 0 0 1 0 13.5v-11A1.5 1.5 0 0 1 1.5 1h10A1.5 1.5 0 0 1 13 2.5v11a.5.5 0 0 0 1 0V3h1v10.5a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
        <path
          d="M10.5 7h-8a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5zM3 6h7V4H3zM2 10h5v1H2zM2 12h5v1H2zM10.5 13h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM9 12h1v-1H9zM2 8h9v1H2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M37 44H6a3 3 0 0 1-3-3V5a1 1 0 0 1 1-1h36a1 1 0 0 1 1 1v35a1 1 0 0 0 2 0V8h2v32a3 3 0 0 1-6 0V6H5v35a1 1 0 0 0 1 1h31z"
          fill="currentColor"
        />
        <path
          d="M36 20H8a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h28a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM9 18h26v-8H9zM7 26h20v2H7zM7 30h20v2H7zM7 34h20v2H7zM7 38h20v2H7zM36 40h-6a1 1 0 0 1-1-1V27a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1zm-5-2h4V28h-4zM7 22h30v2H7z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21 23a1.959 1.959 0 0 1-2-2V3H2v17c0 .376 0 .738.009 1H18v2H2c-2 0-2-1.065-2-3V2a1 1 0 0 1 1-1h19a1 1 0 0 1 1 1v19a3.018 3.018 0 0 0 1-.105V3h2v18c0 .912-.521 2-3 2z"
          fill="currentColor"
        />
        <path
          d="M17 10H4a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zM5 8h11V6H5zM3 12h9v2H3zM3 16h9v2H3zM17 18h-3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-2-2h1v-2h-1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

NewspaperIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

NewspaperIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default NewspaperIcon;
