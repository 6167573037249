import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LifeSaverFloatIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M2.643 5.79l-.925-.38A6.815 6.815 0 0 1 5.41 1.718l.38.925A5.811 5.811 0 0 0 2.643 5.79zM13.357 5.79a5.811 5.811 0 0 0-3.147-3.147l.38-.925a6.815 6.815 0 0 1 3.692 3.692zM5.41 14.282a6.815 6.815 0 0 1-3.692-3.692l.925-.38a5.811 5.811 0 0 0 3.147 3.147zM10.59 14.282l-.38-.925a5.811 5.811 0 0 0 3.147-3.147l.925.38a6.815 6.815 0 0 1-3.692 3.692zM8 12a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-7a3 3 0 1 0 3 3 3 3 0 0 0-3-3z"
          fill="currentColor"
        />
        <path
          d="M10 5H9V2H7v3H6V1.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5zM9.5 15h-3a.5.5 0 0 1-.5-.5V11h1v3h2v-3h1v3.5a.5.5 0 0 1-.5.5zM14.5 10H11V9h3V7h-3V6h3.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5zM5 10H1.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H5v1H2v2h3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M3.925 15.38l-1.85-.76A23.164 23.164 0 0 1 14.62 2.075l.76 1.85A21.157 21.157 0 0 0 3.925 15.38zM44.075 15.38A21.157 21.157 0 0 0 32.62 3.925l.76-1.85A23.164 23.164 0 0 1 45.925 14.62zM14.62 45.925A23.164 23.164 0 0 1 2.075 33.38l1.85-.76A21.157 21.157 0 0 0 15.38 44.075zM33.38 45.925l-.76-1.85A21.157 21.157 0 0 0 44.075 32.62l1.85.76A23.164 23.164 0 0 1 33.38 45.925zM24 36a12 12 0 1 1 12-12 12.014 12.014 0 0 1-12 12zm0-22a10 10 0 1 0 10 10 10.011 10.011 0 0 0-10-10z"
          fill="currentColor"
        />
        <path
          d="M30 14h-2V2h-8v12h-2V1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zM29 48H19a1 1 0 0 1-1-1V34h2v12h8V34h2v13a1 1 0 0 1-1 1zM47 30H34v-2h12v-8H34v-2h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM14 30H1a1 1 0 0 1-1-1V19a1 1 0 0 1 1-1h13v2H2v8h12z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M4 8H2c0-2.58 3.42-6 6-6v2C6.542 4 4 6.542 4 8zM22 8h-2c0-1.458-2.542-4-4-4V2c2.58 0 6 3.42 6 6zM8 22c-2.58 0-6-3.42-6-6h2c0 1.458 2.542 4 4 4zM16 22v-2c1.458 0 4-2.542 4-4h2c0 2.58-3.42 6-6 6zM12 18a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"
          fill="currentColor"
        />
        <path
          d="M15 8h-2V2h-2v6H9V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1zM14 24h-4a1 1 0 0 1-1-1v-7h2v6h2v-6h2v7a1 1 0 0 1-1 1zM23 15h-7v-2h6v-2h-6V9h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zM6.739 15H1a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h5.739v2H2v2h4.739z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LifeSaverFloatIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LifeSaverFloatIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default LifeSaverFloatIcon;
