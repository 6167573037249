import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PhotoGalleryIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3.5 7A1.5 1.5 0 1 1 5 5.5 1.5 1.5 0 0 1 3.5 7zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM11.5 11h-9a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .228-.419l3-1.95a.5.5 0 0 1 .494-.031l1.778.882 3.776-1.928A.5.5 0 0 1 12 6.5v4a.5.5 0 0 1-.5.5zM3 10h8V7.316L7.728 8.987a.506.506 0 0 1-.45 0l-1.745-.863L3 9.771z"
          fill="currentColor"
        />
        <path
          d="M13.5 13H.5a.5.5 0 0 1-.5-.5v-10A.5.5 0 0 1 .5 2h13a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5zM1 12h12V3H1z"
          fill="currentColor"
        />
        <path
          d="M15.5 15H3v-1h12V4h1v10.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M11 19a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM36 34H8a1.964 1.964 0 0 1-2-1.923v-3.662a1 1 0 0 1 .528-.882l6.845-3.668a.989.989 0 0 1 .741-.081l9.566 2.673L36.558 20.1A1 1 0 0 1 38 21v11.077A1.964 1.964 0 0 1 36 34zM8 29.014v3.063L36 32v-9.392L24.222 28.42a1.008 1.008 0 0 1-.711.066l-9.547-2.668z"
          fill="currentColor"
        />
        <path
          d="M39 38H5a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3zM5 8a1 1 0 0 0-1 1v26a1 1 0 0 0 1 1h34a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M43 42H6v-2h37a1 1 0 0 0 1-1V10h2v29a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.987 19H2.013A2.016 2.016 0 0 1 0 16.986V3.014A2.016 2.016 0 0 1 2.013 1h16.974A2.016 2.016 0 0 1 21 3.014v13.972A2.016 2.016 0 0 1 18.987 19zM2.013 3L2 16.986 18.987 17a.013.013 0 0 0 .013-.014V3.014z"
          fill="currentColor"
        />
        <path d="M22 22H5v-2h17V7h2v13a2 2 0 0 1-2 2z" fill="currentColor" />
        <path
          d="M17 16H4a1 1 0 0 1-1-1v-3a1 1 0 0 1 .137-.506c.15-.257.15-.257 3.686-2.354a1 1 0 0 1 .9-.059l4.225 1.811 4.581-2.442A1 1 0 0 1 18 9.333V15a1 1 0 0 1-1 1zM5 14h11v-3l-3.529 1.883a1 1 0 0 1-.865.036l-4.2-1.8A489.02 489.02 0 0 0 5 12.557zM7.333 8a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PhotoGalleryIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PhotoGalleryIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PhotoGalleryIcon;
