import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MoonNightIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.074 4.4l-.167-.471a5.793 5.793 0 0 0-1.357-2.14q-.141-.141-.291-.271a6.719 6.719 0 0 1 .14 2.3l-.056.5L9.35 4.2l.055-.5A5.719 5.719 0 0 0 8.829.493.5.5 0 0 1 9.5-.18a6.662 6.662 0 0 1 1.762 1.263 6.772 6.772 0 0 1 1.589 2.509l.168.471zM6.473 12.639a6.763 6.763 0 0 1-4.791-1.978A6.654 6.654 0 0 1 .418 8.9a.5.5 0 0 1 .673-.668 5.808 5.808 0 0 0 4.36.333l.476-.154.308.951-.476.154a6.8 6.8 0 0 1-3.646.143c.088.1.18.2.275.295a5.784 5.784 0 0 0 8.163 0 5.857 5.857 0 0 0 .9-1.167l.253-.431.862.507-.254.431a6.823 6.823 0 0 1-1.054 1.368 6.747 6.747 0 0 1-4.785 1.977zM4 5h10v1H4zM7 7h8v1H7z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M20 19h26v2H20zM14 23h26v2H14zM20 27h26v2H20zM22.4 44A20.474 20.474 0 0 1 4.434 33.139a17.786 17.786 0 0 1-.854-1.749 1 1 0 0 1 1.45-1.238 16.94 16.94 0 0 0 3.232 1.571 16.666 16.666 0 0 0 11.208 0l.94-.34.681 1.881-.94.34a18.685 18.685 0 0 1-12.568 0q-.364-.131-.723-.278a18.286 18.286 0 0 0 31.76-1.139l.477-.879 1.758.953-.476.879A20.508 20.508 0 0 1 22.4 44zM39.9 17.282l-.342-.94a18.463 18.463 0 0 0-9.439-10.278 18.092 18.092 0 0 1 2.34 8.951c0 .346-.011.7-.032 1.047l-.062 1-2-.123.062-1c.019-.3.028-.619.028-.924a16.2 16.2 0 0 0-3.737-10.38 1 1 0 0 1 1.055-1.595 20.6 20.6 0 0 1 13.662 12.618l.342.939z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.294 7.778l-.336-.942a8.5 8.5 0 0 0-1.88-3.03 10.413 10.413 0 0 1 .065 2.806l-.111.994-1.988-.222.111-.994a8.422 8.422 0 0 0-.85-4.729A1 1 0 0 1 14.644.313a10.349 10.349 0 0 1 2.733 1.959 10.515 10.515 0 0 1 2.465 3.891l.336.942zM9.954 20.194a10.488 10.488 0 0 1-7.43-3.066A4.287 4.287 0 0 1 1 14a1 1 0 0 1 1.707-.707c.74.739 3.295 1.589 4.846.813l.895-.447.895 1.789-.895.447a6.091 6.091 0 0 1-3.9.368 8.525 8.525 0 0 0 11.419-.55c.2-.2.378-.358.538-.5a2.578 2.578 0 0 0 .638-.721l.507-.862 1.724 1.015-.507.861a4.522 4.522 0 0 1-1.037 1.206c-.133.117-.279.245-.448.414a10.464 10.464 0 0 1-7.428 3.068zM9 8h14v2H9zM6 11h14v2H6z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MoonNightIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MoonNightIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MoonNightIcon;
