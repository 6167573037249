import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Container creates a centered st width container to keep content readable, this should be used to wrap most content. */
const Container = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-l-container", className)} {...other}>
      {children}
    </div>
  );
};

Container.propTypes = {
  /** children of the container */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Container.defaultProps = {
  children: null,
  className: ""
};

export default Container;
