import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
} from 'react-unity';
import '../common/wrappers/SectionHomeWrapper.css';
import TAP from '../../models/entities/TAP';
import ApprovalsTAPTableRow from './ApprovalsTAPTableRow';
import TemporaryAccessPasswordService from '../../services/TemporaryAccessPasswordService';
import Paginator from '../common/tables/Paginator';

interface TemporaryAccessPasswordTableRowProps {
    filteredTAPs: TAP[];
    setEndModal: any;
    temporaryAccessPasswordService: TemporaryAccessPasswordService;
}

const ApprovalsTAPTable = ({
    setEndModal, temporaryAccessPasswordService, filteredTAPs
}: TemporaryAccessPasswordTableRowProps) => {
    const [tapsOnPage, setTapsOnPage] = useState<TAP[]>([]);


    return (
        <Table
            footer={
                <Paginator
                    data={filteredTAPs}
                    onPageChange={(taps: TAP[]) => {
                        setTapsOnPage(taps)
                    }}
                />
            }
        >
            <Table.Head>
                <Table.Head.Row>
                    <Table.Head.Cell> Requested Date</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'> Requestor </Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'> Actions </Table.Head.Cell>
                </Table.Head.Row>
            </Table.Head>
            <Table.Body>
                {tapsOnPage.map((tap: TAP) => (
                    <ApprovalsTAPTableRow
                        key={tap.id}
                        tap={tap}
                        setEndModal={setEndModal}
                        temporaryAccessPasswordService={temporaryAccessPasswordService}
                    />
                ))}
            </Table.Body>
        </Table>
    );
}

export default ApprovalsTAPTable; 