import CartaId from '../../entities/CartaId';
import {VNetConfiguration} from './VNetConfiguration';

export class RequestSubscriptionModel {
	environment: number;

	platform: number;

	ownerUserId: string;

	custodianUserId: string;

	custodianTwoUserId: string;

	isProject: boolean;

	wpmCode?: number;

	closeOutDate?: string;

	selectedL2GlobalId: string;

	selectedL3GlobalId: string;

	organizationGlobalId: string;

	subscriptionName: string;

	cartaIds: CartaId[];

	subscriptionUsedFor: string;

	isConnected: boolean;

	amountOfDevices?: number;

	region?: string;

	contributorAccessGroupId?: string;

	initialResourceGroup?: string;

	resourceGroupOwnerId?: string;

	resourceGroupOwnerType?: string;

	virtualNetwork?: VNetConfiguration[];

	subsBudget?: number;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}