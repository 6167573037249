import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import InsetBlockBodyHeadline from "./InsetBlockBodyHeadline";
import InsetBlockBodyDescription from "./InsetBlockBodyDescription";
import InsetBlockBodyActions from "./InsetBlockBodyActions";

const InsetBlockBody = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-inset-block__body", className)} {...other}>
      {children}
    </div>
  );
};

InsetBlockBody.Headline = InsetBlockBodyHeadline;
InsetBlockBody.Description = InsetBlockBodyDescription;
InsetBlockBody.Actions = InsetBlockBodyActions;

InsetBlockBody.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

InsetBlockBody.defaultProps = {
  className: "",
  children: null
};

export default InsetBlockBody;
