import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** TableHeadRow is the row that contains the cells in the table header */
const TableHeadRow = props => {
  const { className, children, ...other } = props;

  return (
    <tr className={clsx("em-c-table__header-row", className)} {...other}>
      {children}
    </tr>
  );
};

TableHeadRow.propTypes = {
  /** children are the contents of the TableHeadRow */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TableHeadRow.defaultProps = {
  children: null,
  className: ""
};

export default TableHeadRow;
