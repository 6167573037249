import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const Refinery = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M13.5,5C12.1,5,11,6.1,11,7.5V10H9l0-4.5c0-0.2-0.1-0.4-0.3-0.4C8.5,5,8.3,5,8.2,5.1L4.6,7.8L4,0.5
	C4,0.2,3.8,0,3.5,0h-2C1.2,0,1,0.2,1,0.4c-0.2,1-1.1,9.7-1,10.7v4.4C0,15.8,0.2,16,0.5,16h8C8.7,16,9,15.8,9,15.5L9,11h2v3.8
	c0,0.7,0.5,1.2,1.2,1.2h2.6c0.7,0,1.2-0.5,1.2-1.2V7.5C16,6.1,14.9,5,13.5,5z M8,15H1l0-3.9C0.9,10.3,1.6,3.1,1.9,1H3l1,12L5,13
	L4.7,9L8,6.5L8,15z M15,14.8c0,0.1-0.1,0.2-0.2,0.2h-2.6c-0.1,0-0.2-0.1-0.2-0.2V7.5C12,6.7,12.7,6,13.5,6S15,6.7,15,7.5V14.8z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M42,21v-2c0-1.1-1.2-2-2.6-2H26.6c-1.5,0-2.6,0.9-2.6,2v7.6l-4,2.5V24c0-0.4-0.2-0.7-0.5-0.9
	c-0.3-0.2-0.7-0.1-1,0.1l-7.8,5.6l1.2,1.6l6.2-4.5V31c0,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.7,0.2,1,0l9.5-6V46H2l0-12.5L3,2h2.1l4,42.1
	l2-0.2L7,0.9C6.9,0.4,6.5,0,6,0H2C1.5,0,1,0.4,1,1L0.1,29L0,33.5V47c0,0.6,0.4,1,1,1h29c0.6,0,1-0.4,1-1V24c0-0.4-0.2-0.7-0.5-0.9
	c-0.3-0.2-0.7-0.2-1,0L26,25.4v-6.2c0.1-0.1,0.3-0.2,0.6-0.2h12.7c0.3,0,0.5,0.1,0.6,0.2V21c-3.4,0.3-6,3.1-6,6.6V45
	c0,1.7,1.3,3,3,3h8c1.7,0,3-1.3,3-3V27.6C48,24.2,45.4,21.3,42,21z M46,45c0,0.6-0.4,1-1,1h-8c-0.6,0-1-0.4-1-1V27.6
	c0-2.5,2.1-4.6,4.6-4.6h0.8c2.5,0,4.6,2.1,4.6,4.6V45z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M20,7.5c-2.2,0-4,1.8-4,4V15l-2,0V9c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.7-0.2-1,0l-5.2,3.5L6,0.9
	C5.9,0.4,5.5,0,5,0H2C1.5,0,1,0.4,1,0.9L0.1,14.4L0,16.6V23c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1v-6l2,0v5c0,1.1,0.9,2.1,2.1,2.1h3.9
	c1.1,0,2.1-0.9,2.1-2.1V11.5C24,9.3,22.2,7.5,20,7.5z M2,22l0-5.3l0.1-2.1L2.9,2h1.2l2.1,18.2l2-0.2l-0.7-6.1l4.5-3V15l0,0l0,1v6H2z
	 M22,21.9C22,22,22,22,22,21.9L18.1,22c0,0-0.1,0-0.1-0.1v-5l0,0l0-1v-4.4c0-1.1,0.9-2,2-2s2,0.9,2,2V21.9z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

Refinery.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

Refinery.defaultProps = {
  size: "medium",

  className: ""
};

export default Refinery;
