import { UserRole } from './enums/UserRole';

export default class UserInfo {
	userEmail: string;

	userId: string;

	roles: UserRole[];

	isAdmin!: boolean;

	displayName: string;

	myL3Orgs: string[];

	constructor(obj: any) {
		Object.assign(this, obj);
	}

	isInRole(role: UserRole) {
		return this.roles?.some((assignedRole) => assignedRole === role || assignedRole === UserRole.SuperAdmin);
	}

	isInAnyRole(roles: UserRole[]) {
		return roles.some(role => this.isInRole(role));
	}
}