import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FileAudioIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7 10H6V6.09a.5.5 0 0 1 .4-.49l3-.591a.5.5 0 0 1 .6.491V9H9V6.107L7 6.5z"
          fill="currentColor"
        />
        <path
          d="M6 11a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1zM9 10a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1z"
          fill="currentColor"
        />
        <path
          d="M12.5 15h-9A1.5 1.5 0 0 1 2 13.5v-11A1.5 1.5 0 0 1 3.5 1h6.35a1.51 1.51 0 0 1 1.063.442l2.65 2.658A1.491 1.491 0 0 1 14 5.163V13.5a1.5 1.5 0 0 1-1.5 1.5zm-9-13a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V5.163a.5.5 0 0 0-.146-.353L10.2 2.147A.5.5 0 0 0 9.85 2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M20 30.5h-2V18.777a1.51 1.51 0 0 1 1.217-1.477l7.978-1.766A1.494 1.494 0 0 1 29 16.994V28.5h-2V17.624l-7 1.549zm-.354-11.249h-.02z"
          fill="currentColor"
        />
        <path
          d="M38 45H8a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h20.343a4.968 4.968 0 0 1 3.536 1.464l7.656 7.657A4.968 4.968 0 0 1 41 15.657V42a3 3 0 0 1-3 3zM8 5a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V15.657a3.02 3.02 0 0 0-.879-2.121l-7.656-7.658A2.982 2.982 0 0 0 28.343 5z"
          fill="currentColor"
        />
        <path
          d="M16.5 34a3.5 3.5 0 0 1 0-7H19a1 1 0 0 1 1 1v2.5a3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5V29zM25.5 34a3.5 3.5 0 0 1 0-7H28a1 1 0 0 1 1 1v2.5a3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5V29z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M10 17H8V9a1.893 1.893 0 0 1 1.83-1.986l3.928-.984a1.907 1.907 0 0 1 1.558.384A1.953 1.953 0 0 1 16 8v8h-2V8.03l-3.758.94c-.046.011-.16.028-.207.033h-.027z"
          fill="currentColor"
        />
        <path
          d="M8 19a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zM14 18a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2z"
          fill="currentColor"
        />
        <path
          d="M19.875 24H4.125A2.115 2.115 0 0 1 2 21.9V2.1A2.115 2.115 0 0 1 4.125 0h10.318a3.255 3.255 0 0 1 2.29.93l4.307 4.211a3.2 3.2 0 0 1 .96 2.27V21.9a2.115 2.115 0 0 1-2.125 2.1zM4.125 2A.115.115 0 0 0 4 2.1v19.8a.115.115 0 0 0 .125.1h15.75a.115.115 0 0 0 .125-.1V7.411a1.169 1.169 0 0 0-.358-.841l-4.307-4.211A1.283 1.283 0 0 0 14.443 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FileAudioIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FileAudioIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FileAudioIcon;
