import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FaceNeutralIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M6.5 8A1.5 1.5 0 1 1 8 6.5 1.5 1.5 0 0 1 6.5 8zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM10.5 8A1.5 1.5 0 1 1 12 6.5 1.5 1.5 0 0 1 10.5 8zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM5 10h7v1H5z"
          fill="currentColor"
        />
        <path
          d="M8.5 16A7.5 7.5 0 1 1 16 8.5 7.508 7.508 0 0 1 8.5 16zm0-14A6.5 6.5 0 1 0 15 8.5 6.508 6.508 0 0 0 8.5 2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 45a21 21 0 1 1 21-21 21.024 21.024 0 0 1-21 21zm0-40a19 19 0 1 0 19 19A19.022 19.022 0 0 0 24 5z"
          fill="currentColor"
        />
        <path
          d="M17 23a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM31 23a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM13 29h22v2H13z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M6 15h11v2H6z" />
        <path
          d="M11.5 24A11.5 11.5 0 1 1 23 12.5 11.513 11.513 0 0 1 11.5 24zm0-21a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 11.5 3z"
          fill="currentColor"
        />
        <path
          d="M8 11a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zM15 11a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FaceNeutralIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FaceNeutralIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FaceNeutralIcon;
