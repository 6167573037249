import * as React from 'react';
import SwaggerUI from 'swagger-ui-react';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';

import 'swagger-ui-react/swagger-ui.css';
import './SwaggerHome.css'
import ApiClient from '../../services/ApiClient';
import { ProgressBar } from 'react-unity';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';

interface SwaggerHomeState {
	spec: object;
}

export default class SwaggerHome extends React.Component<{}, SwaggerHomeState> {
	apiClient: ApiClient;
	
	constructor(props) {
		super(props);
		this.apiClient = new ApiClient();
		this.state = {
			spec: null
		};
	}

	async componentDidMount() {
		const spec = await this.apiClient.request<object>('GET', '/swagger/v1/swagger.json');
		this.setState({
			spec
		});
	}


	render() {
		return (
			<SectionHomeWrapper title="API Documentation">
				{(this.state.spec) ? 
					<SwaggerUI
						spec={this.state.spec}
						docExpansion="none"
						requestInterceptor={request => {
							return request;
						}}
						defaultModelExpandDepth={-1}
						plugins={[

						]}
						supportedSubmitMethods= {authenticatedUser.isInRole(UserRole.SSPDeveloper) ? ["get","put","post","patch","delete"] : ["get"] }
					/>
					:
					<ProgressBar indeterminate hideValueLabel label="Loading data..." />}
				
			</SectionHomeWrapper>
		);
	}
}