/* eslint-disable react/prefer-stateless-function */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  InfoIcon,
  CircleCheckIcon,
  WarningIcon,
  CircleExclamationMarkIcon
} from "../Icons";

function getIcon(variant) {
  switch (variant) {
    case "error":
      return (
        <CircleExclamationMarkIcon className="em-c-alert__icon" size="small" />
      );
    case "warning":
      return <WarningIcon className="em-c-alert__icon" size="small" />;
    case "success":
      return <CircleCheckIcon className="em-c-alert__icon" size="small" />;
    default:
      return <InfoIcon className="em-c-alert__icon" size="small" />;
  }
}

function getClass(variant) {
  switch (variant) {
    case "error":
      return "em-c-alert--error";
    case "warning":
      return "em-c-alert--warning";
    case "success":
      return "em-c-alert--success";
    default:
      return "";
  }
}

/** A message that informs or warns a user without interrupting their current flow */
const Alert = props => {
  const { variant, onClick, onClose, className, children, ...other } = props;

  return (
    <div
      className={clsx("em-c-alert", getClass(variant), className)}
      role="alert"
      onClick={onClick}
      {...other}
    >
      {getIcon(variant)}
      <div className="em-c-alert__body">{children}</div>
      <div className="em-c-alert__actions">
        <button
          type="button"
          className="em-c-text-btn"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

Alert.propTypes = {
  variant: PropTypes.oneOf(["default", "success", "warning", "error"]),
  /** message that will appear in the alert */
  children: PropTypes.node,
  /** The function that will be called on button click */
  onClick: PropTypes.func,
  /** The function that will be called when close it hit */
  onClose: PropTypes.func,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Alert.defaultProps = {
  variant: "default",
  children: null,
  onClick: () => {},
  onClose: () => {},
  className: ""
};

export default Alert;
