import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SkeletonInput from "./SkeletonInput";
import "./Skeleton.css";

function getAnimationClass(animation) {
  switch (animation) {
    case "pulse":
      return "em-c-skeleton--pulse";
    case "wave":
      return "em-c-skeleton--wave";
    default:
      return "";
  }
}

function getColorClass(color) {
  switch (color) {
    case "main":
      return "em-c-skeleton--main";
    case "accent":
      return "em-c-skeleton--accent";
    case "action":
      return "em-c-skeleton--action";
    case "caution":
      return "em-c-skeleton--caution";
    case "positive":
      return "em-c-skeleton--positive";
    case "negative":
      return "em-c-skeleton--negative";
    default:
      return "";
  }
}

function getAlignClass(align) {
  switch (align) {
    case "center":
      return "em-c-skeleton--align-center";
    case "right":
      return "em-c-skeleton--align-right";
    default:
      return "";
  }
}

/**
 * A skeleton loader to be used as a placeholder while content loads. You Should use these in your components using a loading boolean to swap them out with the content themselves, do not make entire new components for skeletons, build them into your component.
 *
 * @visibleName Skeleton
 * */
const Skeleton = props => {
  const {
    animation,
    circle,
    height,
    width,
    className,
    children,
    color,
    align,
    ...other
  } = props;

  const style = props.style || {};

  if (width !== null) {
    style.width = width;
  }

  if (height !== null) {
    style.height = height;
  }
  return (
    <div
      style={style}
      className={clsx(
        "em-c-skeleton",
        { "em-c-skeleton--circle": circle },
        getAnimationClass(animation),
        getColorClass(color),
        getAlignClass(align),
        className
      )}
      {...other}
    >
      &zwnj;
    </div>
  );
};

Skeleton.Input = SkeletonInput;

Skeleton.propTypes = {
  /** choose animation type for the loader */
  animation: PropTypes.oneOf(["pulse", "wave", "none"]),
  /** Change the shape of the skeleton loader */
  circle: PropTypes.bool,
  /** Set width manually */
  width: PropTypes.string,
  /** Set height manually */
  height: PropTypes.string,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Theme color */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "positive",
    "caution",
    "error",
    "default",
    "black"
  ]),
  /** Component Direction */
  align: PropTypes.oneOf(["left", "center", "right"]),
  /** React component children */
  children: PropTypes.node
};

Skeleton.defaultProps = {
  animation: "pulse",
  circle: false,
  height: null,
  width: null,
  className: "",
  color: "default",
  align: "left",
  children: null
};

export default Skeleton;
