import * as React from 'react';
import {
	Field as UnityField,
	AlertVariant,
	Typeahead,
	TextPassage,
	InfoIcon
} from 'react-unity';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { useState, useEffect, useRef } from 'react';
import { Validation } from '../../../utils/Validation';
import ApplicationLookupObject from '../../../models/ApplicationLookupObject';
import ApplicationsService from '../../../services/ApplicationsService';

interface ApplicationLookupProps extends FieldHandler<ApplicationLookupObject[]> {
	id?: string;
	setAppToGrant: (app: ApplicationLookupObject) => any
}

interface ApplicationLookuptState {
	typeSelected: string;
	searchText: string;
	searching: boolean;
	appSearchResults: ApplicationLookupObject[];
	retrievedResults: boolean;
	issueAlert: {
		variant: AlertVariant;
		message: string;
	};
}

const ApplicationLookup = (props: ApplicationLookupProps, homeState: ApplicationLookuptState) => {

	const appService = new ApplicationsService();
	const [fieldLabel] = useState<string>("Application's Name");
	const [searching, setSearching] = useState<boolean>(false);
	const [appSearchResults, setAppSearchResults] = useState<ApplicationLookupObject[]>([]);
	const [retrievedResults, setRetrievedResults] = useState<boolean>(false);
	const searchText = useRef<string>('');
	const typeSelected = useRef<string>('');


	const getInitialState = () => {
		searchText.current = '';
		setSearching(false);
		setAppSearchResults(null);
		setRetrievedResults(false);
		typeSelected.current = '';
	};

	const resetState = () => {
		getInitialState();
	};

	const searchApplicationInfo = async () => {
		setAppSearchResults([]);
		const searchString = searchText.current;
		if (searchString?.length <= 2)
			return;
		setSearching(true);
		try {
			const appInfo = await appService.applicationLookup(searchString);
			setAppSearchResults(appInfo);
		} catch (error) {
			const HTTP_NOT_FOUND_ERROR_CODE = 404;
			if (error.status !== HTTP_NOT_FOUND_ERROR_CODE) {
				console.log('An unexpected error occurred while retrieving data from Application Repository.');

			}
		}
		setSearching(false);
		setRetrievedResults(true);
	}

	const isValid = (): boolean => {
		if (!props.validation) return true;
		return new Validation(props.validation).assert(props.value);
	}

	const handleTextChange = async (e) => {
		searchText.current = e.target.value;
		setAppSearchResults([]);
		setRetrievedResults(false);
		searchApplicationInfo();
	};

	return (
		<> {props.disabled}
			{!props.disabled &&
				<UnityField>
					<UnityField>
						<TextPassage>
							<>
								<div id="text-passage">
									<InfoIcon className="info-icon" />
									Please note:
								</div>
								This app will have Credential Administrator role upon yours
							</>
						</TextPassage>
					</UnityField>
					<UnityField.Label>
						{fieldLabel}
					</UnityField.Label>
					<Typeahead
						className="em-u-width-100"
						onChange={(e, value) => {
							const obj = appSearchResults.find(o => o.id === value);
							resetState();
							searchText.current = obj?.displayName;
							setRetrievedResults(true);
							props.value[0] = obj;
							props.setAppToGrant(obj);
							setAppSearchResults([]);
						}}
						fieldProps={{
							label: '',
							error: !isValid(),
							note: '',
						}}
						inputProps={{
							onChange: (e) => handleTextChange(e),
							placeholder: "Application's Name",
							value: searchText.current,
							loading: searching,
						}}
					>
						{retrievedResults && !appSearchResults?.length ? (
							<Typeahead.Item value={0} onClick={() => { }}>
								<Typeahead.Item.Suggestion aria-disabled={true}>
									<i>Object was not found</i>
								</Typeahead.Item.Suggestion>
							</Typeahead.Item>
						) : (
							appSearchResults?.map((app) => (
								<Typeahead.Item value={app.id} key={app.id}>
									<Typeahead.Item.Suggestion>
										<span>{app.displayName}</span>
									</Typeahead.Item.Suggestion>
								</Typeahead.Item>
							))
						)}
					</Typeahead>
				</UnityField>
			}
		</>
	);
}

export default ApplicationLookup;