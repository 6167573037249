import * as React from 'react';
import { Tooltip as UnityTooltip, TooltipProps as UnityTooltipProps, CircleQuestionMarkFilledIcon } from 'react-unity';

interface TooltipProps extends UnityTooltipProps {
	content?: any;
}

export default class Tooltip extends React.Component<TooltipProps> {
	render() {
		return (
			<UnityTooltip
				{...this.props}
			>
				<UnityTooltip.Passage>
					{this.props.content || <CircleQuestionMarkFilledIcon size='small' />}
				</UnityTooltip.Passage>
				<UnityTooltip.Content>
					{this.props.children}
				</UnityTooltip.Content>
			</UnityTooltip>
		);
	}
}
