import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import LinkListItem from "./LinkListItem";

function getSize(size) {
  switch (size) {
    case "small":
      return "em-c-link-list--small";
    default:
      return "";
  }
}

/**
 * A vertically-oriented list, inteded to route you to other pages
 *
 * @visibleName Link list
 * */
const LinkList = props => {
  const { size, bulleted, icons, color, className, children, ...other } = props;

  return (
    <ul
      className={clsx(
        "em-c-link-list",
        getSize(size),
        {
          "em-c-link-list--bulleted": bulleted,
          "em-c-link-list--has-icons": icons
        },
        className
      )}
      {...other}
    >
      {React.Children.toArray(children).map(c =>
        React.cloneElement(c, {
          color
        })
      )}
    </ul>
  );
};

LinkList.Item = LinkListItem;

LinkList.propTypes = {
  /** Controls the size of the bullet points and icons */
  size: PropTypes.oneOf(["default", "small"]),
  /** Adds bullet points */
  bulleted: PropTypes.bool,
  /** Formats the list for icons */
  icons: PropTypes.bool,
  /** Theme color of the list items */
  color: PropTypes.oneOf(["default", "main", "accent"]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

LinkList.defaultProps = {
  bulleted: false,
  icons: false,
  color: "default",
  size: "default",
  className: "",
  children: null
};

export default LinkList;
