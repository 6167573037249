import * as React from 'react';
import { useState } from 'react';
import { 
	Field,
	Table,
	Toolbar,
	TextPassage, 
	Button, 
	CheckOutDoc2Icon,
	Container, 
	Alert
} from 'react-unity';
import BulkUploadModal from './components/BulkUploadModal';
import Paginator from '../common/tables/Paginator';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import AADAppsImportProps from './AADAppsImportTableRow';
import '../common/wrappers/SectionHomeWrapper.css';
import { LinkTree, LinkTreeItem } from '../common/LinkTree';

interface AADAppsImportProps
{ 
	apps: [];
	onChange: (apps: []) => any;
}

const AADAppsImport = ({linkTreeText, linkTreeRedirectTo, title, applicationsService}, {apps, onChange} : AADAppsImportProps) => {

	const [filteredApplications, setFilteredApplications] = useState<[]>([]);
	const [appsOnPage, setAppsOnPage] = useState<[]>([]);
	const [submissionAlert, setSubmissionAlert] = useState<AlertBanner>({
														visible: false,
														text: ''
													});
	const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState<boolean>(false);
	const [uploadingApps, setUploadingApps] = useState<boolean>(false);

	const handleBulkUpload = (applications: []) => {
		setBulkUploadModalVisible(false);
		setFilteredApplications(applications);
		onChange([...apps, ...applications]);
	};

	const importApplications = async () => {
		try {
			setSubmissionAlert({
					visible: true,
					text: 'Importing Applications'
			});
			setUploadingApps(true);
			
			await applicationsService.import(filteredApplications);
			setSubmissionAlert({
				visible: true,
				text: 'Applications queued for upload. You will receive a notification via email once the process has finished.',
				variant: 'success'
			});
			setUploadingApps(false);
		} catch(e) {
			setSubmissionAlert({
				visible: true,
				text: e.response?.data.message || 'An error occurred while importing the applications. Error message: ' + e,
				variant: 'error'
			});
		}
	};

	const showBulkModal = async () => {
		setBulkUploadModalVisible(true);
	};

	const getLinkTree = (): LinkTreeItem[] => {
		return [
			{ text: `${linkTreeText}`, to: `${linkTreeRedirectTo}` },
		];
	};


	return (
		<>
			<Container className="em-u-max-width-layout em-u-margin-top-double em-u-margin-bottom-double">
				<LinkTree links={getLinkTree()} />
				<TextPassage>
					<h2 className="em-u-margin-top-double">
						{
							title
						}
					</h2>
				</TextPassage>
				<TextPassage>
						<p>
					Here you can process the data input from the received csv application file. You can select the file that contains the information to insert, 
					the file format is validated, it is processed following a defined logic and finally the data is stored in the platform&apos;s database.
						</p>
				</TextPassage>
				{submissionAlert.visible && (
					<Alert
						variant={submissionAlert.variant}
					>
						{submissionAlert.text}
					</Alert>
				)}
				<div>
				<Field>
					<Field.Label>
						File Data (preview):
					</Field.Label>
					<Field.Body>
					<Table
							header={
								<Toolbar>
									<Toolbar.Item right>
										<Button
											variant="primary"
											size="small"
											disabled={false}
											onClick={showBulkModal}
										>
											<CheckOutDoc2Icon size="small" />
											<span>Bulk Upload</span>
										</Button>
									</Toolbar.Item>
								</Toolbar>							
							}						
							footer={
								<Paginator
									data={filteredApplications}
									onPageChange={(apps: any) => {
										setAppsOnPage(apps);
									}}
								/>
							}
						>
							<Table.Head>
								<Table.Head.Row>
									<Table.Head.Cell > Application Id </Table.Head.Cell>
									<Table.Head.Cell > Owner UPN </Table.Head.Cell>
									<Table.Head.Cell > Environment </Table.Head.Cell>
									<Table.Head.Cell > Expiration Date </Table.Head.Cell>
									<Table.Head.Cell > DBM Id </Table.Head.Cell>
								</Table.Head.Row>
							</Table.Head>
							<Table.Body>
								{appsOnPage.map((app) => (
									<AADAppsImportProps
										application={app}
									/>
								))}
							</Table.Body>
						</Table>
					</Field.Body>
					</Field>
				</div>
				{bulkUploadModalVisible && <BulkUploadModal
					applicationService={applicationsService}
					visible={bulkUploadModalVisible}
					onSuccess={handleBulkUpload}
					onCancel={(ev) => {
						setBulkUploadModalVisible(false);
					}}
				/>}
			<Button
				variant="primary"
				size="large"
				loading={uploadingApps}
				disabled={!filteredApplications?.length || uploadingApps}
				onClick={importApplications}
			>
				<span>Upload</span>
			</Button>
			</Container>
		</>
	);
}

export default AADAppsImport;