import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretRightIcon } from "../../../../Icons";

/**  is the a dropdown footer that can link to a new page. */
const HorizontalHeaderNavDropdownFooter = props => {
  const { className, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-primary-nav__subitem",
        "em-c-primary-nav__subitem-footer",
        className
      )}
      {...other}
    >
      <div className="em-c-primary-nav__sublink">
        {children}
        <CaretRightIcon size="small" className="em-c-primary-nav__icon" />
      </div>
    </div>
  );
};

HorizontalHeaderNavDropdownFooter.propTypes = {
  /** Text shown  */
  text: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  children: PropTypes.node
};

HorizontalHeaderNavDropdownFooter.defaultProps = {
  text: "link",
  className: "",
  children: null
};

export default HorizontalHeaderNavDropdownFooter;
