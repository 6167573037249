import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowLeftIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M3.5 8h10v1h-10z" />
        <path
          d="M4.824 13.039l-3.5-3.8A1.167 1.167 0 0 1 1.313 7.7l3.511-3.815.736.677-3.5 3.8a.16.16 0 0 0 .009.2l3.491 3.799z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M12 23h33v2H12z" />
        <path
          d="M17.586 36.414L7.293 26.121a3 3 0 0 1 0-4.243l10.293-10.292L19 13 8.707 23.293a1 1 0 0 0 0 1.414L19 35z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19.45 9.751l-7.109-6.092-7.108 6.092-1.3-1.519L11.04 2.14a1.989 1.989 0 0 1 2.6 0l7.108 6.092z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11.341 6.341h2v16h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowLeftIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowLeftIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowLeftIcon;
