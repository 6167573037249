import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OilDrop = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M15.8,2.1C15.7,2,15.5,2,15.4,2l-4,1.1l0.3,1l3.3-1V13h1V2.5C16,2.3,15.9,2.2,15.8,2.1z"
          />
          <path
            fill="currentColor"
            d="M7.2,5.9l-2.3,6.9l0.9,0.3l0.3-0.8l4.7-3.5l1.4,4.3l0.9-0.3l-2.3-6.9l0,0C10.9,5.6,11,5.3,11,5
		c0-1.1-0.9-2-2-2C7.9,3,7,3.9,7,5C7,5.3,7.1,5.6,7.2,5.9L7.2,5.9z M6.6,10.7l1.3-4C8.3,6.9,8.6,7,9,7c0.4,0,0.7-0.1,1-0.3l0.4,1.2
		L6.6,10.7z M9,4c0.6,0,1,0.4,1,1S9.6,6,9,6C8.4,6,8,5.6,8,5S8.4,4,9,4z"
          />
          <rect x="2" y="14" fill="currentColor" width="14" height="1" />
          <path
            fill="currentColor"
            d="M2,13h1v-2.9c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0l0.7-0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.4
		L4.1,6.6l2.6-0.8l-0.3-1L3.8,5.6L3.2,3.4C3.1,3.1,2.8,3,2.6,3c-0.8,0.2-1,0.2-1.5,0.4c-1,0.3-1.4,1.5-0.9,3.1c0.3,1.1,1,2.3,1.8,3
		V13z M1.2,6.3C0.9,5.4,0.9,4.5,1.4,4.4c0.3-0.1,0.6-0.2,1-0.3L3.7,9L3.4,9.1C2.7,9.3,1.6,7.9,1.2,6.3z"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M46,41v-2.6c0-0.8-0.6-1.4-1.4-1.4h-0.8L44,8c0-0.3-0.2-0.6-0.4-0.8C43.3,7,43,6.9,42.7,7.1l-11.9,4.4
		l0.7,1.9L42,9.4L41.8,37h-0.4c-0.8,0-1.4,0.6-1.4,1.4V41H10v-2.6c0-0.8-0.6-1.4-1.4-1.4H8v-8.1c0.9,0.6,1.7,0.9,2.6,0.9
		c0.3,0,0.7-0.1,1-0.2l0.8-0.3c0.3-0.1,0.5-0.2,0.9-0.3c0.3-0.1,0.5-0.3,0.6-0.5c0.1-0.2,0.1-0.5,0-0.8L11.1,20l9.2-3.5l-0.7-1.9
		l-9.2,3.5l-2.5-6.5c-0.2-0.5-0.7-0.8-1.2-0.6c-1.8,0.6-2.4,0.8-3.6,1.2c-2.2,0.9-2.7,3.8-1.3,7.8c0.9,2.4,2.4,5.1,4.2,7V37H5.4
		C4.6,37,4,37.6,4,38.4V41H3v2h44v-2H46z M3.8,14.1c0.9-0.3,1.4-0.5,2.6-0.9l5.4,14.2L11,27.7c-1.9,0.6-5.6-3.5-7.3-8.3
		C2.9,17.1,2.6,14.6,3.8,14.1z M42,39h2v2h-2V39z M6,39h2v2H6V39z"
          />
          <path
            fill="currentColor"
            d="M15.8,38.7l1.9,0.6l0.3-1l13.6-8.7l2.8,9.8l1.9-0.5l-6.4-22.6c0.1-0.4,0.2-0.8,0.2-1.2c0-2.2-1.8-4-4-4
		c-2.2,0-4,1.8-4,4c0,0.4,0.1,0.8,0.2,1.2L15.8,38.7z M28.4,18.1l0.3,1.2l-7,5.9l2-7.1c0.7,0.5,1.5,0.8,2.3,0.8
		C26.9,18.9,27.7,18.6,28.4,18.1z M18.8,35.3l1.8-6.3h8L18.8,35.3z M22.7,27l6.6-5.5l1.6,5.5H22.7z M26.1,12.9c1.1,0,2,0.9,2,2
		s-0.9,2-2,2c-1.1,0-2-0.9-2-2S24.9,12.9,26.1,12.9z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M23.6,3.2C23.3,3,23,3,22.7,3l-5,1.5l0.6,1.9L22,5.4V19h2V4C24,3.7,23.8,3.4,23.6,3.2z"
          />
          <path
            fill="currentColor"
            d="M7.7,18.6l1.9,0.6l0.3-0.9l6.4-5.3l2.1,6.2l1.9-0.6L16.8,8.1C16.9,7.8,17,7.4,17,7c0-1.7-1.3-3-3-3
		s-3,1.3-3,3c0,0.4,0.1,0.8,0.2,1.1L7.7,18.6z M11.1,14.8l1.7-5C13.2,9.9,13.6,10,14,10c0.4,0,0.8-0.1,1.2-0.3l0.4,1.3L11.1,14.8z
		 M14,6c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S13.4,6,14,6z"
          />
          <rect x="2" y="20" fill="currentColor" width="22" height="2" />
          <path
            fill="currentColor"
            d="M2,14v5h2v-3.5c0.3,0.1,0.7,0.2,1,0.2c0.2,0,0.4,0,0.6-0.1l1.1-0.3c0.3-0.1,0.5-0.2,0.6-0.5
		s0.2-0.5,0.1-0.8l-1-3.9l3.8-1.1L9.7,7.3L5.9,8.3L5.1,5.5C5,4.9,4.4,4.6,3.9,4.8C2.8,5,2.4,5.1,1.7,5.3c-1.6,0.5-2.2,2.4-1.5,4.9
		C0.5,11.5,1.2,12.9,2,14L2,14z M2.3,7.3C2.7,7.1,3,7.1,3.4,6.9l1.8,6.7l-0.1,0c-0.8,0.2-2.4-1.6-3-4C1.8,8.5,1.9,7.4,2.3,7.3z"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OilDrop.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OilDrop.defaultProps = {
  size: "medium",

  className: ""
};

export default OilDrop;
