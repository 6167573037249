import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ModalWindowHeaderCloseButton from "./ModalWindowHeaderCloseButton";
import ModalWindowHeaderTitle from "./ModalWindowHeaderTitle";

const ModalWindowHeader = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-modal__header", className)} {...other}>
      {children}
    </div>
  );
};

ModalWindowHeader.CloseButton = ModalWindowHeaderCloseButton;
ModalWindowHeader.Title = ModalWindowHeaderTitle;

ModalWindowHeader.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

ModalWindowHeader.defaultProps = {
  className: "",
  children: null
};

export default ModalWindowHeader;
