import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CompassIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 15A6.5 6.5 0 1 1 15 8.5 6.508 6.508 0 0 1 8.5 15zm0-12A5.5 5.5 0 1 0 14 8.5 5.507 5.507 0 0 0 8.5 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M8 2.5h1v2H8zM8 12.5h1v2H8zM12.5 8h2v1h-2zM2.5 8h2v1h-2zM5.954 11.545a.5.5 0 0 1-.447-.724L7.2 7.428a.5.5 0 0 1 .228-.228l3.395-1.7a.5.5 0 0 1 .671.671L9.8 9.572a.5.5 0 0 1-.224.224l-3.395 1.7a.5.5 0 0 1-.227.049zm2.07-3.521l-.952 1.9 1.9-.952.952-1.9zm1.325 1.325z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 46a21 21 0 1 1 21-21 21.024 21.024 0 0 1-21 21zm0-40a19 19 0 1 0 19 19A19.022 19.022 0 0 0 24 6z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M23 8h2v4h-2zM23 38h2v4h-2zM37 24h4v2h-4zM7 24h4v2H7zM14 36a1 1 0 0 1-.895-1.447l6.667-13.333a1 1 0 0 1 .447-.447l13.333-6.667a1 1 0 0 1 1.342 1.342l-6.666 13.333a1 1 0 0 1-.447.447l-13.334 6.667A1 1 0 0 1 14 36zm7.412-13.588l-5.176 10.352 10.352-5.176 5.176-10.352zm5.921 5.921z"
        />
        <path
          fill="currentColor"
          d="M21.586 24L23 22.586 26.414 26 25 27.414z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11 3h2v3h-2zM11 18h2v3h-2zM18 11h3v2h-3zM3 11h3v2H3zM7 18a1 1 0 0 1-.873-1.488L9.713 10.1a1.006 1.006 0 0 1 .385-.385l6.414-3.586a1 1 0 0 1 1.361 1.361l-3.586 6.41a1.006 1.006 0 0 1-.385.385l-6.414 3.588A1 1 0 0 1 7 18zm4.32-6.679L9.6 14.4l3.081-1.723L14.4 9.6zm2.094 2.093z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CompassIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CompassIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CompassIcon;
