import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TileHeadline from "./TileHeadline";
import TileDescription from "./TileDescription";
import TileList from "./TileList";

function getColorClass(color) {
  switch (color) {
    case "main":
      return "em-c-tile--main";
    case "accent":
      return "em-c-tile--accent";
    case "action":
      return "em-c-tile--action";
    case "caution":
      return "em-c-tile--caution";
    case "negative":
      return "em-c-tile--negative";
    case "positive":
      return "em-c-tile--positive";
    case "disabled":
      return "em-c-tile--disabled";
    case "inverted":
      return "em-c-tile--inverted";
    default:
      return "";
  }
}

/** Tile is a compact card combining status with a linked call to action. Almost always used as part of a tile list. */
const Tile = props => {
  const { color, className, children, ...other } = props;

  return (
    <div
      className={clsx("em-c-tile", getColorClass(color), className)}
      {...other}
    >
      {children}
    </div>
  );
};

Tile.Headline = TileHeadline;
Tile.Description = TileDescription;
Tile.List = TileList;

Tile.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Change the color of the tile */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "caution",
    "negative",
    "positive",
    "disabled",
    "inverted"
  ]),
  /** React component children */
  children: PropTypes.node
};

Tile.defaultProps = {
  color: "",
  className: "",
  children: null
};

export default Tile;
