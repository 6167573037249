import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { BanIcon, LockIcon, CircleCheckIcon, WarningIcon } from "../Icons";
import Loader from "../Loader";

/** Input is an input with unity styling and built in styling for different states such as error, valid, invalid, read-only, and disabled. */
const Input = props => {
  const {
    fullWidth,
    type,
    name,
    id,
    value,
    checked,
    onChange,
    disabled,
    readOnly,
    placeholder,
    valid,
    error,
    className,
    icon,
    loading,
    children,
    ...other
  } = props;

  return (
    <div className={clsx({ "em-u-width-100": fullWidth })}>
      <input
        type={type}
        name={name}
        id={id}
        className={clsx("em-c-input", "em-js-input", className)}
        placeholder={placeholder}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        {...other}
      />
      {icon && disabled ? (
        <BanIcon className="em-c-field__icon" size="medium" />
      ) : icon && readOnly ? (
        <LockIcon className="em-c-field__icon" size="medium" />
      ) : icon && valid ? (
        <CircleCheckIcon className="em-c-field__icon" size="medium" />
      ) : icon && error ? (
        <WarningIcon className="em-c-field__icon" size="medium" />
      ) : icon && loading ? (
        <div
          style={{
            bottom: 12,
            cursor: "default",
            position: "absolute",
            right: 14,
            height: "1.5rem",
            width: "1.5rem"
          }}
        >
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

Input.propTypes = {
  /** the value that the input contains */
  value: PropTypes.any,
  /** if the input is checked or not */
  checked: PropTypes.bool,
  /** Function that is triggered when users type */
  onChange: PropTypes.func,
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** Name for accessibility reasons */
  name: PropTypes.string,
  /** Id for accessibility reasons */
  id: PropTypes.string,
  /** Warning for when the state is invalid */
  type: PropTypes.oneOf([
    "text",
    "password",
    "email",
    "number",
    "checkbox",
    "radio",
    "file",
    "date",
    "tel",
    "url",
    "color",
    "search"
  ]),
  /** If the field is valid or not */
  valid: PropTypes.bool,
  /** If the field is error or not */
  error: PropTypes.bool,
  /** If the field is read-only or not */
  readOnly: PropTypes.bool,
  /** if the field is disabled or not */
  disabled: PropTypes.bool,
  /** if the field is loading or not */
  loading: PropTypes.bool,
  /** icon is bool to show the icon or not based on state (disabled.readonly,valid,error) */
  icon: PropTypes.bool,
  /** fullwidth determines if you want the input to span 100% of the space or only take up the space it requires */
  fullWidth: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Input.defaultProps = {
  valid: false,
  error: false,
  readOnly: false,
  disabled: false,
  loading: false,
  icon: true,
  fullWidth: true
};

export default Input;
