import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretDownIcon } from "../../../Icons";
import HorizontalHeaderNavDropdownItem from "./HorizontalHeaderNavDropdownItem";
import HorizontalHeaderNavDropdownFooter from "./HorizontalHeaderNavDropdownFooter";

/** NavDropdown creates a dropdown to put NavDropdownItems in */
const HorizontalHeaderNavDropdown = props => {
  const { className, open, text, children, onFocusToggle, ...other } = props;
  const [isOpen, setIsOpen] = useState(open || false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // When the component mounts, add the Unity JS and Mouse Down Event
    if (!document.body.classList.contains("em-js")) {
      document.body.classList.add("em-js");
    }
    document.addEventListener("mousedown", handleClickOutside);

    // When the component unmounts, remove the Unity JS and Mouse Down Event
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (!dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  function handleClick() {
    setIsOpen(prev => !prev);
    onFocusToggle();
  }

  return (
    <li ref={dropdownRef} className="em-c-primary-nav__item" {...other}>
      <span
        className={clsx(
          "em-c-primary-nav__link",
          "em-c-primary-nav__link--has-children",
          "em-no-outline",
          { "em-is-active": isOpen },
          "em-u-clickable",
          className
        )}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyPress={handleClick}
      >
        {text}
        <CaretDownIcon size="small" className="em-c-primary-nav__icon" />
      </span>
      <div
        className={clsx("em-c-primary-nav__sublist", {
          "em-is-active": isOpen
        })}
      >
        <ul>{children}</ul>
      </div>
    </li>
  );
};

HorizontalHeaderNavDropdown.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Text shown  */
  text: PropTypes.node,
  /** If the dropdown is initially expanded or not */
  open: PropTypes.bool,
  /** emits up an event when it is taking or releasing focus for the navbar */
  onFocusToggle: PropTypes.func,
  /** List items to be rendered in the Dropdown, should be EMNavDropdownItems */
  children: PropTypes.node
};

HorizontalHeaderNavDropdown.defaultProps = {
  className: "",
  text: "Link Dropdown",
  open: false,
  onFocusToggle: () => {},
  children: null
};

HorizontalHeaderNavDropdown.Item = HorizontalHeaderNavDropdownItem;
HorizontalHeaderNavDropdown.Footer = HorizontalHeaderNavDropdownFooter;

export default HorizontalHeaderNavDropdown;
