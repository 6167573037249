import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** FieldListItem is an aggregate of many field items. */
const FieldListItem = props => {
  const { className, children, variant, ...other } = props;

  return (
    <li
      className={clsx(
        "em-c-field__item",
        { "em-c-field__item--small": variant === "small" },
        className
      )}
      {...other}
    >
      {children}
    </li>
  );
};

FieldListItem.propTypes = {
  /** children is the contents */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Variant for the field item, default or small */
  variant: PropTypes.string
};

FieldListItem.defaultProps = {
  children: null,
  className: "",
  variant: "default"
};

export default FieldListItem;
