import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CircleCheckIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.813 10.7a1.155 1.155 0 0 1-.82-.339L4.806 8.172l.707-.707L7.7 9.652a.16.16 0 0 0 .227 0l6.807-6.807.707.707-6.808 6.807a1.155 1.155 0 0 1-.82.341z"
          fill="currentColor"
        />
        <path
          d="M8.5 16a7.5 7.5 0 1 1 3.45-14.165l.444.23-.459.888-.444-.229a6.517 6.517 0 1 0 3.444 4.915l-.069-.5.991-.137.068.5A7.484 7.484 0 0 1 8.5 16z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M22.418 30.647a2.99 2.99 0 0 1-2.121-.877l-6.264-6.264 1.414-1.414 6.264 6.264a1 1 0 0 0 1.414 0L43.389 8.093 44.8 9.507 24.539 29.771a2.99 2.99 0 0 1-2.121.876z"
          fill="currentColor"
        />
        <path
          d="M24 46.989a23 23 0 1 1 9.135-44.1l.918.4-.794 1.836-.918-.4A21.006 21.006 0 1 0 44.914 22.1l-.09-1 1.992-.18.09 1A22.984 22.984 0 0 1 24 46.989z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11.459 16.074a1.986 1.986 0 0 1-1.414-.586L6.56 12l1.414-1.414 3.485 3.485L21.944 3.589 23.358 5 12.873 15.488a1.986 1.986 0 0 1-1.414.586z"
          fill="currentColor"
        />
        <path
          d="M12 23.994A12 12 0 1 1 16.515.879l.927.376-.752 1.853-.927-.376A10 10 0 1 0 22 12v-1h2v1a11.99 11.99 0 0 1-12 11.994z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CircleCheckIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CircleCheckIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CircleCheckIcon;
