import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Field from "../../Field";
import Skeleton from "..";
import "../Skeleton.css";

/**
 * A skeleton loader to be used as a placeholder while form data loads.
 *
 * @visibleName Skeleton Input
 * */
const SkeletonInput = props => {
  const {
    animation,
    height,
    width,
    className,
    color,
    children,
    ...other
  } = props;

  const style = props.style || {};

  if (width !== null) {
    style.width = width;
  }

  if (height !== null) {
    style.height = height;
  }

  return (
    <Field {...other}>
      <Field.Label>
        <Skeleton
          className={clsx("em-c-skeleton-input--label", className)}
          width="33%"
          animation={animation}
          color={color}
        />
      </Field.Label>
      <Field.Body>
        <Skeleton
          className="em-c-skeleton-input--body"
          animation={animation}
          color={color}
        />
      </Field.Body>
    </Field>
  );
};

SkeletonInput.propTypes = {
  /** choose animation type for the loader */
  animation: PropTypes.oneOf(["pulse", "wave", "none"]),
  /** Set width manually */
  width: PropTypes.string,
  /** Set height manually */
  height: PropTypes.string,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Theme Color */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "positive",
    "caution",
    "error",
    "default",
    "white",
    "black"
  ]),
  /** React component children */
  children: PropTypes.node
};

SkeletonInput.defaultProps = {
  animation: "pulse",
  height: null,
  width: null,
  className: "",
  children: null,
  color: "default"
};

export default SkeletonInput;
