import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PlaneIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.115 12H11v-1h2.115a.506.506 0 0 0 .354-.146l1-1a.5.5 0 0 0 0-.707l-1-1A.5.5 0 0 0 13.115 8H3.64a.5.5 0 0 1-.458-.3L1.994 5h-.842l1.313 4.889A1.5 1.5 0 0 0 3.914 11H5v1H3.914A2.5 2.5 0 0 1 1.5 10.148L.017 4.63A.5.5 0 0 1 .5 4h1.82a.5.5 0 0 1 .458.3L3.966 7h9.149a1.491 1.491 0 0 1 1.061.439l1 1a1.5 1.5 0 0 1 0 2.121l-1 1a1.491 1.491 0 0 1-1.061.44z"
          fill="currentColor"
        />
        <path
          d="M6.425 15H4.3a.5.5 0 0 1-.434-.748L6.753 9.2l.868.5-2.46 4.3h1.264a.5.5 0 0 0 .407-.21l3.292-4.607.813.581-3.291 4.608A1.5 1.5 0 0 1 6.425 15zM8.865 7.8l-1.35-1.62A.5.5 0 0 0 7.131 6H5.894l.8 1.2-.832.555-1.32-1.98A.5.5 0 0 1 4.959 5h2.172a1.5 1.5 0 0 1 1.152.54l1.35 1.62zM13 9h2v1h-2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40.671 33H33v-2h7.671a.994.994 0 0 0 .708-.293L46.086 26l-4.707-4.707a.994.994 0 0 0-.708-.293H10.207a1 1 0 0 1-.937-.649L6.514 13H2.551l4.331 14.437A4.969 4.969 0 0 0 11.671 31H14v2h-2.329a6.957 6.957 0 0 1-6.705-4.989L.249 12.287A1 1 0 0 1 1.207 11h6a1 1 0 0 1 .937.649L10.9 19h29.771a2.98 2.98 0 0 1 2.122.879l4.707 4.707a2 2 0 0 1 0 2.828l-4.707 4.707a2.98 2.98 0 0 1-2.122.879z"
          fill="currentColor"
        />
        <path
          d="M21.207 43h-8a1 1 0 0 1-.868-1.5l8.5-14.868 1.736.992L14.93 41h5.762l10.283-14.4 1.625 1.168-10.579 14.813a1 1 0 0 1-.814.419zM27.439 20.64l-4.7-5.64h-5.663l2.963 4.445-1.664 1.109-4-6A1 1 0 0 1 15.207 13h8a1 1 0 0 1 .768.36l5 6zM39 23h7v2h-7zM35 23h3v2h-3zM31 23h3v2h-3zM27 23h3v2h-3zM23 23h3v2h-3zM19 23h3v2h-3zM15 23h3v2h-3zM11 23h3v2h-3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.5 21h-4a1 1 0 0 1-.895-1.447l3.447-6.895 1.789.895L10.118 19h1.847l3.7-5.555A1 1 0 0 1 16.5 13h2.338a.6.6 0 0 0 .422-.175.988.988 0 0 1 .125-.105l1.265-.907a1 1 0 0 0 0-1.625l-1.265-.908a.988.988 0 0 1-.125-.105.6.6 0 0 0-.422-.175H6.5a1 1 0 0 1-.895-.553L3.882 5H2.844l1.972 6.575A1.988 1.988 0 0 0 6.732 13H8v2H6.732A3.976 3.976 0 0 1 2.9 12.149L.542 4.287A1 1 0 0 1 1.5 3h3a1 1 0 0 1 .895.553L7.118 7h11.72a2.58 2.58 0 0 1 1.771.7l1.206.865a3 3 0 0 1 0 4.876l-1.206.864a2.58 2.58 0 0 1-1.771.7h-1.8l-3.7 5.555a1 1 0 0 1-.838.44z"
          fill="currentColor"
        />
        <path
          d="M15.105 8.447L13.382 5h-1.764l1.276 2.553-1.789.895-2-4A1 1 0 0 1 10 3h4a1 1 0 0 1 .895.553l2 4z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PlaneIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PlaneIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PlaneIcon;
