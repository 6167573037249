import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MicrophoneMutedIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M1.023 1.73l.707-.706L14.977 14.27l-.707.707zM8 11H6.5A1.5 1.5 0 0 1 5 9.5V8h1v1.5a.5.5 0 0 0 .5.5H8zM12 9h-1V2.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V3H5v-.5A1.5 1.5 0 0 1 6.5 1h4A1.5 1.5 0 0 1 12 2.5z"
        />
        <path
          d="M10 13H5.5A2.5 2.5 0 0 1 3 10.5V8h1v2.5A1.5 1.5 0 0 0 5.5 12H10zM13 8h1v3h-1zM3 14h9v1H3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 12h1v3H8zM7 3h3v1H7zM8 5h2v1H8z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M3.586 4L5 2.586 44.414 42 43 43.414zM31 38H20a9.01 9.01 0 0 1-9-9v-5h2v5a7.008 7.008 0 0 0 7 7h11zM39 24h2v8h-2zM11 44h26v2H11z"
        />
        <path
          fill="currentColor"
          d="M25 36h2v10h-2zM23 31h-1a5.006 5.006 0 0 1-5-5v-2h2v2a3 3 0 0 0 3 3h1zM35 26h-2V7a3 3 0 0 0-3-3h-8a3 3 0 0 0-3 3v1h-2V7a5.006 5.006 0 0 1 5-5h8a5.006 5.006 0 0 1 5 5z"
        />
        <path
          fill="currentColor"
          d="M22 10h9v2h-9zM21 6h10v2H21zM24 14h7v2h-7z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M1.085 2.5L2.5 1.086 22.914 21.5 21.5 22.914zM16 20h-6a5.006 5.006 0 0 1-5-5v-3h2v3a3 3 0 0 0 3 3h6zM19 12h2v5h-2zM5 22h14v2H5z"
        />
        <path
          fill="currentColor"
          d="M12 18h2v6h-2zM12 16.5h-1a3 3 0 0 1-3-3V12h2v1.5a1 1 0 0 0 1 1h1zM18 13h-2V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1H8V4a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3z"
        />
        <path fill="currentColor" d="M11 5h5v2h-5zM13 8h3v2h-3z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MicrophoneMutedIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MicrophoneMutedIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MicrophoneMutedIcon;
