import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { PeoplePickerContext } from "../PeoplePicker.jsx";
import PeoplePickerItemSuggestion from "./PeoplePickerItemSuggestion";

function PeoplePickerItem(props) {
  const { className, value, children, ...other } = props;
  const { onChange } = useContext(PeoplePickerContext);

  function handleItemClick(e) {
    if (onChange) {
      onChange(e, value);
    }
  }

  return (
    <li
      className={clsx("em-c-typeahead-list__item", className)}
      onClick={handleItemClick}
      {...other}
    >
      {children}
    </li>
  );
}

PeoplePickerItem.Suggestion = PeoplePickerItemSuggestion;

PeoplePickerItem.defaultProps = {
  className: ""
};

PeoplePickerItem.propTypes = {
  /** Additional classname */
  className: PropTypes.string,
  /** Unique item value */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default PeoplePickerItem;
