export type AppColumn = {
	header: string;
	cell: (app) => string;
};

export const AppColumnsLayout = [
	{
		header: 'AppGuid',
		cell: (app) => app.appGuid,
	},
	{
		header: 'OwnerUPN',
		cell: (app) => app.ownerUPN,
	},
	{
		header: 'Environment',
		cell: (app) => app.environment,
	},
	{
		header: 'ExpirationDate',
		cell: (app) => app.expirationDate,
	},
	{
		header: 'DBM id',
		cell: (app) => app.cartaIds,
	}
];

export const AppColumnsExample = [
	{
		appGuid: '00000000-0000-0000-0000-000000000000',
		ownerUPN: 'example.name@exxonmobil.com',
		environment: 'Production/Non-Production',
		expirationDate: 'MM/dd/YYYY',
		cartaIds:'APP-0000'
	}
];