import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const WatchIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.5 13A5.5 5.5 0 1 1 13 7.5 5.507 5.507 0 0 1 7.5 13zm0-10A4.5 4.5 0 1 0 12 7.5 4.5 4.5 0 0 0 7.5 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11 15H4v-3.5h1V14h5v-2.5h1V15zM11 3.5h-1V1H5v2.5H4V0h7v3.5zM15 9h-3V8h2V7h-2V6h3v3zM9.3 10.007L7 7.707V5h1v2.293L10.007 9.3l-.707.707z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M22 41a17 17 0 1 1 17-17 17.019 17.019 0 0 1-17 17zm0-32a15 15 0 1 0 15 15A15.017 15.017 0 0 0 22 9z"
          fill="currentColor"
        />
        <path
          d="M31 47H13a2 2 0 0 1-2-2v-4h2v4h18v-4h2v4a2 2 0 0 1-2 2zM33 7h-2V3H13v4h-2V3a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2zM40 27h-3v-2h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-3v-2h3a3 3 0 0 1 3 3v2a3 3 0 0 1-3 3zM29 31.914l-8-8V16h2v7.086l7.414 7.414L29 31.914zM21 10h2v4h-2zM21 35h2v4h-2zM33 23h4v2h-4zM7 23h4v2H7z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11.556 21.056a8.556 8.556 0 1 1 8.555-8.556 8.565 8.565 0 0 1-8.555 8.556zm0-15.111a6.556 6.556 0 1 0 6.555 6.555 6.563 6.563 0 0 0-6.555-6.556z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M17.278 23.889H5.833V21h2v.889h7.445V21h2v2.889zM17 4h-2V3H8v1H6V1h11v3zM13 16.414l-3-3V8h2v4.586L14.414 15 13 16.414z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

WatchIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

WatchIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default WatchIcon;
