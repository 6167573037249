import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HardwareIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 15h-14a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM2 14h13V7H2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M5 12h7v1H5zM5 10h1v1H5zM13 12h1v1h-1zM13 10h1v1h-1zM5 8h1v1H5zM3 12h1v1H3zM3 10h1v1H3zM3 8h1v1H3zM7 10h1v1H7zM7 8h1v1H7zM9 10h1v1H9zM9 8h1v1H9zM11 10h1v1h-1zM11 8h1v1h-1zM13 8h1v1h-1zM9.492 4.889l-.984-.179.451-2.479A1.5 1.5 0 0 1 10.435 1H13v1h-2.565a.5.5 0 0 0-.492.411z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M45 44H3a3 3 0 0 1-3-3V21a3 3 0 0 1 3-3h42a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3zM3 20a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V21a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M13 36h20v2H13zM5 36h4v2H5zM5 30h4v2H5zM37 36h4v2h-4zM37 30h4v2h-4zM5 24h4v2H5zM13 30h4v2h-4zM13 24h4v2h-4zM21 30h4v2h-4zM21 24h4v2h-4zM29 30h4v2h-4zM29 24h4v2h-4zM37 24h4v2h-4zM23.984 15.679l-1.968-.357 1.1-6.073A7 7 0 0 1 30.007 3.5H35v2h-4.993a5 5 0 0 0-4.919 4.106z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 23H2c-1.221 0-2-1.48-2-2.5v-10a1.452 1.452 0 0 1 .414-1.068A1.917 1.917 0 0 1 1.875 9H22a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM2.185 21H22V11H2v9.5a1.19 1.19 0 0 0 .185.5z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M7 18h10v2H7zM3 18h2v2H3zM3 15h2v2H3zM19 18h2v2h-2zM19 15h2v2h-2zM3 12h2v2H3zM7 15h2v2H7zM7 12h2v2H7zM11 15h2v2h-2zM11 12h2v2h-2zM15 15h2v2h-2zM15 12h2v2h-2zM19 12h2v2h-2zM13 7h-2V4a3.918 3.918 0 0 1 4-4h3v2h-3a1.928 1.928 0 0 0-2 2z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

HardwareIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

HardwareIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default HardwareIcon;
