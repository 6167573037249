import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretDownIcon } from "../Icons";

/** A simple section container and heading for segmenting discrete content areas */
const Section = props => {
  const {
    title,
    underline,
    variant,
    size,
    className,
    children,
    ...other
  } = props;
  const [open, setOpen] = useState(props.open || false);

  const getUnderline = underline => {
    switch (underline) {
      case "main":
        return "em-c-section__header--underline em-c-section__header--underline-main";
      case "accent":
        return "em-c-section__header--underline em-c-section__header--underline-accent";
      case "mainToAccent":
        return "em-c-section__header--underline em-c-section__header--underline-mainToAccent";
      case "accentToMain":
        return "em-c-section__header--underline em-c-section__header--underline-accentToMain";
      case "action":
        return "em-c-section__header--underline em-c-section__header--underline-action";
      case "caution":
        return "em-c-section__header--underline em-c-section__header--underline-caution";
      case "negative":
        return "em-c-section__header--underline em-c-section__header--underline-negative";
      case "positive":
        return "em-c-section__header--underline em-c-section__header--underline-positive";
      case "red":
        return "em-c-section__header--underline em-c-section__header--underline-red";
      case "blue":
        return "em-c-section__header--underline em-c-section__header--underline-blue";
      default:
        return "";
    }
  };

  const getSize = size => {
    switch (size) {
      case "large":
        return "em-c-section__title--large";
      default:
        return "em-c-section__title";
    }
  };

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  return (
    <section
      className={clsx(
        "em-c-section",
        {
          "em-c-section--split": variant === "split",
          "em-c-section--expandable": variant === "expandable",
          "em-is-closed": !open
        },
        className
      )}
      {...other}
    >
      <header
        className={clsx("em-c-section__header", getUnderline(underline))}
        onClick={() => {
          handleClick();
        }}
      >
        {variant === "expandable" && (
          <CaretDownIcon className="em-c-section__icon" />
        )}
        <h2 className={getSize(size)}>
          {title}
          {underline && <span className="em-c-section__title-underline" />}
        </h2>
      </header>
      <div className="em-c-section__body">{children}</div>
    </section>
  );
};

Section.defaultProps = {
  title: "Section",
  underline: "",
  open: false,
  variant: "default",
  size: "default",
  className: "",
  children: null
};

Section.propTypes = {
  /** title is the title of the section */
  title: PropTypes.node,
  /** Adds a styled bar underneath the title */
  underline: PropTypes.oneOf([
    "main",
    "accent",
    "mainToAccent",
    "accentToMain",
    "action",
    "caution",
    "negative",
    "positive",
    "red",
    "blue"
  ]),
  /** identifies if the expandable section will started open or closed, cloased/false by default */
  open: PropTypes.bool,
  /** Changes the view of the section */
  variant: PropTypes.oneOf(["default", "split", "expandable"]),
  /** Changes the size of the section title */
  size: PropTypes.oneOf(["default", "large"]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** The children of TagList, which should most likely only be Tags */
  children: PropTypes.node
};

export default Section;
