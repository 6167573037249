import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FilterIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.5 15.6a.5.5 0 0 1-.5-.5v-3.788l-.225-.256-3.3-3.095A1.506 1.506 0 0 1 3 6.867V2.5A1.5 1.5 0 0 1 4.5 1h8A1.5 1.5 0 0 1 14 2.5v4.367a1.506 1.506 0 0 1-.473 1.094l-3.34 3.139c-.029.037-.113.132-.187.216V13.9a.5.5 0 0 1-.243.429l-2 1.2a.5.5 0 0 1-.257.071zM4.5 2a.5.5 0 0 0-.5.5v4.367a.5.5 0 0 0 .158.365L7.5 10.367c.129.147.375.422.375.422a.5.5 0 0 1 .125.333v3.095l1-.6v-2.495a.5.5 0 0 1 .127-.333s.246-.275.337-.382l3.378-3.176A.5.5 0 0 0 13 6.867V2.5a.5.5 0 0 0-.5-.5zm5 11.9z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M20 47a1 1 0 0 1-1-1V33.093a1.036 1.036 0 0 0-.245-.67L8.473 22.1A5.1 5.1 0 0 1 7 18.523v-14.5A3.015 3.015 0 0 1 10 1h28a3.015 3.015 0 0 1 3 3.023v14.5a5.1 5.1 0 0 1-1.473 3.577L29.19 32.482a1.036 1.036 0 0 0-.19.611v8.861a1 1 0 0 1-.549.893l-8 4.046A1 1 0 0 1 20 47zM10 3a1.013 1.013 0 0 0-1 1.023v14.5a3.086 3.086 0 0 0 .89 2.165l10.337 10.383A3.077 3.077 0 0 1 21 33.093v11.28l6-3.034v-8.246a3.04 3.04 0 0 1 .718-1.963L38.11 20.688a3.086 3.086 0 0 0 .89-2.165V4.024A1.013 1.013 0 0 0 38 3H10zm18 38.954z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M14.086 19.5l1.415-1.413 6.414 6.414-1.414 1.414z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M9 24a1 1 0 0 1-1-1v-6.349l-5.1-4.932A3.026 3.026 0 0 1 2 9.581V2a2 2 0 0 1 2-2h15a2 2 0 0 1 2 2v7.581a3.02 3.02 0 0 1-.886 2.128l-5.178 5.009L15 21a1 1 0 0 1-.628.929l-5 2A1 1 0 0 1 9 24zM4 2v7.581a.991.991 0 0 0 .3.709l5.159 4.991a2.047 2.047 0 0 1 .541 1.37v4.872l3-1.2v-3.672a2 2 0 0 1 .481-1.3l5.232-5.068a1 1 0 0 0 .286-.7V2H4zm10 19z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M5.586 9L7 7.586 10.414 11 9 12.414z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FilterIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FilterIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FilterIcon;
