import * as React from 'react';
import { CheckboxDropdown, CheckboxDropdownProps } from 'react-unity';

export type CheckboxOption = { label: string; value: string, checked: boolean };

interface CheckboxFilterProps extends CheckboxDropdownProps{
	options: CheckboxOption[];
}

interface CheckboxFilterState {
	allChecked: boolean;
}

export default class CheckboxFilter extends React.Component<CheckboxFilterProps, CheckboxFilterState> {
	constructor(props) {
		super(props);
		this.state = {
			allChecked: false,
		};
	}

	setAllCheckbox = (event) => {
		event.persist();
		this.setState((prevState) => {
			return { allChecked: !prevState.allChecked };
		}, () => {
			this.props.onChange(event);
		});
	};

	handleCheckboxClick = (event) => {
		this.props.onChange(event);

		let checkedOptions = this.props.options.filter(o => o.checked);
		let totalOptions = this.props.options.length;

		this.setState({ allChecked: checkedOptions.length === totalOptions });
	};

	render() {
		const checkBoxDropdownItems = [];
		checkBoxDropdownItems.push(
			<CheckboxDropdown.Item 
				label="All"
				inputProps={
					{ 
						value: 'All',
						checked: this.state.allChecked,
						onChange: this.setAllCheckbox,
					}
				}
				key="All"
			/>
		);
		this.props.options?.forEach((option) => {
			checkBoxDropdownItems.push(
				<CheckboxDropdown.Item 
					label={option.label}
					inputProps={
						{ 
							value: option.value,
							checked: option.checked,
							onChange: this.handleCheckboxClick,
						}
					}
					key={option.value}
				/>
			);
		});
		return (
			<CheckboxDropdown
				label={this.props.label}
				buttonProps={
					{
						size: 'small',
						...this.props.buttonProps
					}
				}
			>
				{checkBoxDropdownItems}
			</CheckboxDropdown>
		);
	}
}