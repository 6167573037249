import { IValidation, Validation } from './Validation';

type TFieldModel<T> = {
	getValue: () => any;
	validation?: T
};

type FieldModelDictionary<T> = {
	[field: string]: TFieldModel<T>;
};

export interface FieldModel extends TFieldModel<IValidation> { }

export default class FormModel {
	fields: FieldModelDictionary<Validation>;

	constructor(fields: FieldModelDictionary<IValidation>) {
		Object.keys(fields).forEach(key => {
			this.addField(key, fields[key]);
		});
	}

	addField(key: string, model: FieldModel) {
		const dictionary = this.fields || {};
		dictionary[key] = {
			getValue: model.getValue
		};
		!!model.validation && (dictionary[key].validation = new Validation(model.validation));
		this.fields = dictionary;
	}

	isValid(): boolean {
		return Object.keys(this.fields).every((key) => {
			const field = this.fields[key];
			if (!field.validation) return true;
			return field.validation.assert(field.getValue());
		});
	}

	create<T>(ModelClass: (new (obj: any) => T)): T {
		const createdModel = new ModelClass({});
		const properties = Object.keys(createdModel);
		Object.keys(this.fields).forEach((key) => {
			if (properties.includes(key))
				createdModel[key] = this.fields[key].getValue();
		});

		return createdModel;
	}
}
