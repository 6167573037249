import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** TableFootCell are the cells that contain the data in the table Footer */
const TableFootCell = props => {
  const { className, children, ...other } = props;

  return (
    <th
      scope="col"
      className={clsx("em-c-table__footer-cell", className)}
      {...other}
    >
      {children}
    </th>
  );
};

TableFootCell.propTypes = {
  /** children are the contents of the TableFootCell */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TableFootCell.defaultProps = {
  children: null,
  className: ""
};

export default TableFootCell;
