import React, { useState } from 'react';
import { Button, Searchbar, Select, Toolbar } from 'react-unity';
import styled from 'styled-components';
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest';				
import { DoNotDecryptRequestsDirection } from '../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import CheckboxFilter, { CheckboxOption } from '../common/tables/CheckboxFilter';
import Filter from '../common/tables/Filter';
import { useNavigate } from 'react-router-dom';
import { WorkflowState } from '../../models/enums/WorkflowState';

interface DNDRByRequestFilterProps {
	dndrs: DoNotDecryptRequest[];				
	onFilterChange: any;
	subscriptionId: number;
	subscriptionOwner: string;
}

export type DNDRFilters = {
	scope: CheckboxOption[];
	status: CheckboxOption[];
	searchUser: string;
	orderBy: string;
};

const MyP = styled.p`
	margin-right: 8px;
	margin-bottom: auto !important;
	margin-top: auto !important;
`;

const StyledItem = styled(Toolbar.Item)`
	width: 50%;
`;

const MyLink = styled.span`
	color: #0c69b0;
	text-decoration: underline;
	cursor: pointer;
`;

const DNDRByRequestFilter = ({ dndrs, onFilterChange, subscriptionId, subscriptionOwner }: DNDRByRequestFilterProps) => {
	const navigate = useNavigate();
	const getDefaultFilters = () => {
		const defaultFilters = {
			searchUser: '',
			status: [
				{ label: 'Submitted', value: 'Submitted', checked: true },
				{ label: 'Rejected', value: 'Rejected', checked: true },
				{
					label: 'Pending for Cloud Network Approval',
					value: 'Pending for Cloud Network Approval',
					checked: true,
				},
				{
					label: 'Pending for Gateway Policy Team Approval',
					value: 'Pending for Gateway Policy Team Approval',
					checked: true,
				},
				{
					label: 'Pending for Cloud Architect Approval',
					value: 'Pending for Cloud Architect Approval',
					checked: true,
				},
				{
					label: 'Pending for Cyber Team Approval',
					value: 'Pending for Cyber Team Approval',
					checked: true,
				},
				{ label: 'Review', value: 'Review', checked: true },
				{ label: 'Awaiting Implementation', value: 'Awaiting Implementation', checked: true },
				{ label: 'Completed', value: 'Completed', checked: true },
				{ label: 'Cancelled', value: 'Cancelled', checked: true },
				{ label: 'Draft', value: 'Draft', checked: true },
			],
			scope: [
				{
					label: 'Subscription <-> ExxonMobil Network',
					value: DoNotDecryptRequestsDirection.SubscriptionToOnPrem.name,
					checked: true,
				},				
				{
					label: 'Subscription -> Internet',
					value: DoNotDecryptRequestsDirection.SubscriptionToInternet.name,
					checked: true,
				},
			],
			orderBy: 'newest',
		} as DNDRFilters;

		return defaultFilters;
	};

	const filterPORs = () => {
		let filteredRequests = [];

		const filterCallback = (dndr: DoNotDecryptRequest) => {				
			return (
				filter.filterCheckBox(
					'status',
					dndr.workflowInstance.currentWorkflowInstanceState.workflowState?.name
				) &&
				filter.filterCheckBox('scope', dndr.direction.name) &&
				(filter.filterSearchText('searchUser', dndr.workflowInstance.createdBy.displayName) ||
				filter.filterSearchText('searchUser', dndr.contactUser?.displayName) ||
				dndr.workflowInstance.currentWorkflowInstanceState.workflowState.value ===
					WorkflowState.PendingSubscriptionOwnerApproval.value
					? filter.filterSearchText('searchUser', subscriptionOwner)
					: filter.filterSearchText('searchUser', subscriptionOwner))
			);
		};

		filteredRequests = dndrs.filter(filterCallback).sort((a, b) => {
			if (filter.filters.orderBy === 'newest') {
				return a.id < b.id ? 1 : -1;
			}
			return a.id > b.id ? 1 : -1;
		});
		onFilterChange(filteredRequests);
	};
	const [filter] = useState<Filter<DNDRFilters>>(new Filter(filterPORs, getDefaultFilters()));				

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchUser');
	};

	return (
		<>
			{filter && (
				<>
					<Toolbar style={{ backgroundColor: 'white', borderTopColor: 'white' }}>
						<StyledItem>
							<Searchbar
								condensed
								buttonProps={{ onClick: clearSearchbar}}
								inputProps={{
									value: filter.filters.searchUser,
									onChange: (e) => filter.handleSearchTextChange(e, 'searchUser'),
									placeholder: 'Search all fields shown below (i.e. specific IP address, Description, etc.)',
								}}
							/>
						</StyledItem>
						<Toolbar.Item right>
							<Button.Group>
								<Button
									variant="primary"
									size="small"
									onClick={() => {
										navigate(`/subscriptions/${subscriptionId}/doNotDecryptRequests/new`);
									}}
								>
									<span>Open new request</span>
								</Button>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
					<Toolbar>
						<Toolbar.Item>
							<CheckboxFilter
								label="Status"
								onChange={(event) => filter.handleCheckboxChange(event, 'status')}
								options={filter.filters.status}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<MyLink onClick={() => filter.updateFilters(getDefaultFilters())}>Clear filters</MyLink>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button.Group>
								<MyP>Sort by</MyP>
								<Select
									style={{ marginBottom: -12 }}
									value={filter.filters.orderBy}
									onChange={(e) => filter.handleSearchTextChange(e, 'orderBy')}
								>
									<option value="newest">Newest</option>
									<option value="oldest">Oldest</option>
								</Select>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default DNDRByRequestFilter;
