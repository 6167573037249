import { Enumeration } from '../../../utils/AppEnum';

export class FeedbackCategory extends Enumeration<FeedbackCategory> {
	public static Bug = new FeedbackCategory('Bug', 0);

	public static Comment = new FeedbackCategory('Comment', 1);

	public static Question = new FeedbackCategory('Question', 2);

	public static Suggestion = new FeedbackCategory('Suggestion', 3);
}
