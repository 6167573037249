import React, { useState } from "react";
import PropTypes, { oneOfType } from "prop-types";
import clsx from "clsx";
import Field from "../Field";
import Input from "../Input";

/**
 * A field used to elicit a response from a user
 *
 * @visibleName Text input
 * */
const TextInput = props => {
  const {
    value,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    name,
    id,
    label,
    note,
    type,
    valid,
    error,
    readOnly,
    disabled,
    className,
    loading,
    inputProps,
    children,
    ...other
  } = props;

  const [isActive, setIsActive] = useState(false);

  function handleInputFocus(e) {
    if (onFocus) {
      onFocus(e);
    }
    setIsActive(true);
  }

  function handleInputLoseFocus(e) {
    if (onBlur) {
      onBlur(e);
    }
    setIsActive(false);
  }

  return (
    <Field
      className={clsx({ "em-is-active": isActive }, className)}
      error={error}
      valid={valid}
      disabled={disabled}
      readOnly={readOnly}
      {...other}
    >
      {label && <Field.Label>{label}</Field.Label>}
      <Field.Body>
        <Input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={error}
          valid={valid}
          disabled={disabled}
          readOnly={readOnly}
          loading={loading}
          onFocus={handleInputFocus}
          onBlur={handleInputLoseFocus}
          {...inputProps}
        />
      </Field.Body>
      {note && <Field.Note>{note}</Field.Note>}
    </Field>
  );
};

TextInput.propTypes = {
  /** the value that the TextInput contains */
  value: PropTypes.string,
  /** Function that is triggered when users type */
  onChange: PropTypes.func,
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** Name for accessibility reasons */
  name: PropTypes.string,
  /** Id for accessibility reasons */
  id: PropTypes.string,
  /** Label that sits above the TextInput */
  label: oneOfType([PropTypes.string, PropTypes.number]),
  /** note below the field */
  note: oneOfType([PropTypes.string, PropTypes.number]),
  /** Helps browser interpret what value the text input is displaying */
  type: PropTypes.oneOf(["text", "password", "email", "number", "tel", "url"]),
  /** If the field is valid or not */
  valid: PropTypes.bool,
  /** If the field is error or not */
  error: PropTypes.bool,
  /** If the field is read-only or not */
  readOnly: PropTypes.bool,
  /** if the field is disabled or not */
  disabled: PropTypes.bool,
  /** if the field is loading or not */
  loading: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** onFocus event for the Input component */
  onFocus: PropTypes.func,
  /** onBlur event for the Input component */
  onBlur: PropTypes.func,
  /** Props passed down to the native input element */
  inputProps: PropTypes.object
};

TextInput.defaultProps = {
  value: "",
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  label: "",
  note: "",
  type: "text",
  valid: false,
  error: false,
  readOnly: false,
  disabled: false,
  loading: false,
  inputProps: {},
  className: ""
};

export default TextInput;
