import { AlertVariant } from 'react-unity';
import FormModel from '../../utils/FormModel';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import PortOpeningRequestService from '../../services/PortOpeningRequestService';
import { IStateForm, AbstractFormState, AbstractForm } from '../common/AbstractForm';
import { TenantLabel } from '../../models/enums/TenantLabel';
import UserLookupObject from '../../models/UserLookupObject';
import PortOpeningRule from '../../models/entities/PortOpeningRule';
import { LinkTreeItem } from '../common/LinkTree';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import { Environment } from '../../models/enums/Environment';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import { Params, Location } from 'react-router-dom';

interface EndModal extends AlertBanner {
	redirectionLink?: string;
}

export interface AbstractPORFormProps {
	params?: Params<string>;
	navigate?: any;
	location?: Location;
 }


export interface IPORStateForm extends IStateForm {
	environment?: Environment;
	contact?: UserLookupObject | null;
	approver?: UserLookupObject | null;
	delegatedApprover?: UserLookupObject | null;
	hasDelegatedApprover: boolean;
	isEmergency: boolean;
	incidentNumber?: string;
	customerCoordination?: boolean;
	businessJustification: string;
	comments?: string;
	ruleList: PortOpeningRule[];
	custodianApproval: boolean;
}


export interface AbstractPORFormState<T> extends AbstractFormState<T> {
	subscription?: SubscriptionRequest;
	operationInProgress: boolean;
	endModal: EndModal;
	submissionWarned: boolean;
	submissionAlert: AlertBanner;
}

export abstract class AbstractPORForm<
	Props extends AbstractPORFormProps,
	State extends AbstractPORFormState<IPORStateForm>
	> extends AbstractForm<Props, State> {

	subscriptionRequestService: SubscriptionRequestsService;
	portOpeningRequestService: PortOpeningRequestService;
	
	constructor(props) {
		super(props);
		this.subscriptionRequestService = new SubscriptionRequestsService();
		this.portOpeningRequestService = new PortOpeningRequestService();
	}

	async getSubscription(id) {
		if (!id) return null;
		const subscription = new SubscriptionRequest(await this.subscriptionRequestService.getByIdValidateAccessPOR(id));
		return subscription;
	}

	async getSubscriptionExcludePORs(id) {
		if (!id) return null;
		const subscription = new SubscriptionRequest(await this.subscriptionRequestService.getById(id));
		return subscription;
	}

	async getSubscriptionWithPOR(subsId, id) {
		const subscription = new SubscriptionRequest(await this.subscriptionRequestService.getPORBySubscription(subsId, id));
		return subscription;
	}

	getLinkTree = (): LinkTreeItem[] => {
		return [
			{ text: 'Subscriptions', to: '/subscriptions' },
			{ text: this.state.subscription?.createdSubscriptionName, to: `/subscriptions/${this.state.subscription?.id}` },
			{ text: 'Port Opening Requests', to: `/subscriptions/${this.state.subscription?.id}/PortOpeningRequestsHistory` }
		];
	};

	initFormModel() {
		this.formModel = new FormModel({
			environment: {
				getValue: () => this.state.stateForm.environment?.value,
				validation: {
					required: () => this.state.subscription?.tenant.id === TenantLabel.ExxonMobilTest
				}
			},
			contactId: {
				getValue: () => this.state.stateForm.contact?.id,
			},
			approverId: {
				getValue: () => this.state.stateForm.approver?.id,
			},
			delegatedApproverId: {
				getValue: () => this.state.stateForm.delegatedApprover?.id,
			},
			hasDelegatedApprover: {
				getValue: () => this.state.stateForm.hasDelegatedApprover,
			},
			emergency: {
				getValue: () => this.state.stateForm.isEmergency,
				validation: {
					required: true,
				},
			},
			incidentNumber: {
				getValue: () => this.state.stateForm.incidentNumber,
				validation: {
					required: () => this.state.stateForm.isEmergency,
					rules: [
						{
							assert: () => this.state.stateForm.incidentNumber?.trim().length > 0,
							message: 'The incident number is not valid.',
						},
					],
				},
			},
			businessJustification: {
				getValue: () => this.state.stateForm.businessJustification,
				validation: {
					required: true,
					rules: [
						{
							assert: () =>
								this.state.stateForm.businessJustification?.trim().length > 4,
							message: 'Justification must contain at least 5 characters.',
						},
					],
				},
			},
			customerCoordination: {
				getValue: () => this.state.stateForm.customerCoordination
			},
			custodianApproval: {
				getValue: () => this.state.stateForm.custodianApproval,
				validation: {
					required: true,
				},
			},
			portOpeningRules: {
				getValue: () => this.state.stateForm.ruleList,
				validation: {
					required: true
				}
			},
			comments: {
				getValue: () => this.state.stateForm.comments,
			},
		});
	}

	stateFormHandler = () => this.getStateFormHandlers();

	getStateFormHandlers() {
		return {
			environment: {
				value: this.state.stateForm.environment,
				validation: this.formModel.fields.environment.validation,
				onChange: (env) => {
					this.handleStateFormChange('environment', env);
				}
			},
			contact: {
				value: this.state.stateForm.contact?.userPrincipalName,
				validation: this.formModel.fields.contactId.validation,
				onChange: (value) => {
					this.handleStateFormChange('contact', value);
				}
			},
			approver: {
				value: this.state.stateForm.approver?.userPrincipalName,
				validation: this.formModel.fields.approverId.validation,
				onChange: (value) => {
					this.setState({
						submissionWarned: false,
					});
					this.handleStateFormChange('approver', value);
				},
				higherApproval: this.state.stateForm.isEmergency
			},
			delegatedApprover: {
				value: this.state.stateForm.delegatedApprover?.userPrincipalName,
				validation: this.formModel.fields.delegatedApproverId.validation,
				onChange: (value) => {
					this.setState({
						submissionWarned: false,
					});
					this.handleStateFormChange('delegatedApprover', value);
				},
				higherApproval: this.state.stateForm.isEmergency
			},
			hasDelegatedApprover: {
				value: this.state.stateForm.hasDelegatedApprover,
				validation: this.formModel.fields.hasDelegatedApprover.validation,
				onChange: (event) => {
					this.handleStateFormChange('hasDelegatedApprover', event.target.value === 'true');
					this.handleStateFormChange('delegatedApprover', null);
				}
			},
			custodianApproval: {
				label:'Send to subscription Custodian for approval?',
				value: this.state.stateForm.custodianApproval?.toString(),
				validation: this.formModel.fields.custodianApproval.validation,
				onChange: (event) => {
					this.handleStateFormChange('custodianApproval', event.target.value === 'true');
				},
				note:'Use this to specify the subscription custodian as the approver when the subscription owner is not available.'
			},
			emergency: {
				value: this.state.stateForm.isEmergency,
				validation: this.formModel.fields.emergency.validation,
				onChange: (event) => {
					this.setState({
						submissionWarned: false,
					});
					this.handleStateFormChange(['isEmergency', 'incidentNumber'], [event.target.value === 'true', '']);
				}
			},
			incidentNumber: {
				value: this.state.stateForm.incidentNumber,
				validation: this.formModel.fields.incidentNumber.validation,
				onChange: (event) => {
					this.handleStateFormChange('incidentNumber', event.target.value);
				}
			},
			businessJustification: {
				value: this.state.stateForm.businessJustification,
				validation: this.formModel.fields.businessJustification.validation,
				onChange: (event) => {
					this.handleStateFormChange('businessJustification', event.target.value);
				}
			},
			customerCoordination: {
				label:'Customer Coordination Required?',
				options: this.formModel.fields.customerCoordination ?
				[
					{ value: 'true', text: 'Yes' },
					{ value: 'false', text: 'No' },
				]:
				this.state.stateForm.custodianApproval?
								[ {value: 'true', text: 'Yes'} ]
								:
								[ {value: 'false', text: 'No'} ],
		
				note: 'If yes, the MSP will coordinate the timing of the change with the requestor. If no, the MSP will implement the change as soon as possible.',
				value: this.state.stateForm.customerCoordination,
				validation: this.formModel.fields.customerCoordination.validation,
				onChange: (event) => {
					this.handleStateFormChange('customerCoordination', event.target.value === 'true');
				}
			},
			ruleset: {
				rules: this.state.stateForm.ruleList,
				onChange: (rules: PortOpeningRule[]) => {
					this.handleStateFormChange('ruleList', rules);
				}
			},
			comments: {
				value: this.state.stateForm.comments || '',
				validation: this.formModel.fields.comments.validation,
				onChange: (event) => {
					this.handleStateFormChange('comments', event.target.value);
				}
			}
		};
	}

	handleEndModal = (text: string, variant: AlertVariant, timeout: number, link: string) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
				redirectionLink: link
			}
		},
		() => {
			setTimeout(() => {
				this.endAndRedirect();
			}, timeout);
		});
	};

	endAndRedirect = () => {
		const link = this.state.endModal.redirectionLink;
		this.setState({
			endModal: { visible: false }
		},
		() => {
			this.props.navigate(link);
		});
	};

	setSubmissionAlertInProgress = (message) => {
		window.scroll(0, 0);
		this.setState({
			operationInProgress: true,
			submissionAlert: {
				visible: true,
				text: message
			}
		});
	};

	setSubmissionAlertError = (message) => {
		this.setState({
			submissionAlert: {
				visible: true,
				text: message,
				variant: 'error'
			}
		});
	};

	handleSubmissionAlertClose = () => {
		this.setState({
			submissionAlert: {
				visible: false
			}
		});
	};

	requiresApproverVerification = (selectedScope: string) => {
		return selectedScope !== PortOpeningRequestsDirection.SubscriptionToOnPrem.name;
	};

	// TODO: reduce with notification service
	approverLevelVerified = async (): Promise<boolean> => {
		if (!this.state.submissionWarned) {
			try {
				this.setSubmissionAlertInProgress('Verifying...');
				const response = await this.portOpeningRequestService.verifyApproverLevel(
					this.state.stateForm?.approver?.userPrincipalName, this.state.stateForm?.isEmergency);
				
				if (!response.allowed) {
					this.setState({
						operationInProgress: false,
						submissionWarned: true,
						submissionAlert: {
							visible: true,
							text: 'The Approver does not meet the minimum DOAG level required.',
							variant: 'warning',
						}
					});
				}

				return response.allowed;
			} catch (err) {
				this.setSubmissionAlertError('The approver could not be verified. Please, try again later.');
				this.setState({
					operationInProgress: false,
				});
				return false;
			}
		}
		return true;
		
	};

	// eslint-disable-next-line class-methods-use-this
	stateFormFromRequest(por: PortOpeningRequest): IPORStateForm {
		return {
			environment: por.environment,
			contact: !!por.contactUser &&
				UserLookupObject.fromUser(por.contactUser),
			approver: !!por.approvingUser &&
				UserLookupObject.fromUser(por.approvingUser),
			hasDelegatedApprover: por.delegatedApprovingUser != null,
			delegatedApprover: !!por.delegatedApprovingUser &&
				UserLookupObject.fromUser(por.delegatedApprovingUser),
			isEmergency: por.isEmergency,
			incidentNumber: por.incidentNumber,
			businessJustification: por.businessJustification,
			customerCoordination: por.customerCoordination,
			ruleList: por.portOpeningRules,
			comments: por.comments,
			custodianApproval: por.custodianApproval
		};
	}
}
