import React, { useState, useEffect } from 'react';
import { Table, Toolbar, TextInput, Alert } from 'react-unity';
import Paginator from '../../common/tables/Paginator';
import SubAdminConsentTableRow from './SubAdminConsentTableRow';
import GrantAdminConsentModal from './GrantAdminConsentModal';
import { GrantResponseBody } from '../../../models/entities/GrantResponse';

interface SubAdminConsentTableProps {
	subscriptionId: number;
	resources: any[];
	setAlertBanner: any;
}

const SubAdminConsentTable = ({ subscriptionId, resources, setAlertBanner }: SubAdminConsentTableProps) => {
	const [searchText, setSearchText] = useState('');
	const [filteredResources, setFilteredResources] = useState([]);
	const [resourcesOnPage, setResourcesOnPage] = useState([]);
	const [viewModal, setViewModal] = useState(false);
	const [resourceSelected, setResourceSelected] = useState(null);
	const [response, setResponse] = useState<GrantResponseBody[]>([]);

	const handleSearchTextChange = (e) => {
		const searchText = e.target?.value ?? e;
		const visibleResources = resources.filter((res) => res.name.toLowerCase().includes(searchText.toLowerCase()));

		setFilteredResources(visibleResources);
		setSearchText(searchText);
	};

	const handleConsent = (res) => {
		setResourceSelected(res);
		setViewModal(true);
		setResponse([])
	};

	const handleShowBanner = (res) => {
		setResponse(res);
		setTimeout(() => {
			setResponse([]);
		}, 6000);
	};

	const handleAlertClose = (permission) => {
		setResponse(response.filter((res) => res.permissionName !== permission));
	};

	useEffect(() => {
		setFilteredResources(resources);
		setResponse([]);
	}, [resources]);

	return (
		<>
			{response.map((resItem) => {
				return (
					<Alert
						key={resItem.permissionName}
						variant={resItem.message.includes('Ok') ? 'success' : 'error'}
						onClick={() => handleAlertClose(resItem.permissionName)}
						onClose={() => handleAlertClose(resItem.permissionName)}
					>
						{resItem.permissionName + ': ' + resItem.message}
					</Alert>
				);
			})}
			<Table
				header={
					<Toolbar>
						<Toolbar.Item>
							<TextInput
								inline
								size="small"
								placeholder="Search resource"
								value={searchText}
								onChange={handleSearchTextChange}
							/>
						</Toolbar.Item>
					</Toolbar>
				}
				footer={<Paginator data={filteredResources} onPageChange={(res) => setResourcesOnPage(res)} />}
			>
				<Table.Head>
					<Table.Head.Row>
						<Table.Head.Cell className="em-u-display-flex">Resource Name</Table.Head.Cell>
						<Table.Head.Cell> Resource Type </Table.Head.Cell>
						<Table.Head.Cell> Grant Admin Consent </Table.Head.Cell>
					</Table.Head.Row>
				</Table.Head>
				<Table.Body>
					{resourcesOnPage.length !== 0 ? (
						resourcesOnPage.map((res) => (
							<SubAdminConsentTableRow
								key={res.id}
								displayName={res.name}
								type={res.type}
								onGrantAdminConsent={() => handleConsent(res)}
							/>
						))
					) : (
						<div style={{ marginTop: 20 }}>
							<p>
								{resources.length > 0 ? 'No resources where found with this search filter' : 'There are no resources available in the resource group.'}
							</p>
						</div>
					)}
				</Table.Body>
			</Table>
			<GrantAdminConsentModal
				subscriptionId={subscriptionId}
				visible={viewModal}
				onClose={() => setViewModal(false)}
				resource={resourceSelected}
				showBanner={handleShowBanner}
				setAlertBanner={setAlertBanner}
			/>
		</>
	);
};

export default SubAdminConsentTable;
