import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TruckIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 14h-11a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5zM3 13h10V4H3z"
          fill="currentColor"
        />
        <path
          d="M5 12a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1zM11 12a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1zM11.5 8h-7a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM5 7h6V6H5z"
          fill="currentColor"
        />
        <path
          d="M16 13h-1V1H1v12H0V.5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5zM4.5 16h-1A1.5 1.5 0 0 1 2 14.5v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1A1.5 1.5 0 0 1 4.5 16zM3 14v.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V14zM12.5 16h-1a1.5 1.5 0 0 1-1.5-1.5v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-1.5 1.5zM11 14v.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V14z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M16 48h-4a3 3 0 0 1-3-3v-3h2v3a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3h2v3a3 3 0 0 1-3 3zM36 48h-4a3 3 0 0 1-3-3v-3h2v3a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3h2v3a3 3 0 0 1-3 3zM38 41H10a2 2 0 0 1-2-2V14a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v25a2 2 0 0 1-2 2zM11 13a1 1 0 0 0-1 1v25h28V14a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M16 37a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM32 37a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM35 25H13a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm-21-2h20v-6H14z"
          fill="currentColor"
        />
        <path
          d="M44 38h-3v-2h3V2H4v34h3v2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h40a2 2 0 0 1 2 2v34a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M8 24H6a2 2 0 0 1-2-2v-2h2v2h2v-2h2v2a2 2 0 0 1-2 2zM18 24h-2a2 2 0 0 1-2-2v-2h2v2h2v-2h2v2a2 2 0 0 1-2 2zM20 20H4a1 1 0 0 1-1-1V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v13a1 1 0 0 1-1 1zM5 18h14V6H5z"
          fill="currentColor"
        />
        <circle cx={8.5} cy={15.5} r={1.5} fill="currentColor" />
        <circle cx={15.5} cy={15.5} r={1.5} fill="currentColor" />
        <path
          d="M17 13H7a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-9-2h8V9H8z"
          fill="currentColor"
        />
        <path
          d="M24 20h-2V2H2v18H0V1a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TruckIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TruckIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TruckIcon;
