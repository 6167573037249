import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const AccessibilityIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 7A1.5 1.5 0 1 1 10 5.5 1.5 1.5 0 0 1 8.5 7zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM5 8h7v1H5z"
          fill="currentColor"
        />
        <path
          d="M10.309 13.146L8.5 10.132l-1.809 3.014-.857-.515L8.071 8.9a.52.52 0 0 1 .857 0l2.237 3.729z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 8h1v2H8z" />
        <path
          d="M8.5 16A7.5 7.5 0 1 1 16 8.5 7.508 7.508 0 0 1 8.5 16zm0-14A6.5 6.5 0 1 0 15 8.5 6.508 6.508 0 0 0 8.5 2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 19a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3zM13 20.969h22v2H13zM17.852 39.87l-1.722-1.018 7.009-11.861a.975.975 0 0 1 .9-.491 1 1 0 0 1 .859.552l5.947 11.895-1.789.895-5.129-10.252z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 21.987h2v6.07h-2z" />
        <path
          d="M24 47.511C10.76 47.511-.011 36.964-.011 24S10.76.489 24 .489 48.011 11.036 48.011 24 37.24 47.511 24 47.511zm0-45C11.875 2.511 2.011 12.151 2.011 24S11.875 45.489 24 45.489 45.989 35.849 45.989 24 36.125 2.511 24 2.511z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.5 10.308a3.154 3.154 0 1 1 3.2-3.154 3.181 3.181 0 0 1-3.2 3.154zM12.5 6a1.155 1.155 0 1 0 1.2 1.154A1.179 1.179 0 0 0 12.5 6zM6 11h13v2H6z"
          fill="currentColor"
        />
        <path
          d="M15.707 20.383L12.5 15.45l-3.207 4.933-1.677-1.09 4.045-6.223a1.039 1.039 0 0 1 1.677 0l4.045 6.223z"
          fill="currentColor"
        />
        <path
          d="M12 24a12 12 0 1 1 12-12 12.014 12.014 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

AccessibilityIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

AccessibilityIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default AccessibilityIcon;
