import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import RangeSliderList from "./RangeSliderList";
import Field from "../Field";

function getColor(color) {
  return {
    main: "em-c-range-slider--main",
    accent: "em-c-range-slider--accent",
    action: "em-c-range-slider--action",
    caution: "em-c-range-slider--caution",
    negative: "em-c-range-slider--negative",
    positive: "em-c-range-slider--positive",
    default: ""
  }[color || ""];
}

/** Sliders allow users to quickly select a value within a range. They should be used when the upper and lower bounds to the range are invariable.
 *
 * @visibleName Range slider
 * */
const RangeSlider = props => {
  const {
    value,
    min,
    max,
    step,
    label,
    note,
    className,
    onChange,
    valueFormatter,
    color,
    hideValueLabel,
    fieldProps,
    inputProps
  } = props;

  return (
    <Field
      className={clsx("em-c-range-slider", getColor(color), className)}
      {...fieldProps}
    >
      <Field.Body>
        <div class="em-c-range-slider__label">
          <label>{label}</label>
          {!hideValueLabel && (
            <output>
              {typeof valueFormatter === "function" && valueFormatter(value)}
            </output>
          )}
        </div>
        <input
          type="range"
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          {...inputProps}
        />
      </Field.Body>
      {note && <Field.Note>{note}</Field.Note>}
    </Field>
  );
};

RangeSlider.propTypes = {
  /** Numeric value of the slider */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  /** Minimum that the slider value can be */
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Maximum that the slider value can be */
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Value interval */
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Description that is positioned above the slider */
  label: PropTypes.ReactNode,
  /** Note that is positioned below the slider */
  note: PropTypes.ReactNode,
  /** CSS class added to the outer parent */
  className: PropTypes.string,
  /** Event triggered when the value changes */
  onChange: PropTypes.func,
  /** Allows the value to be customly formatted */
  valueFormatter: PropTypes.func,
  /** Theme color of the slider */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "caution",
    "positive",
    "negative",
    "default"
  ]),
  /** Hides the value label */
  hideValueLabel: PropTypes.bool,
  /** Additional props passed to the Field component */
  fieldProps: PropTypes.object,
  /** Additional props passed to the Input component */
  inputProps: PropTypes.object
};

RangeSlider.defaultProps = {
  className: "",
  value: 0,
  min: 0,
  max: 100,
  step: 1,
  label: "",
  note: "",
  onChange: () => {},
  valueFormatter: value => value,
  color: "default",
  hideValueLabel: false,
  fieldProps: {},
  inputProps: {}
};

RangeSlider.List = RangeSliderList;

export default RangeSlider;
