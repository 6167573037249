import React, { useState, useEffect } from 'react';
import {
	Grid,
	Button,
	XFilledIcon,
	Modal,
	Alert,
} from 'react-unity';
import Organization from '../../../models/entities/Organization';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import SubscriptionRequestsService from '../../../services/SubscriptionRequestsService';
import SubscriptionMultiEditModal from './SubscriptionMultiEditModal';
import { getOrganizations } from '../../common/OrganizationStorage';
import SubscriptionRequest from '../../../models/entities/SubscriptionRequest';
import { authenticatedUser } from '../../../authentication/authModule';
import { UpdateSubscriptionModel } from '../../../models/viewModels/Subscriptions/UpdateSubscriptionModel';
import User from '../../../models/User';

export interface ISubscriptionEditMultipleProps {
	visible: boolean;
	onCancel: (e) => any;
	onClose: (e) => any;
	onConfirm: (e) => any;
	mySubscriptions: SubscriptionRequest[];
	setCheckBoxState: any;
	setAlert: any;
}
//create interface editSubscriptionForm
export interface editSubscriptionForm {
	organizationL3: {
		value: Organization;
		onChange: (value) => void;
	};
	organizationL4: {
		value: Organization;
		onChange: (value) => void;
	};
	organizationL5: {
		value: Organization;
		onChange: (value) => void;
	};
	globalOrganization: {
		value: Organization;
		onChange: (value) => void;
	};
	owner: {
		value: User;
		onChange: (value) => void;
	};
	custodian: {
		value: User;
		onChange: (value) => void;
	};
	custodianTwo: {
		value: User;
		onChange: (value) => void;
	};
}
const SubscriptionsEditModal = ({ visible, onCancel, onClose, onConfirm, mySubscriptions, setCheckBoxState, setAlert }: ISubscriptionEditMultipleProps) => {

	const [submitting, setSubmitting] = useState(false);
	const [organizationsList, setOrganizationList] = useState<Organization[]>([]);
	const [allOrganizationsList, setAllOrganizationList] = useState<Organization[]>([]);
	const [subscriptionsService] = useState<SubscriptionRequestsService>(new SubscriptionRequestsService())
	const [valid, setValid] = useState(false);
	const [loadingAlert, setLoadingAlert] = useState<AlertBanner>({
		visible: false,
		variant: 'error',
		text: ''
	});

	const handleCancel = (e: any) => {
		resetStateForm();
		onClose(e);
	};

	const handleClose = (e: any) => {
		resetStateForm();
		onCancel(e);
	};

	const handleRequest = async (e: any) => {
		window.scroll(0, 0);
		setSubmitting(true);
		setLoadingAlert({
			visible: true,
			text: 'Saving changes... This could take several minutes'
		});
		const subsSelected = mySubscriptions.filter((sub) => sub.selected);

		const subsToEdit = subsSelected.map((sub) => (
			{
				subscriptionId: sub.id,
				selectedL3GlobalId: form.organizationL3.value || sub.organization.L3.id.toString(),
				organizationGlobalId: form.organizationL5.value || form.organizationL4.value || sub.organization.L5?.id?.toString() || sub.organization.L4.id.toString(),
				environment: sub.environment.value,
				cartaIds: sub.cartaIds,
				isProject: sub.isProject,
				wpmCode: parseInt(sub.wpmCode),
				closeOutDate: sub.closeOutDate,
				isConnected: sub.isConnected,
				amountOfDevices: null,
				region: sub.region,
				contributorAccessGroupId: '',
				initialResourceGroup: sub.resourceGroupName,
				ownerUserId: form.owner?.value?.id || sub.owner.id,
				custodianUserId: form.custodian?.value?.id || sub.custodian.id,
				custodianTwoUserId: form.custodianTwo?.value?.id || sub.custodianTwo?.id,
				subscriptionUsedFor: sub.subscriptionUsedFor,
			} as UpdateSubscriptionModel
			) 
		);
		subscriptionsService.updateMultiple(subsToEdit)
			.then(() => {
				setAlert({
					visible: true,
					variant: 'success',
					text: 'Subscriptions updated successfully.'
				});
				setSubmitting(false);
				resetStateForm();
				onConfirm(e);
			})
			.catch((err) => {
				if (err.status != 0) {
					setAlert({
						visible: true,
						variant: 'error',
						text: err.response?.data.message || `The subscription ${err.request?.body?.subscriptionId} could not be updated. Please, try again later.`
					});
					setTimeout(() => {
						setAlert({
							visible: false,
						});
					}, 5000);
					setSubmitting(false);
					handleClose(e);
				} else {
					setAlert({
						visible: true,
						variant: 'success',
						text: 'Subscriptions updated successfully.'
					});
					resetStateForm();
					onConfirm(e);
				}
			});
	};

	const [form, setForm] = useState({
		organizationL3: {
			value: null,
			onChange: (value) => {
				handleL3Change(value)
			}
		},
		organizationL4: {
			value: null,
			onChange: (value) => {
				handleL4Change(value)
			}
		},
		organizationL5: {
			value: null,
			onChange: (value) => {
				handleL5Change(value)
			}
		},
		globalOrganization: {
			value: null,
			onChange: (value) => {
				handleGlobalOrganizationChange(value)
			}
		},
		owner: {
			value: null,
			onChange: (value) => {
				handleInput('owner', value)
			}
		},
		custodian: {
			value: null,
			onChange: (value) => {
				handleInput('custodian', value)
			}
		},
		custodianTwo: {
			value: null,
			onChange: (value) => {
				handleInput('custodianTwo', value)
			}
		}
	}  as editSubscriptionForm);

	const stateFormHandler = () => getStateFormHandlers();

	const getStateFormHandlers = () => {
		return {
			owner: {
				value: null,
				validation: {
					required: false,
				},
				onChange: (value) => {
					handleInput('owner', value)
				}
			},
			custodian: {
				value: null,
				onChange: (value) => {
					handleInput('custodian', value)
				}
			},
			custodianTwo: {
				value: null,
				validation: {
					rules: [
						{
							assert: () => form.owner?.value !== form.custodianTwo?.value && form.custodian?.value !== form.custodianTwo?.value,
							message: 'Custodian two and Owner cannot be the same. Custodian two and Custodian cannot be the same.',
						},
					],
				},
				onChange: (value) => {
					handleInput('custodianTwo', value)
				}
			}
		}
	}

	const handleL3Change = (event) => {
		setForm({ ...form, organizationL3: { ...form.organizationL3, value: event.target.value } })
		setValid(isValid());
	};

	const handleL4Change = (event) => {
		setForm({ ...form, globalOrganization: { ...form.globalOrganization, value: event.target.value } });
		setForm({ ...form, organizationL4: { ...form.organizationL4, value: event.target.value } });
		setValid(isValid());
	};

	const handleL5Change = (event) => {
		setForm({ ...form, globalOrganization: { ...form.globalOrganization, value: event.target.value } });
		setForm({ ...form, organizationL5: { ...form.organizationL5, value: event.target.value } });
		setValid(isValid());
	};

	const handleGlobalOrganizationChange = (value) => {
		setForm({ ...form, globalOrganization: { ...form.globalOrganization, value: value } });
		setValid(isValid());
	};

	const organizationOptions = (parent: string | null) => {
		return organizationsList
			.filter((organization) => organization.findParent(organizationsList)?.id.toString() == parent && organization.status == 'Active')
			.map((organization) => ({ value: organization.id.toString(), text: organization.displayName })).sort((a, b) => a.value > b.value ? 1 : -1);
	};

	const organizationOptionsL5 = (parent: string | null) => {
		var options = organizationOptions(parent);
		options.unshift({ value: null, text: '' });
		return options;
	};

	const handleInput = async (fieldName, value) => {
		let formField = form;
		formField[fieldName].value = value;
		setForm(formField);
		setValid(isValid());
	}

	const isValid = (): boolean => {
		const isOwnerUnique = form.owner?.value==null ? true : form.owner?.value?.id!=form.custodianTwo?.value?.id;
		const isCustodianUnique = form.custodian?.value==null ? true : form.custodian?.value?.id!=form.custodianTwo?.value?.id;
		const isCustodianTwoUnique = form.custodianTwo?.value==null ? true : form.custodianTwo?.value?.id!=form.owner?.value?.id && form.custodianTwo?.value?.id!=form.custodian?.value?.id;
		const isOrganizationSelected = form.organizationL3?.value != null || form.organizationL4?.value != null || form.organizationL5?.value != null;
		return (isOwnerUnique && isCustodianUnique && isCustodianTwoUnique) || isOrganizationSelected;
	}

	const resetStateForm = () => {
		setForm({
			...form,
			organizationL3: { ...form.organizationL3, value: null },
			organizationL4: { ...form.organizationL4, value: null },
			organizationL5: { ...form.organizationL5, value: null },
			globalOrganization: { ...form.globalOrganization, value: null },
			owner: { ...form.owner, value: null },
			custodian: { ...form.custodian, value: null },
			custodianTwo: { ...form.custodianTwo, value: null }
		});
		mySubscriptions.map((sub) => sub.selected = false);
		setCheckBoxState(0);
	};

	useEffect(() => {
		const fetchData = async () => {
			let organizations = (await getOrganizations()).map(o => new Organization(o));
			setAllOrganizationList(organizations);
			setOrganizationList(organizations);
		}
		fetchData();
	}, []);

	useEffect(() => {
		setOrganizationList(allOrganizationsList);
	}, []);

	return (
		<Modal show={visible}
			onHide={() => { }}>
			<Modal.Window className="autoOverflowModal" id="subs-multi-edit-modal">
				<Modal.Window.Header>
					<Modal.Window.Header.Title>Edit Your Subscriptions Selected</Modal.Window.Header.Title>
					<Modal.Window.Header.CloseButton onClick={onClose}>
						<XFilledIcon size='small' />
					</Modal.Window.Header.CloseButton>
				</Modal.Window.Header>
				{loadingAlert.visible &&
					<Alert
						variant={loadingAlert.variant}
						onClose={() => {
							setAlert({
								visible: false,
							});
						}}
					>
						{loadingAlert.text}
					</Alert>}
				<Modal.Window.Body>
					<SubscriptionMultiEditModal
						form={form}
						organizationOptions={organizationOptions}
						organizationOptionsL5={organizationOptionsL5}
						handleL3Change={handleL3Change}
						handleL4Change={handleL4Change}
						handleL5Change={handleL5Change}
						stateFormHandler={stateFormHandler}
					/>
				</Modal.Window.Body>
				<Modal.Window.Footer>
					<Grid variant="2-up">
						<Grid.Item>
							<Button
								variant="secondary"
								disabled={submitting}
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</Grid.Item>
						<Grid.Item>
							<Button
								variant="primary"
								disabled={!valid || submitting}
								onClick={handleRequest}
							>
								Save
							</Button>
						</Grid.Item>
					</Grid>
				</Modal.Window.Footer>
			</Modal.Window>
		</Modal>
	);
};

export default SubscriptionsEditModal;