import React from "react";
import PropTypes, { oneOfType } from "prop-types";
import Field from "../Field";
import ToggleItem from "./ToggleItem";

/** An toggle for two options */
const Toggle = props => {
  const {
    label,
    note,
    error,
    valid,
    disabled,
    color,
    className,
    children,
    ...other
  } = props;

  return (
    <Field
      className={className}
      error={error}
      valid={valid}
      disabled={disabled}
      {...other}
    >
      {label && <Field.Label>{label}</Field.Label>}
      <Field.Body>
        <div className="em-c-toggle">
          {React.Children.toArray(children).map((item, i, arr) => {
            if (arr.length - 1 === i) {
              return React.cloneElement(item, {
                last: true,
                key: item.id,
                color
              });
            }
            return React.cloneElement(item, { key: item.id, color });
          })}
        </div>
      </Field.Body>
      {note && <Field.Note>{note}</Field.Note>}
    </Field>
  );
};

Toggle.propTypes = {
  /** Label for checkbox list */
  label: oneOfType([PropTypes.string, PropTypes.number]),
  /** note to go below the field */
  note: oneOfType([PropTypes.string, PropTypes.number]),
  /** If the Toggle is error state or not */
  error: PropTypes.bool,
  /** If the Toggle is valid or not */
  valid: PropTypes.bool,
  /** If the child Radios are disabled or not */
  disabled: PropTypes.bool,
  /** Theme color of the Toggle */
  color: PropTypes.oneOf(["blue", "main", "accent"]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case Radios */
  children: PropTypes.node
};

Toggle.defaultProps = {
  label: "",
  note: "",
  error: false,
  valid: false,
  disabled: false,
  className: "",
  color: "blue",
  children: null
};

Toggle.Item = ToggleItem;

export default Toggle;
