import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FilePhotoIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.5 13h-7a.5.5 0 0 1-.5-.5V9.843a.5.5 0 0 1 .13-.337l2.038-2.237a.5.5 0 0 1 .367-.169.387.387 0 0 1 .369.16l1.487 1.6 2.814-2.047a.5.5 0 0 1 .794.4V12.5a.5.5 0 0 1-.499.5zM5 12h6V8.2L8.621 9.933a.5.5 0 0 1-.66-.064L6.542 8.343 5 10.036zM5.5 6A1.5 1.5 0 1 1 7 4.5 1.5 1.5 0 0 1 5.5 6zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M12.5 15h-9A1.5 1.5 0 0 1 2 13.5v-11A1.5 1.5 0 0 1 3.5 1h6.165a1.493 1.493 0 0 1 1.03.411l2.835 2.68a1.5 1.5 0 0 1 .47 1.09V13.5a1.5 1.5 0 0 1-1.5 1.5zm-9-13a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V5.181a.5.5 0 0 0-.156-.363l-2.836-2.681A.5.5 0 0 0 9.665 2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M37 40H11a1 1 0 0 1-1-1v-6.776a1.5 1.5 0 0 1 .5-1.12 1.009 1.009 0 0 1 .162-.118l11.5-6.685a1 1 0 0 1 1.251.2l4.55 5.133 8.429-6.431A1 1 0 0 1 38 24v15a1 1 0 0 1-1 1zm-25-2h24V26.021l-7.561 5.769a1 1 0 0 1-1.354-.132l-4.623-5.216L12 32.523zM16 21a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
        <path
          d="M39 45H9a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h20.343a4.968 4.968 0 0 1 3.536 1.464l7.656 7.657A4.968 4.968 0 0 1 42 15.657V42a3 3 0 0 1-3 3zM9 5a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V15.657a3.02 3.02 0 0 0-.879-2.121l-7.656-7.658A2.982 2.982 0 0 0 29.343 5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18 21H6a1 1 0 0 1-1-1v-3a1 1 0 0 1 .137-.5c.161-.275.161-.275 5.354-3.355a1 1 0 0 1 1.217.153L14 15.586l3.293-3.293A1 1 0 0 1 19 13v7a1 1 0 0 1-1 1zM7 19h10v-3.586l-2.293 2.293a1 1 0 0 1-1.414 0l-2.45-2.451c-1.367.813-3.06 1.821-3.843 2.3z"
          fill="currentColor"
        />
        <circle cx={9.5} cy={7.5} r={2.5} fill="currentColor" />
        <path
          d="M19.875 24H4.125A2.115 2.115 0 0 1 2 21.9V2.1A2.115 2.115 0 0 1 4.125 0h10.318a3.255 3.255 0 0 1 2.29.93l4.307 4.211a3.2 3.2 0 0 1 .96 2.27V21.9a2.115 2.115 0 0 1-2.125 2.1zM4.125 2A.115.115 0 0 0 4 2.1v19.8a.115.115 0 0 0 .125.1h15.75a.115.115 0 0 0 .125-.1V7.411a1.169 1.169 0 0 0-.358-.841l-4.307-4.211A1.283 1.283 0 0 0 14.443 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FilePhotoIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FilePhotoIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FilePhotoIcon;
