import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BanIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M3.347 4.056l.708-.707 8.597 8.596-.707.707z"
        />
        <path
          d="M8 15A7 7 0 0 1 3.05 3.05a7 7 0 0 1 9.9 9.9A6.954 6.954 0 0 1 8 15zM3.757 3.757A6 6 0 1 0 8 2a6.007 6.007 0 0 0-4.243 1.757z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M9.339 10.752l1.414-1.414 26.909 26.91-1.415 1.414z"
        />
        <path
          d="M24 43.991A19.993 19.993 0 0 1 9.858 9.858 20 20 0 1 1 24 43.991zm0-37.982a17.943 17.943 0 0 0-12.728 5.263A18.021 18.021 0 1 0 24 6.009zm-13.435 4.556z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M3.608 5.024l1.414-1.415 15.37 15.369-1.414 1.414z"
        />
        <path
          d="M12 22.995A10.995 10.995 0 0 1 4.222 4.222 11 11 0 1 1 12 22.995zM5.636 5.636a9 9 0 1 0 12.729 0 9.011 9.011 0 0 0-12.729 0z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BanIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BanIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BanIcon;
