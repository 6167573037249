import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const GlobeIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 16A7.5 7.5 0 1 1 16 8.5 7.508 7.508 0 0 1 8.5 16zm0-14A6.5 6.5 0 1 0 15 8.5 6.508 6.508 0 0 0 8.5 2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M4 5h9v1H4zM4 11h9v1H4zM3 8h11v1H3z" />
        <path
          fill="currentColor"
          d="M8 3h1v11H8zM5.156 13.318L4.6 11.384a10.49 10.49 0 0 1 0-5.769l.552-1.934.962.275-.549 1.934a9.5 9.5 0 0 0 0 5.22l.553 1.934zM11.844 13.318l-.962-.275.553-1.934a9.5 9.5 0 0 0 0-5.22l-.553-1.934.962-.275.556 1.936a10.49 10.49 0 0 1 0 5.769z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 47a23 23 0 1 1 23-23 23.026 23.026 0 0 1-23 23zm0-44a21 21 0 1 0 21 21A21.024 21.024 0 0 0 24 3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M9 13h30v2H9zM9 33h30v2H9zM5 23h38v2H5z" />
        <path
          fill="currentColor"
          d="M23 5h2v38h-2zM12.323 38.1l-.923-3.9a48.55 48.55 0 0 1 0-22.4l.928-3.9 1.776.423-.929 3.9a46.757 46.757 0 0 0 0 21.555l.929 3.9zM34.677 38.1l-1.777-.423.929-3.9a46.757 46.757 0 0 0 0-21.555l-.929-3.9 1.777-.422.928 3.9a48.55 48.55 0 0 1 0 22.4z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M4 6h16v2H4zM4 16h16v2H4zM3.091 11h17.818v2H3.091z"
        />
        <path
          fill="currentColor"
          d="M11 3.091h2v17.818h-2zM7.553 21.342l-1.448-2.895a1.075 1.075 0 0 1-.067-.173 20.181 20.181 0 0 1 0-11.549 1.075 1.075 0 0 1 .067-.173l1.448-2.894 1.789.895-1.405 2.81a18.239 18.239 0 0 0 0 10.273l1.405 2.811zM16.447 21.342l-1.789-.895 1.405-2.811a18.239 18.239 0 0 0 0-10.273l-1.405-2.81 1.789-.895 1.447 2.895a1.075 1.075 0 0 1 .067.173 20.181 20.181 0 0 1 0 11.549 1.075 1.075 0 0 1-.067.173z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

GlobeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

GlobeIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default GlobeIcon;
