import * as React from 'react';
import { Button, Grid, Alert } from 'react-unity';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
import {
	OrganizationInput,
	EnvironmentInput,
	PlatformInput,
	CustodianInput,
	CustodianTwoInput,
	ProjectInput,
	OwnerInput,
	SubscriptionTypeInput,
	TenantInput,
} from './components';
import TextInputField from '../common/form-controls/TextInputField';
import CartaInput from '../common/form-controls/CartaInput';
import { ImportSubscriptionModel } from '../../models/viewModels/Subscriptions';
import TextAreaField from '../common/form-controls/TextareaField';
import { AbstractSubscriptionsFormNew, ISubscriptionNewStateForm, SubscriptionsFormNewProps } from './AbstractSubscriptionsFormNew';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';
import TenantService from '../../services/TenantService';
import Tenant from '../../models/entities/Tenant';
import { useNavigate } from 'react-router-dom';

interface SubscriptionsImportProps extends SubscriptionsFormNewProps {
	navigate: any;
 }

interface ImportStateForm extends ISubscriptionNewStateForm {
	tenant: string;
	guid: string;
}

interface SubscriptionsImportState {
	stateForm: ImportStateForm,
	submitting: boolean;
	submissionAlert: AlertBanner;
	allTenants: Tenant[];
}

class SubscriptionsImportWithParams
	extends AbstractSubscriptionsFormNew<SubscriptionsImportProps, SubscriptionsImportState> {
	tenantService: TenantService;

	constructor(props: SubscriptionsImportProps) {
		super(props);
		this.state = {
			stateForm: {
				selectedL3: '',
				selectedL4: '',
				selectedOrganization: '',
				tenant: '',
				guid: '',
				subscriptionName: '',
				environment: null,
				cartaIds: [],
				platform: 0,
				owner: null,
				custodian: null,
				custodianTwo: null,
				isProject: null,
				isConnected: false,
				subscriptionUsedFor: '',
				isImportedOrUpdated: true,
				subsBudget: null,
			},
			submitting: false,
			submissionAlert: {
				visible: false,
			},
			allTenants: [],
		};
		this.tenantService = new TenantService();
		this.initFormModel();
	}


	async componentDidMount() {
		if (!(authenticatedUser.isInRole(UserRole.SubscriptionAdmin))) {
			this.userNotAllowed();
		}
		const tenants = await this.tenantService.getAll();
		this.setState({
			allTenants: tenants,
		});
	}

	initFormModel() {
		super.initFormModel();
		
		this.formModel.addField('tenantId', {
			getValue: () => this.state.stateForm.tenant,
			validation: {
				required: true,
			}
		});

		this.handleStateFormChange('isImportedOrUpdated', true);

		this.formModel.addField('createdSubscriptionId', {
			getValue: () => this.state.stateForm.guid,
			validation: {
				required: true,
				rules: [
					{
						assert: () => this.state.stateForm.guid.length === 36,
						message: 'The subscription GUID must be 36 characters long.'
					},
					{
						assert: () => /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$/.test(this.state.stateForm.guid),
						message: 'The subscription GUID does not match the required pattern.'
					}
				]
			}
		});
		this.formModel.fields.contributorAccessGroupId.validation.required = () => 
			(this.state.stateForm.isConnected && this.state.stateForm.tenant !== this.state.allTenants.filter(tenant => tenant.name === 'ExxonMobilTest')[0]?.id);
	}

	handleSave = async () => {
		window.scroll(0, 0);
		this.setState({
			submitting: true,
			submissionAlert: {
				visible: true,
				text: 'Import in progress...',
				variant: 'default'
			}
		});
		try {
			const model = this.formModel.create(ImportSubscriptionModel);
			await this.subscriptionsService.import(model);
			this.props.navigate('/subscriptions');
		} catch (err) {
			this.setState({
				submissionAlert: {
					visible: true,
					variant: 'error',
					text: err.response?.data.message || 'An error occured with your request. Please, try again later.'
				}
			});
		} finally {
			this.setState({
				submitting: false
			});
		}
	};

	handleAlertClose = () => {
		this.setState({
			submissionAlert: {
				visible: false
			}
		});
	};

	render() {
		return (
			<RequestFormWrapper
				title="Import Subscription"
				linkTree={
					[
						{ to: '/subscriptions', text: 'Subscriptions' }
					]
				}
			>
				{this.state.submissionAlert.visible &&
					<Alert
						variant={this.state.submissionAlert.variant}
						onClose={this.state.submitting ? undefined : this.handleAlertClose}
					>
						{this.state.submissionAlert.text}
					</Alert>}
				<OrganizationInput
					organizationL3={this.stateFormHandler().selectedL3}
					organizationL4={this.stateFormHandler().selectedL4}
					globalOrganization={this.stateFormHandler().selectedOrganization}
					disabled={this.state.submitting}
				/>
				<Grid variant="halves">
					<Grid.Item>
						<TenantInput
							value={this.state.stateForm.tenant}
							validation={this.formModel.fields.tenantId.validation}
							tenants={this.state.allTenants}
							onChange={
								(event) => {
									this.handleStateFormChange('tenant', event.target.value);
								}
							}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
					<Grid.Item>
						<TextInputField
							label="Subscription GUID"
							value={this.state.stateForm.guid}
							validation={this.formModel.fields.createdSubscriptionId.validation}
							onChange={
								(event) => {
									this.handleStateFormChange('guid', event.target.value);
								}
							}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
				</Grid>
				<TextInputField
					label="Subscription Name"
					{...this.stateFormHandler().subscriptionName}
					disabled={this.state.submitting}
				/>
				<Grid>
					<Grid.Item>
						<PlatformInput value={this.state.stateForm.platform} />
					</Grid.Item>
					<Grid.Item>
						<EnvironmentInput
							{...this.stateFormHandler().environment}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
				</Grid>
				<SubscriptionTypeInput
					subscriptionType={this.stateFormHandler().isConnected}
					amountOfDevices={this.stateFormHandler().amountOfDevices}
					region={this.stateFormHandler().region}
					initialResourceGroup={this.stateFormHandler().initialResourceGroup}
					contributorAccessGroup={this.stateFormHandler().contributorAccessGroup}
					disabled={this.state.submitting}
					resourceGroupOwnerType={this.stateFormHandler().resourceGroupOwnerType}
					resourceGroupOwner={this.stateFormHandler().resourceGroupOwner}
					virtualNetwork={this.stateFormHandler().virtualNetwork}
					requesting={true}

				/>
				<ProjectInput
					isProject={this.stateFormHandler().isProject}
					wpmCode={this.stateFormHandler().wpmCode}
					closeOutDate={this.stateFormHandler().closeOutDate}
					disabled={this.state.submitting}
				/>
				<CartaInput
					{...this.stateFormHandler().cartaIds}
					disabled={this.state.submitting}
				/>
				<Grid variant="halves">
					<Grid.Item>
						<OwnerInput
							{...this.stateFormHandler().owner}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
					<Grid.Item>
						<CustodianInput
							{...this.stateFormHandler().custodian}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
					<Grid.Item>
						<CustodianTwoInput
							{...this.stateFormHandler().custodianTwo}
							disabled={this.state.submitting}
						/>
					</Grid.Item>
				</Grid>
				<TextAreaField
					label="Subscription Usage"
					{...this.stateFormHandler().subscriptionUsedFor}
					disabled={this.state.submitting}
				/>
				<Grid variant="3-up">
					<Grid.Item>
						<Button
							variant="secondary"
							disabled={this.state.submitting}
							className="z-index-0"
							onClick={() => this.props.navigate('/subscriptions')}
						>
							Cancel
						</Button>
					</Grid.Item>
					<Grid.Item />
					<Grid.Item>
						<Button
							variant="primary"
							disabled={!this.formModel.isValid()}
							loading={this.state.submitting}
							onClick={this.handleSave}
							className="z-index-0"
						>
							Import Subscription
						</Button>
					</Grid.Item>
				</Grid>
			</RequestFormWrapper>
		);
	}
}

const SubscriptionsImport = () => {

    return <SubscriptionsImportWithParams navigate={useNavigate()}/>;
}
export default SubscriptionsImport;