import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PowerCordIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M4.5 6h-4a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 2h4a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5zM1 5h3V3H1z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M1 0h1v3H1zM3 0h1v3H3zM11.5 15a1.5 1.5 0 0 1-1.5-1.5v-5a.5.5 0 0 0-1 0v5a1.5 1.5 0 0 1-3 0v-5a.5.5 0 0 0-1 0v5a1.5 1.5 0 0 1-3 0V5h1v8.5a.5.5 0 0 0 1 0v-5a1.5 1.5 0 0 1 3 0v5a.5.5 0 0 0 1 0v-5a1.5 1.5 0 0 1 3 0v5a.5.5 0 0 0 1 0v-5a1.5 1.5 0 0 1 3 0V11h1v1h-1.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 0-1 0v5a1.5 1.5 0 0 1-1.5 1.5z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M7 15H3a3 3 0 0 1-3-3V6a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v6a3 3 0 0 1-3 3zM2 7v5a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V7zM2 1h2v4H2zM6 1h2v4H6zM34 47a4 4 0 0 1-4-4V21a2 2 0 0 0-4 0v22a4 4 0 0 1-8 0V21a2 2 0 0 0-4 0v21a5 5 0 0 1-10 0V17.5h2V42a3 3 0 0 0 6 0V21a4 4 0 0 1 8 0v22a2 2 0 0 0 4 0V21a4 4 0 0 1 8 0v22a2 2 0 0 0 4 0V21.5a4 4 0 0 1 8 0v15a1 1 0 0 0 1 1h3v2h-3a3 3 0 0 1-3-3v-15a2 2 0 0 0-4 0V43a4 4 0 0 1-4 4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M7 10H3a2 2 0 0 1-2-2V4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a2 2 0 0 1-2 2zM3 5v3h4V5z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M2 1h2v3H2zM6 1h2v3H6zM15 24c-.742 0-3 0-3-3v-8.945A5.723 5.723 0 0 0 11 12a5.8 5.8 0 0 0-1 .054V21a2.916 2.916 0 0 1-3 3 3 3 0 0 1-3-3V8h2v13a1 1 0 0 0 1 1 .915.915 0 0 0 1-1v-9c0-2 2.026-2 3-2s3 0 3 2v9c0 1 .172 1 1 1a.915.915 0 0 0 1-1v-9c0-2 2.026-2 3-2s3 0 3 2v6h2v2h-2a2 2 0 0 1-2-2v-5.945A5.723 5.723 0 0 0 19 12a5.8 5.8 0 0 0-1 .054V21a2.916 2.916 0 0 1-3 3z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PowerCordIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PowerCordIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PowerCordIcon;
