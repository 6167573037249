import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ButtonGroup from "./ButtonGroup";
import Loader from "../Loader";

function getSize(size) {
  switch (size) {
    case "small":
      return "em-c-btn--small";
    case "large":
      return "em-c-btn--large";
    default:
      return "";
  }
}

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-btn--main";
    case "accent":
      return "em-c-btn--accent";
    case "action":
      return "em-c-btn--action";
    case "caution":
      return "em-c-btn--caution";
    case "negative":
      return "em-c-btn--negative";
    case "positive":
      return "em-c-btn--positive";
    case "blue":
    default:
      return "";
  }
}

function getColorForLoader(color) {
  if (color === "default") return "main";
  return color;
}

function getVariant(variant) {
  switch (variant) {
    case "primary":
      return "em-c-btn--primary";
    case "secondary":
      return "em-c-btn--secondary";
    case "tertiary":
      return "em-c-btn--tertiary";
    case "bare":
      return "em-c-btn--bare";
    case "inverted":
      return "em-c-btn--inverted";
    case "invertedRed":
      return "em-c-btn--inverted-red";
    // Reversed buttons can only be used with secondary buttons according to the unity docs
    case "reversed":
      return "em-c-btn--secondary em-c-btn--reversed";
    case "default":
    default:
      return "";
  }
}

/** Button Component acts as all of the different types of buttons in Unity depending on the type you give it, and if you give it an icon/text. */
const Button = props => {
  const {
    onClick,
    variant,
    color,
    type,
    size,
    disabled,
    active,
    selectable,
    loading,
    className,
    children,
    ...other
  } = props;

  const styledChildren = React.Children.map(children, child => {
    if (child.type && child.type.name && child.type.name.includes("Icon")) {
      return React.cloneElement(child, {
        className: clsx("em-c-btn__icon", child.props.className)
      });
    }
    if (child.type) {
      return React.cloneElement(child, {
        className: clsx("em-c-btn__text", child.props.className)
      });
    }
    return child;
  });

  /** the code above is a sin. but it makes it better. */
  return (
    <button
      type={type}
      className={clsx(
        "em-c-btn",
        getVariant(variant),
        getSize(size),
        getColor(color),
        {
          "em-c-btn--disabled": disabled,
          "em-is-active": active,
          "em-js-btn-selectable": selectable
        },
        className
      )}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      <div className="em-c-btn__inner">
        {loading ? (
          <div>
            <div style={{ visibility: "hidden" }}>
              {children && styledChildren}
            </div>
            <div
              style={{
                position: "absolute",
                height: "1rem",
                top: "50%",
                left: "0",
                marginTop: "-.5rem",
                textAlign: "left"
              }}
            >
              <Loader color={getColorForLoader(color)} />
            </div>
          </div>
        ) : (
          children && styledChildren
        )}
      </div>
    </button>
  );
};

Button.defaultProps = {
  onClick: () => {},
  variant: "default",
  color: "blue",
  type: "button",
  size: "medium",
  disabled: false,
  active: false,
  selectable: false,
  loading: false,
  className: "",
  children: null
};

Button.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Theme color of the button */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "caution",
    "positive",
    "negative",
    "blue"
  ]),
  /** Controls the style of the button */
  variant: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "tertiary",
    "bare",
    "inverted",
    "invertedRed",
    "reversed"
  ]),
  /** Useful for form buttons */
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  /** Size of the button */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /** If the button is disabled or not */
  disabled: PropTypes.bool,
  /** If the button is active or not. Should be used with selectable prop enabled */
  active: PropTypes.bool,
  /** If you would like to toggle a button on and off. Should be used with active prop */
  selectable: PropTypes.bool,
  /** loading boolean, can be set and unset */
  loading: PropTypes.bool,
  /** The function that will be called on button click */
  onClick: PropTypes.func,
  /** Child allows users to pass icons in for icon buttons */
  children: PropTypes.node
};

Button.Group = ButtonGroup;

export default Button;
