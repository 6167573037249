import React, { useState } from 'react';
import { Table } from 'react-unity';
import Paginator from '../common/tables/Paginator';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import ApprovalsPORsTableRow from './ApprovalsPORsTableRow';

interface ApprovalsPORsTableProps {
    filteredPORs: PortOpeningRequest[];
}

const ApprovalsPORsTable = ({
    filteredPORs,
}: ApprovalsPORsTableProps) => {
    const [porsOnPage, setPorsOnPage] = useState<PortOpeningRequest[]>([]);
    return (
        <Table
            footer={
                <Paginator
                    data={filteredPORs}
                    onPageChange={(pors: PortOpeningRequest[]) => {
                        setPorsOnPage(pors)
                    }}
                />
            }
        >
            <Table.Head>
                <Table.Head.Row>
                    <Table.Head.Cell>POR #</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'>Subscription</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'>
                        Status
                    </Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'>Action</Table.Head.Cell>
                </Table.Head.Row>
            </Table.Head>
            <Table.Body>
                {porsOnPage.map((por: PortOpeningRequest) => {
                    return <ApprovalsPORsTableRow
                        key={por.id}
                        por={por}
                    />;
                }
                )}
            </Table.Body>
        </Table>
    );
}

export default ApprovalsPORsTable;