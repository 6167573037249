import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Input from "../../Input";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-toggle__label--main";
    case "accent":
      return "em-c-toggle__label--accent";
    case "blue":
    default:
      return "";
  }
}

/** Toggle items make up a toggle! */
const ToggleItem = props => {
  const {
    id,
    name,
    disabled,
    checked,
    onChange,
    placeholder,
    value,
    error,
    valid,
    readOnly,
    last,
    color,
    className,
    children,
    ...other
  } = props;

  return (
    <Fragment>
      <Input
        type="radio"
        name={name}
        id={id}
        className="em-c-toggle__input em-u-is-vishidden"
        placeholder={placeholder}
        value={value}
        checked={checked}
        onChange={onChange}
        error={error}
        valid={valid}
        disabled={disabled}
        readOnly={readOnly}
        icon={false}
        fullWidth={false}
        {...other}
      />
      <label
        className={clsx(
          "em-c-toggle__label",
          { "em-is-active": checked, "em-c-toggle__label--last": last },
          getColor(color),
          className
        )}
        htmlFor={id}
      >
        {children}
      </label>
    </Fragment>
  );
};

ToggleItem.propTypes = {
  /** Html Id, this should be filled out for accessibility */
  id: PropTypes.string,
  /** Html name, this should also be filled out for accessibility */
  name: PropTypes.string,
  /** Value of the selected radio, could be the same as the text or can be different */
  value: PropTypes.any.isRequired, //eslint-disable-line
  /** If the radio is selected or not */
  checked: PropTypes.bool,
  /** If the radio is disabled or not */
  disabled: PropTypes.bool,
  /** Function triggered by changes to the radio */
  onChange: PropTypes.func,
  /** last item in the switch (gives it a right border) */
  last: PropTypes.bool,
  /** Theme color of the Toggle */
  color: PropTypes.oneOf(["blue", "main", "accent"]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children */
  children: PropTypes.node
};

ToggleItem.defaultProps = {
  id: "",
  name: "",
  disabled: false,
  checked: false,
  onChange: () => {},
  last: false,
  className: "",
  color: "blue",
  children: null
};

export default ToggleItem;
