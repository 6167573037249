import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** A tab item within a tabs list */
const TabsItem = props => {
  const { open, value, className, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-tabs__panel",
        "em-js-tabs-panel",
        { "em-is-active": open },
        className
      )}
      {...other}
    >
      {children}
    </div>
  );
};

TabsItem.propTypes = {
  /** Shown or hidden state of a given tab. Automatically passed by Tab parent */
  open: PropTypes.bool,
  /** Unique identifier */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Text in the Tab Header */
  title: PropTypes.node
};

TabsItem.defaultProps = {
  open: false,
  className: "",
  title: ""
};

export default TabsItem;
