export default class CartaIdInfo {
	type!: string;

	id!: string;

	name!: string;

	tier!: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}
