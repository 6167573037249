import Application from '../models/Application';
import EnterpriseApp from '../models/entities/EnterpriseApp';
import ApplicationPermission from '../models/entities/ApplicationPermission';
import PermissionToApplication from '../models/entities/PermissionToApplication';
import { RequestEnterpriseAppModel } from '../models/viewModels/EnterpriseApps/RequestEnterpriseAppModel';
import { UpdateEnterpriseAppModel } from '../models/viewModels/EnterpriseApps/UpdateEnterpriseAppModel';
import ApiClient from './ApiClient';
import ChangeHistory from '../models/entities/ChangeHistory';
import EntityChangeHistoryService from '../models/interfaces/EntityChangeHistoryService';
import { getToken } from '../authentication/authModule';

export default class ThirdPartyApplicationsService extends ApiClient implements EntityChangeHistoryService {
	async getAll() {
		return this.get<Application[]>('ThirdPartyApplications');
	}

	async getAllApplications() {
		return this.get<EnterpriseApp[]>('ThirdPartyApplications/allApps');
	}

	async getEnterpriseAppByAppId(id: string) {
		return this.get<EnterpriseApp>(`ThirdPartyApplications/application/${id}`);
	}

	async getEnterpriseAppById(id: number) {
		return this.get<EnterpriseApp>(`ThirdPartyApplications/${id}`);
	}

	// async deleteById(id: string) {
	// 	return this.delete<Application>(`ThirdPartyApplications/${id}`, null);
	// }

	async cancelTransferOwnership(id: string){
		return this.patch(`ThirdPartyApplications/${id}/cancelTransferOwnership`, null);
	}

	async getMyApplications() {
		return this.get<EnterpriseApp[]>('ThirdPartyApplications/me');
	}

	// async createApplication(app: RequestEnterpriseAppModel) {
	// 	return this.post('ThirdPartyApplications', app);
	// }

	// async updateApplication(appId: string, app: UpdateEnterpriseAppModel) {
	// 	return this.patch(`ThirdPartyApplications/${appId}`, app);
	// }

	// async createSamlApplication(app: RequestEnterpriseAppModel) {
	// 	return this.post('ThirdPartyApplications/saml', app);
	// }

	// async grantAdminConsent(id: string) {
	// 	return this.patch(`ThirdPartyApplications/${id}/grantAdminConsent`, null);
	// }

	// async assignHomeRealmDiscoveryPolicy(id: string) {
	// 	return this.post(`ThirdPartyApplications/${id}/homeRealmDiscoveryPolicy`, null);
	// }

	// async removeHomeRealmDiscoveryPolicy(id: string) {
	// 	return this.delete(`ThirdPartyApplications/${id}/homeRealmDiscoveryPolicy`, null);
	// }

	async getApplicationPermissions(id: string) {
		return this.get<ApplicationPermission[]>(`ThirdPartyApplications/${id}/configuredPermissions`);
	}

	async getPermissionsToApplication(id: string) {
		return this.get<PermissionToApplication[]>(`ThirdPartyApplications/${id}/permissionsToApp`);
	}

	// async takeOwnershipOfApplication(id: string) {
	// 	return this.patch(`ThirdPartyApplications/${id}/takeOwnership`, null);
	// }

	// async acceptOwnershipOfApplication(id: string) {
	// 	return this.patch(`ThirdPartyApplications/${id}/acceptOwnership`, null);
	// }

	// async rejectOwnershipOfApplication(id: string) {
	// 	return this.patch(`ThirdPartyApplications/${id}/rejectOwnership`, null);
	// }

	// async grantCredentialAdminRole(id: string) {
	// 	var reqBody = {
	// 		isAppCredentialAdminRoleGranted: true
	// 	};
	// 	return this.patch(`ThirdPartyApplications/${id}/applicationAdministratorRole`, reqBody);
	// }

	async getChangeHistory(id: string) {
		return this.get<ChangeHistory[]>(`ThirdPartyApplications/${id}/history`);
	}

	async import(apps: []) {
		return this.post(`ThirdPartyApplications/import`, apps);
	}

	// eslint-disable-next-line class-methods-use-this
	async parse(file: File) {
		const formData = new FormData();
		formData.append(file.name, file);
		const token = await getToken();		

		return new Promise(async (resolve, reject) => 
			fetch(`/api/v1.0/ThirdPartyApplications/parse`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'ThirdPartyApplications/json, */*',
				},
				body: formData,
			})
				.then((response) => {
					if (!response.ok) throw response;
					return response;
				})
				.then(response => resolve(response.json()))
				.catch(response => response.json())
				.then(response => reject(response))
		);
	}
	
}