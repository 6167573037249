import * as React from 'react';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import SelectField from '../../common/form-controls/SelectField';
import { DoNotDecryptRequestsDirection } from '../../../models/enums/DNDR/DoNotDecryptRequestsDirection';

interface DNDRTypeInputProps {
	scope:  FieldHandler<string>;
}

interface DNDRTypeInputState {}

export class DNDRTypeInput extends React.Component<DNDRTypeInputProps, DNDRTypeInputState> {

	scopeOptions = () => {
		return [
			//{ value:  DoNotDecryptRequestsDirection.SubscriptionToOnPrem.name, text: 'Subscription <-> ExxonMobil Network' },
			{ value: DoNotDecryptRequestsDirection.SubscriptionToInternet.name, text: 'Subscription -> Internet' }
		];
	};

	render() {
		return (
			<SelectField
				label="Scope"
				className="full-width"
				options={this.scopeOptions()}
				{...this.props.scope}
			/>
		);
	}
}


