import * as React from 'react';
import {
	Modal,
	Button,
	Grid,
	TextLink,
	Field,
	XFilledIcon,
	Alert
} from 'react-unity';
import FormModel from '../../../utils/FormModel';
import SelectField from '../../common/form-controls/SelectField';
import TextInputField from '../../common/form-controls/TextInputField';
import TextAreaField from '../../common/form-controls/TextareaField';
import GroupsService from '../../../services/GroupsService';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import './CloudGroupsModal.css';

interface CloudGroupsModalProps {
	visible: boolean;
	onConfirm: (event: React.ChangeEvent<any>) => void;
	onCancel?: (event: React.ChangeEvent<any>) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
}

interface CloudGroupsModalState {
	selectedMembershipType: string;
	name: string;
	description: string;
	membershipRule: string;
	alert: AlertBanner;
	submitting: boolean;
}

export default class CloudGroupsModal extends React.Component<CloudGroupsModalProps, CloudGroupsModalState> {
	form: FormModel;

	groupsService: GroupsService;

	constructor(props: CloudGroupsModalProps) {
		super(props);
		this.state = this.getInitialState();

		this.groupsService = new GroupsService();

		this.form = new FormModel({
			selectedMembershipType: {
				getValue: () => this.state.selectedMembershipType,
				validation: {
					required: true,
				},
			},
			name: {
				getValue: () => this.state.name,
				validation: {
					required: true,
					rules: [
						{
							assert: () => this.state.name.length <= 120,
							message: 'The value must be between 1-120 characters long.',
						}
					],
				},
			},
			description: {
				getValue: () => this.state.description,
				validation: {
					required: true,
					rules: [
						{
							assert: () => this.state.description.length <= 500,
							message: 'The value must be between 1-500 characters long.',
						}
					],
				},
			},
			membershipRule: {
				getValue: () => this.state.membershipRule,
				validation: {
					required: () => this.hasMembershipRule(),
				},
			},
		});
	}

	getInitialState = () => {
		const initialState = {
			selectedMembershipType: 'Assigned',
			name: '',
			description: '',
			membershipRule: '',
			alert: {
				visible: false
			},
			submitting: false,
		};
		
		return initialState;
	};

    resetState = () => {
    	this.setState(this.getInitialState());
    };
	
	componentDidUpdate = (prevProps: CloudGroupsModalProps) => {
		if (this.props.visible && !prevProps.visible) {
			document.getElementById('nameInput').focus();
		}
	};

	hasMembershipRule = (): boolean => {
		return (this.state.selectedMembershipType === 'Dynamic' || this.state.selectedMembershipType === 'Device');
	};

	handleRequest = async (e) => {
		try {
			this.setState({ submitting: true });
			const membershipRule = this.state.membershipRule.length > 0 ? this.state.membershipRule : null;
			await this.groupsService.createGroup(this.state.name, this.state.description, membershipRule);
			this.props.onConfirm(e);
			this.resetState();

		} catch (err) {
			this.setState({
				submitting: false,
				alert: {
					visible: true,
					variant: 'error',
					text: err.response?.data.message
				}
			});
		}

	};

	handleCancel = (e) => {
		this.resetState();
		this.props.onClose(e);
	};

	handleMembershipTypeChange = (e) => {
		const selectedMembershipType = e.target.value;
		this.setState({ selectedMembershipType });

		if (selectedMembershipType === 'Assigned') {
			this.setState({ membershipRule: '' });
		}
	};

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window id="cloud-groups-modal">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>Create a new Entra ID Group</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<TextInputField
							id='nameInput'
							label="Display Name"
							value={this.state.name}
							validation={this.form.fields.name.validation}
							onChange={(e) => this.setState({ name: e.target.value })}
						/>
						<TextAreaField
							id='descriptionInput2'
							label="Description"
							value={this.state.description}
							validation={this.form.fields.description.validation}
							onChange={(e) => this.setState({ description: e.target.value })}
						/>
						<SelectField
							label="Membership Type"
							value={this.state.selectedMembershipType}
							options={[
								'Assigned',
								{ value: 'Dynamic', text: 'Dynamic (for users)' },
								{ value: 'Device', text: 'Dynamic (for devices)' },
							]}
							className="full-width"
							onChange={this.handleMembershipTypeChange}
							validation={this.form.fields.selectedMembershipType.validation}
							note={
								this.hasMembershipRule()
									? 'Dynamically populate members based on a provided rule.'
									: 'Manually manage group membership.'
							}
						/>
						<TextAreaField
							disabled={!this.hasMembershipRule()}
							label="Membership Rule"
							value={this.state.membershipRule}
							validation={this.form.fields.membershipRule.validation}
							onChange={(e) => this.setState({ membershipRule: e.target.value })}
							note="Only for dynamic groups."
						/>
						{this.hasMembershipRule() && (
							<Field>
								<TextLink
									className="em-u-font-style-light em-u-font-size-small-2"
									href="https://docs.microsoft.com/en-us/azure/active-directory/users-groups-roles/groups-dynamic-membership"
									target="_blank"
								>
									Dynamic membership rules for groups in Azure Active Directory
								</TextLink>
							</Field>
						)}
						{this.state.alert.visible &&
							<Alert
								variant={this.state.alert.variant}
								onClose={() => {
									this.setState({
										alert: {
											visible: false,
										}
									});
								}}
							>
								{this.state.alert.text}
							</Alert>}
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button
									variant="secondary"
									disabled={this.state.submitting}
									onClick={this.handleCancel}
								>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button variant="primary" disabled={!this.form.isValid() || this.state.submitting} onClick={this.handleRequest}>
									Create
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}