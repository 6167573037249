import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const WrenchIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <circle
          cx={3.474}
          cy={11.54}
          r={0.526}
          transform="rotate(-45 3.475 11.54)"
          fill="#112"
        />
        <path
          d="M3.474 11.014a.526.526 0 1 0 .372.154.524.524 0 0 0-.372-.154z"
          fill="currentColor"
        />
        <path
          d="M3.081 14.377a2.442 2.442 0 0 1-1.589-4.3l3.969-3.389A4.5 4.5 0 0 1 11.42 1.2l.7.283-2.989 2.986 1.414 1.414 2.989-2.989.283.7a4.463 4.463 0 0 1-1.005 4.831 4.462 4.462 0 0 1-4.486 1.128l-3.391 3.97a2.451 2.451 0 0 1-1.76.853zm6.688-12.5a3.513 3.513 0 0 0-3.258 4.781l.134.334-4.5 3.847a1.441 1.441 0 0 0-.083 2.114 1.5 1.5 0 0 0 1.077.423 1.43 1.43 0 0 0 1.039-.5l3.848-4.5.333.135a3.464 3.464 0 0 0 3.741-.793 3.487 3.487 0 0 0 1-2.977L10.545 7.3 7.717 4.469l2.556-2.556a3.362 3.362 0 0 0-.504-.038zm2.689 6.2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M12 40a3 3 0 0 1-2.121-5.121 3.073 3.073 0 0 1 4.243 0 3 3 0 0 1 0 4.242A2.981 2.981 0 0 1 12 40zm0-4a.989.989 0 0 0-.707.293 1 1 0 0 0 0 1.414 1.021 1.021 0 0 0 1.414 0 1 1 0 0 0 0-1.414A.991.991 0 0 0 12 36z"
          fill="currentColor"
        />
        <path
          d="M11.167 45.5a6.981 6.981 0 0 1-4.924-2.039l-.7-.7a6.961 6.961 0 0 1 .408-10.221l13.51-11.515A13.913 13.913 0 0 1 36.2 2.977a1.95 1.95 0 0 1 1.391 1.4 2.012 2.012 0 0 1-.516 1.939L30.486 12.9a.986.986 0 0 0 0 1.4l4.214 4.215a.988.988 0 0 0 1.4 0l6.591-6.592a2.008 2.008 0 0 1 1.938-.517 1.952 1.952 0 0 1 1.4 1.392 13.913 13.913 0 0 1-18.054 16.741L16.463 43.053a6.983 6.983 0 0 1-5.021 2.447h-.275zm21.443-41a11.913 11.913 0 0 0-11.039 16.45l.276.672-14.6 12.44a4.96 4.96 0 0 0-.291 7.283l.7.7a4.96 4.96 0 0 0 7.284-.291l12.44-14.6.671.276a11.913 11.913 0 0 0 16.041-14.115l-6.582 6.614a2.992 2.992 0 0 1-4.224 0l-4.214-4.215a2.986 2.986 0 0 1 0-4.224l6.591-6.59a11.838 11.838 0 0 0-3.053-.4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <circle
          cx={6.285}
          cy={18.019}
          r={0.969}
          transform="rotate(-45 6.285 18.02)"
          fill="#112"
        />
        <path
          d="M6.285 17.05a.969.969 0 1 0 .685.284.966.966 0 0 0-.685-.284z"
          fill="currentColor"
        />
        <path
          d="M6.115 22.44a3.914 3.914 0 0 1-2.764-1.14l-.344-.344a3.909 3.909 0 0 1 .229-5.738l6.332-5.396a7.3 7.3 0 0 1 7.75-9.343 1.909 1.909 0 0 1 1.61 1.275 1.973 1.973 0 0 1-.462 2.072L15.9 6.391 17.957 8.4l2.521-2.56a1.966 1.966 0 0 1 2.071-.463 1.906 1.906 0 0 1 1.275 1.61 7.271 7.271 0 0 1-2.1 5.914 7.283 7.283 0 0 1-7.245 1.835l-5.391 6.331a3.916 3.916 0 0 1-2.818 1.37zM16.671 2.4a5.461 5.461 0 0 0-3.854 1.593 5.286 5.286 0 0 0-1.153 5.765l.276.672-7.407 6.31a1.908 1.908 0 0 0-.112 2.8l.344.344a1.917 1.917 0 0 0 1.425.557 1.885 1.885 0 0 0 1.375-.669l6.31-7.407.671.276a5.3 5.3 0 0 0 7.288-5.447l-2.506 2.623a1.972 1.972 0 0 1-2.784 0l-2.057-2.056a1.969 1.969 0 0 1 0-2.785l2.563-2.562c-.126-.014-.25-.014-.379-.014zm4.349 9.795z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

WrenchIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

WrenchIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default WrenchIcon;
