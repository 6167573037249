import React, { useState } from 'react';
import { Table } from 'react-unity';
import TAP from '../../models/entities/TAP';
import moment from 'moment';
import TemporaryAccessPasswordService from '../../services/TemporaryAccessPasswordService';
import TAPApprovals from './components/TAPApprovals';
import TAPRequests from './components/TAPRequests';

interface TemporaryAccessPasswordTableRowProps {
    tap: TAP;
    tab: string;
    setTapModal: any;
    setEndModal: any;
    retreiveTaps: any;
    setTapass: any;
    temporaryAccessPasswordService: TemporaryAccessPasswordService;
}


const TemporaryAccessPasswordTableRow = ({tap, tab, setTapModal, setEndModal, retreiveTaps, setTapass, temporaryAccessPasswordService} : TemporaryAccessPasswordTableRowProps) => {

    const [loading, setLoading] = useState<boolean>(false);

    const handleCompleteTAP = async (tapId: number, approve: boolean) => {
        try {
            setLoading(true);
            setEndModal({ 
                visible: true,
                text: approve ? `Approving...` : 'Rejecting...',
                variant: 'default'
            });
            const response = await temporaryAccessPasswordService.completeTap(tapId, approve);
            setEndModal({ visible: false });
        }
        catch (err) {
            setEndModal({ 
                visible: true,
                text: `An error occurred when approving the TAP request. Please try again later.`,
                variant: 'error'
            });
        }
        retreiveTaps(true);
        setLoading(false);
    }

    const handleGetApprovedTap = async () => {
        try{
            setLoading(true);
            const response = await temporaryAccessPasswordService.getTapAfterApproved();
            setTapass(response.taPass);
            setTapModal(true);
        }
        catch (err){
            setEndModal({ 
                visible: true,
                text: `An error occurred when getting the TAP request. Please try again later.`,
                variant: 'error'
            });
        }
        retreiveTaps(true);
        setLoading(false);
    }
   
    return (
        <>
            <Table.Body.Row>
                {tab == 'My Approvals' ?
                <Table.Body.Cell>{moment(moment.utc(tap.createdTime)).local().format('DD/MM/YYYY, h:mm:ss a')}</Table.Body.Cell>
                : <Table.Body.Cell>{tap.startingTime ? moment(moment.utc(tap.startingTime)).local().format('DD/MM/YYYY, h:mm:ss a') : '-'}</Table.Body.Cell>}
                <Table.Body.Cell className='em-u-text-align-center'>{tap.approver != null ? 'Yes' : 'No'}</Table.Body.Cell>
                <Table.Body.Cell className='em-u-text-align-center'>{tap.approvalStatus}</Table.Body.Cell>
                {tab == 'My Approvals' ?
                <Table.Body.Cell className='em-u-text-align-center'>{tap.requestor?.displayName}</Table.Body.Cell>
                : (tab == 'My TAP Requests' ? <Table.Body.Cell className='em-u-text-align-center'>{tap.approver?.displayName}</Table.Body.Cell>
                : <Table.Body.Cell className='em-u-text-align-center'>{tap.onBehalf?.displayName}</Table.Body.Cell> )}
                <Table.Body.Cell className='em-u-text-align-center' style={{display: 'block'}}>
                    {tab == 'My Approvals'
                        ?
                        <TAPApprovals 
                            tap={tap}
                            handleCompleteTAP={handleCompleteTAP}
                            loading={loading}
                        />
                        :
                        <TAPRequests
                            tap={tap}
                            handleGetApprovedTap={handleGetApprovedTap}
                            loading={loading}
                        />
                    }
                </Table.Body.Cell>
            </Table.Body.Row>
        </>
    );
}

export default TemporaryAccessPasswordTableRow;