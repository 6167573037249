import React from 'react';
import { Table, Button } from 'react-unity';
import { useNavigate } from 'react-router-dom';
import ProgressBarSmall from '../DoNotDecryptRequests/components/ProgressBarSmall';
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest';
import './ApprovalsDPIsTableRow.css';

interface ApprovalsDPIsTableRowProps {
    dpi: DoNotDecryptRequest;
}

const ApprovalsDPIsTableRow = ({ dpi }: ApprovalsDPIsTableRowProps) => {
    const navigate = useNavigate();
	const handleViewDetails = () => {
		navigate(`/doNotDecryptRequests/${dpi.id}`);
	};
    
    return (
		<Table.Body.Row key={dpi.id}>
            <Table.Body.Cell>#{dpi.id}</Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>{dpi.subscriptionRequestName}</Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center center-align'>
                <ProgressBarSmall workflowState={dpi.workflowInstance.currentWorkflowInstanceState.workflowState} dndrSevenDayExemption={dpi.sevenDayExemption}/>
            </Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>
                <Button style={{ width: 120, height: 34 }} onClick={handleViewDetails} variant="primary">
                    See Details
                </Button>
            </Table.Body.Cell>
        </Table.Body.Row>
    );
}

export default ApprovalsDPIsTableRow;