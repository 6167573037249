import ApiClient from './ApiClient';
import CartaIdInfo from '../models/viewModels/CartaId/CartaIdInfo';
import CartaObjectLookup from '../models/viewModels/CartaId/CartaObjectLookup';

export default class CartaService extends ApiClient {

	async getCartaIdInfo(type: string, id: string): Promise<CartaIdInfo> {
		const url = `cartaId?type=${type}&id=${id}`;
		return this.get<CartaIdInfo>(url);
	}

	async searchCartaObjects(searchString: string, type: string) {
		return this.get<CartaObjectLookup[]>(`cartaId/DBMLookup?searchString=${searchString}&type=${type}`);
	}
}
