import React, { useState, useEffect } from 'react';
import { Button, Searchbar, Select, Toolbar } from 'react-unity';
import DoNotDecryptRule from '../../../models/entities/DoNotDecryptRule';				
import { DoNotDecryptRequestsDirection } from '../../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import CheckboxFilter, { CheckboxOption } from '../../common/tables/CheckboxFilter';
import Filter from '../../common/tables/Filter';
import styled from 'styled-components';

interface ImplementedRulesTableFiltersProps {
	onFilterChange: any;
	rules: DoNotDecryptRule[];				
    selectedScope: string;
}

export type RulesFilters = {
	//action: CheckboxOption[];
	region: CheckboxOption[];
	orderBy: string;
	searchBox: string;
};

const MyP = styled.p`
	margin-right: 8px;
	margin-bottom: auto !important;
	margin-top: auto !important;
`;

const StyledItem = styled(Toolbar.Item)`
	width: 50%;
`;

const MyLink = styled.span`
	color: #0c69b0;
	text-decoration: underline;
	cursor: pointer;
`;

const ImplementedRulesTableFilters = ({
	rules,
	onFilterChange,
    selectedScope
}: ImplementedRulesTableFiltersProps) => {
	const getDefaultFilters = () => {
		const defaultFilters = {
			// action: [
			// 	{ label: 'Allow', value: 'Allow', checked: true },
			// 	{ label: 'Block', value: 'Block', checked: true },
			// ],
			region: [
				{
					label: 'USSC',
					value: 'USSC',
					checked: true,
				},
				{
					label: 'USE1',
					value: 'USE1',
					checked: true,
				},
				{
					label: 'ASST',
					value: 'ASST',
					checked: true,
				},
			],
			orderBy: 'newest',
			searchBox: '',
		} as RulesFilters;

		return defaultFilters;
	};

	const filterPORs = () => {
		let filteredRequests = [];

		const filterCallback = (rule: DoNotDecryptRule) => {				
			return (
				//filter.filterCheckBox('action', rule.action.name) &&
				filter.filterCheckBox('region', rule.region.name) &&
				(filter.filterSearchText('searchBox', rule.name) ||
					filter.filterSearchText('searchBox', rule.description) ||
					filter.filterSearchText('searchBox', rule.sourceIPs) ||
					//filter.filterSearchText('searchBox', rule.destinationIPs) ||
					filter.filterSearchText('searchBox', rule.destinationURLs) 
					//filter.filterSearchText('searchBox', rule.destinationPorts)
					)
			);
		};

		filteredRequests = rules.filter(filterCallback).sort((a, b) => {
			if (filter.filters.orderBy === 'newest') {
				return a.id < b.id ? 1 : -1;
			}
			return a.id > b.id ? 1 : -1;
		});
		onFilterChange(filteredRequests);
	};

	const [filter] = useState<Filter<RulesFilters>>(new Filter(filterPORs, getDefaultFilters()));

    useEffect(() => {
        setFilterOptions();
		if(selectedScope === DoNotDecryptRequestsDirection.SubscriptionToInternet.name) filter.updateFilter([...filter.filters.region, diffRegionScoped], 'region');
    }, [rules])

	// const diffActionScoped = {
	// 	deny: { label: 'Deny', value: 'Deny', checked: true },
	// 	block: { label: 'Block', value: 'Block', checked: true },
	// };

	const diffRegionScoped = {
		label: 'ASEA',
		value: 'ASEA',
		checked: true,
	};

	const setFilterOptions = () => {
		if (selectedScope === DoNotDecryptRequestsDirection.SubscriptionToInternet.name) {
			
		} else {
			//if (filter.filters.action.some((act) => act.value === diffActionScoped.deny.value)) return;
			
		}
	};

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchBox');
	};

	return (
		<>
			{filter && (
				<>
					<Toolbar style={{ backgroundColor: 'white', borderTopColor: 'white' }}>
						<StyledItem>
							<Searchbar
								condensed
								buttonProps={{ onClick: clearSearchbar}}
								inputProps={{
									value: filter.filters.searchBox,
									onChange: (e) => filter.handleSearchTextChange(e, 'searchBox'),
									placeholder: 'What are you looking for?',
								}}
							/>
						</StyledItem>
					</Toolbar>
					<Toolbar>
						<Toolbar.Item>
							{/* <CheckboxFilter
								label="Action"
								buttonProps={{ size: 'small' }}
								onChange={(event) => filter.handleCheckboxChange(event, 'action')}
							//	options={filter.filters.action}
							/> */}
						</Toolbar.Item>
						<Toolbar.Item>
							<CheckboxFilter
								label="Region"
								onChange={(event) => filter.handleCheckboxChange(event, 'region')}
								options={filter.filters.region}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<MyLink onClick={() => filter.updateFilters(getDefaultFilters())}>Clear filters</MyLink>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button.Group>
								<MyP>Sort by</MyP>
								<Select
									style={{ marginBottom: -10 }}
									value={filter.filters.orderBy}
									onChange={(e) => filter.handleSearchTextChange(e, 'orderBy')}
								>
									<option value="newest">Newest</option>
									<option value="oldest">Oldest</option>
								</Select>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default ImplementedRulesTableFilters;
