import * as React from 'react';
import {
	Button,
	Card,
	PowerCordIcon,
	TrashcanEmptyIcon,
	Grid,
	SolidCard,
	TextPassage,
	SolidCardBody,
	BulletedList,
	Checkbox
} from 'react-unity';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import ToggleField from '../../common/form-controls/ToggleField';
import SelectField from '../../common/form-controls/SelectField';
import TextInputField from '../../common/form-controls/TextInputField';
import GroupLookup from '../../common/form-controls/GroupLookup';
import { authenticatedUser } from '../../../authentication/authModule';
import { UserRole } from '../../../models/enums/UserRole';
import VirtualNetworkModal from '../VirtualNetworkModal';
import UserLookup from '../../common/form-controls/UserLookup';
import { VNetConfiguration } from '../../../models/viewModels/Subscriptions/VNetConfiguration';
import { SubnetConfiguration } from '../../../models/viewModels/Subscriptions/SubnetConfiguration';
import ServicePrincipalLookup from '../../common/form-controls/ServicePrincipalLookUp';

interface SubscriptionTypeInputProps {
	subscriptionType: FieldHandler<boolean>,
	amountOfDevices: FieldHandler<number | null>,
	region: FieldHandler<string | null>,
	initialResourceGroup: FieldHandler<string | null>,
	resourceGroupOwner: FieldHandler<string | null>,
	resourceGroupOwnerType: FieldHandler<string | null>,
	contributorAccessGroup: FieldHandler<string | null>,
	virtualNetwork: FieldHandler<VNetConfiguration[] | null>,
	disabled?: boolean;
	hideNotes?: boolean;
	requesting?: boolean;
}

interface SubscriptionTypeInputState {
	virtualNetworkConfiguration: boolean;
	regionSelected: string;
	vNetConfig: VNetConfiguration[];
	editingVNet: VNetConfiguration;
	editing: boolean;
}

export class SubscriptionTypeInput extends React.Component<SubscriptionTypeInputProps, SubscriptionTypeInputState> {

	constructor(props: SubscriptionTypeInputProps) {
		super(props);
		this.state = {
			virtualNetworkConfiguration: false,
			regionSelected: 'USSC',
			vNetConfig: [],
			editingVNet: null,
			editing: false,
		}
		this.handler = this.handler.bind(this)
		this.setEditing = this.setEditing.bind(this)
	}

	getResourceGroupNote(): string {
		const rgName = this.props.initialResourceGroup.value || '';
		const rgSuffix = '-rg';
		const rgFullName = rgName.endsWith(rgSuffix) ? rgName : rgName.concat(rgSuffix);
		return !!rgName ? `The resource group name will be: ${rgFullName}` : '';
	}

	handleConfirm = () => {
		this.setState({ virtualNetworkConfiguration: false })
	}

	handleInputChange = () => {
		this.setState({ virtualNetworkConfiguration: true, regionSelected: 'USSC' })
	}

	handler = (e: VNetConfiguration) => {
		let { vNetConfig } = this.state;
		this.setState({
			editingVNet: e
		});
		if (this.state.editing) {
			let filtered = this.state.vNetConfig.filter(element => element.region != e.region)
			filtered = [...filtered, e]
			this.setState({ vNetConfig: filtered, editing: false });
			this.props.virtualNetwork?.onChange(filtered);
		}
		else {
			this.setState({ vNetConfig: [...vNetConfig, e], editing: false });
			this.props.virtualNetwork?.onChange([...vNetConfig, e]);
		}
	}

	setEditing = () => {
		this.setState({
			editing: false
		});
	}

	handleRemoveVNetConfig = (subnet: any) => {
		if (this.state.vNetConfig?.length == 1) {
			this.setState({ vNetConfig: [] });
			this.props.virtualNetwork?.onChange([]);
		}
		else {
			const filtered = this.state.vNetConfig.filter(e => e != subnet)
			this.props.virtualNetwork?.onChange(filtered);
			this.setState({ vNetConfig: filtered });
		}
	}

	handleRemoveVNet = (e: any, vNet: VNetConfiguration) => {
		e.stopPropagation();
		this.handleRemoveVNetConfig(vNet);
	}

	handleChangeType = (e) => {
		this.props.resourceGroupOwner.onChange(null);
		this.props.resourceGroupOwnerType?.onChange(e.target.value);
	}

	render() {

		const darkModeEnabled = localStorage.getItem('theme') === 'dark';

		return (
			<>
				<Grid>
					<Grid.Item id="subscription-type-field">
						<ToggleField
							id="subscription-type"
							label="Subscription Type"
							{...this.props.subscriptionType}
							disabled={this.props.disabled
								|| (!authenticatedUser.isInRole(UserRole.ConnSubUser)
									&& !authenticatedUser.isInRole(UserRole.SubscriptionAdmin))}
							value={this.props.subscriptionType.value?.toString()}
							options={!this.props.disabled
								&& (authenticatedUser.isInRole(UserRole.ConnSubUser)
									|| authenticatedUser.isInRole(UserRole.SubscriptionAdmin)) ?
								[
									{ value: 'true', text: 'Connected' },
									{ value: 'false', text: 'Disconnected' },
								]
								:
								this.props.subscriptionType.value ?
									[{ value: 'true', text: 'Connected' }]
									:
									[{ value: 'false', text: 'Disconnected' }]
							}
							note={!this.props.hideNotes &&
								<p>Disconnected: will not have direct network
									connectivity to the on premise L4 network.<br />
									Connected: will have network access to the L4 network
									via non-HTTP/S protocols as well as the ability to call APIs through APIM.
								</p>}
						/>
					</Grid.Item>
				</Grid>
				{
					(this.props.subscriptionType.value) &&
					<>
						<Grid variant="halves">
							<Grid.Item>
								<SelectField
									label="Amount of Devices"
									className="full-width"
									disabled={this.props.disabled
										|| !(authenticatedUser.isInRole(UserRole.SubscriptionAdmin) || authenticatedUser.isInRole(UserRole.ConnSubUser))}
									options={
										[
											{ value: 27, text: 'Less than 30' },
											{ value: 26, text: '30 to 60' },
											{ value: 25, text: '61 to 120' },
											{ value: 24, text: '121 to 250' },
										]
									}
									note={!this.props.hideNotes && 'Estimated number of IP addresses needed for this connected subscription. The provisioning process will reserve a block of IP\'s to attach the subnet to the VNET.'}
									{...this.props.amountOfDevices}
								/>
							</Grid.Item>
							<Grid.Item>
								<SelectField
									label="Region"
									className="full-width"
									disabled={this.props.disabled
										|| !(authenticatedUser.isInRole(UserRole.SubscriptionAdmin) || authenticatedUser.isInRole(UserRole.ConnSubUser))}
									options={
										[
											{ value: 'USSC', text: 'USSC - South Central US' }
										]
									}
									note={!this.props.hideNotes && 'The region that your connected VNET will be peered to. All VNET bound resources will need to be created in this region.'}
									{...this.props.region}
								/>
							</Grid.Item>
						</Grid>
						<Grid variant="halves">
							<Grid.Item>
								<TextInputField
									id='resource-group-name-field'
									disabled={this.props.disabled
										|| !(authenticatedUser.isInRole(UserRole.SubscriptionAdmin) || authenticatedUser.isInRole(UserRole.ConnSubUser))}
									label="Resource Group Name"
									note={this.getResourceGroupNote()}
									{...this.props.initialResourceGroup}
								/>
							</Grid.Item>
							<Grid.Item>
								<GroupLookup
									label="Contributor Access Group"
									disabled={this.props.disabled
										|| !(authenticatedUser.isInRole(UserRole.SubscriptionAdmin) || authenticatedUser.isInRole(UserRole.ConnSubUser)) && this.props.contributorAccessGroup.value != null}
									placeholder="Group Name"
									note="This must be an existing group in AAD"
									{...this.props.contributorAccessGroup}
								/>
							</Grid.Item>
						</Grid>
						<Grid variant="halves">
							<Grid.Item>
								<ToggleField
									id="resoruce-group-owner-type"
									label="Resoruce Group Owner Type"
									className="full-width"
									value={this.props.resourceGroupOwnerType?.value}
									options={[
										{ value: 'ServicePrincipals', text: 'Service Principal' },
										{ value: 'Groups', text: 'Group' },
										{ value: 'Users', text: 'User' }
									]}
									disabled={(!(authenticatedUser.isInRole(UserRole.SubscriptionAdmin)
										|| authenticatedUser.isInRole(UserRole.ConnSubUser))) || this.props.requesting}
									note="Select the resource group owner type"
									onChange={(e) => this.handleChangeType(e)}
									{...this.props.resourceGroupOwnerType}
								/>
							</Grid.Item>
							<Grid.Item>
								{
									{
										'ServicePrincipals':
											<ServicePrincipalLookup
												label="Resource Group Owner"
												disabled={(!(authenticatedUser.isInRole(UserRole.SubscriptionAdmin)
													|| authenticatedUser.isInRole(UserRole.ConnSubUser))
													&& this.props.resourceGroupOwner.value != null) || this.props.requesting}
												placeholder="Service Principal Name"
												note="This must be an existing Service Principal in AAD"
												{...this.props.resourceGroupOwner}
											/>,
										'Groups':
											<GroupLookup
												label="Resource Group Owner"
												disabled={( !(authenticatedUser.isInRole(UserRole.SubscriptionAdmin)
													|| authenticatedUser.isInRole(UserRole.ConnSubUser))
													&& this.props.resourceGroupOwner.value != null) || this.props.requesting}
												placeholder="Group Name"
												note="This must be an existing group in AAD"
												{...this.props.resourceGroupOwner}
											/>,
										'Users':
											<UserLookup
												label="Resource Group Owner"
												disabled={(!(authenticatedUser.isInRole(UserRole.SubscriptionAdmin)
													|| authenticatedUser.isInRole(UserRole.ConnSubUser))
													&& this.props.resourceGroupOwner.value != null) || this.props.requesting}
												placeholder="User Name"
												note="This must be an existing user in AAD"
												{...this.props.resourceGroupOwner}
											/>,
									}[this.props.resourceGroupOwnerType?.value]
								}
							</Grid.Item>
						</Grid>
						{!this.props.requesting &&
							<>
								<Grid variant="halves">
									<Grid.Item>
										<TextPassage>
											<h2>Virtual Network Configuration</h2>
										</TextPassage>
									</Grid.Item>
								</Grid>
								<Grid variant="halves">
									<Grid.Item>
										<Checkbox
											style={{ borderBottom: '0px solid blue', borderTop: '0px solid blue', textDecoration: 'bold' }}
											key={1}
											disabled={this.state.vNetConfig?.find(e => e?.region == 'USSC') != undefined}
											value={''}
											label={"South Central US (USSC)"}
											checked={this.state.vNetConfig?.find(e => e?.region == 'USSC') != undefined}
											onClick={(e) => this.setState({ virtualNetworkConfiguration: true, regionSelected: 'USSC' })}
										/>
									</Grid.Item>
									<Grid.Item>
										<Checkbox
											style={{ borderBottom: '0px solid blue', borderTop: '0px solid blue' }}
											key={2}
											disabled={this.state.vNetConfig?.find(e => e?.region == 'USE1') != undefined}
											value={''}
											label={"East US (USE1)"}
											checked={this.state.vNetConfig?.find(e => e?.region == 'USE1') != undefined}
											onClick={() => this.setState({ virtualNetworkConfiguration: true, regionSelected: 'USE1' })}
										/>
									</Grid.Item>
									<Grid.Item>
										<Checkbox
											style={{ borderBottom: '0px solid blue', borderTop: '0px solid blue' }}
											key={3}
											disabled={this.state.vNetConfig?.find(e => e?.region == 'ASST') != undefined}
											value={''}
											label={"South East Asia (ASST)"}
											checked={this.state.vNetConfig?.find(e => e?.region == 'ASST') != undefined}
											onClick={() => this.setState({ virtualNetworkConfiguration: true, regionSelected: 'ASST' })}
										/>
									</Grid.Item>
								</Grid>
								<Card.List>
									{this.state.vNetConfig?.sort((a, b) => a.region < b.region ? 1 : -1).map((vNet: VNetConfiguration, index: number) =>
										<SolidCard
											className={'em-c-custom-pointer'}
											onClick={() => this.setState({
												virtualNetworkConfiguration: true,
												regionSelected: vNet.region,
												editingVNet: vNet,
												editing: true
											})}
											key={index}
										>
											<SolidCardBody className={!darkModeEnabled ? null : 'em-c-solid-card__body-dark'} >
												<PowerCordIcon size="medium" />
												<SolidCard.Body.Title>{vNet?.region}</SolidCard.Body.Title>
												<SolidCard.Body.Kicker>{vNet?.size}&nbsp;</SolidCard.Body.Kicker>
											</SolidCardBody>
											<SolidCard.Footer className="content-section" style={!darkModeEnabled ? null : { color: 'white' }}>
												<BulletedList>
													{vNet?.subnets.map((subnet: SubnetConfiguration, index: number) => (
														<BulletedList.Item key={index}>
															<p>{`${subnet.name} (*/${subnet.size})`}</p>
														</BulletedList.Item>
													))}
												</BulletedList>
												<Button
													className="small-margin button-section"
													variant="primary"
													color="accent"
													onClick={(e) => this.handleRemoveVNet(e, vNet)}>
													<TrashcanEmptyIcon size="small" />
													<span><b>REMOVE SUBNET</b>&nbsp;</span>
												</Button>
											</SolidCard.Footer>
										</SolidCard>
									)}
								</Card.List>
							</>}
					</>
				}
				<VirtualNetworkModal
					visible={this.state.virtualNetworkConfiguration}
					onCancel={() => this.setState({ virtualNetworkConfiguration: false })}
					onClose={() => this.setState({ virtualNetworkConfiguration: false })}
					onConfirm={() => this.handleConfirm()}
					regionSelected={this.state.regionSelected}
					setVNetConfig={this.handler}
					setEditing={this.setEditing}
					vNetConfig={this.state.editingVNet}
					editing={this.state.editing}
				/>
			</>
		);
	}
}