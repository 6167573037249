import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OffshoreRig = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M2,6h2v2h1V5.5C5,5.2,4.8,5,4.5,5h-3C1.2,5,1,5.2,1,5.5V8h1V6z"
          />
          <path
            fill="currentColor"
            d="M13.9,14.7C13.5,14.4,13,14,12.2,14c-0.8,0-1.2,0.4-1.5,0.7c-0.2,0.2-0.4,0.3-0.6,0.3
		c-0.1,0-0.3-0.2-0.6-0.3C9.2,14.4,8.7,14,7.9,14c-0.8,0-1.2,0.4-1.5,0.7C6.2,14.9,6.1,15,5.8,15c-0.1,0-0.3-0.2-0.6-0.3
		C4.9,14.4,4.3,14,3.6,14c-0.8,0-1.2,0.4-1.5,0.7C1.9,14.9,1.7,15,1.5,15H1v1h0.5c0.7,0,1-0.4,1.3-0.6C3,15.2,3.2,15,3.6,15
		c0.4,0,0.8,0.2,1.1,0.5C5,15.7,5.4,16,5.8,16c0.7,0,1-0.4,1.3-0.6C7.4,15.2,7.5,15,7.9,15c0.4,0,0.8,0.2,1.1,0.5
		c0.3,0.2,0.7,0.5,1.2,0.5c0.7,0,1-0.4,1.3-0.6c0.2-0.2,0.4-0.4,0.8-0.4c0.4,0,0.8,0.2,1.1,0.5c0.3,0.2,0.7,0.5,1.2,0.5H15v-1h-0.5
		C14.4,15,14.2,14.8,13.9,14.7z"
          />
          <path
            fill="currentColor"
            d="M7.5,4.1L10,7.7l0,0.3l1-0.1L10,0.4C10,0.2,9.7,0,9.5,0h-2C7.3,0,7,0.2,7,0.4L5.9,7.8l1,0.1L7.5,4.1z
		 M9.1,1l0.6,4.5l-2-2.9L7.9,1H9.1z"
          />
          <path
            fill="currentColor"
            d="M13,5.7l2.7-2.2l-0.6-0.8l-2.9,2.3C12.1,5.2,12,5.3,12,5.5V8h1V5.7z"
          />
          <polygon
            fill="currentColor"
            points="0.5,10 1,10 1,13 2,13 2,10 3,10 3,13 4,13 4,10 12,10 12,13 13,13 13,10 14,10 14,13 15,13 15,10 
		15.5,10 15.5,9 0.5,9 	"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M44.8,45.4C44.1,44.8,43,44,41.4,44c-1.5,0-2.4,0.8-3,1.3C38,45.8,37.7,46,37.2,46c-0.3,0-0.7-0.3-1.2-0.6
		c-0.8-0.6-1.9-1.4-3.4-1.4c-1.5,0-2.4,0.8-3,1.3c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6c-0.8-0.6-1.9-1.4-3.4-1.4
		c-1.5,0-2.4,0.8-3,1.3c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6C17.7,44.8,16.6,44,15,44c-1.5,0-2.4,0.8-3,1.3
		c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6C8.9,44.8,7.8,44,6.2,44c-1.5,0-2.4,0.8-3,1.3C2.8,45.8,2.5,46,2,46H1v2h1
		c1.3,0,2.1-0.7,2.6-1.2C5.1,46.3,5.5,46,6.2,46c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2
		c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8
		c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1
		c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1h1v-2h-1C45.7,46,45.3,45.7,44.8,45.4z"
          />
          <path
            fill="currentColor"
            d="M47.8,13.6c0.2-0.3,0.3-0.7,0.1-1C47.7,12.2,47.4,12,47,12h-3c-0.2,0-0.5,0.1-0.7,0.3l-9,8.1
		c-0.2,0.2-0.3,0.5-0.3,0.7v7h-2L29,3.1c-0.1-0.5-0.5-0.9-1-0.9h-2V0h-2v2.2h-2c-0.5,0-0.9,0.4-1,0.9l-3,24.9h-2V19c0-0.6-0.4-1-1-1
		H4c-0.6,0-1,0.4-1,1v9.1H2c-0.6,0-1,0.4-1,1v7c0,0.6,0.4,1,1,1h2V43h2v-5.9h4V43h2v-5.9h24V43h2v-5.9h4V43h2v-5.9h2
		c0.6,0,1-0.4,1-1v-7c0-0.6-0.4-1-1-1h-5v-5.5L47.8,13.6z M44.4,14H45l-5.8,7.6C39.1,21.8,39,22,39,22.2v1.9l-2.1-3.4L44.4,14z
		 M36,23l3,4.9v0.1h-3V23z M22,11.8l1.9,3.5l-3,5.5L22,11.8z M29.1,20.8l-3-5.5l1.9-3.5L29.1,20.8z M29.8,26.2
		C29.8,26.2,29.8,26.2,29.8,26.2l0.2,1.8h-4v-8.8L29.8,26.2z M27.1,4.2l0.5,4.1c0,0,0,0,0,0.1L26,11.3V4.2H27.1z M24,4.2v7.1
		l-1.6-2.9c0,0,0,0,0-0.1l0.5-4.1H24z M20.2,26.2C20.2,26.2,20.2,26.2,20.2,26.2l3.8-7v8.8h-4L20.2,26.2z M5,20h9v8.1H5V20z
		 M45,35.1H3v-5h42V35.1z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M3,10h3v3h2V9c0-0.6-0.4-1-1-1H2C1.4,8,1,8.4,1,9v4h2V10z"
          />
          <path
            fill="currentColor"
            d="M21.5,21.7c-0.5-0.3-1.2-0.7-2.2-0.7c-1,0-1.6,0.5-1.9,0.8C17.2,22,17.2,22,17,22c-0.1,0-0.3-0.2-0.5-0.3
		c-0.5-0.3-1.2-0.7-2.2-0.7c-1,0-1.6,0.5-1.9,0.8C12.2,22,12.2,22,12,22c-0.1,0-0.3-0.2-0.5-0.3c-0.5-0.3-1.2-0.7-2.2-0.7
		c-1,0-1.6,0.5-1.9,0.8C7.2,22,7.2,22,7,22c-0.1,0-0.3-0.2-0.5-0.3C6.1,21.4,5.4,21,4.4,21c-1,0-1.6,0.5-1.9,0.8C2.2,22,2.2,22,2,22
		H1v2h1c0.9,0,1.4-0.4,1.7-0.7C4,23.1,4.1,23,4.4,23c0.4,0,0.7,0.2,1.1,0.4C5.9,23.7,6.4,24,7,24c0.9,0,1.4-0.4,1.7-0.7
		C9,23.1,9.1,23,9.4,23c0.4,0,0.7,0.2,1.1,0.4c0.4,0.3,0.9,0.6,1.5,0.6c0.9,0,1.4-0.4,1.7-0.7c0.3-0.2,0.4-0.3,0.7-0.3
		c0.4,0,0.7,0.2,1.1,0.4c0.4,0.3,0.9,0.6,1.5,0.6c0.9,0,1.4-0.4,1.7-0.7c0.3-0.2,0.4-0.3,0.7-0.3c0.4,0,0.7,0.2,1.1,0.4
		c0.4,0.3,0.9,0.6,1.5,0.6h1l0-2h-1C21.9,22,21.7,21.8,21.5,21.7z"
          />
          <path
            fill="currentColor"
            d="M10.9,13.1l0.5-5.4l2.6,4.3l0.1,1.1l2-0.2L15,0.9c0-0.5-0.5-0.9-1-0.9h-3c-0.5,0-0.9,0.4-1,0.9L9.7,4.7l0,0
		l0,0l-0.7,8.2L10.9,13.1z M13.1,2l0.5,5.4l-1.9-3.1L11.9,2H13.1z"
          />
          <path
            fill="currentColor"
            d="M19,9.6l4.3-2.2l-0.9-1.8l-4.9,2.4C17.2,8.3,17,8.6,17,9v4h2V9.6z"
          />
          <polygon
            fill="currentColor"
            points="2,20 4,20 4,16 6,16 6,20 8,20 8,16 16,16 16,20 18,20 18,16 20,16 20,20 22,20 22,16 23,16 23,14 
		22,14 20,14 18,14 16,14 8,14 6,14 4,14 2,14 1,14 1,16 2,16 	"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OffshoreRig.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OffshoreRig.defaultProps = {
  size: "medium",

  className: ""
};

export default OffshoreRig;
