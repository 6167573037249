import ApiClient from './ApiClient';
import PolicyExemptionRequest from '../models/entities/PolicyExemptionRequest';
import SubmitPolicyExemptionRequest from '../models/viewModels/PolicyExemptionRequests/SubmitPolicyExemptionRequest';


export default class PolicyExemptionRequestService extends ApiClient {

	async getById(id: number) {
		return this.get<PolicyExemptionRequest>(`policyExemptionRequests/${id}`);
	}

	async create(model: SubmitPolicyExemptionRequest) {
		return this.post<PolicyExemptionRequest[]>(`policyExemptionRequests`, model)
	}

}
