import { Enumeration } from '../../utils/AppEnum';

export class ApprovalType extends Enumeration<ApprovalType> {

    public static SubscriptionOwner = new ApprovalType('SubscriptionOwner', 1);

    public static Production = new ApprovalType('SecurityCouncil', 2);

    public static MACC = new ApprovalType('MACC', 3);

    public static ITOperationsManager = new ApprovalType('IT Operations Manager', 4);

    public static MAPManager = new ApprovalType("MA&P Manager, ITRM Manager, Requestor's DOAG 7 Approver", 5);

    public static CloudArchitect = new ApprovalType("Cloud Architect",6); 
}
