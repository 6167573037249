import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowUpIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 4.5h1v10H8z" />
        <path
          d="M12.4 6.528l-3.8-3.5a.162.162 0 0 0-.2.009L4.6 6.528l-.677-.736 3.8-3.5a1.168 1.168 0 0 1 1.534-.009l3.815 3.51z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M35 18.914L24.707 8.621a1.025 1.025 0 0 0-1.414 0L13 18.914 11.586 17.5 21.879 7.207a3 3 0 0 1 4.242 0L36.414 17.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 12h2v33h-2z" />
      </svg>
    ) : (
      <svg viewBox="0 0 24 24">
        <path
          d="M18.067 9.458L12 4.312 5.933 9.458 4.639 7.933l6.067-5.147a1.994 1.994 0 0 1 2.588 0l6.067 5.147z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 7h2v16h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowUpIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowUpIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowUpIcon;
