import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import HeroBlockBody from "./HeroBlockBody";
import HeroBlockTitle from "./HeroBlockTitle";
import HeroBlockDescription from "./HeroBlockDescription";

function getVariant(variant) {
  switch (variant) {
    case "bare":
      return "em-c-hero--bare";
    case "tinted":
      return "em-c-hero--tinted";
    default:
      return "";
  }
}

/**
 * Large-format unit to promote content with a high-impact image
 *
 * @visibleName Hero block
 * */
const HeroBlock = props => {
  const { variant, backgroundImage, className, children, ...other } = props;

  return (
    <div
      className={clsx("em-c-hero", getVariant(variant), className)}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      {...other}
    >
      {children}
    </div>
  );
};

HeroBlock.Body = HeroBlockBody;
HeroBlock.Title = HeroBlockTitle;
HeroBlock.Description = HeroBlockDescription;

HeroBlock.propTypes = {
  /** Background image used for the hero block */
  backgroundImage: PropTypes.string.isRequired,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Default shows a linear vinette, Tinted applies a gray tint, and Bare removes all overlay */
  variant: PropTypes.oneOf(["default", "tinted", "bare"]),
  /** React component children */
  children: PropTypes.node
};

HeroBlock.defaultProps = {
  /** Location of background image */
  backgroundImage: "",
  /** Remove the default gradient overlay or replace it with a solid, uniform tint. */
  variant: "default",
  className: "",
  children: null
};

export default HeroBlock;
