import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowMultiDirectionIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M8 1.5h1v4H8zM8 11.5h1v4H8zM11.5 8h4v1h-4zM1.5 8h4v1h-4z"
        />
        <path
          d="M10.48 3.927L8.613 2.06a.16.16 0 0 0-.227 0L6.52 3.927l-.707-.707L7.68 1.353a1.162 1.162 0 0 1 1.641 0l1.866 1.867zM13.78 11.187l-.707-.707 1.867-1.867a.16.16 0 0 0 0-.227L13.073 6.52l.707-.707 1.867 1.867a1.162 1.162 0 0 1 0 1.641zM3.22 11.187L1.353 9.32a1.162 1.162 0 0 1 0-1.641L3.22 5.813l.707.707L2.06 8.387a.16.16 0 0 0 0 .227l1.867 1.866zM8.5 15.986a1.157 1.157 0 0 1-.82-.339L5.813 13.78l.707-.707 1.867 1.867a.16.16 0 0 0 .227 0l1.867-1.867.707.707-1.868 1.867a1.157 1.157 0 0 1-.82.339z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23 3h2v14h-2z" />
        <path
          d="M30 8.914l-5.936-5.935a.092.092 0 0 0-.129 0L18 8.914 16.586 7.5l5.936-5.936a2.093 2.093 0 0 1 2.957 0L31.414 7.5zM23 32h2v13h-2z"
          fill="currentColor"
        />
        <path
          d="M24 47.047a2.084 2.084 0 0 1-1.479-.611L16.586 40.5 18 39.086l5.936 5.936a.092.092 0 0 0 .129 0L30 39.086l1.414 1.414-5.936 5.936a2.084 2.084 0 0 1-1.478.611zM32 23h13v2H32z"
          fill="currentColor"
        />
        <path
          d="M40.5 31.414L39.086 30l5.936-5.936a.091.091 0 0 0 0-.129L39.086 18l1.414-1.414 5.936 5.936a2.093 2.093 0 0 1 0 2.957zM3 23h14v2H3z"
          fill="currentColor"
        />
        <path
          d="M7.5 31.414l-5.936-5.935a2.093 2.093 0 0 1 0-2.957L7.5 16.586 8.914 18l-5.935 5.936a.091.091 0 0 0 0 .129L8.914 30z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M15 5.414l-3-3-3 3L7.586 4l3-3a2 2 0 0 1 2.828 0l3 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11 1h2v8h-2zM20 16.414L18.586 15l3-3-3-3L20 7.586l3 3a2 2 0 0 1 0 2.828z"
        />
        <path
          fill="currentColor"
          d="M15 11h8v2h-8zM4 16.414l-3-3a2 2 0 0 1 0-2.828l3-3L5.414 9l-3 3 3 3z"
        />
        <path
          fill="currentColor"
          d="M1 11h8v2H1zM12 23.585A1.992 1.992 0 0 1 10.586 23l-3-3L9 18.586l3 3 3-3L16.414 20l-3 3a1.992 1.992 0 0 1-1.414.585z"
        />
        <path fill="currentColor" d="M11 15h2v8h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowMultiDirectionIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowMultiDirectionIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowMultiDirectionIcon;
