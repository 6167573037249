import PortOpeningRule from './PortOpeningRule';
import WorkflowInstance from '../WorkflowInstance';
import User from '../User';
import { WorkflowState } from '../enums/WorkflowState';
import { Environment } from '../enums/Environment';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../enums/UserRole';
import { PortOpeningRequestsDirection } from '../enums/POR/PortOpeningRequestsDirection';
import SubscriptionRequest from './SubscriptionRequest';

export default class PortOpeningRequest {

	id!: number;

	portOpeningRules!: PortOpeningRule[];

	workflowInstance!: WorkflowInstance;

	direction!: PortOpeningRequestsDirection;

	environment!: Environment;

	contactUser!: User;

	isEmergency!: boolean;

	incidentNumber!: string;

	submitDate!: Date;

	businessJustification!: string;

	comments!: string;

	approvingUser!: User;

	delegatedApprovingUser!: User;

	hasDelegationApprover!: boolean;

	subscriptionRequestId!: number;
	
	subscriptionRequestName: string;

	customerCoordination!: boolean;

	custodianApproval!: boolean;

	isAttestation: boolean;

	constructor(obj: any) {
		Object.assign(this, obj);
		this.workflowInstance = new WorkflowInstance(obj.workflowInstance);
		this.direction = new PortOpeningRequestsDirection().fromValue(obj.direction?.value);
	}

	implementationTicket(): string {
		const implementationState = this.workflowInstance.workflowInstanceStates
			.reverse()
			.find(state => state.ticketId !== null);
		return implementationState?.ticketId || '';
	}

	isCancellable(): boolean {
		return (
			authenticatedUser.userId === this.workflowInstance.createdBy.id &&
			[
				WorkflowState.Draft.value,
				WorkflowState.PendingSubscriptionOwnerApproval.value,
				WorkflowState.PendingForDOAGApproval.value,
				WorkflowState.PendingForCloudNetworkApproval.value,
				WorkflowState.PendingForGatewayPolicyTeamApproval.value,
				WorkflowState.Review.value,
			].includes(this.workflowInstance.currentWorkflowInstanceState.workflowState.value)
		);
	}

	canViewDOAGApproval(): boolean {
		return authenticatedUser.userId === this.approvingUser?.id;
	}

	canHaveDOAGApproval(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			currentState === WorkflowState.PendingForDOAGApproval.value &&
			(authenticatedUser.userId === this.approvingUser?.id || authenticatedUser.userId === this.delegatedApprovingUser?.id)
		);
	}

	canHaveTechnicalDecision(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			(
				currentState === WorkflowState.PendingForCloudNetworkApproval.value
				&& authenticatedUser.isInRole(UserRole.SubToOnPremTechnicalReviewer)
				
			) ||
			(
				currentState === WorkflowState.PendingForGatewayPolicyTeamApproval.value
				&& authenticatedUser.isInRole(UserRole.SubToInternetTechnicalReviewer)
			)
		);
	}

	canSendToReview(subscription: SubscriptionRequest, workflowInstance: WorkflowInstance): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState;
		return((
				((currentState === WorkflowState.PendingSubscriptionOwnerApproval || currentState === WorkflowState.PendingForCloudNetworkApproval || currentState === WorkflowState.PendingForDOAGApproval || currentState === WorkflowState.PendingForGatewayPolicyTeamApproval) &&
				subscription.owner?.id === authenticatedUser.userId)
				
			) ||
			(
				currentState === WorkflowState.PendingForGatewayPolicyTeamApproval &&
				authenticatedUser.isInRole(UserRole.SubToInternetTechnicalReviewer)
			) ||
			(
				currentState !== WorkflowState.AwaitingImplementation &&
				workflowInstance.createdBy.id === authenticatedUser.userId 
			) &&
				currentState != WorkflowState.Review
			)
	}

	canHaveSubscriptionOwnerDecision(subscription: SubscriptionRequest, hasCustodianApproval: boolean): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState;
		if (hasCustodianApproval){
			return currentState === WorkflowState.PendingSubscriptionOwnerApproval && (
				subscription.owner?.id === authenticatedUser.userId ||
				subscription.custodian?.id === authenticatedUser.userId ||
				subscription.custodianTwo?.id === authenticatedUser.userId
			);
		}
		return (subscription.owner?.id === authenticatedUser.userId &&
				(currentState === WorkflowState.PendingSubscriptionOwnerApproval));
	}



	isEditable(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			authenticatedUser.userId === this.workflowInstance.createdBy.id &&
			[
				WorkflowState.Draft.value,
				WorkflowState.Review.value,
			].includes(currentState)
		);
	}

}