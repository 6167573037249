import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** TableFootRow is the row that contains the cells in the table Footer */
const TableFootRow = props => {
  const { className, children, ...other } = props;

  return (
    <tr className={clsx("em-c-table__footer-row", className)} {...other}>
      {children}
    </tr>
  );
};

TableFootRow.propTypes = {
  /** children are the contents of the TableFootRow */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TableFootRow.defaultProps = {
  children: null,
  className: ""
};

export default TableFootRow;
