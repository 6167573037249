import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**
 * RangeSliderList is a wrapper that adds formatting to multiple range sliders.
 */
const RangeSliderList = props => {
  const { className, children, ...other } = props;

  return (
    <ul className={clsx("em-c-range-slider-list", className)} {...other}>
      {React.Children.toArray(children).map(child => (
        <li className="em-c-range-slider-list__item" key={child.key}>
          {child}
        </li>
      ))}
    </ul>
  );
};

RangeSliderList.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Child React components, in this case 2 buttons */
  children: PropTypes.node
};

RangeSliderList.defaultProps = {
  className: "",
  children: null
};

export default RangeSliderList;
