import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ContactCardIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 14h-11a.5.5 0 0 1-.5-.5V.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5zM3 13h10V1H3zM15 1h1v3h-1zM15 5h1v3h-1z"
          fill="currentColor"
        />
        <path
          d="M15.5 16H3v-1h12V9h1v6.5a.5.5 0 0 1-.5.5zM8 7a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M10.5 10h-5a.5.5 0 0 1-.5-.5V9a3 3 0 0 1 .6-1.8l.3-.4.8.6-.3.4A2 2 0 0 0 6 9h4a2 2 0 0 0-.4-1.2l-.3-.4.8-.6.3.4A3 3 0 0 1 11 9v.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40 42H6a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h34a1 1 0 0 1 1 1v38a1 1 0 0 1-1 1zM6 4a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h33V4zM43 4h2v6h-2zM43 12h2v6h-2z"
          fill="currentColor"
        />
        <path
          d="M44 46H5v-2h38V20h2v25a1 1 0 0 1-1 1zM22 22a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3z"
          fill="currentColor"
        />
        <path
          d="M28 30H16a1 1 0 0 1-1-1v-2a6.945 6.945 0 0 1 1.8-4.671l.671-.741 1.482 1.343-.671.741A4.948 4.948 0 0 0 17 27v1h10v-1a4.948 4.948 0 0 0-1.281-3.329l-.671-.741 1.482-1.343.671.741A6.945 6.945 0 0 1 29 27v2a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 21H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h17a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zM3 3v16h16V3zM22 2h2v4h-2zM22 6h2v4h-2z"
          fill="currentColor"
        />
        <path
          d="M23 24H3v-2h19V10h2v13a1 1 0 0 1-1 1zM11 10.5a2.25 2.25 0 1 1 2.25-2.25A2.252 2.252 0 0 1 11 10.5zM11 8a.25.25 0 0 0-.25.25c0 .275.5.275.5 0A.25.25 0 0 0 11 8z"
          fill="currentColor"
        />
        <path
          d="M14.75 17h-7.5a1 1 0 0 1-1-1v-1.25a4.941 4.941 0 0 1 1.509-3.171l.671-.741 1.483 1.342-.671.741a3.045 3.045 0 0 0-.992 1.829V15h5.5v-.25a3.045 3.045 0 0 0-.991-1.829l-.671-.741 1.482-1.343.671.741a4.941 4.941 0 0 1 1.509 3.172V16a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ContactCardIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ContactCardIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ContactCardIcon;
