import * as React from 'react';
import { Grid } from 'react-unity';
import Organization from '../../../models/entities/Organization';
import SelectField from '../../common/form-controls/SelectField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { getOrganizations } from '../../common/OrganizationStorage';
import ReadonlyField from '../../common/form-controls/ReadonlyField';

interface OrganizationInputProps {
	organizationL3: FieldHandler<string>;
	organizationL4: FieldHandler<string>;
	globalOrganization: FieldHandler<string>;
	disabled?: boolean;
}

interface OrganizationInputState {
	organizationsList: Organization[];
	loading: boolean;
	selectedL4: string;
	selectedL5: string | null;
	assignedCC: string | null;
}

export class OrganizationInput extends React.Component<OrganizationInputProps, OrganizationInputState> {

	constructor(props: OrganizationInputProps) {
		super(props);

		this.state = {
			organizationsList: [],
			loading: true,
			selectedL4: '',
			selectedL5: '',
			assignedCC: '',
		};
	}

	async componentDidMount() {
		const organizations = (await getOrganizations()).map(o => new Organization(o));
		this.setState({
			organizationsList: organizations,
			loading: false,
		}, () => {
			this.setSelectedOrganizations();
			this.setSelectedCostCenter();
		});
		
	}

	componentDidUpdate(prevProps: OrganizationInputProps) {
		if (prevProps.globalOrganization.value !== this.props.globalOrganization.value) {
			this.setSelectedOrganizations();
			this.setSelectedCostCenter();
		}
	}

	setSelectedOrganizations = () => {
		// TODO: improve with organization methods
		const selectedOrganization = this.state.organizationsList.find(org => org.id.toString() === this.props.globalOrganization.value);
		let parentOrganization = this.state.organizationsList.find(org => org.id === selectedOrganization?.parentOrganizationId);
		if (selectedOrganization) {
			if (parentOrganization?.parentOrganizationId != null) {
				this.setState({
					selectedL4: selectedOrganization.findParent(this.state.organizationsList)?.id.toString(),
					selectedL5: selectedOrganization.id.toString(),
				});
				parentOrganization = this.state.organizationsList.find(org => org.id === parentOrganization?.parentOrganizationId);
			
			} else {
				this.setState({
					selectedL4: selectedOrganization.id.toString(),
				});
			}
			this.props.organizationL3.onChange(parentOrganization?.id.toString());
		}
	};

	setSelectedCostCenter = () => {
		const selectedOrganization = this.state.organizationsList.find(org => org.id.toString() === this.props.globalOrganization.value);
		let parentOrganization = this.state.organizationsList.find(org => org.id === selectedOrganization?.parentOrganizationId);
		if (selectedOrganization) {
			if (selectedOrganization.costCenter != null) {//CC assigned at the lowest level L4 / L3
				this.setState({
					assignedCC: selectedOrganization.costCenter,
				});
			}//Has CC assigned or has not parent org (Assigned at L3 / L4 level)
			else if ((parentOrganization.costCenter != null) || (parentOrganization.parentOrganizationId == null)) {
				this.setState({
					assignedCC: parentOrganization.costCenter,
				});
			}//CC assigned at L3 level
			else {
				parentOrganization = this.state.organizationsList.find(org => org.id === parentOrganization.parentOrganizationId);
				this.setState({
					assignedCC: parentOrganization.costCenter,
				});
			}
		}
	};

	organizationOptions = (parent: string | null) => {
		return this.state.organizationsList
			.filter((organization) => organization.findParent(this.state.organizationsList)?.id.toString() == parent && organization.status == 'Active')
			.map((organization) => ({ value: organization.id.toString(), text: organization.displayName })).sort((a, b) => a.value > b.value ? 1 : -1);
	};

	organizationOptionsL5 = (parent: string | null) =>{
		var options = this.organizationOptions(parent);
		options.unshift({value: null, text: ''});
		return options;
	};

	handleL3Change = (event) => {
		this.setState({
			selectedL4: '',
			selectedL5: '',
			assignedCC: null,
		});
		this.props.globalOrganization.onChange(null);
		this.props.organizationL4.onChange(null);
		this.props.organizationL3.onChange(event.target.value);
	};

	handleL4Change = (event) => {
		this.setState({ selectedL4: event.target.value});
		this.props.globalOrganization.onChange(event.target.value);
		this.props.organizationL4.onChange(event.target.value);
	};

	handleL5Change = (event) => {
		this.setState({ selectedL5: event.target.value });
		this.props.globalOrganization.onChange(event.target.value === '' ? this.state.selectedL4 : event.target.value);
	};

	render() {
		return (
			<>
				<Grid variant="halves">
					<Grid.Item>
						<SelectField
							label="L3 Organization"
							options={this.organizationOptions(null)}
							className="full-width"
							{...this.props.organizationL3}
							disabled={this.props.disabled || this.state.loading}
							onChange={this.handleL3Change}
						/>
					</Grid.Item>
					<Grid.Item>
						<SelectField
							label="L4 Organization"
							className="full-width"
							options={this.organizationOptions(this.props.organizationL3.value)}
							{...this.props.organizationL4}
							disabled={!this.props.organizationL3.value || this.props.disabled}
							value={this.state.selectedL4}
							onChange={this.handleL4Change}
						/>
					</Grid.Item>
				</Grid>
				<Grid variant="halves">
					<Grid.Item>
						<SelectField
							label="L5 Organization"
							className="full-width"
							options={this.organizationOptionsL5(this.state.selectedL4)}
							{...this.props.globalOrganization}
							disabled={
								this.organizationOptions(this.state.selectedL4).length < 1
								||
								!this.state.selectedL4
								||
								this.props.disabled
							}
							value={this.state.selectedL5}
							onChange={this.handleL5Change}
						/>
					</Grid.Item>
					<Grid.Item>
						<ReadonlyField
							label="Cost Center"
							text={this.state.assignedCC ? this.state.assignedCC : ''}
						/>
					</Grid.Item>
				</Grid>
			</>
		);
	}
}
