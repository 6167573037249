import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** FieldNote a note for a field on a form. */
const FieldNote = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-field__note", className)} {...other}>
      {children}
    </div>
  );
};

FieldNote.propTypes = {
  /** children is the contents */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

FieldNote.defaultProps = {
  children: null,
  className: ""
};

export default FieldNote;
