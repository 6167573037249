import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TableHeadRow from "./TableHeadRow";
import TableHeadCell from "./TableHeadCell";

/** TableHead is the header row for the table */
const TableHead = props => {
  const { className, children, ...other } = props;

  return (
    <thead className={clsx("em-c-table__header", className)} {...other}>
      {children}
    </thead>
  );
};

TableHead.propTypes = {
  /** children are the contents of the TableHead */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TableHead.defaultProps = {
  children: null,
  className: ""
};

TableHead.Row = TableHeadRow;
TableHead.Cell = TableHeadCell;

export default TableHead;
