import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CarIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3.9 15H2.1A1.133 1.133 0 0 1 1 13.84V13h1v.84c0 .1.059.16.1.16h1.8c.041 0 .1-.062.1-.16V13h1v.84A1.133 1.133 0 0 1 3.9 15zM13.9 15h-1.8a1.133 1.133 0 0 1-1.1-1.16V13h1v.84c0 .1.059.16.1.16h1.8c.041 0 .1-.062.1-.16V13h1v.84A1.133 1.133 0 0 1 13.9 15zM3 11a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1zM13 11a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1zM5 9h6v1H5z"
          fill="currentColor"
        />
        <path
          d="M15.5 13H.5a.5.5 0 0 1-.5-.5v-4A1.5 1.5 0 0 1 1.5 7h.323a.5.5 0 0 0 .464-.314l1.5-3.743A1.493 1.493 0 0 1 5.177 2h5.646a1.494 1.494 0 0 1 1.393.942l1.5 3.744a.5.5 0 0 0 .461.314h.323A1.5 1.5 0 0 1 16 8.5v4a.5.5 0 0 1-.5.5zM1 12h14V8.5a.5.5 0 0 0-.5-.5h-.323a1.494 1.494 0 0 1-1.393-.942l-1.5-3.744A.5.5 0 0 0 10.823 3H5.177a.5.5 0 0 0-.464.314l-1.5 3.743A1.493 1.493 0 0 1 1.823 8H1.5a.5.5 0 0 0-.5.5z"
          fill="currentColor"
        />
        <path
          d="M11.5 8h-7a.5.5 0 0 1-.474-.658l1-3A.5.5 0 0 1 5.5 4h5a.5.5 0 0 1 .474.342l1 3A.5.5 0 0 1 11.5 8zM5.194 7h5.612l-.666-2H5.86z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M12 47H7a3 3 0 0 1-3-3v-4h2v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-4h2v4a3 3 0 0 1-3 3zM41 47h-5a3 3 0 0 1-3-3v-4h2v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-4h2v4a3 3 0 0 1-3 3zM45 39H3a2 2 0 0 1-2-2v-9a5.008 5.008 0 0 1 4.288-4.95l5.289-14.1A3.015 3.015 0 0 1 13.386 7h21.228a3.014 3.014 0 0 1 2.809 1.947l5.289 14.1A5.008 5.008 0 0 1 47 28v9a2 2 0 0 1-2 2zM13.386 9a1.006 1.006 0 0 0-.937.649l-5.513 14.7A1 1 0 0 1 6 25a3 3 0 0 0-3 3v9h42v-9a3 3 0 0 0-3-3 1 1 0 0 1-.937-.649L35.55 9.649A1 1 0 0 0 34.614 9z"
          fill="currentColor"
        />
        <path
          d="M9 35a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM39 35a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM36.023 24h-25a1 1 0 0 1-.94-1.342l4-11a1 1 0 0 1 .94-.658h17a1 1 0 0 1 .94.658l4 11a1 1 0 0 1-.94 1.342zm-23.574-2H34.6l-3.272-9h-15.6zM15 30h18v2H15z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M7 24H4a2 2 0 0 1-2-2v-2h2v2h3v-2h2v2a2 2 0 0 1-2 2zM20 24h-3a2 2 0 0 1-2-2v-2h2v2h3v-2h2v2a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <circle cx={6} cy={15} r={1} fill="currentColor" />
        <path fill="currentColor" d="M9 14h6v2H9z" />
        <circle cx={18} cy={15} r={1} fill="currentColor" />
        <path
          d="M17 12H7a1 1 0 0 1-.98-1.2l1-5A1 1 0 0 1 8 5h8a1 1 0 0 1 .98.8l1 5A1 1 0 0 1 17 12zm-8.78-2h7.56l-.6-3H8.82z"
          fill="currentColor"
        />
        <path
          d="M23 20H1a1 1 0 0 1-1-1v-6.88A2.017 2.017 0 0 1 2 10h.125a.119.119 0 0 0 .116-.09l1.576-6.3A2.258 2.258 0 0 1 6 2h12a2.258 2.258 0 0 1 2.182 1.606l1.576 6.3A.493.493 0 0 0 22 10a.989.989 0 0 1 .26.034A2.124 2.124 0 0 1 24 12.12V19a1 1 0 0 1-1 1zM2 18h20v-5.88c0-.147-.22-.114-.359-.149a2.231 2.231 0 0 1-1.823-1.577l-1.576-6.3A.493.493 0 0 0 18 4H6a.379.379 0 0 0-.252.119l-1.566 6.276A2.117 2.117 0 0 1 2.125 12h-.116c0 .01-.009.054-.009.12z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CarIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CarIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CarIcon;
