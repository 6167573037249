import * as React from 'react';
import { Alert, Button, Grid, ProgressBar, Section, Fieldset, TextLink } from 'react-unity';
import SubscriptionRequestsService from '../../../services/SubscriptionRequestsService';
import FormModel from '../../../utils/FormModel';
import { IStateForm } from '../../common/AbstractForm';
import UserLookup from '../../common/form-controls/UserLookup';
import RequestFormWrapper from '../../common/wrappers/RequestFormWrapper';
import SubscriptionRequest from '../../../models/entities/SubscriptionRequest';
import ToggleField from '../../common/form-controls/ToggleField';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import SelfAttestationService from '../../../services/SelfAttestationService';
import { SubmitSelfAttestationResponseModel } from '../../../models/viewModels/Subscriptions/SubmitSelfAttestationResponseModel';
import { useNavigate } from 'react-router-dom';

type OwnerState = { isOwner: string, actualOwnerGUID: string, actualOwnerUPN: string, subName: string, subId: number };

interface SelfAttestationHomeProps {
	navigate: any;
 }

interface ISelfAttestationHomeState extends IStateForm {
	isLoading: boolean;
	subscriptions: SubscriptionRequest[];
	alert: AlertBanner;
	ownership: OwnerState[];
	resposibilitiesAwareness: string;
	knowledgeUpToDate: string;
}

class SelfAttestationParams extends React.Component<SelfAttestationHomeProps, ISelfAttestationHomeState>{
	form: FormModel;
	subscriptionRequestsService: SubscriptionRequestsService;
	selfAttestationService: SelfAttestationService;
	constructor(props: SelfAttestationHomeProps) {
		super(props);
		this.state = {
			alert: { visible: false, text: '', variant: 'default' },
			isLoading: true,
			subscriptions: [],
			ownership: [],
			resposibilitiesAwareness: '',
			knowledgeUpToDate: ''
		};

		this.subscriptionRequestsService = new SubscriptionRequestsService();
		this.selfAttestationService = new SelfAttestationService();

		this.form = new FormModel({
			ownership: {
				getValue: () => this.state.ownership,
				validation: {
					rules: [
						{
							assert: () => this.state.ownership.every(x => x.isOwner == 'yes' || (x.isOwner == 'no' && x.actualOwnerGUID != '')),
							message: ''
						}
					],
					required: true,
				}
			},
			resposibilitiesAwareness: {
				getValue: () => this.state.resposibilitiesAwareness,
				validation: {
					required: true,
				}
			},
			knowledgeUpToDate: {
				getValue: () => this.state.knowledgeUpToDate,
				validation: {
					required: true,
				}
			}
		});
	}

	handleOwnerToggleClick = (isOwner: string, ownerForSub: OwnerState) => {
		let ownership = this.state.ownership;
		ownership.find(x => x == ownerForSub).isOwner = isOwner;
		this.setState({ ownership });
	};

	handleActualOwnerChange = (ownerForSub: OwnerState, value: any) => {
		let newGUID = '';
		let newUPN = '';
		if (typeof value !== 'undefined' && value != null) {
			newGUID = value.id;
			newUPN = value.userPrincipalName;
		}
		let ownership = this.state.ownership;
		ownership.find(x => x == ownerForSub).actualOwnerGUID = newGUID;
		ownership.find(x => x == ownerForSub).actualOwnerUPN = newUPN;
		this.setState({ ownership });
	}

	handleResponsibilitiesToggleClick = (resposibilitiesAwareness: string) => {
		this.setState({ resposibilitiesAwareness });
	};

	handleKnowledgeUpToDateToggleClick = (knowledgeUpToDate: string) => {
		this.setState({ knowledgeUpToDate });
	}

	handleSubmitSurvey = async () => {
		window.scroll(0, 0);
		const response: SubmitSelfAttestationResponseModel = { IsAwareOfResponsibilities: this.state.resposibilitiesAwareness == 'yes', IsCloudKnowledgeUpToDate: this.state.knowledgeUpToDate == 'yes', SelfAttestationSubscriptions: this.state.ownership.map(x => ({ SubscriptionRequestId: x.subId, NewSubscriptionOwnerId: x.isOwner == 'no' ? x.actualOwnerGUID : null })) };
		this.setState({
			alert: {
				visible: true, text: 'Response is being sent...', variant: 'default'
			},
			isLoading: true
		});
		try {
			await this.selfAttestationService.submitSelfAttestationResponse(response);
			this.setState({
				alert: {
					visible: true, text: 'Response sent!', variant: 'success'
				},
				isLoading: false
			}, this.alertCallback);
		}
		catch (error) {
			this.setState({
				alert: {
					visible: true, text: error?.body?.message ?? 'Something went wrong', variant: 'error'
				},
				isLoading: false
			}, this.alertCallback);
		}
	}

	async componentDidMount() {
		try {
			const subscriptions = (await this.subscriptionRequestsService.getOwnedSubscriptions()).filter(sub => sub.azureState?.toLocaleLowerCase() === 'enabled');
			if (subscriptions.length == 0) {
				throw Error;
			}
			const ownership = subscriptions.map(x => ({ isOwner: '', actualOwnerGUID: '', actualOwnerUPN: '', subName: x.createdSubscriptionName, subId: x.id }));
			this.setState({ isLoading: false, subscriptions, ownership });
		}
		catch (error) {
			this.setState({
				alert: {
					visible: true, text: 'Something went wrong', variant: 'error'
				},
				isLoading: false
			}, this.alertCallback);
		}
	}

	alertCallback = () => {
		setTimeout(() => this.setState({
			alert: {
				visible: false, text: '', variant: 'default'
			}
		}, () => {
			this.props.navigate('/');
		}), 40000);
	};

	render() {
		const subscriptionRows = [];
		this.state.ownership.forEach((ownerStateForSub, index) => {
			subscriptionRows.push(
				<Grid.Item key={index}>
					<ToggleField
						id={index.toString()}
						label={"Subscription: " + ownerStateForSub.subName}
						value={ownerStateForSub.isOwner}
						options={[
							{ text: 'Yes', value: 'yes' },
							{ text: 'No', value: 'no' }
						]}
						onChange={(event) => { this.handleOwnerToggleClick(event.target.value, ownerStateForSub) }}
						validation={{ required: true }}
					/>
				</Grid.Item>
			);
			subscriptionRows.push(
				<Grid.Item key={ownerStateForSub.subId}>
					{ownerStateForSub.isOwner == 'no' &&
						<UserLookup
							id={ownerStateForSub.subName}
							label="Current owner:"
							note="User should be an eligible subscription owner"
							value={ownerStateForSub.actualOwnerUPN}
							onChange={(value) => this.handleActualOwnerChange(ownerStateForSub, value)}
							validation={{ required: () => ownerStateForSub.isOwner == 'no' }}
						/>}
				</Grid.Item>);
		});
		return (
			<RequestFormWrapper
				title='Subscription Owner Attestation'
			>
				{this.state.alert.visible &&
					<Alert variant={this.state.alert.variant} onClose={() => this.setState({ alert: { visible: false, text: '', variant: 'default' } })}> {this.state.alert.text} </Alert>}
				{this.state.isLoading && (
					<ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label="Loading your subscriptions..." />
				)}
					<Fieldset>
						<Fieldset.Legend>Are you the current subscription owner for the following subscription(s)?</Fieldset.Legend>
						<Grid variant="2-up">
							{subscriptionRows}
						</Grid>
					</Fieldset>
					<Fieldset>
					<Fieldset.Legend>Are you aware of your <TextLink target="_blank" external href="https://appwiki.xom.cloud/docs/admin/cloudssp/sub-owner-intro.html">Subscription Owner responsibilities</TextLink>?</Fieldset.Legend>
						<ToggleField
							id="Responsibilities"
							disabled={this.state.isLoading}
							value={this.state.resposibilitiesAwareness}
							options={[
								{ text: 'Yes', value: 'yes' },
								{ text: 'No', value: 'no' }
							]}
							onChange={(event) => { this.handleResponsibilitiesToggleClick(event.target.value) }}
							validation={{ required: true }}
						/>
					</Fieldset>
					<Fieldset>
					<Fieldset.Legend>Is your Azure Cloud <TextLink target="_blank" external href="https://gotocloud.xom.cloud/learning-paths/">knowledge</TextLink> up-to-date?</Fieldset.Legend>
						<ToggleField
							id="Knowledge"
							disabled={this.state.isLoading}
							value={this.state.knowledgeUpToDate}
							options={[
								{ text: 'Yes', value: 'yes' },
								{ text: 'No', value: 'no' }
							]}
							onChange={(event) => { this.handleKnowledgeUpToDateToggleClick(event.target.value) }}
							validation={{ required: true }}
						/>
					</Fieldset>
				<Grid variant="2-up">
					<Grid.Item>
						<Button variant="secondary" disabled={this.state.isLoading} onClick={() => this.props.navigate('/')}>
							Cancel
								</Button>
					</Grid.Item>
					<Grid.Item>
						<Button
							variant={this.state.isLoading ? 'secondary' : 'primary'}
							loading={this.state.isLoading}
							onClick={this.handleSubmitSurvey}
							disabled={!this.form.isValid() || this.state.isLoading}
						>
							Submit
								</Button>
					</Grid.Item>
				</Grid>
			</RequestFormWrapper>
		);
	}
}

const SelfAttestation = () => {

    return <SelfAttestationParams navigate={useNavigate()}/>;
}

export default SelfAttestation;