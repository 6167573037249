import * as React from 'react';
import ApplicationsService from '../../../services/ApplicationsService';
import { useParams } from 'react-router-dom';
import AADAppsDetail from '../AADAppsDetail';
import { EnterpriseAppFormProps } from '../components/AbstractEnterpriseAppForm';

interface ADAppDetailProps extends EnterpriseAppFormProps {
}

const AdAppsDetail = (props) => {

	const { id } = useParams<{ id: string }>();

	return (
		<AADAppsDetail
			{...props}
			id={`${id}`}
			redirectTo="/adApps"
			linkTreeText={'AD Applications'}
			applicationsService={new ApplicationsService()}
		/>
	);
}

export default AdAppsDetail; 