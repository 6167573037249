import * as React from 'react';
import { Badge, BadgeColor } from 'react-unity';
import { WorkflowState } from '../../models/enums/WorkflowState';

interface WorkflowStateBadgeProps {
	workflowState: WorkflowState;
	fullWidth?: boolean;
}

export default class WorkflowStateBadge extends React.Component<WorkflowStateBadgeProps> { 
	color: BadgeColor;

	constructor(props) {
		super(props);
		
		switch (this.props.workflowState?.value) {
		case WorkflowState.Submitted?.value:
		case WorkflowState.PendingDeletion?.value:
		case WorkflowState.Draft?.value:
		case WorkflowState.AwaitingImplementation?.value:
		case WorkflowState.Review?.value:
			this.color = 'caution';
			break;
		case WorkflowState.Approved?.value:
		case WorkflowState.Completed?.value:
			this.color = 'positive';
			break;
		case WorkflowState.Rejected?.value:
		case WorkflowState.Error?.value:
		case WorkflowState.Deleted?.value:
		case WorkflowState.Cancelled?.value:
		case WorkflowState.ImplementationFailed?.value:
			this.color = 'negative';
			break;
		default:
			this.color = 'main';
			break;
		}
	}

	render() {
		return (
			<Badge
				className={`em-u-max-width-10 ${this.props.fullWidth && 'em-u-width-100' || ''}`}
				color={this.color}
			>
				{this.props.workflowState.name}
			</Badge>
		);
	}
}