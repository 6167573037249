import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronLeftIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M9.1 14.317L3.473 8.825a1.164 1.164 0 0 1 0-1.645L9.1 1.683l.7.716-5.628 5.492a.159.159 0 0 0 0 .222L9.8 13.6z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M30.758 41.914L15.672 26.828a4 4 0 0 1 0-5.656L30.758 6.086 32.172 7.5 17.086 22.586a2 2 0 0 0 0 2.828L32.172 40.5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M14.518 21.914l-8.7-8.491a2 2 0 0 1-.009-2.837l8.71-8.5 1.4 1.431-8.7 8.492 8.7 8.474z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronLeftIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronLeftIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronLeftIcon;
