import * as React from 'react';
import { Grid } from 'react-unity';
import TextInputField from '../../common/form-controls/TextInputField';
import ToggleField from '../../common/form-controls/ToggleField';
import FieldHandler from '../../../models/interfaces/FieldHandler';

interface EmergencyInputProps {
	isEmergency: FieldHandler<boolean | null>;
	incidentNumber: FieldHandler<string>;
	disabled?: boolean;
}

interface EmergencyInputState {}

export class EmergencyInput extends React.Component<EmergencyInputProps, EmergencyInputState> {

	render() {
		return (
			<Grid variant="3-up">
				<Grid.Item>
					<ToggleField
						id="is-emergency"
						label="Emergency"
						options={[
							{ value: 'true', text: 'Yes' },
							{ value: 'false', text: 'No' },
						]}
						{...this.props.isEmergency}
						value={this.props.isEmergency.value?.toString()}
						disabled={this.props.disabled}
					/>
				</Grid.Item>
				{this.props.isEmergency.value && (
					<Grid.Item>
						<TextInputField
							label="Incident #"
							{...this.props.incidentNumber}
							disabled={this.props.disabled}
						/>
					</Grid.Item>
				)}
			</Grid>
		);
	}
}
