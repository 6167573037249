const lightTheme = {
  name: "light",
  palette: {
    main: {
      extraLight: "#d3f2ff",
      light: "#0192d0",
      base: "#0c69b0",
      dark: "#002f6c",
      extraDark: "#3a397b",
      text: "#fff"
    },
    accent: {
      extraLight: "#ff9696",
      light: "#f55151",
      base: "#d82424",
      dark: "#ad1723",
      extraDark: "#801119",
      text: "#fff"
    },
    action: {
      light: "#57B6FF",
      base: "#0c69b0",
      dark: "#165585",
      text: "#fff"
    },
    negative: {
      light: "#fdded8",
      base: "#b12a0b",
      dark: "#811f08",
      text: "#fff"
    },
    caution: {
      light: "#fffecf",
      base: "#ffd700",
      dark: "#ccac00",
      text: "#111122"
    },
    positive: {
      light: "#d4f3e6",
      base: "#00b04f",
      dark: "#007d38",
      text: "#fff"
    },
    disabled: {
      light: "#a5a5a5",
      base: "#808080",
      text: "#5f5f5f"
    },
    active: {
      light: "#e1efff",
      base: "#e1efff",
      text: "#111122"
    },
    surface: {
      "00": "#fff",
      "01": "#fbfbfa",
      "02": "#f5f5f5",
      "03": "#e5e5e5",
      "04": "#cccccd",
      "05": "#bbbbbd",
      "06": "#a5a5a5",
      "07": "#808080",
      "08": "#76767e",
      "09": "#545459",
      "10": "#343434",
      "11": "#292938",
      inverted: {
        light: "#545459",
        base: "#111122",
        dark: "#111122",
        text: "#fff"
      }
    },
    text: {
      light: "#6E6E6E",
      base: "#111122"
    },
    alert: {
      default: {
        background: "#d3f2ff",
        base: "#0192d0",
        text: "#111122"
      },
      success: {
        background: "#d4f3e6",
        base: "#00b04f",
        text: "#00b04f"
      },
      warning: {
        background: "#fffecf",
        base: "#ffd700",
        text: "#111122"
      },
      error: {
        background: "#fdded8",
        base: "#b12a0b",
        text: "#b12a0b"
      }
    }
  },
  colors: {
    amber: "#f2ac33",
    black: "#000",
    blue: {
      extraLight: "#d3f2ff",
      light: "#0192d0",
      base: "#0c69b0",
      dark: "#002f6c",
      extraDark: "#3a397b",
      text: "#fff"
    },
    burgundy: "#ad1723",
    cerise: "#a71065",
    curiousBlue: "#3190d9",
    cyan: "#00a3e0",
    deepBlue: "#111122",
    green: "#00af53",
    indigo: "#002f6c",
    lightBurgundy: "#d82424",
    lime: "#b4d405",
    mediumGray: "#545459",
    orange: "#ed8b00",
    plum: "#890c58",
    purple: "#7a4183",
    ruby: "#b10040",
    seaBlue: "#005f7f",
    turquoise: "#00aca8",
    vermilion: "#d93900",
    violet: "#3a397b",
    deepViolet: "#32316a",
    white: "#ffffff",
    yellow: "#ffd700"
  }
};

export default lightTheme;
