import * as React from 'react';
import { DatePicker, DatePickerProps } from 'react-unity';
import { Field } from './Field';

export default class DatePickerField extends Field<DatePickerProps> {
	render() {
		return (
			<DatePicker
				{...this.props}
				error={!this.isValid()}
				valid={this.isValid()}
				note={this.nonValidMessage() || this.props.note}
			/>
		);
	}
}
