import ApiClient from './ApiClient';
import CloudAccount from '../models/CloudAccount';
import { RequestCloudAccountModel, ConfirmPasswordResetModel } from '../models/interfaces/CloudAccounts';
import CloudIdDomain from '../models/CloudIdDomain';
import MFAReset from '../models/MFAReset';

export default class CloudAccountsService extends ApiClient {
	async getAll() {
		return this.get<CloudAccount[]>('cloudIds');
	}

	async getMyAccounts() {
		return this.get<CloudAccount[]>('cloudIds/me');
	}

	async getAccountsForApprove() {
		return this.get<CloudAccount[]>('cloudIds/needingApprovalUser');
	}

	async getSupportedDomains() {
		return this.get<CloudIdDomain[]>('cloudIds/supportedDomains');
	}

	async requestCloudAccount(model: RequestCloudAccountModel) {
		return this.post<CloudAccount>('cloudIds', model);
	}

	async approveCloudAccount(id: number) {
		return this.post<CloudAccount>(`cloudIds/${id}/approval`, null);
	}

	async approveUserCloudAccount(id: number) {
		return this.post<CloudAccount>(`cloudIds/${id}/approvalUser`, null);
	}

	async rejectCloudAccount(id: number, rejectionReason: string) {
		return this.post<CloudAccount>(`cloudIds/${id}/rejection`, { rejectionReason });
	}

	async rejectUserCloudAccount(id: number, rejectionReason: string) {
		return this.post<CloudAccount>(`cloudIds/${id}/rejectionUser`, { rejectionReason });
	}
   
	async deleteCloudAccount(id: number) {
		return this.delete(`cloudIds/${id}`, null);
	}

	async requestPasswordReset(email: string) {
		return this.post('cloudIds/requestPasswordReset', { email });
	}

	async confirmPasswordReset(model: ConfirmPasswordResetModel) {
		return this.patch('cloudIds/confirmPasswordReset', model);
	}

	async resetMfaConfiguration(userPrincipalName: string, emailAddress: string) {
		return this.patch('cloudIds/resetMfaConfiguration', { "UserPrincipalName": userPrincipalName, "EmailAddress": emailAddress });
	}

	async accountCancelationRequest(id: number) {
		return this.patch('cloudIds/accountCancelationRequest', { id });
	}

	async getUsersMFAMethods(userPrincipalName: string) {
		return this.get<MFAReset[]>(`cloudIds/getUsersMFAMethods/${userPrincipalName}`);
	}

}