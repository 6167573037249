import React, { useState } from 'react';
import {
	Modal,
	Button,
	Grid,
	XFilledIcon,
    ButtonProps,
} from 'react-unity';
import ApplicationLookupObject from '../../../models/ApplicationLookupObject';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import ApplicationLookup from '../../common/form-controls/ApplicationLookup';

interface ModalButtonProps {
	label?: string;
	props?: ButtonProps;
}

interface GrantCredentialAdministratorModalProps {
	visible: boolean;
    title: string;
    confirmButton: ModalButtonProps;
	onConfirm: (app: any) => void;
	onCancel?: (event: React.ChangeEvent<any>) => void;
}



const GrantCredentialAdministratorModal = ({ visible, title, confirmButton, onConfirm, onCancel } : GrantCredentialAdministratorModalProps) => {
	const [ submitting, setSubmitting ] = useState(false);
	const [ alert, setAlert ] = useState<AlertBanner>({
										visible: false,
										variant: 'error',
										text: ''
									});
	const [ appToGrant, setAppToGrant ] = useState<ApplicationLookupObject>(null);

	const isValid = () => {
		if (appToGrant) return true;
		return false;
	}

	const resetState = () => {
		setSubmitting(false);
		setAlert({visible: false});
		setAppToGrant(null);
	}

	const handleRequest = async (e: any) => {
		try {
			setSubmitting(true);
			onConfirm(appToGrant);
			resetState();
		} catch (err) {
			setSubmitting(false);
			setAlert({
				visible: true,
				variant: 'error',
				text: `${err.body.code}:${err.body.message}`
			});
		}
	};

	return (
		<Modal show={visible}
			onHide={() => { }}>
			<Modal.Window id="grant-credential-administrator-modal">
				<Modal.Window.Header>
					<Modal.Window.Header.Title>{title}</Modal.Window.Header.Title>
					<Modal.Window.Header.CloseButton onClick={onCancel}>
							<XFilledIcon />
						</Modal.Window.Header.CloseButton>
				</Modal.Window.Header>
				<Modal.Window.Body>
					<ApplicationLookup value={[appToGrant]} setAppToGrant={setAppToGrant}/>
				</Modal.Window.Body>
				<Modal.Window.Footer>
					<Grid variant="2-up">
						<Grid.Item>
							<Button
								type='submit'
								variant="secondary"
								disabled={submitting}
								onClick={onCancel}
							>
								Cancel
							</Button>
						</Grid.Item>
						<Grid.Item>
                                <Button
									variant="primary"
									disabled={!isValid() || submitting}
									onClick={handleRequest}
									type='submit'
                                >
									{confirmButton?.label || 'Confirm'}
								</Button>
						</Grid.Item>
					</Grid>
				</Modal.Window.Footer>
			</Modal.Window>
		</Modal>
	);
};

export default GrantCredentialAdministratorModal;
