import * as React from 'react';
import SelectField from '../../common/form-controls/SelectField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { Platform } from '../../../models/enums/Subscription/Platform';

interface PlatformInputProps extends FieldHandler<number> { }

export class PlatformInput extends React.Component<PlatformInputProps> {

	render() {
		return (
			<SelectField
				label="Platform"
				className="full-width"
				options={
					[
						Platform.Azure,
					]
				}
				disabled
				{...this.props}
			/>
		);
	}
}