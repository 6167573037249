import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import PortOpeningRequestsByPOR from './PortOpeningRequestsByPOR';
import PortOpeningRequestsByRule from './PortOpeningRequestsByRule';
import { AlertVariant, ProgressBar, Tabs, TextLink } from 'react-unity';
import PortOpeningRule from '../../models/entities/PortOpeningRule';
import styled from 'styled-components';
import AlertModal from '../common/modals/AlertModal';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import { useNavigate } from 'react-router-dom';
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';
import { getVnets } from '../common/VirtualNetworksStorage';
import { LinkTreeItem } from '../common/LinkTree';
import { RuleOperation } from '../../models/enums/POR/RuleOperation';
import { WorkflowState } from '../../models/enums/WorkflowState';

const StyledTabsItem = styled(Tabs.Item)`
	padding-top: 0px !important;
`;

interface PortOpeningRequestsHistoryProps {
	subscriptionName?: string;
	subscriptionId?: number;
}

const PortOpeningRequestsHistory = ({}: PortOpeningRequestsHistoryProps) => {
	const subscriptionRequestsService = new SubscriptionRequestsService();
	const params = useParams();
	const subscriptionId = parseInt(params.id);
	const [subscription, setSubscription] = useState<SubscriptionRequest>(null);
	const [pors, setPors] = useState<PortOpeningRequest[]>(null);
	const [rules, setRules] = useState<PortOpeningRule[]>(null);
	const [endModal, setEndModal] = useState<AlertBanner>({ visible: false });
	const [validVirtualNetworks, setValidVirtualNetworks] = useState<VirtualNetwork[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		const getData = async () => {
			try {
				const subscription = await subscriptionRequestsService.getByIdValidateAccessPOR(subscriptionId);
				const resPors = subscription.portOpeningRequests;
				const resRules = getPortOpeningRules(subscription);

				setPors(resPors.sort((a, b) => (a.id < b.id ? 1 : -1)));
				setRules(resRules.sort((a, b) => (a.id < b.id ? 1 : -1)));
				setSubscription(subscription);
				const vNets = await getVnets(subscription.createdSubscriptionId, subscription.tenant.name);
				setValidVirtualNetworks(vNets);
			} catch {
				handleEndModal('Something went wrong, try again later.', 'error', 5000);
			}
		};
		getData();
	}, []);

	const redirectToSubscriptions = () => {
		setEndModal({ ...endModal, visible: false });
		navigate('/subscriptions');
	};

	const getLinkTree = (): LinkTreeItem[] => {
		return [
			{ text: 'Subscriptions', to: '/subscriptions' },
			{ text: subscription?.createdSubscriptionName, to: `/subscriptions/${subscriptionId}` },
			{ text: 'Port Opening Requests', to: `/subscriptions/${subscriptionId}/PortOpeningRequestsHistory` }
		];
	};

	function getPortOpeningRules(subscription: SubscriptionRequest) {
		const portOpeningRules = subscription.portOpeningRequests.filter(obj => {
			return obj.workflowInstance.currentWorkflowInstanceState.workflowState.name === 'Completed' || obj.workflowInstance.currentWorkflowInstanceState.workflowState.name === 'Pending Attestation';
		}).flatMap(o => o.portOpeningRules);

		const groupedRules = portOpeningRules.reduce((r, a) => {
			r[a.azureName] = [...r[a.azureName.indexOf[-1]] || [], a];
			return r;
		}, {});
		
		return Object.values(groupedRules).filter((data: any) => { return data[0].operation.name !== RuleOperation.Remove.name || data[0].isBeingAttested == true }).map(a => a[0]);
	}

	const handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		setEndModal({
			visible: true,
			text,
			variant,
		});
		setTimeout(() => redirectToSubscriptions(), timeout);
	};

	return (
		<>
			<SectionHomeWrapper
				reference={subscription?.createdSubscriptionName || ''}
				title="Request history"
				linkTree={getLinkTree()}
				soft
			>
				<p>
					For more information about PORs {' '}
					<TextLink className='em-u-margin-right-none' external target="_blank" href='https://appwiki.xom.cloud/docs/ConnectedAzure/PortOpeningRequests.html'>
						Click here
					</TextLink>
				</p>
				{!subscription ? (
					<ProgressBar
						label="Loading subscription information..."
						className="em-u-margin-top-half"
						indeterminate
						hideValueLabel
					/>
				) : (
					<Tabs variant="underlined" style={{ fontSize: 20 }}>
						<StyledTabsItem title="See by POR">
							{pors ? (
								<PortOpeningRequestsByPOR subscription={subscription} pors={pors} />
							) : (
								<ProgressBar
									label="Loading Port Opening Requests..."
									className="em-u-margin-top-half"
									indeterminate
									hideValueLabel
								/>
							)}
						</StyledTabsItem>
						<StyledTabsItem title="See by Rule">
							{rules ? (
								<PortOpeningRequestsByRule
									subscription={subscription}
									rules={rules}
									validVnets={validVirtualNetworks}
								/>
							) : (
								<ProgressBar
									label="Loading Rules..."
									className="em-u-margin-top-half"
									indeterminate
									hideValueLabel
								/>
							)}
						</StyledTabsItem>
					</Tabs>
				)}
			</SectionHomeWrapper>
			<AlertModal {...endModal} willTimeout={false} onClose={redirectToSubscriptions} />
		</>
	);
};

export default PortOpeningRequestsHistory;
