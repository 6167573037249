import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-pagination__link--main";
    case "accent":
      return "em-c-pagination__link--accent";
    case "blue":
    default:
      return "";
  }
}

/** PaginationItem is a button to navigate to a specific page */
const PaginationItem = props => {
  const {
    active,
    disabled,
    color,
    onChange,
    className,
    children,
    ...other
  } = props;

  return (
    <li
      className={clsx(
        "em-c-pagination__item",
        { "em-u-clickable": !disabled },
        className
      )}
      onClick={onChange}
      {...other}
    >
      <div
        className={clsx("em-c-pagination__link", getColor(color), {
          "em-is-current": active
        })}
      >
        {children}
      </div>
    </li>
  );
};

PaginationItem.propTypes = {
  /** if the pagination item is active (highlighted) */
  active: PropTypes.bool,
  /** URL of the page */
  href: PropTypes.string,
  /** function emitted on change, returns this pages inner value */
  onChange: PropTypes.func,
  /** Theme color of the buttons */
  color: PropTypes.oneOf(["blue", "main", "accent"]),
  /** children are the contents of the PaginationItem */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

PaginationItem.defaultProps = {
  active: false,
  href: "",
  onChange: () => {},
  children: PropTypes.node,
  className: "",
  color: "blue"
};

export default PaginationItem;
