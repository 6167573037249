import WorkflowInstance from '../WorkflowInstance';
import User from '../User';
import { WorkflowState } from '../enums/WorkflowState';
import { Environment } from '../enums/Environment';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../enums/UserRole';
import { PortOpeningRequestsDirection } from '../enums/POR/PortOpeningRequestsDirection';
import AWSPortOpeningRule from './AWSPortOpeningRule';

export default class AWSPortOpeningRequest {

	id!: number;

	awsPortOpeningRules!: AWSPortOpeningRule[];

	workflowInstance!: WorkflowInstance;

	direction!: PortOpeningRequestsDirection;

	environment!: Environment;

	contactUser!: User;

	isEmergency!: boolean;

	submitDate!: Date;

	businessJustification!: string;

	comments!: string;

	approvingUser!: User;

	delegatedApprovingUser!: User;

	hasDelegationApprover!: boolean;

	accountId!: number;
	
	accountName: string;

	customerCoordination!: boolean;

	custodianApproval!: boolean;

	accountOwnerId: string;

	escalated!: boolean;

	constructor(obj: any) {
		Object.assign(this, obj);
		this.workflowInstance = new WorkflowInstance(obj.workflowInstance);
		this.direction = new PortOpeningRequestsDirection().fromValue(obj.direction?.value);
	}

	implementationTicket(): string {
		const implementationState = this.workflowInstance.workflowInstanceStates
			.reverse()
			.find(state => state.ticketId !== null);
		return implementationState?.ticketId || '';
	}

	isCancellable(): boolean {
		return (
			authenticatedUser.userId === this.workflowInstance.createdBy.id &&
			[
				WorkflowState.Draft.value,
				WorkflowState.PendingSubscriptionOwnerApproval.value,
				WorkflowState.PendingForDOAGApproval.value,
				WorkflowState.PendingForGatewayPolicyTeamApproval.value,
				WorkflowState.Review.value,
			].includes(this.workflowInstance.currentWorkflowInstanceState.workflowState.value)
		);
	}

	canViewDOAGApproval(): boolean {
		return authenticatedUser.userId === this.approvingUser?.id;
	}

	canHaveDOAGApproval(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			currentState === WorkflowState.PendingForDOAGApproval.value &&
			(authenticatedUser.userId === this.approvingUser?.id || authenticatedUser.userId === this.delegatedApprovingUser?.id)
		);
	}

	canHaveTechnicalDecision(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			(authenticatedUser.isInRole(UserRole.AWSPORTechnicalReviewer)
			|| authenticatedUser.isInRole(UserRole.AWSPORSolArqReviewer)
			|| authenticatedUser.isInRole(UserRole.AWSPORCyberReviewer)
			|| authenticatedUser.isInRole(UserRole.AWSPORTechnicalImplementer))
			&&
			[
				WorkflowState.PendingTechnicalApproval.value,
				WorkflowState.PendingSolutionArchitectureApproval.value,
				WorkflowState.PendingForCyberApproval.value,
			].includes(currentState)
		);
	}

	canHaveEscalateDecision(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			(authenticatedUser.isInRole(UserRole.AWSPORTechnicalReviewer) && currentState === WorkflowState.PendingTechnicalApproval.value)
			|| (authenticatedUser.isInRole(UserRole.AWSPORSolArqReviewer) && currentState === WorkflowState.PendingSolutionArchitectureApproval.value)
		);
	}

	canSendToReview(accountOwnerId: string, workflowInstance: WorkflowInstance): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState;
		return((
				((currentState === WorkflowState.PendingAccountOwnerApproval || currentState === WorkflowState.PendingForDOAGApproval || currentState === WorkflowState.PendingForGatewayPolicyTeamApproval) &&
				accountOwnerId === authenticatedUser.userId)
				
			) ||
			(
				currentState === WorkflowState.PendingTechnicalApproval &&
				authenticatedUser.isInRole(UserRole.AWSPORTechnicalReviewer)
			) ||
			(
				(currentState !== WorkflowState.AwaitingImplementation && currentState !== WorkflowState.Completed) &&
				workflowInstance.createdBy.id === authenticatedUser.userId 
			) &&
				currentState != WorkflowState.Review
			)
	}

	canHaveAccountOwnerDecision(accountOwnerId: string, hasCustodianApproval: boolean): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState;
		if (hasCustodianApproval){
			return currentState === WorkflowState.PendingAccountOwnerApproval && (
				accountOwnerId === authenticatedUser.userId);
		}
		return (accountOwnerId === authenticatedUser.userId &&
				(currentState === WorkflowState.PendingAccountOwnerApproval));
	}



	isEditable(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		return (
			authenticatedUser.userId === this.workflowInstance.createdBy.id &&
			[
				WorkflowState.Draft.value,
				WorkflowState.Review.value,
			].includes(currentState)
		);
	}

	architectApprovalBypassed(): boolean {
		const workflowInstanceStates = this.workflowInstance.workflowInstanceStates;
		return (workflowInstanceStates.find((workflowInstanceState) => workflowInstanceState.workflowState.value == WorkflowState.PendingSolutionArchitectureApproval.value) == undefined) && !this.escalated;
	}

	cyberApprovalBypassed(): boolean {
		const workflowInstanceStates = this.workflowInstance.workflowInstanceStates;
		return (workflowInstanceStates.find((workflowInstanceState) => workflowInstanceState.workflowState.value == WorkflowState.PendingForCyberApproval.value) == undefined) && !this.escalated;
	}

}