import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Field from "../Field";
import { Input } from "../index";
import PeoplePickerItem from "./PeoplePickerItem";

export const PeoplePickerContext = React.createContext({
  onChange: () => {}
});

/**
 * An input field for searching for people. It’s a specific but common instance of typeahead
 * @visibleName People picker
 */
const PeoplePicker = props => {
  const {
    className,
    inputProps,
    fieldProps,
    children,
    onChange,
    onClick,
    ...other
  } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const fieldRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (isMenuOpen) {
      // When the component mounts, add the Unity JS and Mouse Down Event
      if (!document.body.classList.contains("em-js")) {
        document.body.classList.add("em-js");
      }
      document.addEventListener("mousedown", handleClickOutside);

      // When the component unmounts, remove the Unity JS and Mouse Down Event
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isMenuOpen]);

  function handleClickOutside(event) {
    if (
      !fieldRef.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  }

  function handlePeoplePickerItemClick(e, value) {
    e.stopPropagation();
    setIsMenuOpen(false);

    if (onChange) {
      onChange(e, value);
    }
  }

  function handleFieldClick(e) {
    if (onClick) {
      onClick(e);
    }
    setIsMenuOpen(true);
  }

  return (
    <PeoplePickerContext.Provider
      value={{ onChange: handlePeoplePickerItemClick }}
    >
      <Field
        className={clsx("em-js-typeahead", className)}
        onClick={handleFieldClick}
        innerRef={fieldRef}
        {...other}
        {...fieldProps}
      >
        {fieldProps && fieldProps.label && (
          <Field.Label>{fieldProps.label}</Field.Label>
        )}
        <Field.Body>
          <Input {...inputProps} />
          <div
            ref={menuRef}
            className={clsx(
              "em-c-field__menu",
              { "em-is-active": isMenuOpen },
              "em-js-typeahead-menu"
            )}
          >
            <ul className="em-c-typeahead-list">{children}</ul>
          </div>
        </Field.Body>
        {fieldProps && fieldProps.note && (
          <Field.Note>{fieldProps.note}</Field.Note>
        )}
      </Field>
    </PeoplePickerContext.Provider>
  );
};

PeoplePicker.Item = PeoplePickerItem;

PeoplePicker.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Props sent to the Input component */
  inputProps: PropTypes.object,
  /** Props sent to the Field component */
  fieldProps: PropTypes.object,
  /** Callback whenever an item is clicekd */
  onChange: PropTypes.func,
  /** Callback whenever the input is clicked */
  onClick: PropTypes.func,
  /** children of component */
  children: PropTypes.node
};

PeoplePicker.defaultProps = {
  className: "",
  children: null
};

export default PeoplePicker;
