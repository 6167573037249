import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TableFootRow from "./TableFootRow";
import TableFootCell from "./TableFootCell";

/** TableFoot is the Footer row for the table */
const TableFoot = props => {
  const { className, children, ...other } = props;

  return (
    <tfoot className={clsx("em-c-table__footer", className)} {...other}>
      {children}
    </tfoot>
  );
};

TableFoot.propTypes = {
  /** children are the contents of the TableFoot */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TableFoot.defaultProps = {
  children: null,
  className: ""
};

TableFoot.Row = TableFootRow;
TableFoot.Cell = TableFootCell;

export default TableFoot;
