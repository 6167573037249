import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const RotateRightIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.772 12.033l-.8-.594.3-.4c.09-.122.176-.248.255-.378l.26-.428.854.521-.261.427c-.095.155-.2.307-.3.452zM8.154 14.11a5.957 5.957 0 0 1-.608-.031l-.5-.05.1-1 .5.051a5.19 5.19 0 0 0 .88.012l.5-.036.072 1-.5.036a5.085 5.085 0 0 1-.444.018zm2.02-.32l-.406-.914.457-.2a5.191 5.191 0 0 0 .775-.428l.415-.278.557.83-.415.278a6.176 6.176 0 0 1-.921.509zm-4.2-.057l-.451-.216a6.245 6.245 0 0 1-.905-.532l-.405-.285.578-.816.408.289a5.149 5.149 0 0 0 .759.446l.451.216zm-2.543-1.825l-.287-.41a6.018 6.018 0 0 1-.525-.911l-.211-.453.906-.422.211.453a5.069 5.069 0 0 0 .439.761l.286.409zM2.112 9.042l-.044-.5a6.136 6.136 0 0 1-.024-.544V7.5h1V8c0 .153.007.306.021.456l.044.5zM13.438 6.554a.477.477 0 0 1-.127-.017L9.436 5.52l.254-.967 3.4.892.926-3.389.965.264-1.06 3.866a.5.5 0 0 1-.483.368z"
          fill="currentColor"
        />
        <path
          d="M3.044 8.5h-1V8a6.111 6.111 0 0 1 11.593-2.7l.222.448-.9.442-.221-.449A5.111 5.111 0 0 0 3.044 8z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39.541 19a2.625 2.625 0 0 1-.679-.09l-9.587-2.563.517-1.933 9.588 2.564a.661.661 0 0 0 .8-.486l2.609-9.718 1.932.52-2.609 9.717A2.678 2.678 0 0 1 39.541 19z"
          fill="currentColor"
        />
        <path
          d="M7 25H5v-1A18.872 18.872 0 0 1 23.7 5a18.643 18.643 0 0 1 17.224 11.617l.383.924-1.848.766-.383-.924A16.647 16.647 0 0 0 23.7 7 16.87 16.87 0 0 0 7 24z"
          fill="currentColor"
        />
        <path
          d="M23.937 43c-.376 0-.749-.012-1.12-.033l-1-.058.116-2 1 .059a17.24 17.24 0 0 0 2.831-.068l.994-.107.215 1.988-.994.107a19.214 19.214 0 0 1-2.042.112zm-6.343-1.064l-.922-.385a18.909 18.909 0 0 1-2.809-1.459l-.845-.534 1.067-1.69.846.533a16.947 16.947 0 0 0 2.51 1.3l.923.386zm13.55-.333l-.86-1.806.9-.43a17.052 17.052 0 0 0 2.442-1.426l.818-.574L35.595 39l-.818.574a18.967 18.967 0 0 1-2.729 1.594zM9.792 36.67l-.625-.78A19.161 19.161 0 0 1 7.4 33.267l-.487-.873 1.746-.975.487.873a17.282 17.282 0 0 0 1.579 2.348l.625.78zm28.886-.707l-1.621-1.172.586-.811a16.97 16.97 0 0 0 1.457-2.423l.442-.9 1.794.886-.443.9a19.034 19.034 0 0 1-1.633 2.709zm-33.215-7.65l-.173-.984A19.084 19.084 0 0 1 5 24.181l-.009-1 2-.018.009 1a17.107 17.107 0 0 0 .258 2.817l.174.985z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.178 9.767a1.789 1.789 0 0 1-.444-.057l-4.93-1.3.509-1.935 4.705 1.236L19.3 3.023l1.93.527-1.344 4.917a1.776 1.776 0 0 1-1.708 1.3zm.063-1.991z"
          fill="currentColor"
        />
        <path
          d="M3.067 11.785h-2v-1a9.286 9.286 0 0 1 17.617-4.1l.442.9-1.794.884-.441-.9a7.287 7.287 0 0 0-13.824 3.216zM15.813 19.486l-1.27-1.545.772-.635a7.739 7.739 0 0 0 .651-.6l.7-.719 1.434 1.391-.7.719a9.831 9.831 0 0 1-.819.755zM10.567 21a9.53 9.53 0 0 1-1.1-.063l-.994-.113.227-1.988.993.114a7.8 7.8 0 0 0 2-.034l.988-.15.3 1.977-.988.15a9.489 9.489 0 0 1-1.426.107zm-4.96-1.335l-.8-.606a9.507 9.507 0 0 1-1.791-1.788l-.607-.794L4 15.262l.607.794a7.692 7.692 0 0 0 .655.748 7.584 7.584 0 0 0 .761.665l.795.605z"
          fill="currentColor"
        />
        <path
          d="M1.251 13.61l-.118-.993a9.39 9.39 0 0 1-.066-1.117v-1h2v1a7.5 7.5 0 0 0 .052.881l.118.993z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

RotateRightIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

RotateRightIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default RotateRightIcon;
