import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import PageHeader from "./PageHeader";
import PageBody from "./PageBody";
import PageFooter from "./PageFooter";

/** Page is a layout wrapper to easily create a sticky footer */
const Page = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("page-wrapper", className)} {...other}>
      {children}
    </div>
  );
};

Page.propTypes = {
  /** children will hold the header, footer, and body */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Page.defaultProps = {
  children: null,
  className: ""
};

Page.Header = PageHeader;
Page.Body = PageBody;
Page.Footer = PageFooter;

export default Page;
