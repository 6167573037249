import React from 'react';
import { Button, FileCsvIcon, Loader } from 'react-unity';
import { CSVLink } from 'react-csv';

type Column<T> = {
	header: string;
	cell: (row: T) => string;
};

interface CSVGeneratorButtonProps<T> {
	label?: string;
	fileName?: string;
	data: T[];
	columns: Column<T>[];
	isLoading?:boolean
	disabled?:boolean

}

interface CSVGeneratorButtonState {
}

export default class CSVGeneratorButton<T>
	extends React.Component<CSVGeneratorButtonProps<T>, CSVGeneratorButtonState> {
	
	fileData = () => {
		const headers = this.props.columns.map(column => column.header);
		const rows = this.props.data.map(row => (
			this.props.columns.map(column => column.cell(row) || '')
		));

		return [headers, ...rows];
	};
	
	render() {
		const isLoading = this.props.isLoading ? this.props.isLoading : false;
		const disabled = this.props.disabled ? this.props.disabled : false;
		return (
			<>
			{!(isLoading || disabled) ? <CSVLink
				data={this.fileData()}
				filename={this.props.fileName || 'export.csv'}
				className='em-c-btn em-c-btn--secondary em-c-btn--small'
			>
				<FileCsvIcon size="small" />&nbsp;
				<span>{this.props.label || 'Export as CSV'}</span>
			</CSVLink>
			:
				<Button
					variant="secondary"
					style={{borderRadius: '4px'}}
					size="small"
					disabled={true}
				>
					{(disabled && !isLoading) ?  <span>{this.props.label || 'Export as CSV'}</span> : <Loader className="em-u-margin em-c-icon--medium" />}
				</Button>
			}
		</>);
	}
}