import SubmitAWSPortOpeningRequestRule from './SubmitAWSPortOpeningRequestRule';

export default class SubmitAWSPortOpeningRequest {

	accountId!: number;

	portOpeningRules!: SubmitAWSPortOpeningRequestRule[];

	direction!: string;

	environment: string;

	contactId!: string;

	emergency!: boolean;

	businessJustification!: string;

	comments!: string;

	customerCoordination!: boolean;

	approverId!: string;

	AWSPortOpeningRequestId!: number;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}