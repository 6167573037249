import * as React from 'react';
import { Address4, Address6 } from 'ip-address';
import FormModel from '../../../../utils/FormModel';
import { RuleAction } from '../../../../models/enums/POR/RuleAction';
import { AbstractFormState, AbstractForm } from '../../../common/AbstractForm';
import { ValidationRule } from '../../../../utils/Validation';
import { RuleOperation } from '../../../../models/enums/POR/RuleOperation';
import AWSPortOpeningRule from '../../../../models/entities/AWSPortOpeningRule';
import { VirtualPrivateCloud } from '../../../../models/entities/AzureResources/VirtualPrivateCloud';
import { AWSRuleRegion } from '../../../../models/enums/POR/AWSRuleRegion';


export interface RulesFormProps {
	visible: boolean;
	onConfirm: (rule: AWSPortOpeningRule) => void;
	onCancel?: (event: React.ChangeEvent<any>) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
	editingRule?: AWSPortOpeningRule;
	virtualPrivateClouds: VirtualPrivateCloud[];
	isReadOnly?: boolean;
	isFromHistory?: boolean;
 }

export interface IRuleStateForm {
	operation: RuleOperation;
	action: RuleAction;
	region: AWSRuleRegion;
	sourceIPs: string;
	destinationIPs: string;
	destinationPorts: string;
	description: string;	
}

/** @abstract */
export abstract class AbstractRulesForm<
	Props extends RulesFormProps,
	State extends AbstractFormState<IRuleStateForm>
> extends AbstractForm<Props, State> {
	
	componentDidUpdate(prevProps) {
		if (this.props.editingRule !== prevProps.editingRule) {
			if (this.props.editingRule != null)
				this.setState({ stateForm: this.getRuleCopy() });
			else
				this.setState({ stateForm: this.getDefaultForm() });
		}
	}

	parseMultipleValues = (values: string) => {
		return values?.split(/\n/).join(';')
			.split(' ').join('');
	};

	hasEmptyValues = (values: string): boolean => {
		return this.parseMultipleValues(values)?.split(';').some(value => {			
			return value.length === 0;
		});
	};

	isPrivateAddress = (ipAddress: Address4): boolean => {
		const privateSubnets = [
			'10.0.0.0/8',       // RFC1918 Class A
			'172.16.0.0/12',    // RFC1918 Class B
			'192.168.0.0/16',   // RFC1918 Class C
			'131.126.0.0/16',
			'142.37.0.0/16',
			'142.38.0.0/16',
			'150.127.0.0/16',
			'159.129.0.0/16',
			'144.201.0.0/16',
			'146.156.0.0/16',
			'150.25.0.0/16',
			'158.23.0.0/16',
			'158.27.0.0/16',
			'158.28.0.0/16',
			'158.30.0.0/16',
			'158.35.0.0/16',
			'158.55.0.0/16',
			'159.70.0.0/16',
			'159.131.0.0/16',
			'198.43.0.0/16',
			'158.33.0.0/16',
			'158.22.0.0/16',
			'169.254.0.0/16',
			'142.102.0.0/16',
			'159.249.0.0/16',
			'199.64.205.0/24',
			'204.209.0.0/16',
			'158.24.0.0/16',
			'198.160.0.0/16',
			'199.64.203.0/24',
			'192.77.15.0/24',
			'158.26.0.0/16',
			'192.9.200.0/24',
			'199.64.206.0/24',
			'158.31.0.0/16',
			'158.29.0.0/16',
			'199.64.204.0/24',
			'198.161.0.0/16',
			'192.83.106.0/24',
			'192.75.98.0/24',
			'199.64.207.0/24',
			'158.21.0.0/16',
			'158.25.0.0/16',
			'161.157.0.0/16',
			'158.34.0.0/16',
			'158.32.0.0/16',
			'136.228.238.0/24',
			'138.43.107.128/25',
			'136.228.255.128/25',
			'136.228.213.128/26',
			'136.228.242.0/24',
			'136.228.237.192/27',
			'136.228.235.192/26',
			'136.228.243.192/27',
			'103.111.183.192/27',
			'103.111.181.192/27',
			'136.228.218.192/27',
			'103.111.182.192/27',
			'136.228.240.0/24',
			'136.228.241.0/24',
			'136.228.223.192/27',
			'185.251.8.192/26',
			'136.228.251.0/24',
			'136.228.222.192/28',
			'136.228.219.80/28',
			'136.228.209.80/28',
			'136.228.226.64/28',
			'136.228.230.64/27',
			'97.64.51.0/28',
			'136.228.220.64/29',
			'136.228.208.224/27',
			'192.67.48.0/24'
		];

		return privateSubnets.some(subnet => ipAddress.isInSubnet(new Address4(subnet)));
	};

	isIpAddressInputValid = (input: string, onlyPrivate: boolean): boolean => {
		try {
			if (input.includes('-')) {
				const [startRange, endRange] = input.split('-');
				const startAddress = new Address4(startRange);
				const endAddress = new Address4(endRange);

				return (startAddress.toHex() < endAddress.toHex()) &&
					(!onlyPrivate ||
						(this.isPrivateAddress(startAddress) && this.isPrivateAddress(endAddress)));
			}
			const ip = new Address4(input);
			return ip.isCorrect() &&
					(
						!onlyPrivate ||
						(this.isPrivateAddress(ip) && !!onlyPrivate)
					);
			
		} catch {
			return false;
		}
	};

	// TODO: Move all these IPs validations, methods, etc.. to a separate/dedicated service
	isIpInAnyAddressSpace = (ipAddress: string, validAddressSpaces: string[]): boolean => {
		return !!validAddressSpaces?.length && validAddressSpaces.some(addressSpace => this.isIpInAddressSpace(ipAddress, addressSpace));
	}

	isIpInAddressSpace = (ipAddress: string, addressSpace: string): boolean => {
		try {
			var ip6 = new Address6(ipAddress);
			var addressSpace6 = new Address6(addressSpace);

			if (addressSpace6.isCorrect()) {
				return ip6.isInSubnet(addressSpace6);
			} else {
				var ip4 = new Address4(ipAddress);
				var addressSpace4 = new Address4(addressSpace);
				return ip4.isInSubnet(addressSpace4);
			}
		} catch (ex) {
			try {
				var ip4 = new Address4(ipAddress);
				var addressSpace4 = new Address4(addressSpace);
				return ip4.isInSubnet(addressSpace4);
			}
			catch (ex) {
				return false
			}
		}
	}

	isPortInputValid = (value: string, minimumPort: number): boolean => {
		const ValidPort = (port) => {
			if(port.includes(",")) return -1;
			const maxmimumPort = 65535;
			const parsedPort = parseInt(port);

			if (!isNaN(parsedPort) && parsedPort >= minimumPort && parsedPort <= maxmimumPort)
				return parsedPort;
			return -1;
		};
		
		if (/^([0-9])*([-])([0-9])*$/.test(value)) {
			const [startRange, endRange] = value.split('-').map(p => ValidPort(p));
			return (startRange > -1 && endRange > -1 && startRange < endRange);
		}
		return ValidPort(value) > -1;
	};

	isModalForNewRule() {
		return !this.props.isReadOnly && this.stateFormHandler().operation.value === RuleOperation.Add.value;
	}

	getStateFormHandlers() {
		return {
			operation: {
				value: this.state.stateForm.operation?.value,
				validation: this.formModel.fields.operation.validation,
				onChange: (event) => {
					this.handleStateFormChange('operation', new RuleOperation().fromValue(event.target.value));
				},
				options: (this.state.stateForm.operation?.value === RuleOperation.Add.value) ?
					new RuleOperation().list().sort((a, b) => a.name > b.name ? 1 : -1) : [
						RuleOperation.Modify,
						RuleOperation.Remove
					],
				disabled: this.state.stateForm.operation?.value === RuleOperation.Add.value
			},
			action: {
				label: 'Action',				
				value: this.state.stateForm.action?.value,
				validation: this.formModel.fields.action.validation,
				disabled: this.state.stateForm.action,
				onChange: (event) => {
					this.handleStateFormChange('action', new RuleAction().fromValue(event.target.value));
				}
			},
			region: {
				label: 'Region',
				value: this.state.stateForm.region?.value,
				className: 'full-width',
				onChange: (event) => this.handleStateFormChange('region', new AWSRuleRegion().fromValue(event.target.value)),
				validation: this.formModel.fields.region.validation
			},
			sourceIPs: {
				label: 'Source (IP)',
				value: this.state.stateForm.sourceIPs,
				validation: this.formModel.fields.sourceIPs.validation,
				onChange: (event) => {
					this.handleStateFormChange('sourceIPs', event.target.value);
				},
				note: 'Multiple values are allowed, separated by semicolons.'
			},
			destinationIPs: {
				label: 'Destination IP',
				value: this.state.stateForm.destinationIPs,
				validation: this.formModel.fields.destinationIPs.validation,
				onChange: (event) => {
					this.handleStateFormChange('destinationIPs', event.target.value);
				},
				note: 'Multiple values are allowed, separated by semicolons.'
			},
			destinationPorts: {
				label: 'Destination Port(s)',
				value: this.state.stateForm.destinationPorts,
				validation: this.formModel.fields.destinationPorts.validation,
				onChange: (event) => {
					this.handleStateFormChange('destinationPorts', event.target.value);
				},
				note: 'Multiple values are allowed, separated by semicolons. Port ranges are accepted.'
			},
			description: {
				label: 'Description',
				value: this.state.stateForm.description,
				validation: this.formModel.fields.description.validation,
				onChange: (event) => {
					if (event.target.value.length <= 140) {
						this.handleStateFormChange('description', event.target.value);
					}
				},
				note: `${this.state.stateForm.description.length} / 140` 
			}
		};
	}
	
	getDefaultForm() {
		return {
			operation: null,
			action: null,
			region: null,
			sourceIPs: '',
			destinationIPs: '',
			destinationPorts: '',
			description: '',
			...this.getDefaultFormState()
		};
	}

	abstract getDefaultFormState();

	getRuleCopy() {
		return { ...this.props.editingRule };
	}

	handleCancel = (e) => {
		this.resetState();
		this.props.onClose(e);
	};

	handleFormChange = (event, field: string) => {
		const stateForm = { ...this.state.stateForm };
		stateForm[field] = event.target.value;
		this.setState({ stateForm });
	};

	handleRequest = async (e) => {
		this.props.onClose(e);
		const model = this.formModel.create(AWSPortOpeningRule);
		this.props.onConfirm(model);
		this.resetState();
	};

	formCanBeEdited() {
		return !this.props.isReadOnly && this.state.stateForm.operation?.name !== RuleOperation.Remove.name;
	}

	handleStateFormChange(field: string, value: any): void;
	handleStateFormChange(field: string[], value: any[]): void;

	handleStateFormChange(field, value): void {
		const fields = [].concat(...[field]);
		const values = !Array.isArray(field) ? [value] : value;
		const form = this.state.stateForm;
		fields.forEach((fieldName, index) => {
			form[fieldName] = values[index];
		});
		this.setState({ stateForm: form });
	}

	initFormModel() {
		this.formModel = new FormModel(
			{
				operation: {
					getValue: () => this.state.stateForm.operation,
					validation: {
						required: true,
					},
				},
				action: {
					getValue: () => this.state.stateForm.action,
					validation: {
						required: true,
					},
				},
				region: {
					getValue: () => this.state.stateForm.region,
					validation: {
						required: true,
					},
				},
				sourceIPs: {
					getValue: () => this.parseMultipleValues(this.state.stateForm.sourceIPs),
					validation: {
						required: true,
						rules: [
							{
								assert: () => !this.hasEmptyValues(this.state.stateForm.sourceIPs),
								message: 'Empty values are not allowed.'
							}
						]
					},
				},
				destinationIPs: {
					getValue: () => this.parseMultipleValues(this.state.stateForm.destinationIPs),
					validation: {
						required: true,
						rules: [
							{
								assert: () => !this.hasEmptyValues(this.state.stateForm.destinationIPs),
								message: 'Empty values are not allowed.'
							}
						]
					},
				},
				destinationPorts: {
					getValue: () => this.parseMultipleValues(this.state.stateForm.destinationPorts),
					validation: {
						required: true,
						rules: [
							{
								assert: () => !this.hasEmptyValues(this.state.stateForm.destinationPorts),
								message: 'Empty values are not allowed.'
							}
						]
					},
				},
				description: {
					getValue: () => this.state.stateForm.description,
					validation: {
						required: true,
						rules: [
							{
								assert: () => this.state.stateForm.description?.trim().length >= 5,
								message: 'Description must have at least 5 characters.',
							}
						],
					},
				}
			}
		);
	}

	PortInputValidationRule = ({ minimumPort }): ValidationRule => ({
		assert: (value) => {
			const ports = this.parseMultipleValues(value).split(';');
			return ports.every(port => this.isPortInputValid(port, minimumPort));
		},
		message: (value) => {
			const invalidPort = this.parseMultipleValues(value).split(';').find(port => !this.isPortInputValid(port, minimumPort));
			return `${invalidPort} is an invalid port input.`;
		}
	});

	IpInputValidationRule = ({ onlyPrivateAddresses }): ValidationRule => ({
		assert: (value) => {
			const addresses = this.parseMultipleValues(value).split(';');
			return addresses.every(address => this.isIpAddressInputValid(address, onlyPrivateAddresses));
		},
		message: (value) => {
			const invalidIp = this.parseMultipleValues(value).split(';').find(address => !this.isIpAddressInputValid(address, onlyPrivateAddresses));
			return `${invalidIp} is an invalid address.`;
		}
	});

	IpIsInAddressSpacesRule = (): ValidationRule => ({
		assert: (sourceIpsInput) => {
			const sourceIps = this.parseMultipleValues(sourceIpsInput).split(';');
			const addressSpaces = this.props.virtualPrivateClouds.flatMap(vNet => vNet.cidr);
			return sourceIps.every(ip => this.isIpInAnyAddressSpace(ip, addressSpaces));
		},
		message: (sourceIpsInput) => {
			const addressSpaces = this.props.virtualPrivateClouds.flatMap(vNet => vNet.cidr);
			const invalidIp = this.parseMultipleValues(sourceIpsInput).split(';').find(ip => !this.isIpInAnyAddressSpace(ip, addressSpaces));
			return `${invalidIp} is not part of any valid address space.`;
		}
	});

	resetState = () => {
    	this.setState({ stateForm: this.getDefaultForm() });
	};
	
	stateFormHandler = () => this.getStateFormHandlers();
}