import * as React from 'react';
import { Toggle, ToggleProps } from 'react-unity';
import './ToggleField.css';
import { Option } from '../../../utils/Option';
import { AbstractSelectableProps, AbstractSelectable, AbstractSelectableState } from './AbstractSelectable';

interface Props extends AbstractSelectableProps {
	onChange?: (...args: any[]) => any;
}

export default class ToggleField
	extends AbstractSelectable<ToggleProps, Props, AbstractSelectableState> {
	
	constructor(props) {
		super(props);
		this.state = {
			options: []
		}
	}

	render() {
		return (
			<Toggle
				{...this.props}
				note={this.nonValidMessage() || this.props.note}
				className={`${!this.isValid() && 'toggle-error' || ''} ${this.props.className ?? ''}`}
			>
				{this.state.options.map((option: Option, index: number) => (
					<Toggle.Item className={this.state.options.length == 1 ? 'em-c-toogle-single-option' : null + ` ${this.props.className ?? ''}`}
						id={`${this.props.id || `toggle-${this.props.label.split(' ').join('')}`}-${index}`}
						key={`${this.props.id || 'toggle'}-${option.value.toString()}`}
						name={`${this.props.id || 'toggle'}-${index}`}
						value={option.value}
						onChange={this.props.onChange}
						checked={this.props.value === option.value}
						disabled={this.props.disabled}
					>
						{option.text}
					</Toggle.Item>
				))}
			</Toggle>
		);
	}
}
