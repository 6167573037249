import { Enumeration } from '../../utils/AppEnum';

export class ObjectType extends Enumeration<ObjectType> {
    public static SubscriptionRequest = new ObjectType('Subscription', 1);

    public static WorkflowInstance = new ObjectType('Workflow Instance', 2);

    public static PortOpeningRequest = new ObjectType('Port Opening Request', 3);

    public static ApimApiRequest = new ObjectType('Apim Api Request', 4);

    public static EnterpriseApp = new ObjectType('Enterprise App', 5);

    public static DoNotDecryptRequest = new ObjectType('DPI (Deep Packet Inspection) Exception Request', 6);

    public static AWSPortOpeningRequest = new ObjectType('AWS Port Opening Request', 7);
}