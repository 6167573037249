import React, { useEffect, useState } from 'react';
import { Alert, Container, Grid, Modal, XFilledIcon } from 'react-unity';
import { AbstractFormState } from '../../common/AbstractForm';
import { SubDomainOperationForm, ISubDomainOperationStateForm, SubDomainOperationFormProps } from './SubDomainOperationForm';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import './SubDomainOperationModal.css'
import SelectField from '../../common/form-controls/SelectField';
import { DomainOperationRequest } from '../../../models/viewModels/Subscriptions/DomainOperationRequest';
import DomainOperationService from '../../../services/DomainOperationService';
import ResourceGroup from '../../../models/entities/ResourceGroup';
import ReactButton from '../../common/ReactButton';
import Select, { StylesConfig } from "react-select";

interface SubDomainOperationModalRouteProps {
	visible: boolean;
	onClose: any;
	onConfirm: any;
	subId?: number;
	isConnected: boolean;
}

interface IOption {
	value: any;
	label: string;
}

const SubDomainOperationModal = (props: SubDomainOperationModalRouteProps) => {
	const [formData] = useState<SubDomainOperationForm<SubDomainOperationFormProps, AbstractFormState<ISubDomainOperationStateForm>>>(new SubDomainOperationForm(props));
	const [state, setStateForm] = useState<AbstractFormState<ISubDomainOperationStateForm>>({
		stateForm: {
			resourceGroupName: '',
			virtualMachineName: '',
			action: '',
		},
	});
	const [submitting, setSubmitting] = useState<boolean>();
	const [submissionAlert, setSubmissionAlert] = useState<AlertBanner>({
		visible: false,
	});
	const [resourceGroups, setResoruceGroups] = useState<ResourceGroup[]>([]);
	const [resourceGroupsOptions, setResoruceGroupOptions] = useState<IOption[]>([]);
	const [resourcesOptions, setResorucesOptions] = useState<IOption[]>([]);
	const [loadingResourceGroupName, setLoadingResourceGroupName] = useState<boolean>(false);
	const [loadingVirtualMachineName, setLoadingVirtualMachineName] = useState<boolean>(false);
	const [resourceGroupSelected, setResourceGroupSelected] = useState<ResourceGroup>();
	const domainOperationService = new DomainOperationService();
	const VMStyles: StylesConfig<any, false> = {
		control: (css) => ({
			...css,
			borderColor: state?.stateForm?.virtualMachineName == '' && 'rgb(202, 106, 114)!important',
		}),
		valueContainer: () => ({
			padding: 10,
			display: "flex",
			alignItems: "center"
		}),
		menu: () => ({
			zIndex: 50,
			position: "fixed",
			backgroundColor: "#fff"
		}),
	};
	const ResourceStyles: StylesConfig<any, false> = {
		control: (css) => ({
			...css,
			borderColor: state?.stateForm?.resourceGroupName == '' && 'rgb(202, 106, 114)!important',
		}),
		valueContainer: () => ({
			padding: 10,
			display: "flex",
			alignItems: "center"
		}),
		menu: () => ({
			zIndex: 50,
			position: "fixed",
			backgroundColor: "#fff"
		}),
	};

	const initFormModel = async () => {
		setLoadingResourceGroupName(true);
		formData.initFormModel(state);
		const resourceGroups = props.isConnected ? await domainOperationService.getResourceGroupsBySubscriptionId(props.subId) : [];
		let options: IOption[] = [];
		resourceGroups.map(virtualMachineName => options.push({ value: virtualMachineName.name, label: virtualMachineName.name }))
		setResoruceGroupOptions(options);
		setResoruceGroups(resourceGroups);
		setLoadingResourceGroupName(false);
	}

	const handleStateFormChange = (field, value): void => {
		const fields = [].concat(...[field]);
		const values = !Array.isArray(field) ? [value] : value;
		const form = state.stateForm;
		fields.forEach((fieldName, index) => {
			form[fieldName] = values[index];
		});
		setStateForm({ ...state, stateForm: form });
	}

	const getDefaultStateForm = (): void => {
		const form = state.stateForm;
		Object.keys(form).forEach(key => {
			form[key] = '';
		})
		setStateForm({ ...state, stateForm: form });
	}

	const stateFormHandler = () => getStateFormHandlers();

	const getStateFormHandlers = () => {
		return {
			resourceGroupName: {
				value: state.stateForm?.resourceGroupName,
				validation: formData?.formModel?.fields?.resourceGroupName.validation,
				placeholder: '',
				options: resourceGroupsOptions,
				isDisabled: submitting || loadingResourceGroupName,
				isLoading: loadingResourceGroupName,
				onChange: async (event: IOption) => {
					setLoadingVirtualMachineName(true)
					handleStateFormChange('resourceGroupName', event);
					await handleResourceVMOptions(event.value);
				}
			},
			virtualMachineName: {
				value: state.stateForm?.virtualMachineName,
				validation: formData?.formModel?.fields?.virtualMachineName.validation,
				isDisabled: submitting,
				placeholder: '',
				isLoading: loadingVirtualMachineName,
				options: resourcesOptions,
				onChange: (event: IOption) => {
					handleStateFormChange('virtualMachineName', event);
				}
			},
			action: {
				value: state.stateForm?.action,
				validation: formData?.formModel?.fields?.action.validation,
				options: [
					{ value: 'join', text: 'Add' },
					{ value: 'unjoin', text: 'Remove' },
				],
				label: 'Actions',
				disabled: submitting,
				onChange: (event) => {
					handleStateFormChange('action', event.target.value);
				}
			}
		};
	}

	const handleSave = async (e) => {
		window.scroll(0, 0);
		setSubmitting(true);
		setSubmissionAlert({
			visible: true,
			text: 'Submitting request...',
			variant: 'default'
		})
		try {
			let model = formData.formModel.create(DomainOperationRequest);
			model.resourceGroupName = model.resourceGroupName['label'];
			model.virtualMachineName = model.virtualMachineName['label'];
			await domainOperationService.manageVirtualMachinesDomian(props.subId, model);
			setSubmissionAlert({
				visible: true,
				text: 'Request submitted succesfully. An email will be sent once the operation is completed.',
				variant: 'success'
			});
		} catch (err) {
			setSubmissionAlert({
				visible: true,
				variant: 'error',
				text: err.response?.data.message || 'An error occured with your request. Please, try again later.'
			});
		} finally {
			setSubmitting(false);
		}
	};

	const handleCancel = (e) => {
		setSubmissionAlert({visible: false});
		formData.handleCancel(e);
		setResorucesOptions([]);
		getDefaultStateForm();
	}

	const handleResourceVMOptions = async (resoruceGroupName: any) => {
		handleStateFormChange('virtualMachineName', null);
		const resourceSelected = resourceGroups.find((resoruceGroup) => resoruceGroup.name == resoruceGroupName);
		setResourceGroupSelected(resourceSelected);
		const resourceVMs = await domainOperationService.getVirtualMachinesByResourceGroup(props.subId, resourceSelected.name);
		let options: IOption[] = [];
		resourceVMs.map((virtualMachineName, index) => options.push({ value: index, label: virtualMachineName.name }));
		setResorucesOptions(options);
		setLoadingVirtualMachineName(false);
	}

	const CustomOptionResource = (props: { data: any; innerRef: any; innerProps: any }) => {
		const { data, innerRef, innerProps } = props;
		return (
			<div
				style={{ cursor: 'pointer' }}
				ref={innerRef}
				{...innerProps}
				className='custom-option-box'
			>
				<div className='custom-option'>{loadingResourceGroupName ? data.loadingMessage : data.label}</div>
			</div>
		)
	}

	const CustomOptionVM = (props: { data: any; innerRef: any; innerProps: any }, loading: boolean) => {
		const { data, innerRef, innerProps } = props;
		return (
			<div
				style={{ cursor: 'pointer' }}
				ref={innerRef}
				{...innerProps}
				className='custom-option-box'
			>
				<div className='custom-option'>{loadingVirtualMachineName ? data.loadingMessage : data.label}</div>
			</div>
		)
	}

	useEffect(() => {
		props.subId && initFormModel();
	}, [props.subId])

	return (
		<Modal show={props.visible}>
			<Modal.Window id="sub-domain-operation-modal">
				<Modal.Window.Header>
					<Modal.Window.Header.Title>
						Request Domain Operation
					</Modal.Window.Header.Title>
					<Modal.Window.Header.CloseButton onClick={handleCancel}>
						<XFilledIcon size='small' />
					</Modal.Window.Header.CloseButton>
				</Modal.Window.Header>
				<Modal.Window.Body>
					<Container>
						{submissionAlert.visible &&
							<Alert
								variant={submissionAlert.variant}
								onClose={() => setSubmissionAlert({ visible: false })}
							>
								{submissionAlert.text}
							</Alert>}
						<Grid>
								<div className="em-c-field em-is-valid em-c-field--date-picker full-width">
									<label className="em-c-field__label">Resource Group</label>
									<Select
										isSearchable={false}
										styles={ResourceStyles}
										//className="react-select-container"
										classNamePrefix="react-select"
										components={{ Option: CustomOptionResource }}
										{...stateFormHandler().resourceGroupName}
									/>
								</div>
							</Grid>
							<Grid>
								<div className="em-c-field em-is-valid em-c-field--date-picker full-width">
									<label className="em-c-field__label">Resource (VM)</label>
									<Select
										isSearchable={false}
										styles={VMStyles}
										className="react-select-container"
										classNamePrefix="react-select"
										components={{ Option: CustomOptionVM }}
										{...stateFormHandler().virtualMachineName}
								/>
								<div className="em-c-field__note">VM must be running and Windows Server OS installed</div>
							</div>							
						</Grid>
						<Grid>
							<SelectField
								className="full-width"
								{...stateFormHandler().action}
							/>
						</Grid>
						<Grid variant="3-up">
							<Grid.Item>
								<ReactButton
									variant="secondary"
									isLoading={submitting}
									handleUpdateSubmit={(e) => handleCancel(e)}
									name='Cancel'
									disabled={submitting}
									loadingVariant={'default'}
								/>
							</Grid.Item>
							<Grid.Item />
							<Grid.Item>
								<ReactButton
									variant="secondary"
									isLoading={submitting}
									handleUpdateSubmit={(e) => handleSave(e)}
									name='Request'
									disabled={submitting || !formData.isValid(state)}
									loadingVariant={'default'}
								/>
							</Grid.Item>
						</Grid>
					</Container>
				</Modal.Window.Body>
			</Modal.Window>
		</Modal>
	);
}


export default SubDomainOperationModal;