import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChartLineIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3 15a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM6 10a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM14 5a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM11 12a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M3.146 11.146l2-2 .707.707-2 2zM7.277 8.948l.447-.895 2 1-.447.894zM11.053 8.276l2-4 .894.447-2 4z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path d="M46 45H2a1 1 0 0 1-1-1V3h2v40h43z" fill="currentColor" />
        <path
          d="M9 38a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM20 25a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM32 28a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM39 16a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M21.659 21.447l.894-1.788 7.789 3.894-.895 1.789zM32.687 22.525l3.787-8.839 1.839.788-3.787 8.84zM9.6 32.201l7.199-9.6 1.6 1.2-7.198 9.6z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <circle cx={3.5} cy={18} r={2} fill="currentColor" />
        <path
          d="M3.5 20.5A2.5 2.5 0 1 1 6 18a2.5 2.5 0 0 1-2.5 2.5zm0-4A1.5 1.5 0 1 0 5 18a1.5 1.5 0 0 0-1.5-1.5z"
          fill="currentColor"
        />
        <circle cx={9.5} cy={11} r={2} fill="currentColor" />
        <path
          d="M9.5 13.5A2.5 2.5 0 1 1 12 11a2.5 2.5 0 0 1-2.5 2.5zm0-4A1.5 1.5 0 1 0 11 11a1.5 1.5 0 0 0-1.5-1.5z"
          fill="currentColor"
        />
        <circle cx={16.5} cy={13} r={2} fill="currentColor" />
        <path
          d="M16.5 15.5A2.5 2.5 0 1 1 19 13a2.5 2.5 0 0 1-2.5 2.5zm0-4A1.5 1.5 0 1 0 18 13a1.5 1.5 0 0 0-1.5-1.5z"
          fill="currentColor"
        />
        <circle cx={21.5} cy={4} r={2} fill="currentColor" />
        <path
          d="M21.5 6.5A2.5 2.5 0 1 1 24 4a2.5 2.5 0 0 1-2.5 2.5zm0-4A1.5 1.5 0 1 0 23 4a1.5 1.5 0 0 0-1.5-1.5zM3.586 16L8 11.586 9.414 13 5 17.414zM9.32 11.748l.77-1.846 5.82 2.427-.77 1.846z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M16.11 12.046l4.013-8.028 1.789.895-4.014 8.027z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChartLineIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChartLineIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChartLineIcon;
