import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OilTanker = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M16,7.4C15.9,7.2,15.7,7,15.5,7H6.2L5.4,6.1C5.3,6.1,5.1,6,5,6V3.5C5,3.2,4.8,3,4.5,3h-3C1.2,3,1,3.2,1,3.5
		V6H0.5C0.2,6,0,6.2,0,6.5c0,0.3,0,3.2,1.1,4.4l0.4,0.4l0.7-0.7l-0.4-0.4C1.3,9.6,1.1,8.1,1,7h3.8l0.9,0.9C5.7,7.9,5.9,8,6,8h8
		c-0.5,0.6-1,1.4-1,2.5V11h1v-0.5c0-1.7,1.7-2.5,1.7-2.6C15.9,7.8,16,7.6,16,7.4z M2,4h2v2H2V4z"
          />
          <path
            fill="currentColor"
            d="M13.9,12.7C13.5,12.4,13,12,12.2,12c-0.8,0-1.2,0.4-1.5,0.7c-0.2,0.2-0.4,0.3-0.6,0.3
		c-0.1,0-0.3-0.2-0.6-0.3C9.2,12.4,8.7,12,7.9,12c-0.8,0-1.2,0.4-1.5,0.7C6.2,12.9,6.1,13,5.8,13c-0.1,0-0.3-0.2-0.6-0.3
		C4.9,12.4,4.3,12,3.6,12c-0.8,0-1.2,0.4-1.5,0.7C1.9,12.9,1.7,13,1.5,13H1v1h0.5c0.7,0,1-0.4,1.3-0.6C3,13.2,3.2,13,3.6,13
		c0.4,0,0.8,0.2,1.1,0.5C5,13.7,5.4,14,5.8,14c0.7,0,1-0.4,1.3-0.6C7.4,13.2,7.5,13,7.9,13c0.4,0,0.8,0.2,1.1,0.5
		c0.3,0.2,0.7,0.5,1.2,0.5c0.7,0,1-0.4,1.3-0.6c0.2-0.2,0.4-0.4,0.8-0.4c0.4,0,0.8,0.2,1.1,0.5c0.3,0.2,0.7,0.5,1.2,0.5H15v-1h-0.5
		C14.4,13,14.2,12.8,13.9,12.7z"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M44.8,35.4C44.1,34.8,43,34,41.4,34c-1.5,0-2.4,0.8-3,1.3C38,35.8,37.7,36,37.2,36c-0.3,0-0.7-0.3-1.2-0.6
		c-0.8-0.6-1.9-1.4-3.4-1.4c-1.5,0-2.4,0.8-3,1.3c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6c-0.8-0.6-1.9-1.4-3.4-1.4
		c-1.5,0-2.4,0.8-3,1.3c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6C17.7,34.8,16.6,34,15,34c-1.5,0-2.4,0.8-3,1.3
		c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6C8.9,34.8,7.8,34,6.2,34c-1.5,0-2.4,0.8-3,1.3C2.8,35.8,2.5,36,2,36H1v2h1
		c1.3,0,2.1-0.7,2.6-1.2C5.1,36.3,5.5,36,6.2,36c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2
		c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8
		c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1
		c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1h1v-2h-1C45.7,36,45.3,35.7,44.8,35.4z"
          />
          <path
            fill="currentColor"
            d="M47.9,20.7C47.8,20.3,47.4,20,47,20H17.3l-2.7-1.8C14.4,18.1,14.2,18,14,18v-7c0-0.6-0.4-1-1-1H3
		c-0.6,0-1,0.4-1,1v7H1c-0.6,0-1,0.4-1,1c0,0.3,0,8.4,3.2,12.6l0.6,0.8l1.6-1.2l-0.6-0.8C2.6,27.5,2.1,22.2,2,20h11.7l2.7,1.8
		c0.2,0.1,0.4,0.2,0.6,0.2h27.6C43,23.8,41,27,41,31v1h2v-1c0-5.4,4.6-9.2,4.6-9.2C48,21.5,48.1,21.1,47.9,20.7z M4,18v-6h8v6H4z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M21.5,18.7c-0.5-0.3-1.2-0.7-2.2-0.7c-1,0-1.6,0.5-1.9,0.8C17.2,19,17.2,19,17,19c-0.1,0-0.3-0.2-0.5-0.3
		c-0.5-0.3-1.2-0.7-2.2-0.7c-1,0-1.6,0.5-1.9,0.8C12.2,19,12.2,19,12,19c-0.1,0-0.3-0.2-0.5-0.3c-0.5-0.3-1.2-0.7-2.2-0.7
		c-1,0-1.6,0.5-1.9,0.8C7.2,19,7.2,19,7,19c-0.1,0-0.3-0.2-0.5-0.3C6.1,18.4,5.4,18,4.4,18c-1,0-1.6,0.5-1.9,0.8C2.2,19,2.2,19,2,19
		H1v2h1c0.9,0,1.4-0.4,1.7-0.7C4,20.1,4.1,20,4.4,20c0.4,0,0.7,0.2,1.1,0.4C5.9,20.7,6.4,21,7,21c0.9,0,1.4-0.4,1.7-0.7
		C9,20.1,9.1,20,9.4,20c0.4,0,0.7,0.2,1.1,0.4c0.4,0.3,0.9,0.6,1.5,0.6c0.9,0,1.4-0.4,1.7-0.7c0.3-0.2,0.4-0.3,0.7-0.3
		c0.4,0,0.7,0.2,1.1,0.4c0.4,0.3,0.9,0.6,1.5,0.6c0.9,0,1.4-0.4,1.7-0.7c0.3-0.2,0.4-0.3,0.7-0.3c0.4,0,0.7,0.2,1.1,0.4
		c0.4,0.3,0.9,0.6,1.5,0.6h1l0-2h-1C21.9,19,21.7,18.8,21.5,18.7z"
          />
          <path
            fill="currentColor"
            d="M23.9,10.6C23.8,10.2,23.4,10,23,10H8.8L8,9.5V5c0-0.6-0.4-1-1-1H2C1.4,4,1,4.4,1,5v4c-0.6,0-1,0.4-1,1
		c0,0.2,0,4.4,1.7,6.6l0.6,0.8l1.6-1.2l-0.6-0.8C2.5,14.3,2.2,12.3,2,11h4.6l1.2,0.8C8.1,11.9,8.3,12,8.5,12h12.3
		c-0.9,1.1-1.8,2.7-1.8,4v1h2v-1c0-1.1,1.6-3.2,2.7-4.3C24,11.4,24.1,11,23.9,10.6z M3,6h3v3H3V6z"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OilTanker.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OilTanker.defaultProps = {
  size: "medium",

  className: ""
};

export default OilTanker;
