import React, { useState } from 'react';
import { Button, Searchbar, Select, Toolbar } from 'react-unity';
import DoNotDecryptRule from '../../models/entities/DoNotDecryptRule';
import { DoNotDecryptRequestsDirection } from '../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import CSVGeneratorButton from '../common/CSVGeneratorButton';
import ToggleField from '../common/form-controls/ToggleField';
import CheckboxFilter, { CheckboxOption } from '../common/tables/CheckboxFilter';
import { DNDRColumn, RuleColumnsLayout } from './components/RuleColumnsLayout';
import Filter from '../common/tables/Filter';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

interface DNDRByRuleFiltersProps {
	columns: DNDRColumn[];
	onFilterChange: any;
	rules: DoNotDecryptRule[];
	changeColumns: any;
	subscriptionId: number;
	rulesFilteredForCSVCreation: DoNotDecryptRule[];
	resetSelectedRules: any;
	selectedRules: DoNotDecryptRule[];
}

export type DNDRRuleFilters = {
	region: CheckboxOption[];
	orderBy: string;
	searchBox: string;
	selectedScope: string;
};

const MyP = styled.p`
	margin-right: 8px;
	margin-bottom: auto !important;
	margin-top: auto !important;
`;

const StyledItem = styled(Toolbar.Item)`
	width: 50%;
`;

const MyLink = styled.span`
	color: #0c69b0;
	text-decoration: underline;
	cursor: pointer;
`;

const DNDRByRuleFilters = ({
	rules,
	columns,
	changeColumns,
	onFilterChange,
	subscriptionId,
	rulesFilteredForCSVCreation,
	resetSelectedRules,
	selectedRules,
}: DNDRByRuleFiltersProps) => {
	const navigate = useNavigate();
	const getDefaultFilters = () => {
		const defaultFilters = {
			region: [
				{
					label: 'USSC',
					value: 'USSC',
					checked: true,
				},
				{
					label: 'USE1',
					value: 'USE1',
					checked: true,
				},
				{
					label: 'ASST',
					value: 'ASST',
					checked: true,
				},
			],
			orderBy: 'newest',
			selectedScope: DoNotDecryptRequestsDirection.SubscriptionToInternet.name,
			searchBox: '',
		} as DNDRRuleFilters;

		return defaultFilters;
	};

	const filterPORs = () => {
		let filteredRequests = [];

		const filterCallback = (rule: DoNotDecryptRule) => {
			return (
				filter.filterSearchText('selectedScope', rule.direction.name) &&
				filter.filterCheckBox('region', rule.region.name) &&
				(filter.filterSearchText('searchBox', rule.name) ||
					filter.filterSearchText('searchBox', rule.description) ||
					filter.filterSearchText('searchBox', rule.sourceIPs) ||
					//filter.filterSearchText('searchBox', rule.destinationIPs) ||
					filter.filterSearchText('searchBox', rule.destinationURLs) 
					//filter.filterSearchText('searchBox', rule.destinationPorts)
					)
			);
		};

		filteredRequests = rules.filter(filterCallback).sort((a, b) => {
			if (filter.filters.orderBy === 'newest') {
				return a.id < b.id ? 1 : -1;
			}
			return a.id > b.id ? 1 : -1;
		});
		onFilterChange(filteredRequests);
	};

	const [filter] = useState<Filter<DNDRRuleFilters>>(new Filter(filterPORs, getDefaultFilters()));


	const diffRegionScoped = {
		label: 'ASEA',
		value: 'ASEA',
		checked: true,
	};

	const handleChangeScope = (e) => {
		changeFilterOptions(e.target.value);
		changeColumns(RuleColumnsLayout.get(new DoNotDecryptRequestsDirection().fromName(e.target.value)));
		resetSelectedRules();
		filter.handleSearchTextChange(e, 'selectedScope');
	};

	const changeFilterOptions = (newSelectedScope) => {
		if (newSelectedScope === DoNotDecryptRequestsDirection.SubscriptionToInternet.name) {
			filter.updateFilter([...filter.filters.region, diffRegionScoped], 'region');
		} else {
			filter.updateFilter(
				filter.filters.region.filter((reg) => reg.value !== 'ASEA'),
				'region'
			);
		}
	};

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchBox');
	};

	return (
		<>
			{filter && (
				<>
					<Toolbar style={{ backgroundColor: 'white', borderTopColor: 'white' }}>
						<StyledItem>
							<Searchbar
								condensed
								buttonProps={{ onClick: clearSearchbar}}
								inputProps={{
									value: filter.filters.searchBox,
									onChange: (e) => filter.handleSearchTextChange(e, 'searchBox'),
									placeholder: 'Search all fields shown below (i.e. specific IP address, Description, etc.)',
								}}
							/>
						</StyledItem>
						<Toolbar.Item right>
							<Button.Group>
								<CSVGeneratorButton data={rulesFilteredForCSVCreation} columns={columns} />
								<Button
									variant="primary"
									size="small"
									onClick={() => {
										navigate(`/subscriptions/${subscriptionId}/doNotDecryptRequests/new`);
									}}
								>
									<span>Open new request</span>
								</Button>

								<Link
									to={`/subscriptions/${subscriptionId}/doNotDecryptRequests/new`}
									state={{ selectedRules: selectedRules }}
								>
									<Button
										variant="secondary"
										size="small"
										disabled={selectedRules.length === 0}
									>
										<span>Modify selected</span>
									</Button>
								</Link>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
					<Toolbar>
						<Toolbar.Item>
							<ToggleField
								label=""
								className="em-u-margin-none"
								size="small"
								value={filter.filters.selectedScope}
								options={[
									{
										value: DoNotDecryptRequestsDirection.SubscriptionToInternet.name,
										text: 'Sub -> Internet',
									},
									{
										value: DoNotDecryptRequestsDirection.SubscriptionToOnPrem.name,
										text: 'Sub <-> XOMNet',
									},
								]}
								onChange={(e) => handleChangeScope(e)}
							/>
						</Toolbar.Item>						
						<Toolbar.Item>
							<CheckboxFilter
								label="Region"
								onChange={(event) => filter.handleCheckboxChange(event, 'region')}
								options={filter.filters.region}
							/>
						</Toolbar.Item>
						<Toolbar.Item>
							<MyLink onClick={() => filter.updateFilters(getDefaultFilters())}>Clear filters</MyLink>
						</Toolbar.Item>
						<Toolbar.Item right>
							<Button.Group>
								<MyP>Sort by</MyP>
								<Select
									style={{ marginBottom: -10 }}
									value={filter.filters.orderBy}
									onChange={(e) => filter.handleSearchTextChange(e, 'orderBy')}
								>
									<option value="newest">Newest</option>
									<option value="oldest">Oldest</option>
								</Select>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default DNDRByRuleFilters;
