import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import GridItem from "./GridItem";

/** Grid is a grid layout that will make different columns of grid items based on variant, until the screen is small, then they will stack to one column */
const Grid = props => {
  const { variant, breakSpeed, className, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-l-grid",
        {
          "em-l-grid--halves": variant === "halves",
          "em-l-grid--2up": variant === "2-up",
          "em-l-grid--1-2-1": variant === "1-2-1",
          "em-l-grid--3up": variant === "3-up",
          "em-l-grid--1-to-3up": variant === "1-3",
          "em-l-grid--4up": variant === "4-up",
          "em-l-grid--1-2-4up": variant === "1-2-4",
          "em-l-grid--5up": variant === "5-up",
          "em-l-grid--6up": variant === "6-up",
          "em-l-grid--2-3-6up": variant === "2-3-6",
          "em-l-grid--break-fast": breakSpeed === "fast",
          "em-l-grid--break-slow": breakSpeed === "slow"
        },
        className
      )}
      {...other}
    >
      {children}
    </div>
  );
};

Grid.propTypes = {
  /** variant of the grid, determines how the grid items will be displayed */
  variant: PropTypes.oneOf([
    "halves",
    "2-up",
    "1-2-1",
    "3-up",
    "1-3",
    "4-up",
    "1-2-4",
    "5-up",
    "6-up",
    "2-3-6"
  ]),
  /** breakSpeed sets the speed the grid breaks apart going from mobile to desktop */
  breakSpeed: PropTypes.oneOf(["default", "fast", "slow"]),
  /** children of the container */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Grid.defaultProps = {
  variant: "2-up",
  breakSpeed: "default",
  children: null,
  className: ""
};

Grid.Item = GridItem;

export default Grid;
