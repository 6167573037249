import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ListIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M5 2h10v1H5zM2.5 4A1.5 1.5 0 1 1 4 2.5 1.5 1.5 0 0 1 2.5 4zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM5 7h10v1H5zM2.5 9A1.5 1.5 0 1 1 4 7.5 1.5 1.5 0 0 1 2.5 9zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM5 12h10v1H5zM2.5 14A1.5 1.5 0 1 1 4 12.5 1.5 1.5 0 0 1 2.5 14zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z"
        />
        <path
          fill="currentColor"
          d="M5 2h10v1H5zM2.5 4A1.5 1.5 0 1 1 4 2.5 1.5 1.5 0 0 1 2.5 4zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM5 7h10v1H5zM2.5 9A1.5 1.5 0 1 1 4 7.5 1.5 1.5 0 0 1 2.5 9zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM5 12h10v1H5zM2.5 14A1.5 1.5 0 1 1 4 12.5 1.5 1.5 0 0 1 2.5 14zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M14 8h32v2H14zM14 22h32v2H14zM14 36h32v2H14zM7 42a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3zM7 28a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3zM7 14a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M9 4h15v2H9zM9 11h15v2H9zM9 18h15v2H9zM4 21a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zM4 14a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zM4 7a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ListIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ListIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ListIcon;
