import ApiClient from './ApiClient';
import Group from '../models/Group';
import GroupAccessReview from '../models/GroupAccessReview';

export default class GroupsService extends ApiClient {
	async getMyGroups() {
		return this.get<Group[]>('groups/me');
	}

	async getById(id: string) {
		return this.get<Group>(`groups/${id}`);
	}

	async createGroup(displayName: string,description: string ,membershipRule: string) {
		return this.post('groups', { displayName, description,  membershipRule });
	}

	async updateGroup(id: string, membershipRule: string) {
		return this.patch(`groups/${id}`, { membershipRule });
	}

	async groupLookup(name:string) {
		return this.get<Group[]>(`groups/lookup?nameFilter=${name}&maxRows=10`);
	}

	async createGroupAccessReview(id: string, model: GroupAccessReview) {
		return this.post(`groups/${id}/accessReview`, model);
	}

}