import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FlagIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M2 1h1v15H2z" />
        <path
          d="M8.5 9H2V8h6V2H2V1h6.5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5zM13.5 12H10v-1h3V4h-3V3h3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path
          d="M8.5 12h-3a.5.5 0 0 1-.354-.854l3-3A.5.5 0 0 1 9 8.5v3a.5.5 0 0 1-.5.5zm-1.793-1H8V9.707z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M8 47a3 3 0 0 1-3-3V4a3 3 0 0 1 6 0v40a3 3 0 0 1-3 3zM8 3a1 1 0 0 0-1 1v40a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1zM32 27H13v-2h18V5H13V3h19a1 1 0 0 1 1 1v22a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M44 35H29v-2h14V13h-8v-2h9a1 1 0 0 1 1 1v22a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M24 35a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h8a1 1 0 0 1 .707 1.707l-8 8A1 1 0 0 1 24 35zm1-8v4.586L29.586 27z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16 13H3v-2h12V2H3V0h13a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M23 18H13v-2h9V6h-5V4h6a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M8 18a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h8a1 1 0 0 1 .53 1.848l-8 5A1 1 0 0 1 8 18zm1-5v2.2l3.513-2.2zM0 0h2v24H0z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FlagIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FlagIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FlagIcon;
