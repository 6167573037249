import React from 'react';
import FormModel from '../../utils/FormModel';

export interface IStateForm { }

export interface AbstractFormState<T> {
	stateForm?: T;
}

export abstract class AbstractForm<
	Props,
	State extends AbstractFormState<IStateForm>
> extends React.Component <Props, State>{
	formModel: FormModel;

	handleStateFormChange(field: string, value: any): void;
	handleStateFormChange(field: string[], value: any[]): void;

	handleStateFormChange(field, value): void {
		const fields = [].concat(...[field]);
		const values = !Array.isArray(field) ? [value] : value;
		const form = this.state.stateForm;
		fields.forEach((fieldName, index) => {
			form[fieldName] = values[index];
		});
		this.setState({ stateForm: form });
	}
}