import CartaIdInfo from "./CartaIdInfo";

export default class CartaObjectLookup extends CartaIdInfo {

	applicationId: string;

	status: string;

	URL: string;

	constructor(obj: any) {
		super(obj);
		this.applicationId = obj["Application ID"];
		Object.assign(this, obj);
	}
}
