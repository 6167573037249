import User from '../User';
import { FeedbackCategory } from '../enums/Feedback/FeedbackCategory';
import { WorkItemState } from '../enums/Feedback/WorkItemState';

export default class Feedback {
	id: number;

	title!: string;

	category!: FeedbackCategory;

	description!: string;

	submittedBy!: User;

	submitDate!: Date;

	workItemNumber!: number;

	workItemState!: WorkItemState;

	comments!: string;

	constructor(obj?: any) {
		Object.assign(this, obj);
		this.category = new FeedbackCategory().fromValue(obj?.category?.value);
		this.workItemState = new WorkItemState().fromValue(obj?.workItemState?.value);
	}
}
