import moment from "moment";
import SubscriptionRequest from "../../../models/entities/TAP";
import TAP from "../../../models/entities/TAP";

const exportColumns = [
    {
        header: 'Requestor',
        cell: (tap: TAP) => tap.requestor.email?.address,
    },
    {
        header: 'Requestor Name',
        cell: (tap: TAP) => tap.requestor.displayName,
    },
    {
        header: 'OnBehalfOf',
        cell: (tap: TAP) => tap.onBehalf.email?.address,
    },
    {
        header: 'OnBehalfOf Name',
        cell: (tap: TAP) => tap.onBehalf.displayName,
    },
    {
        header: 'Requested Date',
        cell: (tap: TAP) => moment(tap.createdTime).format('MM/DD/YYYY'),
    },
];

export default exportColumns;