import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const GavelIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M1.156 14.066l.707-.707 1.2 1.2-.707.707zM5.956 14.56l1.2-1.2.707.707-1.2 1.2zM4 13h1v2H4zM13.389 14.8a.5.5 0 0 1-.354-.146l-4.6-4.6.707-.707 4.247 4.253.708-.707-4.243-4.247.707-.707 4.6 4.6a.5.5 0 0 1 0 .707l-1.415 1.414a.5.5 0 0 1-.357.14zM4.9 11.975a.5.5 0 0 1-.354-.146L1.015 8.293a.5.5 0 0 1 0-.707L8.086.515a.5.5 0 0 1 .353-.147.5.5 0 0 1 .354.146l3.535 3.536a.5.5 0 0 1 0 .707l-7.071 7.072a.5.5 0 0 1-.357.146zM2.075 7.939L4.9 10.768 11.268 4.4 8.439 1.575z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M3.294 43.708l1.414-1.415 4 4-1.414 1.415zM19.293 46.293l4-4 1.415 1.414-4 4zM14 41h2v6h-2zM41.714 46.01a1.006 1.006 0 0 1-.714-.3L24.571 29.286 26 27.857l15.714 15.714 2.857-2.857L28.857 25l1.429-1.429L46.714 40a1.01 1.01 0 0 1 0 1.429l-4.286 4.286a1.006 1.006 0 0 1-.714.295zM13.5 36.208a2.992 2.992 0 0 1-2.121-.876L2.9 26.846a3.005 3.005 0 0 1 0-4.242L24.111 1.391a3.072 3.072 0 0 1 4.242 0l8.485 8.485a3 3 0 0 1 0 4.243L15.626 35.332a2.993 2.993 0 0 1-2.126.876zm12.729-33.7a.991.991 0 0 0-.706.292L4.312 24.018a1 1 0 0 0 0 1.415l8.488 8.484a1 1 0 0 0 1.415 0L35.425 12.7a1 1 0 0 0 0-1.415L26.939 2.8a1 1 0 0 0-.707-.288z"
        />
        <path
          fill="currentColor"
          d="M6.1 23.5l1.4-1.4 8.4 8.4-1.4 1.4zM24.1 5.5l1.4-1.4 8.4 8.4-1.4 1.4z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M.293 20.707l1.414-1.414 3 3-1.414 1.415zM9.293 22.293l3-3 1.414 1.414-3 3zM6 20h2v4H6zM20 23a1 1 0 0 1-.707-.293L12.586 16 14 14.586l6 6 .586-.586-6-6L16 12.586l6.707 6.707a1 1 0 0 1 0 1.414l-2 2A1 1 0 0 1 20 23zM6.5 18.589a3.115 3.115 0 0 1-2.207-.882l-3.019-3a3.2 3.2 0 0 1 .019-4.417l9-9a3.2 3.2 0 0 1 4.414-.021l3 3.024a3.2 3.2 0 0 1 0 4.412l-9 9a3.115 3.115 0 0 1-2.207.884zM12.522 2.4a1.162 1.162 0 0 0-.815.305l-9 9a1.215 1.215 0 0 0-.021 1.586l3.019 3a1.214 1.214 0 0 0 1.588 0l9-9a1.215 1.215 0 0 0 0-1.586l-3-3.023a1.088 1.088 0 0 0-.771-.282z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

GavelIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

GavelIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default GavelIcon;
