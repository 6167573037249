import { AlertVariant } from 'react-unity';
import { Params, Location } from 'react-router-dom';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import { AbstractForm, AbstractFormState, IStateForm } from '../../common/AbstractForm';
import { Environment } from '../../../models/enums/Environment';
import UserLookupObject from '../../../models/UserLookupObject';
import FormModel from '../../../utils/FormModel';
import AWSPortOpeningRule from '../../../models/entities/AWSPortOpeningRule';
import AWSAccount from '../../../models/entities/AWSAccount';
import AWSPortOpeningRequestsService from '../../../services/AWSPortOpeningRequestsService';
import AWSPortOpeningRequest from '../../../models/entities/AWSPortOpeningRequests';
import { LinkTreeItem } from '../../common/LinkTree';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';


interface EndModal extends AlertBanner {
	redirectionLink?: string;
}

export interface AbstractAWSPORFormProps {
	params?: Params<string>;
	navigate?: any;
	location?: Location;
 }


export interface IAWSPORStateForm extends IStateForm {
	environment?: Environment;
	contact?: UserLookupObject | null;
	approver?: UserLookupObject | null;
	delegatedApprover?: UserLookupObject | null;
	customerCoordination?: boolean;
	businessJustification: string;
	comments?: string;
	ruleList: AWSPortOpeningRule[];
}


export interface AbstractAWSPORFormState<T> extends AbstractFormState<T> {
	awsAccount: AWSAccount;
	operationInProgress: boolean;
	endModal: EndModal;
	submissionWarned: boolean;
	submissionAlert: AlertBanner;
}

export abstract class AbstractAWSPORForm<
	Props extends AbstractAWSPORFormProps,
	State extends AbstractAWSPORFormState<IAWSPORStateForm>
	> extends AbstractForm<Props, State> {

	awsPortOpeningRequestService: AWSPortOpeningRequestsService;
	
	constructor(props) {
		super(props);
		this.awsPortOpeningRequestService = new AWSPortOpeningRequestsService();
	}

	async getAWSAccount(id: Number) {
		if (!id) return null;
		const awsAccount = new AWSAccount(await this.awsPortOpeningRequestService.getAWSAccount(id));
		return awsAccount;
	}

	initFormModel() {
		this.formModel = new FormModel({
			accountId: {
				getValue: () => this.state.awsAccount?.account.id,
				validation: {
					required: () => true
				}
			},
			environment: {
				getValue: () => this.state.stateForm.environment?.value,
				validation: {
					required: () => true
				}
			},
			contactId: {
				getValue: () => this.state.stateForm.contact?.id,
			},
			approverId: {
				getValue: () => this.state.stateForm.approver?.id,
				validation: {
					required: () => true
				}
			},
			delegatedApproverId: {
				getValue: () => this.state.stateForm.delegatedApprover?.id,
			},
			businessJustification: {
				getValue: () => this.state.stateForm.businessJustification,
				validation: {
					required: true,
					rules: [
						{
							assert: () =>
								this.state.stateForm.businessJustification?.trim().length > 4,
							message: 'Justification must contain at least 5 characters.',
						},
					],
				},
			},
			customerCoordination: {
				getValue: () => this.state.stateForm.customerCoordination
			},
			portOpeningRules: {
				getValue: () => this.state.stateForm.ruleList,
				validation: {
					required: true
				}
			},
			comments: {
				getValue: () => this.state.stateForm.comments,
			},
		});
	}

	stateFormHandler = () => this.getStateFormHandlers();

	getStateFormHandlers() {
		return {
			awsAccount: {
				value: this.state.awsAccount,
				validation: this.formModel.fields.accountId.validation,
				onChange: (env) => {
					this.setState({awsAccount: env});
					this.handleStateFormChange('accountId', env.account.id);
				}
			},
			environment: {
				value: this.state.stateForm.environment,
				validation: this.formModel.fields.environment.validation,
				onChange: (env) => {
					this.handleStateFormChange('environment', env);
				}
			},
			contact: {
				value: this.state.stateForm.contact?.userPrincipalName,
				validation: this.formModel.fields.contactId.validation,
				onChange: (value) => {
					this.handleStateFormChange('contact', value);
				}
			},
			approver: {
				value: this.state.stateForm.approver?.userPrincipalName,
				validation: this.formModel.fields.approverId.validation,
				onChange: (value) => {
					this.setState({
						submissionWarned: false,
					});
					this.handleStateFormChange('approver', value);
				},
				higherApproval: false
			},
			delegatedApprover: {
				value: this.state.stateForm.delegatedApprover?.userPrincipalName,
				validation: this.formModel.fields.delegatedApproverId.validation,
				onChange: (value) => {
					this.setState({
						submissionWarned: false,
					});
					this.handleStateFormChange('delegatedApprover', value);
				},
				higherApproval: false
			},
			businessJustification: {
				value: this.state.stateForm.businessJustification,
				validation: this.formModel.fields.businessJustification.validation,
				onChange: (event) => {
					this.handleStateFormChange('businessJustification', event.target.value);
				}
			},
			customerCoordination: {
				label:'Customer Coordination Required?',
				options: this.formModel.fields.customerCoordination ?
				[
					{ value: 'true', text: 'Yes' },
					{ value: 'false', text: 'No' },
				]:
				[ {value: 'false', text: 'No'} ],		
				note: 'If yes, the MSP will coordinate the timing of the change with the requestor. If no, the MSP will implement the change as soon as possible.',
				value: this.state.stateForm.customerCoordination,
				validation: this.formModel.fields.customerCoordination.validation,
				onChange: (event) => {
					this.handleStateFormChange('customerCoordination', event.target.value === 'true');
				}
			},
			ruleset: {
				rules: this.state.stateForm.ruleList,
				onChange: (rules: AWSPortOpeningRule[]) => {
					this.handleStateFormChange('ruleList', rules);
				}
			},
			comments: {
				value: this.state.stateForm.comments || '',
				validation: this.formModel.fields.comments.validation,
				onChange: (event) => {
					this.handleStateFormChange('comments', event.target.value);
				}
			}
		};
	}

	handleEndModal = (text: string, variant: AlertVariant, timeout: number, link: string) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
				redirectionLink: link
			}
		},
		() => {
			setTimeout(() => {
				this.endAndRedirect();
			}, timeout);
		});
	};

	endAndRedirect = () => {
		const link = this.state.endModal.redirectionLink;
		this.setState({
			endModal: { visible: false }
		},
		() => {
			this.props.navigate(link);
		});
	};

	setSubmissionAlertInProgress = (message) => {
		window.scroll(0, 0);
		this.setState({
			operationInProgress: true,
			submissionAlert: {
				visible: true,
				text: message
			}
		});
	};

	setSubmissionAlertError = (message) => {
		this.setState({
			submissionAlert: {
				visible: true,
				text: message,
				variant: 'error'
			}
		});
	};

	handleSubmissionAlertClose = () => {
		this.setState({
			submissionAlert: {
				visible: false
			}
		});
	};

	requiresApproverVerification = (selectedScope: string) => {
		return selectedScope == PortOpeningRequestsDirection.AccountToInternet.name;
	};

	async getAccountWithPOR(accountId: Number, id: Number) {
		const subscription = new AWSAccount(await this.awsPortOpeningRequestService.getAWSAccountWithPOR(accountId, id));
		return subscription;
	}

	getLinkTree = (): LinkTreeItem[] => {
		return [
			{text: 'AWS Port Opening Requests', to: `/awsPortOpeningRequest/connectedAWS`}
		];
	};

	// TODO: reduce with notification service
	approverLevelVerified = async (): Promise<boolean> => {
		if (!this.state.submissionWarned) {
			try {
				this.setSubmissionAlertInProgress('Verifying...');
				const response = await this.awsPortOpeningRequestService.verifyApproverLevel(
					this.state.stateForm?.approver?.userPrincipalName, false);
				
				if (!response.allowed) {
					this.setState({
						operationInProgress: false,
						submissionWarned: true,
						submissionAlert: {
							visible: true,
							text: 'The Approver does not meet the minimum DOAG level required.',
							variant: 'warning',
						}
					});
				}

				return response.allowed;
			} catch (err) {
				this.setSubmissionAlertError('The approver could not be verified. Please, try again later.');
				this.setState({
					operationInProgress: false,
				});
				return false;
			}
		}
		return true;
		
	};

	// eslint-disable-next-line class-methods-use-this
	stateFormFromRequest(por: AWSPortOpeningRequest): IAWSPORStateForm {
		return {
			environment: por.environment,
			contact: !!por.contactUser &&
				UserLookupObject.fromUser(por.contactUser),
			approver: !!por.approvingUser &&
				UserLookupObject.fromUser(por.approvingUser),
			delegatedApprover: !!por.delegatedApprovingUser &&
				UserLookupObject.fromUser(por.delegatedApprovingUser),
			businessJustification: por.businessJustification,
			customerCoordination: por.customerCoordination,
			ruleList: por.awsPortOpeningRules,
			comments: por.comments,
		};
	}
}
