import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MediaControlVolumeMutedIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M2.023 1.73l.707-.706 13.246 13.245-.707.708zM14 6h-1V1.988a.129.129 0 0 0-.08-.124.126.126 0 0 0-.146.021L7.726 4.894l-.51-.86 4.973-2.954a1.071 1.071 0 0 1 1.144-.127A1.124 1.124 0 0 1 14 1.988zM11.868 14.493L5.8 11H3.5A1.5 1.5 0 0 1 2 9.5v-3A1.5 1.5 0 0 1 3.5 5H4v1h-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h2.438a.507.507 0 0 1 .25.066l6.18 3.561z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M4.586 5L6 3.586 45.414 43 44 44.414zM16 22h2v8h-2zM41 34h-2V5.1a1.074 1.074 0 0 0-.639-1.01 1 1 0 0 0-1.1.176 1.022 1.022 0 0 1-.148.11l-15.449 9.372-1.037-1.71L36 2.712a2.982 2.982 0 0 1 3.175-.444A3.062 3.062 0 0 1 41 5.1z"
        />
        <path
          d="M36.991 47a2.954 2.954 0 0 1-1.9-.693L16.722 35.245H9a3.017 3.017 0 0 1-3-3.027V18.027A3.017 3.017 0 0 1 9 15h3v2H9a1.015 1.015 0 0 0-1 1.027v14.19a1.015 1.015 0 0 0 1 1.027h8a1 1 0 0 1 .516.144L36.2 44.638a.943.943 0 0 1 .148.108.952.952 0 0 0 1.054.164 1.01 1.01 0 0 0 .6-.941v-1h2v1A3.02 3.02 0 0 1 36.991 47z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M2.087 2.576l1.336-1.489L24.41 19.923l-1.336 1.489zM7 11h2v6H7zM21 11h-2V3c0-.052-.063-.105-.09-.126a.423.423 0 0 0-.293-.1l-6.894 4.613-1.11-1.664L17.4 1.2a2.151 2.151 0 0 1 2.241-.2A2.213 2.213 0 0 1 21 3z"
        />
        <path
          d="M17.993 23.8a1.976 1.976 0 0 1-1.229-.431L7.726 18H4a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h2v2H4v7h4a1 1 0 0 1 .511.141l9.341 5.549a.95.95 0 0 1 .148.11v-1h2v1a1.98 1.98 0 0 1-1.173 1.821 2.023 2.023 0 0 1-.834.179z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MediaControlVolumeMutedIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MediaControlVolumeMutedIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MediaControlVolumeMutedIcon;
