import React from 'react';

interface ResourceTabsProps {
	allResources: any[];
	filter: any;
	disabled?: boolean;
}

const ResourceTabs = ({ allResources, filter, disabled }: ResourceTabsProps) => {
	return (
		<div style={{marginBottom: 0, borderBottom: 0}} className="em-c-tabs em-js em-c-tabs--underline">
			<ul className="em-c-tabs__list">
				{allResources.map(element => (
				<div
					key={element.item1}
					className="em-c-tabs__item"
					style={{ cursor: 'pointer' }}
					onClick={disabled ? () => {} :  () => filter.updateFilter(element.item1, 'showResources')}
				>
					<span style={{ fontSize: 20 }} className={`em-c-tabs__link em-js-tab ${element.item1 == filter.filters.showResources ? 'em-is-active' : ''}`}>
						{element.item1}
					</span>
				</div>
				))}
			</ul>
		</div>
	);
};

export default ResourceTabs;
