import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FlagSimpleFillIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M2 1h1v14H2zM4.5 1.5h9l-2 4 2 4h-9v-8z" />
        <path
          d="M13.5 10h-9a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .447.724L12.059 5.5l1.889 3.776A.5.5 0 0 1 13.5 10zM5 9h7.691l-1.638-3.276a.5.5 0 0 1 0-.447L12.691 2H5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <rect
          x={6.5}
          y={2.5}
          width={4}
          height={42}
          rx={2}
          ry={2}
          fill="currentColor"
        />
        <path
          d="M8.5 45A2.5 2.5 0 0 1 6 42.5v-38a2.5 2.5 0 0 1 5 0v38A2.5 2.5 0 0 1 8.5 45zm0-42A1.5 1.5 0 0 0 7 4.5v38a1.5 1.5 0 0 0 3 0v-38A1.5 1.5 0 0 0 8.5 3zM14.5 4.5h23l-7 11 7 11h-23v-22z"
          fill="currentColor"
        />
        <path
          d="M37.5 27h-23a.5.5 0 0 1-.5-.5v-22a.5.5 0 0 1 .5-.5h23a.5.5 0 0 1 .422.769L31.093 15.5l6.829 10.731A.5.5 0 0 1 37.5 27zM15 26h21.589l-6.511-10.231a.5.5 0 0 1 0-.537L36.589 5H15z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <rect
          x={4}
          y={2}
          width={2}
          height={21}
          rx={1}
          ry={1}
          fill="currentColor"
        />
        <path
          d="M5 24a2 2 0 0 1-2-2V3a2 2 0 0 1 4 0v19a2 2 0 0 1-2 2zM5 3v19zM9 3h11l-3 5.5 3 5.5H9V3z"
          fill="currentColor"
        />
        <path
          d="M20 15H9a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h11a1 1 0 0 1 .878 1.479L18.139 8.5l2.739 5.021A1 1 0 0 1 20 15zm-10-2h8.315l-2.193-4.021a1 1 0 0 1 0-.957L18.315 4H10z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FlagSimpleFillIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FlagSimpleFillIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FlagSimpleFillIcon;
