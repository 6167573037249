import * as React from 'react';
import { Grid, Button, Alert, TextLink } from 'react-unity';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
import UserLookup from '../common/form-controls/UserLookup';
import TextAreaField from '../common/form-controls/TextareaField';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import { EnvironmentInput, PORTypeInput, RulesTable, ApproverInput, ApproverDelegationInput } from './components';
import { AbstractPORForm, AbstractPORFormProps, IPORStateForm, AbstractPORFormState } from './AbstractPORForm';
import { TenantLabel } from '../../models/enums/TenantLabel';
import { Validation } from '../../utils/Validation';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import AlertModal from '../common/modals/AlertModal';
import SubmitPortOpeningRequest from '../../models/viewModels/PortOpeningRequests/SubmitPortOpeningRequest';
import SubmitPortOpeningRequestRule from '../../models/viewModels/PortOpeningRequests/SubmitPortOpeningRequestRule';
import ConfirmModal from '../common/modals/ConfirmModal';
import PortOpeningRule from '../../models/entities/PortOpeningRule';
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';
import { getVnets } from '../common/VirtualNetworksStorage';
import ToggleField from '../common/form-controls/ToggleField';
import { RuleOperation } from '../../models/enums/POR/RuleOperation';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UpdatePortOpeningRequest from '../../models/viewModels/PortOpeningRequests/UpdatePortOpeningRequest';
import { authenticatedUser } from '../../authentication/authModule';
import RulesAttestationTable from './components/RulesAttestationTable';
import ReactButton from '../common/ReactButton';

interface PortOpeningRequestsCreateProps extends PortOpeningRequestsCreateWithParamsProps {}

interface PortOpeningRequestsCreateWithParamsProps extends AbstractPORFormProps {
	draftRequest?: PortOpeningRequest;
	validNets?: VirtualNetwork[];
}

interface StateForm extends IPORStateForm {
	selectedScope: string;
}

interface ILocationState {
	selectedRules?: PortOpeningRule[]
}

interface PortOpeningRequestsCreateWithParamsState extends AbstractPORFormState<StateForm> {
	subscription: SubscriptionRequest | null;
	cancelModalVisible: boolean;
	changeScopeModalVisible: boolean;
	newScope: string;
	implementedRules: PortOpeningRule[];
	subVirtualNetworks: VirtualNetwork[];
	loading: boolean;
	subToInternetWarningVisible: boolean;
	loadingImplementedRules: boolean;
	failedImplementedRules: boolean;
	selectedFromExisting: string[];
}

class PortOpeningRequestsCreateWithParams extends AbstractPORForm
	<PortOpeningRequestsCreateWithParamsProps, PortOpeningRequestsCreateWithParamsState> {

	constructor(props) {
		super(props);
		this.state = {
			subscription: null,
			stateForm: {
				selectedScope: '',
				contact: null,
				isEmergency: false,
				customerCoordination: null,
				businessJustification: '',
				ruleList: [],
				hasDelegatedApprover: false,
				custodianApproval: false,
			},
			endModal: {
				visible: false,
			},
			submissionAlert: {
				visible: false,
			},
			submissionWarned: false,
			operationInProgress: false,
			cancelModalVisible: false,
			changeScopeModalVisible: false,
			newScope: null,
			implementedRules: [],
			subVirtualNetworks: [],
			loading: true,
			subToInternetWarningVisible: true,
			loadingImplementedRules: true,
			failedImplementedRules: false,
			selectedFromExisting: [],
		};
		this.initFormModel();
	}

	initFormModel() {
		super.initFormModel();
		this.formModel.addField('direction', {
			getValue: () => this.state.stateForm.selectedScope,
			validation: {
				required: true,
			},
		});
		this.formModel.fields.approverId.validation = new Validation({
			required: () => this.requiresApproverVerification(this.state.stateForm.selectedScope)
		});

		this.formModel.fields.portOpeningRules.getValue =
			() => this.state.stateForm.ruleList.map(rule => new SubmitPortOpeningRequestRule(rule));

		this.formModel.addField('subscriptionRequestId', {
			getValue: () => this.props.draftRequest?.subscriptionRequestId || this.state.subscription?.id,
		});

		this.formModel.addField('portOpeningRequestId', {
			getValue: () => this.props.draftRequest?.id,
		});

	}

	stateFormFromRequest(por: PortOpeningRequest): StateForm {
		let obj = {
			...super.stateFormFromRequest(por),
			selectedScope: por.direction?.name || '',
		};
		return { ...obj, ruleList: obj.ruleList.map(rule => { return { ...rule } }) }
	}

	async setImplementedRules(subId: number) {
		try {
			const subscription = await this.getSubscription(subId);
			const implementedRules = await this.getPortOpeningRules(subscription);
			let subVirtualNetworks: VirtualNetwork[] = [];
			(Array.isArray(this.props.validNets) && this.props.validNets.length > 0) ?
				subVirtualNetworks = this.props.validNets : subVirtualNetworks = await getVnets(subscription.createdSubscriptionId, subscription.tenant.name);
			this.setState({
				subscription,
				implementedRules,
				subVirtualNetworks,
				loadingImplementedRules: false,
				failedImplementedRules: false
			});
		} catch (error) {
			console.log(error)
			this.setState({
				failedImplementedRules: true
			})
			this.handleEndModal('Error while retrieving subscription info, please try again', 'error', 5000, `/subscriptions/${subId}`);
		}
	}

	async getPortOpeningRules(subscription: SubscriptionRequest) {		
		const portOpeningRules = subscription.portOpeningRequests.filter(obj => {
			return obj.workflowInstance.currentWorkflowInstanceState.workflowState.name === 'Completed';
		}).flatMap(o => o.portOpeningRules);

		const groupedRules = portOpeningRules.reduce((r, a) => {
			r[a.azureName] = [...r[a.azureName.indexOf[-1]] || [], a];
			return r;
		}, {});

		return Object.values(groupedRules).filter((data: any) => data[0].operation.name !== "Remove").map(a => a[0]);
	}

	async componentDidMount() {
		window.scroll(0, 0);
		let id = 0;
		if (!this.props.draftRequest) {
			id = parseInt(this.props.params.subsId);
		} else {
			id = this.props.draftRequest?.subscriptionRequestId;
			this.setState({ stateForm: this.stateFormFromRequest(this.props.draftRequest) });
		}
		this.setImplementedRules(id);
		if (this.props.location.state) {
			const { selectedRules }: ILocationState = this.props.location.state;
			this.handleStateFormChange('selectedScope', selectedRules[0].direction.name);
			this.handleStateFormChange('ruleList', selectedRules.map(rule => { return { ...rule, operation: RuleOperation.Modify } }));
			this.setState({
				selectedFromExisting: selectedRules.map(rule => rule.name)
			})
		}
		this.setState({
			loading: false
		});
	}

	async getSubscription(id) {
		const subscription = await super.getSubscription(id);

		if (!subscription.canSubmitPortOpeningRequests()) {
			this.handleEndModal('You are not allowed to submit a request.', 'error', 5000, '/subscriptions');
		}

		return subscription;
	}

	returningRoute = () => {
		return `/subscriptions/${this.state.subscription?.id}/PortOpeningRequestsHistory`;
	};

	handleCancel = () => {
		this.setSubmissionAlertInProgress('Cancelling...');
		this.setState({
			cancelModalVisible: false,
		}, async () => {
			try {
				await this.portOpeningRequestService.cancel(this.props.draftRequest?.id, {
					cancellationReason: 'Draft dismissed',
				});
				this.handleEndModal(
					'Request cancelled sucessfully.',
					'success',
					5000,
					this.returningRoute()
				);
			} catch (err) {
				this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
			} finally {
				this.setState({
					operationInProgress: false,
				});
			}
		});
	};

	handleSubmit = async () => {
		this.setSubmissionAlertInProgress('Submitting your request...');
		if (this.requiresApproverVerification(this.state.stateForm.selectedScope)) {
			const isVerified = await this.approverLevelVerified();
			if (!isVerified)
				return;
		}
		try {
			let model: UpdatePortOpeningRequest | SubmitPortOpeningRequest;
			let request: PortOpeningRequest;
			if(this.props.draftRequest?.isAttestation){
				model = this.formModel.create(UpdatePortOpeningRequest);
				request = await this.portOpeningRequestService.updateAttestation(this.props.draftRequest?.id, model);
			}else{
				model = this.formModel.create(SubmitPortOpeningRequest);
				request = await this.portOpeningRequestService.create(model);
			}
			this.handleEndModal(
				`Your request was submitted successfully. You will receive a notification via email once the process has finished.`,
				'success',
				5000,
				this.returningRoute()
			);
		} catch (err) {
			this.setSubmissionAlertError(err?.response?.data.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
			});
		}
	};

	handleUpdate = async () => {
		this.setSubmissionAlertInProgress('Saving changes...');
		try {
			const model = this.formModel.create(SubmitPortOpeningRequest);
			const request = await this.portOpeningRequestService.saveDraft(model);
			this.handleEndModal(
				`Request #${request.id} was saved successfully.`,
				'success',
				5000,
				this.returningRoute()
			);
		} catch (err) {
			this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
			});
		}
	};

	handleScopeChange = (event: any) => {
		if (this.state.stateForm.ruleList.length > 0) {
			this.setState({ changeScopeModalVisible: true, newScope: event.target.value });
		} else {
			this.handleStateFormChange('selectedScope', event.target.value);
			this.handleStateFormChange('approver', null);
			this.handleStateFormChange('delegatedApprover', null);
		}

	};

	confirmScopeChange = () => {
		this.handleStateFormChange('selectedScope', this.state.newScope);
		this.handleStateFormChange('ruleList', []);
		this.handleStateFormChange('approver', null);
		this.setState({
			changeScopeModalVisible: false,
			newScope: null
		});
	};

	ruleIsFromSelectedDirection(rule: PortOpeningRule): boolean {
		return rule.direction.name === this.state.stateForm.selectedScope;
	}

	ruleIsAddedAlready(rule: PortOpeningRule): boolean {
		return this.state.stateForm.ruleList
			.map(editedRule => editedRule.azureName).includes(rule.azureName);
	}

	getImplementedRulesToShow(): PortOpeningRule[] {
		return this.state.implementedRules.filter(rule =>
			this.ruleIsFromSelectedDirection(rule)
			&& !this.ruleIsAddedAlready(rule)
			&& !this.state.selectedFromExisting.includes(rule.name)
			&& !rule.isBeingAttested);
	}

	setSelectedFromExisting = (rulesName: string[]) => {
		this.setState({
			selectedFromExisting: rulesName
		})
	}

	isValid = () => {
		return (this.formModel.isValid() && (this.props.draftRequest || !this.wasNotEdited()))
	}

	isKeepOrRemove = (rule) => rule.operation == RuleOperation.Keep || rule.operation == RuleOperation.Remove;

	attestationIsValid = () => {
		return this.props.draftRequest?.isAttestation && 
		this.state.stateForm.ruleList.every(this.isKeepOrRemove)
	}	

	wasNotEdited = () => {
		return (this.state.stateForm.ruleList.some((rule) => rule.azureName != undefined));
	}

	ownerIsAttesting = () => {
		return this.state.subscription?.owner.id != authenticatedUser.userId
	}

	render() {
		return (
			<>

				<RequestFormWrapper
					title={this.props.draftRequest?.isAttestation==true ? "New Attestation Request" : "New Port Opening Request"}
					linkTree={this.getLinkTree()}
					loading={this.state.loading}
				>
					<p>
						For more information about PORs {' '}
						<TextLink className='em-u-margin-right-none' external target="_blank" href='https://appwiki.xom.cloud/docs/ConnectedAzure/PortOpeningRequests.html'>
							Click here
						</TextLink>
					</p>
					{this.state.submissionAlert.visible &&
						<Alert
							variant={this.state.submissionAlert.variant}
							onClose={this.handleSubmissionAlertClose}
						>
							{this.state.submissionAlert.text}
						</Alert>}
					<Grid variant="halves">
						<Grid.Item>
							<PORTypeInput
								scope={{
									value: this.state.stateForm.selectedScope,
									validation: this.formModel.fields.direction.validation,
									onChange: this.handleScopeChange,
									disabled: this.state.operationInProgress || this.props.draftRequest?.isAttestation,
								}}
							/>
						</Grid.Item>
						{this.state.subscription?.tenant.id === TenantLabel.ExxonMobilTest &&
							<Grid.Item>
								<EnvironmentInput
									{...this.stateFormHandler().environment}
									disabled={this.state.operationInProgress || this.props.draftRequest?.isAttestation}
								/>
							</Grid.Item>}
					</Grid>
					<Grid variant="halves">
						<Grid.Item>
							<UserLookup
								label="Technical contact"
								{...this.stateFormHandler().contact}
								disabled={this.state.operationInProgress || this.props.draftRequest?.isAttestation}
							/>
						</Grid.Item>
						{this.requiresApproverVerification(this.state.stateForm.selectedScope) &&
							<Grid.Item>
								<ApproverInput
									{...this.stateFormHandler().approver}
									disabled={this.state.operationInProgress || this.props.draftRequest?.isAttestation}
									scope={this.state.stateForm.selectedScope}
								/>
							</Grid.Item>}
					</Grid>
					{this.requiresApproverVerification(this.state.stateForm.selectedScope) &&
					<ApproverDelegationInput
						hasApproverDelegation={this.stateFormHandler().hasDelegatedApprover}
						delegatedApprover={this.stateFormHandler().delegatedApprover}
						disabled={this.state.operationInProgress || this.props.draftRequest?.isAttestation}
						isRequired={this.state.stateForm.selectedScope !== 'SubscriptionToOnPrem'}
					/>}
					
					<ToggleField
						{...this.stateFormHandler().custodianApproval}
						options={[
							{ value: 'true', text: 'Yes' },
							{ value: 'false', text: 'No' },
						]}
						disabled={this.state.operationInProgress || this.props.draftRequest?.isAttestation}
					/>
					<TextAreaField
						label="Business Justification"
						{...this.stateFormHandler().businessJustification}
						disabled={this.state.operationInProgress}
					/>
					{this.props.draftRequest?.isAttestation
						?
						<RulesAttestationTable
							{...this.stateFormHandler().ruleset}
							porDirection={new PortOpeningRequestsDirection().fromName(this.state.stateForm.selectedScope)}
							subscriptionRequestName={this.state.subscription?.createdSubscriptionName}
							subscriptionRequestId={this.state.subscription?.id}
							readonly={this.state.operationInProgress}
							validVirtualNetworks={this.state.subVirtualNetworks}
						/>
						:
						<RulesTable
							{...this.stateFormHandler().ruleset}
							currentWorkflowState={this.props.draftRequest?.workflowInstance.currentWorkflowInstanceState.workflowState.value}
							porDirection={new PortOpeningRequestsDirection().fromName(this.state.stateForm.selectedScope)}
							readonly={this.state.operationInProgress}
							subscriptionRequestName={this.state.subscription?.createdSubscriptionName}
							subscriptionRequestId={this.state.subscription?.id}
							implementedRules={this.getImplementedRulesToShow()}
							validVirtualNetworks={this.state.subVirtualNetworks}
							loadingImplementedRules={this.state.loadingImplementedRules}
							failedImplementedRules={this.state.failedImplementedRules}
							selectedFromExisting={this.state.selectedFromExisting}
							setSelectedFromExisting={this.setSelectedFromExisting}
						/>
					}
					{this.state.subscription == null && (
						<TextAreaField
							label="Additional Comments"
							{...this.stateFormHandler().comments}
							disabled={this.state.operationInProgress || this.props.draftRequest?.isAttestation}
						/>
					)}
					<Grid variant="4-up">
						<Grid.Item>
							{!!this.props.draftRequest && this.props.draftRequest?.isCancellable() &&
								<Button
									variant="secondary"
									onClick={() => {
										this.setState({
											cancelModalVisible: true,
											operationInProgress: true,
										});
									}}
									disabled={this.state.operationInProgress}
								>
									Cancel Request
								</Button>}
						</Grid.Item>
						<Grid.Item />
						<Grid.Item>
							<ReactButton
								variant="primary"
								disabled={!this.formModel.isValid() || this.props.draftRequest?.isAttestation}
								isLoading={this.state.operationInProgress}
								handleUpdateSubmit={this.handleUpdate}
								loadingVariant="secondary"
								name="Save & Close"
							/>
						</Grid.Item>
						<Grid.Item>
							<ReactButton
								variant="primary"
								disabled={!this.isValid() || (this.props.draftRequest?.isAttestation && this.ownerIsAttesting())}
								isLoading={this.state.operationInProgress}
								handleUpdateSubmit={this.handleSubmit}
								loadingVariant="secondary"
								name="Submit Request"
							/>
						</Grid.Item>
					</Grid>
				</RequestFormWrapper>
				<ConfirmModal
					visible={this.state.cancelModalVisible}
					title={`Cancel POR #${this.props.draftRequest?.id}`}
					question="Are you sure you want to cancel this request? This action cannot be undone."
					confirmButton={{
						label: 'Cancel Request',
						props: {
							variant: 'primary',
							color: 'negative'
						}
					}}
					onConfirm={this.handleCancel}
					onCancel={() => {
						this.setState({
							cancelModalVisible: false,
							operationInProgress: false
						});
					}}
				/>
				<ConfirmModal
					visible={this.state.changeScopeModalVisible}
					title="Change scope"
					question="Are you sure you want to continue? If so, the ruleset will be cleared."
					confirmButton={{
						label: 'Yes',
					}}
					onConfirm={this.confirmScopeChange}
					onCancel={() => {
						this.setState({
							changeScopeModalVisible: false
						});
					}}
				/>
				<AlertModal
					{...this.state.endModal}
					willTimeout={false}
					onClose={this.endAndRedirect}
				/>
			</>
		);
	}
}

const PortOpeningRequestsCreate = (props: PortOpeningRequestsCreateProps) => {

    return <PortOpeningRequestsCreateWithParams
		draftRequest={props.draftRequest}
		validNets={props.validNets}
		location={useLocation()}
		navigate={useNavigate()}
		params={useParams()}/>;
};

export default PortOpeningRequestsCreate;