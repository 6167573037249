import React, { useState } from 'react';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';
import { WorkflowState } from '../../../models/enums/WorkflowState';
import WorkflowStateBadge from '../../common/WorkflowStateBadge';
import './ProgressBarSmall.css';

interface ProgressBarSmallProps {
	workflowState: WorkflowState;
	porScope: string;
}

const ProgressBarSmall = ({ workflowState, porScope }: ProgressBarSmallProps) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const getFirstBulletClass = () => {
		switch (workflowState?.value) {
			case WorkflowState.Review.value:
			case WorkflowState.Draft.value:
				return 'circle-yellow';
			case WorkflowState.PendingAccountOwnerApproval.value:
				return '';
			case WorkflowState.Cancelled.value:
			case WorkflowState.Rejected.value:
				return 'circle-red';
			default:
				return 'back-blue';
		}
	};

	const getLastBulletClass = () => {
		switch (workflowState?.value) {
			case WorkflowState.AwaitingImplementation.value:
				return 'circle-yellow';
			case WorkflowState.Error.value:
			case WorkflowState.ImplementationFailed.value:
				return 'circle-red';
			case WorkflowState.Completed.value:
				return 'circle-green';
			default:
				return '';
		}
	};

	const getInternetApproverBullet = () => {
		switch (workflowState?.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.PendingForCyberApproval.value:
			case WorkflowState.PendingTechnicalApproval.value:
			case WorkflowState.Error.value:
				return 'back-blue';
			default:
				return '';
		}
	};

	const getInternetGatewayBullet = () => {
		switch (workflowState?.value) {
			case WorkflowState.PendingForDOAGApproval.value:
			case WorkflowState.PendingForCyberApproval.value:
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.Cancelled.value:
			case WorkflowState.Error.value:
				return 'back-blue';
			default:
				return '';
		}
	};

	return (
		<div
			className="progress"
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
			title=""
		>
			{showTooltip && (
				<div className="tooltip">
					<WorkflowStateBadge fullWidth workflowState={workflowState} />
				</div>
			)}
			<div className="progress-bar-line" />
			<div className={`circle ${getFirstBulletClass()} left-0`} />
			<div className={`circle ${getInternetApproverBullet()} left-33`} />
			<div className={`circle ${getInternetGatewayBullet()} left-66`} />
			<div className={`circle ${getLastBulletClass()} left-100`} />
		</div>
	);
};

export default ProgressBarSmall;
