import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MousePointerIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.97 13.45a.5.5 0 0 1-.354-.146L7.063 9.751 5.431 11.93a.506.506 0 0 1-.5.189.5.5 0 0 1-.384-.377l-1.98-8.58A.5.5 0 0 1 2.7 2.7a.491.491 0 0 1 .466-.134l8.58 1.98a.5.5 0 0 1 .188.888L9.751 7.063l3.549 3.553a.5.5 0 0 1 0 .707l-1.98 1.98a.5.5 0 0 1-.35.147zM7.01 8.49a.5.5 0 0 1 .354.146l3.606 3.606 1.273-1.273-3.606-3.606a.5.5 0 0 1 .054-.754l1.779-1.334-6.753-1.558 1.558 6.753 1.334-1.78a.5.5 0 0 1 .365-.2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M32 40a1 1 0 0 1-.707-.293L20.108 28.522 14.8 35.6a1 1 0 0 1-1.774-.375l-6-26a1 1 0 0 1 1.2-1.2l26 6A1 1 0 0 1 34.6 15.8l-7.077 5.309 11.184 11.184a1 1 0 0 1 0 1.414l-6 6A1 1 0 0 1 32 40zM20 26a1 1 0 0 1 .707.293L32 37.586 36.586 33 25.293 21.707A1 1 0 0 1 25.4 20.2l6.278-4.71-22.344-5.156 5.156 22.345L19.2 26.4a1 1 0 0 1 .729-.4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M17 21a1 1 0 0 1-.707-.293l-5.563-5.562-1.856 3.34a.987.987 0 0 1-.988.508 1 1 0 0 1-.848-.719l-4-14a1 1 0 0 1 1.236-1.236l14 4A1 1 0 0 1 18.6 8.8l-3.077 2.309 5.185 5.185a1 1 0 0 1 0 1.414l-3 3A1 1 0 0 1 17 21zm-6.5-8.5a1 1 0 0 1 .707.293L17 18.586 18.586 17l-5.293-5.293A1 1 0 0 1 13.4 10.2l2.389-1.792L5.456 5.456 8.3 15.4l1.328-2.39a1 1 0 0 1 .735-.5.946.946 0 0 1 .137-.01z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MousePointerIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MousePointerIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MousePointerIcon;
