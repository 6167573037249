import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HeartBrokenIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 16a.5.5 0 0 1-.277-.084C7.407 15.706 0 10.719 0 6.5A4.5 4.5 0 0 1 4.5 2c3.294 0 4.885 3.679 4.951 3.835a.5.5 0 0 1-.107.548l-.967.967.967.966a.5.5 0 0 1 0 .707l-.967.967.967.966a.5.5 0 0 1 0 .707L7.67 13.337l-.707-.707 1.32-1.32-.967-.966a.5.5 0 0 1 0-.707l.967-.967-.967-.97a.5.5 0 0 1 0-.707l1.069-1.065C7.966 5.13 6.646 3 4.5 3A3.5 3.5 0 0 0 1 6.5c0 2.93 4.81 6.876 7 8.394 1.328-.935 7-5.122 7-8.394a3.5 3.5 0 0 0-5.388-2.949l-.42.27-.541-.841.421-.271A4.5 4.5 0 0 1 16 6.5c0 4.219-7.407 9.206-7.723 9.416A.5.5 0 0 1 8 16z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 45a1 1 0 0 1-.5-.132 58.678 58.678 0 0 1-10.344-7.928C6.254 30.33 2.4 23.157 2.019 16.2 1.664 9.778 6.3 3.907 12.354 3.108a12.1 12.1 0 0 1 8.2 1.838l.837.547L20.3 7.167l-.838-.546a9.978 9.978 0 0 0-6.847-1.53c-4.955.654-8.895 5.689-8.6 11C4.8 30.324 20.942 40.946 24 42.833c3.059-1.888 19.194-12.506 19.984-26.74.3-5.335-3.668-10.377-8.656-11.009a10.012 10.012 0 0 0-9.963 4.866l3.345 3.342a1 1 0 0 1 0 1.415L25.415 18l3.295 3.292a1 1 0 0 1 0 1.415L25.415 26l3.295 3.292a1 1 0 0 1 0 1.415L24 35.414 22.586 34l4-4-3.295-3.292a1 1 0 0 1 0-1.415L26.588 22l-3.295-3.292a1 1 0 0 1 0-1.415L26.588 14 24 11.41a1 1 0 0 1-1-1V10a1 1 0 0 1 .54-.888A12.018 12.018 0 0 1 35.58 3.1c6.094.773 10.76 6.651 10.4 13.1-.386 6.959-4.237 14.13-11.139 20.738a58.653 58.653 0 0 1-10.341 7.93A1 1 0 0 1 24 45z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 24a1 1 0 0 1-.606-.2C10.949 23.457.5 15.421.5 9 .5 5.523 3.233 3 7 3a4.408 4.408 0 0 1 2.706 1.292l.708.708L9 6.414l-.707-.7A2.491 2.491 0 0 0 7 5C4.351 5 2.5 6.645 2.5 9c0 4.22 6.371 10.215 9.5 12.729 3.128-2.516 9.5-8.514 9.5-12.729 0-2.355-1.851-4-4.5-4a4.407 4.407 0 0 0-3.761 1.825l1.468 1.468a1 1 0 0 1-.152 1.539L12.8 11l1.752 1.168a1 1 0 0 1 0 1.664l-1.985 1.323L14.414 17 13 18.414l-2.707-2.707a1 1 0 0 1 .152-1.539L12.2 13l-1.752-1.168a1 1 0 0 1 0-1.664l1.985-1.323-1.14-1.138a1 1 0 0 1-.2-1.12A6.32 6.32 0 0 1 17 3c3.767 0 6.5 2.523 6.5 6 0 6.421-10.449 14.457-10.894 14.8A1 1 0 0 1 12 24z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

HeartBrokenIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

HeartBrokenIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default HeartBrokenIcon;
