import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MaximizeIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.018 7L13 3.016 9 3V2l4.5.018a.5.5 0 0 1 .5.5L14.018 7zM7 14l-4.5-.018a.5.5 0 0 1-.5-.5L1.982 9h1L3 12.984 7 13z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M4.146 11.147l7-7 .707.707-7 7z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 18l-.045-10.96a1 1 0 0 0-.987-.995L31 6V4l10.972.05a3 3 0 0 1 2.978 2.986L45 18zM19 44l-10.972-.05a3 3 0 0 1-2.978-2.986L5 30h2l.05 10.964a1 1 0 0 0 .987.995L19 42z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11.586 36l25.415-25.415L38.415 12 13 37.414z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19.027 10L19 4.022 13 4V2l6 .027a2.005 2.005 0 0 1 2 1.986L21.027 10zM10 21l-6-.027a2.005 2.005 0 0 1-2-1.986L1.973 13l2-.009L4 18.978 10 19z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M4.586 17L17 4.586 18.415 6 6 18.414z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MaximizeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MaximizeIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MaximizeIcon;
