import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SaveDiskIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 15h-11A1.5 1.5 0 0 1 1 13.5v-11A1.5 1.5 0 0 1 2.5 1h8.11a1.5 1.5 0 0 1 1.152.539l2.89 3.468a1.5 1.5 0 0 1 .348.961V13.5a1.5 1.5 0 0 1-1.5 1.5zM2.5 2a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V5.968a.5.5 0 0 0-.116-.32L10.995 2.18A.5.5 0 0 0 10.61 2z"
          fill="currentColor"
        />
        <path
          d="M8.5 6h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM5 5h3V2H5zM11.5 15h-7a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM5 14h6v-4H5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M6 11h4v1H6z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M41 44H7a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h25.063a2.991 2.991 0 0 1 2.3 1.08L43.3 15.8a3.007 3.007 0 0 1 .7 1.921V41a3 3 0 0 1-3 3zM7 6a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h34a1 1 0 0 0 1-1V17.724a1 1 0 0 0-.232-.641L32.832 6.36a1 1 0 0 0-.769-.36z"
          fill="currentColor"
        />
        <path
          d="M25 18H13a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1zm-11-2h10V6H14zM35 44H13a1 1 0 0 1-1-1V27a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1zm-21-2h20V28H14z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M18 34h12v2H18z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 22H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h12.532a1.994 1.994 0 0 1 1.536.719l4.469 5.362A2 2 0 0 1 22 8.362V20a2 2 0 0 1-2 2zM15.532 3H3v17h17V8.362z"
          fill="currentColor"
        />
        <path
          d="M12 9H6a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zM7 7h4V3H7zM17 22H6a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zM7 20h9v-6H7z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 16h7v2H8z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SaveDiskIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SaveDiskIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SaveDiskIcon;
