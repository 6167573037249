import ApiClient from './ApiClient';
import SubmitPortOpeningRequest from '../models/viewModels/PortOpeningRequests/SubmitPortOpeningRequest';
import PortOpeningRequest from '../models/entities/PortOpeningRequest';
import PortOpeningRule from '../models/entities/PortOpeningRule';
import UpdatePortOpeningRequest from '../models/viewModels/PortOpeningRequests/UpdatePortOpeningRequest';
import RejectionModel from '../models/interfaces/RejectionModel';
import ReviewReason from '../models/interfaces/ReviewReason';
import CancellationReason from '../models/interfaces/CancellationReason';
import { PortOpeningRequestsDirection } from '../models/enums/POR/PortOpeningRequestsDirection';
import { getToken } from '../authentication/authModule';
import PortOpeningRequestApproverVerification from '../models/viewModels/PortOpeningRequests/PortOpeningRequestApproverVerification';

export default class PortOpeningRequestService extends ApiClient {
	async getAll() {
		return this.get<PortOpeningRequest[]>('PortOpeningRequests');
	}

	async getById(Id: number) {
		return this.get<PortOpeningRequest>(`PortOpeningRequests/${Id}`);
	}

	async assignedRequests(userPrincipalName: string) {
		return this.get<PortOpeningRequest[]>(`PortOpeningRequests/${userPrincipalName}/assigned`);
	}

	async getMyRequests() {
		return this.get<PortOpeningRequest[]>('PortOpeningRequests/me');
	}
	async getMyApprovals() {
		return this.get<PortOpeningRequest[]>('PortOpeningRequests/myApprovals');
	}

	async getRequestsForAdminReview() {
		return this.get<PortOpeningRequest[]>('PortOpeningRequests/admin');
	}

	async getBySubscriptionId(id: number) {
		return this.get<PortOpeningRequest[]>(`SubscriptionRequests/${id}/portOpeningRequests`);
	}

	async approve(portOpeningRequestId: number) {
		return this.patch<PortOpeningRequest>(`PortOpeningRequests/${portOpeningRequestId}/approval`, null);
	}

	async reject(portOpeningRequestId: number, rejectionModel: RejectionModel) {
		return this.patch<null>(`PortOpeningRequests/${portOpeningRequestId}/rejection`, rejectionModel);
	}

	async sendToReview(portOpeningRequestId: number, review: ReviewReason) {
		return this.patch<null>(`PortOpeningRequests/${portOpeningRequestId}/review`, review);
	}

	async cancel(portOpeningRequestId: number, cancellationModel: CancellationReason) {
		return this.patch<PortOpeningRequest>(
			`PortOpeningRequests/${portOpeningRequestId}/cancellation`,
			cancellationModel
		);
	}

	async create(por: SubmitPortOpeningRequest) {
		return this.post<PortOpeningRequest>('PortOpeningRequests', por);
	}

	async saveDraft(por: SubmitPortOpeningRequest) {
		return this.put<PortOpeningRequest>('PortOpeningRequests/draft', por);
	}

	async update(portOpeningRequestId: number, por: UpdatePortOpeningRequest) {
		return this.patch<PortOpeningRequest>(`PortOpeningRequests/${portOpeningRequestId}`, por);
	}

	async updateAttestation(portOpeningRequestId: number, por: UpdatePortOpeningRequest) {
		return this.patch<PortOpeningRequest>(`PortOpeningRequests/${portOpeningRequestId}/attestation`, por);
	}

	async synchronize() {
		return this.put<PortOpeningRequest[]>('PortOpeningRequests/sync', null);
	}

	async getRulesByPORId(id: number, getAsArray: boolean) {
		return this.get<PortOpeningRule[]>(`PortOpeningRequests/${id}/rules?getAsArray=${getAsArray}`);
	}

	async getRulesBySubscriptionId(id: number) {
		return this.get<PortOpeningRule[]>(`SubscriptionRequests/${id}/rules?onlyApproved=true`);
	}

	async verifyApproverLevel(email: string, isEmergency: boolean) {
		return this.get<PortOpeningRequestApproverVerification>(`PortOpeningRequests/verifyApproverLevel?email=${email}&emergency=${isEmergency}`);
	}

	// eslint-disable-next-line class-methods-use-this
	async parse(scope: PortOpeningRequestsDirection, file: File, Id: number) {
		const formData = new FormData();
		formData.append(file.name, file);
		const token = await getToken();
		// TODO: integrate form-data submission on api client
		return new Promise(async (resolve, reject) => 
			fetch(`/api/v1.0/rules/parse/${scope.name}/${Id}`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json, */*',
				},
				body: formData,
			})
				.then((response) => {
					if (!response.ok) throw response;
					return response;
				})
				.then(response => resolve(response.json()))
				.catch(response => response.json())
				.then(response => reject(response))
		);
	}
}
