import * as React from 'react';
import { FieldProps as UnityFieldProps } from 'react-unity';
import { IValidation, Validation } from '../../../utils/Validation';
import Tooltip from '../Tooltip';

export interface FieldProps {
	validation?: IValidation;
	tooltip?: Tooltip;
	value: any;
}

export class Field<
	UnityProps extends UnityFieldProps,
	SSPProps extends FieldProps = any,
	SSPState = any
	> extends React.Component<SSPProps & UnityProps, SSPState> {

	isValid(): boolean {
		if (!this.props.validation) return true;
		return new Validation(this.props.validation).assert(this.props.value);
	}

	nonValidMessage() {
		if (this.isValid()) return null;
		const invalidRule = this.props.validation.rules?.find((rule) => !rule.assert(this.props.value));
		if (!invalidRule) return '';
		
		const message = invalidRule?.message;
		if (typeof message == 'string') return message;
		return message(this.props.value);
	}
}