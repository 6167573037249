import * as React from 'react';
import { Table, Toolbar, TextLink, ProgressBar, AlertVariant, Button, PlusFilledIcon, TextInput, } from 'react-unity'
import { Params, useNavigate, useParams } from 'react-router-dom';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest';				
import DoNotDecryptRequestService from '../../services/DoNotDecryptRequestService';				
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import { LinkTreeItem } from '../common/LinkTree';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import AlertModal from '../common/modals/AlertModal';
import Paginator from '../common/tables/Paginator';
import NewDNDRInfoModal from './components/NewDNDRInfoModal';
import './DoNotDecryptRequestsHome.css';
import { DoNotDecryptRequestsDirection } from '../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import CheckboxFilter, { CheckboxOption } from '../common/tables/CheckboxFilter';
import Filter from '../common/tables/Filter';
import serviceNow from '../../services/serviceNow.config';
import ProgressBarSmall from './components/ProgressBarSmall';
import moment from 'moment';

export type DNDRFilters = {
	scope: CheckboxOption[];
	status: CheckboxOption[];
	searchUser: string;
};

interface DoNotDecryptRequestsHomeProps extends DoNotDecryptRequestsHomeWithParamsProps{ }

interface DoNotDecryptRequestsHomeWithParamsProps{
	subtitle?: string;
	scopes: DoNotDecryptRequestsDirection[];
	navigate?: any;
	params?: Params<string>;
}

interface DoNotDecryptRequestsHomeWithParamsState {
	loading: boolean;
	subscription?: SubscriptionRequest;
	requests: DoNotDecryptRequest[];				
	filteredRequests: DoNotDecryptRequest[];				
	endModal: AlertBanner;
	newNewDNDRInfoModalVisible: boolean;
	
	visibleRequests: DoNotDecryptRequest[];
}

class DoNotDecryptRequestsHomeWithParams extends
	React.Component<DoNotDecryptRequestsHomeWithParamsProps, DoNotDecryptRequestsHomeWithParamsState> {
	
	doNotDecryptRequestService: DoNotDecryptRequestService;

	subscriptionRequestsService: SubscriptionRequestsService;

	filter: Filter<DNDRFilters>;
	constructor(props) {
		super(props);
		this.doNotDecryptRequestService = new DoNotDecryptRequestService();				
		this.subscriptionRequestsService = new SubscriptionRequestsService();
		this.state = {
			loading: true,
			requests: [],
			filteredRequests: [],
			visibleRequests: [],
			endModal: {
				visible: false,
			},
			newNewDNDRInfoModalVisible: false,
		};
		
		this.filter = new Filter(this.filterDNDRs, this.getDefaultFilters());
	}

	async componentDidMount() {
		this.fetchData();
	}
	
	fetchData = async () => {
		if (this.props.params.id) {
			const subscriptionId = parseInt(this.props.params.id);
			const subscription = new SubscriptionRequest(await this.subscriptionRequestsService.getById(subscriptionId));

			this.setState({
				subscription
			});
		} else {
			this.setState({
				subscription: null
			});
		}
		await this.loadRequests();
	};

	getLinkTree = (): LinkTreeItem[] => {
		return (this.state.subscription?.id && [
			{ text: 'Subscriptions', to: '/subscriptions' },
			{ text: this.state.subscription?.createdSubscriptionName, to: `/subscriptions/${this.state.subscription?.id}` },
		]) || [];
	};

	loadRequests = async () => {
		try {
			let requests = [];
			this.setState({ loading: true });
			if (this.state.subscription?.id) {
				requests = await this.doNotDecryptRequestService.getBySubscriptionId(this.state.subscription?.id);
			} else {
				requests = await this.doNotDecryptRequestService.getMyRequests();
			}

			requests = requests.map(r => new DoNotDecryptRequest(r))				
				.reverse()
				.filter(request => this.props.scopes.includes(request.direction));
		
			this.setState({
				requests,
				loading: false,
			}, this.filter.filter);

		} catch {
			this.setState({
				loading: false,
			});
			this.handleEndModal('Something went wrong, try again later.', 'error', 5000);
		}
	};
	
	handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		this.setState({
			endModal: {
				visible: true,
				text,
				variant,
			}
		},
		() => {
			setTimeout(() => {
				this.redirectToSubscriptions();
			}, timeout);
		});
	};

	redirectToSubscriptions = () => {
		this.setState({
			endModal: { visible: false }
		},
		() => {
			this.props.navigate('/subscriptions');
		});
	};

	getDefaultFilters = () => {
		const defaultFilters = {
			searchUser: '',
			status: [
				{ label: 'Submitted', value: 'Submitted', checked: true },
				{ label: 'Rejected', value: 'Rejected', checked: true },
				{ label: 'Pending for Cloud Network Approval', value: 'Pending for Cloud Network Approval', checked: true },
				{ label: 'Pending for Gateway Policy Team Approval', value: 'Pending for Gateway Policy Team Approval', checked: true },
				{ label: 'Pending Cloud Architect Approval', value: 'Pending Cloud Architect Approval', checked: true },
				{ label: 'Pending for Cyber Team Approval', value: 'Pending for Cyber Team Approval', checked: true },
				{ label: 'Review', value: 'Review', checked: true },
				{ label: 'Awaiting Implementation', value: 'Awaiting Implementation', checked: true },
				{ label: 'Completed', value: 'Completed', checked: true },
				{ label: 'Cancelled', value: 'Cancelled', checked: true },
				{ label: 'Draft', value: 'Draft', checked: true },
			],
			scope:
				[
					{ label: 'Subscription -> Internet', value: DoNotDecryptRequestsDirection.SubscriptionToInternet.name, checked: true }
				]
					
		} as DNDRFilters;

		return defaultFilters;
	};

	filterDNDRs = () => {
		let filteredRequests = [];
		
		const filterCallback = (dndr: DoNotDecryptRequest) => {				
			return this.filter.filterCheckBox('status', dndr.workflowInstance.currentWorkflowInstanceState.workflowState?.name)
			&& this.filter.filterCheckBox('scope', dndr.direction.name)
			&& (this.filter.filterSearchText('searchUser', dndr.workflowInstance.createdBy.displayName)
			|| this.filter.filterSearchText('searchUser', dndr.contactUser?.displayName));
		};

		filteredRequests = this.state.requests.filter(filterCallback);

		this.setState({
			filteredRequests
		});
		
	};

	goToNewRequest = () => {
		this.props.navigate(
			'/new'			//FIGURE THIS OUT
		);
	};

	getTicketURL = (ticket: string) => {
		return ticket.toUpperCase().includes('CRQ') ?
			`https://itconsole.na.xom.com/#/${ticket}`
			: `https://${serviceNow.serviceNowEnv}.service-now.com/nav_to.do?uri=change_request.do?sysparm_query=number=${ticket}&sysparm_limit=1`;
	};

	render() {
		const rows = this.state.visibleRequests?.map(request => (
			<Table.Body.Row key={request.id}>
				<Table.Body.Cell>
					<TextLink href={`/doNotDecryptRequests/${request.id}`}># {request.id}</TextLink>
				</Table.Body.Cell>
				<Table.Body.Cell>
					<ProgressBarSmall workflowState={request.workflowInstance.currentWorkflowInstanceState.workflowState} dndrSevenDayExemption={request.sevenDayExemption}/>
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.implementationTicket() !== '' ?
						<TextLink
							external
							href={this.getTicketURL(request.implementationTicket())}
							target="_blank"
						>
							{request.implementationTicket()}&nbsp;
						</TextLink>
						:
						'-'}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.workflowInstance.createdBy.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{request.contactUser?.displayName}
				</Table.Body.Cell>
				<Table.Body.Cell>
					{moment(new Date(request.workflowInstance.createdDate)).format('YYYY-MM-DD')}
				</Table.Body.Cell>
			</Table.Body.Row>
		));

		return (
			<>
				<SectionHomeWrapper
					title={`DPI (Deep Packet Inspection) Exception Requests${this.props.subtitle ? `: ${this.props.subtitle}`: ''}`}
					linkTree={this.getLinkTree()}
				>
					{this.state.loading ?
						<ProgressBar
							label="Loading requests..."
							className="em-u-margin-top-half"
							indeterminate
							hideValueLabel
						/> :
						<>	
							<Table
								header={
									<Toolbar>
										<Toolbar.Item>
											<CheckboxFilter
												label="Status"
												onChange={(event) => this.filter.handleCheckboxChange(event, 'status')}
												options={this.filter.filters.status}
											/>
										</Toolbar.Item>
										<Toolbar.Item>
											<TextInput
												inline
												className="em-u-margin-bottom-none"
												placeholder=" Search User"
												type="text"
												size="small"
												value={this.filter.filters.searchUser}
												onChange={e => this.filter.handleSearchTextChange(e, 'searchUser')}
											/>
										</Toolbar.Item>
										<Toolbar.Item right>
											<Button
												variant="primary"
												size="small"
												onClick={() => {
													if (
														(this.props.params.id &&
														this.state.subscription?.canSubmitDoNotDecryptRequests()) ||
													!this.props.scopes.includes(DoNotDecryptRequestsDirection.SubscriptionToInternet)
													) {
														this.goToNewRequest();
													} else {
														this.setState({
															newNewDNDRInfoModalVisible: true
														});
													}
												}}
												disabled={this.state.subscription ?
													!this.state.subscription?.canSubmitDoNotDecryptRequests()
													: false}
											>
												<PlusFilledIcon size="small" />
												<span>New Request</span>
											</Button>
										</Toolbar.Item>
									</Toolbar>
								}
								footer={
									<Paginator
										data={this.state.filteredRequests}
										onPageChange={(page) => {
											this.setState({
												visibleRequests: page
											});
										}}
									/>
								}
							>
								<Table.Head>
									<Table.Head.Row>
										<Table.Head.Cell> DNDR # </Table.Head.Cell>
										<Table.Head.Cell> Status </Table.Head.Cell>
										<Table.Head.Cell> CRQ # </Table.Head.Cell>
										<Table.Head.Cell> Requestor </Table.Head.Cell>
										<Table.Head.Cell> Contact </Table.Head.Cell>
										<Table.Head.Cell> Requested Date </Table.Head.Cell>
									</Table.Head.Row>
								</Table.Head>
								<Table.Body>
									{rows}
								</Table.Body>
							</Table>
						</>}
				</SectionHomeWrapper>
				<AlertModal
					{...this.state.endModal}
					willTimeout={false}
					onClose={this.redirectToSubscriptions}
				/>
				<NewDNDRInfoModal
					visible={this.state.newNewDNDRInfoModalVisible}
					onClose={() => this.setState({ newNewDNDRInfoModalVisible: false })}
				/>	
			</>
		);
	}
}

const DoNotDecryptRequestsHome = (props: DoNotDecryptRequestsHomeProps) => {

    return <DoNotDecryptRequestsHomeWithParams subtitle={props.subtitle} scopes={props.scopes} params={useParams()} navigate={useNavigate()}/>;
}

export default DoNotDecryptRequestsHome;
