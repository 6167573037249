import React from 'react';
import { Button, CircleExclamationMarkIcon, Table, Tag, Tooltip, WarningIcon } from 'react-unity';
import './AADAppsHomeTableRow.css';
import { useNavigate } from 'react-router-dom';
import EnterpriseApp from '../../models/entities/EnterpriseApp';
import { authenticatedUser } from '../../authentication/authModule';
import moment from 'moment';


interface AADAppsHomeTableRowProps {
	application: EnterpriseApp;
	seePermissionsReport: (app: EnterpriseApp, selectedPermissionsTab: 'App Permissions' | 'Permissions to App') => void;
	applicationRowRef: React.RefObject<HTMLBaseElement>;
	allApplications: EnterpriseApp[];
	takeOwnershipOfApplication: any;
	assignHomeRealmDiscoveryPolicy: any;
	removeHomeRealmDiscoveryPolicy: any;
	grantAdminConsent: any;
	editMultiple: boolean;
	setCheckBoxState: any;
	checkBoxState: number;
	retreiveApps: any;
	setSubmissionAlert: any;
	typeOfApps: 'Applications' | '3rd Party Applications';
	setAreMyAppsLoading: any;
}

const AADAppsHomeTableRow = ({
	application,
	seePermissionsReport,
	applicationRowRef,
	allApplications,
	takeOwnershipOfApplication,
	assignHomeRealmDiscoveryPolicy,
	removeHomeRealmDiscoveryPolicy,
	grantAdminConsent,
	editMultiple,
	setCheckBoxState,
	checkBoxState,
	retreiveApps,
	setSubmissionAlert,
	typeOfApps,
	setAreMyAppsLoading,
}: AADAppsHomeTableRowProps) => {
	const navigate = useNavigate();
	const handleViewDetails = () => {
		typeOfApps == 'Applications' ?
			navigate(`/adApps/${application.id}`) :
			navigate(`/ad3rdPartyApps/${application.id}`);
	};

	const handleTakeOwnership = async (application: EnterpriseApp) => {
		setAreMyAppsLoading(true);
		try {
			await takeOwnershipOfApplication(application.applicationId);
			setSubmissionAlert({
				visible: true,
				text: `You've been added as an owner of the app registration ${application.displayName} in Azure`,
				variant: 'success',
			})
		} catch (err) {
			setSubmissionAlert({
				visible: true,
				text: err?.body?.message ?? 'An error occured when processing your request. Please, try again later.',
				variant: 'error',
			});
		}
		setAreMyAppsLoading(false)
	};

	const handleEnableSSO = async (application: EnterpriseApp) => {
		setAreMyAppsLoading(true);
		try {
			const res = await assignHomeRealmDiscoveryPolicy(application.applicationId);
			setSubmissionAlert({
				visible: true,
				text: `Seamless Single Sign-On applied successfully to the application '${application.displayName}'! Login prompt will no longer appear for users.`,
				variant: 'success',
			});
		} catch (err) {
			setSubmissionAlert({
				visible: true,
				text: err?.body?.message ?? 'An error occured when processing your request. Please, try again later.',
				variant: 'error',
			});
		};
		retreiveApps(true);
	}

	const handleDisableSSO = async (application: EnterpriseApp) => {
		setAreMyAppsLoading(true);
		try {
			const res = await removeHomeRealmDiscoveryPolicy(application.applicationId);
			setSubmissionAlert({
				visible: true,
				text: `Seamless Single Sign-On has been removed from the application '${application.displayName}'.`,
				variant: 'success',
			});
		} catch (err) {
			setSubmissionAlert({
				visible: true,
				text: err?.body?.message ?? 'An error occured when processing your request. Please, try again later.',
				variant: 'error',
			});
		}
		retreiveApps(true);
	};

	const handleExpirationAlert = (expdate: Date): "success" | "warning" | "error" =>{
		var now = new Date;
		var dur = moment.duration({ from: now, to: expdate });

		if (dur.asDays() < 0 && expdate !=null)
			return "error";
		else if(dur.asDays() < 30 && expdate !=null)
			return "warning";
		else
			return "success";
	}

	const handleGrantAdminConsent = async (application: EnterpriseApp) => {
		setAreMyAppsLoading(true);
		retreiveApps(true);
		try {
			const res = await grantAdminConsent(application.applicationId);
			setSubmissionAlert({
				visible: true,
				text: `Admin consent has been successfully granted for the application '${application.displayName}'.`,
				variant: 'success',
			});
			retreiveApps(true);
		} catch (err) {
			setSubmissionAlert({
				visible: true,
				text: err?.body?.message ?? 'An error occured when processing your request. Please, try again later.',
				variant: 'error',
			});
		}
	};

	const onItemCheck = (e, application) => {
		application.setEditable(e.target.checked);
		e.target.checked ? setCheckBoxState(checkBoxState += 1) : setCheckBoxState(checkBoxState -= 1);
	};

	return (
		<Table.Body.Row >
			{editMultiple &&
				<Table.Body.Cell>
					<input
						disabled={application.owner.id!=authenticatedUser.userId}
						type="checkbox"
						id={application.id.toString()}
						value={application.id.toString()}
						onChange={(e) => onItemCheck(e, application)}
					/>
				</Table.Body.Cell>
			}
			<Table.Body.Cell>
				<div className='wrapper'>
					<div>
						{application.displayName}
					</div>
					{handleExpirationAlert(application.expirationDate) == 'warning' ? <div className='small-margin '>
						<Tooltip className="em-u-margin-none" variant="above">
							<Tooltip.Passage >
								<WarningIcon size='medium' className='em-c-icon em-c-warning-icon em-c-alert-icon--medium ' />
							</Tooltip.Passage>
							<Tooltip.Content >App close to expire</Tooltip.Content>
						</Tooltip>
					</div> : null}
					{handleExpirationAlert(application.expirationDate) == 'error' ? <div className='small-margin '>
						
						<Tooltip className="em-u-margin-none" variant="above">
							<Tooltip.Passage >
								<CircleExclamationMarkIcon size='medium' className='em-c-icon em-c-alert-icon--medium  em-c-error-icon' />
							</Tooltip.Passage>
							<Tooltip.Content>App expired</Tooltip.Content>
						</Tooltip>
					</div> : null}
				</div>
				<Tag.Group>
					{application.isApplicationRegistrationOwner && 
						<Tag className='small-margin' onClick={() => window.open(`https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/appId/${application.applicationId}`, '_blank')}>
							App Reg
						</Tag>}
					{application.isServicePrincipalOwner &&
						<Tag className='small-margin' onClick={() => window.open(`${typeOfApps == 'Applications' ? `https://portal.azure.com/#blade/Microsoft_AAD_IAM/ManagedAppMenuBlade/Overview/appId/${application.applicationId}/objectId/${application.servicePrincipalObjectId}` : `https://portal.azure.com/#view/Microsoft_AAD_IAM/ManagedAppMenuBlade/~/Overview/objectId/${application.servicePrincipalObjectId}/appId/${application.applicationId}`}`, '_blank')}>
							Ent App
						</Tag>
					}
					{authenticatedUser.userId === application.owner?.id ?
						<Tag
							className='small-margin'
							color='positive'
						>Owner
						</Tag>
						: !allApplications ?
							<Tag
								className='small-margin'
								color='action'
							>Custodian
							</Tag>
							: ''}
				</Tag.Group>
			</Table.Body.Cell>
			<Table.Body.Cell>
				<Button
					onClick={() => seePermissionsReport(application, 'App Permissions')}
					size='small'
				>
					View App Permissions
				</Button>
			</Table.Body.Cell>
			<Table.Body.Cell>
				{!application.isServicePrincipalOwner ?
					<Button disabled size='small' title='You must be an owner of this enterprise application to enable Grant Admin Consent.'>Not Available</Button>
					: <Button onClick={() => handleGrantAdminConsent(application)} size='small'>Grant Admin Consent</Button>}
			</Table.Body.Cell>
			<Table.Body.Cell>
				{!application.isServicePrincipalOwner ?
					<Button
						disabled
						size='small'
						title='You must be an owner of this enterprise application to enable Seamless Single Sign-On.'
					>
						Not Available
					</Button> :
					<Button
						size='small'
						title={application.isHomeRealmDiscoveryPolicyApplied ?
							'Remove the Seamless Single Sign-On policy from this application.">Disable Seamless SSO'
							: 'Assign the Seamless Single Sign-On policy to this application.">Enable Seamless SSO'}
						onClick={application.isHomeRealmDiscoveryPolicyApplied ?
							() => handleDisableSSO(application)
							: () => handleEnableSSO(application)}
					>
						{application.isHomeRealmDiscoveryPolicyApplied ?
							'Disable Seamless SSO'
							: 'Enable Seamless SSO'}
					</Button>}
			</Table.Body.Cell>
			<Table.Body.Cell>
				<Button
					size='small'
					title={application.canTakeAzOwnership() ?
						'Take AZ ownership over this application.'
						: ''}
					disabled={!application.canTakeAzOwnership()}
					onClick={() => handleTakeOwnership(application)}
				>
					Take AZ Ownership
				</Button>
			</Table.Body.Cell>
			<Table.Body.Cell>
				<Button
					variant="primary"
					size="small"
					title="View application details"
					onClick={() => handleViewDetails()}
				>
					View Details &nbsp;&nbsp;
				</Button>
			</Table.Body.Cell>
		</Table.Body.Row>
	);
};

export default AADAppsHomeTableRow;
