import { useEffect, useState } from "react";

export const BrowserPreference = Object.freeze({
  light: "light",
  dark: "dark"
});

function getBrowserDefaultTheme() {
  // Check if the browser is non-legacy, then it has the ability to check if light or dark mode is preferred
  if (window && window.matchMedia) {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // If the media query matches, then the dark theme is preferred
    return mediaQuery.matches
      ? BrowserPreference.dark
      : BrowserPreference.light;
  }

  // Legacy browsers will default to light
  return BrowserPreference.light;
}

/**
 * Gets the browsers default light or dark theme and updates if it is ever changed by the user
 * @param {boolean} disable Indicates if the browser's default light or dark theme should be used.
 * @returns {"light" | "dark" }
 */
function useBrowserPreference(disable) {
  const [preference, setPreference] = useState(
    disable ? BrowserPreference.light : getBrowserDefaultTheme()
  );

  useEffect(() => {
    let mediaQuery = null;

    // Setup an event listener that checks if the user ever changes their scheme preference
    if (!disable && window && window.matchMedia) {
      mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      mediaQuery.addListener(onBrowserThemeChange);
    }

    return () => {
      if (mediaQuery) {
        mediaQuery.removeListener(onBrowserThemeChange);
      }
    };
  }, []);

  /**
   * Checks if the user ever changes their browser's preference while the app is running
   * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
   */
  function onBrowserThemeChange(e) {
    if (e.matches) {
      setPreference(BrowserPreference.dark);
    } else {
      setPreference(BrowserPreference.light);
    }
  }

  return preference;
}

export default useBrowserPreference;
