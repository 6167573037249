import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TriangleUpFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 5.351L11.948 11H4.052L8 5.351z" />
        <path
          d="M13.867 12H2.133L8 3.6zm-7.9-2h4.058L8 7.1z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23.5 9.509L44 38.5H3L23.5 9.509z" />
        <path
          d="M46.9 40H.1L23.5 6.911zm-41-3h35.2L23.5 12.106z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12 4.888l9 12.728H3l9-12.728z" />
        <path
          d="M23.9 19.116H.1L12 2.291zm-18-3h12.2L12 7.486z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TriangleUpFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TriangleUpFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TriangleUpFilledIcon;
