import ServicePrincipalLookupObject from '../models/ServicePrincipalLookupObject';
import ApiClient from './ApiClient';

export default class ServicePrincipalService extends ApiClient {

  async servicePrincipalLookup(name:string) {
    return this.get<ServicePrincipalLookupObject[]>(`serviceprincipals/lookup?nameFilter=${name}&maxRows=10`);
	}

  async getById(id:string) {
    return this.get<ServicePrincipalLookupObject[]>(`serviceprincipals/${id}`);
	}
}
