import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ExclamationPointIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.964 11H7.036a.5.5 0 0 1-.5-.462l-.641-8.356A1.151 1.151 0 0 1 7.036 1h1.928a1.144 1.144 0 0 1 1.143 1.143l-.644 8.4a.5.5 0 0 1-.499.457zM7.5 10h1l.607-7.9a.147.147 0 0 0-.143-.1H7.036a.143.143 0 0 0-.143.143zM8 16a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M25.148 32h-2.3a3.014 3.014 0 0 1-2.991-2.77L18 5.077A3.016 3.016 0 0 1 21 2h6a3 3 0 0 1 3 3l-1.861 24.23A3.014 3.014 0 0 1 25.148 32zM21 4a1 1 0 0 0-1 1l1.855 24.077a1 1 0 0 0 1 .923h2.3a1 1 0 0 0 1-.923L28 4.923A.994.994 0 0 0 27 4zM24 47a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M13 16h-2c-1.429 0-1.53-1.344-1.563-1.786L8.5 3.083A2.017 2.017 0 0 1 10.5 1h3a2 2 0 0 1 2 2l-.933 11.16C14.53 14.656 14.429 16 13 16zm0-2zm-1.574 0h1.148L13.5 2.917 10.5 3l.926 10.993zM12 23a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ExclamationPointIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ExclamationPointIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ExclamationPointIcon;
