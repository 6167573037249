import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const EnvelopeOpenIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.17 10h-.338A3.157 3.157 0 0 1 5.9 9.337L2.8 7.509l.508-.861 3.156 1.864A2.227 2.227 0 0 0 7.832 9h.338a2.153 2.153 0 0 0 1.315-.451L12.751 6.6l.511.859-3.215 1.914A3.083 3.083 0 0 1 8.17 10z"
          fill="currentColor"
        />
        <path
          d="M13.5 15h-11A1.5 1.5 0 0 1 1 13.5V5.26a.5.5 0 0 1 .248-.432l6.529-3.8a.5.5 0 0 1 .5 0l6.471 3.8A.5.5 0 0 1 15 5.26v8.24a1.5 1.5 0 0 1-1.5 1.5zM2 5.547V13.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V5.546l-5.972-3.51z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 43H5a3.009 3.009 0 0 1-3-3.011V15.11a3.026 3.026 0 0 1 1.708-2.718l19-9.1a2.983 2.983 0 0 1 2.583 0l19 9.1A3.026 3.026 0 0 1 46 15.11v24.879A3.009 3.009 0 0 1 43 43zM24 5a.993.993 0 0 0-.43.1l-19 9.1a1.017 1.017 0 0 0-.57.91v24.879A1.007 1.007 0 0 0 5 41h38a1.007 1.007 0 0 0 1-1.011V15.11a1.017 1.017 0 0 0-.571-.914l-19-9.1A.993.993 0 0 0 24 5z"
          fill="currentColor"
        />
        <path
          d="M23.987 31.989a8.861 8.861 0 0 1-4.406-1.167L4.619 21.307l1.074-1.687 14.922 9.49a6.9 6.9 0 0 0 6.81-.024l14.882-9.466 1.074 1.688L28.459 30.8a8.993 8.993 0 0 1-4.472 1.189z"
          fill="currentColor"
        />
        <path
          d="M40 23h-2v-7H10v7H8v-7a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.258 14.5h-.512c-.774 0-3.217-1.225-7.259-3.642l-.858-.513 1.025-1.717.858.513a48.189 48.189 0 0 0 6.312 3.364l.434-.005a53.843 53.843 0 0 0 6.229-3.358l.858-.513 1.026 1.717-.857.513c-4.041 2.416-6.483 3.641-7.256 3.641z"
          fill="currentColor"
        />
        <path
          d="M22 21H2a2 2 0 0 1-2-2V7c0-1.1 1.308-1.778 1.57-1.9L11.161.8a1.977 1.977 0 0 1 1.699 0l9.549 4.286.021.009C22.692 5.222 24 5.9 24 7v12a2 2 0 0 1-2 2zM2 7.194V19h20V7.194a1.968 1.968 0 0 0-.422-.288l-9.558-4.29-9.611 4.3A1.915 1.915 0 0 0 2 7.194z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

EnvelopeOpenIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

EnvelopeOpenIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default EnvelopeOpenIcon;
