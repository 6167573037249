import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**
 * A stylized card for an icon and label card
 *
 * @visibleName Icon card
 * */
const IconCard = props => {
  const { label, className, children, ...other } = props;

  return (
    <div className={clsx("em-c-icon-card", className)} {...other}>
      <div className="em-c-icon-card__body">
        {children != null &&
          React.cloneElement(children, {
            className: clsx(
              "em-c-icon-card__icon em-u-width-100",
              children.props.className
            ),
            size: "large"
          })}
        {label && <div className="em-c-icon-card__label">{label}</div>}
      </div>
    </div>
  );
};

IconCard.propTypes = {
  /** Text to show below the icon */
  label: PropTypes.node,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

IconCard.defaultProps = {
  label: null,
  className: "",
  children: null
};

export default IconCard;
