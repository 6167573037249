import * as React from 'react';
import moment from 'moment';
import FormModel from '../../../utils/FormModel';
import { AbstractFormState, AbstractForm } from '../../common/AbstractForm';
import { Environment } from '../../../models/enums/Environment';
import CartaId from '../../../models/entities/CartaId';
import { RequestEnterpriseAppModel } from '../../../models/viewModels/EnterpriseApps/RequestEnterpriseAppModel';
import EnterpriseApp from '../../../models/entities/EnterpriseApp';
import UserLookupObject from '../../../models/UserLookupObject';
import { ExpirationAction } from '../../../models/enums/AdApps/ExpirationAction';

export interface EnterpriseAppFormProps {
	visible: boolean;
	onCreate: (enterpriseApp: RequestEnterpriseAppModel) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
	editingApplication?: EnterpriseApp;
 }

export interface IEnterpriseAppStateForm {
	displayName: string;
	expirationDate: string;
	environment: Environment;
	cartaIds: CartaId[];
	ownerInTransfer: UserLookupObject;
	applicationLifeTime: ExpirationAction;
}

/** @abstract */
export abstract class AbstractEnterpriseAppForm<
	Props extends EnterpriseAppFormProps,
	State extends AbstractFormState<IEnterpriseAppStateForm>
> extends AbstractForm<Props, State> {

	constructor(props) {
		super(props);
	}
	
	initFormModel() {
		this.formModel = new FormModel(
			{
				displayName: {
					getValue: () => this.state.stateForm?.displayName,
					validation: {
						required: true,
					},
				},
				expirationDate: {
        			getValue: () => {
        				const value = this.state.stateForm?.expirationDate?.replace('12:00:00 AM', '11:59:59 PM');
						return value ? moment(value).format('MM-YYYY-DD') : value;
					},
					validation: {
        				required: () => this.state.stateForm?.applicationLifeTime === ExpirationAction.Temporary,
        				rules: [
        					{
        						assert: () => {
        							const dateInSixMonths = new Date();
        							dateInSixMonths.setMonth(dateInSixMonths.getMonth() + 6);
        							dateInSixMonths.setHours(0, 0, 0, 0);
        							const selectedDate = new Date(this.state.stateForm?.expirationDate || null);
        							selectedDate.setHours(0, 0, 0, 0);
        							const limitDate = new Date();
									limitDate.setDate(limitDate.getDate() + 7)
        							limitDate.setHours(0, 0, 0, 0);
        							return selectedDate <= dateInSixMonths && selectedDate >= limitDate;
        						},
        						message: 'Expiration date can\'t be longer than six months and less than a week.'
        					}

        				],
        			},
				},
				environment: {
					getValue: () => this.state.stateForm.environment?.value,
					validation: {
						required: true,
					},
				},
				cartaIds: {
					getValue: () => this.state.stateForm.cartaIds,
					validation: {
						required: () => this.state.stateForm.applicationLifeTime?.value === ExpirationAction.Permanent.value,
						rules: [{
							assert: () => {
								if (this.state.stateForm.cartaIds.every(x => x.cartaAppId.length > 0 && x.cartaAppName.length > 0)) {
									return true;
								} if (this.state.stateForm.cartaIds.length === 1) {
									return (this.state.stateForm.cartaIds[0].comment.length > 0 && this.state.stateForm.cartaIds[0].cartaAppId.length == 0);
								}
								return false;
									
							},
							message: '',
						}]
					},
				},
				ownerInTransferId: {
					getValue: () => this.state.stateForm.ownerInTransfer?.id,
				},
				applicationLifeTime: {
					getValue: () => this.state.stateForm.applicationLifeTime?.value,
					validation: {
						required: true,
					},
				},
			}
		);
	}

	stateFormHandler = () => this.getStateFormHandlers();

	getStateFormHandlers() {
		return {
			displayName: {
				value: this.state.stateForm.displayName,
				validation: this.formModel.fields.displayName.validation,
				onChange: (event) => {
					this.handleStateFormChange('displayName', event?.target.value);
				}
			},
			expirationDate: {
				label: 'Expiration Date',
    			format: 'MMMM DD yyyy',
				value: this.state.stateForm.expirationDate,
	 			validation: this.formModel.fields.expirationDate.validation,
    			onChange: (event) => {
					const dt = new Date(event?.target.value)
    				this.handleStateFormChange('expirationDate', dt?.toDateString());
    			}
			},
			environment: {
				value: this.state.stateForm.environment,
				validation: this.formModel.fields.environment.validation,
				onChange: (env) => {
					this.handleStateFormChange('environment', env);
				}
			},
			cartaIds: {
				value: this.state.stateForm.cartaIds,
				validation: this.formModel.fields.cartaIds.validation,
				onChange: (cartaIds) => { this.handleStateFormChange('cartaIds', cartaIds); }
			},
			ownerInTransfer: {
				value: this.state.stateForm.ownerInTransfer?.userPrincipalName,
				validation: this.formModel.fields.ownerInTransferId.validation,
				onChange: (ownerTrans: UserLookupObject) => {
					this.handleStateFormChange('ownerInTransfer', ownerTrans);
				}
			},
			applicationLifeTime: {
				value: this.state.stateForm.applicationLifeTime,
				validation: this.formModel.fields.applicationLifeTime?.validation,
				onChange: (event) => {
					const appLifeTime = new ExpirationAction().fromValue(event.target.value);
					this.handleStateFormChange('applicationLifeTime', appLifeTime);
					if (appLifeTime === ExpirationAction.Permanent){
						this.handleStateFormChange('expirationDate', null);
					}
				}
			},
		};
	}

	getDefaultForm() {
		return {
			displayName: '',
			applicationType: '',
			expirationDate: null,
			environment: null,
			cartaIds: [],
			ownerInTransfer: null,
			applicationLifeTime: null,
			...this.getDefaultFormState()
		};
	}

	abstract getDefaultFormState();

	handleCancel = (e) => {
		this.props.onClose(e);
		this.resetState();
	};

	handleRequest = async () => {
		const model = this.formModel.create(RequestEnterpriseAppModel);
		this.props.onCreate(model);
		
		this.resetState();
	};

	resetState = () => {
    	this.setState({ stateForm: this.getDefaultForm() });
	};

	// eslint-disable-next-line class-methods-use-this
	stateFormFromRequest(eApp: EnterpriseApp) {
		return {
			displayName: eApp.displayName,
			expirationDate: eApp?.expirationDate ? moment(eApp?.expirationDate).format('MMMM DD yyyy') : null,
			environment: eApp.environment,
			cartaIds: eApp.cartaIds,
			ownerInTransfer: eApp.newOwner &&
				UserLookupObject.fromUser(eApp.newOwner),
			applicationLifeTime: eApp.expirationDate == null ? ExpirationAction.Permanent : ExpirationAction.Temporary,
		};
	}
}