import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OilDrop = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M8,16c-2,0-3.9-1.1-5-2.8c-0.1-0.2-0.2-0.4-0.3-0.5C2.3,11.8,2,10.8,2,9.9l0,0c0-1.1,0.2-2.1,0.6-3
		c0.3-0.7,1-1.7,2.1-3.1c1.4-2,2.8-3.5,2.9-3.6c0.2-0.2,0.5-0.2,0.7,0c0.1,0.1,1.5,1.6,2.9,3.6c1.1,1.4,1.8,2.4,2.1,3.1
		c0.5,1.1,0.7,2.2,0.6,3.3c-0.1,1.1-0.4,2.1-1,3C11.8,14.9,10,16,8,16z M8,1.2C7.5,1.8,6.5,3,5.6,4.3c-1,1.3-1.7,2.4-2,3
		C3.2,8.1,3,8.9,3,9.8l0,0c0,0.8,0.2,1.6,0.6,2.4c0.1,0.2,0.2,0.3,0.3,0.5C4.8,14.1,6.3,15,8,15c1.6,0,3.2-0.9,4.1-2.4
		c0.5-0.7,0.8-1.7,0.8-2.6c0.1-0.9-0.1-1.9-0.5-2.7c-0.3-0.7-1-1.7-2-3C9.5,3,8.5,1.8,8,1.2z"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M24,48c-6.3,0-12.2-3.1-15.8-8.4c-0.4-0.5-0.7-1.1-1-1.6c-1.4-2.5-2.2-5.4-2.2-8.3
		c-0.1-3.2,0.6-6.3,2.1-9.1c1-2,3.3-5.2,6.8-9.5c4.6-6,9.3-10.8,9.4-10.9c0.4-0.4,1.1-0.4,1.4,0c0,0,4.8,4.9,9.4,10.9
		c3.5,4.3,5.8,7.4,6.8,9.4l0.1,0.3c1.5,3,2.2,6.3,1.9,9.6c-0.2,3.3-1.4,6.4-3.2,9.1C36.2,44.9,30.3,48,24,48z M24,2.4
		c-1.5,1.6-5.1,5.5-8.5,10c-3.4,4.2-5.7,7.3-6.6,9.2C7.6,24,6.9,26.8,7,29.6l0,0c0.1,2.6,0.8,5.2,2,7.4c0.3,0.5,0.6,1,0.9,1.4
		C13.1,43.2,18.4,46,24,46c5.6,0,10.9-2.8,14.1-7.5c1.6-2.4,2.6-5.2,2.9-8.1c0.2-3-0.4-5.9-1.7-8.6l-0.1-0.2c-1-1.9-3.2-4.9-6.6-9.1
		C29,7.9,25.5,4,24,2.4z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M12,24c-3,0-5.8-1.6-7.5-4.3c-0.2-0.3-0.3-0.5-0.5-0.8c-0.6-1.3-1-2.7-1-4.2c0-1.6,0.3-3.2,1-4.6
		c0.5-1,1.5-2.5,3.1-4.6c2-2.9,4.1-5.1,4.2-5.2C11.4,0.1,11.7,0,12,0l0,0c0.3,0,0.6,0.1,0.7,0.3c0.1,0.1,2.2,2.4,4.2,5.3
		c1.6,2.1,2.6,3.6,3.1,4.6l0.1,0.1c0.7,1.5,1,3.2,0.9,4.8c-0.1,1.7-0.6,3.2-1.5,4.6C17.8,22.4,15,24,12,24z M12,2.5
		C11.2,3.4,9.9,5,8.7,6.8c-1.9,2.5-2.6,3.7-2.9,4.3C5.2,12.2,5,13.4,5,14.7l0,0C5,15.9,5.3,17,5.8,18c0.1,0.2,0.2,0.4,0.4,0.6
		C7.5,20.8,9.7,22,12,22c2.3,0,4.5-1.2,5.8-3.3c0.7-1.1,1.1-2.3,1.2-3.6c0.1-1.3-0.2-2.7-0.7-3.9c0,0,0-0.1,0-0.1
		c-0.3-0.6-1-1.8-2.9-4.3C14.1,5,12.8,3.4,12,2.5z"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OilDrop.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OilDrop.defaultProps = {
  size: "medium",

  className: ""
};

export default OilDrop;
