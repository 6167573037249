import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from "react-dom/client"

import "react-unity/dist/unity.css"
import './index.css'

import App from './App'
import serviceWorker from './serviceWorker'
import { loadAuthModule } from './authentication/authModule'

window.addEventListener("load", async () => {
	await loadAuthModule();

	const root = ReactDOM.createRoot(document.getElementById('root'))
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);

	serviceWorker();
});