import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** An aggregate of many tiles */
const TileList = props => {
  const { className, children, ...other } = props;

  return (
    <ul
      className={clsx(
        "em-c-tile-list",
        "em-u-margin-bottom-double",
        "em-l-grid",
        "em-l-grid--1-2-4up",
        className
      )}
      {...other}
    >
      {React.Children.toArray(children).map(child => (
        <li className="em-c-tile-list__item em-l-grid__item" key={child.key}>
          {child}
        </li>
      ))}
    </ul>
  );
};

TileList.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

TileList.defaultProps = {
  className: "",
  children: null
};

export default TileList;
