import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MediaControlPlayIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM8 1a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7z"
          fill="currentColor"
        />
        <path
          d="M5.5 12.13a.5.5 0 0 1-.5-.5V4.37a.5.5 0 0 1 .724-.447l6.6 3.3a.5.5 0 0 1 .033.876l-6.6 3.96a.5.5 0 0 1-.257.071zM6 5.179v5.567l5.062-3.036z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 48a24 24 0 1 1 24-24 24.027 24.027 0 0 1-24 24zm0-46a22 22 0 1 0 22 22A22.025 22.025 0 0 0 24 2z"
          fill="currentColor"
        />
        <path
          d="M19.018 33a3.131 3.131 0 0 1-1.588-.43A2.822 2.822 0 0 1 16 30.126V15.874a2.831 2.831 0 0 1 1.334-2.384 3.117 3.117 0 0 1 3.006-.2l13.966 6.478a2.809 2.809 0 0 1 .2 5.06L20.541 32.6a3.127 3.127 0 0 1-1.523.4zm0-18a1.119 1.119 0 0 0-.6.176.83.83 0 0 0-.41.7v14.25a.835.835 0 0 0 .442.718 1.141 1.141 0 0 0 1.126.013l13.966-7.774a.83.83 0 0 0 .458-.758.851.851 0 0 0-.536-.74L19.5 15.107a1.147 1.147 0 0 0-.486-.107z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M9 17.737a2.009 2.009 0 0 1-2-2V8.118a2 2 0 0 1 2.895-1.789l6.924 3.462a2 2 0 0 1 .134 3.5l-6.923 4.154a2 2 0 0 1-1.03.292zm0-9.619v7.621l6.923-4.16z"
          fill="currentColor"
        />
        <path
          d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MediaControlPlayIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MediaControlPlayIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MediaControlPlayIcon;
