import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TagIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 13H3.909a.5.5 0 0 1-.188-.037L.312 11.578A.5.5 0 0 1 0 11.115v-6.23a.5.5 0 0 1 .312-.463l3.409-1.385A.5.5 0 0 1 3.909 3H15.5a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5zM4.007 12H15V4H4.007L1 5.222v5.557z"
          fill="currentColor"
        />
        <path
          d="M6 10a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M44 39H12.385a3 3 0 0 1-1.112-.213l-8.387-3.355A2.987 2.987 0 0 1 1 32.646V17.354a2.987 2.987 0 0 1 1.886-2.786l8.385-3.354A3 3 0 0 1 12.385 11H44a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3zM12.385 13a1 1 0 0 0-.373.072l-8.383 3.354a1 1 0 0 0-.629.928v15.292a1 1 0 0 0 .629.929l8.385 3.354a1 1 0 0 0 .371.071H44a1 1 0 0 0 1-1V14a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M15 31a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 20H6.192a2 2 0 0 1-.74-.142l-4.2-1.679A1.989 1.989 0 0 1 0 16.323V8.677A1.988 1.988 0 0 1 1.258 6.82L5.45 5.143A2.01 2.01 0 0 1 6.192 5H22a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2zM6.192 7L2 8.678v7.646L6.193 18H22V7z"
          fill="currentColor"
        />
        <path
          d="M7.5 16a3.5 3.5 0 1 1 3.5-3.5A3.5 3.5 0 0 1 7.5 16zm0-5A1.5 1.5 0 1 0 9 12.5 1.5 1.5 0 0 0 7.5 11z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TagIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TagIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TagIcon;
