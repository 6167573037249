import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MediaControlVolumeDownIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M11 8h5v1h-5zM7 9H6V5.04a.5.5 0 0 1 .245-.43l5.945-3.53a1.071 1.071 0 0 1 1.143-.127A1.124 1.124 0 0 1 14 1.988V6h-1V1.988a.129.129 0 0 0-.08-.124.126.126 0 0 0-.146.021L7 5.324z"
        />
        <path
          d="M12.808 14.866a1.184 1.184 0 0 1-.771-.286L5.8 11H3.5A1.5 1.5 0 0 1 2 9.5v-3A1.5 1.5 0 0 1 3.5 5H5v1H3.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h2.438a.507.507 0 0 1 .25.066l6.422 3.7c.162.127.255.1.29.079a.156.156 0 0 0 .1-.145V11h1v2.7a1.156 1.156 0 0 1-.681 1.056 1.217 1.217 0 0 1-.511.11z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M32 24h14v2H32zM18 30h-2V15.449a2.957 2.957 0 0 1 1.477-2.549L35.11 2.664a3 3 0 0 1 3.162-.382A2.925 2.925 0 0 1 40 4.959V20h-2V4.959a.93.93 0 0 0-.565-.859 1.019 1.019 0 0 0-1.1.147.991.991 0 0 1-.148.105L18.48 14.634a.951.951 0 0 0-.48.815z"
        />
        <path
          d="M36.991 47a2.954 2.954 0 0 1-1.9-.693L16.722 35.245H9a3.017 3.017 0 0 1-3-3.027V18.027A3.017 3.017 0 0 1 9 15h4v2H9a1.015 1.015 0 0 0-1 1.027v14.19a1.015 1.015 0 0 0 1 1.027h8a1 1 0 0 1 .516.144L36.2 44.638a.943.943 0 0 1 .148.108.952.952 0 0 0 1.054.164 1.01 1.01 0 0 0 .6-.941V30h2v13.969A3.02 3.02 0 0 1 36.991 47z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M14 11h8v2h-8z" />
        <path
          d="M8 17H6V8.069a2.012 2.012 0 0 1 .978-1.72l8.787-5.219A2 2 0 0 1 19 2.7V6h-2V2.7a.954.954 0 0 1-.148.106L8 8.068z"
          fill="currentColor"
        />
        <path
          d="M16.993 23.8a1.976 1.976 0 0 1-1.229-.431L6.726 18H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h4v2H3v7h4a1 1 0 0 1 .511.141l9.341 5.549a.95.95 0 0 1 .148.11V18h2v3.8a1.98 1.98 0 0 1-1.173 1.821 2.023 2.023 0 0 1-.834.179z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MediaControlVolumeDownIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MediaControlVolumeDownIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MediaControlVolumeDownIcon;
