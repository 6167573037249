import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BatteryChargingIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 14H10v-1h2.5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5H9V4h3.5A1.5 1.5 0 0 1 14 5.5v7a1.5 1.5 0 0 1-1.5 1.5zM5 14H1.5A1.5 1.5 0 0 1 0 12.5v-7A1.5 1.5 0 0 1 1.5 4H4v1H1.5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5H5zM15.5 12H14v-1h1V7h-1V6h1.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path
          d="M7.5 14.94a.5.5 0 0 1-.5-.5V11H3.71a.5.5 0 0 1-.451-.715l3.79-7.94A.5.5 0 0 1 8 2.56V7h2.31a.5.5 0 0 1 .463.688l-2.81 6.94a.5.5 0 0 1-.463.312zM4.5 10h3a.5.5 0 0 1 .5.5v1.373L9.568 8H7.5a.5.5 0 0 1-.5-.5V4.768z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39 39H26v-2h13a1 1 0 0 0 1-1V14a1 1 0 0 0-1-1H24v-2h15a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3zM18 39H3a3 3 0 0 1-3-3V14a3 3 0 0 1 3-3h12v2H3a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h15zM46 33h-2v-2h2V19h-2v-2h2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <path
          d="M21 43a1 1 0 0 1-1-1V27h-9a1 1 0 0 1-.895-1.447l10-20A1 1 0 0 1 22 6v15h9a1 1 0 0 1 .895 1.447l-10 20A1 1 0 0 1 21 43zm-8.382-18H21a1 1 0 0 1 1 1v11.764L29.382 23H21a1 1 0 0 1-1-1V10.236z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19 21h-4v-2h4V8h-5V6h5a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2zM7 21H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4v2H2v11h5zM23 18h-2v-2h1v-5h-1V9h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zM14.333 12.333L11 19v-5.333H7.667L11 7v5.333h3.333z"
          fill="currentColor"
        />
        <path
          d="M10 23.236v-8.569H6.048L12 2.764v8.569h3.952zm2-9.9v1.431l.715-1.431zm-2.715-.667H10v-1.433z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BatteryChargingIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BatteryChargingIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BatteryChargingIcon;
