import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**  NavItems are the items that go within the primary nav of the header */
const HorizontalHeaderNavItem = props => {
  const { className, active, children, ...other } = props;

  return (
    <li
      className={clsx("em-c-primary-nav__item em-u-clickable", className)}
      {...other}
    >
      <div
        className={clsx("em-c-primary-nav__link", { "em-is-current": active })}
      >
        {children}
      </div>
    </li>
  );
};

HorizontalHeaderNavItem.propTypes = {
  /** active determines if the link will look highlighted */
  active: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node
};

HorizontalHeaderNavItem.defaultProps = {
  active: false,
  className: ""
};

export default HorizontalHeaderNavItem;
