import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MinimizeIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15 7l-5.5-.018a.5.5 0 0 1-.5-.5L8.982 2h1L10 5.984 15 6z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11.146 4.146l4-4 .708.707-4 4zM6.018 15L6 10.016 2 10V9l4.5.018a.5.5 0 0 1 .5.5L7.018 15z"
        />
        <path fill="currentColor" d="M.146 15.146l4-4 .708.707-4 4z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42 23l-12.971-.049a3 3 0 0 1-2.98-2.986L26 7h2l.049 12.964a1 1 0 0 0 .988.994L42 21z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M29.086 18.5L43.501 4.086 44.915 5.5 30.5 19.914zM20 43l-.045-12.96a1 1 0 0 0-.988-.994L6 29v-2l12.971.049a3 3 0 0 1 2.98 2.986L22 43z"
        />
        <path
          fill="currentColor"
          d="M2.902 44.5L17.5 29.901l1.598 1.6L4.501 46.098z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M23 11l-7-.027a2 2 0 0 1-2-1.987L13.974 2l2-.008L16 8.979 23 9z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M16.586 7L23 .586 24.414 2 18 8.414zM9.026 23L9 16.021 2 16v-2l7 .027a2 2 0 0 1 2 1.987L11.026 23z"
        />
        <path fill="currentColor" d="M.586 23L7 16.584 8.414 18 2 24.414z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MinimizeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MinimizeIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MinimizeIcon;
