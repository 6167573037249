/* eslint-disable no-useless-escape */
import * as React from 'react';
import {
	Modal,
	Button,
	Grid,
	XFilledIcon
} from 'react-unity';
import './RuleModal.css';
import TextAreaField from '../../../common/form-controls/TextareaField';
import ToggleField from '../../../common/form-controls/ToggleField';
import TextInputField from '../../../common/form-controls/TextInputField';
import { AbstractRulesForm, IRuleStateForm, RulesFormProps } from './AbstractRulesForm';
import { RuleAction } from '../../../../models/enums/DNDR/RuleAction';
import { RuleRegion } from '../../../../models/enums/DNDR/RuleRegion';
import { TrafficDirection } from '../../../../models/enums/DNDR/TrafficDirection';
import { VirtualNetwork } from '../../../../models/entities/AzureResources/VirtualNetwork';
import SelectField from '../../../common/form-controls/SelectField';
import MultipleSelectField from '../../../common/form-controls/MultipleSelectField';
import { RuleOperation } from '../../../../models/enums/DNDR/RuleOperation';
import { DoNotDecryptRequestsDirection } from '../../../../models/enums/DNDR/DoNotDecryptRequestsDirection';

interface RuleModalSubToOnPremProps extends RulesFormProps{
	subscriptionRequestName: string;
}




interface SubToOnPremRuleStateForm extends IRuleStateForm {
	name: string;
    trafficDirection: TrafficDirection;
    protocol: string;
	destinationURLs: string;
}
interface RuleModalSubToOnPremState {
	stateForm: SubToOnPremRuleStateForm;
}

export default class RuleModalSubToOnPrem extends AbstractRulesForm<
	RuleModalSubToOnPremProps,
	RuleModalSubToOnPremState
> {

	constructor(props: RuleModalSubToOnPremProps) {
		super(props);
		this.state = {
			stateForm: this.props.isFromHistory ? this.getRuleCopy() : this.getDefaultForm(),
		};

		this.initFormModel();
	}
	
	isURLValid = (url) => /^(http(s)?:\/\/)?(www\.)?[a-zA-ZA-Z0-9-]+(\.[a-zA-ZA-Z0-9-]+)+(\/[^\s]*)?$/.test(url);

	initFormModel() {
		super.initFormModel();
		this.formModel.addField('name', {
			getValue: () => this.state.stateForm.name,
			validation: {
				required: true,
				rules:
					[
						{
							assert: () => this.state.stateForm.operation !== RuleOperation.Add
								|| this.state.stateForm.name?.length <= 24,
							message: 'Name cannot be longer than 24 characters.',
						},
						{
							assert: () => !this.state.stateForm.name?.includes(' '),
							message: 'Name cannot contain spaces.',
						},
						{
							assert: () => this.state.stateForm.operation !== RuleOperation.Add
							|| /^(?![-_])[a-zA-Z][a-zA-Z-_]*(?<![-_])$/.test(this.state.stateForm.name ?? ''),
							message: 'Only letters (uppercase and lowercase), dashes, underscores are allowed. No trailing or beginning dashes or underscores.',
						}
					]
			},
		});	

		this.formModel.fields.destinationIPs.validation.required =
			() => !(this.state.stateForm.destinationURLs?.trim().length > 0);
		
		this.formModel.fields.destinationIPs.validation.rules.push(
			this.IpInputValidationRule({
				onlyPrivateAddresses: false
			})
		);

		this.formModel.addField('trafficDirection', {
			getValue: () => this.state.stateForm.trafficDirection,
			validation: {
				required: true,
			},
		});

		this.formModel.addField('protocol', {
			getValue: () => this.state.stateForm.protocol,
			validation: {
				required: true
			},
		});

		const numberOfDestinationItemsValidation = {
			assert: () => {
				//const numberOfIPs = this.state.stateForm.destinationIPs?.split(';').filter(s => s !== '').length ?? 0;
				const numberOfURLs = this.state.stateForm.destinationURLs?.split(';').filter(s => s !== '').length ?? 0;
				const numberOfDestinationItems =  numberOfURLs;
				const maxNumberOfDestinationItems = 50;
				return numberOfDestinationItems <= maxNumberOfDestinationItems;
			},
			message: "Total Destination URLs and IPs can't be greater than 50"
		}

		this.formModel.addField('destinationURLs', {
			getValue: () => this.parseMultipleValues(this.state.stateForm.destinationURLs),
			validation: {
				required: true,//() => !(this.state.stateForm.destinationIPs?.trim().length > 0),
				rules: [
					{
						assert: () => !this.hasEmptyValues(this.state.stateForm.destinationURLs),
						message: 'Empty values are not allowed.'
					},
					{
						assert: () => !(this.state.stateForm.destinationURLs.includes('https://')
						||this.state.stateForm.destinationURLs.includes('http://')),
						message: 'URL cannot have "http" or "https"'
					},
					{
						assert: (value) => {
							const urls = this.parseMultipleValues(value)?.split(';');
							return urls?.every(url => this.isURLValid(url));
						},
						message: (value) => {
							const invalidAddress = this.parseMultipleValues(value)?.split(';').find(url => !this.isURLValid(url));
							return `${invalidAddress} is an invalid URL.`;
						}
					},
					numberOfDestinationItemsValidation
				]
			}
		});

		this.formModel.fields.destinationIPs.validation.rules.push(numberOfDestinationItemsValidation);

	}

	// eslint-disable-next-line class-methods-use-this
	getDefaultFormState() {
		return {
			operation: RuleOperation.Add,
			name: '',
			destinationURLs: ''
		};
	}

	getRegionOptions(): RuleRegion[] {
		var subToOnPremRegions = new RuleRegion().listByDirection(DoNotDecryptRequestsDirection.SubscriptionToOnPrem);
		const wwSubscriptionsNames = ['APPS_SharedSvcs_WW_DEV', 'APPS_SharedSvcs_WW_LAB', 'APPS_SharedSvcs_WW_PRD'];
		return wwSubscriptionsNames.includes(this.props.subscriptionRequestName) ? subToOnPremRegions : subToOnPremRegions.filter(region => region !== RuleRegion.WW);
	}

	getSourceIPOptions() {
		const subnets = this.props.validVirtualNetworks.flatMap(vNet => vNet.properties.subnets);
		const addressSpaces = subnets.flatMap(subnet => subnet.properties.addressPrefix);
		return addressSpaces;
	}

	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window className="ruleModal">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							{this.props.isReadOnly ? 'View Rule' : !this.isModalForNewRule() ? 'Edit Rule' : 'New Rule'}
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						{ !this.isModalForNewRule() &&
							<ToggleField
								{...this.stateFormHandler().operation}
								label="Operation"
								disabled={this.props.isReadOnly}
							/>
						}
						<Grid variant="2-up">
							<Grid.Item>
								<TextInputField
									label="Rule Name"
									value={this.state.stateForm.name}
									validation={this.formModel.fields.name.validation}
									onChange={
										(event) => {
											this.handleStateFormChange('name', event.target.value);
										}
									}
									disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
								/>
							</Grid.Item>
                            <Grid.Item>
                                <SelectField
                                    label="Traffic Direction"
                                    value={this.state.stateForm.trafficDirection?.value}
                                    options={[
                                        { text: 'Inbound (XOMNetwork -> Subscription)', value: TrafficDirection.In.value },
                                        { text: 'Outbound (Subscription -> XOMNetwork)', value: TrafficDirection.Out.value },
                                    ]}
                                    className="full-width"
                                    onChange={(event) => this.handleStateFormChange('trafficDirection', new TrafficDirection().fromValue(event.target.value))}
                                    validation={this.formModel.fields.trafficDirection.validation}
                                    disabled={!this.formCanBeEdited()}
                                />
                            </Grid.Item>
						</Grid>
						{this.state.stateForm.operation?.name !== RuleOperation.Remove.name &&
							<>
								<Grid variant="2-up">
									<Grid.Item>
									</Grid.Item>
									<Grid.Item /> 
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<MultipleSelectField
											{...this.stateFormHandler().sourceIPs}
											options={this.getSourceIPOptions()}
											label="Source (IPs / IP Range / CIDR)"
											disabled={!this.formCanBeEdited()}
											note='Multiple values are allowed, separated by semicolons.'
										/>
									</Grid.Item>
{/* 									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().destinationIPs}
											label="Destination (IPs / IP range / CIDR)"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item> */}
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											label="Destination (URLs)"
											value={this.state.stateForm.destinationURLs}
											validation={this.formModel.fields.destinationURLs.validation}
											onChange={
												(event) => {
													this.handleStateFormChange('destinationURLs', event.target.value);
												}
											}
											note='Multiple values are allowed, separated by semicolons.'
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().description}
											label="Description"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<SelectField
											label="Protocol"
											value={this.state.stateForm.protocol}
											options={['Any', 'TCP', 'UDP', 'ICMP']}
											onChange={(event) => this.handleStateFormChange('protocol', event.target.value)}
											validation={this.formModel.fields.protocol.validation}
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
								</Grid>
							</>}
					</Modal.Window.Body>
					{ !this.props.isReadOnly &&
						<Modal.Window.Footer>
							<Grid variant="2-up">
								<Grid.Item>
									<Button
										variant="primary"
										disabled={!this.formModel.isValid() && this.state.stateForm.operation.name !== RuleOperation.Remove.name}						
										onClick={this.handleRequest}
									>
										{this.props.editingRule ? 'Confirm' : 'Create'}
									</Button>
								</Grid.Item>
								<Grid.Item>
									<Button
										variant="secondary"
										onClick={this.handleCancel}
									>
										Cancel
									</Button>
								</Grid.Item>
							</Grid>
						</Modal.Window.Footer>
					}

				</Modal.Window>
			</Modal>
		);
	}
}