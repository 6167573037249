import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** TextPassageIntro is a way to format an intro inside a TextPassage */
const TextPassageIntro = props => {
  const { className, children, ...other } = props;

  return (
    <p className={clsx("em-c-text-passage__intro", className)} {...other}>
      {children}
    </p>
  );
};

TextPassageIntro.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TextPassageIntro.defaultProps = {
  className: ""
};

export default TextPassageIntro;
