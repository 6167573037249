import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HeartFillIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 6.5c0 4-7.5 9-7.5 9s-7.5-5-7.5-9A4 4 0 0 1 8 4.57a4 4 0 0 1 7.5 1.93z"
          fill="currentColor"
        />
        <path
          d="M8 16a.5.5 0 0 1-.277-.084C7.407 15.706 0 10.719 0 6.5a4.5 4.5 0 0 1 8-2.826A4.5 4.5 0 0 1 16 6.5c0 4.219-7.407 9.206-7.723 9.416A.5.5 0 0 1 8 16zM4.5 3A3.5 3.5 0 0 0 1 6.5c0 2.93 4.81 6.876 7 8.394 1.328-.935 7-5.122 7-8.394a3.5 3.5 0 0 0-6.562-1.689.521.521 0 0 1-.877 0A3.49 3.49 0 0 0 4.5 3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M45.5 15.5c0 17-21 29-21 29s-21-12-21-29a11 11 0 0 1 21-4.59 11 11 0 0 1 21 4.59z"
          fill="currentColor"
        />
        <path
          d="M24.5 45a.5.5 0 0 1-.248-.066C24.039 44.813 3 32.592 3 15.5a11.5 11.5 0 0 1 21.5-5.684A11.5 11.5 0 0 1 46 15.5c0 17.092-21.039 29.313-21.252 29.434A.5.5 0 0 1 24.5 45zm-10-40A10.512 10.512 0 0 0 4 15.5c0 15.52 18.149 27 20.5 28.418C26.851 42.5 45 31.02 45 15.5a10.5 10.5 0 0 0-20.046-4.381.52.52 0 0 1-.908 0A10.532 10.532 0 0 0 14.5 5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 8c0 6.5-10 14-10 14S2 14.5 2 8a4.683 4.683 0 0 1 5-5 5.291 5.291 0 0 1 5 3 5.291 5.291 0 0 1 5-3 4.683 4.683 0 0 1 5 5z"
          fill="currentColor"
        />
        <path
          d="M12 23a1 1 0 0 1-.6-.2C10.976 22.481 1 14.92 1 8a5.68 5.68 0 0 1 6-6 6.54 6.54 0 0 1 5 2.166A6.54 6.54 0 0 1 17 2a5.68 5.68 0 0 1 6 6c0 6.92-9.976 14.481-10.4 14.8a1 1 0 0 1-.6.2zM7 4a3.675 3.675 0 0 0-4 4c0 5.046 6.9 11.022 9 12.726 2.1-1.7 9-7.68 9-12.726a3.675 3.675 0 0 0-4-4 4.292 4.292 0 0 0-4.089 2.413 1 1 0 0 1-1.822 0A4.292 4.292 0 0 0 7 4z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

HeartFillIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

HeartFillIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default HeartFillIcon;
