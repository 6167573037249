import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SettingsIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.5 10A2.5 2.5 0 1 1 10 7.5 2.5 2.5 0 0 1 7.5 10zm0-4A1.5 1.5 0 1 0 9 7.5 1.5 1.5 0 0 0 7.5 6z"
          fill="currentColor"
        />
        <path
          d="M8.5 15h-2a.5.5 0 0 1-.49-.4l-.326-1.625a.492.492 0 0 0-.3-.363.5.5 0 0 0-.47.046l-1.378.921a.5.5 0 0 1-.631-.062l-1.42-1.42a.5.5 0 0 1-.062-.631l.921-1.378a.5.5 0 0 0-.317-.768L.4 8.99A.5.5 0 0 1 0 8.5v-2a.5.5 0 0 1 .4-.49l1.626-.326a.5.5 0 0 0 .317-.768l-.919-1.378a.5.5 0 0 1 .063-.631l1.42-1.42a.5.5 0 0 1 .631-.062l1.378.92a.5.5 0 0 0 .769-.317L6.01.4A.5.5 0 0 1 6.5 0h2a.5.5 0 0 1 .49.4l.326 1.625a.5.5 0 0 0 .769.317l1.378-.921a.5.5 0 0 1 .631.063l1.42 1.42a.5.5 0 0 1 .063.631l-.921 1.378a.5.5 0 0 0 .317.768l1.627.329a.5.5 0 0 1 .4.49v2a.5.5 0 0 1-.4.49l-1.626.326a.5.5 0 0 0-.317.768l.921 1.378a.5.5 0 0 1-.062.631l-1.42 1.42a.5.5 0 0 1-.631.063l-1.378-.92a.5.5 0 0 0-.769.317L8.99 14.6a.5.5 0 0 1-.49.4zm-1.591-1h1.182l.245-1.224a1.5 1.5 0 0 1 2.3-.952l1.037.692.84-.84-.693-1.037a1.5 1.5 0 0 1 .952-2.3L14 8.09V6.91l-1.225-.246a1.5 1.5 0 0 1-.952-2.3l.693-1.037-.84-.84-1.037.693a1.5 1.5 0 0 1-2.3-.953L8.091 1H6.909l-.245 1.224a1.5 1.5 0 0 1-2.3.952l-1.041-.693-.84.84.694 1.037a1.5 1.5 0 0 1-.952 2.3L1 6.91v1.18l1.225.246a1.5 1.5 0 0 1 .952 2.3l-.693 1.037.84.84 1.037-.693a1.5 1.5 0 0 1 2.3.953zM14.5 8.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M25.3 46h-2.6a3 3 0 0 1-2.966-2.51l-.508-3.037a2.233 2.233 0 0 0-3.474-1.441L13.239 40.8a3.014 3.014 0 0 1-3.866-.309l-1.86-1.86a3.018 3.018 0 0 1-.3-3.874l1.778-2.506a2.233 2.233 0 0 0-1.422-3.47l-3.052-.51A3 3 0 0 1 2 25.3v-2.6a3 3 0 0 1 2.511-2.965l3.057-.511A2.142 2.142 0 0 0 9.2 17.93a2.212 2.212 0 0 0-.21-2.177L7.2 13.238a3.014 3.014 0 0 1 .309-3.865l1.86-1.86a3.017 3.017 0 0 1 3.874-.3L15.772 9a2.142 2.142 0 0 0 2.079.23 2.213 2.213 0 0 0 1.375-1.686l.448-2.678A3.117 3.117 0 0 1 22.7 2h2.6a3 3 0 0 1 2.971 2.515l.507 3.032a2.233 2.233 0 0 0 3.474 1.441L34.761 7.2a3.014 3.014 0 0 1 3.866.308l1.86 1.86a3.016 3.016 0 0 1 .3 3.874l-1.777 2.507a2.232 2.232 0 0 0 1.441 3.473l3.036.508A3 3 0 0 1 46 22.7v2.6a3 3 0 0 1-2.511 2.966l-3.056.511A2.143 2.143 0 0 0 38.8 30.07a2.212 2.212 0 0 0 .21 2.177l1.783 2.515a3.016 3.016 0 0 1-.308 3.866l-1.86 1.86a3.018 3.018 0 0 1-3.874.3l-2.507-1.778a2.232 2.232 0 0 0-3.473 1.442l-.447 2.674A3.121 3.121 0 0 1 25.3 46zm-8.277-9.4a4.262 4.262 0 0 1 4.177 3.523l.508 3.037a1 1 0 0 0 .992.84h2.6c.773 0 1-.841 1.058-1.2l.447-2.675a4.232 4.232 0 0 1 6.6-2.743l2.516 1.785a1 1 0 0 0 1.294-.092l1.86-1.86a1 1 0 0 0 .1-1.286L37.381 33.4a4.232 4.232 0 0 1 2.719-6.6l3.056-.511A1 1 0 0 0 44 25.3v-2.6a1 1 0 0 0-.841-.993l-3.036-.507a4.231 4.231 0 0 1-2.742-6.6l1.783-2.515a1 1 0 0 0-.091-1.294l-1.86-1.86a1 1 0 0 0-1.286-.1L33.4 10.62a4.233 4.233 0 0 1-6.6-2.742l-.507-3.033A1.009 1.009 0 0 0 25.3 4h-2.6c-.77 0-.992.838-1.053 1.2L21.2 7.877a4.233 4.233 0 0 1-6.584 2.756l-2.534-1.8a1 1 0 0 0-1.294.091l-1.86 1.86a1 1 0 0 0-.1 1.286l1.79 2.523A4.232 4.232 0 0 1 7.9 21.2l-3.057.511A1 1 0 0 0 4 22.7v2.6a1.009 1.009 0 0 0 .845 1l3.055.5a4.133 4.133 0 0 1 3.144 2.5 4.2 4.2 0 0 1-.425 4.105l-1.784 2.513a1.006 1.006 0 0 0 .092 1.294l1.86 1.86a1 1 0 0 0 1.286.1L14.6 37.38a4.184 4.184 0 0 1 2.419-.78z"
          fill="currentColor"
        />
        <path
          d="M24 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zm0-14a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.147 23h-1.3a2 2 0 0 1-1.976-1.672L8.618 19.8a.582.582 0 0 0-.354-.44.6.6 0 0 0-.589.049l-1.267.9a2.009 2.009 0 0 1-2.575-.209l-.93-.93a2.011 2.011 0 0 1-.2-2.584l.893-1.258a.582.582 0 0 0 .058-.566l-.011-.026a.586.586 0 0 0-.438-.351l-1.526-.255A2 2 0 0 1 0 12.148v-1.3a2 2 0 0 1 1.673-1.974L3.2 8.618a.58.58 0 0 0 .44-.354.6.6 0 0 0-.049-.588l-.9-1.267A2.009 2.009 0 0 1 2.9 3.832l.933-.932a2.013 2.013 0 0 1 2.584-.2l1.259.894a.579.579 0 0 0 .565.058.593.593 0 0 0 .377-.452l.226-1.35A2.061 2.061 0 0 1 10.85 0h1.3a2 2 0 0 1 1.979 1.675l.253 1.525a.581.581 0 0 0 .354.441.594.594 0 0 0 .589-.049l1.266-.9a2.01 2.01 0 0 1 2.576.208l.93.93a2.013 2.013 0 0 1 .2 2.584l-.893 1.258a.582.582 0 0 0-.058.566l.011.026a.586.586 0 0 0 .438.351l1.528.256A2 2 0 0 1 23 10.85v1.3a2 2 0 0 1-1.673 1.976l-1.528.256a.58.58 0 0 0-.44.354.6.6 0 0 0 .049.588l.9 1.267a2.01 2.01 0 0 1-.209 2.576l-.93.93a2.013 2.013 0 0 1-2.584.2l-1.258-.893a.58.58 0 0 0-.566-.058.593.593 0 0 0-.377.449l-.226 1.349A2.063 2.063 0 0 1 12.147 23zM8 17.3a2.606 2.606 0 0 1 1.012.206 2.578 2.578 0 0 1 1.574 1.964l.261 1.53h1.288a1.7 1.7 0 0 0 .049-.184l.226-1.35a2.617 2.617 0 0 1 4.072-1.691l1.267.9.935-.921-.906-1.271A2.569 2.569 0 0 1 17.5 14l.018-.044a2.565 2.565 0 0 1 1.952-1.547l1.53-.256v-1.3l-1.531-.258a2.569 2.569 0 0 1-1.956-1.557L17.5 9a2.568 2.568 0 0 1 .275-2.486l.9-1.266-.922-.935-1.271.907a2.616 2.616 0 0 1-4.073-1.69l-.256-1.525L10.863 2c0 .013-.028.068-.047.182l-.226 1.352a2.617 2.617 0 0 1-4.071 1.691l-1.268-.9-.934.922.906 1.271A2.569 2.569 0 0 1 5.5 9l-.018.044a2.565 2.565 0 0 1-1.952 1.547L2 10.847v1.3l1.531.261a2.569 2.569 0 0 1 1.956 1.557L5.5 14a2.568 2.568 0 0 1-.275 2.486l-.9 1.266.922.935 1.271-.907A2.562 2.562 0 0 1 8 17.3zm10.423-2.929z"
          fill="currentColor"
        />
        <path
          d="M11.5 16a4.5 4.5 0 1 1 4.5-4.5 4.5 4.5 0 0 1-4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5A2.5 2.5 0 0 0 11.5 9z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SettingsIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SettingsIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SettingsIcon;
