import React, { useState } from 'react';
import { Table, DownloadCloudIcon, ProgressBar, Loader } from 'react-unity';
import './NetworkSecurityGroupHomeTableRow.css';
import NetworkSecurityGroup from '../../models/entities/NetworkSecurityGroup';
import NetworkSecurityGroupService from '../../services/NetworkSecurityGroupService';
import { saveAs } from 'file-saver';

interface NetworkSecurityGroupHomeTableRowProps {
    nsg?: NetworkSecurityGroup;
    setAlertBanner: any;
}


const NetworkSecurityGroupHomeTableRow = ({ nsg, setAlertBanner }: NetworkSecurityGroupHomeTableRowProps) => {

    const networkSecurityGroupService = new NetworkSecurityGroupService();
    const [ fileDownloaded, setFileDownloaded ] = useState<boolean>(false);
    const [ downloading, setDownloading ] = useState<boolean>(false);

    const handleDownload = async () => {
        setDownloading(true);
        networkSecurityGroupService.downloadNSGBlob(nsg?.downloadUrl)
        .then((response) => {
            const blob = new Blob([response], {
              type: 'application/octet-stream'
            });
            const filename = `${nsg?.zipName}`;
            saveAs(blob, filename);
            setFileDownloaded(true);
            setDownloading(false);
          })
          .catch((e) => {
            setAlertBanner({ 
                visible: true,
                text: `An error occurred. ${e.message}`}
            )
          })
    }

    return (
        <tr>
            <Table.Body.Cell>
                <span className='em-u-text-align-center'>
                    {nsg?.blobDate}
                </span>
            </Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>
                <span className='table__cell'>
                    {nsg?.blobHour}
                </span>
            </Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>
                <span className='table__cell'>
                    {nsg?.blobSize}
                </span>
            </Table.Body.Cell>
            <Table.Body.Cell style={{ display: 'flex', justifyContent: 'space-evenly' }}> 
                {!downloading ? <DownloadCloudIcon style={{ cursor: 'pointer' }} size='small' onClick={() => handleDownload()}/> : <Loader className="icon-loader em-c-icon--small"/>}
                <ProgressBar value={fileDownloaded ? 100 : 0}/>
            </Table.Body.Cell>
        </tr>
    );
};

export default NetworkSecurityGroupHomeTableRow;