import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Renders an avatar on the tag, given an image in the src */
const TagAvatar = props => {
  const { className, alt, ...other } = props;

  return (
    <img className={clsx("em-c-avatar", className)} alt={alt} {...other} />
  );
};

TagAvatar.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** specifies an alternate text for the avatar, if it cannot be displayed */
  alt: PropTypes.string
};

TagAvatar.defaultProps = {
  className: "",
  alt: "avatar"
};

export default TagAvatar;
