import React, { useEffect, useState } from 'react';
import { Table, TextLink, ProgressBar, AlertVariant } from 'react-unity';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import AlertModal from '../common/modals/AlertModal';
import Paginator from '../common/tables/Paginator';
import './AWSPortOpeningRequestsHome.css';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import { CheckboxOption } from '../common/tables/CheckboxFilter';
import { Params, useNavigate, useParams } from 'react-router-dom';
import AWSPortOpeningRequest from '../../models/entities/AWSPortOpeningRequests';
import AWSPortOpeningRequestsService from '../../services/AWSPortOpeningRequestsService';
import NewAWSPORInfoModal from './components/NewAWSPORInfoModal';
import AWSPortOpeningRequestsHomeTableRow from './AWSPortOpeningRequestsHomeTableRow';
import AWSPortOpeningRequestsHomeFilters from './AWSPortOpeningRequestsHomeFilters';

export type PORFilters = {
	scope: CheckboxOption[];
	status: CheckboxOption[];
	searchUser: string;
	subscriptionId: number;
	orderBy: string;
};

interface AWSPortOpeningRequestsHomeProps {
	scopes?: PortOpeningRequestsDirection[];
	subtitle?: string;
	navigate?: any;
	params?: Params<string>;
}

const AWSPortOpeningRequestsHome = (props: AWSPortOpeningRequestsHomeProps) => {

	const awsPortOpeningRequestService = new AWSPortOpeningRequestsService();
	const [loading, setLoading] = useState<boolean>();
	const [pors, setPors] = useState<AWSPortOpeningRequest[]>([]);
	const [filteredRequests, setFilteredRequests] = useState<AWSPortOpeningRequest[]>([]);
	const [visibleRequests, setVisibleRequests] = useState<AWSPortOpeningRequest[]>([]);
	const [endModal, setEndModal] = useState<AlertBanner>({ visible: false });
	const [newAWSPORInfoModalVisible, setNewAWSPORInfoModal] = useState<boolean>(false);
	const navigate = useNavigate();
	const { params } = useParams();

	const handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		setEndModal({
			visible: true,
			text,
			variant,
		});
		setTimeout(() => {
			redirectToHome();
		}, timeout);
	};

	const redirectToHome = () => {
		setEndModal({ visible: false });
		navigate('/');
	};

	const getFilterProps = () => {
		return {
			setFilteredRequests,
			pors,
			filteredRequests,
		};
	};

	const fetchData = async () => {
		try {
			setLoading(true);
			console.log("aca")
			let requests = await awsPortOpeningRequestService.getMyRequests();
			requests = requests?.map((r) => new AWSPortOpeningRequest(r));

			setPors(requests);
		} catch (err) {
			handleEndModal(`Something went wrong, try again later.${err.message}`, 'error', 5000);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<SectionHomeWrapper
				title={`AWS Port Opening Requests${props.subtitle ? `: ${props.subtitle}` : ''}`}
			>
				<p>
					For more information about PORs {' '}
					<TextLink className='em-u-margin-right-none' external target="_blank" href=''>
						Click here
					</TextLink>
				</p>
				{loading ?
					<ProgressBar
						label="Loading PORs..."
						className="em-u-margin-top-half"
						indeterminate
						hideValueLabel /> :
					<>
						<AWSPortOpeningRequestsHomeFilters {...getFilterProps()} />
						<Table
							footer={<Paginator
								data={filteredRequests}
								onPageChange={(page) => setVisibleRequests(page)} />}
						>
							<Table.Head>
								<Table.Head.Row>
									<Table.Head.Cell> POR # </Table.Head.Cell>
									<Table.Head.Cell> Scope </Table.Head.Cell>
									<Table.Head.Cell> Status </Table.Head.Cell>
									<Table.Head.Cell> Requestor </Table.Head.Cell>
									<Table.Head.Cell> Contact </Table.Head.Cell>
									<Table.Head.Cell> Doag Approver </Table.Head.Cell>
									<Table.Head.Cell> Requested Date </Table.Head.Cell>
									<Table.Head.Cell> Actions </Table.Head.Cell>
								</Table.Head.Row>
							</Table.Head>
							<Table.Body>
								{visibleRequests.map((request) => (
									<AWSPortOpeningRequestsHomeTableRow key={request.id} request={request} />
								))}
							</Table.Body>
						</Table>
					</>}
			</SectionHomeWrapper>
			<AlertModal
				{...endModal}
				willTimeout={false}
				onClose={redirectToHome} />
			<NewAWSPORInfoModal
				visible={newAWSPORInfoModalVisible}
				onClose={() => setNewAWSPORInfoModal(false)} />
		</>
	);
}

export default AWSPortOpeningRequestsHome;