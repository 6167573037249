import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    AlertVariant,
    Alert,
} from 'react-unity';
import EnterpriseApp from '../../models/entities/EnterpriseApp';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import Paginator from '../common/tables/Paginator';
import '../common/wrappers/SectionHomeWrapper.css';
import ApprovalsEntraIDAppsTableRow from './ApprovalsEntraIDAppsTableRow';

interface AdAppsHomeProps {
    filteredEnterpriseApps: EnterpriseApp[];
    setAlert: any;
    setRefresh: any;
    refresh: boolean;
}

interface WarningsApp {
    id: number;
    visible: Boolean;
}

const memoized = {
    myApps: void 0,
    my3rdApps: void 0,
    all3rdApps: void 0,
}

const ApprovalsEntraIDAppsTable = ({
    filteredEnterpriseApps,
    setAlert,
    setRefresh,
    refresh,
}: AdAppsHomeProps) => {
    const [applicationsOnPage, setApplicationsOnPage] = useState<EnterpriseApp[]>([]);
    return (
        <Table
            footer={
                <Paginator
                    data={filteredEnterpriseApps}
                    onPageChange={(apps) => {
                        setApplicationsOnPage(apps);
                    }}
                />
            }
        >
            <Table.Head>
                <Table.Head.Row>
                    <Table.Head.Cell> App Name</Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'> Old Owner </Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'> New Owner </Table.Head.Cell>
                    <Table.Head.Cell className='em-u-text-align-center'> Actions </Table.Head.Cell>
                </Table.Head.Row>
            </Table.Head>
            <Table.Body>
                {applicationsOnPage.map((app) => (
                        <ApprovalsEntraIDAppsTableRow
                        key={app.id}
                        enterpriseApp={app} 
                        setAlert={setAlert}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        />
                ))}
            </Table.Body>
        </Table>
    );
}

export default ApprovalsEntraIDAppsTable; 