export default class Group {
  id!: string;

	// eslint-disable-next-line react/static-property-placement
	displayName!: string;

	description!: string;

	mailEnabled!: boolean;

	mailNickName!: string;

	securityEnabled!: boolean;

	groupTypes!: string[];

	owners!: string[];

	members!: string[];

	membershipRule: string;

	createdDateTime: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}
