import CartaId from './CartaId';
import WorkflowInstance from '../WorkflowInstance';
import { Environment } from '../enums/Environment';
import User from '../User';
import Warning from '../Warning';
import { WorkflowState } from '../enums/WorkflowState';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../enums/UserRole';


export default class EnterpriseApp {
	static filterSearchText(arg0: string, arg1: any, arg2: string): unknown {
		throw new Error('Method not implemented.');
	}

	id: number;

	applicationId: string;

	displayName: string;

	environment: Environment;

	expirationDate: Date;

	workflowInstance: WorkflowInstance;

	cartaIds: CartaId[];

	newOwner: User;

	owner: User;

	warnings: Warning[];

	greyBookOwner: User;

	azureState!: string;

	isApplicationRegistrationOwner: boolean;

	isServicePrincipalOwner: boolean;

	isHomeRealmDiscoveryPolicyApplied: boolean;

	servicePrincipalObjectId: string;

	filter: string;

	selected!: boolean;

	constructor(obj: any) {
		if (obj != ''){
			Object.assign(this, obj);
			this.workflowInstance = new WorkflowInstance(obj.workflowInstance);
			this.cartaIds = obj.cartaIds.map(c => new CartaId(c));
		}
	}

	canTakeAzOwnership(): boolean {
		return authenticatedUser.userId === this.owner?.id
			&& (!this.isApplicationRegistrationOwner || !this.isServicePrincipalOwner);
	}

	setEditable(select: boolean) {
		this.selected = select;
	}

	isEditable(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState;
		return (currentState.value === WorkflowState.Enabled.value
			|| currentState.value === WorkflowState.Disabled.value)
			&& (authenticatedUser.isInRole(UserRole.SuperAdmin)
			|| authenticatedUser.userId === this.owner.id);
	}

	isInTransfer(): boolean {
		return this.newOwner != null;
	}

	isTransferable(): boolean {
		return authenticatedUser.userId === this.owner.id;
	}

	isExpired(): boolean {
		var currentDate = new Date();
		return (new Date(this.expirationDate) < currentDate) && this.expirationDate != null;
	}
	filterSearchText = (filter: string, param?: string) => {
		return (this.filter === '' ||
			param?.toLowerCase().includes(filter.toLowerCase().trim()));
	}
}
