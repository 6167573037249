import ApiClient from './ApiClient';
import Organization from '../models/entities/Organization';
import OrganizationTuple from '../models/entities/OrganizationTuple';

export default class OrganizationsService extends ApiClient {
	async getAll() {
		return this.get<Organization[]>('Organizations');
	}

	async getAllL3Organizations() {
		return this.get<OrganizationTuple[]>(`Organizations/OrgAdmin/me`);
	}

	async getByDisplayName(l3Name: string) {
		return this.get<Organization>(`Organizations/getByDisplayName/${l3Name}`);
	}

	async updateOrganization(model: Organization, edit: boolean) {
        return this.patch<Organization>(`Organizations/edit=${edit}`, model);
    }

	async deleteOrganization(model: Organization) {
        return this.delete<Organization>(`Organizations`, model);
    }
}
