import { Enumeration } from '../../../utils/AppEnum';

export class RuleAction extends Enumeration<RuleAction> {

    public static Deny = new RuleAction('Deny', 0);

    public static Allow = new RuleAction('Allow', 1);

    public static Block = new RuleAction('Block', 2);

}
