import * as React from 'react';
import { TextPassage, BulletedList, TextLink } from 'react-unity';

export class PolicyGeneralInformation extends
	React.Component<{}, {}> {

        render() {
            return (
                <TextPassage>
                    The strategy of the MA&P team is to enhance the security of applications and data hosted on our cloud platforms and monitor compliance at the subscription level.
                    To that end, we follow a ‘pre-approved resource type´ framework where we only introduce resources that have been previously control assessed and approved.
                    This allows our organization to identify which guardrails are necessary to ensure risk mitigation on the available cloud resources and services.
                    <br/><br/>
                    These controls are implemented through the use of Azure Policies that are <strong>enforced</strong> following the <TextLink target="_blank" className="em-u-margin-right-none" external href="https://goto/severity">IT Risk Management (ITRM) Risk Rating</TextLink>.
                    This classification divides risks into four (4) categories: <strong>Critical, High, Medium</strong> and <strong>Low</strong> risks and replaces the Enterprise Risk classification.
                    <br/><br/>
                    Please find more detail on <TextLink target="_blank" className="em-u-margin-right-none" external href="https://appwiki.xom.cloud/docs/AzurePolicies/PolicyExceptionProcess.html">cloud security policy exception process</TextLink>.
                    <br/><br/>
                </TextPassage>
            );
        }
}