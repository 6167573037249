import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretDownIcon, CaretRightIcon } from "../Icons";
import { Button } from "../index";

/**
 * Toggles associated content on click
 *
 * @visibleName Expandable button
 * */
const ExpandableButton = props => {
  const {
    className,
    showText,
    hideText,
    buttonProps,
    children,
    ...other
  } = props;
  const { className: buttonClassName, ...restButtonProps } = buttonProps;
  const [open, setOpen] = useState(props.open || false);

  return (
    <div
      className={clsx(
        "em-c-expandable-button",
        { "em-is-closed": !open },
        className
      )}
      {...other}
    >
      <Button
        className={clsx("em-c-btn--is-expandable", buttonClassName)}
        variant="primary"
        size="small"
        onClick={() => setOpen(prev => !prev)}
        data-show-hide-label={hideText}
        {...restButtonProps}
      >
        <span className="em-c-btn__text em-js-btn-text">
          {open ? hideText : showText}
        </span>
        {open ? (
          <CaretDownIcon
            size="small"
            className="em-c-btn__icon em-c-btn__icon-only"
          />
        ) : (
          <CaretRightIcon
            size="small"
            className="em-c-btn__icon em-c-btn__icon-only"
          />
        )}
      </Button>

      <div
        className={clsx("em-js-show-hide-target", { "em-u-is-hidden": !open })}
      >
        {children}
      </div>
    </div>
  );
};

ExpandableButton.propTypes = {
  /** Iniital open state of the button */
  open: PropTypes.bool,
  /** Text to show on button when content is hidden. */
  showText: PropTypes.node,
  /** Text to show on button when content is shown. */
  hideText: PropTypes.node,
  /** Props to send to the Button component */
  buttonProps: PropTypes.object,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

ExpandableButton.defaultProps = {
  open: false,
  showText: "",
  hideText: "",
  buttonProps: {},
  className: "",
  children: null
};

export default ExpandableButton;
