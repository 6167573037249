import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import InsetBlockBody from "./InsetBlockBody";

function getVariant(variant) {
  switch (variant) {
    case "bare":
      return "em-c-inset-block--bare";
    case "tinted":
      return "em-c-inset-block--tinted";
    default:
      return "";
  }
}

/**
 * A strong, image-driven call to action
 *
 * @visibleName Inset block
 * */
const InsetBlock = props => {
  const { backgroundImage, variant, className, children, ...other } = props;

  return (
    <div
      className={clsx("em-c-inset-block", getVariant(variant), className)}
      {...other}
    >
      <img
        className="em-c-inset-block__image em-js-carousel-image"
        src={backgroundImage}
      />
      {children}
    </div>
  );
};

InsetBlock.Body = InsetBlockBody;

InsetBlock.propTypes = {
  /** Background image used for the hero block */
  backgroundImage: PropTypes.string.isRequired,
  /** Remove the default gradient overlay or replace it with a solid, uniform tint. */
  variant: PropTypes.oneOf(["default", "bare", "tinted"]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

InsetBlock.defaultProps = {
  backgroundImage: "",
  variant: "default",
  className: "",
  children: null
};

export default InsetBlock;
