import WorkflowInstance from '../WorkflowInstance';
import { ApprovalType } from '../enums/ApprovalType';
import { WorkflowState } from '../enums/WorkflowState';
import PolicyExemptionDefinition from './PolicyExemptionDefinition';
import PolicyExemptionAssignment from './PolicyExemptionAssignment';
import serviceNow from '../../services/serviceNow.config';

export default class PolicyExemptionRequest {
    id: number;

    displayName: string;

    azureName: string;

    assignmentId: string;

    assignmentDisplayName: string;

    workflowInstance: WorkflowInstance;

    durationRequested: string;

    approvalType: ApprovalType;

    riskAssessmentLink: string;

    businessJustification: string;

    resource: string;

    resourceGroup: string;

    policyExemptionDefinitions: PolicyExemptionDefinition[];

    policyExemptionAssignments: PolicyExemptionAssignment[];

    constructor(obj: any) {
    	Object.assign(this, obj);
    	this.workflowInstance = new WorkflowInstance(obj.workflowInstance);
    	this.approvalType = new ApprovalType().fromValue(obj.approvalType?.value);
    }

    implementationTicket(): string {
    	const implementationState = this.workflowInstance.workflowInstanceStates
    		.reverse()
    		.find(state => state.workflowState.value === WorkflowState.Submitted.value);
    	return implementationState?.ticketId || '';
    }

    getTicketURL(): string {
    	const ticketId = this.implementationTicket();
    	return ticketId.includes('WO') ?
    		`https://itconsole.na.xom.com/#/${ticketId}`
    		: `https://${serviceNow.serviceNowEnv}.service-now.com/nav_to.do?uri=sc_req_item.do?sysparm_query=number=${ticketId}&sysparm_limit=1`;
    }
}
