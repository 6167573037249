import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CircleXIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M5.13 11.163l6.033-6.034.708.707-6.034 6.034z"
        />
        <path
          fill="currentColor"
          d="M5.13 5.836l.706-.707 6.034 6.034-.707.707z"
        />
        <path
          d="M8.5 16A7.5 7.5 0 0 1 3.2 3.2 7.5 7.5 0 1 1 8.5 16zM3.9 3.9a6.5 6.5 0 1 0 9.193 0 6.508 6.508 0 0 0-9.193 0z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M14.586 32L32 14.586l1.414 1.415L16 33.414z"
        />
        <path
          fill="currentColor"
          d="M14.586 16L16 14.586l17.414 17.413L32 33.414z"
        />
        <path
          d="M24 46.989A22.989 22.989 0 0 1 7.736 7.737 23 23 0 1 1 24 46.989zM9.15 9.151a21 21 0 1 0 29.7 0 21.025 21.025 0 0 0-29.7 0z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M6.585 16L16 6.586l1.414 1.415L8 17.414z"
        />
        <path fill="currentColor" d="M6.585 8L8 6.586l9.414 9.413L16 17.414z" />
        <path
          d="M12 23.994A11.994 11.994 0 0 1 3.515 3.515 12 12 0 1 1 12 23.994zM4.929 4.929a10 10 0 1 0 14.143 0 10.011 10.011 0 0 0-14.143 0z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CircleXIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CircleXIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CircleXIcon;
