import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MediaControlPreviousIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 11.406a.5.5 0 0 1-.283-.088L5.991 8.412a.5.5 0 0 1 0-.824l4.226-2.906a.5.5 0 0 1 .783.412v5.813a.5.5 0 0 1-.5.5zM7.157 8L10 9.956V6.044z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M5 5h1v6H5z" />
        <path
          d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM8 1a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M32 32a1.98 1.98 0 0 1-1.137-.359l-10.004-6.988a2.02 2.02 0 0 1 0-3.307l10.007-6.986a1.978 1.978 0 0 1 2.055-.133A2.007 2.007 0 0 1 34 16.014v13.972a2.007 2.007 0 0 1-1.079 1.787A1.986 1.986 0 0 1 32 32zm0-15.991l-10 6.977L32.011 30zM14 14h2v20h-2z"
          fill="currentColor"
        />
        <path
          d="M24 48a24 24 0 1 1 24-24 24.027 24.027 0 0 1-24 24zm0-46a22 22 0 1 0 22 22A22.025 22.025 0 0 0 24 2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16.5 16.953a1.5 1.5 0 0 1-.851-.265l-5.02-3.452a1.5 1.5 0 0 1 0-2.473l5.021-3.451A1.5 1.5 0 0 1 18 8.547v6.905a1.5 1.5 0 0 1-1.5 1.5zm.284-1.913zm0 0zM12.362 12L16 14.5v-5zM7 7h2v10H7z"
          fill="currentColor"
        />
        <path
          d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MediaControlPreviousIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MediaControlPreviousIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MediaControlPreviousIcon;
