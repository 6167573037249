import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CreditCardIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 13h-10A1.5 1.5 0 0 1 2 11.5v-6A1.5 1.5 0 0 1 3.5 4h10A1.5 1.5 0 0 1 15 5.5v6a1.5 1.5 0 0 1-1.5 1.5zm-10-8a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M2 6h13v2H2zM10 9h3v1h-3z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42 39H6a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h36a3 3 0 0 1 3 3v24a3 3 0 0 1-3 3zM6 11a1 1 0 0 0-1 1v24a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V12a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M3 15h41v4H3zM33 25h8v2h-8zM30 29H8a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zM9 27h20v-2H9z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 20H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zM2 6v12h20V6z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M1 8h22v2H1zM4 12h5v2H4z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CreditCardIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CreditCardIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CreditCardIcon;
