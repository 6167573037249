import React, { useState } from 'react';
import { Button, Table } from 'react-unity';
import PortOpeningRule from '../../models/entities/PortOpeningRule';
import { PORColumn } from './components/RuleColumnsLayout';
import styled from 'styled-components';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import RuleModalSubToInternet from './components/porRule/RuleModalSubToInternet';
import RuleModalSubToOnPrem from './components/porRule/RuleModalSubToOnPrem';
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';
import PORTypeBadge from './components/PORTypeBadge';

interface PortOpeningRequestsByRuleRowProps {
	rule: PortOpeningRule;
	indexOnList: number;
	columns: PORColumn[];
	ruleIndex: number;
	selectedRules: PortOpeningRule[];
	onChangeSelectedRules: any;
	subscriptionRequestName: string;
	validVnets: VirtualNetwork[];
	subscriptionRequestId: number
}

const StyledButton = styled(Button)`
	width: 120px;
	height: 34px;
`;

const StyledCheckbox = styled.input`
	margin: 0 auto !important;
`;

const PortOpeningRequestsByRuleRow = ({
	rule,
	ruleIndex,
	columns,
	selectedRules,
	onChangeSelectedRules,
	subscriptionRequestName,
	validVnets,
	subscriptionRequestId
}: PortOpeningRequestsByRuleRowProps) => {
	const defaultLimit = 10;
	const [ruleModalVisible, setRuleModalVisible] = useState(false);

	const handleViewDetails = (e) => {
		e.stopPropagation();
		setRuleModalVisible(true)
	};

	const getRulesModalProps = () => {
		return {
			visible: ruleModalVisible,
			onConfirm: () => {},
			onClose: () => setRuleModalVisible(false),
			editingRule: rule,
			validVirtualNetworks: validVnets,
			isReadOnly: true,
			isFromHistory: true
		};
	}

	return (
		<>
			<Table.Body.Row style={{ cursor: 'pointer' }} key={rule.id} onClick={() => onChangeSelectedRules(rule)}>
				<Table.Body.Cell>
					<StyledCheckbox
						type="checkbox"
						value={rule.id}
						checked={selectedRules.some((selectedRule) => selectedRule.id === rule.id)}
					></StyledCheckbox>
				</Table.Body.Cell>
				{columns?.map((column, colIndex) => (
					<Table.Body.Cell
						key={`${ruleIndex}-${colIndex}`}
						className="em-u-margin-right-auto"
						title={column.cell(rule) || '-'}
					>
						{column.header === 'Type' ? (<PORTypeBadge >{column.cell(rule)}</PORTypeBadge>)
							: (column.cell(rule)?.length > (column.limit || defaultLimit)
								? `${column
									.cell(rule)
									.trim()
									.substring(0, column.limit || defaultLimit)}...`
								: column.cell(rule) || '-')}
					</Table.Body.Cell>
				))}
				<Table.Body.Cell>
					<StyledButton onClick={handleViewDetails} variant="primary">
						See Details
					</StyledButton>
				</Table.Body.Cell>
			</Table.Body.Row>
			{rule.direction.value === PortOpeningRequestsDirection.SubscriptionToInternet.value && (
				<RuleModalSubToInternet
					{...getRulesModalProps()}
					subscriptionRequestName={subscriptionRequestName}
					subscriptionRequestId = {subscriptionRequestId}

				/>
			)}
			{rule.direction.value === PortOpeningRequestsDirection.SubscriptionToOnPrem.value && (
				<RuleModalSubToOnPrem
				{...getRulesModalProps()}
				subscriptionRequestName={subscriptionRequestName}
				subscriptionRequestId={subscriptionRequestId}
				/>
			)}
		</>
	);
};

export default PortOpeningRequestsByRuleRow;
