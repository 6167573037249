import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SuitcaseIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 14h-11A1.5 1.5 0 0 1 1 12.5v-8A1.5 1.5 0 0 1 2.5 3h11A1.5 1.5 0 0 1 15 4.5v8a1.5 1.5 0 0 1-1.5 1.5zM2.5 4a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M11 4h-1V2H6v2H5V1.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5zM10 5h1v7h-1zM5 5h1v7H5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M45 45H3a3 3 0 0 1-3-3V14a3 3 0 0 1 3-3h42a3 3 0 0 1 3 3v28a3 3 0 0 1-3 3zM3 13a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V14a1 1 0 0 0-1-1zM36 10h-2V6a1 1 0 0 0-1-1H15a1 1 0 0 0-1 1v4h-2V6a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M34 15h2v26h-2zM12 15h2v26h-2z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 23H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2zM2 7v14h20V7zM19 4h-2V2.5a3.887 3.887 0 0 0-.027-.5H7.027A3.887 3.887 0 0 0 7 2.5V4H5V2.5C5 .771 5.617 0 7 0h10c1.383 0 2 .771 2 2.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M17 8h2v12h-2zM5 8h2v12H5z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SuitcaseIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SuitcaseIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SuitcaseIcon;
