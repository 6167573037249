export default class Organization {
	id!: number;

	parentOrganizationId!: number;

	parentOrganizationDisplayName!: string;

	displayName!: string;

	shortName!: string;

	status: string;
	
	costCenter!: string;

	public L3: Organization;

	public L4: Organization;

	public L5: Organization;

	constructor(obj: any) {
		Object.assign(this, obj);
	}

	findParent(organizations: Organization[]): Organization {
		return organizations.find(org => (org.id === this.parentOrganizationId));
	}

	setLevels(organizations: Organization[]): void {
		const parent = this.findParent(organizations);
		const me = organizations.find(org => org.id === this.id);
		if (me == null) {
			this.L5 = null;
			this.L4 = null;
			this.L3 = null;
			return;
		}
		if (parent?.parentOrganizationId == null) {
			this.L5 = null;
			this.L4 = me;
			this.L3 = parent;
		} else {
			this.L5 = me;
			this.L4 = parent;
			this.L3 = parent.findParent(organizations);
		}
	}

}
