export default class SubmitPolicyExemptionRequest {

    displayName: string;

    assignmentId: string;

    durationRequested: string;

    riskAssessmentLink: string;

    businessJustification: string;

    resource: string;

    resourceGroup: string;

    subscriptionRequestId: number;

    policyDefinitionsIds: string[];

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}