import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronUpFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M12.2 11.014L8 6.814l-4.2 4.2L2.386 9.6 8 3.986 13.614 9.6 12.2 11.014z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M38 33.121l-14-14-14 14L7.879 31 24 14.879 40.121 31 38 33.121z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M19 16.121l-7-7-7 7L2.879 14 12 4.879 21.121 14 19 16.121z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronUpFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronUpFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronUpFilledIcon;
