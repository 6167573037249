import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowVerticalCapsIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 2.5h1v11H8z" />
        <path
          d="M11.139 3.718L8.5 1.251 5.861 3.718l-.684-.73L7.916.428A.876.876 0 0 1 9.071.417l2.751 2.571zM8.5 15.719a.857.857 0 0 1-.566-.212l-2.756-2.571.684-.73L8.5 14.673l2.639-2.467.684.73L9.084 15.5a.893.893 0 0 1-.584.219z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23 7h2v35h-2z" />
        <path
          d="M32 11.328l-7.293-7.292a1 1 0 0 0-1.414 0L16 11.328l-1.414-1.414 7.293-7.292a3 3 0 0 1 4.242 0l7.293 7.292zM24 47.084a2.99 2.99 0 0 1-2.121-.877l-7.293-7.293L16 37.5l7.293 7.293a1 1 0 0 0 1.414 0L32 37.5l1.414 1.414-7.293 7.293a2.99 2.99 0 0 1-2.121.877z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16 6.414l-4-4-4 4L6.586 5l4-4a2 2 0 0 1 2.828 0l4 4zM12 23.585A1.993 1.993 0 0 1 10.586 23l-4-4L8 17.586l4 4 4-4L17.414 19l-4 4a1.993 1.993 0 0 1-1.414.585z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 5h2v14h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowVerticalCapsIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowVerticalCapsIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowVerticalCapsIcon;
