import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PencilIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.4 5.893l-.707-.707.707-.707-2.83-2.829-.707.707-.707-.707 1.06-1.06a.5.5 0 0 1 .707 0l3.536 3.535a.5.5 0 0 1 0 .707zM2.663 13.886a.5.5 0 0 1-.5-.545l.354-3.889a.5.5 0 0 1 .145-.309L9.1 2.711a.5.5 0 0 1 .707 0l3.535 3.535a.5.5 0 0 1 0 .707L6.9 13.386a.5.5 0 0 1-.308.145l-3.889.354zm3.889-.854zM3.5 9.723l-.283 3.11 3.111-.282L12.277 6.6 9.449 3.771z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M4.498 10.136l4.95-4.95.707.707-4.95 4.95z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42.471 15.843l-1.414-1.414 2.828-2.829-5.657-5.657L35.4 8.771l-1.415-1.414 3.536-3.535a1 1 0 0 1 1.414 0l7.071 7.071a1 1 0 0 1 0 1.414zM5.236 45.592a1 1 0 0 1-.949-1.316l3.536-10.607a1 1 0 0 1 .242-.391l23.8-23.8a1.029 1.029 0 0 1 1.414 0l7.071 7.071a1 1 0 0 1 0 1.414l-23.8 23.8a1 1 0 0 1-.39.237L5.553 45.54a.985.985 0 0 1-.317.052zm4.41-11.066l-2.829 8.485 8.483-2.828 22.928-22.925-5.657-5.658zm6.2 6.531z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11.358 35.643l21.214-21.214 1.414 1.414-21.214 21.214z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21 8.414L19.586 7l1-1L18 3.414l-1 1L15.586 3l1.707-1.707a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414zM1.868 23.132a1 1 0 0 1-.977-1.216l1.132-5.132a1 1 0 0 1 .27-.491l12-12a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-.492.27l-5.132 1.131a1.006 1.006 0 0 1-.215.024zM7 21zm-3.086-3.5l-.731 3.317 3.317-.731L17.586 9 15 6.414z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M4.585 18L15 7.586 16.414 9 6 19.415z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PencilIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PencilIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PencilIcon;
