import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FPSO = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M16,9.4C15.9,9.2,15.7,9,15.5,9H13V6.7L15.7,4L15,3.3l-2.9,2.9C12.1,6.2,12,6.4,12,6.5V9h-1l-0.1-0.8l0,0l0,0L10,0.4
		C10,0.2,9.8,0,9.5,0h-2C7.2,0,7,0.2,7,0.4L6,8.8L5.4,8.1C5.3,8.1,5.1,8,5,8V5.5C5,5.2,4.8,5,4.5,5h-3C1.2,5,1,5.2,1,5.5V8H0.5
		C0.2,8,0,8.2,0,8.5c0,0.3,0,3.2,1.1,4.4l0.4,0.4l0.7-0.7l-0.4-0.4C1.3,11.6,1.1,10.1,1,9h3.8l0.9,0.9C5.7,9.9,5.9,10,6,10h8
		c-0.5,0.6-1,1.4-1,2.5V13h1v-0.5c0-1.7,1.7-2.5,1.7-2.6C15.9,9.8,16,9.6,16,9.4z M2,6h2v2H2V6z M10,9H7l0.5-4.4l2.4,3.9L10,9z
		 M9.1,1l0.6,5.1L7.7,3l0.2-2H9.1z"
          />{" "}
          <path
            fill="currentColor"
            d="M13.9,14.7C13.5,14.4,13,14,12.2,14c-0.8,0-1.2,0.4-1.5,0.7c-0.2,0.2-0.4,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.6-0.3
		C9.2,14.4,8.7,14,7.9,14c-0.8,0-1.2,0.4-1.5,0.7C6.2,14.9,6.1,15,5.8,15c-0.1,0-0.3-0.2-0.6-0.3C4.9,14.4,4.3,14,3.6,14
		c-0.8,0-1.2,0.4-1.5,0.7C1.9,14.9,1.7,15,1.5,15H1v1h0.5c0.7,0,1-0.4,1.3-0.6C3,15.2,3.2,15,3.6,15c0.4,0,0.8,0.2,1.1,0.5
		C5,15.7,5.4,16,5.8,16c0.7,0,1-0.4,1.3-0.6C7.4,15.2,7.5,15,7.9,15c0.4,0,0.8,0.2,1.1,0.5c0.3,0.2,0.7,0.5,1.2,0.5
		c0.7,0,1-0.4,1.3-0.6c0.2-0.2,0.4-0.4,0.8-0.4c0.4,0,0.8,0.2,1.1,0.5c0.3,0.2,0.7,0.5,1.2,0.5H15v-1h-0.5
		C14.4,15,14.2,14.8,13.9,14.7z"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M44.8,44.4C44.1,43.8,43,43,41.4,43c-1.5,0-2.4,0.8-3,1.3C38,44.8,37.7,45,37.2,45c-0.3,0-0.7-0.3-1.2-0.6
		c-0.8-0.6-1.9-1.4-3.4-1.4c-1.5,0-2.4,0.8-3,1.3c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6c-0.8-0.6-1.9-1.4-3.4-1.4
		c-1.5,0-2.4,0.8-3,1.3c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6C17.7,43.8,16.6,43,15,43c-1.5,0-2.4,0.8-3,1.3
		c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6C8.9,43.8,7.8,43,6.2,43c-1.5,0-2.4,0.8-3,1.3C2.8,44.8,2.5,45,2,45H1v2h1
		c1.3,0,2.1-0.7,2.6-1.2C5.1,45.3,5.5,45,6.2,45c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2
		c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8
		c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1
		c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1h1v-2h-1C45.7,45,45.3,44.7,44.8,44.4z"
          />
          <path
            fill="currentColor"
            d="M47.9,29.7C47.8,29.3,47.4,29,47,29h-6v-5.4l6.8-9c0.2-0.3,0.3-0.7,0.1-1C47.7,13.2,47.4,13,47,13h-3
		c-0.2,0-0.5,0.1-0.7,0.3l-9,8.1c-0.2,0.2-0.3,0.5-0.3,0.7V29h-2L29,4.1c-0.1-0.5-0.5-0.9-1-0.9h-2V1h-2v2.2h-2
		c-0.5,0-0.9,0.4-1,0.9L18,29h-0.7l-2.7-1.8C14.4,27.1,14.2,27,14,27v-7c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1v7H1c-0.6,0-1,0.4-1,1
		c0,0.3,0,8.4,3.2,12.6l0.6,0.8l1.6-1.2l-0.6-0.8C2.6,36.5,2.1,31.2,2,29h11.7l2.7,1.8c0.2,0.1,0.4,0.2,0.6,0.2h27.6
		C43,32.8,41,36,41,40v1h2v-1c0-5.4,4.6-9.2,4.6-9.2C48,30.5,48.1,30.1,47.9,29.7z M4,27v-6h8v6H4z M44.4,15H45l-5.8,7.6
		C39.1,22.8,39,23,39,23.2v1.9l-2.1-3.4L44.4,15z M36,24l3,4.9V29h-3V24z M22,12.8l1.9,3.5l-3,5.5L22,12.8z M29.1,21.8l-3-5.5
		l1.9-3.5L29.1,21.8z M29.8,27.2C29.8,27.2,29.8,27.2,29.8,27.2L30,29h-4v-8.8L29.8,27.2z M27.1,5.2l0.5,4.1c0,0,0,0,0,0.1L26,12.3
		V5.2H27.1z M24,5.2v7.1l-1.6-2.9c0,0,0,0,0-0.1l0.5-4.1H24z M20.2,27.2C20.2,27.2,20.2,27.2,20.2,27.2l3.8-7V29h-4L20.2,27.2z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M21.5,21.7c-0.5-0.3-1.2-0.7-2.2-0.7c-1,0-1.6,0.5-1.9,0.8C17.2,22,17.2,22,17,22c-0.1,0-0.3-0.2-0.5-0.3   c-0.5-0.3-1.2-0.7-2.2-0.7c-1,0-1.6,0.5-1.9,0.8C12.2,22,12.2,22,12,22c-0.1,0-0.3-0.2-0.5-0.3c-0.5-0.3-1.2-0.7-2.2-0.7   c-1,0-1.6,0.5-1.9,0.8C7.2,22,7.2,22,7,22c-0.1,0-0.3-0.2-0.5-0.3C6.1,21.4,5.4,21,4.4,21c-1,0-1.6,0.5-1.9,0.8C2.2,22,2.2,22,2,22   H1v2h1c0.9,0,1.4-0.4,1.7-0.7C4,23.1,4.1,23,4.4,23c0.4,0,0.7,0.2,1.1,0.4C5.9,23.7,6.4,24,7,24c0.9,0,1.4-0.4,1.7-0.7   C9,23.1,9.1,23,9.4,23c0.4,0,0.7,0.2,1.1,0.4c0.4,0.3,0.9,0.6,1.5,0.6c0.9,0,1.4-0.4,1.7-0.7c0.3-0.2,0.4-0.3,0.7-0.3   c0.4,0,0.7,0.2,1.1,0.4c0.4,0.3,0.9,0.6,1.5,0.6c0.9,0,1.4-0.4,1.7-0.7c0.3-0.2,0.4-0.3,0.7-0.3c0.4,0,0.7,0.2,1.1,0.4   c0.4,0.3,0.9,0.6,1.5,0.6h1l0-2h-1C21.9,22,21.7,21.8,21.5,21.7z"
          ></path>
          <path
            fill="currentColor"
            d="M23.9,13.6C23.8,13.2,23.4,13,23,13h-4V9.3l4.4-3.1l-1.1-1.6L17.4,8C17.2,8.2,17,8.5,17,8.8V13h-1L15,0.9   C15,0.4,14.5,0,14,0h-3c-0.5,0-1,0.4-1,0.9L9.7,5l0,0l0,0L9,13H8.8L8,12.5V8c0-0.6-0.4-1-1-1H2C1.4,7,1,7.4,1,8v4c-0.6,0-1,0.4-1,1   c0,0.2,0,4.4,1.7,6.6l0.6,0.8l1.6-1.2l-0.6-0.8C2.5,17.3,2.2,15.3,2,14h4.6l1.2,0.8C8.1,14.9,8.3,15,8.5,15h12.3   c-0.9,1.1-1.8,2.7-1.8,4v1h2v-1c0-1.1,1.6-3.2,2.7-4.3C24,14.4,24.1,14,23.9,13.6z M3,9h3v3H3V9z M13.1,2l0.5,6l-1.9-3.4L11.9,2   H13.1z M11.4,8.2l2.6,4.8l0,0.1h-3L11.4,8.2z"
          ></path>
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FPSO.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FPSO.defaultProps = {
  size: "medium",

  className: ""
};

export default FPSO;
