import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**
 * This is a figure with a caption
 *
 * @visibleName Image with caption
 * */
const ImageWithCaption = props => {
  const { src, alt, caption, className, children, ...other } = props;

  return (
    <figure className={clsx("em-c-figure", className)} {...other}>
      <div className="em-c-figure__media">
        <img src={src} alt={alt} />
      </div>

      <figcaption className="em-c-figure__caption">{caption}</figcaption>
    </figure>
  );
};

ImageWithCaption.propTypes = {
  /** src is the uri for the image */
  src: PropTypes.string,
  /** caption is the string that will be displayed beneath the picture */
  caption: PropTypes.string,
  /** alt is the alt text for the image */
  alt: PropTypes.string,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

ImageWithCaption.defaultProps = {
  src: "https://unity.exxonmobil.com/images/fpo_16x9.png",
  caption: "This is the caption for the image",
  alt: "",
  className: ""
};

export default ImageWithCaption;
