import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BellFullIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path d="M6.093 13.5a2.091 2.091 0 0 0 3.815 0z" fill="currentColor" />
        <path
          d="M8 15.234a2.6 2.6 0 0 1-2.363-1.529L5.319 13h5.361l-.317.706A2.6 2.6 0 0 1 8 15.234zM7.163 14a1.661 1.661 0 0 0 1.674 0zM13.5 11.5h-11V9.713a3 3 0 0 1 .944-2.185l.111-.1A3 3 0 0 0 4.5 5.239V4.5a3 3 0 0 1 3-3h1a3 3 0 0 1 3 3v.72a3 3 0 0 0 .924 2.166l.151.145A3 3 0 0 1 13.5 9.7z"
          fill="currentColor"
        />
        <path
          d="M13.5 12h-11a.5.5 0 0 1-.5-.5V9.713a3.513 3.513 0 0 1 1.1-2.549l.112-.1A2.513 2.513 0 0 0 4 5.238V4.5A3.5 3.5 0 0 1 7.5 1h1A3.5 3.5 0 0 1 12 4.5v.72a2.515 2.515 0 0 0 .771 1.806l.151.145A3.517 3.517 0 0 1 14 9.7v1.8a.5.5 0 0 1-.5.5zM3 11h10V9.7a2.514 2.514 0 0 0-.771-1.805l-.151-.145A3.517 3.517 0 0 1 11 5.22V4.5A2.5 2.5 0 0 0 8.5 2h-1A2.5 2.5 0 0 0 5 4.5v.738a3.515 3.515 0 0 1-1.1 2.55l-.112.105A2.511 2.511 0 0 0 3 9.713z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39 36H11a2 2 0 0 1-2-2v-4.657a6 6 0 0 1 1.889-4.37l2.852-2.683A4 4 0 0 0 15 19.377V15A10 10 0 0 1 25 5a10 10 0 0 1 10 10v4.294a4 4 0 0 0 1.233 2.888l2.918 2.8A6 6 0 0 1 41 29.31V34a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <path
          d="M39 37H11a3 3 0 0 1-3-3v-4.657a7.028 7.028 0 0 1 2.2-5.1l2.853-2.683A3.01 3.01 0 0 0 14 19.376V15a11 11 0 0 1 22 0v4.293a3.013 3.013 0 0 0 .925 2.166l2.918 2.8A7.034 7.034 0 0 1 42 29.311V34a3 3 0 0 1-3 3zM25 6a9.01 9.01 0 0 0-9 9v4.376a5.02 5.02 0 0 1-1.573 3.642L11.574 25.7A5.018 5.018 0 0 0 10 29.343V34a1 1 0 0 0 1 1h28a1 1 0 0 0 1-1v-4.689a5.026 5.026 0 0 0-1.541-3.611l-2.918-2.8A5.024 5.024 0 0 1 34 19.293V15a9.01 9.01 0 0 0-9-9zM19 39a6.5 6.5 0 0 0 12 0z"
          fill="currentColor"
        />
        <path
          d="M25 44a7.463 7.463 0 0 1-6.924-4.618A1 1 0 0 1 19 38h12a1 1 0 0 1 .924 1.382A7.463 7.463 0 0 1 25 44zm-4.249-4a5.514 5.514 0 0 0 8.5 0z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path d="M9.11 21a3.168 3.168 0 0 0 5.78 0z" fill="currentColor" />
        <path
          d="M12 23.87a4.179 4.179 0 0 1-3.8-2.459L7.563 20h8.873l-.636 1.411A4.179 4.179 0 0 1 12 23.87zM18.933 18H5.067A1.05 1.05 0 0 1 4 16.968v-2.4a3.051 3.051 0 0 1 1.007-2.256l1.186-1.082A3.051 3.051 0 0 0 7.2 8.974V6.645A4.724 4.724 0 0 1 12 2a4.724 4.724 0 0 1 4.8 4.645v2.292a3.049 3.049 0 0 0 .986 2.236l1.228 1.138A3.049 3.049 0 0 1 20 14.547v2.42A1.05 1.05 0 0 1 18.933 18z"
          fill="currentColor"
        />
        <path
          d="M18.934 19H5.066A2.052 2.052 0 0 1 3 16.968v-2.4a4.062 4.062 0 0 1 1.335-3l1.184-1.078A2.057 2.057 0 0 0 6.2 8.974V6.645A5.73 5.73 0 0 1 12 1a5.73 5.73 0 0 1 5.8 5.645v2.292a2.062 2.062 0 0 0 .666 1.5l1.228 1.139A4.066 4.066 0 0 1 21 14.547v2.42A2.052 2.052 0 0 1 18.934 19zM12 3a3.729 3.729 0 0 0-3.8 3.645v2.329a4.059 4.059 0 0 1-1.335 3l-1.183 1.074A2.058 2.058 0 0 0 5 14.564v2.4L18.934 17a.072.072 0 0 0 .066-.031v-2.422a2.062 2.062 0 0 0-.666-1.5l-1.228-1.139A4.066 4.066 0 0 1 15.8 8.937V6.645A3.729 3.729 0 0 0 12 3z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BellFullIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BellFullIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BellFullIcon;
