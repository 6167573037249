import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PipelineValue = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <polygon
            fill="currentColor"
            points="8,4 9,4 9,1 12,1 12,0 5,0 5,1 8,1 	"
          />
          <path
            fill="currentColor"
            d="M15,8v1h-4V6h1V5h-1.5H6.4H5v1h0.9v3H2V8H1v8h1v-1h13v1h1V8H15z M15,14H2v-4h4.9V9.2V9V6H10v3v0.2V10h5V14z
		"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <polygon
            fill="currentColor"
            points="23,11 25,11 25,4 34,4 34,2 14,2 14,4 23,4 	"
          />
          <path
            fill="currentColor"
            d="M47,23h-4c-0.6,0-1,0.4-1,1v1H32v-6h1c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1H15c-0.6,0-1,0.4-1,1v4
		c0,0.6,0.4,1,1,1h1v6H5.9v-1c0-0.6-0.4-1-1-1H1c-0.6,0-1,0.4-1,1v20c0,0.6,0.4,1,1,1h3.9c0.6,0,1-0.4,1-1v-1H42v1c0,0.6,0.4,1,1,1
		h4c0.6,0,1-0.4,1-1V24C48,23.4,47.6,23,47,23z M3.9,43H2V25h1.9V43z M16,15h16v2h-1H17h-1V15z M5.9,41V27H17c0.6,0,1-0.4,1-1v-7h12
		v7c0,0.6,0.4,1,1,1h11v14H5.9z M46,43h-2V25h2V43z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <polygon
            fill="currentColor"
            points="11,7 13,7 13,2.1 18,2.1 18,0.1 6,0.1 6,2.1 11,2.1 	"
          />
          <path
            fill="currentColor"
            d="M22,12.1v1h-5v-3h1v-2h-2H8H6v2h1v3H2v-1H0v12h2v-1h20v1h2v-12H22z M2,21.1v-6h6c0.6,0,1-0.4,1-1v-4h6v4
		c0,0.6,0.4,1,1,1h6v6H2z"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PipelineValue.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PipelineValue.defaultProps = {
  size: "medium",

  className: ""
};

export default PipelineValue;
