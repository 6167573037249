import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "../Button";
import Container from "../Container";
import HorizontalHeaderNav from "./HorizontalHeaderNav";
import HorizontalHeaderGlobalNav from "./HorizontalHeaderGlobalNav";
import { HamburgerIcon } from "../Icons";

function getVariantClass(type) {
  switch (type) {
    case "large":
      return "em-c-header--large";
    case "condensed":
      return "em-c-header--condensed";
    default:
      return "";
  }
}

function getColor(color) {
  switch (color) {
    case "blue":
      return "em-c-header--blue em-c-header--theme-blue";
    case "main":
      return "em-c-header--main";
    case "accent":
      return "em-c-header--accent";
    case "mainToAccent":
      return "em-c-header--mainToAccent";
    case "accentToMain":
      return "em-c-header--accentToMain";
    case "surface":
      return "em-c-header--surface";
    case "red":
    default:
      return "em-c-header--theme-red";
  }
}

/**
 * Displays a full-width header on wide screens and a hamburger menu on small screens.
 *
 * @visibleName Horizontal header
 * */
const HorizontalHeader = props => {
  const {
    title,
    kicker,
    color,
    variant,
    className,
    children,
    ...other
  } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    // this fixes a unity bug of going from mobil menu open to fullscreen will create some nasty scrollbars... Not sure how to hand this
    window.addEventListener(
      "resize",
      event => event.target.outerWidth > 650 && setMobileOpen(false)
    );
    return () =>
      window.removeEventListener(
        "resize",
        event => event.target.outerWidth > 650 && setMobileOpen(false)
      );
  }, []);

  return (
    <div>
      <header
        className={clsx(
          "em-c-header",
          getColor(color),
          getVariantClass(variant),
          className
        )}
        role="banner"
        {...other}
      >
        <Container className="em-c-header__inner">
          <div className="em-c-header__body">
            <div className="em-c-header__title-container">
              {kicker && <h4 className="em-c-header__kicker">{kicker}</h4>}
              <h2 className="em-c-header__title">
                <div className="em-c-header__title-link">{title}</div>
              </h2>
            </div>
            {children ? (
              <Button
                className="em-c-header__nav-btn"
                variant="inverted"
                size="small"
                onClick={() => {
                  setMobileOpen(prev => !prev);
                }}
              >
                <HamburgerIcon />
                <span>&nbsp;Menu</span>
              </Button>
            ) : (
              ""
            )}
            <div
              className={clsx("em-c-header__nav-container", {
                "em-is-active": mobileOpen
              })}
            >
              {children}
            </div>
          </div>
        </Container>
      </header>
      <div className="em-c-header-overlay" />
    </div>
  );
};

HorizontalHeader.propTypes = {
  /** Header */
  title: PropTypes.node,
  /** Subtitle */
  kicker: PropTypes.node,
  /** Theme will determine the color of the header */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "mainToAccent",
    "accentToMain",
    "surface",
    "red",
    "blue"
  ]),
  /** Size of the Header */
  variant: PropTypes.oneOf(["default", "large", "condensed"]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

HorizontalHeader.defaultProps = {
  title: "",
  kicker: "",
  color: "red",
  variant: "default",
  className: "",
  children: null
};

HorizontalHeader.Nav = HorizontalHeaderNav;

HorizontalHeader.GlobalNav = HorizontalHeaderGlobalNav;

export default HorizontalHeader;
