import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PlusIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 2h1v13H8z" />
        <path fill="currentColor" d="M2 8h13v1H2z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23 5h2v38h-2z" />
        <path fill="currentColor" d="M5 23h38v2H5z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11 2h2v20h-2z" />
        <path fill="currentColor" d="M2 11h20v2H2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PlusIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PlusIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PlusIcon;
