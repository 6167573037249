import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FileVideoIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 15h-9A1.5 1.5 0 0 1 2 13.5v-11A1.5 1.5 0 0 1 3.5 1h6.349a1.491 1.491 0 0 1 1.063.441L13.563 4.1A1.49 1.49 0 0 1 14 5.163V13.5a1.5 1.5 0 0 1-1.5 1.5zm-9-13a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V5.163a.5.5 0 0 0-.146-.353L10.2 2.147A.5.5 0 0 0 9.849 2z"
          fill="currentColor"
        />
        <path
          d="M5.69 11.64a.5.5 0 0 1-.5-.5V5.86a.5.5 0 0 1 .743-.437l4.759 2.64a.5.5 0 0 1 0 .874l-4.759 2.64a.5.5 0 0 1-.243.063zm.5-4.931v3.582L9.418 8.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40 46H10a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h20.343a4.967 4.967 0 0 1 3.535 1.464l7.657 7.657A4.967 4.967 0 0 1 43 16.657V43a3 3 0 0 1-3 3zM10 6a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V16.657a3.022 3.022 0 0 0-.878-2.121l-7.658-7.658A3.022 3.022 0 0 0 30.343 6z"
          fill="currentColor"
        />
        <path
          d="M19.472 33.5a1.931 1.931 0 0 1-.959-.259 2.038 2.038 0 0 1-1.013-1.769V18.528a2.039 2.039 0 0 1 1.012-1.771 1.913 1.913 0 0 1 1.937.01l11.042 6.472a2.04 2.04 0 0 1 0 3.521l-11.042 6.471a1.921 1.921 0 0 1-.977.269zm.028-14.97v12.942l10.98-6.436z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M9.648 17.077a1.881 1.881 0 0 1-.947-.249A1.368 1.368 0 0 1 8 15.651v-6.3a1.368 1.368 0 0 1 .7-1.177 1.881 1.881 0 0 1 1.784-.047l5.429 3.067a1.5 1.5 0 0 1-.006 2.619l-5.416 3.059a1.748 1.748 0 0 1-.843.205zM10 10.148v4.7l4.163-2.348zm4.931 2.786h.006zm0-.868h-.006z"
          fill="currentColor"
        />
        <path
          d="M19.875 24H4.125A2.115 2.115 0 0 1 2 21.9V2.1A2.115 2.115 0 0 1 4.125 0h10.318a3.258 3.258 0 0 1 2.29.929l4.307 4.212a3.2 3.2 0 0 1 .96 2.27V21.9a2.115 2.115 0 0 1-2.125 2.1zM4.125 2A.116.116 0 0 0 4 2.1v19.8a.116.116 0 0 0 .125.1h15.75a.116.116 0 0 0 .125-.1V7.411a1.167 1.167 0 0 0-.358-.84l-4.307-4.212A1.287 1.287 0 0 0 14.443 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FileVideoIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FileVideoIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FileVideoIcon;
