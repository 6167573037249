import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Adds a legend to the field */
const FieldsetLegend = props => {
  const { className, children, ...other } = props;

  return (
    <legend className={clsx("em-c-fieldset__legend", className)} {...other}>
      {children}
    </legend>
  );
};

FieldsetLegend.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case footerlinks usually */
  children: PropTypes.node
};

FieldsetLegend.defaultProps = {
  className: "",
  children: null
};

export default FieldsetLegend;
