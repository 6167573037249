import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const EnvelopeClosedIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 14h-11A1.5 1.5 0 0 1 1 12.5v-7A1.5 1.5 0 0 1 2.5 4h11A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5zm-11-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M8.33 11h-.66a.5.5 0 0 1-.248-.066L2.367 8.046l.5-.868L7.8 10h.4l4.939-2.822.5.868-5.061 2.888A.5.5 0 0 1 8.33 11z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 41H5a3 3 0 0 1-3-3V14a3 3 0 0 1 3-3h38a3 3 0 0 1 3 3v24a3 3 0 0 1-3 3zM5 13a1 1 0 0 0-1 1v24a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1V14a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M24 29.5a2.952 2.952 0 0 1-1.483-.4L6.633 19.862l1.006-1.729 15.883 9.237a.94.94 0 0 0 .955 0l15.884-9.237 1.006 1.729L25.483 29.1a2.952 2.952 0 0 1-1.483.4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 22H2a2 2 0 0 1-2-2V7.333C0 5.4 1.088 5 2 5h20c.912 0 2 .4 2 2.333V20a2 2 0 0 1-2 2zM2.01 7C2 7.1 2 7.245 2 7.333V20h20V7.333c0-.088 0-.229-.01-.333z"
          fill="currentColor"
        />
        <path
          d="M12 16a1 1 0 0 1-.5-.132l-7.868-4.5.992-1.736L12 13.848l7.372-4.212.992 1.736-7.868 4.5A1 1 0 0 1 12 16z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

EnvelopeClosedIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

EnvelopeClosedIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default EnvelopeClosedIcon;
