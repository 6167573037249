import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OilBarrel = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M8,0C5.8,0,2,0.4,2,2v12h0c0.1,1.6,3.8,2,6,2s5.9-0.4,6-2h0V2C14,0.4,10.2,0,8,0z M8,1c3,0,4.9,0.7,5,1
		c-0.1,0.4-2,1-5,1C5,3,3.2,2.4,3,2C3.2,1.6,5,1,8,1z M8,15c-3.3,0-4.9-0.8-5-1.1V3.2C4.3,3.8,6.5,4,8,4s3.7-0.2,5-0.8v10.7
		C12.9,14.2,11.3,15,8,15z"
          />
          <path
            fill="currentColor"
            d="M8,7C6,7,4.6,6.3,4.3,6.1L3.7,6.9C4.2,7.3,5.8,8,8,8s3.8-0.7,4.3-1.1l-0.6-0.8C11.4,6.3,10,7,8,7z"
          />
          <path
            fill="currentColor"
            d="M8,11c-2,0-3.4-0.7-3.7-0.9l-0.6,0.8C4.2,11.3,5.8,12,8,12s3.8-0.7,4.3-1.1l-0.6-0.8C11.4,10.3,10,11,8,11z
		"
          />
          <ellipse fill="currentColor" cx="5" cy="2" rx="1" ry="0.5" />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <ellipse fill="currentColor" cx="17" cy="6" rx="3" ry="2" />
          <path
            fill="currentColor"
            d="M41,6c0-3.9-8.6-6-17-6C15.6,0,7,2.1,7,6v36h0c0,0.1,0,0.1,0,0.2C7,46,15.6,48,24,48c8.4,0,17-2,17-5.8
		c0-0.1,0-0.1,0-0.2h0V6z M24,2c9.2,0,15,2.4,15,4s-5.8,4-15,4C14.8,10,9,7.6,9,6S14.8,2,24,2z M39,42.2c0,1.3-5.2,3.8-15,3.8
		c-9.8,0-15-2.5-15-3.8c0-0.1,0-0.1,0-0.2h0V9c3,2,9,3,15,3c6,0,12-1,15-3v33h0C39,42.1,39,42.1,39,42.2z"
          />
          <path
            fill="currentColor"
            d="M24,21c-8.3,0-12-2.5-12.4-2.8l-1.2,1.6C12.6,21.4,17.2,23,24,23c8.7,0,12.8-2.7,13.6-3.2l-1.2-1.6
		C36,18.5,32.3,21,24,21z"
          />
          <path
            fill="currentColor"
            d="M24,34c-8.3,0-12-2.5-12.4-2.8l-1.2,1.6C12.6,34.4,17.2,36,24,36c8.7,0,12.8-2.7,13.6-3.2l-1.2-1.6
		C36,31.5,32.3,34,24,34z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M21,3.5C21,0.7,15.3,0,12,0S3,0.7,3,3.5v17h0c0,0.1,0,0.1,0,0.2c0,3.2,8.1,3.3,9,3.3c3.3,0,9-0.7,9-3.3
		c0-0.1,0-0.1,0-0.2h0V3.5z M12,2c4.2,0,6.6,1,7,1.5C18.6,4,16.2,5,12,5C7.8,5,5.4,4,5,3.5C5.4,3,7.8,2,12,2z M19,20.6
		C18.7,21,16.3,22,12,22s-6.7-1-7-1.4c0,0,0-0.1,0-0.1h0V5.8C7,6.7,9.9,7,12,7s5-0.3,7-1.2v14.7h0C19,20.5,19,20.6,19,20.6z"
          />
          <path
            fill="currentColor"
            d="M12,11c-2.1,0-3.9-0.4-5.5-1.3l-0.9,1.8c1.9,1,4,1.5,6.5,1.5c2.4,0,4.5-0.5,6.5-1.5l-0.9-1.8
		C15.9,10.6,14.1,11,12,11z"
          />
          <path
            fill="currentColor"
            d="M12,16c-2.1,0-3.9-0.4-5.5-1.3l-0.9,1.8c1.9,1,4,1.5,6.5,1.5c2.4,0,4.5-0.5,6.5-1.5l-0.9-1.8
		C15.9,15.6,14.1,16,12,16z"
          />
          <ellipse fill="currentColor" cx="9" cy="3.5" rx="1.5" ry="1" />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OilBarrel.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OilBarrel.defaultProps = {
  size: "medium",

  className: ""
};

export default OilBarrel;
