import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const GraduationHatIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 9.843a.506.506 0 0 1-.237-.06L.4 5.819a.5.5 0 0 1 0-.881L7.763.974a.5.5 0 0 1 .475 0L15.6 4.938a.5.5 0 0 1 0 .881L8.237 9.783a.506.506 0 0 1-.237.06zM1.69 5.379L8 8.775l6.31-3.4L8 1.982zM14 7h1v4h-1z"
          fill="currentColor"
        />
        <path
          d="M7.7 14C5.064 14 3 12.726 3 11.1V8h1v3.1C4 12 5.52 13 7.7 13h.656C10.534 12.984 12 12 12 11.1V8h1v3.1c0 1.607-2.038 2.88-4.64 2.9z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 29.987a.991.991 0 0 1-.52-.147l-21-13a.987.987 0 0 1 0-1.679l21-13a.99.99 0 0 1 1.039 0l21 13a.987.987 0 0 1 0 1.679l-21 13a.991.991 0 0 1-.519.147zM4.875 16L24 27.839 43.125 16 24 4.161zM43 20h2v12h-2z"
          fill="currentColor"
        />
        <path
          d="M24 44c-8.411 0-15-3.953-15-9v-9h2v9c0 3.794 5.953 7 13 7h2.182C33.13 41.948 39 38.742 39 35v-9h2v9c0 4.984-6.5 8.937-14.8 9z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 16a1 1 0 0 1-.537-.156l-11-7a1 1 0 0 1 0-1.687l11-7a1 1 0 0 1 1.074 0l11 7a1 1 0 0 1 0 1.688l-11 7A1 1 0 0 1 12 16zM2.862 8L12 13.814 21.138 8 12 2.186z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M20 9h2v8h-2zM11 23c-3.6 0-7-2.43-7-5v-5h2v5c0 1.31 2.425 3 5 3h2a4.914 4.914 0 0 0 2.649-1.05A2.9 2.9 0 0 0 17 18v-4h2v4a4.7 4.7 0 0 1-2.15 3.55A6.8 6.8 0 0 1 13 23h-2z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

GraduationHatIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

GraduationHatIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default GraduationHatIcon;
