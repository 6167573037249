import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowSubdirectoryRightIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 13H4.16A1.162 1.162 0 0 1 3 11.84V2.5h1v9.34a.161.161 0 0 0 .16.16h6.34z"
          fill="currentColor"
        />
        <path
          d="M10.418 15.913l-.73-.683 2.47-2.638-2.47-2.638.73-.683 2.562 2.737a.878.878 0 0 1 .012 1.155z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M33 38H11a3 3 0 0 1-3-3V6h2v29a1 1 0 0 0 1 1h22z"
          fill="currentColor"
        />
        <path
          d="M30.586 46.414L29.172 45l7.293-7.293a1 1 0 0 0 0-1.414L29.172 29l1.414-1.414 7.293 7.293a3 3 0 0 1 0 4.242z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M17 23.414L15.586 22l4-4-4-4L17 12.586l4 4a2 2 0 0 1 0 2.828z"
          fill="currentColor"
        />
        <path d="M17 19H6a2 2 0 0 1-2-2V2h2v15h11z" fill="currentColor" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowSubdirectoryRightIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowSubdirectoryRightIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowSubdirectoryRightIcon;
