import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const AttachmentIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 15h-7A1.5 1.5 0 0 1 2 13.5v-11A1.5 1.5 0 0 1 3.5 1h4A1.5 1.5 0 0 1 9 2.5v8A1.5 1.5 0 0 1 7.5 12h-1A1.5 1.5 0 0 1 5 10.5V4h1v6.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V2h1v11.5a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M29 46H17a7.008 7.008 0 0 1-7-7V9a7.008 7.008 0 0 1 7-7h4a7.008 7.008 0 0 1 7 7v25a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4V12h2v22a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V9a5.006 5.006 0 0 0-5-5h-4a5.006 5.006 0 0 0-5 5v30a5.006 5.006 0 0 0 5 5h12a5.006 5.006 0 0 0 5-5V6h2v33a7.008 7.008 0 0 1-7 7z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16 24H8a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v15a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V6h2v12h2V4a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v17a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3h2v18a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

AttachmentIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

AttachmentIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default AttachmentIcon;
