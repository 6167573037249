import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** GlobalNavDropdownItem go inside a GlobalNavDropdown. */
const HorizontalHeaderGlobalNavDropdownItem = props => {
  const { className, children, ...other } = props;

  return (
    <li className={clsx("em-c-global-nav__subitem", className)} {...other}>
      <div className="em-c-global-nav__sublink">{children}</div>
    </li>
  );
};

HorizontalHeaderGlobalNavDropdownItem.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of the component */
  children: PropTypes.node
};

HorizontalHeaderGlobalNavDropdownItem.defaultProps = {
  className: "",
  children: null
};

export default HorizontalHeaderGlobalNavDropdownItem;
