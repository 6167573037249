import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LocationArrowIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 15.93a.5.5 0 0 1-.317-.114c-.24-.2-5.886-4.894-5.886-9.389C1.8 3.323 4.405 1.07 8 1.07s6.2 2.253 6.2 5.357c0 4.5-5.646 9.191-5.886 9.389A.5.5 0 0 1 8 15.93zM8 2.07c-3.015 0-5.2 1.83-5.2 4.357 0 3.458 4.027 7.3 5.2 8.341 1.175-1.046 5.2-4.888 5.2-8.341C13.2 3.9 11.015 2.07 8 2.07z"
          fill="currentColor"
        />
        <path
          d="M8 9a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 47a1 1 0 0 1-.642-.233C22.649 46.174 6 32.095 6 18.719 6 9.611 13.57 3 24 3s18 6.611 18 15.719c0 13.376-16.649 27.456-17.358 28.048A1 1 0 0 1 24 47zm0-42C14.729 5 8 10.77 8 18.719c0 11.025 12.939 23.223 16 25.953 3.061-2.73 16-14.929 16-25.954C40 10.77 33.271 5 24 5z"
          fill="currentColor"
        />
        <path
          d="M24 26a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 24a1 1 0 0 1-.636-.228c-.367-.3-9-7.489-9-14.413C2.359 4.516 6.414 1 12 1s9.641 3.516 9.641 8.359c0 6.924-8.638 14.11-9 14.413A1 1 0 0 1 12 24zm0-21C7.572 3 4.359 5.674 4.359 9.359c0 4.988 5.7 10.562 7.641 12.313 1.938-1.75 7.641-7.324 7.641-12.312C19.641 5.674 16.428 3 12 3z"
          fill="currentColor"
        />
        <path
          d="M12 14a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LocationArrowIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LocationArrowIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default LocationArrowIcon;
