import React, { useEffect, useState } from 'react';
import { Button, PlusFilledIcon, Searchbar, Select, Toolbar } from "react-unity";
import styled from "styled-components";
import AWSPortOpeningRequest from '../../models/entities/AWSPortOpeningRequests';
import { PORFilters } from './AWSPortOpeningRequestsHome';
import Filter from '../common/tables/Filter';
import { useNavigate } from 'react-router-dom';
import CheckboxFilter from '../common/tables/CheckboxFilter';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';

const StyledItem = styled(Toolbar.Item)`
	width: 50%;
`;

const MyP = styled.p`
	margin-right: 8px;
	margin-bottom: auto !important;
	margin-top: auto !important;
`;

const MyLink = styled.span`
	color: #0c69b0;
	text-decoration: underline;
	cursor: pointer;
`;

interface PORByRequestFilterProps {
	pors: AWSPortOpeningRequest[];
    setFilteredRequests: any;
}

const AWSPortOpeningRequestsHomeFilters = ({
    pors,
    setFilteredRequests,
}: PORByRequestFilterProps) => {

    const navigate = useNavigate();

    const getDefaultFilters = () => {
		const defaultFilters = {
			searchUser: '',
			status: [
				{ label: 'Submitted', value: 'Submitted', checked: true },
				{ label: 'Rejected', value: 'Rejected', checked: true },
				{ label: 'Pending for DOAG Approval', value: 'Pending for DOAG Approval', checked: true },
				{ label: 'Pending for Network Policy Approval', value: 'Pending for Network Policy Approval', checked: true },
				{ label: 'Pending for Network Policy Solution Architect Approval', value: 'Pending for Solution Architect Approval', checked: true },
				{ label: 'Pending for Cyber Approval', value: 'Pending for Cyber Approval', checked: true },
				{ label: 'Review', value: 'Review', checked: true },
				{ label: 'Awaiting Implementation', value: 'Awaiting Implementation', checked: true },
				{ label: 'Completed', value: 'Completed', checked: true },
				{ label: 'Cancelled', value: 'Cancelled', checked: true },
				{ label: 'Draft', value: 'Draft', checked: true },
			],
			scope:
				[
					{ label: 'Account -> Internet', value: PortOpeningRequestsDirection.AccountToInternet.name, checked: true },
				]

		} as PORFilters;

		return defaultFilters;
	};

    const filterPORs = () => {
		let filteredRequests = [];

		const filterCallback = (por: AWSPortOpeningRequest) => {
			return (
				filter.filterCheckBox(
					'status',
					por.workflowInstance.currentWorkflowInstanceState.workflowState?.name
				) &&
				filter.filterCheckBox('scope', por.direction.name) &&
				(filter.filterSearchText('searchUser', por.workflowInstance.createdBy.displayName) ||
				filter.filterSearchText('searchUser', por.contactUser?.displayName) ||
				filter.filterSearchText('searchUser', por.id.toLocaleString()))
			);
		};

		filteredRequests = pors.filter(filterCallback).sort((a, b) => {
			if (filter.filters.orderBy === 'newest') {
				return a.id > b.id ? 1 : -1;
			}
			return a.id < b.id ? 1 : -1;
		});

		setFilteredRequests(filteredRequests);

	};

    useEffect(() => {
		filter.updateFilterFunction(filterPORs);
	}, [pors]);

	useEffect(() => {
		filter.filter();
	}, []);

    const [filter] = useState<Filter<PORFilters>>(new Filter(filterPORs, getDefaultFilters()));

    const goToNewRequest = () => {
		navigate('/awsPortOpeningRequest/new');
	};

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchUser');
	};

    return (
        <>
            {filter && (
                <>
                    <Toolbar>
                        <StyledItem>
                            <Searchbar
                                condensed
                                buttonProps={{ onClick: clearSearchbar }}
                                inputProps={{
                                    value: filter.filters.searchUser,
                                    onChange: (e) => filter.handleSearchTextChange(e, 'searchUser'),
                                    placeholder: 'Search all fields shown below (i.e. specific IP address, Description, etc.)',
                                }} />
                        </StyledItem>
                        <Toolbar.Item right>
                            <Button
                                variant="primary"
                                size="small"
                                onClick={() => goToNewRequest()}
                            >
                                <PlusFilledIcon size="small" />
                                <span>New Request</span>
                            </Button>
                        </Toolbar.Item>
                    </Toolbar>
                    <Toolbar>
                        <Toolbar.Item>
                            <CheckboxFilter
                                label="Scope"
                                buttonProps={{ size: 'small' }}
                                onChange={(event) => filter.handleCheckboxChange(event, 'scope')}
                                options={filter.filters.scope} />
                        </Toolbar.Item>
                        <Toolbar.Item>
                            <CheckboxFilter
                                label="Status"
                                onChange={(event) => filter.handleCheckboxChange(event, 'status')}
                                options={filter.filters.status} />
                        </Toolbar.Item>
                        <Toolbar.Item>
                            <MyLink onClick={() => filter.updateFilters(getDefaultFilters())}>Clear filters</MyLink>
                        </Toolbar.Item>
                        <Toolbar.Item right>
                            <Button.Group>
                                <MyP>Sort by</MyP>
                                <Select
                                    style={{ marginBottom: -12 }}
                                    value={filter.filters.orderBy}
                                    onChange={(e) => filter.handleSearchTextChange(e, 'orderBy')}
                                >
                                    <option className='em-c-field-option' value="newest">Newest</option>
                                    <option className='em-c-field-option' value="oldest">Oldest</option>
                                </Select>
                            </Button.Group>
                        </Toolbar.Item>
                    </Toolbar>
                </>
            )}
        </>
    );
};

export default AWSPortOpeningRequestsHomeFilters;