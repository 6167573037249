import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TankTrunk = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M16,7.8l-1.5-3.5C14.4,4.1,14.2,4,14,4h-2.5C11.2,4,11,4.2,11,4.5V9h1V5h1.7L15,8.1V12c0-1.1-0.9-2-2-2
		s-2,0.9-2,2H8c0-1.1-0.9-2-2-2c-0.6,0-1.1,0.3-1.5,0.7C4.1,10.3,3.6,10,3,10c-1.1,0-2,0.9-2,2s0.9,2,2,2c0.6,0,1.1-0.3,1.5-0.7
		C4.9,13.7,5.4,14,6,14c0.7,0,1.4-0.4,1.7-1h3.6c0.3,0.6,1,1,1.7,1s1.4-0.4,1.7-1h0.8c0.3,0,0.5-0.2,0.5-0.5V8C16,7.9,16,7.9,16,7.8
		z M3,13c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S3.6,13,3,13z M6,13c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S6.6,13,6,13z M13,13
		c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S13.6,13,13,13z"
          />
          <path
            fill="currentColor"
            d="M2.5,9h5.1c0,0,0,0,0,0C9.4,8.9,10,6.6,10,5.5C10,3.5,8.9,2,7.5,2H2.5C1.1,2,0,3.5,0,5.5S1.1,9,2.5,9z
		 M2.5,3h5.1C8.3,3,9,4.1,9,5.5C9,6.1,8.6,8,7.5,8h-5C1.7,8,1,6.9,1,5.5S1.7,3,2.5,3z"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M47.9,23.6l-3-7C44.8,16.2,44.4,16,44,16h-7c-0.6,0-1,0.4-1,1v11h2V18h5.3l2.7,6.2V33h-0.1
		c-0.5-2.3-2.5-4-4.9-4s-4.4,1.7-4.9,4H20.9c-0.5-2.3-2.5-4-4.9-4c-2,0-3.7,1.2-4.5,2.9C10.7,30.2,9,29,7,29c-2.8,0-5,2.2-5,5
		s2.2,5,5,5c2,0,3.7-1.2,4.5-2.9C12.3,37.8,14,39,16,39c2.4,0,4.4-1.7,4.9-4h15.2c0.5,2.3,2.5,4,4.9,4s4.4-1.7,4.9-4H47
		c0.6,0,1-0.4,1-1V24C48,23.9,48,23.7,47.9,23.6z M7,37c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S8.7,37,7,37z M16,37c-1.7,0-3-1.3-3-3
		s1.3-3,3-3s3,1.3,3,3S17.7,37,16,37z M41,37c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S42.7,37,41,37z"
          />
          <path
            fill="currentColor"
            d="M7.9,27h18.1c0,0,0,0,0,0c5.5-0.1,7.9-5.6,7.9-8.5c0-4.7-3.6-8.5-7.9-8.5H7.9C3.6,10,0,13.8,0,18.5
		S3.6,27,7.9,27z M7.9,12h18.1c3.3,0,5.9,2.9,5.9,6.5c0,1.9-1.7,6.5-6,6.5H7.9C4.7,25,2,22.1,2,18.5S4.7,12,7.9,12z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M23.9,11.7l-1.8-5C22,6.3,21.6,6,21.2,6H17c-0.6,0-1,0.4-1,1v7h2V8h2.5l1.5,4.2V17h-0.2
		c-0.4-1.2-1.5-2-2.8-2s-2.4,0.8-2.8,2h-4.4c-0.4-1.2-1.5-2-2.8-2c-1,0-2,0.5-2.5,1.3C6,15.5,5,15,4,15c-1.7,0-3,1.3-3,3s1.3,3,3,3
		c1,0,2-0.5,2.5-1.3C7,20.5,8,21,9,21c1.3,0,2.4-0.8,2.8-2h4.4c0.4,1.2,1.5,2,2.8,2s2.4-0.8,2.8-2H23c0.6,0,1-0.4,1-1v-6
		C24,11.9,24,11.8,23.9,11.7z M4,19c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S4.6,19,4,19z M9,19c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1
		S9.6,19,9,19z M19,19c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S19.6,19,19,19z"
          />
          <path
            fill="currentColor"
            d="M3.8,14h7.4c2.7,0,3.8-3.5,3.8-5c0-2.8-1.7-5-3.8-5H3.8C1.7,4,0,6.2,0,9S1.7,14,3.8,14z M3.8,6h7.4
		C12,6,13,7.2,13,9c0,0.9-0.8,3-1.8,3H3.8C3,12,2,10.8,2,9S3,6,3.8,6z"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TankTrunk.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TankTrunk.defaultProps = {
  size: "medium",

  className: ""
};

export default TankTrunk;
