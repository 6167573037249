import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const StarFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M8 1.13L9.745 6.5h5.646l-4.568 3.319 1.745 5.37L8 11.87l-4.568 3.319 1.745-5.37L.609 6.5h5.646L8 1.13z"
        />
        <path
          d="M12.568 15.688a.5.5 0 0 1-.294-.1L8 12.488l-4.274 3.1a.5.5 0 0 1-.77-.559l1.632-5.023L.315 6.9A.5.5 0 0 1 .609 6h5.283L7.524.976a.52.52 0 0 1 .951 0L10.108 6h5.283a.5.5 0 0 1 .294.9l-4.274 3.106 1.632 5.023a.5.5 0 0 1-.476.654zM8 11.37a.5.5 0 0 1 .294.1l3.323 2.414-1.269-3.91a.5.5 0 0 1 .182-.559L13.853 7H9.745a.5.5 0 0 1-.476-.346L8 2.748 6.731 6.654A.5.5 0 0 1 6.255 7H2.147l3.324 2.415a.5.5 0 0 1 .182.559l-1.27 3.906 3.323-2.414A.5.5 0 0 1 8 11.37z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M24 3l5.193 16.043H46l-13.597 9.914L37.597 45 24 35.085 10.403 45l5.194-16.043L2 19.043h16.807L24 3z"
        />
        <path
          d="M37.6 46a1 1 0 0 1-.589-.192L24 36.322l-13.007 9.486a1 1 0 0 1-1.541-1.115l4.97-15.354-13.011-9.488A1 1 0 0 1 2 18.043h16.079l4.97-15.351a1 1 0 0 1 1.9 0l4.97 15.351H46a1 1 0 0 1 .589 1.808l-13.011 9.487 4.97 15.354A1 1 0 0 1 37.6 46zM24 34.085a1 1 0 0 1 .589.192l11.107 8.1-4.244-13.112a1 1 0 0 1 .362-1.115l11.117-8.106H29.193a1 1 0 0 1-.951-.692L24 6.247l-4.242 13.1a1 1 0 0 1-.951.692H5.069l11.117 8.106a1 1 0 0 1 .362 1.115L12.3 42.377l11.107-8.1a1 1 0 0 1 .593-.192z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M11.599 2l2.503 7.702h8.097l-6.551 4.761 2.502 7.702-6.551-4.76-6.55 4.76 2.502-7.702L1 9.702h8.097L11.599 2z"
        />
        <path
          d="M18.15 23.165a1 1 0 0 1-.588-.191L11.6 18.641l-5.963 4.333A1 1 0 0 1 4.1 21.856l2.278-7.012-5.966-4.333A1 1 0 0 1 1 8.7h7.371l2.278-7.011A1 1 0 0 1 11.6 1a1 1 0 0 1 .951.691L14.828 8.7H22.2a1 1 0 0 1 .588 1.809l-5.963 4.334 2.275 7.013a1 1 0 0 1-.951 1.309zM4.077 10.7l4.062 2.952a1 1 0 0 1 .361 1.119l-1.549 4.776 4.061-2.947a1 1 0 0 1 1.176 0l4.061 2.951-1.549-4.78a1 1 0 0 1 .363-1.117l4.059-2.954H14.1a1 1 0 0 1-.951-.691L11.6 5.236l-1.551 4.774a1 1 0 0 1-.949.69z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

StarFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

StarFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default StarFilledIcon;
