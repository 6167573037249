import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import "./ProgressBar.css";

function getColor(color) {
  return (
    {
      main: "em-c-progress-bar--main",
      accent: "em-c-progress-bar--accent",
      action: "em-c-progress-bar--action",
      caution: "em-c-progress-bar--caution",
      negative: "em-c-progress-bar--negative",
      positive: "em-c-progress-bar--positive",
      blue: ""
    }[color] || ""
  );
}

function calculateValue(min, max, value, isIndeterminate) {
  // The bar needs to be max value when the progress is indeterminate
  if (isIndeterminate) {
    return 100;
  }

  return ((value - min) * 100) / (max - min);
}

/**
 * Progress bars show the progression of a system operation downloading, uploading, processing, etc., in a visual way.
 * They can represent either determinate or indeterminate progress
 *
 * @visibleName Progress bar
 * */
function ProgressBar(props) {
  const {
    className,
    label,
    value,
    valueBuffer,
    indeterminate,
    thin,
    min,
    max,
    color,
    hideValueLabel,
    valueFormatter,
    ...other
  } = props;
  const calculatedValue = calculateValue(min, max, value, indeterminate);
  const calculatedBufferedValue = calculateValue(
    min,
    max,
    valueBuffer,
    indeterminate
  );

  return (
    <div
      className={clsx(
        "em-c-field",
        "em-c-progress-bar",
        getColor(color),
        {
          "em-c-progress-bar--indeterminate": indeterminate,
          "em-c-progress-bar--thin": thin
        },
        className
      )}
      {...other}
    >
      <div className="em-c-progress-bar__label">
        {label && <label>{label}</label>}

        {/* Displays the value as a percentage */}
        {!hideValueLabel && (
          <span className="em-u-margin-left-half">
            {typeof valueFormatter === "function" &&
              valueFormatter(calculatedValue)}
          </span>
        )}
      </div>

      <div
        className={clsx("em-c-progress-bar__bar", {
          "em-c-progress-bar__bar--buffer": calculatedBufferedValue
        })}
      >
        {/* Value Bar */}
        <span
          className={clsx("em-c-progress-bar__value", {
            "em-c-progress-bar--indeterminate__value": indeterminate,
            "em-c-progress-bar__value--buffer": calculatedBufferedValue
          })}
          style={{ width: `${calculatedValue}%` }}
        />

        {/* Buffer Bar */}
        {calculatedBufferedValue > 0 && !indeterminate && (
          <span
            className="em-c-progress-bar__value em-c-progress-bar__buffer-value"
            style={{ width: `${calculatedBufferedValue}%` }}
          />
        )}
      </div>
    </div>
  );
}

ProgressBar.defaultProps = {
  className: "",
  label: "",
  value: 0,
  valueBuffer: 0,
  indeterminate: false,
  thin: false,
  min: 0,
  max: 100,
  color: "blue",
  hideValueLabel: false,
  valueFormatter: value => `${Math.round(value)}%`
};

ProgressBar.propTypes = {
  /** ClassName to append to the outermost element */
  className: PropTypes.string,
  /** Description of the progress bar is for */
  label: PropTypes.node,
  /** Progress value between the maximum and minimum */
  value: PropTypes.number,
  /** Progress value of the buffer */
  valueBuffer: PropTypes.number,
  /** Indicates that the progress is indefinite */
  indeterminate: PropTypes.bool,
  /** Indicates a thin style progress bar */
  thin: PropTypes.bool,
  /** Minimum value of the progress bar */
  min: PropTypes.number,
  /** Maximum value of the progress bar */
  max: PropTypes.number,
  /** Theme color of the progress bar */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "positive",
    "caution",
    "negative",
    "blue"
  ]),
  /** Hides the value percentage label */
  hideValueLabel: PropTypes.bool,
  /** Formats the way that the value is displayed above the progress bar */
  valueFormatter: PropTypes.func
};

export default ProgressBar;
