import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** TooltipContent is the content that will be shown in the tooltip */
const TooltipContent = props => {
  const { className, children, ...other } = props;

  return (
    <span className={clsx("em-c-tooltip__content", className)} {...other}>
      {children}
    </span>
  );
};

TooltipContent.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node
};

TooltipContent.defaultProps = {
  className: "",
  children: null
};

export default TooltipContent;
