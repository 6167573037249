import User from '../User';
import { UserRole } from '../enums/UserRole';
import { ObjectType } from '../enums/ObjectType'

export default class ChangeHistory {

    objectType!: ObjectType;

    objectId!: string;

    field!: string;

    oldValue!: string;

    newValue!: string;

    responsibleUser!: User;

    responsibleUserRole!: UserRole;

    timestamp!: Date;

    constructor(obj: any, field?: string, oldValue?: string, newValue?: string) {
		Object.assign(this, obj);
        if (field && oldValue && newValue) {
            this.field = field;
            this.oldValue = oldValue;
            this.newValue = newValue;
        }
	}

}
