import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SoftwareIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.5 15a4.5 4.5 0 1 1 4.5-4.5 4.5 4.5 0 0 1-4.5 4.5zm0-8a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 11.5 7z"
          fill="currentColor"
        />
        <path
          d="M11.5 12a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM6 14H2.5a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5V5h-1V2H3v11h3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M32.5 48A14.5 14.5 0 1 1 47 33.5 14.517 14.517 0 0 1 32.5 48zm0-27A12.5 12.5 0 1 0 45 33.5 12.514 12.514 0 0 0 32.5 21z"
          fill="currentColor"
        />
        <path
          d="M32.5 41a7.5 7.5 0 1 1 7.5-7.5 7.508 7.508 0 0 1-7.5 7.5zm0-13a5.5 5.5 0 1 0 5.5 5.5 5.507 5.507 0 0 0-5.5-5.5z"
          fill="currentColor"
        />
        <path
          d="M32.5 37a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5zM16 42H6a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h28a3 3 0 0 1 3 3v13h-2V3a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h10z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 6h21v2H8zM8 10h17v2H8zM8 14h13v2H8z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16 24a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zm0-14a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6z"
          fill="currentColor"
        />
        <path
          d="M16 18a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM7 21H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v5h-2V2H3v17h4z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SoftwareIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SoftwareIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SoftwareIcon;
