import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SolidCardBodyKicker = props => {
  const { className, children, ...other } = props;

  return (
    <h4 className={clsx("em-c-solid-card__kicker", className)} {...other}>
      {children}
    </h4>
  );
};

SolidCardBodyKicker.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

SolidCardBodyKicker.defaultProps = {
  className: "",
  children: null
};

export default SolidCardBodyKicker;
