import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CircleExclamationMarkFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.8 3.2a7.5 7.5 0 1 0 0 10.6 7.49 7.49 0 0 0 0-10.6zM8 4h1v6H8zm.5 8.5a.75.75 0 1 1 .75-.75.755.755 0 0 1-.75.75z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39.56 8.44a22.005 22.005 0 1 0 0 31.12 22.012 22.012 0 0 0 0-31.12zM23 11h2v16h-2zm1 24a2 2 0 0 1-2-2 2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19.78 4.22a11 11 0 1 0 0 15.56 11.015 11.015 0 0 0 0-15.56zM13 18h-2v-2h2zm0-4h-2V6h2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CircleExclamationMarkFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CircleExclamationMarkFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CircleExclamationMarkFilledIcon;
