import React, { useState, useRef } from 'react';
import {
    Field as UnityField,
    Grid,
    Tag,
    Tooltip,
    Typeahead,
    Alert,
    TextPassage,
    TextLink
} from 'react-unity';
import ReadonlyField from './ReadonlyField';
import ServicePrincipalService from '../../../services/ServicePrincipalService';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { Validation } from '../../../utils/Validation';
import ServicePrincipalLookupObject from '../../../models/ServicePrincipalLookupObject';

interface ServicePrincipalLookupProps extends FieldHandler<any> { 
	label: string;
	placeholder?: string;
	note?: string;
    noteLink?: string;
	readonly?: boolean;
}

const ServicePrincipalLookup = (props: ServicePrincipalLookupProps ) => {
	
    const servicePrinciaplService = new ServicePrincipalService();
	const [searching, setSearching] = useState<boolean>(false);
	const [results, setSearchResults] = useState<ServicePrincipalLookupObject[]>([]);
	const searchText = useRef<string>('');

	const isValid = (): boolean => {
		if (!props.validation) return true;
		return new Validation(props.validation).assert(props.value);
	}

	const nonValidMessage = () => {
		if (isValid()) return null;
		const invalidRule = props.validation.rules?.find((rule) => !rule.assert(props.value));
		if (!invalidRule) return '';

		const message = invalidRule?.message;
		if (typeof message == 'string') return message;
		return message(props.value);
	}

	const handleOnChange = (value) => {
        const obj = results.find(o => o.appId === value);
        searchText.current = obj?.displayName
        props.onChange(obj);
	}

	const handleSearchUpdate = async (e) => {
		searchText.current = e.target.value;
		const searchTextTrimed = searchText.current.trim();
		props.onChange(null);
		if (searchTextTrimed?.length > 2) {
            setSearching(true);
			const response = await servicePrinciaplService.servicePrincipalLookup(searchTextTrimed);
            setSearchResults(response);
            setSearching(false);
		}
	}

	return (
		<>
            {!props.disabled ?
                <>
                        <Typeahead
                            className="em-u-width-100"
                            onChange={(e, value) => handleOnChange(value)}
                            fieldProps={{
                                label: props.label,
                                error: !isValid(),
                                note: nonValidMessage() || (!props.noteLink? props.note: null),
                                disabled: props.disabled,
                            }}
                            inputProps={{
                                onChange: (e) => handleSearchUpdate(e),
                                placeholder: props.placeholder,
                                value: searchText.current,
                                loading: searching,
                            }}
                        >
                            {searching && !results?.length ? (
                                <Typeahead.Item value={0} onClick={() => { }}>
                                    <Typeahead.Item.Suggestion aria-disabled={true}>
                                        <i>No results found.</i>
                                    </Typeahead.Item.Suggestion>
                                </Typeahead.Item>
                            ) : (
                                results?.map((sp) => (
                                    <Typeahead.Item value={sp.appId} key={sp.appId}>
                                        <Typeahead.Item.Suggestion>
                                            <strong>{`${sp.displayName}`}</strong>&nbsp;
								            <span>{`(${sp.appId})`}</span>
                                        </Typeahead.Item.Suggestion>
                                    </Typeahead.Item>
                                ))
                            )}
                        </Typeahead>
                        {props.noteLink? <TextPassage>
							<p className='em-c-field__note' style={!isValid()? { color: '#ca6a72' } : null}>
								{props.note}
								<TextLink href={props.noteLink} external>
									More info...
								</TextLink>
							</p>
						</TextPassage>: null}
                </>
            :
                <ReadonlyField
					label={props.label}
					text={props.value}
				/>
            }
        </>
    );
}

export default ServicePrincipalLookup;
