import React, { useState, useEffect } from 'react';
import { Button, PlusIcon, RefreshIcon, Toolbar } from 'react-unity';
import OrganizationTuple from '../../../models/entities/OrganizationTuple';
import ResourceTabs from '../../common/ResourceTabs';
import Filter from '../../common/tables/Filter';
import OrganizationsService from '../../../services/OrganizationsService';

type OrganizationsFilters = {
	searchGeneral: string;
	showResources: string;
};

interface OrganizationHomeFiltersProps {
	filteredOrganizations: OrganizationTuple[];
	setFilteredOrganizations: any;
	allOrganizations: OrganizationTuple[];
	retreiveOrganizations: any;
	setDecisionModalVisible: any;
	setL3: any;
}

const OrganizationsHomeFilters = ({
	allOrganizations,
	setFilteredOrganizations,
	retreiveOrganizations,
	setDecisionModalVisible,
	setL3
}: OrganizationHomeFiltersProps) => {

	const organizationsService = new OrganizationsService();
	const defaultFilters = {
		searchGeneral: '',
		showResources: allOrganizations[0].item1,
	};

	const filterOrganizations = () => {
		let filtered = [];

		const filterCallback = (org: OrganizationTuple) => {			
			return(
					filter.filterSearchText('searchGeneral', org.item1.toLowerCase())
				);
			
		};

		filtered = allOrganizations.filter(filterCallback);

		filtered = filtered.filter(
			(org: OrganizationTuple) => {			
				if (org.item1 == filter.filters.showResources){
					organizationsService.getByDisplayName(org.item1).then((res) => setL3(res));
					return org
				}
			}
		);

		setFilteredOrganizations(filtered);
	};

	const clearSearchbar = (e) => {
		e.preventDefault();
		filter.updateFilter('', 'searchGeneral');
	};

	const handleRefresh = () => {
		filter.updateFilter(allOrganizations[0].item1, 'showResources');
		retreiveOrganizations(true);
	};

	useEffect(() => {
		filter.updateFilterFunction(filterOrganizations);
	}, [allOrganizations]);

	useEffect(() => {
		filter.filter();
	}, []);

	const [filter] = useState<Filter<OrganizationsFilters>>(new Filter(filterOrganizations, defaultFilters));

	return (
		<>
			{filter && (
				<>
					<ResourceTabs
						allResources={allOrganizations}
						filter={filter}
					/>
					<Toolbar id="toggle-toolbar">
						<Toolbar.Item right>
							<Button.Group>
								<Button
									variant="primary"
									size="small"
									onClick={() => setDecisionModalVisible({ ['createOrEditOrg']: true })}
								>
									<PlusIcon size="small" />
									<span>Create new L4/L5</span>
								</Button>
								<Button variant="secondary" size="small" onClick={handleRefresh}>
									<RefreshIcon size="small" />
									<span> Refresh </span>
								</Button>
							</Button.Group>
						</Toolbar.Item>
					</Toolbar>
				</>
			)}
		</>
	);
};

export default OrganizationsHomeFilters;