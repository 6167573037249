import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**
 * LineLengthContainer creates a container set to line-length size, great for wrapping forms.
 *
 * @visibleName Line-length container
 * */
const LineLengthContainer = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-l-linelength-container", className)} {...other}>
      {children}
    </div>
  );
};

LineLengthContainer.propTypes = {
  /** children of the container */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

LineLengthContainer.defaultProps = {
  children: null,
  className: ""
};

export default LineLengthContainer;
