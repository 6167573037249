import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BookmarkLineIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 14.22a.5.5 0 0 1-.253-.069L8 11.661l-4.247 2.49A.5.5 0 0 1 3 13.72V2.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v11.22a.5.5 0 0 1-.5.5zM8 10.582a.5.5 0 0 1 .253.069L12 12.848V3H4v9.848l3.747-2.2A.5.5 0 0 1 8 10.582z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M38 41a1 1 0 0 1-.5-.132L24 33.156l-13.5 7.712A1 1 0 0 1 9 40V8a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3v32a1 1 0 0 1-1 1zM24 31a1 1 0 0 1 .5.132L37 38.277V8a1 1 0 0 0-1-1H12a1 1 0 0 0-1 1v30.277l12.5-7.141A1 1 0 0 1 24 31z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21 23a1 1 0 0 1-.5-.132l-8.143-4.649-7.848 4.642A1 1 0 0 1 3 22V2a1.811 1.811 0 0 1 1.941-2h14.824A2.238 2.238 0 0 1 22 2.235V22a1 1 0 0 1-1 1zm-8.647-6.938a1 1 0 0 1 .5.132L20 20.277V2.235A.235.235 0 0 0 19.765 2H5v18.247l6.844-4.047a1 1 0 0 1 .509-.138z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BookmarkLineIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BookmarkLineIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BookmarkLineIcon;
