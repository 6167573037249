import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FilePngIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3.937 7.142a1.921 1.921 0 0 1-.119.668 1.486 1.486 0 0 1-.342.548 2.086 2.086 0 0 1-.679.455 2.456 2.456 0 0 1-.931.154H1.05v1.658H-.263V5.536H1.9a3.42 3.42 0 0 1 .818.083 1.928 1.928 0 0 1 .59.251 1.344 1.344 0 0 1 .47.516 1.626 1.626 0 0 1 .159.756zm-1.356.029a.6.6 0 0 0-.116-.37.635.635 0 0 0-.27-.216 1.226 1.226 0 0 0-.4-.091c-.13-.005-.3-.008-.52-.008H1.05v1.521h.376a3.06 3.06 0 0 0 .552-.041.777.777 0 0 0 .364-.166.628.628 0 0 0 .183-.261 1.056 1.056 0 0 0 .056-.368zM9.916 10.625H8.65L6.49 7.132v3.493h-1.2V5.536h1.566l1.857 2.915V5.536h1.2zM16.136 10.334a6.918 6.918 0 0 1-.905.265 4.967 4.967 0 0 1-1.125.131 2.815 2.815 0 0 1-2.033-.706 2.576 2.576 0 0 1-.735-1.95 2.556 2.556 0 0 1 .741-1.912 2.83 2.83 0 0 1 2.068-.727 4.875 4.875 0 0 1 .957.091 4.256 4.256 0 0 1 1.012.36v1.195h-.146l-.281-.2a3.41 3.41 0 0 0-.355-.224 2.318 2.318 0 0 0-.463-.188 1.965 1.965 0 0 0-.562-.079 1.844 1.844 0 0 0-.632.1 1.471 1.471 0 0 0-.509.314 1.406 1.406 0 0 0-.34.521 1.959 1.959 0 0 0-.125.729 1.747 1.747 0 0 0 .447 1.292 1.794 1.794 0 0 0 1.322.448h.166c.061 0 .115-.006.166-.01v-1h-1.017v-.96h2.349zM3 4H2V2.5A1.5 1.5 0 0 1 3.5 1h6.506a1.491 1.491 0 0 1 1.061.439l1.7 1.7-.707.707-1.7-1.7A.5.5 0 0 0 10.006 2H3.5a.5.5 0 0 0-.5.5zM12.5 15h-9A1.5 1.5 0 0 1 2 13.5V12h1v1.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V12h1v1.5a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M44 35H6a3 3 0 0 1-3-3V19a3 3 0 0 1 3-3h38a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3zM6 18a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1V19a1 1 0 0 0-1-1zM40 14.414l-7.527-7.527A3 3 0 0 0 30.34 6H10a1 1 0 0 0-1 1v7H7V7a3 3 0 0 1 3-3h20.34a4.983 4.983 0 0 1 3.547 1.473L41.414 13zM40 46H10a3 3 0 0 1-3-3v-6h2v6a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-6h2v6a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M11.5 22.271a.747.747 0 0 1 .745-.771h2.568c1.968 0 3.193 1.156 3.193 2.909v.025c0 1.952-1.512 2.971-3.361 2.971h-1.668v2.1a.739.739 0 1 1-1.477 0zm3.193 3.754a1.6 1.6 0 0 0 1.813-1.555v-.024c0-1.019-.709-1.554-1.813-1.554h-1.716v3.133zM19.523 22.282a.753.753 0 0 1 .744-.77h.156a.924.924 0 0 1 .768.447l4.2 5.632v-5.345a.726.726 0 1 1 1.452 0v7.31a.73.73 0 0 1-.719.758h-.061a.924.924 0 0 1-.768-.46l-4.321-5.793v5.52a.726.726 0 1 1-1.452 0zM28.638 26.025V26a4.36 4.36 0 0 1 4.332-4.5 4.45 4.45 0 0 1 2.868.883.781.781 0 0 1 .3.622.749.749 0 0 1-.733.758.758.758 0 0 1-.455-.174 3.125 3.125 0 0 0-2.041-.683 2.9 2.9 0 0 0-2.725 3.07V26a2.862 2.862 0 0 0 2.857 3.108 3.347 3.347 0 0 0 2.028-.659V26.82h-1.653a.656.656 0 0 1-.661-.659.668.668 0 0 1 .661-.671h2.34a.747.747 0 0 1 .745.77v2.325a1.065 1.065 0 0 1-.516.97A5.227 5.227 0 0 1 33 30.5a4.244 4.244 0 0 1-4.362-4.475z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M7.937 11.395a1.921 1.921 0 0 1-.119.668 1.486 1.486 0 0 1-.342.548 2.086 2.086 0 0 1-.679.455 2.456 2.456 0 0 1-.931.154H5.05v1.658H3.737v-5.09H5.9a3.42 3.42 0 0 1 .818.083 1.928 1.928 0 0 1 .59.251 1.344 1.344 0 0 1 .47.516 1.626 1.626 0 0 1 .159.757zm-1.356.029a.6.6 0 0 0-.116-.37.635.635 0 0 0-.27-.216 1.226 1.226 0 0 0-.4-.091c-.13-.005-.3-.008-.52-.008H5.05v1.521h.376a3.06 3.06 0 0 0 .552-.041.777.777 0 0 0 .364-.164.628.628 0 0 0 .183-.261 1.056 1.056 0 0 0 .056-.37zM13.916 14.877H12.65l-2.16-3.493v3.493h-1.2V9.788h1.569l1.854 2.912V9.788h1.2zM20.136 14.587a6.918 6.918 0 0 1-.905.265 4.967 4.967 0 0 1-1.125.131 2.815 2.815 0 0 1-2.033-.706 2.576 2.576 0 0 1-.735-1.95 2.556 2.556 0 0 1 .741-1.912 2.83 2.83 0 0 1 2.068-.727 4.875 4.875 0 0 1 .957.091 4.256 4.256 0 0 1 1.012.36v1.193h-.146l-.281-.2a3.41 3.41 0 0 0-.355-.224 2.318 2.318 0 0 0-.463-.188 1.965 1.965 0 0 0-.562-.079 1.844 1.844 0 0 0-.632.1 1.471 1.471 0 0 0-.509.314 1.406 1.406 0 0 0-.34.521 1.959 1.959 0 0 0-.125.729 1.747 1.747 0 0 0 .447 1.292 1.794 1.794 0 0 0 1.322.448h.166c.061 0 .115-.006.166-.01v-1h-1.017v-.96h2.349z"
          fill="currentColor"
        />
        <path
          d="M23 19H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1zM2 17h20V8H2zM19.151 5.406L16.654 3.4a.879.879 0 0 1-.081-.073C16.265 3.013 16.175 3 16 3H5v2H3V3a1.979 1.979 0 0 1 2-2h11a2.609 2.609 0 0 1 1.949.87l2.457 1.978zM17.857 24H5.143A2.077 2.077 0 0 1 3 22v-2h2v2c0-.042.07 0 .143 0h12.714a.234.234 0 0 0 .159-.063L18 20h2v2a2.077 2.077 0 0 1-2.143 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FilePngIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FilePngIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FilePngIcon;
