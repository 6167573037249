import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SolidCardBody from "./SolidCardBody";
import SolidCardFooter from "./SolidCardFooter";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-solid-card--main";
    case "accent":
      return "em-c-solid-card--accent";
    case "action":
      return "em-c-solid-card--action";
    case "caution":
      return "em-c-solid-card--caution";
    case "negative":
      return "em-c-solid-card--negative";
    case "positive":
      return "em-c-solid-card--positive";
    case "red":
      return "em-c-solid-card--red";
    case "blue":
      return "em-c-solid-card--blue";
    case "gray":
      return "em-c-solid-card--gray";
    case "default":
    default:
      return "";
  }
}

/**
 * A stylized card with a flat graphical style ideal for calling out sections of an app or website, or listing small amounts of content by category or content type.
 *
 * @visibleName Solid card
 * */
const SolidCard = props => {
  const { compact, color, className, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-solid-card",
        { "em-c-solid-card--compact": compact },
        getColor(color),
        className
      )}
      {...other}
    >
      {children}
    </div>
  );
};

SolidCard.Body = SolidCardBody;
SolidCard.Footer = SolidCardFooter;

SolidCard.propTypes = {
  /** Remove the default gradient overlay or replace it with a solid, uniform tint. */
  compact: PropTypes.bool,
  /** Change the background of the card */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "caution",
    "negative",
    "positive",
    "red",
    "blue",
    "gray",
    "default"
  ]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

SolidCard.defaultProps = {
  compact: false,
  color: "default",
  className: "",
  children: null
};

export default SolidCard;
