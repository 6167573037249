import React from 'react';
import { Grid, TreeNavigation, ButtonBar, Button, XFilledIcon, SaveDiskIcon, ButtonSize, Alert, Loader } from 'react-unity';
import WorkflowDetailWrapper from '../common/wrappers/WorkflowDetailWrapper';
import UserLookup from '../common/form-controls/UserLookup';
import TextAreaField from '../common/form-controls/TextareaField';
import { Validation } from '../../utils/Validation';
import ReadonlyField from '../common/form-controls/ReadonlyField';
import AlertModal from '../common/modals/AlertModal';
import ConfirmModal from '../common/modals/ConfirmModal';
import UpdateAWSPortOpeningRequest from '../../models/viewModels/PortOpeningRequests/UpdateAWSPortOpeningRequest';
import UpdateAWSPortOpeningRequestRule from '../../models/viewModels/PortOpeningRequests/UpdateAWSPortOpeningRequestRule';
import { WorkflowState } from '../../models/enums/WorkflowState';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import './AWSPortOpeningRequestsDetail.css';
import { useNavigate, useParams } from 'react-router-dom';
import { AbstractAWSPORForm, AbstractAWSPORFormProps, AbstractAWSPORFormState, IAWSPORStateForm } from './components/AbstractAWSPORForm';
import AWSPortOpeningRequest from '../../models/entities/AWSPortOpeningRequests';
import { AWSRulesTable } from './components/AWSRulesTable';
import { ApproverInput, EnvironmentInput } from '../PortOpeningRequests/components';
import PORTypeInput from './components/PORTypeInput';
import AWSPortOpeningRequestsCreate from './AWSPortOpeningRequestsCreate';
import { VirtualPrivateCloud } from '../../models/entities/AzureResources/VirtualPrivateCloud';
import ProgressBarBig from './components/ProgressBarBig';
import { ObjectType } from '../../models/enums/ObjectType';
import ChangeHistoryItem from '../common/ChangeHistoryItem';
import ChangeHistoryModal from '../common/modals/ChangeHistoryModal';
import ChangeHistory from '../../models/entities/ChangeHistory';

interface AWSPortOpeningRequestsDetailWithParamsProps extends AbstractAWSPORFormProps { }

interface StateForm extends IAWSPORStateForm { }

interface AWSPortOpeningRequestsDetailWithParamsState extends AbstractAWSPORFormState<StateForm> {
	loading: boolean;
	awsPortOpeningRequest?: AWSPortOpeningRequest;
	editMode: boolean;
	matchPatch: string;
	decisionModalVisible: {
		approve?: boolean;
		escalate?: boolean;
		reject?: boolean;
		review?: boolean;
		cancel?: boolean;
		history?: boolean;
	},
	virtualPrivateClouds: VirtualPrivateCloud[];
	modalComment: string;
	selectedFromExisting: string[];
	changeHistoryData: ChangeHistory[];
}

class AWSPortOpeningRequestsDetailWithParams extends AbstractAWSPORForm
	<AWSPortOpeningRequestsDetailWithParamsProps, AWSPortOpeningRequestsDetailWithParamsState> {

	constructor(props) {
		super(props);

		this.state = {
			awsAccount: null,
			loading: true,
			stateForm: {
				environment: null,
				contact: null,
				customerCoordination: null,
				businessJustification: '',
				ruleList: [],
			},
			editMode: false,
			submissionWarned: false,
			operationInProgress: false,
			endModal: {
				visible: false,
			},
			submissionAlert: {
				visible: false,
			},
			virtualPrivateClouds: [],
			decisionModalVisible: {},
			modalComment: '',
			selectedFromExisting: [],
			matchPatch: null,
			changeHistoryData: [],
		};

		this.initFormModel();
	}

	initFormModel() {
		super.initFormModel();
		this.formModel.fields.approverId.validation = new Validation({
			required: () =>
				(this.state.awsPortOpeningRequest?.direction !== PortOpeningRequestsDirection.AccountToInternet)
		});

		this.formModel.fields.portOpeningRules.getValue =
			() => this.state.stateForm.ruleList.map(rule => new UpdateAWSPortOpeningRequestRule(rule));
	}

	async componentWillMount() {
		const id = parseInt(this.props.params.porId);
		const accId = parseInt(this.props.params.accId);
		await this.loadRequest(id, accId);
	}

	loadRequest = async (id: number, accId: any) => {
		this.setState(
			{
				loading: true
			},
			async () => {
				try {
					const request = new AWSPortOpeningRequest(await this.awsPortOpeningRequestService.getRequestById(id));

					const changeHistoryData = request.workflowInstance.workflowInstanceStates.map(workflowState => { 
						return (new ChangeHistory({
							objectType: ObjectType.AWSPortOpeningRequest,
							objectId: workflowState.workflowInstanceId,
							field: 'Comments',
							oldValue:'',
							newValue: workflowState.performedReason,
							responsibleUser: workflowState.performedByUser,
							responsibleUserRole: workflowState.performedByRoles,
							timestamp: workflowState.performedOnDate,
		
						}))}).reverse();
		

					this.setState({
						awsPortOpeningRequest: request,
						loading: false,
						changeHistoryData,
					}, this.resetStateForm);
					
					if (request.workflowInstance.currentWorkflowInstanceState.workflowState == (WorkflowState.Review)){
						const awsAccount = await this.getAWSAccount(accId);
						const virtualPrivateClouds = awsAccount.vpc;
						this.setState({awsAccount, virtualPrivateClouds});
					}
					else 
					{
						let vpcs:VirtualPrivateCloud[] = []

						let vpc : VirtualPrivateCloud;
						
						let rulesVpcs =[... new Set(request.awsPortOpeningRules.map(rule => [rule.region.name, rule.sourceIPs]))]
						
						for (var i = 0; i < rulesVpcs.length; i++){
							 vpc = {
								id: 0,
								arn: '',
								name: '',
								region: rulesVpcs[i][0],
								cidr: rulesVpcs[i][1]
							};
							vpcs.push(vpc)
						}
						
						const virtualPrivateClouds = vpcs;
						this.setState({virtualPrivateClouds});
					}
					
					this.setState({
						selectedFromExisting: this.state.stateForm.ruleList.map(rule => rule.name)
					});

				} catch (error) {
					const HTTP_FORBIDDEN_ERROR_CODE = 403;
					if (error.status === HTTP_FORBIDDEN_ERROR_CODE) {
						this.handleEndModal('You are not allowed to see this request.', 'error', 5000, '/awsPortOpeningRequest/connectedAWS');
					} else {						
						this.handleEndModal('The request could not be retrieved.', 'error', 10000, '/awsPortOpeningRequest/connectedAWS');
					}
				}
			}
		);
	};

	resetStateForm = () => {
		this.setState((prevState) => (
			{
				stateForm: this.stateFormFromRequest(prevState.awsPortOpeningRequest),
			}
		));
	};

	stateFormFromRequest(por: AWSPortOpeningRequest): StateForm {
		const currentState = this.state.awsPortOpeningRequest.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		if (currentState === WorkflowState.Review.value || currentState === WorkflowState.Draft.value) {
			const obj = super.stateFormFromRequest(por);
			return { ...obj, ruleList: obj.ruleList.map(rule => { return { ...rule, azureName: undefined }; }) };

		};
		return super.stateFormFromRequest(por);
	}

	setSelectedFromExisting = (rulesName: string[]) => {
		this.setState({
			selectedFromExisting: rulesName,
		});
	};

	actions = () => (
		!this.state.operationInProgress &&
		<TreeNavigation className="em-u-clickable">
			{this.state.awsPortOpeningRequest?.isEditable() &&
				<>
					<TreeNavigation.Item
						label="Edit"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={this.enableEdit}
					/>
					<br />
					<br />
				</>}
			{(this.state.awsPortOpeningRequest?.canHaveDOAGApproval() ||
				this.state.awsPortOpeningRequest?.canHaveTechnicalDecision() ||
				this.state.awsPortOpeningRequest?.canHaveAccountOwnerDecision(this.state.awsPortOpeningRequest.accountOwnerId, this.state.awsPortOpeningRequest?.custodianApproval)) &&
				<>
					<TreeNavigation.Item
						label="Approve"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('approve'); }}
					/>
					<br />
					<br />
					<TreeNavigation.Item
						label="Reject"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('reject'); }}
					/>
					<br />
					<br />
				</>}
			{this.state.awsPortOpeningRequest?.canHaveEscalateDecision() &&
				<>
					<TreeNavigation.Item
						label="Escalate"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('escalate'); }}
					/>
					<br />
					<br />
				</>}
			{this.state.awsPortOpeningRequest?.isCancellable() &&
				<>
					<TreeNavigation.Item
						label="Cancel Request"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('cancel'); }}
					/>
					<br />
					<br />
				</>}
			{(this.state.awsPortOpeningRequest?.canHaveTechnicalDecision() ||
				this.state.awsPortOpeningRequest?.canSendToReview(this.state.awsPortOpeningRequest.accountOwnerId, this.state.awsPortOpeningRequest?.workflowInstance)) &&
				<>
					<TreeNavigation.Item
						label="Send to Review"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('review'); }}
					/>
					<br />
					<br />
				</>
			}
			<TreeNavigation.Item
						label="Change History"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('history'); }}
					/>
		</TreeNavigation>
	);


	editActions = (size: ButtonSize) => (
		<ButtonBar className='edit-button-bar'>
			{!this.state.operationInProgress &&
				<>
					<ButtonBar.Item>
						<Button
							size={size}
							variant="secondary"
							onClick={this.cancelEdit}
						>
							<XFilledIcon
								size={size}
							/>
							<span>Cancel</span>
						</Button>
					</ButtonBar.Item>
					<ButtonBar.Item separator />
				</>}
			<ButtonBar.Item>
				<Button
					size={size}
					variant="primary"
					disabled={!this.formModel.isValid() || this.state.operationInProgress}
					onClick={this.handleSave}
				>
					{(this.state.operationInProgress) ?
						<>
							<Loader className="icon-loader em-c-icon--small" />
							<span>Loading...</span>
						</>
						:
						<>
							<SaveDiskIcon
								className='em-c-save-icon'
								size={size}
							/>
							<span>Finish Review</span>
						</>
					}
				</Button>
			</ButtonBar.Item>
		</ButtonBar>
	);

	enableEdit = async () => {
		this.setState({
			editMode: true,
		});
	};

	cancelEdit = () => {
		this.setState({ editMode: false });
		this.resetStateForm();
	};

	openDecisionModal = (type: 'approve' | 'escalate' | 'reject' | 'review' | 'cancel' | 'history') => {
		this.setState({
			decisionModalVisible: {
				[type]: true
			}
		});
	};

	closeDecisionModal = () => {
		this.setState({
			decisionModalVisible: {},
			modalComment: '',
		});
	};

	setEmbedAlertSuccess = (message) => {
		this.setState({
			submissionAlert: {
				variant: 'success',
				text: message,
				visible: true,
			}
		}, () => {
			setTimeout(this.handleSubmissionAlertClose, 5000);
		});
	};

	handleAlert = (variant, text) => {
		this.setState({
			submissionAlert: {
				visible: true,
				variant,
				text
			}
		});
	};

	decisionModalConfirmationField = () => ({
		label: 'Please, provide the reason.',
		value: this.state.modalComment,
		validation: {
			required: true,
		},
		onChange: (ev) => {
			this.setState({
				modalComment: ev.target.value,
			});
		},
	});

	handleSave = () => {
		const operation = async () => {
			if (this.requiresApproverVerification(this.state.awsPortOpeningRequest?.direction.name)) {
				const isVerified = await this.approverLevelVerified();
				if (!isVerified)
					return;
			}
			const model = this.formModel.create(UpdateAWSPortOpeningRequest);
			model.isDraft = false;
			await this.awsPortOpeningRequestService.update(this.state.awsPortOpeningRequest?.id, model);
			this.setState({
				editMode: false,
			});
		};

		this.handleOperationConfirmed({
			operation,
			inProgressMessage: 'Updating request...',
			successMessage: 'Your request was updated successfully.'
		});
	};

	handleApprove = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.awsPortOpeningRequestService.approve(this.state.awsPortOpeningRequest?.id);
			},
			inProgressMessage: 'Approving...',
			successMessage: 'Request approved sucessfully.'
		});
	};

	handleEscalate = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.awsPortOpeningRequestService.escalate(this.state.awsPortOpeningRequest?.id, {
					reviewReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Escalating...',
			successMessage: 'Request escalated sucessfully.'
		});
	};

	handleReject = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.awsPortOpeningRequestService.reject(this.state.awsPortOpeningRequest?.id, {
					rejectionReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Rejecting...',
			successMessage: 'Request rejected sucessfully.'
		});
	};

	handleReview = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.awsPortOpeningRequestService.sendToReview(this.state.awsPortOpeningRequest?.id, {
					reviewReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Sending to review...',
			successMessage: 'Request sent to review successfully.',
		});
	};

	handleCancellation = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.awsPortOpeningRequestService.cancel(this.state.awsPortOpeningRequest?.id, {
					cancellationReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Cancelling...',
			successMessage: 'Request cancelled successfully.',
		});
	};

	handleOperationConfirmed = async ({
		operation,
		inProgressMessage,
		successMessage,
	}) => {
		this.setSubmissionAlertInProgress(inProgressMessage);
		this.closeDecisionModal();
		try {
			await operation();
			await this.loadRequest(this.state.awsPortOpeningRequest?.id, this.state.awsPortOpeningRequest.accountId);
			this.setEmbedAlertSuccess(successMessage);
		} catch (err) {
			const HTTP_INTERNAL_SERVER_ERROR_CODE = 500;
			if ((err.status === HTTP_INTERNAL_SERVER_ERROR_CODE) && err?.response?.data.message) {
				await this.loadRequest(this.state.awsPortOpeningRequest?.id, this.state.awsPortOpeningRequest.accountId);
			}
			this.setSubmissionAlertError(err?.response?.data.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
				modalComment: '',
			});
		}
	};

	render() {
		return (
			((!this.state.loading &&
				this.state.awsPortOpeningRequest != null &&
				this.state.awsPortOpeningRequest?.isEditable() &&
				this.state.awsPortOpeningRequest?.workflowInstance.currentWorkflowInstanceState.workflowState.value === WorkflowState.Draft.value)
			) ?
				<AWSPortOpeningRequestsCreate
					draftRequest={this.state.awsPortOpeningRequest}
				/>
				:
				<>
					<WorkflowDetailWrapper
						title={`AWS Port Opening Request #${this.state.awsPortOpeningRequest?.id}`}
						workflowInstance={this.state.awsPortOpeningRequest?.workflowInstance}
						loading={this.state.loading}
						linkTree={this.getLinkTree()}
						actions={this.state.editMode ? this.editActions('small') : this.actions()}
						fromPOR
					>
						{this.state.submissionAlert.visible &&
							<Alert
								variant={this.state.submissionAlert.variant}
								onClose={this.handleSubmissionAlertClose}
							>
								{this.state.submissionAlert.text}
							</Alert>}
						<Grid variant="halves">
							<Grid.Item>
								<ReadonlyField
									label="Account's Name or Id"
									text={this.state.awsPortOpeningRequest?.accountName}
									copy
								/>
							</Grid.Item>
						</Grid>
						<Grid variant="3-up">
							<Grid.Item>
								<PORTypeInput
									scope={{
										value: this.state.awsPortOpeningRequest?.direction.name,
										disabled: true
									}}
								/>
							</Grid.Item>
							<Grid.Item>
								<EnvironmentInput
									{...this.stateFormHandler().environment}
									disabled={this.state.operationInProgress || !this.state.editMode}
								/>
							</Grid.Item>
							{this.state.awsPortOpeningRequest?.implementationTicket() !== '' &&
								<Grid.Item>
									<ReadonlyField
										label="Implementation Ticket"
										text={this.state.awsPortOpeningRequest?.implementationTicket()}
										copy
									/>
								</Grid.Item>}
						</Grid>
						<Grid variant="2-up">
							<Grid.Item>
								<UserLookup
									label="Technical contact"
									{...this.stateFormHandler().contact}
									disabled={this.state.operationInProgress || !this.state.editMode}
								/>
							</Grid.Item>
							<Grid.Item>
								<ApproverInput
									{...this.stateFormHandler().approver}
									disabled={this.state.operationInProgress || !this.state.editMode}
									scope={this.state.awsPortOpeningRequest?.direction.name}
								/>
							</Grid.Item>
						</Grid>
						<Grid>
							<Grid.Item>
								<ProgressBarBig
									workflowState={this.state.awsPortOpeningRequest?.workflowInstance.currentWorkflowInstanceState.workflowState}
									por={this.state.awsPortOpeningRequest}
								/>
							</Grid.Item>
						</Grid>
						<Grid />
						<br></br>
						<br></br>
						<TextAreaField
							label="Business Justification"
							{...this.stateFormHandler().businessJustification}
							disabled={this.state.operationInProgress || !this.state.editMode}
						/>
						<Grid />
						<AWSRulesTable
							{...this.stateFormHandler().ruleset}
							porDirection={this.state.awsPortOpeningRequest?.direction}
							readonly={this.state.operationInProgress || !this.state.editMode}
							virtualPrivateClouds={this.state.virtualPrivateClouds}
							loadingImplementedRules={false}
							currentWorkflowState={this.state.awsPortOpeningRequest?.workflowInstance.currentWorkflowInstanceState.workflowState.value}
							fromDetails
							selectedFromExisting={this.state.selectedFromExisting}
							setSelectedFromExisting={this.setSelectedFromExisting}
						/>
						{this.state.editMode && this.editActions('medium')}
					</WorkflowDetailWrapper>
					<AlertModal
						{...this.state.endModal}
						willTimeout={false}
						onClose={this.endAndRedirect}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.approve}
						title={`Approve AWS POR #${this.state.awsPortOpeningRequest?.id}`}
						question="Are you sure you want to approve this request?"
						onConfirm={this.handleApprove}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.escalate}
						title={`Escalate AWS POR #${this.state.awsPortOpeningRequest?.id}`}
						question={`Are you sure you want to escalate to 
							${this.state.awsPortOpeningRequest?.workflowInstance.currentWorkflowInstanceState.workflowState.value == WorkflowState.PendingTechnicalApproval.value
								? 'Network Policy Solution Architect'
								: 'Cyber Security'} this request?`}
						confirmationField={this.decisionModalConfirmationField()}
						onConfirm={this.handleEscalate}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.cancel}
						title={`Cancel AWS POR #${this.state.awsPortOpeningRequest?.id}`}
						question="Are you sure you want to cancel this request? This action cannot be undone."
						confirmationField={this.decisionModalConfirmationField()}
						confirmButton={{
							label: 'Cancel Request',
							props: {
								variant: 'primary',
								color: 'negative'
							}
						}}
						onConfirm={() => this.handleCancellation()}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.reject}
						title={`Reject AWS POR #${this.state.awsPortOpeningRequest?.id}`}
						question="Are you sure you want to reject this request?"
						confirmationField={this.decisionModalConfirmationField()}
						onConfirm={() => this.handleReject()}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.review}
						title={`Confirm Revision for AWS POR #${this.state.awsPortOpeningRequest?.id}`}
						question="Are you sure you want to send this request to review?"
						confirmationField={this.decisionModalConfirmationField()}
						onConfirm={() => this.handleReview()}
						onCancel={this.closeDecisionModal}
					/>
					<ChangeHistoryModal
						visible={this.state.decisionModalVisible.history}
						onClose={this.closeDecisionModal}
						changeHistory={this.state.changeHistoryData}
					/>
				</>
		);
	}
}

const AWSPortOpeningRequestsDetail = () => {

	return <AWSPortOpeningRequestsDetailWithParams params={useParams()} navigate={useNavigate()} />;
};

export default AWSPortOpeningRequestsDetail;