import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LightBulbIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M9.5 16h-3a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM7 15h2v-1H7zM10 12.5H9v-1.21a.5.5 0 0 1 .364-.481 5 5 0 1 0-2.729 0A.5.5 0 0 1 7 11.29v1.21H6v-.844a6 6 0 1 1 4 0z"
          fill="currentColor"
        />
        <path
          d="M5 6.5H4V6a4 4 0 0 1 4-4h.5v1H8a3 3 0 0 0-3 3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M31 38h-2v-3.284a1 1 0 0 1 .7-.954A16.168 16.168 0 0 0 41 18.233a16.347 16.347 0 0 0-5.452-12.2A15.736 15.736 0 0 0 23.12 2.109a16.288 16.288 0 0 0-2.82 31.653 1 1 0 0 1 .7.954V38h-2v-2.566A18.29 18.29 0 0 1 22.89.123a17.7 17.7 0 0 1 13.988 4.416A18.347 18.347 0 0 1 31 35.434zM30 48H20a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-9-2h8v-4h-8z"
          fill="currentColor"
        />
        <path
          d="M14.5 19.5h-2v-1a12.013 12.013 0 0 1 12-12h1v2h-1a10.011 10.011 0 0 0-10 10z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M15.353 18.118h-2v-1.29a1 1 0 0 1 .7-.955 7 7 0 1 0-4.111 0 1 1 0 0 1 .7.955v1.29h-2v-.584a9 9 0 1 1 6.705 0zM15 24H9a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1zm-5-2h4v-1h-4z"
          fill="currentColor"
        />
        <path
          d="M9 11H7v-1a5.868 5.868 0 0 1 6-6h1v2h-1a3.869 3.869 0 0 0-4 4z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LightBulbIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LightBulbIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default LightBulbIcon;
