import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router';
import {
	ProgressBar,
	Alert
} from 'react-unity';
import SectionHomeWrapper from '../../common/wrappers/SectionHomeWrapper';
import SubscriptionRequestsService from '../../../services/SubscriptionRequestsService';
import SubAdminConsentTable from './SubAdminConsentTable';
import SelectField from '../../common/form-controls/SelectField';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';

const SubAdminConsent = () => {

	const params = useParams();
	const subscriptionId = parseInt(params.id);
	const subscriptionService = new SubscriptionRequestsService();
	const [isLoadingResourceGroups, setIsLoadingResourceGroups] = useState(false);
	const [isLoadingResources, setIsLoadingResources] = useState(false);

	const [resources, setResources] = useState([]);
	const [resourceGroups, setResourceGroups] = useState([]);
	const [selectedResourceGroup, setSelectedResourceGroup] = useState(null);
	const [selectedResourceGroupName, setSelectedResourceGroupName] = useState('Select a Resource Group');
	const [ alert, setAlertBanner ] = useState<AlertBanner>({
		visible: false,
        variant: 'default'
	});

	useEffect(() => {
		setIsLoadingResourceGroups(true);
		subscriptionService.getAllResourceGroupsInSubscription(subscriptionId).then((resourceGroups) => {
			setResourceGroups(resourceGroups);
			setIsLoadingResourceGroups(false);
		});
	}, []);

	useEffect(() => {
		if (selectedResourceGroup) {
			setIsLoadingResources(true);
			subscriptionService
				.getAllResourcesWithIdentities(subscriptionId, selectedResourceGroup?.id)
				.then((resources) => {
					setResources(resources);
					setIsLoadingResources(false);
				});
		}
	}, [selectedResourceGroup]);

	const handleResourceGroupChange = (e) => {
		setSelectedResourceGroup(resourceGroups.find((rg) => rg.id === e.target.value));
		setSelectedResourceGroupName(e.target.value);
	};

	const resourceGroupOptions = () => {
		return resourceGroups
			.map((resourceGroup) => ({ value: resourceGroup.id, text: resourceGroup.name }));
	};

	return (
		<>
			<SectionHomeWrapper title="Admin Consent for Subscription Resources">
				{alert.visible &&
					<Alert
						variant={alert.variant}
						onClose={() => {
							setAlertBanner({
								visible: false,
							});
						}}
					>
						{alert.text}
					</Alert>
				}
				{isLoadingResourceGroups ? (
					<ProgressBar indeterminate hideValueLabel label="Loading subscription resource groups..." />
				) : (
					<>
						<div>
							<SelectField
								label="Resource Group"
								options={resourceGroupOptions()}
								className="full-width"
								// disabled={this.props.disabled || this.state.loading}
								onChange={handleResourceGroupChange}
								value={selectedResourceGroupName}
							/>
						</div>
					</>
				)}
				{selectedResourceGroup &&
					(isLoadingResources ? (
						<ProgressBar indeterminate hideValueLabel label="Loading resources of resource group..." />
					) : (
						<SubAdminConsentTable subscriptionId={subscriptionId} resources={resources} setAlertBanner={setAlertBanner}/>
					))}
			</SectionHomeWrapper>
		</>
	);
};

export default SubAdminConsent;
