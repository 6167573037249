import React from 'react';
import { WorkflowState } from '../../../models/enums/WorkflowState';
import { DoNotDecryptRequestsDirection } from '../../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import WorkflowStateBadge from '../../common/WorkflowStateBadge';
import './ProgressBarBig.css';

interface ProgressBarBigProps {
	workflowState: WorkflowState;
	dndrSevenDayExemption: boolean;
}

const ProgressBarBig = ({ workflowState, dndrSevenDayExemption }: ProgressBarBigProps) => {
	const hasProgressBar = (): boolean => {
		const noProgressArray = [WorkflowState.Cancelled, WorkflowState.Rejected];
		return !noProgressArray.some((nws) => nws.value === workflowState.value);
	};

	const activeSOApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingForCyberApproval.value:
			case WorkflowState.PendingForGatewayPolicyTeamApproval.value:
			case WorkflowState.PendingCloudArchitectApproval.value:
			case WorkflowState.Error.value:
                return "big-back-blue";
            default:
                return "";
		}
	};

	const activeSevenDaySOApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingCloudArchitectApproval.value:
			case WorkflowState.Error.value:
                return "big-back-blue";
            default:
                return "";
		}
	};

    const activeCyberApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.PendingForGatewayPolicyTeamApproval.value:
			case WorkflowState.Error.value:
                return "big-back-blue";
            default:
                return "";
		}
	};

	const activeSevenDayExemptionApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.Error.value:
                return "big-back-blue";
            default:
                return "";
		}
	};

    const activeTechnicalApproval = () => {
		switch (workflowState.value) {
			case WorkflowState.AwaitingImplementation.value:
			case WorkflowState.Completed.value:
			case WorkflowState.Error.value:
                return "big-back-blue";
            default:
                return "";
		}
	};

	return (
		<>
			<div className="big-progress-bar__title">
				<span className="em-c-field__label em-c-field__label--margin">Status</span>
				<WorkflowStateBadge workflowState={workflowState} />
			</div>
			{hasProgressBar() && (
				<div className="big-progress-bar">
					<div className="big-progress-bar__line" />
					{dndrSevenDayExemption === false ? (
						<>
							<div className="big-progress-bar__circle-with-title big-left-0">
								<div className={`big-progress-bar__circle ${activeSOApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">SO Approval</div>
							</div>
							<div className="big-progress-bar__circle-with-title big-left-50">
								<div className={`big-progress-bar__circle ${activeCyberApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">Cyber Approval</div>
							</div>
							<div className="big-progress-bar__circle-with-title big-left-100">
								<div className={`big-progress-bar__circle ${activeTechnicalApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">Gateway Approval</div>
							</div>
						</>
					) : (
						<>
							<div className="big-progress-bar__circle-with-title big-left-0">
								<div className={`big-progress-bar__circle ${activeSOApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">SO Approval</div>
							</div>
							<div className="big-progress-bar__circle-with-title big-left-100">
								<div className={`big-progress-bar__circle ${activeTechnicalApproval()}`}>✓</div>
								<div className="big-progress-bar__circle--title">Technical Approval</div>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default ProgressBarBig;
