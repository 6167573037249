import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button, CaretDownIcon } from "../index";
import CheckboxDropdownItem from "./CheckboxDropdownItem";

/**
 * A multiselect dropdown menu.
 * @visibleName Checkbox dropdown
 * */
function CheckboxDropdown(props) {
  const { className, label, buttonProps, children, ...other } = props;
  const [isOpen, setIsOpen] = useState(false);
  const checkboxDropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (!checkboxDropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  return (
    <div
      className={clsx("em-c-dropdown-check", "em-js-dropdown-check", {
        "em-is-active": isOpen
      })}
      ref={checkboxDropdownRef}
      {...other}
    >
      <Button
        className="em-c-btn--is-dropdown em-js-dropdown-trigger"
        onClick={() => setIsOpen(prev => !prev)}
        {...buttonProps}
      >
        <span className="em-c-btn__text">{label}</span>
        <CaretDownIcon size="small" />
      </Button>
      <div className="em-c-dropdown-check__panel">
        <ul className="em-c-dropdown-check__list">{children}</ul>
      </div>
    </div>
  );
}

CheckboxDropdown.Item = CheckboxDropdownItem;

CheckboxDropdown.propTypes = {
  /** Class to append to the outer element */
  className: PropTypes.string,
  /** Title of the dropdown */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Props to pass to the Button component */
  buttonProps: PropTypes.object,
  /** Dropdown items */
  children: PropTypes.node
};

CheckboxDropdown.defaultProps = {
  className: "",
  label: "",
  buttonProps: {},
  children: []
};

export default CheckboxDropdown;
