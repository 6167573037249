import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import MediaBlockBodyHeadline from "./MediaBlockBodyHeadline";
import MediaBlockBodyDescription from "./MediaBlockBodyDescription";

const MediaBlockBody = props => {
  const { children, className, ...other } = props;

  return (
    <div className={clsx("em-c-media-block__body", className)} {...other}>
      {children}
    </div>
  );
};

MediaBlockBody.Headline = MediaBlockBodyHeadline;
MediaBlockBody.Description = MediaBlockBodyDescription;

MediaBlockBody.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

MediaBlockBody.defaultProps = {
  className: "",
  children: null
};

export default MediaBlockBody;
