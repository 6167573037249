import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PowerOffIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 14h-13A1.5 1.5 0 0 1 0 12.5v-8A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v8a1.5 1.5 0 0 1-1.5 1.5zM1.5 4a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M8.5 12h-6a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5zM3 11h5V6H3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M4 7h1v3H4z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40 39H8a7.008 7.008 0 0 1-7-7V18a7.008 7.008 0 0 1 7-7h32a7.008 7.008 0 0 1 7 7v14a7.008 7.008 0 0 1-7 7zM8 13a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h32a5.006 5.006 0 0 0 5-5V18a5.006 5.006 0 0 0-5-5z"
          fill="currentColor"
        />
        <path
          d="M22 35H8a3 3 0 0 1-3-3V18a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3zM8 17a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V18a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M9 19h2v12H9z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 20H4a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v9a4 4 0 0 1-4 4zM4 5a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2z"
          fill="currentColor"
        />
        <path
          d="M12 17H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2zM5 8v7h7V8z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M6 9h2v5H6z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PowerOffIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PowerOffIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PowerOffIcon;
