import * as React from 'react';
import { SelectProps, Select, Loader } from 'react-unity';
import './SelectField.css';
import { Option } from '../../../utils/Option';
import { AbstractSelectableProps, AbstractSelectableState, AbstractSelectable } from './AbstractSelectable'; 

interface Props extends AbstractSelectableProps {
	fullWidth?: boolean;
	isLoading?: boolean;
}

export default class SelectField
	extends AbstractSelectable<SelectProps, Props, AbstractSelectableState> {
	
	constructor(props) {
		super(props);
		this.state = {
			options: [],
		}
	}

	render() {
		return (
			<>
				{!this.props.isLoading ?
					<Select
						{...this.props}
						valid={this.isValid()}
						error={!this.isValid()}
						value={this.props.value != null ? this.props.value : ''}
						note={this.nonValidMessage() || this.props.note}
					>
						<option value='' hidden />
						{this.state.options.map((op: Option) => (
							<option className='em-c-field-option' key={op.value} value={op.value}>
								{op.text}
							</option>
						))}
					</Select>
					:
					<Select
						{...this.props}
						valid={this.isValid()}
						error={!this.isValid()}
						value={this.props.value != null ? this.props.value : ''}
						note={this.nonValidMessage() || this.props.note}
					>
						<Loader className="em-u-margin em-c-icon--medium" />
					</Select>
				}
			</>
		);
	}
}