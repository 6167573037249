import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CaretDownIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3.621 5h8.758a.66.66 0 0 1 .545 1.033l-4.379 6.4a.66.66 0 0 1-1.089 0l-4.379-6.4A.66.66 0 0 1 3.621 5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M10.731 13.151h26.538a2 2 0 0 1 1.651 3.129l-13.269 19.4a2 2 0 0 1-3.3 0L9.08 16.28a2 2 0 0 1 1.651-3.129z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M5.365 7h13.27a1 1 0 0 1 .825 1.565l-6.635 9.7a1 1 0 0 1-1.651 0l-6.634-9.7A1 1 0 0 1 5.365 7z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CaretDownIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CaretDownIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CaretDownIcon;
