import { getToken } from '../authentication/authModule';
import axios from 'axios';

export default class ApiClient {
	protected baseUrl: string;

	private client: any;

	constructor() {
		this.client = axios.create();
		this.baseUrl = '/api/v1.0';
	}

	request = async<T>(method: string, resourceUrl: string, body?: any) => {
		const token = await getToken();
		const request = await this.client(resourceUrl, {
			method,
			data: JSON.stringify(body),
			timeout:200000,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				'Content-Security-Policy':  "frame-ancestors 'none'"
			},
		});
		return request.data as T;
	};

	async get<T>(resourceUrl: string) {
		return this.request<T>('GET', `${this.baseUrl}/${resourceUrl}`);
	}

	async post<T>(resourceUrl: string, body: any) {
		return this.request<T>('POST', `${this.baseUrl}/${resourceUrl}`, body);
	}

	async put<T>(resourceUrl: string, body: any) {
		return this.request<T>('PUT', `${this.baseUrl}/${resourceUrl}`, body);
	}

	async patch<T>(resourceUrl: string, body: any) {
		return this.request<T>('PATCH', `${this.baseUrl}/${resourceUrl}`, body);
	}

	async delete<T>(resourceUrl: string, body: any) {
		return this.request<T>('DELETE', `${this.baseUrl}/${resourceUrl}`, body);
	}
}