import { AlertVariant } from 'react-unity';
import moment from 'moment';
import React from 'react';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import SubscriptionRequestsService from '../../services/SubscriptionRequestsService';
import PolicyExemptionRequestService from '../../services/PolicyExemptionRequestService';
import FormModel from '../../utils/FormModel';
import { AbstractFormState, IStateForm, AbstractForm } from '../common/AbstractForm';
import { EndModal } from '../common/EndModal';
import { LinkTreeItem } from '../common/LinkTree';
import { PolicyDefinitionResponse } from '../../models/viewModels/PolicyExemptionRequests/PolicyDefinitionResponse';
import { PolicyAssignmentResponse } from '../../models/viewModels/PolicyExemptionRequests/PolicyAssignmentResponse';
import { Environment } from '../../models/enums/Environment';
import PolicyExemptionRequest from '../../models/entities/PolicyExemptionRequest';
import { Params } from 'react-router-dom';

export interface AbstractPolicyExemptionRequestFormProps  {
	navigate?: any;
	params?: Params<string>;
 }


export interface AbstractPolicyExemptionRequestFormState extends AbstractFormState<IPolicyExemptionRequestStateForm> {
	subscription: SubscriptionRequest;
	endModal: EndModal;
	submissionWarned: boolean;
}

export interface IPolicyExemptionRequestStateForm extends IStateForm {
    displayName: string;
	businessJustification: string;
	riskAssessmentLink: string;
    durationRequested: string;
	policyDefinitions: PolicyDefinitionResponse[];
    policyAssignment: PolicyAssignmentResponse;
	resourceGroup: string;
	resource: string;
}


export abstract class AbstractPolicyExemptionRequestForm<
    Props extends AbstractPolicyExemptionRequestFormProps,
	State extends AbstractPolicyExemptionRequestFormState
	> extends AbstractForm<Props, State> {

        subscriptionRequestService: SubscriptionRequestsService;

        policyExemptionRequestService: PolicyExemptionRequestService;
        constructor(props) {
        	super(props);
        	this.subscriptionRequestService = new SubscriptionRequestsService();
        	this.policyExemptionRequestService = new PolicyExemptionRequestService();
        }

        async getSubscription(id: number) {
        	const subscription = new SubscriptionRequest(await this.subscriptionRequestService.getById(id));
        	if (!subscription.canSeePolicyExemptionRequests()) {
        		this.handleEndModal('You are not allowed to perform this action.', 'error', 5000, '/subscriptions');
        	}
        	return subscription;
        }

        getLinkTree = (): LinkTreeItem[] => {
        	return this.state.subscription ? [
        		{ text: 'Subscriptions', to: '/subscriptions' },
        		{ text: this.state.subscription?.createdSubscriptionName, to: `/subscriptions/${this.state.subscription?.id}` },
        		{ text: 'Policy Exception Requests', to: `/subscriptions/${this.state.subscription?.id}/policyExemptionRequests` }
        	] : [];
        };

        handleEndModal = (text: string, variant: AlertVariant, timeout: number, link: string) => {
        	this.setState({
        		endModal: {
        			visible: true,
        			text,
        			variant,
        			redirectionLink: link
        		}
        	},
        	() => {
        		setTimeout(() => {
        			this.endAndRedirect();
        		}, timeout);
        	});
        };


        // TODO: Move to another component
        endAndRedirect = () => {
        	const link = this.state.endModal.redirectionLink;
        	this.setState({
        		endModal: { visible: false }
        	},
        	() => {
        		this.props.navigate(link);
        	});
        };

        riskAssessmentIsRequired(): boolean {
        	return this.state.subscription.environment.value !== Environment.Development.value;
        }

        initFormModel() {
        	this.formModel = new FormModel({
        		displayName: {
        			getValue: () => this.state.stateForm.displayName,
        			validation: {
        				required: true,
        				rules: [
        					{
        						assert: () => {
        							const azureName = this.getExemptionAzureName();
        							return azureName.length <= 64;
        						},
        						message: () : any => {
        							return (<>
										Exception Name: {this.getExemptionAzureName()}
        								<br />
									 	The Exception Name must contain less than 65 characters.
        							</>);
        						},
        					},
        				],
        			}
        		},
        		businessJustification: {
        			getValue: () => this.state.stateForm.businessJustification,
        			validation: {
        				required: true,
        				rules: [
        					{
        						assert: () =>
                                    this.state.stateForm.businessJustification?.trim().length > 4,
        						message: 'Justification must contain at least 5 characters.',
        					},
        				],
        			},
        		},
        		riskAssessmentLink: {
        			getValue: () => this.state.stateForm.riskAssessmentLink,
        			validation: {
        				required: () => this.riskAssessmentIsRequired()
        			}
        		},
        		durationRequested: {
        			getValue: () => {
        				const value = this.state.stateForm?.durationRequested;
        				return value;
        			},
        			validation: {
        				required: true,
					}
        		},
        		policyDefinitions: {
        			getValue: () => this.state.stateForm.policyDefinitions,
        			validation: {
        				required: true
        			},
        		},
        		policyAssignment: {
        			getValue: () => this.state.stateForm.policyAssignment,
        			validation: {
        				required: true
        			}
        		},
        		resourceGroup: {
                    getValue: () => this.state.stateForm.resourceGroup,
                    validation: {
                        required: false
                    }
        		},
        		resource: {
                    getValue: () => this.state.stateForm.resource,
                    validation: {
                        required: false
                    }
        		}
        	});
        }

    stateFormHandler = () => this.getStateFormHandlers();

    getStateFormHandlers() {
    	return {
    		displayName: {
    			label: 'Exception Description',
    			value: this.state.stateForm.displayName,
    			validation: this.formModel.fields.displayName.validation,
    			onChange: (event) => {
    				this.handleStateFormChange('displayName', event.target.value);
    			}
    		},
    		businessJustification: {
    			label: 'Business Justification',
    			value: this.state.stateForm.businessJustification,
    			validation: this.formModel.fields.businessJustification.validation,
    			onChange: (event) => {
    				this.handleStateFormChange('businessJustification', event.target.value);
    			}
    		},
    		riskAssessmentLink: {
    			label: 'Risk Assessment Link',
    			value: this.state.stateForm.riskAssessmentLink,
    			validation: this.formModel.fields.riskAssessmentLink.validation,
    			onChange: (event) => {
    				this.handleStateFormChange('riskAssessmentLink', event.target.value);
    			}
    		},
    		durationRequested: {
				label: 'Duration Requested',
				value: this.state.stateForm.durationRequested,
				options:
					[
						{ text: 'Temporary', value: "temporary" },
						{ text: "60 days", value: "60days" },
						{ text: "90 days", value: "90days" },
						{ text: "6 months", value: "6months" },
						{ text: "9 months", value: "9months" },
						{ text: "12 months", value: "12months" },
					],
				onChange: (event) => {
					this.handleStateFormChange('durationRequested', event.target.value);
				}
			},
    		policyDefinitions: {
    			value: this.state.stateForm.policyDefinitions,
    			onChange: (policyDefinitions: PolicyDefinitionResponse[]) => {
    				this.handleStateFormChange('policyDefinitions', policyDefinitions);
    			}
    		},
    		policyAssignment: {
    			value: this.state.stateForm.policyAssignment,
    			onChange: (policyAssignment: PolicyAssignmentResponse) => {
    				this.handleStateFormChange('policyAssignment', policyAssignment);
    				this.handleStateFormChange('policyDefinitions', []);
    			}
    		},
    		resourceGroup: {
                value: this.state.stateForm.resourceGroup,
                validation: this.formModel.fields.resourceGroup.validation,
    			onChange: (resourceGroup: string) => {
                    this.handleStateFormChange('resourceGroup', resourceGroup);
    				this.handleStateFormChange('resource', null);
    			}
    		},
    		resource: {
                value: this.state.stateForm.resource,
                validation: this.formModel.fields.resource.validation,
    			onChange: (resource: string) => {
                    this.handleStateFormChange('resource', resource);
    			}
    		}
    	};
    }

    getExemptionAzureName(): string {

    	let azureName = `SSP-xxxxx-${this.state.stateForm.displayName}`;
    	const scopes = this.state.stateForm.policyAssignment?.properties.scopeDisplayName.split('/');

    	if (scopes?.length > 1) {
    		const longestScope = scopes.sort(function (a, b) { return b.length - a.length; })[0];
    		azureName += `-${longestScope}`;
    	}

    	return azureName;
    }
    
    stateFormFromRequest(per: PolicyExemptionRequest): IPolicyExemptionRequestStateForm {
    	return {
    		displayName: per.displayName,
    		businessJustification: per.businessJustification,
    		riskAssessmentLink: per.riskAssessmentLink,
    		durationRequested: per.durationRequested,
    		policyDefinitions: [],
    		policyAssignment: null,
    		resourceGroup: per.resourceGroup,
    		resource: per.resource 
    	};
    }

}

