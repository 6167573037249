import UpdateAWSPortOpeningRequestRule from './UpdateAWSPortOpeningRequestRule';

export default class UpdateAWSPortOpeningRequest {

	portOpeningRules!: UpdateAWSPortOpeningRequestRule[];

	contactId!: string;

	isDraft: boolean;

	environment: number;

	businessJustification!: string;

	comments!: string;

	approverId!: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}