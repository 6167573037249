import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const GasPump = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M11.6,3H7.5C7.2,3,7,3.2,7,3.5v3C7,6.8,7.2,7,7.5,7h4.1c0.3,0,0.5-0.2,0.5-0.5v-3C12.1,3.2,11.9,3,11.6,3z
		 M11.1,6H8V4h3.1V6z"
          />
          <path
            fill="currentColor"
            d="M14,15l0-12.8C14,1,13,0,11.7,0H7.3C6,0,5,1,5,2.2L5,15H4v1h9h1h1v-1H14z M6,15L6,2.2C6,1.5,6.6,1,7.3,1
		h4.4C12.4,1,13,1.5,13,2.2L13,15H6z"
          />
          <path
            fill="currentColor"
            d="M4,10H3.5C2.7,10,2,9,2,8.2c0.1-1.7,0.2-3.4,0.4-4.7c0-0.3,0.2-0.4,0.2-0.4C2.8,3,3.1,3,3.3,3.1l0.5,0.2
		l0.4-0.9L3.8,2.2C3.2,2,2.6,2,2.2,2.2C1.8,2.4,1.5,2.8,1.4,3.3C1.2,4.7,1.1,6.5,1,8.2C1,9.5,2.1,11,3.5,11H4V10z"
          />
          <rect x="8" y="10" fill="currentColor" width="3" height="1" />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M36,8H20c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V9C37,8.4,36.6,8,36,8z M35,16H21v-6h14V16
		z"
          />
          <path
            fill="currentColor"
            d="M41,46V6.5C41,2.9,38.1,0,34.5,0H21.5C17.9,0,15,2.9,15,6.5V46h-2v2h30v-2H41z M17,46V6.5C17,4,19,2,21.5,2
		h13.1C37,2,39,4,39,6.5V46H17z"
          />
          <path
            fill="currentColor"
            d="M13,31h-1c-3.3,0-6-2.2-6-4.9c0.4-5.6,0.8-11.3,1.4-15.6c0.1-0.8,0.5-1.4,1.1-1.7c0.8-0.4,2-0.4,3.1,0.1
		l0.9,0.4l0.8-1.8l-0.9-0.4c-1.7-0.7-3.5-0.7-4.9,0c-0.8,0.4-1.8,1.3-2.1,3.1C4.8,14.6,4.4,20.4,4,26.1c0,3.8,3.6,6.9,8,6.9h1V31z"
          />
          <rect x="22" y="31" fill="currentColor" width="12" height="2" />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M17,4h-6c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1V5C18,4.4,17.6,4,17,4z M16,8h-4V6h4V8z"
          />
          <path
            fill="currentColor"
            d="M21,22V3.3C21,1.5,19.3,0,17.3,0h-6.5C8.7,0,7,1.5,7,3.3V22H6v2h16v-2H21z M9,3.3C9,2.6,9.8,2,10.7,2h6.5
		C18.2,2,19,2.6,19,3.3V22H9V3.3z"
          />
          <path
            fill="currentColor"
            d="M6,14.1H5c-1.1,0-2-0.7-2-1.6c0.2-2.5,0.3-5.1,0.6-7c0-0.3,0.2-0.3,0.2-0.4C3.9,5,4.2,5,4.6,5.2l0.9,0.4
		l0.8-1.8L5.4,3.4C4.5,3,3.6,3,2.8,3.4c-0.7,0.4-1.1,1-1.2,1.8c-0.3,2-0.5,4.6-0.6,7.2c0,2,1.8,3.7,4,3.7h1V14.1z"
          />
          <rect x="11" y="14" fill="currentColor" width="6" height="2" />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

GasPump.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

GasPump.defaultProps = {
  size: "medium",

  className: ""
};

export default GasPump;
