import React, { useState, useEffect } from 'react';
import {
    Table,
    Button,
} from 'react-unity';
import TAP from '../../models/entities/TAP';
import moment from 'moment';
import './ApprovalsTAPTableRow.css';
import TAPApprovals from '../TemporaryAccessPass/components/TAPApprovals';
import TemporaryAccessPasswordService from '../../services/TemporaryAccessPasswordService';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';


interface ApprovalsTAPTableRowProps {
    tap: TAP;
    setEndModal: any;
    temporaryAccessPasswordService: TemporaryAccessPasswordService;
}

const ApprovalsTAPTableRow = ({ tap, setEndModal, temporaryAccessPasswordService }: ApprovalsTAPTableRowProps) => {

    const [loading, setLoading] = useState<boolean>(false);

    const handleCompleteTAP = async (tapId: number, approve: boolean) => {
        try {
            setLoading(true);
            setEndModal({
                visible: true,
                text: approve ? `Approving...` : 'Rejecting...',
                variant: 'default'
            });
            const response = await temporaryAccessPasswordService.completeTap(tapId, approve);
            setEndModal({ visible: false });
        }
        catch (err) {
            setEndModal({
                visible: true,
                text: `An error occurred when approving the TAP request. Please try again later.`,
                variant: 'error'
            });
        }
        setLoading(false);
    }

        return (
            <tr>
                <Table.Body.Cell className='wrapper'>
                            {moment(moment.utc(tap.createdTime)).local().format('DD/MM/YYYY, h:mm:ss a')}
                </Table.Body.Cell>
                <Table.Body.Cell className='em-u-text-align-center'>
                            {tap.requestor.displayName}
                </Table.Body.Cell>
                <Table.Body.Cell className='center-align'>
                <Button.Group>
                    <Button
                        size='small'
                        color='positive'
                        variant="primary"
                        disabled={(authenticatedUser.isInRole(UserRole.ApprovalsViewer) && tap.approver.id !== authenticatedUser.userId)}
                        onClick={() => handleCompleteTAP(tap.id, true)}
                    >Accept
                    </Button>
                    <Button
                        size='small'
                        color='negative'
                        variant="primary"
                        disabled={(authenticatedUser.isInRole(UserRole.ApprovalsViewer) && tap.approver.id !== authenticatedUser.userId)}
                        onClick={() => handleCompleteTAP(tap.id, false)}
                    >Reject
                    </Button>
                </Button.Group>
                </Table.Body.Cell>

            </tr>
        );
    }

export default ApprovalsTAPTableRow;