import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronDownIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.4 11.23a1.153 1.153 0 0 1-.818-.338l-5.5-5.633.716-.7 5.492 5.628a.16.16 0 0 0 .222 0L14 4.561l.716.7-5.488 5.627a1.162 1.162 0 0 1-.828.342z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 34.5a3.973 3.973 0 0 1-2.828-1.172L6.086 18.243 7.5 16.829l15.086 15.085a2.047 2.047 0 0 0 2.828 0L40.5 16.829l1.414 1.414-15.086 15.085A3.973 3.973 0 0 1 24 34.5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.4 17.137a1.988 1.988 0 0 1-1.411-.584l-8.5-8.709 1.432-1.4 8.491 8.7 8.474-8.7 1.432 1.4-8.49 8.7a2.012 2.012 0 0 1-1.428.593z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronDownIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronDownIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronDownIcon;
