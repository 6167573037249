import React, {useEffect} from 'react';
import {
	Field as UnityField,
	Typeahead,
} from 'react-unity';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { useState, useRef } from 'react';
import AWSPortOpeningRequestsService from '../../../services/AWSPortOpeningRequestsService';
import AWSAccountLookupObject from '../../../models/AWSAccountLookupObject';
import AWSAccount from '../../../models/entities/AWSAccount';

interface AWSAccountLookupProps extends FieldHandler<AWSAccount> {
	onAccountChange: any;
	newAccount?: AWSAccount
}

const AWSAccountLookup = (props: AWSAccountLookupProps) => {
	const HTTP_NOT_FOUND_ERROR_CODE = 404;
	const awsAccountService = new AWSPortOpeningRequestsService();
	const [fieldLabel] = useState<string>("AWS Account's Name or Id");
	const [error, setError] = useState<string>('')
	const [searching, setSearching] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [awsAccountSearchResults, setAWSAccountSearchResults] = useState<AWSAccountLookupObject[]>([]);
	const [retrievedResults, setRetrievedResults] = useState<boolean>(false);
	const searchText = useRef<string>('');
	const typeSelected = useRef<string>('');


	const getInitialState = () => {
		searchText.current = '';
		setSearching(false);
		setAWSAccountSearchResults(null);
		setRetrievedResults(false);
		setError('');
		typeSelected.current = '';
	};

	const resetState = () => {
		getInitialState();
	};

	const searchApplicationInfo = async () => {
		setAWSAccountSearchResults([]);
		const searchString = searchText.current;
		if (searchString?.length <= 2)
			return;
		setSearching(true);
		try {
			const appInfo = await awsAccountService.awsAccountLookUp(searchString);
			setAWSAccountSearchResults(appInfo);
		} catch (error) {
			if (error.status !== HTTP_NOT_FOUND_ERROR_CODE) {
				console.log('An unexpected error occurred while retrieving data from Application Repository.');

			}
		}
		setSearching(false);
		setRetrievedResults(true);
	}

	const handleTextChange = async (e) => {
		searchText.current = e.target.value;
		setAWSAccountSearchResults([]);
		setRetrievedResults(false);
		searchApplicationInfo();
	};

	const handleOnChange = async (e, value) => {
		const obj = awsAccountSearchResults.find(o => o.id === value);
		resetState();
		searchText.current = obj?.name ?? obj?.id;
		setRetrievedResults(true);
		setLoading(true);
		try{
			const awsAccount = await awsAccountService.getAWSAccount(parseInt(obj?.id));
			props.onAccountChange(awsAccount);
			setAWSAccountSearchResults([]);
		}catch(error){
			setError(error.response.data.message)
			searchText.current = '';
			setRetrievedResults(true);
		}
		setLoading(false);
	}

	useEffect(() => {
		if (props.newAccount) {
			searchText.current = props.newAccount.account.name;
			props.onAccountChange(props.newAccount);
		}
	}, []);

	return (
		<> {props.disabled}
			{!props.disabled &&
				<UnityField>
					<UnityField.Label>
						{fieldLabel}
					</UnityField.Label>
					<Typeahead
						className="em-u-width-100"
						onChange={(e, value) => handleOnChange(e, value)}
						fieldProps={{
							label: '',
							note: !error ? "" : error,
							error: Boolean(error)
						}}
						inputProps={{
							onChange: (e) => handleTextChange(e),
							placeholder: "Account's Name or Id",
							value: searchText.current,
							loading: searching || loading,
							error: Boolean(error)
						}}
					>
						{retrievedResults && !awsAccountSearchResults?.length ? (
							<Typeahead.Item value={0} onClick={() => { }}>
								<Typeahead.Item.Suggestion aria-disabled={true}>
									<i>Object was not found</i>
								</Typeahead.Item.Suggestion>
							</Typeahead.Item>
						) : (
							awsAccountSearchResults?.map((app) => (
								<Typeahead.Item value={app.id} key={app.id}>
									<Typeahead.Item.Suggestion>
										<span>{app.name}</span>
									</Typeahead.Item.Suggestion>
								</Typeahead.Item>
							))
						)}
					</Typeahead>
				</UnityField>
			}
		</>
	);
}

export default AWSAccountLookup;