import React from "react";
import { unityTheme } from "../Themes";

// Create the Theme Context
const ThemeContext = React.createContext({
  theme: unityTheme,
  setTheme: () => {}
});

export default ThemeContext;
