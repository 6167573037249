import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const RssIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M9.48 14H6.46a.5.5 0 0 1-.491-.593A2.234 2.234 0 0 0 6 13a3 3 0 0 0-3-3 2.234 2.234 0 0 0-.407.031A.5.5 0 0 1 2 9.54V6.52a.5.5 0 0 1 .47-.5C2.653 6.01 2.821 6 3 6a7.008 7.008 0 0 1 7 7c0 .171-.009.333-.019.5a.526.526 0 0 1-.501.5zM7 13h2a6.007 6.007 0 0 0-6-6v2a4 4 0 0 1 4 4z"
          fill="currentColor"
        />
        <path
          d="M9.48 14H6.46a.5.5 0 0 1-.491-.593A2.234 2.234 0 0 0 6 13a3 3 0 0 0-3-3 2.234 2.234 0 0 0-.407.031A.5.5 0 0 1 2 9.54V6.52a.5.5 0 0 1 .47-.5C2.653 6.01 2.821 6 3 6a7.008 7.008 0 0 1 7 7c0 .171-.009.333-.019.5a.526.526 0 0 1-.501.5zM7 13h2a6.007 6.007 0 0 0-6-6v2a4 4 0 0 1 4 4z"
          fill="currentColor"
        />
        <path
          d="M9.48 14H6.46a.5.5 0 0 1-.491-.593A2.234 2.234 0 0 0 6 13a3 3 0 0 0-3-3 2.234 2.234 0 0 0-.407.031A.5.5 0 0 1 2 9.54V6.52a.5.5 0 0 1 .47-.5C2.653 6.01 2.821 6 3 6a7.008 7.008 0 0 1 7 7c0 .171-.009.333-.019.5a.526.526 0 0 1-.501.5zM7 13h2a6.007 6.007 0 0 0-6-6v2a4 4 0 0 1 4 4z"
          fill="currentColor"
        />
        <path
          d="M15.48 14h-2.99a.5.5 0 0 1-.5-.529c.01-.16.01-.311.01-.471a9.01 9.01 0 0 0-9-9c-.173.008-.312 0-.472.009A.5.5 0 0 1 2 3.51V.52a.5.5 0 0 1 .438-.5A4.618 4.618 0 0 1 3 0a13.015 13.015 0 0 1 13 13 4.587 4.587 0 0 1-.023.561.5.5 0 0 1-.497.439zM13 13h2A12.013 12.013 0 0 0 3 1v2a10.011 10.011 0 0 1 10 10zM2 16a1.9 1.9 0 0 1-.674-.121 1.967 1.967 0 0 1-1.208-1.214A1.888 1.888 0 0 1 0 14a1.99 1.99 0 0 1 1.337-1.883A1.875 1.875 0 0 1 2 12a2 2 0 0 1 2 2 1.913 1.913 0 0 1-.121.674A1.986 1.986 0 0 1 2 16zm0-3a.883.883 0 0 0-.324.058A.994.994 0 0 0 1 14a.882.882 0 0 0 .059.326.969.969 0 0 0 .606.612.974.974 0 0 0 1.272-.6A.906.906 0 0 0 3 14a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M10 43a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3zM28 39h-6.546a1 1 0 0 1-1-1A10.466 10.466 0 0 0 10 27.545a1 1 0 0 1-1-1V20a1 1 0 0 1 1-1 19.022 19.022 0 0 1 19 19 1 1 0 0 1-1 1zm-5.586-2h4.557A17.025 17.025 0 0 0 11 21.029v4.556A12.475 12.475 0 0 1 22.414 37z"
          fill="currentColor"
        />
        <path
          d="M41.046 38.955H34.52a1 1 0 0 1-1-1 23.5 23.5 0 0 0-23.474-23.474 1 1 0 0 1-1-1V6.955a1 1 0 0 1 1-1 32.036 32.036 0 0 1 32 32 1 1 0 0 1-1 1zm-5.546-2h4.529A30.036 30.036 0 0 0 11.046 7.971V12.5A25.509 25.509 0 0 1 35.5 36.955z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M3.5 23A3.5 3.5 0 1 1 7 19.5 3.5 3.5 0 0 1 3.5 23zm0-5A1.5 1.5 0 1 0 5 19.5 1.5 1.5 0 0 0 3.5 18zM14 19h-4a1 1 0 0 1-1-1c0-2.617-1.383-4-4-4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1 10.011 10.011 0 0 1 10 10 1 1 0 0 1-1 1zm-3.06-2h2A8.016 8.016 0 0 0 6 10.062v2A5.35 5.35 0 0 1 10.94 17z"
          fill="currentColor"
        />
        <path
          d="M22 19h-4a1 1 0 0 1-1-1A12.474 12.474 0 0 0 5 6a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1c9.084 0 18 8.916 18 18a1 1 0 0 1-1 1zm-3.041-2h2C20.371 9.616 13.384 2.629 6 2.04v2A14.692 14.692 0 0 1 18.959 17z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

RssIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

RssIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default RssIcon;
