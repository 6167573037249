export type UserRole = string;

export const UserRole = {
	SuperAdmin: 'Super_Admin',

	ApimAdmin: 'APIM_Admin',

	ApprovalsViewer: 'Approvals_Viewer',

	CloudAccountAdmin: 'CloudAccount_Admin',

	CloudGroupAdmin: 'Cloud_Group_Admin',

	DisconnectedSubscriptionRequestor: 'Disconnected_Subscription_Requestor',
	SubscriptionRequestor: 'Subscription_Requestor',
	SubscriptionAdmin: 'Subscription_Admin',
	SubscriptionTechnicalReviewer: 'Subscription_Technical_Reviewer',
	ConnSubUser: 'Conn_Sub_User',

	PORReader: 'POR_Reader',
	SubToOnPremTechnicalReviewer: 'POR_SubToOnPrem_Technical_Reviewer',
	SubToInternetTechnicalReviewer: 'POR_SubToInternet_Technical_Reviewer',
	DNDRSubToInternetTechnicalReviewer: 'DNDR_SubToInternet_Technical_Reviewer',
	DNDRSubToInternetGatewayPolicyReviewer: 'DNDR_SubToInternet_GatewayPolicy_Reviewer',
	DNDRSubToInternetCyberReviewer:  'DNDR_SubToInternet_Cyber_Reviewer',

	AWSPORReader: 'AWS_POR_Reader',
	AWSPORTechnicalImplementer: 'AWS_POR_Technical_Implementer',
	AWSPORCyberReviewer: 'AWS_POR_Cyber_Reviewer',
	AWSPORSolArqReviewer: 'AWS_POR_Sol_Arq_Reviewer',
	AWSPORTechnicalReviewer: 'AWS_POR_Technical_Reviewer',

	DNDRReader: 'DNDR_Reader',

	ReportingUser: 'Reporting_User',

	CapabilityManager: 'Capability_Manager',

	SSPDeveloper: 'SSP_Developer',
	
	OrgAdmins: 'Org_Admins',

	ADAppsUser: 'AD_Apps_User',

	TAPReportUser:'TAP_Report_User'
};
