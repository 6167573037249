import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SlidersIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.5 6h-3a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM5 5h2V2H5zM7.5 16h-3a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM5 15h2v-3H5zM14.5 11h-3a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM12 10h2V7h-2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M1 3h4v1H1zM1 8h10v1H1zM1 13h4v1H1zM9 3h6v1H9zM9 13h6v1H9z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M22 18h-6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM16 6v10h6V6zM22 46h-6a2 2 0 0 1-2-2V34a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zm-6-12v10h6V34zM42 32h-6a2 2 0 0 1-2-2V20a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zm-6-12v10h6V20z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M4 10h12v2H4zM4 24h32v2H4zM4 38h12v2H4zM28 10h16v2H28zM28 38h16v2H28z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11 9H7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zM8 7h2V3H8zM11 23H7a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-3-2h2v-4H8zM21 16h-4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-3-2h2v-4h-2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M1 4h6v2H1zM1 11h14v2H1zM1 18h6v2H1zM13 4h9v2h-9zM13 18h9v2h-9z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SlidersIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SlidersIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SlidersIcon;
