import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "../Button";
import Input from "../Input";
import { SearchIcon } from "../Icons";

/** A form for submitting a search query. */
const Searchbar = props => {
  const {
    filter,
    tags,
    condensed,
    inputProps,
    buttonProps,
    className,
    ...other
  } = props;
  const { className: inputPropsClassName = "", ...restInputProps } = inputProps;

  const form = (
    <form
      role="search"
      className={clsx(
        "em-c-search-form",
        { "em-c-search-form--small": condensed },
        className
      )}
      {...other}
    >
      <div className="em-c-search__body">
        {filter}
        <Input
          className={clsx("em-c-search__input", inputPropsClassName)}
          type="search"
          {...restInputProps}
        />
        <Button
          variant="primary"
          size={condensed ? "small" : "medium"}
          type="submit"
          {...buttonProps}
        >
          <SearchIcon size="small" />
        </Button>
      </div>
    </form>
  );

  if (tags) {
    return (
      <div className="em-c-tag-search" role="search">
        {form}
        <div className="em-c-tag-search__tags">{tags}</div>
      </div>
    );
  }

  return form;
};

Searchbar.propTypes = {
  /** value is the value of the searchbar */
  value: PropTypes.string,
  /** filter allows you to add components to your search like filters */
  filter: PropTypes.any,
  /** condensed will show a smaller searchbar */
  condensed: PropTypes.bool,
  /** List of tags to show beneath the searchbar */
  tags: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  /** Props passed to the Input element such as value and onChange */
  inputProps: PropTypes.object,
  /** Props that are passed to the search button */
  buttonProps: PropTypes.object,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Searchbar.defaultProps = {
  filter: null,
  condensed: false,
  tags: null,
  inputProps: {},
  buttonProps: {},
  className: ""
};

export default Searchbar;
