import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function getItemColor(color) {
  switch (color) {
    case "main":
      return "em-c-breadcrumbs__item--main";
    case "accent":
      return "em-c-breadcrumbs__item--accent";
    case "blue":
    default:
      return "";
  }
}

function getLinkColor(color) {
  switch (color) {
    case "main":
      return "em-c-breadcrumbs__link--main";
    case "accent":
      return "em-c-breadcrumbs__link--accent";
    case "blue":
    default:
      return "";
  }
}

/** BreadcrumbsItem displays a quote by pulling it out and enlarging it to emphasize it */
const BreadcrumbsItem = props => {
  const { className, color, children, ...rest } = props;

  return (
    <li
      className={clsx("em-c-breadcrumbs__item", getItemColor(color), className)}
      {...rest}
    >
      <div className={clsx("em-c-breadcrumbs__link", getLinkColor(color))}>
        {children}
      </div>
    </li>
  );
};

BreadcrumbsItem.propTypes = {
  /** children are the contents of the BreadcrumbsItem */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Theme color of the text and arrows */
  color: PropTypes.oneOf(["blue", "main", "accent"])
};

BreadcrumbsItem.defaultProps = {
  children: PropTypes.node,
  className: "",
  color: "blue"
};

export default BreadcrumbsItem;
