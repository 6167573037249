import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import PictureCardBody from "./PictureCardBody";

function getVariant(variant) {
  switch (variant) {
    case "bare":
      return "em-c-picture-card--bare";
    case "tinted":
      return "em-c-picture-card--tinted";
    case "reversed":
      return "em-c-picture-card--reversed";
    default:
      return "";
  }
}

/**
 * A visual card for promoting features or content
 *
 * @visibleName Picture card
 * */
const PictureCard = props => {
  const {
    background,
    backgroundImage,
    variant,
    reversed,
    className,
    children,
    ...other
  } = props;

  return (
    <div
      style={
        !background ? null : { backgroundImage: `url(${backgroundImage})` }
      }
      className={clsx(
        "em-c-picture-card",
        {
          "em-c-picture-card--background": background,
          "em-c-picture-card--reversed": reversed
        },
        getVariant(variant),
        className
      )}
      {...other}
    >
      {!background && (
        <div className="em-c-picture-card__header">
          <img src={backgroundImage} className="em-c-picture-card__img" />
        </div>
      )}
      {children}
    </div>
  );
};

PictureCard.Body = PictureCardBody;

PictureCard.propTypes = {
  /** Use an inline img element instead of a CSS background	*/
  background: PropTypes.bool,
  /** Display dark text on a light background. (The default picture card displays light text on a dark background.)	*/
  reversed: PropTypes.bool,
  /** Background image used for the hero block */
  backgroundImage: PropTypes.string,
  /** Remove the default gradient overlay or replace it with a solid, uniform tint. */
  variant: PropTypes.oneOf(["default", "bare", "tinted", "reversed"]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

PictureCard.defaultProps = {
  background: true,
  reversed: false,
  backgroundImage: "",
  variant: "default",
  className: "",
  children: null
};

export default PictureCard;
