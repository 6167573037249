export enum HomeCardTag {
	Subscriptions,
	AzureAD,
	Organizations,
	Network,
	Containers,
	Documentation
}

export default class HomeCardData {
	title: string;

	kicker?: string;

	description: string;

	actions?: string[];

	tags: HomeCardTag[];

	additionalLinks?: {
		label: string;
		href: string;
	}[];

	startingLink?: string;

	external?: boolean;

	constructor(obj) {
		Object.assign(this, obj);
	}

	contains(text: string): boolean {
		const texts = [
			this.title,
			(this.kicker || ''),
			this.description,
			...(this.actions || []),
			...(this.additionalLinks?.map(link => link.label) || []),
		];
		return texts.some(t => t.toLocaleLowerCase().includes(text.toLocaleLowerCase()));
		
	}
}