import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from "react-unity";
import AWSPortOpeningRequest from '../../models/entities/AWSPortOpeningRequests';
import moment from 'moment';
import ProgressBarSmall from './components/ProgressBarSmall';

interface AWSPortOpeningRequestsHomeTableRowProps {
	request: AWSPortOpeningRequest;
}

const AWSPortOpeningRequestsHomeTableRow = (props: AWSPortOpeningRequestsHomeTableRowProps) => {
	const navigate = useNavigate();

	const goToPORDetails = () => {
		navigate(
			`/accounts/${props.request.accountId}/awsPortOpeningRequests/${props.request.id}`
		);
	};

	return (
		<Table.Body.Row key={props.request.id}>
			<Table.Body.Cell>
				{props.request.id}
			</Table.Body.Cell>
			<Table.Body.Cell>
				{props.request.direction.displayName}
			</Table.Body.Cell>
			<Table.Body.Cell>
				<ProgressBarSmall workflowState={props.request.workflowInstance.currentWorkflowInstanceState.workflowState} porScope={props.request.direction.name} />
			</Table.Body.Cell>
			<Table.Body.Cell>
				{props.request.workflowInstance.createdBy.displayName}
			</Table.Body.Cell>
			<Table.Body.Cell>
				{props.request.contactUser?.displayName}
			</Table.Body.Cell>
			<Table.Body.Cell>
				{props.request.approvingUser?.displayName}
			</Table.Body.Cell>
			<Table.Body.Cell>
				{moment(new Date(props.request.workflowInstance.createdDate)).format('YYYY-MM-DD')}
			</Table.Body.Cell>
			<Table.Body.Cell>
				<Button
					style={{ zIndex: 0 }}
					variant="primary"
					size="small"
					onClick={() => goToPORDetails()}
				>
					See details
				</Button>
			</Table.Body.Cell>
		</Table.Body.Row>
	)
}

export default AWSPortOpeningRequestsHomeTableRow;