import React, { ReactElement } from 'react';
import { Table, CaretRightIcon, CaretDownIcon, DefinitionList, TreeNavigation } from 'react-unity';
import './ActionRow.css';

interface ActionRowProps {
	actionRef?: React.RefObject<any>;
	actionCell?: any;
	actions?: ReactElement<TreeNavigation>;
	children?: any;
}

interface ActionRowState {
	open: boolean;
}

export default class ActionRow extends React.Component<ActionRowProps, ActionRowState> {

	toolbarRef: React.RefObject<any>;

	constructor(props) {
		super(props);
		this.state = {
			open: false
		};

		this.toolbarRef = React.createRef();
	}

	render() {
		return (
			<tr
				ref={this.props.actionRef}
				className={`em-c-table__row ${(!!this.props.actions && 'em-u-clickable') || ''}`}
				onClick={(event) => {
					const toolbarNode = this.toolbarRef.current as Node;
					const currentTarget = event.target as Node;
					if (!toolbarNode || !toolbarNode.contains(currentTarget)) {
						this.setState((prevState: ActionRowState) => ({ open: !prevState.open }));
					}
				}}
			>
				{this.props.actions &&
						<Table.Body.Cell
							dropdown
							icon={(this.state.open ? <CaretDownIcon size="small" /> : <CaretRightIcon size="small" />)}
							className={`${(this.state.open && 'em-u-padding-bottom-none') || ''}`}
						>
							<DefinitionList className="em-u-margin-left">
								<DefinitionList.Item>
									{this.props.actionCell}
								</DefinitionList.Item>
								<br />
								{this.state.open &&
									<DefinitionList.Item>
										<div className="em-c-toolbar" ref={this.toolbarRef}>
											{this.props.actions}
										</div>
									</DefinitionList.Item>}
							</DefinitionList>
						</Table.Body.Cell>}
				{this.props.children}
			</tr>
		);
	}
}