import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LNGVessel = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M15.5,8h-0.3C15.6,7.5,16,6.7,16,5.7C16,3.6,14.9,2,13.5,2H8.5C7.1,2,6,3.6,6,5.7C6,6.9,6.4,7.5,6.7,8H6.2
		L5.4,7.1C5.3,7.1,5.1,7,5,7V4.5C5,4.2,4.8,4,4.5,4h-3C1.2,4,1,4.2,1,4.5V7H0.5C0.2,7,0,7.2,0,7.5c0,0.3,0,3.2,1.1,4.4l0.4,0.4
		l0.7-0.7l-0.4-0.4C1.3,10.6,1.1,9.1,1,8h3.8l0.9,0.9C5.7,8.9,5.9,9,6,9h8c-0.5,0.6-1,1.4-1,2.5V12h1v-0.5c0-1.7,1.7-2.5,1.7-2.6
		C15.9,8.8,16,8.6,16,8.4C15.9,8.2,15.7,8,15.5,8z M2,5h2v2H2V5z M7.8,7.7C7.4,7.2,7,6.8,7,5.6C7,4.1,7.8,3,8.5,3h5.1
		C14.2,3,15,4.1,15,5.7c0,0.7-0.2,1.3-0.8,2L14,8H8.1L7.8,7.7z"
          />
          <path
            fill="currentColor"
            d="M13.9,13.7C13.5,13.4,13,13,12.2,13c-0.8,0-1.2,0.4-1.5,0.7c-0.2,0.2-0.4,0.3-0.6,0.3
		c-0.1,0-0.3-0.2-0.6-0.3C9.2,13.4,8.7,13,7.9,13c-0.8,0-1.2,0.4-1.5,0.7C6.2,13.9,6.1,14,5.8,14c-0.1,0-0.3-0.2-0.6-0.3
		C4.9,13.4,4.3,13,3.6,13c-0.8,0-1.2,0.4-1.5,0.7C1.9,13.9,1.7,14,1.5,14H1v1h0.5c0.7,0,1-0.4,1.3-0.6C3,14.2,3.2,14,3.6,14
		c0.4,0,0.8,0.2,1.1,0.5C5,14.7,5.4,15,5.8,15c0.7,0,1-0.4,1.3-0.6C7.4,14.2,7.5,14,7.9,14c0.4,0,0.8,0.2,1.1,0.5
		c0.3,0.2,0.7,0.5,1.2,0.5c0.7,0,1-0.4,1.3-0.6c0.2-0.2,0.4-0.4,0.8-0.4c0.4,0,0.8,0.2,1.1,0.5c0.3,0.2,0.7,0.5,1.2,0.5H15v-1h-0.5
		C14.4,14,14.2,13.8,13.9,13.7z"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M47,23h-1.7c1.7-2,2.7-4.6,2.7-7c0-5.5-3.4-10-7.5-10h-17c-4.2,0-7.5,4.3-7.5,9.7V16c0,3.5,1.3,5.6,2.6,7
		h-1.3l-2.7-1.8C14.4,21.1,14.2,21,14,21v-7c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1v7H1c-0.6,0-1,0.4-1,1c0,0.3,0,8.4,3.2,12.6l0.6,0.8
		l1.6-1.2l-0.6-0.8C2.6,30.5,2.1,25.2,2,23h11.7l2.7,1.8c0.2,0.1,0.4,0.2,0.6,0.2h27.6C43,26.8,41,30,41,34v1h2v-1
		c0-5.4,4.6-9.2,4.6-9.2c0.3-0.3,0.5-0.7,0.3-1.1C47.8,23.3,47.4,23,47,23z M4,21v-6h8v6H4z M20.7,22.3c-1.3-1.2-2.7-3-2.7-6.3v-0.2
		c0-4.3,2.4-7.7,5.5-7.7h17c3,0,5.5,3.6,5.5,8c0,2.2-1,4.6-2.7,6.3L42.6,23H21.4l0,0L20.7,22.3z"
          />
          <path
            fill="currentColor"
            d="M44.8,38.4C44.1,37.8,43,37,41.4,37c-1.5,0-2.4,0.8-3,1.3C38,38.8,37.7,39,37.2,39c-0.3,0-0.7-0.3-1.2-0.6
		c-0.8-0.6-1.9-1.4-3.4-1.4c-1.5,0-2.4,0.8-3,1.3c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6c-0.8-0.6-1.9-1.4-3.4-1.4
		c-1.5,0-2.4,0.8-3,1.3c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6C17.7,37.8,16.6,37,15,37c-1.5,0-2.4,0.8-3,1.3
		c-0.5,0.5-0.7,0.7-1.3,0.7c-0.3,0-0.7-0.3-1.2-0.6C8.9,37.8,7.8,37,6.2,37c-1.5,0-2.4,0.8-3,1.3C2.8,38.8,2.5,39,2,39H1v2h1
		c1.3,0,2.1-0.7,2.6-1.2C5.1,39.3,5.5,39,6.2,39c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2
		c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8
		c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1
		c1.3,0,2.1-0.7,2.6-1.2c0.5-0.5,0.8-0.8,1.6-0.8c0.9,0,1.6,0.5,2.2,1c0.7,0.5,1.4,1,2.3,1h1v-2h-1C45.7,39,45.3,38.7,44.8,38.4z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M23,11L23,11c0.6-1,1-2.3,1-3.5C24,4.4,22.3,2,20.2,2h-7.4C10.6,2,9,4.3,9,7.4v0.1C9,8.8,9.4,10,10.1,11
		H8.8L8,10.5V6c0-0.6-0.4-1-1-1H2C1.4,5,1,5.4,1,6v4c-0.6,0-1,0.4-1,1c0,0.2,0,4.4,1.7,6.6l0.6,0.8l1.6-1.2l-0.6-0.8
		C2.5,15.3,2.2,13.3,2,12h4.6l1.2,0.8C8.1,12.9,8.3,13,8.5,13h12.3c-0.9,1.1-1.8,2.7-1.8,4v1h2v-1c0-1.1,1.6-3.2,2.7-4.3
		c0.3-0.3,0.4-0.7,0.2-1.1C23.8,11.2,23.4,11,23,11z M3,7h3v3H3V7z M12,10.3c-0.5-0.5-1-1.3-1-2.8V7.4c0-2,0.9-3.4,1.8-3.4h7.4
		C21,4,22,5.5,22,7.5c0,1-0.4,2.1-1.1,2.9L20.4,11h-7.7L12,10.3z"
          />
          <path
            fill="currentColor"
            d="M21.5,19.7c-0.5-0.3-1.2-0.7-2.2-0.7c-1,0-1.6,0.5-1.9,0.8C17.2,20,17.2,20,17,20c-0.1,0-0.3-0.2-0.5-0.3
		c-0.5-0.3-1.2-0.7-2.2-0.7c-1,0-1.6,0.5-1.9,0.8C12.2,20,12.2,20,12,20c-0.1,0-0.3-0.2-0.5-0.3c-0.5-0.3-1.2-0.7-2.2-0.7
		c-1,0-1.6,0.5-1.9,0.8C7.2,20,7.2,20,7,20c-0.1,0-0.3-0.2-0.5-0.3C6.1,19.4,5.4,19,4.4,19c-1,0-1.6,0.5-1.9,0.8C2.2,20,2.2,20,2,20
		H1v2h1c0.9,0,1.4-0.4,1.7-0.7C4,21.1,4.1,21,4.4,21c0.4,0,0.7,0.2,1.1,0.4C5.9,21.7,6.4,22,7,22c0.9,0,1.4-0.4,1.7-0.7
		C9,21.1,9.1,21,9.4,21c0.4,0,0.7,0.2,1.1,0.4c0.4,0.3,0.9,0.6,1.5,0.6c0.9,0,1.4-0.4,1.7-0.7c0.3-0.2,0.4-0.3,0.7-0.3
		c0.4,0,0.7,0.2,1.1,0.4c0.4,0.3,0.9,0.6,1.5,0.6c0.9,0,1.4-0.4,1.7-0.7c0.3-0.2,0.4-0.3,0.7-0.3c0.4,0,0.7,0.2,1.1,0.4
		c0.4,0.3,0.9,0.6,1.5,0.6h1l0-2h-1C21.9,20,21.7,19.8,21.5,19.7z"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LNGVessel.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LNGVessel.defaultProps = {
  size: "medium",

  className: ""
};

export default LNGVessel;
