import { RuleAction } from '../enums/DNDR/RuleAction';
import { RuleOperation } from '../enums/DNDR/RuleOperation';
import { TrafficDirection } from '../enums/DNDR/TrafficDirection';
import { RuleRegion } from '../enums/DNDR/RuleRegion';
import { DoNotDecryptRequestsDirection } from '../enums/DNDR/DoNotDecryptRequestsDirection';

export default class DoNotDecryptRule {
	id!: number;

	doNotDecryptRequestId!: number;

	action!: RuleAction;

	operation!: RuleOperation;

	description!: string;

	sourceIPs!: string;

	//destinationIPs!: string;

	destinationURLs!: string;

	//sourcePorts!: string;

	protocol!: string;

	service!: string;

	name!: string;

	azureName!: string;

	direction!: DoNotDecryptRequestsDirection;

	//destinationPorts!: string;

	region!: RuleRegion;

	trafficDirection!: TrafficDirection;

	constructor(obj: any) {
		this.action = RuleAction.Allow;
		Object.assign(this, obj);
		this.direction = new DoNotDecryptRequestsDirection().fromValue(obj.direction?.value);
	}
}