import * as React from 'react';
import ApplicationsService from '../../../services/ApplicationsService';
import AADAppsImport from '../AADAppsImport';


const AdAppsImport = () => {
	return (
		<AADAppsImport
			applicationsService={new ApplicationsService()}
			linkTreeText={'AD Applications'}
			linkTreeRedirectTo={'/adApps'}
			title={'Entra ID Applications: Import Apps data'}
		/>
	);
}

export default AdAppsImport; 