import { Enumeration } from '../../utils/AppEnum';

export class Environment extends Enumeration<Environment> {

    public static Development = new Environment('DEV', 1);

    public static Production = new Environment('PRD', 3);

    public static Lab = new Environment('LAB', 4);

}
