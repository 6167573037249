import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Vertical Header Band Icon Item */
function VerticalHeaderBodyBandIcon(props) {
  const { children } = props;

  return React.Children.toArray(children).map(child =>
    React.cloneElement(child, {
      className: clsx("em-c-solid-nav__icon", child.props.className)
    })
  );
}

VerticalHeaderBodyBandIcon.propTypes = {
  /** children of component */
  children: PropTypes.node
};

VerticalHeaderBodyBandIcon.defaultProps = {
  children: null
};

export default VerticalHeaderBodyBandIcon;
