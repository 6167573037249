import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  XFilledIcon,
  TriangleDownFilledIcon,
  SquareFilledIcon,
  TriangleUpFilledIcon
} from "../Icons";

function getIcon(variant) {
  switch (variant) {
    case "inactive":
      return <XFilledIcon className="em-c-status__icon" size="small" />;
    case "negative":
      return (
        <TriangleDownFilledIcon className="em-c-status__icon" size="small" />
      );
    case "caution":
      return <SquareFilledIcon className="em-c-status__icon" size="small" />;
    case "positive":
      return (
        <TriangleUpFilledIcon className="em-c-status__icon" size="small" />
      );
    default:
      return "";
  }
}

/** Status labels are data elements that can optionally suggest positive/negative value */
const Status = props => {
  const { variant, className, children, ...other } = props;

  return (
    <div
      className={clsx("em-c-status", `em-c-status--${variant}`, className)}
      {...other}
    >
      {getIcon(variant)}
      <span className="em-c-status__label">{children}</span>
    </div>
  );
};

Status.propTypes = {
  /** Determines the icon and color of the status */
  variant: PropTypes.oneOf([
    "default",
    "positive",
    "caution",
    "negative",
    "inactive"
  ]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node
};

Status.defaultProps = {
  variant: "default",
  className: "",
  children: null
};

export default Status;
