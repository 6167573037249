import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Logo from "../Logo";
import FooterLink from "./FooterLink";

function getVariantClass(type) {
  switch (type) {
    case "condensed":
    case "horizontal":
      return "em-c-multicolumn-nav--horizontal";
    case "3-column":
      return "em-c-multicolumn-nav--three";
    default:
      return "";
  }
}

/** Logo and housekeeping links displayed at page bottom */
const Footer = (props) => {
  const { variant, color, hideLogo, className, children, ...other } = props;

  return (
    <footer
      className={clsx(
        "em-c-footer",
        { "em-c-footer--dark": color === "dark",
          "em-c-footer--condensed": variant === "condensed" },
        className
      )}
      role="contentinfo"
      {...other}
    >
      <div className="em-l-container">
        <div className="em-c-footer__inner">
          <ul
            className={clsx(
              "em-c-multicolumn-nav",
              { "em-c-footer--dark": color === "dark" },
              getVariantClass(variant)
            )}
            role="navigation"
          >
            {children}
          </ul>
          {!hideLogo && <Logo />}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  /** Column Layout */
  variant: PropTypes.oneOf(["default", "horizontal", "3-column", "condensed"]), // eslint-disable-line
  /** Light or Dark */
  color: PropTypes.oneOf(["light", "dark"]),
  /** Option to hide ExxonMobil logo */
  hideLogo: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case footerlinks usually */
  children: PropTypes.node,
};

Footer.defaultProps = {
  variant: "default",
  color: "light",
  hideLogo: false,
  className: "",
  children: null,
};

Footer.Link = FooterLink;

export default Footer;
