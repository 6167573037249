import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const RocketIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.5 13H10v-1h1V4.876a.5.5 0 0 0-.124-.329l-2.5-2.858a.511.511 0 0 0-.752 0l-2.5 2.857a.5.5 0 0 0-.124.33V12h2v1H4.5a.5.5 0 0 1-.5-.5V4.876a1.5 1.5 0 0 1 .372-.988l2.5-2.857a1.549 1.549 0 0 1 2.257 0l2.5 2.858a1.5 1.5 0 0 1 .371.987V12.5a.5.5 0 0 1-.5.5zM3 12H1.5a.5.5 0 0 1-.487-.613L2.127 6.62l.974.228L2.13 11H3zM14.5 12H13v-1h.87l-.97-4.153.974-.228 1.114 4.767A.5.5 0 0 1 14.5 12z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 7h1v8H8zM5 13h1v3H5zM10 13h1v3h-1z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M34 40h-7v-2h6V20.284a18.877 18.877 0 0 0-5.565-13.435L24 3.414l-3.435 3.435A18.877 18.877 0 0 0 15 20.284V38h6v2h-7a1 1 0 0 1-1-1V20.284a20.862 20.862 0 0 1 6.151-14.849l4.142-4.142a1 1 0 0 1 1.414 0l4.142 4.142A20.862 20.862 0 0 1 35 20.284V39a1 1 0 0 1-1 1zM11 40H5.5a1 1 0 0 1-.958-1.287L9.33 22.755l1.916.575L6.844 38H11zM42.5 40H37v-2h4.156l-4.4-14.67 1.916-.575 4.788 15.958A1 1 0 0 1 42.5 40z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M23 23h2v19h-2zM17 41h2v7h-2zM29 41h2v7h-2zM23 44h2v4h-2zM24 19a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M17 20h-4v-2h3v-7.858a8.941 8.941 0 0 0-2.636-6.364L12 2.414l-1.364 1.364A8.941 8.941 0 0 0 8 10.142V18h3v2H7a1 1 0 0 1-1-1v-8.858a10.927 10.927 0 0 1 3.222-7.778L11.293.293a1 1 0 0 1 1.414 0l2.071 2.071A10.927 10.927 0 0 1 18 10.142V19a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M6 19H1.5a1 1 0 0 1-.937-1.351l3.352-8.937 1.873.7L2.943 17H6zM21.5 19H18v-2h2.057l-2.845-7.585 1.873-.7 3.351 8.937A1 1 0 0 1 21.5 19zM11 10h2v13h-2zM8 20h2v4H8zM14 20h2v4h-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

RocketIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

RocketIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default RocketIcon;
