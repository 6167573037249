import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CalculatorIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 16h-10A1.5 1.5 0 0 1 2 14.5v-11A1.5 1.5 0 0 1 3.5 2h10A1.5 1.5 0 0 1 15 3.5v11a1.5 1.5 0 0 1-1.5 1.5zM3.5 3a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M12.5 8h-8a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5zM5 7h7V5H5zM12.5 14h-2a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM11 13h1v-3h-1zM8.5 14h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM5 13h3v-3H5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M4 11h5v1H4z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42 47H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h36a3 3 0 0 1 3 3v38a3 3 0 0 1-3 3zM6 5a1 1 0 0 0-1 1v38a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M40 18H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h32a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1zM9 16h30V9H9zM40 43h-5a1 1 0 0 1-1-1V21a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v21a1 1 0 0 1-1 1zm-4-2h3V22h-3zM13 27H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-4-2h3v-3H9zM22 27h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-4-2h3v-3h-3zM31 27h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-4-2h3v-3h-3zM13 35H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-4-2h3v-3H9zM13 43H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-4-2h3v-3H9zM31 43H17a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-13-2h12v-3H18zM22 35h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-4-2h3v-3h-3zM31 35h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-4-2h3v-3h-3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20.5 24h-17A1.5 1.5 0 0 1 2 22.5v-21A1.5 1.5 0 0 1 3.5 0h17A1.5 1.5 0 0 1 22 1.5v21a1.5 1.5 0 0 1-1.5 1.5zM4 22h16V2H4z"
          fill="currentColor"
        />
        <path
          d="M17.9 9H6.1A1.1 1.1 0 0 1 5 7.9V4.1A1.1 1.1 0 0 1 6.1 3h11.8A1.1 1.1 0 0 1 19 4.1v3.8A1.1 1.1 0 0 1 17.9 9zM7 7h10V5H7zM17.9 21h-3.8a1.1 1.1 0 0 1-1.1-1.1v-8.8a1.1 1.1 0 0 1 1.1-1.1h3.8a1.1 1.1 0 0 1 1.1 1.1v8.8a1.1 1.1 0 0 1-1.1 1.1zM15 19h2v-7h-2zM10.9 15H6.1A1.1 1.1 0 0 1 5 13.9v-2.8A1.1 1.1 0 0 1 6.1 10h4.8a1.1 1.1 0 0 1 1.1 1.1v2.8a1.1 1.1 0 0 1-1.1 1.1zM7 13h3v-1H7zM10.9 21H6.1A1.1 1.1 0 0 1 5 19.9v-2.8A1.1 1.1 0 0 1 6.1 16h4.8a1.1 1.1 0 0 1 1.1 1.1v2.8a1.1 1.1 0 0 1-1.1 1.1zM7 19h3v-1H7z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CalculatorIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CalculatorIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CalculatorIcon;
