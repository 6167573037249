export abstract class Enumeration<T extends Enumeration<T>> {

    public name: string;

    public value: number;

    constructor();
    constructor(name: string, value: number);

    constructor(name?: string, value?: number) {
    	this.name = name;
    	this.value = value;
    }

    // TODO: set these methods as static class methods (similar to the backend)
    public list(): T[] {
    	const properties = Object.getOwnPropertyNames(this.constructor);
    	return properties.map(prop => this.constructor[prop]).filter(prop => prop instanceof this.constructor);
    }

    public fromName(name: string): T {
    	return this.list().find(r => r.name === name);
    }

    public fromValue(value: number | string): T {
    	return this.list().find(r => r.value === Number(value));
    }
}