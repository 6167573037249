import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MapPinIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 9A3.5 3.5 0 1 1 12 5.5 3.5 3.5 0 0 1 8.5 9zm0-6A2.5 2.5 0 1 0 11 5.5 2.5 2.5 0 0 0 8.5 3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 8h1v5H8z" />
        <path
          d="M13 15H4a2 2 0 0 1 0-4h2v1H4a1 1 0 0 0 0 2h9a1 1 0 0 0 0-2h-2v-1h2a2 2 0 0 1 0 4z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M25 25a11 11 0 1 1 11-11 11.012 11.012 0 0 1-11 11zm0-20a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M24 23h2v14h-2z" />
        <path
          d="M40 45H10a6 6 0 0 1 0-12h8.5v2H10a4 4 0 0 0 0 8h30a4 4 0 0 0 0-8h-7.5v-2H40a6 6 0 0 1 0 12z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 13a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 11h2v8h-2z" />
        <path
          d="M19 23H4a3.5 3.5 0 0 1 0-7h5v2H4a1.5 1.5 0 0 0 0 3h15a1.5 1.5 0 0 0 0-3h-4v-2h4a3.5 3.5 0 0 1 0 7z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MapPinIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MapPinIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MapPinIcon;
