import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** PageFooter is the footer portion of a page layout*/
const PageFooter = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("page-footer", className)} {...other}>
      {children}
    </div>
  );
};

PageFooter.propTypes = {
  /** children will be the footer components */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

PageFooter.defaultProps = {
  children: null,
  className: ""
};

export default PageFooter;
