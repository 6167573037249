import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OilTower = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M5.2,12.5L8,9.2V14h1V9.2l2.8,3.2l0.3,1.5l1-0.2L11,2.4c0-0.2-0.3-0.4-0.5-0.4H9V0H8v2H6.5
		C6.3,2,6.1,2.2,6,2.4L3.9,13.8l1,0.2L5.2,12.5z M5.5,10.6l0.8-4.4l1.5,1.7L5.5,10.6z M9.2,7.9l1.5-1.7l0.8,4.4L9.2,7.9z M10.1,3
		l0.4,1.9L9,6.6V3H10.1z M8,3v3.6L6.6,4.9L6.9,3H8z"
          />
          <rect x="3" y="15" fill="currentColor" width="11" height="1" />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M14,42l9-8.3V43h2v-9.3l9,8.3l0.2,1.1l2-0.3L30,4.8C29.9,4.4,29.5,4,29,4h-4V1h-2v3h-4
		c-0.5,0-0.9,0.4-1,0.8l-6.2,38l2,0.3L14,42z M19,11.2l3.6,4l-5.2,5.7L19,11.2z M30.6,20.9l-5.2-5.7l3.6-4L30.6,20.9z M17.4,23.8
		l5.6-6.1v11.4L17.4,23.8C17.4,23.9,17.4,23.9,17.4,23.8z M25,17.8l5.6,6.1c0,0,0.1,0,0.1,0.1L25,29.1V17.8z M22.5,31.4l-8,7.4
		L16.6,26L22.5,31.4z M25.5,31.4l5.9-5.5l2.1,12.9L25.5,31.4z M28.1,6l0.4,2.7L25,12.6V6H28.1z M23,6v6.6l-3.6-3.9L19.9,6H23z"
          />
          <rect x="9" y="45" fill="currentColor" width="30" height="2" />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M7,19.9L7,19.9l4-4.3V21h2v-5.4l3.9,4.3l0,0l0.2,1.3l2-0.4L16,3.8C15.9,3.3,15.5,3,15,3h-2V1h-2v2H9
		C8.5,3,8.1,3.3,8,3.8l-3.2,17l2,0.4L7,19.9z M7.7,16.2l1-5.3l1.9,2.1L7.7,16.2z M13.4,13l1.9-2.1l1,5.3L13.4,13z M14.2,5l0.6,3.4
		l-1.8,2V5H14.2z M11,5v5.4l-1.8-2L9.8,5H11z"
          />
          <rect x="3" y="22" fill="currentColor" width="18" height="2" />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OilTower.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OilTower.defaultProps = {
  size: "medium",

  className: ""
};

export default OilTower;
