import Organization from './Organization';
import User from '../User';
import WorkflowInstance from '../WorkflowInstance';
import CartaId from './CartaId';
import Tenant from './Tenant';
import { WorkflowState } from '../enums/WorkflowState';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../enums/UserRole';
import { TenantLabel } from '../enums/TenantLabel';
import { Environment } from '../enums/Environment';
import { Platform } from '../enums/Subscription/Platform';
import PortOpeningRequest from './PortOpeningRequest';

export default class SubscriptionRequest {

	id!: number;
	
	subscriptionUsedFor!: string;

	environment!: Environment;

	createdSubscriptionName!: string;

	createdSubscriptionOfferType!: string;

	createdSubscriptionId!: string;

	platform!: Platform;

	workflowInstance!: WorkflowInstance;

	owner!: User;

	custodian!: User;
	
	custodianTwo!: User;
	
	organization!: Organization;

	isConnected!: boolean;

	isProject!: boolean;

	wpmCode!: string;

	closeOutDate!: string;

	cartaIds!: CartaId[];

	portOpeningRequests: PortOpeningRequest[];

	tenant!: Tenant;

	addressSpaceLength!: number;

	subsBudget!: number;

	region!: string;

	azureState!: string;

	adGroupObjectId!: string;

	resourceGroupName!: string;

	resourceGroupOwnerType: string;

	resourceGroupOwnerId: string;

	selected!: boolean;

	constructor(obj: any) {
		Object.assign(this, obj);
		this.workflowInstance = new WorkflowInstance(obj.workflowInstance);
		this.organization = new Organization(obj.organization);
		this.platform = new Platform().fromValue(obj.platform?.value);
	}

	setEditable(select: boolean) {
		this.selected = select;
	}

	isEditable(): boolean {
		const currentState = this.workflowInstance.currentWorkflowInstanceState.workflowState;
		return (
			(
				currentState.value == WorkflowState.Submitted.value ||
				currentState.value == WorkflowState.Approved.value
			) &&
			(
				authenticatedUser.isInRole(UserRole.SubscriptionAdmin) ||
				authenticatedUser.userId === this.owner.id ||
				authenticatedUser.userId === this.custodian.id ||
				authenticatedUser.userId === this.custodianTwo?.id || 
				(authenticatedUser.isInRole(UserRole.OrgAdmins) && 
				authenticatedUser.myL3Orgs.includes(this.organization?.L3?.displayName))
			)
		);
	}

	canSeePortOpeningRequests(): boolean {
		const allowedRoles = [
		UserRole.PORReader];
		const userIsAllowed = authenticatedUser.isInAnyRole(allowedRoles);
		return userIsAllowed && this.canHavePortOpeningRequests();
	}

	canSeeDNDRequests(): boolean {
		const allowedRoles = [
		UserRole.DNDRReader];
		const userIsAllowed = authenticatedUser.isInAnyRole(allowedRoles);
		return userIsAllowed && this.canHaveDoNotDecryptRequests();
	}
	
	canSeePolicyExemptionRequests(): boolean {
		return (authenticatedUser.userId === this.owner.id || authenticatedUser.userId === this.custodian.id || authenticatedUser.userId === this.custodianTwo?.id ) && this.canHavePolicyExemptionRequests();
	}

	canSeeSubscriptionAdminConsent(): boolean {
		return authenticatedUser.userId === this.owner.id;
	}

	canSeeSubscriptionDomainOperation(): boolean {
		return this.isConnected;
	}

	canSubmitPortOpeningRequests(): boolean {
		return this.canHavePortOpeningRequests();
	}
	canSubmitDoNotDecryptRequests(): boolean {
		return this.canHaveDoNotDecryptRequests();
	}

	cansSeePermissionsReport(): boolean {
		if (typeof this.azureState === 'undefined' || this.azureState === null)
			return false;
            
		return this.workflowInstance.currentWorkflowInstanceState.workflowState.value === WorkflowState.Approved.value
			&& this.azureState === 'Enabled'
			&& (this.owner.id === authenticatedUser.userId
				|| this.custodian.id === authenticatedUser.userId
				|| this.custodianTwo?.id === authenticatedUser.userId
				|| authenticatedUser.isInRole(UserRole.SubscriptionAdmin));
	}

	canRequestDomainJoin(): boolean {
		return this.isConnected && (this.owner.id === authenticatedUser.userId
				|| this.custodian.id === authenticatedUser.userId
				|| this.custodianTwo?.id === authenticatedUser.userId
				|| authenticatedUser.isInRole(UserRole.SubscriptionAdmin));
	}

	userIsSubscriptionOwner(): boolean {
		return authenticatedUser.userId === this.owner.id;
	}

	userIsSubscriptionCustodian(): boolean {
		return authenticatedUser.userId === this.custodian.id || authenticatedUser.userId === this.custodianTwo?.id;
	}

	private canHavePortOpeningRequests(): boolean {
		return this.isConnected &&
		this.azureState === 'Enabled' &&
		(this.tenant.id === TenantLabel.EMCloudAD || this.tenant.id === TenantLabel.ExxonMobilTest);
	}
	private canHaveDoNotDecryptRequests(): boolean {
		return this.isConnected &&
		this.azureState === 'Enabled' &&
		(this.tenant.id === TenantLabel.EMCloudAD || this.tenant.id === TenantLabel.ExxonMobilTest);
	}

	private canHavePolicyExemptionRequests(): boolean {
		return this.azureState === 'Enabled' &&
		(this.tenant.id === TenantLabel.EMCloudAD || this.tenant.id === TenantLabel.ExxonMobilTest);
	}
}
