import React from 'react';
import './HomeCard.css';
import { Button, SolidCard, SolidCardBody, ArrowRightIcon, DefinitionList, LinkList } from 'react-unity';
import { Link } from 'react-router-dom';
import HomeCardData from '../../utils/HomeCardData';

interface HomeCardProps {
	info: HomeCardData;
}

interface HomeCardState { }

export default class HomeCard extends React.Component<HomeCardProps, HomeCardState> {

	render() {
		const darkModeEnabled = localStorage.getItem('theme') === 'dark';
		
		return (
			<SolidCard className={this.props.info.startingLink ? 'em-c-custom-pointer': null} onClick={() => {
				(this.props.info.startingLink && this.props.info.external)
									? window.open(this.props.info.startingLink, '_blank', 'noopener')
									: window.open(this.props.info.startingLink, '_self');
			}}> 
				<SolidCardBody className={!darkModeEnabled ? null : 'em-c-solid-card__body-dark'} >
					<SolidCard.Body.Kicker>{this.props.info.kicker ?? ''}&nbsp;</SolidCard.Body.Kicker>
					<SolidCard.Body.Title>
						{this.props.info.title}
					</SolidCard.Body.Title>
				</SolidCardBody>
				<SolidCard.Footer className="content-section" style={!darkModeEnabled ? null : { color: 'white' }}>
					<p>
						{this.props.info.description}
					</p>
					{this.props.info.actions?.length > 0 &&
						<DefinitionList>
							{
								this.props.info.actions.map(action =>
									<DefinitionList.Item key={action}>
										<b>{action.toUpperCase()}</b>
									</DefinitionList.Item>)
							}
						</DefinitionList>}
					{this.props.info.additionalLinks?.length > 0 &&
						<LinkList>
							{
								this.props.info.additionalLinks.map((link, index) =>
									<a
										key={index}
										href={link.href}
										target="_blank"
									>
										<LinkList.Item>
											{link.label}
										</LinkList.Item>
									</a>)
							}
						</LinkList>} 
															
						{this.props.info.startingLink &&
						<Button
							className="small-margin button-section"
							variant="tertiary"
							onClick={() => {
								this.props.info.external
									? window.open(this.props.info.startingLink, '_blank', 'noopener')
									: window.open(this.props.info.startingLink, '_self');
							}}
						>
							<span><b>GET STARTED</b>&nbsp;</span>
							<ArrowRightIcon size="small" />
						</Button>}

				</SolidCard.Footer>
			</SolidCard>
		);
	}
}