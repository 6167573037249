import * as React from 'react';
import { Modal, Button, Alert, Checkbox, Grid, XFilledIcon } from 'react-unity';
import FormModel from '../../../utils/FormModel';
import SelectField from '../../common/form-controls/SelectField';
import TextAreaField from '../../common/form-controls/TextareaField';
import CloudAccountsService from '../../../services/CloudAccountsService';

interface CloudAccountsModalProps {
	visible: boolean;
	availableAccountTypes: string[];
	onSubmit: (alertText: string) => void;
	onSuccess: (alertText: string) => void;
	onError: (error: any) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
}
interface CloudAccountsModalState {
	selectedAccountType: string;
	requestReason: string;
	termsAndConditionsCheck: boolean;
}

export default class CloudAccountRequestModal extends React.Component<
	CloudAccountsModalProps,
	CloudAccountsModalState
> {
	form: FormModel;

	cloudAccountsService: CloudAccountsService;

	constructor(props: CloudAccountsModalProps) {
		super(props);
		this.state = {
			selectedAccountType: null,
			requestReason: '',
			termsAndConditionsCheck: false
		};

		this.cloudAccountsService = new CloudAccountsService();

		this.form = new FormModel({
			selectedAccountType: {
				getValue: () => this.state.selectedAccountType,
				validation: {
					required: true,
				},
			},
			requestReason: {
				getValue: () => this.state.requestReason,
				validation: {
					required: true,
				},
			},
			termsAndConditionsCheck: {
				getValue: () => this.state.termsAndConditionsCheck,
				validation: {
					rules: [
						{
							assert: () => this.state.termsAndConditionsCheck,
							message:
								'You must accept to the terms and conditions in order to create the cloud account.',
						},
					],
				},
			},
		});
	}

	componentDidMount = async() => {
		document.getElementById('request-alert').lastChild.remove();
	};

	resetState = () => {
		this.setState({
			selectedAccountType: null,
			requestReason: '',
			termsAndConditionsCheck: false
		});
	};

	handleCancel = (e) => {
		this.resetState();
		this.props.onClose(e);
	};

	handleSubmit = async () => {
		this.props.onSubmit('Processing your request...');

		try {
			await this.cloudAccountsService.requestCloudAccount({
				targetDomainName: this.state.selectedAccountType,
				justification: this.state.requestReason
			});

			this.props.onSuccess('Request submitted successfully. You will be contacted if additional information is required.');
		} catch (error) {
			this.props.onError(error);
		} finally {
			this.resetState();
		}
	};

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>Cloud-only account request</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.handleCancel}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<SelectField
							label="What type of account do you need?"
							value={this.state.selectedAccountType}
							options={this.props.availableAccountTypes ?? []}
							className="full-width"
							onChange={(e) => this.setState({ selectedAccountType: e.target.value })}
							validation={this.form.fields.selectedAccountType.validation}
						/>
						<TextAreaField
							label="What will this account be used for?"
							value={this.state.requestReason}
							validation={this.form.fields.requestReason.validation}
							onChange={(e) => this.setState({ requestReason: e.target.value })}
						/>
						{/* TODO: Investigate and create a CheckboxField Component */}
						<Checkbox.Group
							label="Terms and conditions"
							error={!this.state.termsAndConditionsCheck}
							message="You must accept the terms and conditions in order to create a cloud account."
						>
							<Checkbox
								value="1"
								label="I have read and understand my responsibilities outlined in the Key IT Users Responsibilities"
								id="check-terms"
								checked={this.state.termsAndConditionsCheck}
								onChange={(checked) => this.setState({ termsAndConditionsCheck: checked })}
							/>
						</Checkbox.Group>
						<Alert id='request-alert'>
							Submission of this form does not guarantee that an account will be provisioned. Accounts
							are provisioned on an as-needed basis, subject to approval by an administrator.
						</Alert>
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button variant="secondary" onClick={this.handleCancel}>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<Button variant="primary" onClick={this.handleSubmit} disabled={!this.form.isValid()}>
									Submit Request
								</Button>
							</Grid.Item>
						</Grid>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}
