import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretDownIcon } from "../../Icons";

/** An accordian item within an accordian list. */
const AccordianItem = props => {
  const { title, className, open, value, onChange, children, ...other } = props;
  const [isOpen, setIsOpen] = useState(open);

  function isItemOpen() {
    // If an onChange event was passed from the parent, then this element is controlled
    if (onChange) {
      return open;
    }
    return isOpen;
  }

  function handleItemClick(e) {
    if (onChange) {
      onChange(e, value);
    } else {
      setIsOpen(prev => !prev);
    }
  }

  return (
    <li
      className={clsx(
        "em-c-accordion__item",
        "em-js-accordion-item",
        { "em-is-closed": !isItemOpen() },
        className
      )}
      {...other}
    >
      <div className="em-c-accordion__header">
        <a
          className="em-c-accordion__title em-js-accordion-trigger"
          onClick={handleItemClick}
        >
          <CaretDownIcon className="em-c-accordion__icon" size="small" />
          <span className="em-c-accordion__title-text">{title}</span>
        </a>
      </div>
      <div className="em-c-accordion__body">{children}</div>
    </li>
  );
};

AccordianItem.propTypes = {
  /** title of accordian dropdown */
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /** Value of the accordian item that is sent in the onChange callback */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** if the accordian is open or not. This is automatically passed by the Accordian parent */
  open: PropTypes.bool,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Callback whenever the item is clicked. This is automatically passed by the Accordian parent */
  onChange: PropTypes.func,
  /** React component children */
  children: PropTypes.node
};

AccordianItem.defaultProps = {
  title: "",
  open: false,
  className: "",
  children: null,
  onChange: () => {}
};

export default AccordianItem;
