import React, { useState } from 'react';
import { Table, Button } from 'react-unity';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import ProgressBarSmall from '../PortOpeningRequests/components/ProgressBarSmall';
import { useNavigate } from 'react-router-dom';
import './ApprovalsPORsTableRow.css';

interface ApprovalsPORsTableRowProps {
    por: PortOpeningRequest;
}

const ApprovalsPORsTableRow = ({ por }: ApprovalsPORsTableRowProps) => {
    const [subscriptionName, setSubscriptionName] = useState<string>('');
    const navigate = useNavigate();
	const handleViewDetails = () => {
		navigate(`/subscriptions/${por.subscriptionRequestId}/portOpeningRequests/${por.id}`);
	};
    
    return (
		<Table.Body.Row>
            <Table.Body.Cell>
                #{por.id}
            </Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>
                {por.subscriptionRequestName}
            </Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center center-align'>
                <ProgressBarSmall workflowState={por.workflowInstance.currentWorkflowInstanceState.workflowState} porScope={por.direction?.name} />
            </Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>
                <Button style={{ width: 120, height: 34 }} onClick={handleViewDetails} variant="primary">
                    See Details
                </Button>
            </Table.Body.Cell>
        </Table.Body.Row>
    );
}

export default ApprovalsPORsTableRow;