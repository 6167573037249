import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ThumbsUpIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3.5 15h-3a.5.5 0 0 1-.5-.5v-8A.5.5 0 0 1 .5 6h3a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM1 14h2V7H1zM14.5 10H13V9h1.5a.5.5 0 0 0 0-1H14V7h.5a1.5 1.5 0 0 1 0 3z"
          fill="currentColor"
        />
        <path
          d="M13.5 12H12v-1h1.5a.5.5 0 0 0 0-1H13V9h.5a1.5 1.5 0 0 1 0 3z"
          fill="currentColor"
        />
        <path
          d="M12.5 14H6.81a6.508 6.508 0 0 1-2.016-.32l-.905-.3.311-.951.905.295A5.492 5.492 0 0 0 6.81 13h5.69a.5.5 0 0 0 0-1H12v-1h.5a1.5 1.5 0 0 1 0 3zM14.5 8H13V7h1.5a.5.5 0 0 0 0-1H9.643a.5.5 0 0 1-.48-.638l.7-2.45A1.5 1.5 0 0 0 8.421 1h-.207A.217.217 0 0 0 8 1.214v2.143a.5.5 0 0 1-.071.257L6.215 6.472A3.336 3.336 0 0 1 4.871 7.73l-.444.229-.458-.889.445-.229a2.341 2.341 0 0 0 .942-.882L7 3.218v-2A1.216 1.216 0 0 1 8.214 0h.207a2.5 2.5 0 0 1 2.4 3.188L10.306 5H14.5a1.5 1.5 0 0 1 0 3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40.571 36H39v-2h1.571A2.248 2.248 0 0 0 43 32a2.248 2.248 0 0 0-2.429-2h-1v-2h1A4.236 4.236 0 0 1 45 32a4.236 4.236 0 0 1-4.429 4z"
          fill="currentColor"
        />
        <path
          d="M42.429 30H40v-2h2.429A1.827 1.827 0 0 0 44 26a1.827 1.827 0 0 0-1.571-2H41v-2h1.429A3.808 3.808 0 0 1 46 26a3.808 3.808 0 0 1-3.571 4z"
          fill="currentColor"
        />
        <path
          d="M43 24h-1v-2h1a2 2 0 0 0 1.966-2.376A2.1 2.1 0 0 0 42.858 18H32a1 1 0 0 1-.961-1.275l1.82-6.36A5.005 5.005 0 0 0 28.04 4 1.011 1.011 0 0 0 27 5v6a1 1 0 0 1-.143.515l-4.8 8a8.936 8.936 0 0 1-3.6 3.374l-.889.458-.917-1.778.889-.458a6.945 6.945 0 0 0 2.8-2.622L25 10.723V5a3 3 0 0 1 3-3 7.005 7.005 0 0 1 6.782 8.912L33.326 16h9.532a4.066 4.066 0 0 1 4.078 3.276A4 4 0 0 1 43 24zM38.858 42H24.28a15.022 15.022 0 0 1-4.756-.771l-2.784-.907.62-1.9 2.791.91A13.03 13.03 0 0 0 24.28 40h14.578a2.1 2.1 0 0 0 2.108-1.623A2 2 0 0 0 39 36h-1v-2h1a4 4 0 0 1 3.936 4.724A4.066 4.066 0 0 1 38.858 42zM12 44H4a3 3 0 0 1-3-3V21a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3zM4 20a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V21a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M8 40a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 18h-1v-2h1a.5.5 0 0 0 .5-.5c0-.124 0-.5-1.5-.5h-1v-2h1c2.893 0 3.5 1.359 3.5 2.5A2.5 2.5 0 0 1 20 18z"
          fill="currentColor"
        />
        <path
          d="M21 15h-1v-2h1a.5.5 0 0 0 0-1h-2v-2h2a2.5 2.5 0 0 1 0 5z"
          fill="currentColor"
        />
        <path
          d="M7.553 12.342l-.895-1.789.895-.447C9.764 9 10.109 8.536 10.142 8.487L12 4.765V2a2 2 0 0 1 2-2 4.038 4.038 0 0 1 3.237 1.65 4.17 4.17 0 0 1 .725 3.622L17.387 7H21.5a2.5 2.5 0 0 1 0 5h-1v-2h1a.5.5 0 0 0 0-1H16a1 1 0 0 1-.949-1.316l1-3a2.152 2.152 0 0 0-.409-1.827A2.048 2.048 0 0 0 14.02 2L14 5a1 1 0 0 1-.105.447l-2 4c-.436.739-1.457 1.452-3.447 2.447zM19 21h-7.36a20.026 20.026 0 0 1-3.966-1.055l-.946-.326.652-1.891.945.326a22.657 22.657 0 0 0 3.318.946H19a.5.5 0 0 0 .5-.5c0-.137 0-.5-1.5-.5h-1v-2h1c2.893 0 3.5 1.359 3.5 2.5A2.5 2.5 0 0 1 19 21z"
          fill="currentColor"
        />
        <path
          d="M6 22H2a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM2 10v10h4V10z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ThumbsUpIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ThumbsUpIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ThumbsUpIcon;
