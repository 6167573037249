import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LinkExternalIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M6 7h9v1H6z" />
        <path
          d="M13.5 10.207l-.707-.707 2-2-2-2 .707-.707 2.354 2.354a.5.5 0 0 1 0 .707zM10.5 13H.5a.5.5 0 0 1-.5-.5v-10A.5.5 0 0 1 .5 2h10a.5.5 0 0 1 .5.5V5h-1V3H1v9h9v-2h1v2.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M22 23h17v2H22z" />
        <path
          d="M39.132 33.408l-1.541-1.276 6.169-7.448a1.1 1.1 0 0 0 0-1.369l-6.168-7.448 1.541-1.276L45.3 22.04a3.117 3.117 0 0 1 0 3.92zM31 39H5a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v5h-2v-5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v24a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1v-5h2v5a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M14.6 22H2.1A2.1 2.1 0 0 1 0 19.9V4.1A2.1 2.1 0 0 1 2.1 2h12.5c1.592 0 2.4.673 2.4 2v4h-2V4.013C14.879 4 14.707 4 14.6 4H2.1a.1.1 0 0 0-.1.1v15.8a.1.1 0 0 0 .1.1h12.5c.106 0 .279 0 .4-.013V16h2v4c0 1.327-.808 2-2.4 2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M7.056 11h12v2h-12z" />
        <path
          d="M19 17.414L17.586 16l3.707-3.707c.112-.112.281-.281-.013-.6L17.586 8 19 6.586l3.707 3.707a2.346 2.346 0 0 1 0 3.414z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LinkExternalIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LinkExternalIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default LinkExternalIcon;
