import * as React from 'react';
import { Modal, Field, Grid, XFilledIcon, Button, Alert } from 'react-unity';
import ReadonlyField from '../../common/form-controls/ReadonlyField';

interface TAPModalProps {
  visible: boolean,
  setVisible:any
  tap: string;
}

const TAPModal = ({visible, setVisible, tap}:TAPModalProps) => {
    

    return (
      <Modal show={visible}
        onHide={() => { }}>
        <Modal.Window id="TAPModal" className="em-u-margin-double">
          <Modal.Window.Header>
            <Modal.Window.Header.Title>TAP Request</Modal.Window.Header.Title>
            <Modal.Window.Header.CloseButton onClick={() => setVisible(false)}>
              <XFilledIcon size='small' />
            </Modal.Window.Header.CloseButton>
          </Modal.Window.Header>
          <Modal.Window.Body>
            <Field>
              <Field.Body>
                <ReadonlyField label="Temporary Access Password" text={tap} copy={true}/>
              </Field.Body>
            </Field>
            <Alert
              variant='warning'
            >
              Please keep track of this passcode. The passcode is valid for 60 minutes and you cannot generate another passcode until the current passcode expires.
            </Alert>
          </Modal.Window.Body>
          <Modal.Window.Footer>
            <Grid variant="2-up">
              <Grid.Item style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                <Button
                  variant="secondary"
                  onClick={() => setVisible(false)}
                >
                  Close
                </Button>
              </Grid.Item>
            </Grid>
          </Modal.Window.Footer>
        </Modal.Window>
      </Modal>
    );
}

export default TAPModal;
