import AWSPortOpeningRule from '../../../models/entities/AWSPortOpeningRule';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';

export type PORColumn = {
	header: string;
	cell: (rule: AWSPortOpeningRule) => string;
	limit?: number;
};

export const RuleColumnsLayout = new Map<PortOpeningRequestsDirection, PORColumn[]>();

RuleColumnsLayout.set(PortOpeningRequestsDirection.AccountToInternet, [
	{
		header: 'Operation',
		cell: (rule: AWSPortOpeningRule) => rule.operation?.name,
	},
	{
		header: 'Name',
		cell: (rule: AWSPortOpeningRule) => rule.azureName ? rule.azureName : rule.name,
		limit: 20,
	},
	{
		header: 'Action',
		cell: (rule: AWSPortOpeningRule) => rule.action?.name,
	},
	{
		header: 'Region',
		cell: (rule: AWSPortOpeningRule) => rule.region?.name,
	},
	{
		header: 'Source IPs',
		cell: (rule: AWSPortOpeningRule) => rule.sourceIPs,
		limit: 15,
	},
	{
		header: 'Dest. URLs',
		cell: (rule: AWSPortOpeningRule) => rule.destinationURLs,
		limit: 15,
	},
	{
		header: 'Dest. IPs',
		cell: (rule: AWSPortOpeningRule) => rule.destinationIPs,
		limit: 15,
	},
	{
		header: 'Ports',
		cell: (rule: AWSPortOpeningRule) => rule.destinationPorts,
	},
	{
		header: 'Description',
		cell: (rule: AWSPortOpeningRule) => rule.description,
		limit: 20,
	},
]);