import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const RedoIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.5 12H1v-1h10.5a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5H3V3h8.5A1.5 1.5 0 0 1 13 4.5v6a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
        <path
          d="M3.5 15.207L.146 11.854a.5.5 0 0 1 0-.707L3.5 7.794l.707.707-3 3 3 3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39 34H8v-2h31a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1H14V8h25a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M9.914 41.912l-6.178-6.974a2.923 2.923 0 0 1 0-3.875l6.177-6.975 1.5 1.326-6.179 6.975a.911.911 0 0 0 0 1.223l6.179 6.975z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19 18H5v-2h14V6H6V4h13a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <path
          d="M6 23.414L1.656 19.07A2.924 2.924 0 0 1 .682 17a2.924 2.924 0 0 1 .975-2.07L6 10.586 7.414 12 3.07 16.344c-.521.521-.521.791 0 1.313L7.414 22z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

RedoIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

RedoIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default RedoIcon;
