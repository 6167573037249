import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LeafIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.384 12.827a4.413 4.413 0 0 1-1.922-.44l.436-.9a3.444 3.444 0 0 0 3.933-.673c2.133-2.133 2.854-6.377 3.056-7.949-1.572.2-5.816.923-7.949 3.055a3.633 3.633 0 0 0-.653 3.932l-.912.408a4.664 4.664 0 0 1 .858-5.047c2.9-2.9 8.927-3.392 9.183-3.412a.5.5 0 0 1 .536.537c-.02.255-.514 6.284-3.411 9.182a4.458 4.458 0 0 1-3.155 1.307z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M.842 14.2l8.174-8.174.708.707-8.175 8.174z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M22.751 37a12.8 12.8 0 0 1-5.561-1.267l.867-1.8a10.918 10.918 0 0 0 12.41-2.112c6.835-6.8 8.925-20.569 9.411-24.7C35.73 7.6 21.871 9.68 15.03 16.483A11.326 11.326 0 0 0 12.963 28.8l-1.824.82a13.388 13.388 0 0 1 2.481-14.555c8.586-8.54 26.543-10 27.3-10.063A1 1 0 0 1 42 6.077c-.059.756-1.531 18.621-10.119 27.163a12.944 12.944 0 0 1-9.13 3.76z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M4.086 42.5l24.415-24.414 1.414 1.414L5.5 43.914z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11.338 19.545a6.345 6.345 0 0 1-2.774-.645l.871-1.8a4.616 4.616 0 0 0 5.423-1.138c3.053-3.052 4.455-9.134 4.942-11.794-2.709.417-8.962 1.674-12.01 4.723a5.25 5.25 0 0 0-.939 5.687l-1.826.816a7.314 7.314 0 0 1 1.349-7.917C10.818 3.032 20.493 2.044 20.9 2a.988.988 0 0 1 .813.3 1 1 0 0 1 .275.822c-.051.4-1.285 9.817-5.72 14.25a6.991 6.991 0 0 1-4.93 2.173z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M1.585 21L14 8.586 15.414 10 3 22.414z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LeafIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LeafIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default LeafIcon;
