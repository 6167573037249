import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const InsetBlockBodyDescription = props => {
  const { className, children, ...other } = props;

  return (
    <p
      className={clsx(
        "em-c-inset-block__desc",
        "em-js-carousel-desc",
        className
      )}
      {...other}
    >
      {children}
    </p>
  );
};

InsetBlockBodyDescription.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

InsetBlockBodyDescription.defaultProps = {
  className: "",
  children: null
};

export default InsetBlockBodyDescription;
