import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ModalWindowHeaderCloseButton = props => {
  const { onClick, className, children, ...other } = props;

  return (
    <button
      className={clsx(
        "em-c-btn",
        "em-c-btn--bare",
        "em-c-modal__close-btn",
        className
      )}
      onClick={onClick}
      {...other}
    >
      <span className="em-c-btn__text">{children}</span>
    </button>
  );
};

ModalWindowHeaderCloseButton.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node,
  /** Callback function when the close button is clicked */
  onClick: PropTypes.func
};

ModalWindowHeaderCloseButton.defaultProps = {
  className: "",
  children: null,
  onClick: () => {}
};

export default ModalWindowHeaderCloseButton;
