import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CrosshairsIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M8 1h1v2H8zM8 14h1v2H8zM1 8h2v1H1zM14 8h2v1h-2zM8.5 11a2.5 2.5 0 0 1-1.768-4.268A2.5 2.5 0 1 1 8.5 11zm0-4a1.494 1.494 0 0 0-1.061.438A1.5 1.5 0 1 0 8.5 7zm-1.414.085z"
        />
        <path
          d="M8.5 13.62a5.12 5.12 0 0 1-3.62-8.74 5.12 5.12 0 1 1 3.62 8.74zm0-9.24a4.092 4.092 0 0 0-2.913 1.207A4.119 4.119 0 1 0 8.5 4.38zm-3.267.854z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M23 0h2v6h-2zM23 42h2v6h-2zM0 23h6v2H0zM42 23h6v2h-6zM24 39a15 15 0 1 1 15-15 15.016 15.016 0 0 1-15 15zm0-28a13 13 0 1 0 13 13 13.015 13.015 0 0 0-13-13z"
        />
        <path
          d="M24 31a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7zm0-12a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M11 0h2v4h-2zM11 20h2v4h-2zM0 11h4v2H0zM20 11h4v2h-4z"
        />
        <path
          d="M12 21a9 9 0 1 1 9-9 9.01 9.01 0 0 1-9 9zm0-16a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7z"
          fill="currentColor"
        />
        <path
          d="M12 16a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CrosshairsIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CrosshairsIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CrosshairsIcon;
