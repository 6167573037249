import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const UploadCloudIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 7h1v9H8z" />
        <path
          d="M10.5 9.207l-2-2-2 2-.707-.707 2.353-2.354a.5.5 0 0 1 .707 0L11.207 8.5z"
          fill="currentColor"
        />
        <path
          d="M13 12h-3v-1h3a2 2 0 0 0 .4-3.96.5.5 0 0 1-.4-.49 4.5 4.5 0 1 0-9-.05.556.556 0 0 1-.181.41.52.52 0 0 1-.412.131A2.231 2.231 0 0 0 3 7a2 2 0 0 0 0 4h4v1H3a3 3 0 1 1 .026-6 5.5 5.5 0 0 1 10.964.167A3 3 0 0 1 13 12z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M37.737 38H29v-2h8.737a8.16 8.16 0 0 0 8.178-6.808 8.018 8.018 0 0 0-5.315-8.745 1 1 0 0 1-.675-.856 14.995 14.995 0 0 0-29.811-.431 1 1 0 0 1-.9.875 8.023 8.023 0 0 0-7.179 8.75A8.216 8.216 0 0 0 10.293 36H21v2H10.293A10.229 10.229 0 0 1 .047 28.973a10.031 10.031 0 0 1 8.191-10.82 17 17 0 0 1 33.608.628A10.03 10.03 0 0 1 47.9 29.474 10.169 10.169 0 0 1 37.737 38z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M24 25h2v20h-2z" />
        <path
          d="M32.02 28.414l-6.3-6.128a1.038 1.038 0 0 0-1.435 0l-6.3 6.127-1.399-1.433 6.3-6.128a3.053 3.053 0 0 1 4.222 0l6.3 6.129z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.1 18H16v-2h2.1a3.136 3.136 0 0 0 3.151-2.511 3.028 3.028 0 0 0-2-3.371 1 1 0 0 1-.675-.856 6.156 6.156 0 0 0-12.24-.177 1 1 0 0 1-.9.875 3.033 3.033 0 0 0-2.652 3.71A3.1 3.1 0 0 0 5.863 16H9v2H5.863a5.107 5.107 0 0 1-5.03-3.9 5.033 5.033 0 0 1 3.641-5.994 8.159 8.159 0 0 1 16.014.363 5.031 5.031 0 0 1 2.735 5.337A5.151 5.151 0 0 1 18.1 18z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 13h2v9h-2z" />
        <path
          d="M15 13.414l-2.707-2.707a2.789 2.789 0 0 0-.293-.263 2.789 2.789 0 0 0-.293.263L9 13.414 7.586 12l2.707-2.707c1.2-1.2 2.217-1.2 3.414 0L16.414 12z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

UploadCloudIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

UploadCloudIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default UploadCloudIcon;
