import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ClipboardIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 5h-5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5zM6 4h4V2H6z"
          fill="currentColor"
        />
        <path
          d="M14.5 16h-13a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5H4v1H2v11h12V4h-2V3h2.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path
          d="M12.5 14h-9a.5.5 0 0 1-.5-.5V6h1v7h8V6h1v7.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M31 13H16a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h15a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2zM16 5v6h15V5z"
          fill="currentColor"
        />
        <path
          d="M39 45H8a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h4v2H8a1 1 0 0 0-1 1v32a1 1 0 0 0 1 1h31a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1h-4V7h4a3 3 0 0 1 3 3v32a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M36 41H11a2 2 0 0 1-2-2V15h2v24h25V15h2v24a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M15 7H8a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zM9 5h5V3H9z"
          fill="currentColor"
        />
        <path
          d="M20 23H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3v2H3v16h17V5h-3V3h3a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <path
          d="M18 20H5a1 1 0 0 1-1-1V7h2v11h11V7h2v12a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ClipboardIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ClipboardIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ClipboardIcon;
