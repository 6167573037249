import React, { useState } from 'react';
import { Table } from 'react-unity';
import PortOpeningRule from '../../models/entities/PortOpeningRule';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import Paginator from '../common/tables/Paginator';
import { PORColumn, RuleColumnsLayout } from './components/RuleColumnsLayout';
import PortOpeningRequestsByRuleRow from './PortOpeningRequestsByRuleRow';
import PORByRuleFilters from './PORByRuleFilters';
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';

interface PortOpeningRequestsByRuleProps {
	subscription: SubscriptionRequest;
	rules: PortOpeningRule[];
	validVnets: VirtualNetwork[];
}

const PortOpeningRequestsByRule = ({ subscription, rules, validVnets }: PortOpeningRequestsByRuleProps) => {
	const [visibleRules, setVisibleRules] = useState<PortOpeningRule[]>([]);
	const [columns, setColumns] = useState<PORColumn[]>(
		RuleColumnsLayout.get(
			new PortOpeningRequestsDirection().fromName(PortOpeningRequestsDirection.SubscriptionToInternet.name)
		)
	);
	const [filteredRules, setFilteredRules] = useState(rules.filter(rule => rule.direction.name === PortOpeningRequestsDirection.SubscriptionToInternet.name));
	const [selectedRules, setSelectedRules] = useState<PortOpeningRule[]>([]);

	const getHeaders = () => {
		return columns.map((column) => <Table.Head.Cell key={column.header}>{column.header}</Table.Head.Cell>);
	};

	const changeSelectedRules = (rule) => {
		if (selectedRules.some((selectedRule) => selectedRule.id === rule.id)) {
			setSelectedRules(selectedRules.filter((selectedRule) => selectedRule.id !== rule.id));
			return;
		}
		setSelectedRules([...selectedRules, rule]);
	}

	return (
		<>
			<Table
				header={
					<PORByRuleFilters
						columns={columns}
						rules={rules}
						onFilterChange={setFilteredRules}
						changeColumns={setColumns}
						subscriptionId={subscription.id}
						rulesFilteredForCSVCreation={filteredRules}
						resetSelectedRules={() =>setSelectedRules([])}
						selectedRules={selectedRules}
					/>
				}
				footer={<Paginator data={filteredRules} onPageChange={(page) => setVisibleRules(page)} />}
			>
				<Table.Head>
					<Table.Head.Row>
						<Table.Head.Cell></Table.Head.Cell>
						{getHeaders()}
						<Table.Head.Cell> </Table.Head.Cell>
					</Table.Head.Row>
				</Table.Head>
				<Table.Body>
					{visibleRules.map((rule, ruleIndex) => (
						<PortOpeningRequestsByRuleRow
							key={rule.id}
							rule={rule}
							indexOnList={filteredRules.indexOf(rule) + 1}
							columns={columns}
							ruleIndex={ruleIndex}
							selectedRules={selectedRules}
							onChangeSelectedRules={changeSelectedRules}
							subscriptionRequestName={subscription.createdSubscriptionName}
							validVnets={validVnets}
							subscriptionRequestId = {subscription.id}
						/>
					))}
				</Table.Body>
			</Table>
		</>
	);
};

export default PortOpeningRequestsByRule;
