import * as React from 'react';
import UserLookup from '../../common/form-controls/UserLookup';
import FieldHandler from '../../../models/interfaces/FieldHandler';

interface CustodianInputProps extends FieldHandler<string> { }

interface CustodianInputState { }

export class CustodianInput extends React.Component<CustodianInputProps, CustodianInputState> {
	render() {
		return (
			<UserLookup
				id="custodian-lookup"	
				label="Custodian"
				isSubscriptionOwner
				noteLink='https://gotocloud.xom.cloud/subscription-owner-learning-path/'
				note={!this.props.hideNotes && 'Custodians are responsible for executing the above actions on behalf of the subscription owner. Limited to Approved Subscription Owners. '}
				{...this.props}
			/>
		);
	}
}