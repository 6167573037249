import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MoneyIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 12H.5a.5.5 0 0 1-.5-.5v-9A.5.5 0 0 1 .5 2h13a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5zM1 11h12V3H1z"
          fill="currentColor"
        />
        <path
          d="M15.5 14H1v-1h14V4h1v9.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path
          d="M10.5 10h-7a.5.5 0 0 1-.5-.5V9h-.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H3v-.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5V5h.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H11v.5a.5.5 0 0 1-.5.5zM4 9h6v-.5a.5.5 0 0 1 .5-.5h.5V6h-.5a.5.5 0 0 1-.5-.5V5H4v.5a.5.5 0 0 1-.5.5H3v2h.5a.5.5 0 0 1 .5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 36H1a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h42a1 1 0 0 1 1 1v26a1 1 0 0 1-1 1zM2 34h40V10H2z"
          fill="currentColor"
        />
        <path d="M47 40H4v-2h42V12h2v27a1 1 0 0 1-1 1z" fill="currentColor" />
        <path
          d="M35 32H9a1 1 0 0 1-1-1v-3H5a1 1 0 0 1-1-1V17a1 1 0 0 1 1-1h3v-3a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v3h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1zm-25-2h24v-3a1 1 0 0 1 1-1h3v-8h-3a1 1 0 0 1-1-1v-3H10v3a1 1 0 0 1-1 1H6v8h3a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
        <path
          d="M22 28a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 18H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h19a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1zM2 16h17V5H2z"
          fill="currentColor"
        />
        <path d="M23 21H1v-2h21V4h2v16a1 1 0 0 1-1 1z" fill="currentColor" />
        <path
          d="M15 15H6a1 1 0 0 1-1-1v-1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h1V7a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v1a1 1 0 0 1-1 1zm-8-2h7v-1a1 1 0 0 1 1-1h1v-1h-1a1 1 0 0 1-1-1V8H7v1a1 1 0 0 1-1 1H5v1h1a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M9 9h3v3H9z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MoneyIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MoneyIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MoneyIcon;
