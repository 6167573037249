import * as React from 'react';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import SelectField from '../../common/form-controls/SelectField';
import { PortOpeningRequestsDirection } from '../../../models/enums/POR/PortOpeningRequestsDirection';

interface PORTypeInputProps {
    scope: FieldHandler<string>;
}

const PORTypeInput = ({ scope }: PORTypeInputProps ) => {

    const scopeOptions = () => {
        return [
            { value: PortOpeningRequestsDirection.AccountToInternet.name, text: 'Account -> Internet' }
        ];
    };

    return (
        <SelectField
            label="Scope"
            className="full-width"
            options={scopeOptions()}
            {...scope}
        />
    );
}

export default PORTypeInput;