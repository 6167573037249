import CartaId from '../../entities/CartaId';

export class UpdateEnterpriseAppModel {
    
    appId: string;

	expirationDate: Date;

	environment: number;
	
	cartaIds: CartaId[];

	ownerInTransferId: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}