import * as React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Grid, XFilledIcon, ImageWithCaption, Alert } from 'react-unity';
import NewConnectedPORInfoImage from '../../../images/newConnectedPORInfoImage.png';
import './NewAWSPORInfoModal.css';

interface NewAWSPORInfoModalProps {
    visible: boolean;
    onClose: (event: React.ChangeEvent<any>) => void;
}


const NewAWSPORInfoModal = ({ visible, onClose }: NewAWSPORInfoModalProps) => {

    return (
        <Modal show={visible}>
            <Modal.Window id="PORInfoModal" className="em-u-margin-double">
                <Modal.Window.Header>
                    <Modal.Window.Header.Title>New Connected Port Opening Request</Modal.Window.Header.Title>
                    <Modal.Window.Header.CloseButton onClick={onClose}>
                        <XFilledIcon size='small' />
                    </Modal.Window.Header.CloseButton>
                </Modal.Window.Header>
                <Modal.Window.Body>
                    <Alert
                        variant="warning"
                        className="no-alert-actions"
                    >
                        For AWS Port Opening Requests please go to
                        the Request Form page and follow the instructions
                        in the image below.
                    </Alert>
                    <ImageWithCaption
                        src={NewConnectedPORInfoImage}
                        caption=""
                    />
                </Modal.Window.Body>
                <Modal.Window.Footer>
                    <Grid variant="2-up">
                        <Grid.Item>
                            <Button variant="secondary" onClick={onClose}>
                                Cancel
                            </Button>
                        </Grid.Item>
                        <Grid.Item>
                            <Link to="/awsPortOpeningRequest/new" className="redirect-button">
                                <Button
                                    variant="primary"
                                    className="redirect-button"
                                >
                                    Go to the Request Form
                                </Button>
                            </Link>
                        </Grid.Item>
                    </Grid>
                </Modal.Window.Footer>
            </Modal.Window>
        </Modal>
    );
}

export default NewAWSPORInfoModal;