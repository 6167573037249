import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** ProgressTrackerItem displays a single step in a flow.*/
const ProgressTrackerItem = props => {
  const { label, complete, active, className, children, ...other } = props;

  return (
    <li
      className={clsx(
        "em-c-progress-tracker__item",
        { "em-is-complete": complete, "em-is-current": active },
        className
      )}
      {...other}
    >
      <div className="em-c-progress-tracker__number">{children}</div>
      <div className="em-c-progress-tracker__label">{label}</div>
    </li>
  );
};

ProgressTrackerItem.propTypes = {
  /** label that goes below the progress tracker item */
  label: PropTypes.node,
  /** marks progress tracker item complete */
  complete: PropTypes.bool,
  /** marks progress tracker item as the active item */
  active: PropTypes.bool,
  /** children are the contents of the ProgressTrackerItem */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

ProgressTrackerItem.defaultProps = {
  label: "",
  complete: false,
  active: false,
  children: PropTypes.node,
  className: ""
};

export default ProgressTrackerItem;
