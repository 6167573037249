import React, { useState, useEffect } from 'react';
import {
	Grid,
	Button,
	XFilledIcon,
	Modal,
	Alert,
	TextInput,
} from 'react-unity';
import Organization from '../../../models/entities/Organization';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import OrganizationsService from '../../../services/OrganizationsService';
import SelectField from '../../common/form-controls/SelectField';
import OrganizationTuple, { L4Tuple } from '../../../models/entities/OrganizationTuple';
import './OrganizationsModal.css';

export interface IOrganizationCreateProps {
	visible: boolean;
	editModal: boolean;
	selectedOrganization: Organization;
	onClose: (e) => any;
	onConfirm: (e) => any;
	filteredOrganizations: OrganizationTuple[];
	allOrganizations: OrganizationTuple[];
	setSelectedOrganization: any;
	setAlertBanner: any;
	selectedL3: Organization;
	setL3: any;
	refreshKey: boolean;
	editingL5: boolean;
}

const OrganizationModal = ({
	visible,
	editModal,
	selectedOrganization,
	onClose,
	onConfirm,
	filteredOrganizations,
	allOrganizations,
	setAlertBanner,
	selectedL3,
	setL3,
	refreshKey,
	editingL5
}: IOrganizationCreateProps) => {

	const [submitting, setSubmitting] = useState(false);
	const [organizationsService] = useState<OrganizationsService>(new OrganizationsService());
	const [optionSelected, setOptionSelected] = useState('');
	const [l4, setL4] = useState<OrganizationTuple>();
	const [l4Options, setL4Options] = useState<OrganizationTuple[]>([]);
	const [l5Options, setL5Options] = useState<L4Tuple[]>([]);
	const [alert, setAlert] = useState<AlertBanner>({
		visible: false,
		variant: 'error',
		text: ''
	});

	const handleClose = (e) => {
		resetStateForm();
		onClose(e);
	};

	const handleRequest = async (e: any) => {
		window.scroll(0, 0);
		setSubmitting(true);
		setAlert({
			visible: true,
			text: 'Saving changes... This could take several minutes',
			variant: 'default'
		});
		try {
			const model = new Organization({
				id: editModal ? selectedOrganization.id : null,
				displayName: optionSelected == 'L5' ? `${form.displayName.value}` : `${form.displayName.value.toUpperCase()}`,
				shortName: optionSelected == 'L5' ? `${form.shortName.value}` : `${form.parent.name.toUpperCase()}  ${form.shortName.value.toUpperCase()}`,
				parentOrganizationDisplayName: editModal ? selectedOrganization.parentOrganizationDisplayName : form.parent.name,
				parentOrganizationId: editModal ? selectedOrganization.parentOrganizationId : form.parent.id,
				costCenter: form.costCenter.value ?? null
			});
			await organizationsService.updateOrganization(model, editModal);
			setAlertBanner({
				visible: true,
				variant: 'success',
				text: `Organization ${editModal ? 'edited' : 'created'} successfully.`
			});
			setSubmitting(false);
			resetStateForm();
			onConfirm(e);
		}
		catch (err) {
			setAlertBanner({
				visible: true,
				variant: 'error',
				text: err.body || `The organization ${err.request?.body?.displayName} could not be ${editModal ? 'edited' : 'created'}. Please, try again later.`
			});
			setSubmitting(false);
			handleClose(e);
		};
		setAlert({
			visible: false,
		});
	};

	const [form, setForm] = useState({
		displayName: {
			value: '',
			assert: (e: string) => e.length > 0 && e.length <= 50,
			message: 'Invalid expresion for Organization name.',
		},
		parent: {
			id: null,
			name: '',
		},
		shortName: {
			value: '',
			assert: (e: string) => e.length > 0 && e.length <= 50,
			message: 'Invalid expresion for Organization short name.',
		},
		costCenter: {
			value: ''
		}
	});

	const resetStateForm = () => {
		setForm({
			...form,
			displayName: { ...form.displayName, value: '' },
			parent: { ...form.parent, name: '', id: null },
			shortName: { ...form.shortName, value: '' },
			costCenter: { ...form.costCenter, value: '' }
		});
		setOptionSelected(null);
		setL4Options([]);
		setL4(null);
	};

	const formIsValid = () => {
		if (editModal) return !(form.displayName.value != '');
		return !(form.displayName.value != ''
			&& form.shortName.value != ''
			&& form.parent.name != ''
			&& form.displayName.assert(form.displayName.value)
			&& form.shortName.assert(form.shortName.value)
			&& (optionSelected == 'L5' ? form.costCenter.value != '' : true));
	};

	const handleChange = (e) => {
		resetStateForm();
		setOptionSelected(e.target.value);
		const options = allOrganizations.map((l3) => l3);
		setL4Options(options);
	};

	const handleL5Options = (found: OrganizationTuple) => {
		const options = found.item2.map((l5) => l5);
		setL5Options(options);
	};

	const handleSelectParent = (e) => {
		const found = allOrganizations.find((l4) => l4?.item1 == e.target.value);
		setL4(found);
		organizationsService.getByDisplayName(found.item1).then((res) => {
			setL3(res);
			setForm({ ...form, parent: { ...form.parent, id: res?.id, name: found.item1 } });
		});
		handleL5Options(found);
	};

	const handleSelectL5Parent = (e) => {
		const found = l5Options.find((l5) => l5.item1.displayName == e.target.value);
		setForm({ ...form, parent: { ...form.parent, id: found.item1.id, name: e.target.value } });
	};

	useEffect(() => {
		resetStateForm();
	}, [refreshKey])

	useEffect(() => {
		editModal && setForm({
			...form,
			displayName: { ...form?.displayName, value: `${selectedOrganization?.displayName}` },
			shortName: { ...form.shortName, value: selectedOrganization.shortName ? `${selectedOrganization.shortName}` : '' },
			parent: { ...form.parent, name: selectedL3?.shortName ? `${selectedL3?.shortName}` : `${selectedL3?.displayName}` },
			costCenter: { ... form?.costCenter, value: selectedOrganization?.costCenter}
		});
	}, [selectedOrganization, editModal])

	return (
		<Modal show={visible}
			onHide={() => { }}>
			<Modal.Window id="organizations-modal">
				<Modal.Window.Header>
					<Modal.Window.Header.Title>{`${editModal ? 'Edit' : 'Create'} Your Organization`}</Modal.Window.Header.Title>
					<Modal.Window.Header.CloseButton onClick={onClose}>
						<XFilledIcon size='small' />
					</Modal.Window.Header.CloseButton>
				</Modal.Window.Header>
				{alert.visible &&
					<Alert
						variant={alert.variant}
						onClose={() => {
							setAlert({
								visible: false,
							});
						}}
					>
						{alert.text}
					</Alert>
				}
				<Modal.Window.Body>
					{!editModal &&
						<>
							<SelectField
								label="Select the type of Organization to create"
								options={["L4", "L5"]}
								className="full-width"
								value={optionSelected}
								onChange={handleChange}
							/>
							<SelectField
								label={`Select the L4's Parent Organization`}
								options={l4Options.map(l4 => l4.item1)}
								className="full-width"
								value={l4?.item1}
								onChange={(e) => handleSelectParent(e)}
							/>
							{optionSelected == 'L5' && 
							<SelectField
								label={`Select the L5's Parent Organization`}
								options={l5Options.map(l5 => l5.item1.displayName)}
								className="full-width"
								value={form.parent.name}
								onChange={(e) => handleSelectL5Parent(e)}
							/>}
						</>
					}
					<TextInput
						id='displayName'
						label="Organization Name"
						value={form.displayName.value}
						valid={editModal ? true : form.displayName.assert(form.displayName.value)}
						error={editModal ? false : (!form.displayName.assert(form.displayName.value))}
						onChange={(e) => {
							setForm({ ...form, displayName: { ...form.displayName, value: e.target.value } })
						}}
					/>
					<TextInput
						id='shortName'
						label="Organization Short Name"
						value={form.shortName.value}
						valid={editModal ? true : form.shortName.assert(form.shortName.value)}
						error={editModal ? false : (!form.shortName.assert(form.shortName.value))}
						onChange={(e) => {
							setForm({ ...form, shortName: { ...form.shortName, value: e.target.value } })
						}}
					/>
					{(optionSelected == 'L5' || editingL5) && 
					<TextInput
						id='costCenter'
						label="Cost Center"
						value={`${form.costCenter?.value?.toUpperCase()}`}
						onChange={(e) => {
							setForm({ ...form, costCenter: { ...form.costCenter, value: e.target.value.toUpperCase() }})
						}}
					/>}
					<TextInput
						id='finalOrganizationName'
						label="The Organization name will look like:"
						disabled
						value={optionSelected == 'L5' ? `${form.displayName.value}` : `${form.parent.name.toUpperCase()} ${form.shortName.value.toUpperCase()}`}
					/>
				</Modal.Window.Body>
				<Modal.Window.Footer>
					<Grid>
						<Grid.Item>
							<Button
								variant="secondary"
								disabled={submitting}
								onClick={handleClose}
							>
								Cancel
							</Button>
						</Grid.Item>
						<Grid.Item>
							<Button
								variant="primary"
								disabled={submitting || formIsValid()}
								onClick={handleRequest}
							>
								Save
							</Button>
						</Grid.Item>
					</Grid>
				</Modal.Window.Footer>
			</Modal.Window>
		</Modal>
	);
};

export default OrganizationModal;

