import WorkflowInstanceState from './WorkflowInstanceState';
import User from './User';
import { WorkflowType } from './enums/WorkflowType';

export default class WorkflowInstance {
	workflowType!: WorkflowType;

	owner!: User;

	createdDate!: Date;

	createdBy!: User;

	workflowInstanceStates!: WorkflowInstanceState[];

	currentWorkflowInstanceState!: WorkflowInstanceState;

	constructor(obj: any) {
		Object.assign(this, obj);
		this.workflowInstanceStates = obj.workflowInstanceStates?.map(state => new WorkflowInstanceState(state));
		this.currentWorkflowInstanceState = new WorkflowInstanceState(obj.currentWorkflowInstanceState);
		this.workflowType = new WorkflowType().fromValue(obj.workflowType?.value);
	}
}