import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CaretRightIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M5 12.027V3.269a.66.66 0 0 1 1.033-.545l6.4 4.379a.66.66 0 0 1 0 1.089l-6.4 4.379A.66.66 0 0 1 5 12.027z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M13.151 36.917V10.378a2 2 0 0 1 3.129-1.65L35.677 22a2 2 0 0 1 0 3.3L16.28 38.568a2 2 0 0 1-3.129-1.651z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M7 18.283V5.013a1 1 0 0 1 1.565-.825l9.7 6.635a1 1 0 0 1 0 1.651l-9.7 6.635A1 1 0 0 1 7 18.283z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CaretRightIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CaretRightIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CaretRightIcon;
