import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OilWorker = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <g>
          <path
            fill="currentColor"
            d="M5,5.5C5,7.4,6.6,9,8.5,9S12,7.4,12,5.5V5h1V4h-1V3.5C12,1.6,10.4,0,8.5,0S5,1.6,5,3.5V4H4v1h1V5.5z M6,3.5
		c0-0.8,0.4-1.5,1-2V3h1V1.1C8.2,1,8.3,1,8.5,1C8.7,1,8.8,1,9,1.1V3h1V1.5c0.6,0.5,1,1.2,1,2V4H6V3.5z M11,5v0.5C11,6.9,9.9,8,8.5,8
		C7.1,8,6,6.9,6,5.5V5H11z"
          />
          <path
            fill="currentColor"
            d="M13.3,9.2L13,8.8l-0.7,0.7l0.3,0.4c0.9,1,1.4,2.3,1.4,3.7V15H3v-1.5c0-1.4,0.5-2.7,1.4-3.7l0.3-0.4L4,8.8L3.7,9.2
		C2.6,10.4,2,11.9,2,13.5v2C2,15.8,2.2,16,2.5,16h12c0.3,0,0.5-0.2,0.5-0.5v-2C15,11.9,14.4,10.4,13.3,9.2z"
          />
        </g>
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M36.7,28.3c-1.3-1.3-2.7-2.3-4.3-3.2l-0.9-0.5l-0.9,1.8l0.9,0.5c1.4,0.7,2.7,1.7,3.8,2.8
		c3,3,4.7,7.1,4.7,11.3v3c0,0.6-0.4,1-1,1H9c-0.6,0-1-0.4-1-1v-3c0-5.9,3.3-11.3,8.5-14.1l0.9-0.5l-0.9-1.8l-0.9,0.5
		C9.7,28.2,6,34.3,6,41v3c0,1.7,1.3,3,3,3h30c1.7,0,3-1.3,3-3v-3C42,36.3,40.1,31.6,36.7,28.3z"
          />
          <path
            fill="currentColor"
            d="M14,14v1c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10v-1h-2v1c0,4.4-3.6,8-8,8s-8-3.6-8-8v-1H14z"
          />
          <path
            fill="currentColor"
            d="M16,12h16h2h2v-2h-2.1c-0.5-5-4.8-9-9.9-9c-5.2,0-9.4,4-9.9,9H12v2h2H16z M20,4.1V7h2V3.3
		C22.6,3.1,23.3,3,24,3s1.4,0.1,2,0.3V7h2V4.1c2.1,1.2,3.6,3.4,3.9,5.9H16.1C16.4,7.5,17.9,5.3,20,4.1z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <g>
          <path
            fill="currentColor"
            d="M18.6,13.2l-0.8-0.6l-1.1,1.7l0.8,0.6c2.5,1.7,2.6,3.1,2.6,5.9V22c0,0,0,0-0.1,0H4.1c0,0,0,0-0.1,0v-1.3
		c0-2.8,0-4.2,2.6-5.9l0.8-0.6l-1.1-1.7l-0.8,0.6C2,15.5,2,17.9,2,20.8v1.3c0,1,0.9,1.9,2.1,1.9h15.9c1.1,0,2.1-0.9,2.1-1.9v-1.3
		C22.1,18.1,22.1,15.5,18.6,13.2z"
          />
          <path
            fill="currentColor"
            d="M7,8c0,2.8,2.2,5,5,5s5-2.2,5-5V7h1V5h-1c0-2.8-2.2-5-5-5S7,2.2,7,5H6v2h1V8z M11,2.2V4h2V2.2
		c1.2,0.4,2,1.5,2,2.8H9C9,3.7,9.8,2.6,11,2.2z M15,7v1c0,1.7-1.3,3-3,3S9,9.7,9,8V7H15z"
          />
        </g>
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OilWorker.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OilWorker.defaultProps = {
  size: "medium",

  className: ""
};

export default OilWorker;
