import React, { useState } from 'react';
import { Alert, Button, Grid, TextInput, SearchIcon, ImageWithCaption, Table } from 'react-unity';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
import SectionHomeWrapper from '../common/wrappers/SectionHomeWrapper';
import NetworkSecurityGroupImage from '../../images/NetworkSecurityGroup.png';
import Paginator from '../common/tables/Paginator';
import NetworkSecurityGroupHomeTableRow from './NetworkSecurityGroupHomeTableRow';
import NetworkSecurityGroup from '../../models/entities/NetworkSecurityGroup';
import NetworkSecurityGroupsHomeFilters from './NetworkSecurityGroupsHomeFilters';
import styled from 'styled-components';
import NetworkSecurityGroupService from '../../services/NetworkSecurityGroupService';
import { useParams } from 'react-router-dom';

const Subtitle = styled.span`
	margin: 0px !important;
	font-size: 18px;
	${p => ''}
`

const NetworkSecurityGroupHome = () => {

    const { id } = useParams<{ id: string }>();
    const networkSecurityGroupService = new NetworkSecurityGroupService();
    const [ areMyNSGLoading, setMyNSGLoading ] = useState<boolean>(false);
    const [ allNSGs, setAllNSGs ] = useState<NetworkSecurityGroup[]>([]);
    const [ nsgOnPage, setNSGOnPage ] = useState<NetworkSecurityGroup[]>([]);
    const [ filteredNSGs, setFilteredNSGs ] = useState<NetworkSecurityGroup[]>([]);
    const [ alert, setAlertBanner ] = useState<AlertBanner>({
		visible: false,
        variant: 'default'
	});
    const [form, setForm] = useState({
		subscriptionId: {
			value: id ?? '',
			assert: (e: string) => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(e) && e.length > 0 && e.length <= 50,
			message: 'Invalid expresion for Subscription ID.',
		},
		resourceGroupName: {
            value: '',
			assert: (e: string) => /^(?!-)^([a-zA-Z-_])*(?<!-)$/.test(e) && e.length > 0 && e.length <= 50,
			message: 'Invalid expresion for Resource Group Name.',
		},
		nsgName: {
			value: '',
			assert: (e: string) => e.length > 0 && e.length <= 50,
			message: 'Invalid expresion for NSG Name.',
		}
	});

    const handleSearch = async () => {
        setMyNSGLoading(true);
        setAlertBanner({ 
            visible: true,
            text: "Searching Network Security Group Logs...",
        });
        try{
            const res = await networkSecurityGroupService.searchNSGs(form.subscriptionId.value, form.resourceGroupName.value, form.nsgName.value);
            setAllNSGs(res);
            setNSGOnPage(res);
            setAlertBanner({ visible: false });
            if(res.length == 0){
                setAlertBanner({ 
                    visible: true,
                    variant: 'error',
                    text: 'No blobs found for this Network Security Group'
                });
            }
        }
        catch(err) {
            setAlertBanner({ 
                visible: true,
                text: 'An error occurred. Please try again later',
                variant: 'error'
            });
        }
        setMyNSGLoading(false);
    }

    const formIsValid = () => {
		return (form.subscriptionId.value != ''
			&& form.resourceGroupName.value != ''
			&& form.nsgName.value != ''
			&& form.subscriptionId.assert(form.subscriptionId.value)
			&& form.resourceGroupName.assert(form.resourceGroupName.value)
            && form.nsgName.assert(form.nsgName.value));
	}

    const getFilterProps = () => {
		return {
            setFilteredNSGs,
            allNSGs,
            nsgOnPage
		};
	};

    return (
        <>
            <SectionHomeWrapper title="Network Security Group (NSG) Flow Logs">
            </SectionHomeWrapper>
            <RequestFormWrapper title=''>
                <Grid>
                    <Grid.Item>
                        <TextInput
                            id="SubscriptionID"
                            label="Subscription ID"
                            placeholder="Example f44617f6-ca77-4719-bcfd-8140a59bfe1f"
                            value={form.subscriptionId.value}
                            valid={form.subscriptionId.assert(form.subscriptionId.value)}
                            error={(!form.subscriptionId.assert(form.subscriptionId.value))}
                            onChange={(e) => setForm({ ...form, subscriptionId: { ...form.subscriptionId, value: e.target.value } })}
                        />
                        <TextInput
                            id="resourceGroupName"
                            label="Resource Group Name"
                            placeholder="Type your resource group"
                            value={form.resourceGroupName.value}
                            valid={form.resourceGroupName.assert(form.resourceGroupName.value)}
                            error={(!form.resourceGroupName.assert(form.resourceGroupName.value))}
                            onChange={(e) => setForm({ ...form, resourceGroupName: { ...form.resourceGroupName, value: e.target.value } })}
                        />
                        <TextInput
                            id="networkSecurityGroupName"
                            label="Network Security Group (NSG) Name"
                            placeholder="Type your nsg name"
                            value={form.nsgName.value}
                            valid={form.nsgName.assert(form.nsgName.value)}
                            error={(!form.nsgName.assert(form.nsgName.value))}
                            onChange={(e) => setForm({ ...form, nsgName: { ...form.nsgName, value: e.target.value } })}
                        />
                        <Grid style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="primary"
                                className="z-index-0"
                                disabled={!formIsValid() || areMyNSGLoading}
                                loading={areMyNSGLoading}
                                onClick={() => handleSearch()}
                            >
                                <SearchIcon className="search-icon" />
                                Search
                            </Button>
                        </Grid>
                        {alert.visible &&
                            <Grid style={{ paddingTop: '10px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                <Alert
                                    variant={alert.variant}
                                    onClose={() => {
                                        setAlertBanner({
                                            visible: false,
                                        });
                                    }}
                                >
                                    {alert.text}
                                </Alert>
                            </Grid>
                        }
                    </Grid.Item>
                    <Grid.Item>
                        <ImageWithCaption
							src={NetworkSecurityGroupImage}
							caption=""
						/>
                    </Grid.Item>
                </Grid>
                <Subtitle><span>NSGs FLOW LOGS - BLOB LISTS</span></Subtitle>
					<>
						<NetworkSecurityGroupsHomeFilters {...getFilterProps()} />
						<Table
							footer={
								<Paginator
									data={filteredNSGs}
									totalItems={allNSGs?.length}
									onPageChange={(nsgs) => {
										setNSGOnPage(nsgs);
									}}
								/>
							}
						>
							<Table.Head>
								<Table.Head.Row >
									<Table.Head.Cell className='table__header-cell'> Date </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Time </Table.Head.Cell>
                                    <Table.Head.Cell className='table__header-cell'> Size </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Download </Table.Head.Cell>
									<Table.Head.Cell > </Table.Head.Cell>
								</Table.Head.Row>
							</Table.Head>
                            <Table.Body>
                                {nsgOnPage?.map((nsg: NetworkSecurityGroup, index: number) =>
                                    <NetworkSecurityGroupHomeTableRow
                                        key={index}
                                        nsg={nsg}
                                        setAlertBanner={setAlertBanner}
                                    />
                            )}
                            </Table.Body>
						</Table>
					</>
            </RequestFormWrapper>
        </>
    );
};

export default NetworkSecurityGroupHome;