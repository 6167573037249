import React from 'react';
import {
    Button,
    Table,
} from 'react-unity';
import CloudAccount from '../../models/CloudAccount';
import moment from 'moment';
import './ApprovalsAccountsTableRow.css';
import { authenticatedUser } from '../../authentication/authModule';
import { UserRole } from '../../models/enums/UserRole';

interface ApprovalsAccountsTableRowProps {
    account: CloudAccount;
    setApproveModalVisible: Function;
    setSelectedCloudAccount: Function;
    setRejectModalVisible: Function;
    setReasonModalVisible: Function;
}

const ApprovalsAccountsTableRow = ({ account, setApproveModalVisible, setSelectedCloudAccount, setRejectModalVisible, setReasonModalVisible }: ApprovalsAccountsTableRowProps) => {
    const date = new Date(account.workflowInstance.createdDate);
    return (
        <Table.Body.Row key={account.id}>
            <Table.Body.Cell>{account.createdUserPrincipalName}</Table.Body.Cell>
            <Table.Body.Cell className='em-u-text-align-center'>{moment(date).format('MM/DD/YYYY')}</Table.Body.Cell>
            <Table.Body.Cell className='center-align'>
                <Button.Group className='em-u-text-align-center center-align'>
                    <Button
                        color='positive'
                        variant="primary"
                        size='small'
                        disabled={(authenticatedUser.isInRole(UserRole.ApprovalsViewer) && (account.approver !== authenticatedUser.userEmail))}
                        onClick={() => {
                            setApproveModalVisible(true);
                            setSelectedCloudAccount(account);
                        }
                        }
                    >Approve
                    </Button>
                    <Button
                        color='negative'
                        variant="primary"
                        size='small'
                        disabled={(authenticatedUser.isInRole(UserRole.ApprovalsViewer)&& (account.approver !== authenticatedUser.userEmail))}
                        onClick={() => {
                            setRejectModalVisible(true);
                            setSelectedCloudAccount(account);
                        }
                        }
                    >Reject
                    </Button>
                    <Button
                        size='small'
                        variant="primary"
                        onClick={() => {
                            setReasonModalVisible(true);
                            setSelectedCloudAccount(account);
                        }}
                    >View Request
                    </Button>
                </Button.Group>
            </Table.Body.Cell>
        </Table.Body.Row>
    );
}

export default ApprovalsAccountsTableRow;