import AWSAccountLookupObject from '../models/AWSAccountLookupObject';
import AWSAccount from '../models/entities/AWSAccount';
import AWSPortOpeningRequest from '../models/entities/AWSPortOpeningRequests';
import CancellationReason from '../models/interfaces/CancellationReason';
import RejectionModel from '../models/interfaces/RejectionModel';
import ReviewReason from '../models/interfaces/ReviewReason';
import PortOpeningRequestApproverVerification from '../models/viewModels/PortOpeningRequests/PortOpeningRequestApproverVerification';
import SubmitAWSPortOpeningRequest from '../models/viewModels/PortOpeningRequests/SubmitAWSPortOpeningRequest';
import UpdateAWSPortOpeningRequest from '../models/viewModels/PortOpeningRequests/UpdateAWSPortOpeningRequest';
import ApiClient from './ApiClient';

export default class AWSPortOpeningRequestsService extends ApiClient {
	async getAWSAccount(id: Number) {
		return this.get<AWSAccount>(`AWSPortOpeningRequests/account/${id}`);
	}

	async getAWSPORById(id: Number) {
		return this.get<AWSAccount>(`AWSPortOpeningRequests/${id}`);
	}

	async getAWSAccountWithPOR(accountId: Number, porId: Number) {
		return this.get<AWSAccount>(`AWSPortOpeningRequests/account/${accountId}/por/${porId}`);
	}

	async awsAccountLookUp(idOrName: string) {
		return this.get<AWSAccountLookupObject[]>(`AWSPortOpeningRequests/account/lookUp/${idOrName}`);
	}

	async cancel(portOpeningRequestId: number, cancellationModel: CancellationReason) {
		return this.patch<AWSPortOpeningRequest>(
			`AWSPortOpeningRequests/${portOpeningRequestId}/cancellation`,
			cancellationModel
		);
	}

	async getMyRequests() {
		return this.get<AWSPortOpeningRequest[]>('AWSPortOpeningRequests/me');
	}

	async getRequestById(id: number) {
		return this.get<AWSPortOpeningRequest>(`AWSPortOpeningRequests/${id}`);
	}

	async create(por: SubmitAWSPortOpeningRequest) {
		return this.post<AWSPortOpeningRequest>('AWSPortOpeningRequests', por);
	}

	async sendToReview(portOpeningRequestId: number, review: ReviewReason) {
		return this.patch<AWSPortOpeningRequest>(`AWSPortOpeningRequests/${portOpeningRequestId}/review`, review);
	}

	async reject(portOpeningRequestId: number, rejectionModel: RejectionModel) {
		return this.patch<AWSPortOpeningRequest>(`AWSPortOpeningRequests/${portOpeningRequestId}/rejection`, rejectionModel);
	}

	async approve(portOpeningRequestId: number) {
		return this.patch<AWSPortOpeningRequest>(`AWSPortOpeningRequests/${portOpeningRequestId}/approval`, null);
	}

	async escalate(portOpeningRequestId: number, escalationModel: ReviewReason) {
		return this.post<AWSPortOpeningRequest>(`AWSPortOpeningRequests/${portOpeningRequestId}/escalate`, escalationModel);
	}

	async update(portOpeningRequestId: number, por: UpdateAWSPortOpeningRequest) {
		return this.patch<AWSPortOpeningRequest>(`AWSPortOpeningRequests/${portOpeningRequestId}`, por);
	}

	async saveDraft(por: SubmitAWSPortOpeningRequest) {
		return this.put<AWSPortOpeningRequest>('AWSPortOpeningRequests/draft', por);
	}

	async verifyApproverLevel(email: string, isEmergency: boolean) {
		return this.get<PortOpeningRequestApproverVerification>(`AWSPortOpeningRequests/verifyApproverLevel?email=${email}&emergency=${isEmergency}`);
	}
}