import ApiClient from './ApiClient';
import ResourceGroup from '../models/entities/ResourceGroup';
import { DomainOperationRequest } from '../models/viewModels/Subscriptions/DomainOperationRequest';

export default class DomainOperationService extends ApiClient{

	async getResourceGroupsBySubscriptionId(subscriptionId: number) {
		return this.get<ResourceGroup[]>(`DomainOperation/subscription/${subscriptionId}/ResourceGroups`);
	}

	async manageVirtualMachinesDomian(subscriptionId: number, model: DomainOperationRequest) {
		return this.post<DomainOperationRequest>(`DomainOperation/subscription/${subscriptionId}`, model);
	}

	async getVirtualMachinesByResourceGroup(subscriptionId: number, resourceGroupName: string) {
		return this.get<ResourceGroup[]>(`DomainOperation/subscription/${subscriptionId}/VirtualMachines?resourceGroupName=${resourceGroupName}`);
	}

}