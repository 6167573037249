import {Enumeration} from '../../../utils/AppEnum';
import { VirtualPrivateCloud } from '../../entities/AzureResources/VirtualPrivateCloud';
import {PortOpeningRequestsDirection} from './PortOpeningRequestsDirection';

export class AWSRuleRegion extends Enumeration<AWSRuleRegion> {

	constructor();
	constructor(name: string, value: number);
	constructor(name: string, value: number, porDirections: PortOpeningRequestsDirection[]);

	constructor(name?: string, value?: number, porDirections?: PortOpeningRequestsDirection[]) {
		super(name, value);
		this.porDirections = porDirections ?? [];
	}

	public porDirections: PortOpeningRequestsDirection[];

    public static Singapure = new AWSRuleRegion("Singapure", 0, new PortOpeningRequestsDirection().list());

    public static London = new AWSRuleRegion("London", 1, new PortOpeningRequestsDirection().list());

    public static Ohio = new AWSRuleRegion("Ohio", 2, new PortOpeningRequestsDirection().list());

	public awsListByDirection = (
		porDirection: PortOpeningRequestsDirection,
		validNetworks: VirtualPrivateCloud[],
	) : AWSRuleRegion[] => {
		//TODO: Find out the list of Regions for AWS Regions
		const vNetDictionary = {
			'Ohio': 'us-east-2',
			'London': 'eu-west-2',
			'Singapure': 'ap-southeast-1',
		};

		const ruleRegions = this.list().filter(
			val => validNetworks.find(v=>v.region == val.name));

		if (ruleRegions.length !== 0){
			return (ruleRegions.filter(region => region.porDirections.includes(porDirection)));
		}

		const temp = this.list().filter(region => {
			return validNetworks.find(vnet => {
				return vNetDictionary[region.name] == vnet.region;
			});
		});
		return (temp.filter(region => region.porDirections.includes(porDirection)));
	};
}