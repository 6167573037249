import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const StorageTank = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M15.1,1.7C14,1.2,11.7,1,8.1,1c-3.6,0-6,0.2-7.2,0.7C0.4,1.9,0,2.4,0,3v11.5C0,14.8,0.2,15,0.5,15h15
	c0.3,0,0.5-0.2,0.5-0.5V2.9C16,2.4,15.6,1.9,15.1,1.7z M14.7,2.6C14.9,2.7,15,2.8,15,2.9v1.7C13.8,4.2,11.4,4,7.6,4C7.4,4,7.2,4,7,4
	V2c0.4,0,0.7,0,1.1,0C12.4,2,14.1,2.3,14.7,2.6z M6,7H4V5.1C4.6,5,5.3,5,6,5V7z M4,4.1V2.1c0.6,0,1.2-0.1,2-0.1v2C5.3,4,4.6,4,4,4.1
	z M1.3,2.6C1.6,2.5,2.1,2.4,3,2.2v2C2.3,4.3,1.8,4.4,1.4,4.5C1.3,4.5,1.1,4.6,1,4.6V3C1,2.8,1.1,2.7,1.3,2.6z M15,14H1V5.9
	c0-0.1,0.2-0.3,0.7-0.4C2,5.4,2.5,5.3,3,5.2V11h1V8h2v3h1V5c0.2,0,0.4,0,0.6,0c4.6,0,6.4,0.3,7.1,0.6C14.9,5.6,15,5.8,15,5.8V14z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <g>
          <path
            fill="currentColor"
            d="M46.8,18.5C45,17.5,42.6,17,40,17v2c2.3,0,4.3,0.4,5.8,1.2c0.1,0.1,0.2,0.2,0.2,0.4V38h-6v2h7
		c0.6,0,1-0.4,1-1V20.6C48,19.7,47.5,18.9,46.8,18.5z"
          />
          <path
            fill="currentColor"
            d="M36,9.5C33.4,8.5,27.9,8,19.3,8C10.6,8,4.8,8.5,2.1,9.6C0.8,10.1,0,11.2,0,12.4V39c0,0.6,0.4,1,1,1h36
		c0.6,0,1-0.4,1-1V12.3C38,11.1,37.2,10,36,9.5z M35.2,11.4c0.5,0.2,0.8,0.6,0.8,0.9v1.4c0,0,0,0,0,0c-2.6-1-8.1-1.6-16.7-1.6
		c-3.1,0-5.9,0.1-8.3,0.2v-2.1c2.2-0.1,4.9-0.2,8.3-0.2C29.6,10,33.6,10.8,35.2,11.4z M9,25H7v-4h2V25z M9,13H7v-2.4
		c0.6-0.1,1.3-0.1,2-0.2V13z M9,19H7v-4h2V19z M2.8,11.4C3.3,11.2,4,11,5,10.9V13c-1.2,0.2-2.2,0.5-2.9,0.7c0,0-0.1,0-0.1,0.1v-1.4
		C2,12,2.3,11.6,2.8,11.4z M36,38H2V16.5c0-0.4,0.3-0.8,0.8-1C3.4,15.4,4.1,15.2,5,15v14h2v-2h2v2h2V14.3c2.4-0.1,5.1-0.2,8.3-0.2
		c10.3,0,14.4,0.8,16,1.4c0.5,0.2,0.8,0.6,0.8,0.9V38z"
          />
        </g>
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M22.5,3c-1.6-0.7-5-1-10.4-1C6.8,2,3.2,2.3,1.5,3C0.6,3.4,0,4.2,0,5.1V22c0,0.6,0.4,1,1,1h22
	c0.6,0,1-0.4,1-1V5.1C24,4.2,23.4,3.4,22.5,3z M21.8,4.9C21.9,4.9,22,5,22,5.1v1.8C20.1,6.3,16.7,6,11.5,6c-0.5,0-1,0-1.5,0V4
	c0.7,0,1.4,0,2.2,0C18.4,4,20.8,4.5,21.8,4.9z M8,10H6V8.1c0,0,0,0,0.1,0C6.6,8.1,7.3,8.1,8,8V10z M6,6.1V4.2c0.6-0.1,1.2-0.1,2-0.1
	v2C7.3,6.1,6.6,6.1,6,6.1z M2.3,4.9C2.6,4.8,3.1,4.6,4,4.5v1.9C3.3,6.5,2.7,6.6,2.3,6.8C2.2,6.8,2.1,6.8,2,6.9V5.1
	C2,5.1,2.1,5,2.3,4.9z M22,21H2V9.2c0-0.1,0.2-0.3,0.8-0.5C3.1,8.6,3.5,8.5,4,8.4V15h2v-3h2v3h2V8c0.5,0,1,0,1.5,0
	c6.6,0,9.2,0.5,10.2,0.9C21.9,8.9,22,9.1,22,9.1V21z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

StorageTank.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

StorageTank.defaultProps = {
  size: "medium",

  className: ""
};

export default StorageTank;
