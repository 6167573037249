import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** PullquoteCitation displays the citation part of a pullquote */
const PullquoteCitation = props => {
  const { className, children, ...other } = props;

  return (
    <cite className={clsx("em-c-pullquote__citation", className)} {...other}>
      {props.children}
    </cite>
  );
};

PullquoteCitation.propTypes = {
  /** children is the content of teh pullquote citation */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

PullquoteCitation.defaultProps = {
  children: null,
  className: ""
};

export default PullquoteCitation;
