import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FileJpgIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3.41 9.131a1.656 1.656 0 0 1-.12.627 1.271 1.271 0 0 1-.355.494 1.777 1.777 0 0 1-.6.333 2.793 2.793 0 0 1-.872.118q-.352 0-.662-.025a4.727 4.727 0 0 1-.549-.078V9.576h.123c.109.041.223.079.342.115a1.627 1.627 0 0 0 .454.053 1.271 1.271 0 0 0 .552-.1A.575.575 0 0 0 2 9.38a.969.969 0 0 0 .089-.368c.007-.133.011-.3.011-.512V6.472H.932v-.936H3.41zM9.264 7.142a1.921 1.921 0 0 1-.119.668 1.486 1.486 0 0 1-.342.548 2.086 2.086 0 0 1-.679.455 2.456 2.456 0 0 1-.931.154h-.817v1.658H5.063V5.536h2.16a3.42 3.42 0 0 1 .818.083 1.928 1.928 0 0 1 .59.251 1.344 1.344 0 0 1 .47.516 1.626 1.626 0 0 1 .163.756zm-1.356.029a.6.6 0 0 0-.116-.37.635.635 0 0 0-.27-.216 1.226 1.226 0 0 0-.4-.091c-.13-.005-.3-.008-.52-.008h-.226v1.521h.376a3.06 3.06 0 0 0 .548-.041.777.777 0 0 0 .368-.166.628.628 0 0 0 .183-.261 1.056 1.056 0 0 0 .056-.368zM15.115 10.334a6.893 6.893 0 0 1-.906.265 4.94 4.94 0 0 1-1.124.131 2.817 2.817 0 0 1-2.034-.706 2.579 2.579 0 0 1-.734-1.95 2.553 2.553 0 0 1 .742-1.912 2.828 2.828 0 0 1 2.067-.727 4.882 4.882 0 0 1 .957.091 4.256 4.256 0 0 1 1.012.36v1.195h-.147a6.735 6.735 0 0 0-.279-.2 3.516 3.516 0 0 0-.355-.224 2.367 2.367 0 0 0-.463-.188 1.985 1.985 0 0 0-.562-.079 1.854 1.854 0 0 0-.633.1 1.4 1.4 0 0 0-.85.836 1.979 1.979 0 0 0-.125.729 1.751 1.751 0 0 0 .448 1.292 1.794 1.794 0 0 0 1.323.448h.166c.061 0 .115-.006.165-.01v-1h-1.015v-.96h2.348zM3 4H2V2.5A1.5 1.5 0 0 1 3.5 1h6.506a1.491 1.491 0 0 1 1.061.439l1.7 1.7-.707.707-1.7-1.7A.5.5 0 0 0 10.006 2H3.5a.5.5 0 0 0-.5.5zM12.5 15h-9A1.5 1.5 0 0 1 2 13.5V12h1v1.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V12h1v1.5a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 34H5a3 3 0 0 1-3-3V18a3 3 0 0 1 3-3h38a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3zM5 17a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1V18a1 1 0 0 0-1-1zM39 13.414l-7.527-7.527A3 3 0 0 0 29.34 5H9a1 1 0 0 0-1 1v7H6V6a3 3 0 0 1 3-3h20.34a4.983 4.983 0 0 1 3.547 1.473L40.414 12zM39 45H9a3 3 0 0 1-3-3v-6h2v6a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-6h2v6a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M11.753 29.431a.808.808 0 0 1-.253-.572.715.715 0 0 1 .71-.733.655.655 0 0 1 .458.174 1.781 1.781 0 0 0 1.373.671c.819 0 1.361-.548 1.361-1.716v-5a.747.747 0 1 1 1.494 0v5.047a3.216 3.216 0 0 1-.8 2.312 2.762 2.762 0 0 1-2.047.771 3.092 3.092 0 0 1-2.296-.954zM18.936 22.271a.747.747 0 0 1 .746-.771h2.577c1.975 0 3.2 1.156 3.2 2.909v.025c0 1.952-1.518 2.971-3.373 2.971h-1.67v2.1a.741.741 0 1 1-1.481 0zm3.2 3.754a1.605 1.605 0 0 0 1.818-1.555v-.024c0-1.019-.71-1.554-1.818-1.554h-1.72v3.133zM26.61 26.025V26a4.368 4.368 0 0 1 4.348-4.5 4.477 4.477 0 0 1 2.879.883.785.785 0 0 1 .3.622.749.749 0 0 1-.735.758.767.767 0 0 1-.458-.174 3.138 3.138 0 0 0-2.048-.683 2.906 2.906 0 0 0-2.733 3.07V26a2.867 2.867 0 0 0 2.867 3.108 3.374 3.374 0 0 0 2.036-.659V26.82H31.4a.657.657 0 0 1-.662-.659.668.668 0 0 1 .662-.671h2.35a.748.748 0 0 1 .746.77v2.325a1.063 1.063 0 0 1-.519.97 5.252 5.252 0 0 1-3 .944 4.252 4.252 0 0 1-4.367-4.474z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M7.41 13.384a1.656 1.656 0 0 1-.12.627 1.271 1.271 0 0 1-.355.494 1.777 1.777 0 0 1-.6.333 2.793 2.793 0 0 1-.872.118q-.352 0-.662-.025a4.727 4.727 0 0 1-.547-.073v-1.03h.123c.109.041.223.079.342.115a1.627 1.627 0 0 0 .452.057 1.271 1.271 0 0 0 .552-.1.575.575 0 0 0 .277-.267.969.969 0 0 0 .089-.368c.007-.133.011-.3.011-.512v-2.028H4.932v-.937H7.41zM13.264 11.395a1.921 1.921 0 0 1-.119.668 1.486 1.486 0 0 1-.342.548 2.086 2.086 0 0 1-.679.455 2.456 2.456 0 0 1-.931.154h-.817v1.658H9.063v-5.09h2.16a3.42 3.42 0 0 1 .818.083 1.928 1.928 0 0 1 .59.251 1.344 1.344 0 0 1 .47.516 1.626 1.626 0 0 1 .163.757zm-1.356.029a.6.6 0 0 0-.116-.37.635.635 0 0 0-.27-.216 1.226 1.226 0 0 0-.4-.091c-.13-.005-.3-.008-.52-.008h-.226v1.521h.376a3.06 3.06 0 0 0 .552-.041.777.777 0 0 0 .364-.164.628.628 0 0 0 .183-.261 1.056 1.056 0 0 0 .056-.37zM19.115 14.587a6.893 6.893 0 0 1-.906.265 4.94 4.94 0 0 1-1.124.131 2.817 2.817 0 0 1-2.034-.706 2.579 2.579 0 0 1-.734-1.95 2.553 2.553 0 0 1 .742-1.912 2.828 2.828 0 0 1 2.067-.727 4.882 4.882 0 0 1 .957.091 4.256 4.256 0 0 1 1.012.36v1.193h-.147a6.735 6.735 0 0 0-.279-.2 3.516 3.516 0 0 0-.355-.224 2.367 2.367 0 0 0-.463-.188 1.985 1.985 0 0 0-.562-.079 1.854 1.854 0 0 0-.633.1 1.4 1.4 0 0 0-.85.836 1.979 1.979 0 0 0-.125.729 1.751 1.751 0 0 0 .448 1.292 1.794 1.794 0 0 0 1.323.448h.166c.061 0 .115-.006.165-.01v-1h-1.015v-.96h2.348z"
          fill="currentColor"
        />
        <path
          d="M23 19H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1zM2 17h20V8H2zM19.151 5.406L16.654 3.4a.879.879 0 0 1-.081-.073C16.265 3.013 16.175 3 16 3H5v2H3V3a1.979 1.979 0 0 1 2-2h11a2.609 2.609 0 0 1 1.949.87l2.457 1.978zM17.857 24H5.143A2.077 2.077 0 0 1 3 22v-2h2v2c0-.042.069 0 .143 0h12.714a.234.234 0 0 0 .159-.063L18 20h2v2a2.077 2.077 0 0 1-2.143 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FileJpgIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FileJpgIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FileJpgIcon;
