import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** TwoColumnLayoutMainSection is the wrapper for the main section of a layout. */
const TwoColumnLayoutMain = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-l__main", className)} {...other}>
      {children}
    </div>
  );
};

TwoColumnLayoutMain.propTypes = {
  /** children of the container */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TwoColumnLayoutMain.defaultProps = {
  children: null,
  className: ""
};

export default TwoColumnLayoutMain;
