import CartaId from '../../entities/CartaId';

export class UpdateSubscriptionModel {
	subscriptionId?: number;
	
	selectedL2GlobalId?: string;

	selectedL3GlobalId?: string;

	organizationGlobalId?: string;

	environment?: number;

	cartaIds?: CartaId[];

	isProject?: boolean;

	wpmCode?: number;

	closeOutDate?: string;

	isConnected?: boolean;

	amountOfDevices?: number;

	region?: string;

	contributorAccessGroupId?: string;

	initialResourceGroup?: string;

	ownerUserId?: string;

	custodianUserId?: string;

	custodianTwoUserId?: string;

	subscriptionUsedFor: string;

	subsBudget?: number;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}