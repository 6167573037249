import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import FieldListItem from "./FieldListItem";

/** FieldList is an aggregate of many field items. */
const FieldList = props => {
  const { className, children, ...other } = props;

  return (
    <ul
      className={clsx("em-c-field__list  em-js-field-list", className)}
      {...other}
    >
      {children}
    </ul>
  );
};

FieldList.propTypes = {
  /** children is the contents */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

FieldList.defaultProps = {
  children: null,
  className: ""
};

FieldList.Item = FieldListItem;

export default FieldList;
