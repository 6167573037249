import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretRightIcon } from "../../../Icons";

const SolidCardBodyTitle = props => {
  const { link, className, children, ...other } = props;

  return (
    <h3 className={clsx("em-c-solid-card__title", className)} {...other}>
      {children}
      {link && (
        <CaretRightIcon size="small" className="em-c-solid-card__icon--small" />
      )}
    </h3>
  );
};

SolidCardBodyTitle.propTypes = {
  /** link will make a right caret appear, which makes it more obvious that the title is a clickable link */
  link: PropTypes.bool,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

SolidCardBodyTitle.defaultProps = {
  link: false,
  className: "",
  children: null
};

export default SolidCardBodyTitle;
