import React from 'react';
import { Grid, TreeNavigation, ButtonBar, Button, XFilledIcon, SaveDiskIcon, ButtonSize, Alert } from 'react-unity';
import { AbstractDNDRForm, AbstractDNDRFormState, AbstractDNDRFormProps, IDNDRStateForm } from './AbstractDNDRForm';
import WorkflowDetailWrapper from '../common/wrappers/WorkflowDetailWrapper';
import DoNotDecryptRequest from '../../models/entities/DoNotDecryptRequest'; 
import { DNDRTypeInput, EnvironmentInput, RulesTable } from './components';
import { TenantLabel } from '../../models/enums/TenantLabel';
import UserLookup from '../common/form-controls/UserLookup';
import TextAreaField from '../common/form-controls/TextareaField';
import ReadonlyField from '../common/form-controls/ReadonlyField';
import AlertModal from '../common/modals/AlertModal';
import ConfirmModal from '../common/modals/ConfirmModal';
import UpdateDoNotDecryptRequest from '../../models/viewModels/DoNotDecryptRequests/UpdateDoNotDecryptRequest'; 
import UpdateDoNotDecryptRequestRule from '../../models/viewModels/DoNotDecryptRequests/UpdateDoNotDecryptRequestRule'; 
import { WorkflowState } from '../../models/enums/WorkflowState';
import DoNotDecryptRequestsCreate from './DoNotDecryptRequestsCreate';
import { DoNotDecryptRequestsDirection } from '../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import './DoNotDecryptRequestsDetail.css';
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';
import ProgressBarBig from './components/ProgressBarBig';
import { useParams } from 'react-router-dom';

interface DoNotDecryptRequestDetailWithParamsProps extends AbstractDNDRFormProps { }

interface StateForm extends IDNDRStateForm { }

interface DoNotDecryptRequestDetailWithParamsState extends AbstractDNDRFormState<StateForm> {
	loading: boolean;
	doNotDecryptRequests?: DoNotDecryptRequest;
	editMode: boolean;

	decisionModalVisible: {
		approve?: boolean;
		reject?: boolean;
		review?: boolean;
		cancel?: boolean;
	},
	modalComment: string;
	subVirtualNetworks: VirtualNetwork[];
}

class DoNotDecryptRequestDetailWithParams extends AbstractDNDRForm
	<DoNotDecryptRequestDetailWithParamsProps, DoNotDecryptRequestDetailWithParamsState> {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			stateForm: {
				environment: null,
				contact: null,
				isEmergency: false,
				customerCoordination: null,
				businessJustification: '',
				ruleList: [],
			},
			editMode: false,
			submissionWarned: false,
			operationInProgress: false,
			endModal: {
				visible: false,
			},
			submissionAlert: {
				visible: false,
			},

			decisionModalVisible: {},
			modalComment: '',
			subVirtualNetworks: []
		};

		this.initFormModel();
	}

	initFormModel() {
		super.initFormModel();
		this.formModel.fields.doNotDecryptRules.getValue =
			() => this.state.stateForm.ruleList.map(rule => new UpdateDoNotDecryptRequestRule(rule)); 
	}

	async componentWillMount() {
		const id = parseInt(this.props.params.id);
		await this.loadRequest(id);
	}

	loadRequest = async (id) => {
		this.setState(
			{
				loading: true
			},
			async () => {
				try {
					const request = new DoNotDecryptRequest(await this.doNotDecryptRequestService.getById(id)); 

					if (request.direction.value === DoNotDecryptRequestsDirection.OnPremToInternet.value 
						|| request.direction.value === DoNotDecryptRequestsDirection.NECTUpdate.value) { 
						window.location.href = `https://ssppor-portal-prd.azurewebsites.net/DoNotDecryptRequests/${request.id}`;
					}
					
					const subscription = await this.getSubscription(request.subscriptionRequestId);

					const subVirtualNetworks = await this.subscriptionRequestService.getAddressSpaces(subscription.id);
	
					this.setState({
						subscription,
						doNotDecryptRequests: request,
						loading: false,
						subVirtualNetworks
					}, this.resetStateForm);
					
				}	catch (error) {
					const HTTP_FORBIDDEN_ERROR_CODE = 403;
					if (error.status === HTTP_FORBIDDEN_ERROR_CODE) {
						this.handleEndModal('You are not allowed to see this request.', 'error', 5000, '/subscriptionRequests');
					} else {
						this.handleEndModal('The request could not be retrieved.', 'error', 10000, '/subscriptionRequests');
					}
				}
			}
		);
	};

	resetStateForm = () => {
		this.setState((prevState) => (
			{
				stateForm: this.stateFormFromRequest(prevState.doNotDecryptRequests)
			}
		));
	};

	stateFormFromRequest(dndr: DoNotDecryptRequest): StateForm {
		const currentState = this.state.doNotDecryptRequests.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		if(currentState === WorkflowState.Review.value ||currentState === WorkflowState.Draft.value){
			let obj = super.stateFormFromRequest(dndr);
			return {...obj, ruleList: obj.ruleList.map(rule => {return {...rule, azureName: undefined}})}

		};
		return super.stateFormFromRequest(dndr);
	}

	actions = () => (
		!this.state.operationInProgress &&
		<TreeNavigation className="em-u-clickable">		
			{this.state.doNotDecryptRequests?.isEditable() &&
				<>
					<TreeNavigation.Item
						label="Edit"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={this.enableEdit}
					/>
					<br />
					<br />
				</>}
			{(this.state.doNotDecryptRequests?.canHaveGatewayPolicyDecision() || this.state.doNotDecryptRequests?.canHaveSevenDayDecision() || this.state.doNotDecryptRequests?.canHaveCyberDecision() ||
				this.state.doNotDecryptRequests?.canHaveSubscriptionOwnerDecision(this.state.subscription, this.state.doNotDecryptRequests?.custodianApproval)) &&
				<>
					<TreeNavigation.Item
						label="Approve"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('approve'); }}
					/>
					<br />
					<br />
					<TreeNavigation.Item
						label="Reject"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('reject'); }}
					/>
					<br />
					<br />
				</>}
			{this.state.doNotDecryptRequests?.isCancellable() &&
				<>
					<TreeNavigation.Item
						label="Cancel Request"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('cancel'); }}
					/>
					<br />
					<br />
				</>}
			{(this.state.doNotDecryptRequests?.canHaveGatewayPolicyDecision() || this.state.doNotDecryptRequests?.canHaveCyberDecision())  &&
				<TreeNavigation.Item
					label="Send to Review"
					className="em-c-btn em-c-btn--secondary em-c-btn--small"
					onClick={() => { this.openDecisionModal('review'); }}
				/>}
		</TreeNavigation>
	);


	editActions = (size: ButtonSize) => (
		<ButtonBar className='edit-button-bar'>
			{!this.state.operationInProgress &&
				<>
					<ButtonBar.Item>
						<Button
							size={size}
							variant="secondary"
							onClick={this.cancelEdit}
						>
							<XFilledIcon
								size={size}
							/>
							<span>Cancel</span>
						</Button>
					</ButtonBar.Item>
					<ButtonBar.Item separator />
				</>}
			<ButtonBar.Item>
				<Button
					size={size}
					variant="primary"
					disabled={!this.formModel.isValid() || this.state.operationInProgress}
					loading={this.state.operationInProgress}
					onClick={this.handleSave}
				>
					<SaveDiskIcon
						size={size}
					/>
					<span>Send to Review</span>
				</Button>
			</ButtonBar.Item>
		</ButtonBar>
	);

	enableEdit = () => {
		this.setState({
			editMode: true,
		});
	};

	cancelEdit = () => {
		this.setState({ editMode: false });
		this.resetStateForm();
	};

	openDecisionModal = (type: 'approve' | 'reject' | 'review' | 'cancel') => {
		this.setState({
			decisionModalVisible: {
				[type]: true
			}
		});
	};

	closeDecisionModal = () => {
		this.setState({
			decisionModalVisible: {},
			modalComment: '',
		});
	};

	setEmbedAlertSuccess = (message) => {
		this.setState({
			submissionAlert: {
				variant: 'success',
				text: message,
				visible: true,
			}
		}, () => {
			setTimeout(this.handleSubmissionAlertClose, 5000);
		});
	};

	decisionModalConfirmationField = () => ({
		label: 'Please, provide the reason.',
		value: this.state.modalComment,
		validation: {
			required: true,
		},
		onChange: (ev) => {
			this.setState({
				modalComment: ev.target.value,
			});
		},
	});

	handleSave = () => {
		const operation = async () => {
			const model = this.formModel.create(UpdateDoNotDecryptRequest); 
			await this.doNotDecryptRequestService.update(this.state.doNotDecryptRequests?.id, model); 
			this.setState({
				editMode: false,
			});
		};

		this.handleOperationConfirmed({
			operation,
			inProgressMessage: 'Updating request...',
			successMessage: 'Your request was updated successfully.'
		});
	};

	handleApprove = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.doNotDecryptRequestService.approve(this.state.doNotDecryptRequests?.id); 
			},
			inProgressMessage: 'Approving...',
			successMessage: 'Request approved sucessfully.'
		});
	};

	handleReject = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.doNotDecryptRequestService.reject(this.state.doNotDecryptRequests?.id, { 
					rejectionReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Rejecting...',
			successMessage: 'Request rejected sucessfully.'
		});
	};

	handleReview = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.doNotDecryptRequestService.sendToReview(this.state.doNotDecryptRequests?.id, { 
					reviewReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Sending to review...',
			successMessage: 'Request sent to review successfully.',
		});
	};

	handleCancellation = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.doNotDecryptRequestService.cancel(this.state.doNotDecryptRequests?.id, { 
					cancellationReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Cancelling...',
			successMessage: 'Request cancelled successfully.',
		});
	};

	handleOperationConfirmed = async ({
		operation,
		inProgressMessage,
		successMessage,
	}) => {
		this.setSubmissionAlertInProgress(inProgressMessage);
		this.closeDecisionModal();
		try {
			await operation();
			await this.loadRequest(this.state.doNotDecryptRequests?.id);
			this.setEmbedAlertSuccess(successMessage);
		} catch (err) {
			const HTTP_INTERNAL_SERVER_ERROR_CODE = 500;
			if ((err.status === HTTP_INTERNAL_SERVER_ERROR_CODE) && err?.body?.message) {
				await this.loadRequest(this.state.doNotDecryptRequests?.id);
			}
			this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
				modalComment: '',
			});
		}
	};

	render() {
		return (
			(!this.state.loading &&
				this.state.doNotDecryptRequests != null && 
				this.state.doNotDecryptRequests?.isEditable() &&
				this.state.doNotDecryptRequests?.workflowInstance.currentWorkflowInstanceState.workflowState.value === WorkflowState.Draft.value
				
			) ?
				<DoNotDecryptRequestsCreate
					draftRequest={this.state.doNotDecryptRequests}
				/>
				:
				<>
					<WorkflowDetailWrapper
						title={`DPI (Deep Packet Inspection) Exception #${this.state.doNotDecryptRequests?.id}`}
						workflowInstance={this.state.doNotDecryptRequests?.workflowInstance}
						loading={this.state.loading}
						linkTree={this.getLinkTree()}
						actions={this.state.editMode ? this.editActions('small') : this.actions()}
						fromDNDR
					>
						{this.state.submissionAlert.visible &&
						<Alert
							variant={this.state.submissionAlert.variant}
							onClose={this.handleSubmissionAlertClose}
						>
							{this.state.submissionAlert.text}
						</Alert>}
						<Grid variant="3-up">
							<Grid.Item>
								<DNDRTypeInput
									scope={{
										value: this.state.doNotDecryptRequests?.direction.name,
										disabled: true
									}}
								/>
							</Grid.Item>
							{this.state.subscription?.tenant.id === TenantLabel.ExxonMobilTest &&
							<Grid.Item>
								<EnvironmentInput
									{...this.stateFormHandler().environment}
									disabled={this.state.operationInProgress || !this.state.editMode}
								/>
							</Grid.Item>}
							{this.state.doNotDecryptRequests?.implementationTicket() !== '' &&
							<Grid.Item>
								<ReadonlyField
									label="Implementation Ticket"
									text={this.state.doNotDecryptRequests?.implementationTicket()}
									copy
								/>
							</Grid.Item>}
						</Grid>
						<Grid variant="halves">
							<Grid.Item>
								<UserLookup
									label="Technical contact"
									{...this.stateFormHandler().contact}
									disabled={this.state.operationInProgress || !this.state.editMode}
								/>
							</Grid.Item>
						</Grid>
						<Grid variant="halves">
							<Grid.Item>
								{/* <ToggleField
									{...this.stateFormHandler().custodianApproval}
									options={[
										{ value: 'true', text: 'Yes' },
										{ value: 'false', text: 'No' },
									]}
									disabled={this.state.operationInProgress || !this.state.editMode}
								/> */}
								{/* <ToggleField
									{...this.stateFormHandler().sevenDayExemption}
									options={[
										{ value: 'true', text: 'Yes' },
										{ value: 'false', text: 'No' },
									]}
									disabled={this.state.operationInProgress || !this.state.editMode}
								/> */}
							</Grid.Item>
							<Grid.Item>
								<ProgressBarBig
									workflowState={this.state.doNotDecryptRequests?.workflowInstance.currentWorkflowInstanceState.workflowState} dndrSevenDayExemption={this.state.doNotDecryptRequests?.sevenDayExemption}
								/>
							</Grid.Item>
						</Grid>
						<TextAreaField
							label="Business Justification"
							{...this.stateFormHandler().businessJustification}
							disabled={this.state.operationInProgress || !this.state.editMode}
						/>
						<RulesTable
							{...this.stateFormHandler().ruleset}
							dndrDirection={this.state.doNotDecryptRequests?.direction}
							subscriptionRequestName={this.state.subscription?.createdSubscriptionName}
							readonly={this.state.operationInProgress || !this.state.editMode}
							validVirtualNetworks={this.state.subVirtualNetworks}
							loadingImplementedRules={false}
							currentWorkflowState={this.state.doNotDecryptRequests?.workflowInstance.currentWorkflowInstanceState.workflowState.value}
							fromDetails
						/>
						{this.state.editMode && this.editActions('medium')}
					</WorkflowDetailWrapper>
					<AlertModal
						{...this.state.endModal}
						willTimeout={false}
						onClose={this.endAndRedirect}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.approve}
						title={`Approve DPI (Deep Packet Inspection) Exception Request #${this.state.doNotDecryptRequests?.id}`}
						question="Are you sure you want to approve this request?"
						onConfirm={this.handleApprove}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.cancel}
						title={`Cancel DPI (Deep Packet Inspection) Exception Request #${this.state.doNotDecryptRequests?.id}`}
						question="Are you sure you want to cancel this request? This action cannot be undone."
						confirmationField={this.decisionModalConfirmationField()}
						confirmButton={{
							label: 'Cancel Request',
							props: {
								variant: 'primary',
								color: 'negative'
							}
						}}
						onConfirm={() => this.handleCancellation()}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.reject}
						title={`Reject DPI (Deep Packet Inspection) Exception Request #${this.state.doNotDecryptRequests?.id}`}
						question="Are you sure you want to reject this request?"
						confirmationField={this.decisionModalConfirmationField()}
						onConfirm={() => this.handleReject()}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.review}
						title={`Confirm Revision for DPI (Deep Packet Inspection) Exception Request #${this.state.doNotDecryptRequests?.id}`}
						question="Are you sure you want to send this request to review?"
						confirmationField={this.decisionModalConfirmationField()}
						onConfirm={() => this.handleReview()}
						onCancel={this.closeDecisionModal}
					/>
				</>
		);
	}
}

const DoNotDecryptRequestDetail = () => {

    return <DoNotDecryptRequestDetailWithParams params={useParams()}/>;
}

export default DoNotDecryptRequestDetail;