import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TableBodyRow from "./TableBodyRow";
import TableBodyCell from "./TableBodyCell";
import TableBodyGroupedRow from "./TableBodyGroupedRow";

/** TableBody is where the data goes inside the table */
const TableBody = props => {
  const { className, children, ...other } = props;

  return (
    <tbody className={clsx("em-c-table__body", className)} {...other}>
      {children}
    </tbody>
  );
};

TableBody.propTypes = {
  /** children are the contents of the TableBody */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TableBody.defaultProps = {
  children: null,
  className: ""
};

TableBody.Row = TableBodyRow;
TableBody.Cell = TableBodyCell;
TableBody.GroupedRow = TableBodyGroupedRow;

export default TableBody;
