import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PlugIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M9.5 8h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM6 7h3V4H6zM6 1h1v2H6zM8 1h1v2H8z"
          fill="currentColor"
        />
        <path
          d="M10.5 15A3.5 3.5 0 0 1 7 11.5V7h1v4.5a2.5 2.5 0 0 0 5 0V10h1v1.5a3.5 3.5 0 0 1-3.5 3.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M26 24H14a3 3 0 0 1-3-3V11a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v10a3 3 0 0 1-3 3zM13 12v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-9zM15 2h2v6h-2zM23 2h2v6h-2z"
          fill="currentColor"
        />
        <path
          d="M28 44a8.914 8.914 0 0 1-9-8.8V22h2v13.2a7 7 0 0 0 14 0v-3.444h2V35.2a8.914 8.914 0 0 1-9 8.8z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M15 13H8a2 2 0 0 1-2-2V5a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v6a2 2 0 0 1-2 2zM8 6v5h7V6z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M8 .5h2v4H8zM13 .5h2v4h-2zM16 24c-3.42 0-6-2.149-6-5v-8h2v8c0 1.738 1.682 3 4 3a3.163 3.163 0 0 0 3-3v-3h2v3a5.131 5.131 0 0 1-5 5z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PlugIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PlugIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PlugIcon;
