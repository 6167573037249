import User from './User';
import { WorkflowState } from './enums/WorkflowState';

export default class WorkflowInstanceState {
	workflowInstanceId!: number;

	workflowState!: WorkflowState;

	performedByUser!: User;

	performedOnDate!: Date;

	performedByRoles!: string;

	performedReason!: string;

	ticketId!: string;

	constructor(obj: any) {
		Object.assign(this, obj);
		this.workflowState = new WorkflowState().fromValue(obj.workflowState?.value);
	}
}