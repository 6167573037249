import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { TypeaheadContext } from "../Typeahead.jsx";
import TypeaheadItemSuggestion from "./TypeaheadItemSuggestion";

function TypeaheadItem(props) {
  const { className, value, children, ...other } = props;
  const { onChange } = useContext(TypeaheadContext);

  function handleItemClick(e) {
    if (onChange) {
      onChange(e, value);
    }
  }

  return (
    <li
      className={clsx("em-c-typeahead-list__item", className)}
      onClick={handleItemClick}
      {...other}
    >
      {children}
    </li>
  );
}

TypeaheadItem.Suggestion = TypeaheadItemSuggestion;

TypeaheadItem.defaultProps = {
  className: ""
};

TypeaheadItem.propTypes = {
  /** Additional classname */
  className: PropTypes.string,
  /** Unique item value */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default TypeaheadItem;
