import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const User2MinusIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M9 13h5v1H9zM9.5 8h-3A1.5 1.5 0 0 1 5 6.5V4a3 3 0 0 1 6 0v2.5A1.5 1.5 0 0 1 9.5 8zM8 2a2 2 0 0 0-2 2v2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V4a2 2 0 0 0-2-2zM3 16H2v-1a6.007 6.007 0 0 1 6-6h.5v1H8a5.006 5.006 0 0 0-5 5z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M26 22h-6a7.008 7.008 0 0 1-7-7v-3a10 10 0 0 1 20 0v3a7.008 7.008 0 0 1-7 7zM23 4a8.009 8.009 0 0 0-8 8v3a5.006 5.006 0 0 0 5 5h6a5.006 5.006 0 0 0 5-5v-3a8.009 8.009 0 0 0-8-8zM7 46H5v-2a18.021 18.021 0 0 1 18-18h2v2h-2A16.018 16.018 0 0 0 7 44zM29 36h14v2H29z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M13 11h-3a4 4 0 0 1-4-4V5.5a5.5 5.5 0 0 1 11 0V7a4 4 0 0 1-4 4zm-1.5-9A3.5 3.5 0 0 0 8 5.5V7a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V5.5A3.5 3.5 0 0 0 11.5 2zM4 23H2v-1.5a9.511 9.511 0 0 1 9.5-9.5H13v2h-1.5A7.508 7.508 0 0 0 4 21.5zM14 17h8v2h-8z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

User2MinusIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

User2MinusIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default User2MinusIcon;
