import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LinkBrokenIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M4.615 15.482l1.32-1.98.832.555-1.32 1.98zM9.234 14.057l.832-.555 1.32 1.98-.832.555zM9.233 2.943l1.32-1.98.833.555-1.32 1.98zM4.614 1.517l.832-.555 1.32 1.98-.832.555zM4 8h3v1H4zM9 8h3v1H9z"
        />
        <path
          d="M6.5 12h-6a.5.5 0 0 1-.5-.5v-6A.5.5 0 0 1 .5 5h6a.5.5 0 0 1 .5.5V7H6V6H1v5h5v-1h1v1.5a.5.5 0 0 1-.5.5zM15.5 12h-6a.5.5 0 0 1-.5-.5V10h1v1h5V6h-5v1H9V5.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M13.613 46.278l5.11-7.664 1.664 1.11-5.11 7.663zM27.613 39.723l1.664-1.11 5.11 7.664-1.665 1.11zM27.613 9.277l5.11-7.664 1.663 1.11-5.109 7.664zM13.613 2.723l1.664-1.11 5.11 7.664-1.665 1.11zM12 24h10v2H12zM25 24h10v2H25z"
        />
        <path
          d="M19 36H3a3 3 0 0 1-3-3V17a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v5h-2v-5a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-5h2v5a3 3 0 0 1-3 3zM44 36H28a3 3 0 0 1-3-3v-5h2v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V17a1 1 0 0 0-1-1H28a1 1 0 0 0-1 1v5h-2v-5a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M5.586 22L9 18.586l1.414 1.415L7 23.414zM13.586 20L15 18.585 18.414 22 17 23.414zM13.586 4L17 .585 18.414 2 15 5.414zM5.586 2L7 .587 10.414 4 9 5.415zM6 11h12v2H6z"
        />
        <path
          d="M10 18H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v3H9V8H2v8h7v-2h2v3a1 1 0 0 1-1 1zM23 18h-9a1 1 0 0 1-1-1v-3h2v2h7V8h-7v2h-2V7a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LinkBrokenIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LinkBrokenIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default LinkBrokenIcon;
