import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretRightIcon } from "../../Icons";

/** TreeNavigationDropdown is a node ina  tree navigation that lets you add children to it */
const TreeNavigationDropdown = props => {
  const { expanded, label, className, children, ...other } = props;

  const [open, setOpen] = useState(expanded || false);

  return (
    <li className={clsx("em-c-tree__item", className)} {...other}>
      <a
        onClick={() => setOpen(prev => !prev)}
        className={clsx(
          "em-c-tree__link",
          "em-c-tree__link--has-children",
          "em-js-tree-dropdown-trigger",
          "em-u-clickable",
          {
            "em-is-active": open
          }
        )}
        role="tab"
      >
        <span className="em-c-tree__text">{label}</span>
        <CaretRightIcon
          size="small"
          className="em-c-tree__icon em-block-icon"
        />
      </a>
      <ul className="em-c-tree__list em-js-tree-dropdown" id="" role="tabpanel">
        {open && children}
      </ul>
    </li>
  );
};

TreeNavigationDropdown.propTypes = {
  /** label is the text that is on the nav item */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** determins if the dropdown will be initially dropped-down or not */
  expanded: PropTypes.bool,
  /** children are the contents of the TreeNavigationDropdown */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TreeNavigationDropdown.defaultProps = {
  label: "",
  expanded: false,
  children: PropTypes.node,
  className: ""
};

export default TreeNavigationDropdown;
