import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MapIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M.5 15a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .314-.464l5-2a.5.5 0 0 1 .371 0L10.5 2.961l4.814-1.926A.5.5 0 0 1 16 1.5v11a.5.5 0 0 1-.314.464l-5 2a.5.5 0 0 1-.371 0L5.5 13.039.686 14.964A.5.5 0 0 1 .5 15zm5-3a.5.5 0 0 1 .186.036l4.814 1.925 4.5-1.8V2.238l-4.314 1.726a.5.5 0 0 1-.371 0L5.5 2.039 1 3.838v9.923l4.314-1.726A.5.5 0 0 1 5.5 12zm10 .5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M5 2h1v11H5zM10 3h1v11h-1z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M31.98 41a1.985 1.985 0 0 1-.669-.116l-15.3-5.5-12.259 5.047a1.977 1.977 0 0 1-1.852-.188 2.029 2.029 0 0 1-.9-1.69V10.378A2.019 2.019 0 0 1 2.247 8.5l13.022-5.351a1.978 1.978 0 0 1 1.42-.033l15.3 5.5 12.259-5.047a1.978 1.978 0 0 1 1.855.188 2.029 2.029 0 0 1 .9 1.69v28.175a2.019 2.019 0 0 1-1.25 1.878l-13.022 5.351a1.967 1.967 0 0 1-.751.149zm-15.96-7.614a1.985 1.985 0 0 1 .669.116l15.3 5.5 13-5.35L45 5.447l-12.269 5.018a1.967 1.967 0 0 1-1.42.033L16.013 5l-13 5.351L3 38.553l12.269-5.018a1.967 1.967 0 0 1 .751-.149zm29.353 1.189zm-.381-.924z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M15 5h2v30h-2zM31 10h2v30h-2zM7.167 24.089l-.094-1A16.536 16.536 0 0 1 7 21.5v-1h2v1c0 .486.021.958.063 1.4l.095 1z"
        />
        <path
          d="M15.977 31c-.332 0-.657-.011-.974-.033L14 30.9l.136-2 1 .068c.279.019.559.029.86.028a9.614 9.614 0 0 0 1.846-.164l.981-.193.387 1.962-.981.193a14.435 14.435 0 0 1-2.252.206zm-4.359-.735l-.857-.514A6.941 6.941 0 0 1 8.334 27.3l-.51-.86 1.721-1.02.51.86a4.893 4.893 0 0 0 1.732 1.751l.858.514zm9.908-.888l-1.48-1.345.672-.74a7.592 7.592 0 0 0 1.342-2.257l.383-.924 1.848.766-.383.924a9.488 9.488 0 0 1-1.708 2.836zm3.374-6.446l-1.883-.676.338-.941a13.076 13.076 0 0 1 1.387-2.914l.555-.832 1.664 1.109-.555.833a11.18 11.18 0 0 0-1.169 2.475zm13.483-4.12l-.78-.626a5.167 5.167 0 0 0-2.293-.931l-.982-.189.378-1.964.981.189a7.075 7.075 0 0 1 3.168 1.335l.78.626zm-10.355-1l-.694-1.875.938-.347a10.336 10.336 0 0 1 3.228-.584l1-.034.069 2-1 .035a8.356 8.356 0 0 0-2.6.46zM41 23.5h-2v-1a12.474 12.474 0 0 0-.07-1.388l-.111-.994 1.987-.223.112.994A14.56 14.56 0 0 1 41 22.5zM8.5 19a3.5 3.5 0 1 1 3.5-3.5A3.5 3.5 0 0 1 8.5 19zm0-5a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 8.5 14zM37.086 27.5l1.415-1.414 4.413 4.414-1.414 1.414z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M37.086 30.5l4.414-4.414 1.414 1.414-4.413 4.414z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M1 22.05a1 1 0 0 1-1-1V6.252a1 1 0 0 1 .628-.928l7-2.8a.989.989 0 0 1 .7-.016l7.65 2.678 6.649-2.662A1 1 0 0 1 24 3.45v14.8a1 1 0 0 1-.628.928l-7 2.8a1 1 0 0 1-.7.016l-7.65-2.678-6.65 2.662A.994.994 0 0 1 1 22.05zm7-4.8a.993.993 0 0 1 .33.056l7.65 2.678L22 17.571V4.928L16.374 7.18a1 1 0 0 1-.7.016L8.021 4.518 2 6.929v12.643l5.626-2.252A1 1 0 0 1 8 17.248zm15 1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M7 4h2v15H7zM15 6h2v16h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MapIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MapIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MapIcon;
