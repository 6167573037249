import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import StackedBlockHeaderTitle from "./StackedBlockHeaderTitle";
import StackedBlockHeaderLink from "./StackedBlockHeaderLink";

const StackedBlockHeader = props => {
  const { className, children, ...other } = props;
  return (
    <div className={clsx("em-c-stacked-block__header", className)} {...other}>
      {children}
    </div>
  );
};

StackedBlockHeader.Title = StackedBlockHeaderTitle;
StackedBlockHeader.Link = StackedBlockHeaderLink;

StackedBlockHeader.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

StackedBlockHeader.defaultProps = {
  className: "",
  children: null
};

export default StackedBlockHeader;
