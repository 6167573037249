import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import VerticalHeaderBodyBandIcon from "./VerticalHeaderBodyBandIcon";
import VerticalHeaderBodyBandItem from "./VerticalHeaderBodyBandItem";

/** Vertical Header Band */
const VerticalHeaderBodyBand = props => {
  const {
    className,
    children,
    isMobileMenuOpen,
    onMobileMenuClick,
    ...other
  } = props;
  return (
    <div className={clsx("em-c-solid-nav", className)} {...other}>
      <ul className="em-c-solid-nav__list">
        {React.Children.toArray(children).map((child, i) => (
          <li key={i} className="em-c-solid-nav__item">
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
};

VerticalHeaderBodyBand.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node,
  /** Automatically passed by the VerticalHeader to indicate if the mobile menu is open */
  isMobileMenuOpen: PropTypes.bool,
  /** Automatically passed by the VerticalHeader to change if the mobile menu is open */
  onMobileMenuClick: PropTypes.func
};

VerticalHeaderBodyBand.defaultProps = {
  className: "",
  children: null
};

VerticalHeaderBodyBand.Item = VerticalHeaderBodyBandItem;
VerticalHeaderBodyBand.Icon = VerticalHeaderBodyBandIcon;

export default VerticalHeaderBodyBand;
