/* eslint-disable no-useless-escape */
import * as React from 'react';
import {
	Modal,
	Button,
	Grid,
	XFilledIcon,
	ProgressBar
} from 'react-unity';
import './RuleModal.css';
import TextAreaField from '../../../common/form-controls/TextareaField';
import ToggleField from '../../../common/form-controls/ToggleField';
import TextInputField from '../../../common/form-controls/TextInputField';
import { AbstractRulesForm, IRuleStateForm, RulesFormProps } from './AbstractRulesForm';
import { RuleAction } from '../../../../models/enums/DNDR/RuleAction';
import { RuleRegion } from '../../../../models/enums/DNDR/RuleRegion';
import { VirtualNetwork } from '../../../../models/entities/AzureResources/VirtualNetwork';
import SelectField from '../../../common/form-controls/SelectField';
import MultipleSelectField from '../../../common/form-controls/MultipleSelectField';
import { RuleOperation } from '../../../../models/enums/DNDR/RuleOperation';
import { DoNotDecryptRequestsDirection } from '../../../../models/enums/DNDR/DoNotDecryptRequestsDirection';
import { Address4, Address6 } from 'ip-address';
import { Option } from '../../../../utils/Option';

interface RuleModalSubToInternetProps extends RulesFormProps{
	subscriptionRequestName: string;
}




interface SubToInternetRuleStateForm extends IRuleStateForm {
	name: string;
	destinationURLs: string;
}
interface RuleModalSubToInternetState {
	stateForm: SubToInternetRuleStateForm;
	options: Option[]
}

export default class RuleModalSubToInternet extends AbstractRulesForm<
	RuleModalSubToInternetProps,
	RuleModalSubToInternetState
> {

	constructor(props: RuleModalSubToInternetProps) {
		super(props);
		this.state = {
			stateForm: this.props.isFromHistory ? this.getRuleCopy() : this.getDefaultForm(),
			options: null
		};

		this.initFormModel();
	}
	
	isURLValid = (url) => /^(((http(s)?:\/\/.)?(www\.)?)|(\*\.)?)[a-zA-Z0-9]+[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(url);

	initFormModel() {
		super.initFormModel();
		this.formModel.addField('name', {
			getValue: () => this.state.stateForm.name,
			validation: {
				required: true,
				rules:
					[
						{
							assert: () => this.state.stateForm.operation !== RuleOperation.Add
								|| this.state.stateForm.name?.length <= 24,
							message: 'Name cannot be longer than 24 characters.',
						},
						{
							assert: () => !this.state.stateForm.name?.includes(' '),
							message: 'Name cannot contain spaces.',
						},
						{
							assert: () => this.state.stateForm.operation !== RuleOperation.Add
							|| /^[a-zA-Z](?:[a-zA-Z_-]*[a-zA-Z])?$/.test(this.state.stateForm.name ?? ''),
							message: 'Only letters (uppercase and lowercase), dashes, underscores are allowed. No trailing or beginning dashes or underscores.',
						}
					]
			},
		});	

		// this.formModel.fields.destinationIPs.validation.required =
		// 	() => !(this.state.stateForm.destinationURLs?.trim().length > 0);
		
		// this.formModel.fields.destinationIPs.validation.rules.push(
		// 	this.IpInputValidationRule({
		// 		onlyPrivateAddresses: false
		// 	})
		// );

		const numberOfDestinationItemsValidation = {
			assert: () => {
				//const numberOfIPs = this.state.stateForm.destinationIPs?.split(';').filter(s => s !== '').length ?? 0;
				const numberOfURLs = this.state.stateForm.destinationURLs?.split(';').filter(s => s !== '').length ?? 0;
				const numberOfDestinationItems =  numberOfURLs;
				const maxNumberOfDestinationItems = 50;
				return numberOfDestinationItems <= maxNumberOfDestinationItems;
			},
			message: "Total Destination URLs and IPs can't be greater than 50"
		}

		this.formModel.addField('destinationURLs', {
			getValue: () => this.parseMultipleValues(this.state.stateForm.destinationURLs),
			validation: {
				required: () => true,//!(this.state.stateForm.destinationIPs?.trim().length > 0),
				rules: [
					{
						assert: () => !this.hasEmptyValues(this.state.stateForm.destinationURLs),
						message: 'Empty values are not allowed.'
					},
					{
						assert: () => !(this.state.stateForm.destinationURLs.includes('https://')
						||this.state.stateForm.destinationURLs.includes('http://')),
						message: 'URL cannot have "http" or "https"'
					},
					{
						assert: (value) => {
							const urls = this.parseMultipleValues(value)?.split(';');
							return urls?.every(url => this.isURLValid(url));
						},
						message: (value) => {
							const invalidAddress = this.parseMultipleValues(value)?.split(';').find(url => !this.isURLValid(url));
							return `${invalidAddress} is an invalid URL.`;
						}
					},
					numberOfDestinationItemsValidation
				]
			}
		});

		// this.formModel.fields.destinationIPs.validation.rules.push(numberOfDestinationItemsValidation);

		// this.formModel.fields.destinationPorts.validation.rules.push(
		// 	this.PortInputValidationRule({
		// 		minimumPort: 1
		// 	})
		// );
	}

	// eslint-disable-next-line class-methods-use-this
	getDefaultFormState() {
		return {
			operation: RuleOperation.Add,
			name: '',
			destinationURLs: ''
		};
	}

	getRegionOptions(): RuleRegion[] {
		var subToInternetRegions = new RuleRegion().listByDirection(DoNotDecryptRequestsDirection.SubscriptionToInternet);
		const wwSubscriptionsNames = ['APPS_SharedSvcs_WW_DEV', 'APPS_SharedSvcs_WW_LAB', 'APPS_SharedSvcs_WW_PRD'];
		return wwSubscriptionsNames.includes(this.props.subscriptionRequestName) ? subToInternetRegions : subToInternetRegions.filter(region => region !== RuleRegion.WW);
	}

	getSourceIPOptions() {
		const subnets =  this.props.validVirtualNetworks.flatMap(vNet => vNet.properties.subnets);
		const addressSpaces =  subnets.flatMap(subnet => subnet.properties.addressPrefix);
		let options = [];
		for (var count = 0; count < addressSpaces.length; count++) {

			let start = new Address4(addressSpaces[count]).startAddress()
			let end  = new Address4(addressSpaces[count]).endAddress()
			options.push({												//Each subnet gets a CheckboxOption
				value: addressSpaces[count],							//Value is a string of the subnet. ex: 192.33.144.92/28
				text: addressSpaces[count] +" ("+start.correctForm()+" - "+end.correctForm()+")"  , //Label is the subnet + Source IP Range. ex: 192.33.144.92/28.....(192.33.144.81 - 192.33.144.94)
			});

		}
		return options;
	}

	options = this.getSourceIPOptions()

	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window className="ruleModal">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							{this.props.isReadOnly ? 'View Rule' : !this.isModalForNewRule() ? 'Edit Rule' : 'New Rule'}
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						{ !this.isModalForNewRule() &&
							<ToggleField
								{...this.stateFormHandler().operation}
								label="Operation"
								disabled={this.props.isReadOnly}
							/>
						}
						<Grid variant="2-up">
							<Grid.Item>
								<TextInputField
									label="Rule Name"
									value={this.state.stateForm.name}
									validation={this.formModel.fields.name.validation}
									onChange={
										(event) => {
											this.handleStateFormChange('name', event.target.value);
										}
									}								
									disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
								/>
							</Grid.Item>
							<Grid.Item>
								<SelectField
									{...this.stateFormHandler().region}
									options={this.getRegionOptions()}
									disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
								/>
							</Grid.Item>
						</Grid>
						{this.state.stateForm.operation?.name !== RuleOperation.Remove.name &&
							<>
								<Grid variant="2-up">
									<Grid.Item>
									</Grid.Item>
									<Grid.Item /> 
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
									{this.state.options ?
										<ProgressBar
											label='Loading form...'
											className="em-u-margin-top-half"
											indeterminate
											hideValueLabel
										/> :										
										<MultipleSelectField
											{...this.stateFormHandler().sourceIPs}
											options={this.getSourceIPOptions()}
											label="Source (IPs / IP Range / CIDR)"
											disabled={!this.formCanBeEdited()}
											note='Multiple values are allowed, separated by semicolons.'
										/>
									}
									</Grid.Item>
									{/* <Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().destinationIPs}
											label="Destination (IPs / IP range / CIDR)"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item> */}
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().description}
											label="Description"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<TextAreaField
											label="Destination (URLs)"
											value={this.state.stateForm.destinationURLs}
											validation={this.formModel.fields.destinationURLs.validation}
											onChange={
												(event) => {
													this.handleStateFormChange('destinationURLs', event.target.value);
												}
											}
											note='Multiple values are allowed, separated by semicolons.'
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
								</Grid>
							</>}
					</Modal.Window.Body>
					{ !this.props.isReadOnly &&
						<Modal.Window.Footer>
							<Grid variant="2-up">
								<Grid.Item>
									<Button
										variant="primary"
										disabled={!this.formModel.isValid() && this.state.stateForm.operation.name !== RuleOperation.Remove.name}						
										onClick={this.handleRequest}
									>
										{this.props.editingRule ? 'Confirm' : 'Create'}
									</Button>
								</Grid.Item>
								<Grid.Item>
									<Button
										variant="secondary"
										onClick={this.handleCancel}
									>
										Cancel
									</Button>
								</Grid.Item>
							</Grid>
						</Modal.Window.Footer>
					}

				</Modal.Window>
			</Modal>
		);
	}
}