import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import PromoBlockTitle from "./PromoBlockTitle";
import PromoBlockDescription from "./PromoBlockDescription";
import PromoBlockActions from "./PromoBlockActions";

/**
 * Strong color field to drive a call to action
 *
 * @visibleName Promo block
 * */
const PromoBlock = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-promo-block", className)} {...other}>
      {children}
    </div>
  );
};

PromoBlock.Title = PromoBlockTitle;
PromoBlock.Description = PromoBlockDescription;
PromoBlock.Actions = PromoBlockActions;

PromoBlock.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

PromoBlock.defaultProps = {
  className: "",
  children: null
};

export default PromoBlock;
