import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FilePdf2Icon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M4.559 7.142a1.945 1.945 0 0 1-.12.668 1.486 1.486 0 0 1-.342.548 2.076 2.076 0 0 1-.679.455 2.458 2.458 0 0 1-.932.154H1.67v1.658H.357V5.536h2.16a3.415 3.415 0 0 1 .818.083 1.928 1.928 0 0 1 .59.251 1.352 1.352 0 0 1 .47.516 1.626 1.626 0 0 1 .164.756zM3.2 7.171a.6.6 0 0 0-.116-.37.62.62 0 0 0-.271-.216 1.221 1.221 0 0 0-.4-.091c-.13-.005-.3-.008-.52-.008H1.67v1.521h.376a3.053 3.053 0 0 0 .554-.041.786.786 0 0 0 .362-.166.62.62 0 0 0 .183-.261 1.056 1.056 0 0 0 .055-.368zM10.717 8.085a2.5 2.5 0 0 1-.324 1.273 2.374 2.374 0 0 1-.82.863 2.524 2.524 0 0 1-.817.314 5.449 5.449 0 0 1-1.053.089H5.908V5.536h1.846a4.828 4.828 0 0 1 1.072.1 2.513 2.513 0 0 1 .76.3 2.309 2.309 0 0 1 .828.866 2.611 2.611 0 0 1 .303 1.283zm-1.357-.01a1.875 1.875 0 0 0-.182-.859 1.286 1.286 0 0 0-.58-.559 1.527 1.527 0 0 0-.412-.135 4.173 4.173 0 0 0-.634-.036h-.331v3.185h.331a3.971 3.971 0 0 0 .687-.042 1.5 1.5 0 0 0 .428-.156 1.27 1.27 0 0 0 .526-.548 1.922 1.922 0 0 0 .166-.85zM15.785 6.52h-2.342v.947h2.171v.984h-2.171v2.174h-1.3V5.536h3.646zM3 4H2V2.5A1.5 1.5 0 0 1 3.5 1h6.506a1.491 1.491 0 0 1 1.061.439l1.7 1.7-.707.707-1.7-1.7A.5.5 0 0 0 10.006 2H3.5a.5.5 0 0 0-.5.5zM12.5 15h-9A1.5 1.5 0 0 1 2 13.5V12h1v1.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V12h1v1.5a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 34H5a3 3 0 0 1-3-3V18a3 3 0 0 1 3-3h38a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3zM5 17a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1V18a1 1 0 0 0-1-1zM39 13.414l-7.527-7.527A3 3 0 0 0 29.34 5H9a1 1 0 0 0-1 1v7H6V6a3 3 0 0 1 3-3h20.34a4.983 4.983 0 0 1 3.547 1.473L40.414 12zM39 45H9a3 3 0 0 1-3-3v-6h2v6a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-6h2v6a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M10.693 22.244a.733.733 0 0 1 .744-.744H14c1.967 0 3.19 1.115 3.19 2.807v.024c0 1.883-1.511 2.866-3.358 2.866h-1.663v2.026a.738.738 0 0 1-1.476 0zm3.19 3.622c1.116 0 1.812-.624 1.812-1.5v-.023c0-.983-.708-1.5-1.812-1.5h-1.714v3.022zM18.71 22.244a.732.732 0 0 1 .743-.744h2.387a4.185 4.185 0 0 1 4.46 4.174v.026a4.2 4.2 0 0 1-4.462 4.2h-2.385a.732.732 0 0 1-.743-.743zm3.13 6.308a2.735 2.735 0 0 0 2.914-2.83V25.7a2.754 2.754 0 0 0-2.914-2.854h-1.654v5.708zM28.094 22.244a.733.733 0 0 1 .743-.744h4.893a.672.672 0 0 1 0 1.344h-4.162v2.29h3.623a.672.672 0 0 1 0 1.344h-3.623v2.746a.737.737 0 0 1-1.475 0z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M8.559 11.395a1.945 1.945 0 0 1-.12.668 1.486 1.486 0 0 1-.342.548 2.076 2.076 0 0 1-.679.455 2.458 2.458 0 0 1-.932.154H5.67v1.658H4.357v-5.09h2.16a3.415 3.415 0 0 1 .818.083 1.928 1.928 0 0 1 .59.251 1.352 1.352 0 0 1 .47.516 1.626 1.626 0 0 1 .164.757zm-1.359.029a.6.6 0 0 0-.116-.37.62.62 0 0 0-.271-.216 1.221 1.221 0 0 0-.4-.091c-.13-.005-.3-.008-.52-.008H5.67v1.521h.376a3.053 3.053 0 0 0 .552-.041.786.786 0 0 0 .364-.164.62.62 0 0 0 .183-.261 1.056 1.056 0 0 0 .055-.37zM14.717 12.338a2.5 2.5 0 0 1-.324 1.273 2.374 2.374 0 0 1-.82.863 2.524 2.524 0 0 1-.817.314 5.449 5.449 0 0 1-1.053.089H9.908V9.788h1.846a4.828 4.828 0 0 1 1.072.1 2.513 2.513 0 0 1 .76.3 2.309 2.309 0 0 1 .828.866 2.611 2.611 0 0 1 .303 1.284zm-1.357-.01a1.875 1.875 0 0 0-.182-.859 1.286 1.286 0 0 0-.58-.559 1.527 1.527 0 0 0-.412-.135 4.173 4.173 0 0 0-.634-.036h-.331v3.186h.331a3.971 3.971 0 0 0 .687-.042 1.5 1.5 0 0 0 .428-.156 1.27 1.27 0 0 0 .526-.548 1.922 1.922 0 0 0 .166-.851zM19.785 10.772h-2.342v.947h2.171v.981h-2.171v2.174h-1.3V9.788h3.646z"
          fill="currentColor"
        />
        <path
          d="M23 19H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1zM2 17h20V8H2zM19.151 5.406L16.654 3.4a.879.879 0 0 1-.081-.073C16.265 3.013 16.175 3 16 3H5v2H3V3a1.979 1.979 0 0 1 2-2h11a2.609 2.609 0 0 1 1.949.87l2.457 1.978zM17.857 24H5.143A2.077 2.077 0 0 1 3 22v-2h2v2c0-.042.069 0 .143 0h12.714a.234.234 0 0 0 .159-.063L18 20h2v2a2.077 2.077 0 0 1-2.143 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FilePdf2Icon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FilePdf2Icon.defaultProps = {
  size: "medium",

  className: ""
};

export default FilePdf2Icon;
