import React, { useState, useEffect } from 'react';
import {
	Table,
	TextPassage,
	TextLink,
	ProgressBar,
	AlertVariant,
	Alert,
} from 'react-unity';
import { Link, useNavigate } from 'react-router-dom';
import SectionHomeWrapper from '../../common/wrappers/SectionHomeWrapper';
import './OrganizationsHome.css';
import Paginator from '../../common/tables/Paginator';
import { AlertBanner } from '../../../models/interfaces/AlertBanner';
import AlertModal from '../../common/modals/AlertModal';
import OrganizationsService from '../../../services/OrganizationsService';
import OrganizationHomeTableRow from './OrganizationHomeTableRow';
import OrganizationsHomeFilters from './OrganizationsHomeFilters';
import OrganizationTuple from '../../../models/entities/OrganizationTuple';
import { UserRole } from '../../../models/enums/UserRole';
import { authenticatedUser } from '../../../authentication/authModule';
import Organization from '../../../models/entities/Organization';
import ConfirmModal from '../../common/modals/ConfirmModal';
import OrganizationModal from '../components/OrganizationModal';
import { refreshOrganizations } from '../../common/OrganizationStorage';

const memoized = {
	myOrgs: void 0,
	allOrgs: void 0,
}

interface Dictionary {
	[Key: string]: boolean;
}

interface DecisionModalInterface {
	deleteOrg?: boolean;
	createOrEditOrg?: boolean;
}

const OrganizationsHome = () => {
	const [allOrganizations, setAllOrganizations] = useState<OrganizationTuple[]>([]);
	const [filteredOrganizations, setFilteredOrganizations] = useState<OrganizationTuple[]>([]);
	const [organizationsOnPage, setOrganizationsOnPage] = useState<OrganizationTuple[]>([]);
	const [selectedOrganization, setSelectedOrganization] = useState<Organization>(null);
	const [areMyOrgsLoading, setAreMyOrgsLoading] = useState<boolean>(true);
	const [progressLabel, setProgressLabel] = useState<string>('Loading your organizations...');
	const [endModal, setEndModal] = useState<AlertBanner>({ visible: false });
	const [alertEditMode, setAlertEditMode] = useState<boolean>(false);
	const [editMultiple, setEditMultiple] = useState<boolean>(false);
	const [open, setOpen] = useState<Dictionary>();
	const [isEditting, setIsEditting] = useState<boolean>(false);
	const [selectedL3, setL3] = useState<Organization>(null);
	const [decisionModalVisible, setDecisionModalVisible] = useState<DecisionModalInterface>({
		deleteOrg: false,
		createOrEditOrg: false
	});
	const [alert, setAlertBanner] = useState<AlertBanner>({
		visible: false,
		variant: 'error',
		text: ''
	});
	const [refreshKey, setRefreshKey] = useState<boolean>(false);
	const [editingL5, setEditingL5] = useState<boolean>(false);
	const organizationsService = new OrganizationsService();
	const navigate = useNavigate();

	async function getOrgs(method: string, key: string) {
		if (typeof memoized[key] !== 'undefined') return memoized[key];
		let data = [];
		data = await organizationsService[method]();
		memoized[key] = data;
		return data;
	}

	function refreshCache() {
		refreshOrganizations();
		memoized.myOrgs = void 0;
		memoized.allOrgs = void 0;
	}

	useEffect(() => {
		if (!authenticatedUser.isInRole(UserRole.OrgAdmins)) {
			handleEndModal('You are not allowed to submit a request.', 'error', 5000);
		}
		retreiveOrganizations(false);
	}, []);


	const retreiveOrganizations = async (isRefresh: boolean) => {
		setAreMyOrgsLoading(true);
		setProgressLabel(`${isRefresh ? 'Reloading' : 'Loading'} your organizations...`);

		try {
			if (isRefresh) refreshCache();
			const orgs = await getOrgs('getAllL3Organizations', 'myOrgs');
			setAllOrganizations(orgs);
			orgs.map((org) => {
				setOpen({ ...open, [org.item1]: false })
				org.item2.map((l4) => setOpen({ ...open, [l4.item1.displayName]: false }))
			});
			setAreMyOrgsLoading(false);
			setAlertBanner({ visible: false })
		} catch (err) {
			handleEndModal('Organizations could not be retrieved.', 'error', 10000);
		}
	};

	const handleEndModal = (text: string, variant: AlertVariant, timeout: number) => {
		setEndModal({
			visible: true,
			text,
			variant,
		});
		setTimeout(() => endAndRedirect(), timeout);
	};

	const endAndRedirect = () => {
		setEndModal({ visible: false });
		navigate('/');
	};

	const getFilterProps = () => {
		return {
			filteredOrganizations,
			setFilteredOrganizations,
			areMyOrgsLoading,
			retreiveOrganizations,
			allOrganizations,
			setEditMultiple,
			editMultiple,
			organizationsOnPage,
			setDecisionModalVisible,
			setL3
		};
	};

	const handleRefresh = () => {
		retreiveOrganizations(true);
	};

	const handleModalEditModeConfirmed = async () => {
		closeDecisionModal();
		setAlertEditMode(true);
		setTimeout(() => {
			setAlertEditMode(false);
		}, 5000);
		handleRefresh();
	};

	const handleConfirm = async () => {
		window.scroll(0, 0);
		setDecisionModalVisible({});
		setAlertBanner({
			visible: true,
			text: `Deleting organization...`
		});
		try {
			await organizationsService.deleteOrganization(selectedOrganization)
			handleRefresh();
			setAlertBanner({
				visible: true,
				variant: 'success',
				text: `Organization deleted successfully.`
			});
		}
		catch (error) {
			setAlertBanner({
				visible: true,
				variant: 'error',
				text: `Organization could not be deleted. ${error.response?.data}`
			});
		}
		setIsEditting(false);
		setRefreshKey(!refreshKey);
	};

	const closeDecisionModal = () => {
		setDecisionModalVisible({});
		setIsEditting(false);
		setRefreshKey(!refreshKey);
	};

	const closeOrganizationModal = () => {
		setDecisionModalVisible({});
		setIsEditting(false);
	};

	return (
		<>
			<SectionHomeWrapper title="Organizations">
				<TextPassage>
					<p><br /></p>
				</TextPassage>
				{alert.visible &&
					<Alert
						variant={alert.variant}
						onClose={() => {
							setAlertBanner({
								visible: false,
							});
						}}
					>
						{alert.text}
					</Alert>
				}
				{areMyOrgsLoading && (
					<ProgressBar className="em-u-margin-top-half" indeterminate hideValueLabel label={progressLabel} />
				)}
				{!areMyOrgsLoading && (
					<>
						<OrganizationsHomeFilters {...getFilterProps()} />
						<Table
							footer={
								<Paginator
									data={filteredOrganizations}
									totalItems={organizationsOnPage[0]?.item2.length}
									onPageChange={(orgs) => {
										setOrganizationsOnPage(orgs);
									}}
								/>
							}
						>
							<Table.Head>
								<Table.Head.Row >
									{editMultiple && <Table.Head.Cell> </Table.Head.Cell>}
									<Table.Head.Cell className='table__header-cell'> Organization Name (L4) </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Organization (L5) </Table.Head.Cell>
									<Table.Head.Cell className='table__header-cell'> Actions </Table.Head.Cell>
									<Table.Head.Cell > </Table.Head.Cell>
								</Table.Head.Row>
							</Table.Head>
							{organizationsOnPage.map(l3 =>
								(l3.item2.map((org) =>
									<OrganizationHomeTableRow
										key={org.item1.id}
										l4={org}
										open={open}
										setOpen={setOpen}
										selectedOrganization={selectedOrganization}
										setSelectedOrganization={setSelectedOrganization}
										setDecisionModalVisible={setDecisionModalVisible}
										setIsEditting={setIsEditting}
										setEditingL5={setEditingL5}
									/>
								)))}
						</Table>
					</>
				)}
			</SectionHomeWrapper>
			<AlertModal {...endModal} willTimeout={false} onClose={endAndRedirect} />
			<OrganizationModal
				setL3={setL3}
				selectedL3={selectedL3}
				allOrganizations={allOrganizations}
				filteredOrganizations={filteredOrganizations}
				editModal={isEditting}
				selectedOrganization={selectedOrganization}
				visible={decisionModalVisible.createOrEditOrg}
				onConfirm={handleModalEditModeConfirmed}
				onClose={closeOrganizationModal}
				setAlertBanner={setAlertBanner}
				setSelectedOrganization={setSelectedOrganization}
				refreshKey={refreshKey}
				editingL5={editingL5}
			/>
			<ConfirmModal
				visible={decisionModalVisible.deleteOrg}
				title={`Delete ${selectedOrganization?.displayName} Organization`}
				question="Are you sure you want to delete this organization? This action cannot be undone."
				confirmButton={{
					label: 'Delete Organization',
					props: {
						variant: 'primary',
						color: 'negative'
					}
				}}
				onConfirm={handleConfirm}
				onCancel={closeDecisionModal}
			/>
		</>
	);
};

export default OrganizationsHome;