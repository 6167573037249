import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import RadioGroup from "./RadioGroup";

/** A radio button limits a user to choosing just one selection among a set of options. */
const Radio = props => {
  const {
    id,
    name,
    disabled,
    checked,
    onChange,
    placeholder,
    value,
    error,
    valid,
    readOnly,
    label,
    className,
    ...other
  } = props;

  return (
    <div
      className={clsx(
        "em-c-option-list__item",
        { "em-is-active": checked },
        className
      )}
      {...other}
    >
      <label className="em-c-input-group em-c-radio--container" htmlFor={id}>
        <input
          type="radio"
          name={name}
          id={id}
          className="em-c-input-group__control"
          placeholder={placeholder}
          value={value}
          checked={checked}
          onChange={onChange}
          error={error}
          valid={valid}
          disabled={disabled}
          readOnly={readOnly}
        />
        <span className="em-c-radio--checkmark"></span>
        <span className="em-c-input-group__text" htmlFor={id}>
          {label}
        </span>
      </label>
    </div>
  );
};

Radio.propTypes = {
  /** Html Id, this should be filled out for accessibility */
  id: PropTypes.string,
  /** Html name, this should also be filled out for accessibility */
  name: PropTypes.string,
  /** Text that will be shown next to the checkbox */
  label: PropTypes.node,
  /** Value of the selected checkbox, could be the same as the text or can be different */
  value: PropTypes.any.isRequired, //eslint-disable-line
  /** If the checkbox is checked or not */
  checked: PropTypes.bool,
  /** If the checkbox is disabled or not */
  disabled: PropTypes.bool,
  /** Function triggered by changes to the checkbox */
  onChange: PropTypes.func,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Radio.defaultProps = {
  id: "",
  name: "",
  label: "",
  disabled: false,
  checked: false,
  onChange: () => {},
  className: ""
};

Radio.Group = RadioGroup;

export default Radio;
