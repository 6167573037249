import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-badge--main";
    case "accent":
      return "em-c-badge--accent";
    case "action":
      return "em-c-badge--action";
    case "caution":
      return "em-c-badge--caution";
    case "negative":
      return "em-c-badge--negative";
    case "positive":
      return "em-c-badge--positive";
    default:
      return "em-c-badge--action";
  }
}

/** A compact indicator for status, notifications, or counts. */
const Badge = props => {
  const { className, color, children, ...other } = props;

  return (
    <span className={clsx("em-c-badge", getColor(color), className)} {...other}>
      {children}
    </span>
  );
};

Badge.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Flag color indicating the state of the page or content */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "positive",
    "caution",
    "negative"
  ]),
  /** children of component */
  children: PropTypes.node
};

Badge.defaultProps = {
  className: "",
  color: "action",
  children: null
};

export default Badge;
