import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TabsItem from "./TabsItem";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-tabs__link--main";
    case "accent":
      return "em-c-tabs__link--accent";
    case "blue":
    default:
      return "";
  }
}
/** A control for selecting and revealing content from a set of panels */
const Tabs = props => {
  const {
    variant,
    background,
    color,
    border,
    selected,
    className,
    onChange,
    children,
    ...other
  } = props;
  const [currentTab, setCurrentTab] = useState(0);

  function handleTabItemClick(e, newValue, index) {
    if (onChange) {
      onChange(e, newValue);
    } else {
      setCurrentTab(index);
    }
  }

  function isTabSelected(index, childValue) {
    // If the component is controlled, then get the selected tab by props
    if (selected) {
      return selected === childValue;
    }

    // Otherwise, use the internal state
    return currentTab === index;
  }

  return (
    <div
      className={clsx(
        "em-c-tabs",
        "em-js",
        {
          "em-c-tabs--underline": variant === "underlined",
          "em-c-tabs--gray": background === "gray"
        },
        className
      )}
      {...other}
    >
      <ul className="em-c-tabs__list">
        {React.Children.toArray(children).map((child, index) => (
          <div
            key={index}
            className="em-c-tabs__item"
            onClick={e => handleTabItemClick(e, child.props.value, index)}
            style={{ cursor: "pointer" }}
          >
            <span
              className={clsx("em-c-tabs__link", "em-js-tab", getColor(color), {
                "em-is-active": isTabSelected(index, child.props.value)
              })}
            >
              {child.props.title}
            </span>
          </div>
        ))}
      </ul>
      <div
        className={clsx("em-c-tabs__body", {
          "em-c-tabs__body--no-border": border === "none"
        })}
      >
        {React.Children.toArray(children).map((child, index) =>
          React.cloneElement(child, {
            open: isTabSelected(index, child.props.value)
          })
        )}
      </div>
    </div>
  );
};

Tabs.Item = TabsItem;

Tabs.propTypes = {
  /** Display tabs in the underline style. */
  variant: PropTypes.oneOf(["default", "underlined"]),
  /** Give the selected tab and content a light gray background color. */
  background: PropTypes.oneOf(["default", "gray"]),
  /** Theme Color of the tabs and underline */
  color: PropTypes.oneOf(["blue", "main", "accent"]),
  /** Display the selected content without a border around it. */
  border: PropTypes.oneOf(["default", "none"]),
  /** Tab value that is open */
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Callback whenever a tab title is clicked */
  onChange: PropTypes.func,
  /** React component children */
  children: PropTypes.node
};

Tabs.defaultProps = {
  variant: "default",
  className: "",
  background: "default",
  color: "blue",
  selected: null,
  onChange: null, // allows for internal state management
  children: null
};

export default Tabs;
