import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TreeIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.929 13H2.071a.5.5 0 0 1-.407-.791L4.671 8h-1.4a.5.5 0 0 1-.4-.8L8.1.062a.519.519 0 0 1 .807 0L14.135 7.2a.5.5 0 0 1-.4.8h-1.4l3.007 4.209a.5.5 0 0 1-.407.791zM3.043 12h10.914L10.95 7.791A.5.5 0 0 1 11.357 7h1.389L8.5 1.2 4.254 7h1.389a.5.5 0 0 1 .407.791z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 12h1v4H8z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42 37H6a1 1 0 0 1-.813-1.581L14.057 23H9.35a1 1 0 0 1-.807-1.591l14.65-20a1.036 1.036 0 0 1 1.613 0l14.65 20A1 1 0 0 1 38.65 23h-4.707l8.87 12.419A1 1 0 0 1 42 37zM7.943 35h32.114l-8.87-12.419A1 1 0 0 1 32 21h4.679L24 3.692 11.321 21H16a1 1 0 0 1 .813 1.581zM25 47h-2a2 2 0 0 1-2-2v-6h2v6h2v-6h2v6a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 19H3a1 1 0 0 1-.759-1.65L6.826 12H5.175a1 1 0 0 1-.807-1.591l7.325-10a1.036 1.036 0 0 1 1.613 0l7.325 10A1 1 0 0 1 19.825 12h-1.651l4.585 5.35A1 1 0 0 1 22 19zM5.174 17h14.652l-4.585-5.349A1 1 0 0 1 16 10h1.854L12.5 2.692 7.146 10H9a1 1 0 0 1 .759 1.651zM14 24h-3a1 1 0 0 1-1-1v-4h2v3h1v-3h2v4a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TreeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TreeIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TreeIcon;
