import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ButtonBarItem = props => {
  const { className, separator, children, ...other } = props;

  return (
    <li
      className={clsx(
        "em-c-btn-bar__item",
        {
          "em-c-btn-bar__item--separator": separator
        },
        className
      )}
      {...other}
    >
      {children}
    </li>
  );
};

ButtonBarItem.propTypes = {
  /** Creates a space in the button bar, to visually group sets of buttons inside the bar. */
  separator: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Child React components, in this case 2 buttons */
  children: PropTypes.node
};

ButtonBarItem.defaultProps = {
  separator: false,
  className: "",
  children: null
};

export default ButtonBarItem;
