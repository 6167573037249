import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TrashcanFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.322 4.2L9.36 3H7.881l-.461.384a.5.5 0 0 1-.386.112l-1.386-.185-.964.322-.316-.949 1.074-.358a.485.485 0 0 1 .224-.026l1.283.171.432-.359A.5.5 0 0 1 7.7 2h1.9a.5.5 0 0 1 .391.188l1.109 1.39zM7.863 10.4l-1.685-.419a.5.5 0 0 1-.369-.388l-.4-1.99.98-.2.336 1.68 1.378.346z"
          fill="currentColor"
        />
        <path
          d="M8 15c-.409 0-4-.042-4-1.5l-.989-8.873A.718.718 0 0 1 3 4.5a.5.5 0 0 1 .472-.5.487.487 0 0 1 .516.389A9.115 9.115 0 0 0 8 5a9.115 9.115 0 0 0 4.012-.61.492.492 0 0 1 .516-.39.5.5 0 0 1 .472.5.718.718 0 0 1-.011.127L12 13.556C12 14.958 8.409 15 8 15zM4.118 5.536L5 13.444A5.678 5.678 0 0 0 8 14a5.332 5.332 0 0 0 3.022-.59l.861-7.874A14.083 14.083 0 0 1 8 6a14.072 14.072 0 0 1-3.882-.464z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M33.128 9.408l-3.769-3.14L27.587 4.5h-1.938l-1.735 3.906a1 1 0 0 1-1.487.413l-4.1-2.871-1.955 3.416-1.736-.992L17.132 4a1 1 0 0 1 1.441-.323l4.006 2.8 1.507-3.391A1 1 0 0 1 25 2.5h3a1 1 0 0 1 .707.293l2 2 3.7 3.079zM19.228 24.213l-4.97-1.243a1 1 0 0 1-.738-.773l-1.2-5.98 1.961-.393 1.072 5.36 4.356 1.089zM30.656 27.405l-1.562-1.249 4.473-5.591 1.148-5.742 1.961.393-1.2 5.98a1 1 0 0 1-.2.429z"
          fill="currentColor"
        />
        <path
          d="M24.5 47.5c-4.793 0-12.881-.83-13-3.931L7.507 9.115A.912.912 0 0 1 7.5 9c0-1.394 1.446-2.4 4.3-2.979l.979-.2.4 1.96-.98.2c-2.059.422-2.586.927-2.689 1.055l3.982 34.351a.912.912 0 0 1 .007.115c.342.672 4.325 2 11 2s10.658-1.328 11.013-2.071L39.493 9c-.06-.044-.533-.579-2.693-1.021l-.98-.2.4-1.96.979.2C40.054 6.6 41.5 7.606 41.5 9a.912.912 0 0 1-.007.115L37.5 43.569c-.119 3.101-8.207 3.931-13 3.931z"
          fill="currentColor"
        />
        <path
          d="M24.5 12.86c-2.839 0-17-.187-17-3.86h2a.382.382 0 0 0-.058-.216c.541.7 5.664 2.076 15.058 2.076s14.517-1.375 15.058-2.076A.382.382 0 0 0 39.5 9h2c0 3.674-14.161 3.86-17 3.86z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M13 24c-2.051 0-8-.738-8-3L4 5.063a2.329 2.329 0 0 1 1.675-2.008l.946-.325.649 1.892-.945.324a.789.789 0 0 0-.316.2l.976 15.569A13.368 13.368 0 0 0 13 22a9.012 9.012 0 0 0 5.013-1.2l.977-15.656a.783.783 0 0 0-.314-.2l-.945-.324.649-1.892.946.325A2.332 2.332 0 0 1 21 5l-1 16.063C20 22.957 15.935 24 13 24zm5-3.075A.608.608 0 0 0 18 21zm-11.006-.043v.056a.356.356 0 0 0 0-.056z"
          fill="currentColor"
        />
        <path
          d="M13 8c-2.937 0-9-.733-9-3h2a.578.578 0 0 0-.115-.368C6.241 5.009 8.968 6 13 6c3.9 0 5.811-.922 6.066-1.257A.463.463 0 0 0 19 5h2c0 2.062-4.146 3-8 3zM16 4.414l-2.393-2.393-.713 1.426a1 1 0 0 1-1.342.447L10.2 3.217 9 4.414 7.586 3l1.707-1.707a1 1 0 0 1 1.154-.187l1.105.553.553-1.106A1 1 0 0 1 13 0h1a1 1 0 0 1 .707.293L17.414 3zM11.447 14.342l-2.894-1.447A1 1 0 0 1 8 12V9h2v2.382l2.342 1.171zM14 15.414L12.586 14 15 11.586V9h2v3a1 1 0 0 1-.293.707z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TrashcanFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TrashcanFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TrashcanFilledIcon;
