import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BookIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M4 3h1v8H4z" />
        <path
          d="M13.5 15h-11a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5H12v1H3v3h10V2h1v12.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path
          d="M12 11h-1V2H3v9H2V1.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5zM4 12h8v1H4z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M8 9h2v30H8z" />
        <path
          d="M43 45H7a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h30a1 1 0 0 1 1 1v34a1 1 0 0 1-1 1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h35V9h2v35a1 1 0 0 1-1 1zM7 5a1 1 0 0 0-1 1v31.171A2.989 2.989 0 0 1 7 37h29V5z"
          fill="currentColor"
        />
        <path d="M40 42H8v-2h31V6h2v35a1 1 0 0 1-1 1z" fill="currentColor" />
        <path
          d="M32 16H14a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-17-2h16v-3H15z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="#fff" d="M3 1h18v19H3z" />
        <path
          d="M21 21H3a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v19a1 1 0 0 1-1 1zM4 19h16V2H4z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M6 1h2v11H6z" />
        <path
          d="M5 17h16v5.75a.25.25 0 0 1-.25.25H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2z"
          fill="#fff"
        />
        <path
          d="M20.75 24H5a3 3 0 0 1-3-3v-2a3 3 0 0 1 3-3h17v6.75A1.251 1.251 0 0 1 20.75 24zM5 18a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h15v-4z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M10 19h11v2H10z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BookIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BookIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BookIcon;
