import React from 'react';
import { Grid, TreeNavigation, ButtonBar, Button, XFilledIcon, SaveDiskIcon, ButtonSize, Alert, Loader } from 'react-unity';
import { AbstractPORForm, AbstractPORFormState, AbstractPORFormProps, IPORStateForm } from './AbstractPORForm';
import WorkflowDetailWrapper from '../common/wrappers/WorkflowDetailWrapper';
import PortOpeningRequest from '../../models/entities/PortOpeningRequest';
import { PORTypeInput, EnvironmentInput, ApproverInput, RulesTable, ApproverDelegationInput } from './components';
import { TenantLabel } from '../../models/enums/TenantLabel';
import UserLookup from '../common/form-controls/UserLookup';
import TextAreaField from '../common/form-controls/TextareaField';
import { Validation } from '../../utils/Validation';
import ReadonlyField from '../common/form-controls/ReadonlyField';
import AlertModal from '../common/modals/AlertModal';
import ConfirmModal from '../common/modals/ConfirmModal';
import UpdatePortOpeningRequest from '../../models/viewModels/PortOpeningRequests/UpdatePortOpeningRequest';
import UpdatePortOpeningRequestRule from '../../models/viewModels/PortOpeningRequests/UpdatePortOpeningRequestRule';
import { WorkflowState } from '../../models/enums/WorkflowState';
import PortOpeningRequestsCreate from './PortOpeningRequestsCreate';
import { PortOpeningRequestsDirection } from '../../models/enums/POR/PortOpeningRequestsDirection';
import './PortOpeningRequestsDetail.css';
import ToggleField from '../common/form-controls/ToggleField';
import { VirtualNetwork } from '../../models/entities/AzureResources/VirtualNetwork';
import { getVnets } from '../common/VirtualNetworksStorage';
import ProgressBarBig from './components/ProgressBarBig';
import { useParams } from 'react-router-dom';
import RulesAttestationTable from './components/RulesAttestationTable';

interface PortOpeningRequestsDetailWithParamsProps extends AbstractPORFormProps { }

interface StateForm extends IPORStateForm { }

interface PortOpeningRequestsDetailWithParamsState extends AbstractPORFormState<StateForm> {
	loading: boolean;
	portOpeningRequest?: PortOpeningRequest;
	editMode: boolean;
	matchPatch: string;

	decisionModalVisible: {
		approve?: boolean;
		reject?: boolean;
		review?: boolean;
		cancel?: boolean;
	},
	modalComment: string;
	subVirtualNetworks: VirtualNetwork[];
	selectedFromExisting: string[];
}

class PortOpeningRequestsDetailWithParams extends AbstractPORForm
	<PortOpeningRequestsDetailWithParamsProps, PortOpeningRequestsDetailWithParamsState> {
	
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			stateForm: {
				environment: null,
				contact: null,
				isEmergency: false,
				customerCoordination: null,
				businessJustification: '',
				ruleList: [],
				hasDelegatedApprover: null,
				custodianApproval: null
			},
			editMode: false,
			submissionWarned: false,
			operationInProgress: false,
			endModal: {
				visible: false,
			},
			submissionAlert: {
				visible: false,
			},

			decisionModalVisible: {},
			modalComment: '',
			subVirtualNetworks: [],
			selectedFromExisting: [],
			matchPatch: null,
		};

		this.initFormModel();
	}

	initFormModel() {
		super.initFormModel();
		this.formModel.fields.approverId.validation = new Validation({
			required: () => 
				(this.state.portOpeningRequest?.direction !== PortOpeningRequestsDirection.SubscriptionToOnPrem)
		});

		this.formModel.fields.portOpeningRules.getValue =
			() => this.state.stateForm.ruleList.map(rule => new UpdatePortOpeningRequestRule(rule))
	}

	async componentWillMount() {
		const id = parseInt(this.props.params.porId);
		console.log(this.props.params.porId);
		const subsId = parseInt(this.props.params.subsId);
		console.log(this.props.params.subsId);
		await this.loadRequest(id, subsId);
	}

	loadRequest = async (id, subsId) => {
		this.setState(
			{
				loading: true
			},
			async () => {
				try {
					const subscription = await this.getSubscriptionWithPOR(subsId, id);
					const request = new PortOpeningRequest(subscription.portOpeningRequests?.find((por: { id: number; }) => por.id == id));					
					const subVirtualNetworks = await getVnets(subscription.createdSubscriptionId, subscription.tenant.name)

					if (request?.direction.value === PortOpeningRequestsDirection.OnPremToInternet.value
						|| request?.direction.value === PortOpeningRequestsDirection.NECTUpdate.value){
						window.location.href = `https://ssppor-portal-prd.azurewebsites.net/PortOpeningRequests/${request?.id}`;
					}
	
					this.setState({
						subscription,
						subVirtualNetworks,
						portOpeningRequest: request,
						loading: false,
					}, this.resetStateForm);

					this.setState({
						selectedFromExisting: this.state.stateForm.ruleList.map(rule => rule.name)
					});
					
				}	catch (error) {
					const HTTP_FORBIDDEN_ERROR_CODE = 403;
					if (error.status === HTTP_FORBIDDEN_ERROR_CODE) {
						this.handleEndModal('You are not allowed to see this request.', 'error', 5000, '/subscriptions');
					} else {
						this.handleEndModal('The request could not be retrieved.', 'error', 10000, '/subscriptions');
					}
				}
			}
		);
	};

	resetStateForm = () => {
		this.setState((prevState) => (
			{
				stateForm: this.stateFormFromRequest(prevState.portOpeningRequest),
			}
		));
	};

	stateFormFromRequest(por: PortOpeningRequest): StateForm {
		const currentState = this.state.portOpeningRequest.workflowInstance.currentWorkflowInstanceState.workflowState.value;
		if(currentState === WorkflowState.Review.value ||currentState === WorkflowState.Draft.value){
			let obj = super.stateFormFromRequest(por);
			return {...obj, ruleList: obj.ruleList.map(rule => {return {...rule, azureName: undefined}})}

		};
		return super.stateFormFromRequest(por);
	}

	setSelectedFromExisting = (rulesName: string[]) => {
		this.setState({
			selectedFromExisting: rulesName,
		})
	}

	actions = () => (
		!this.state.operationInProgress &&
		(!this.state.portOpeningRequest?.isAttestation &&
			<TreeNavigation className="em-u-clickable">
				{this.state.portOpeningRequest?.isEditable() &&
					<>
						<TreeNavigation.Item
							label="Edit"
							className="em-c-btn em-c-btn--secondary em-c-btn--small"
							onClick={this.enableEdit}
						/>
						<br />
						<br />
					</>}
				{(this.state.portOpeningRequest?.canHaveDOAGApproval() ||
					this.state.portOpeningRequest?.canHaveTechnicalDecision() ||
					this.state.portOpeningRequest?.canHaveSubscriptionOwnerDecision(this.state.subscription, this.state.portOpeningRequest?.custodianApproval)) &&
					<>
						<TreeNavigation.Item
							label="Approve"
							className="em-c-btn em-c-btn--secondary em-c-btn--small"
							onClick={() => { this.openDecisionModal('approve'); }}
						/>
						<br />
						<br />
						<TreeNavigation.Item
							label="Reject"
							className="em-c-btn em-c-btn--secondary em-c-btn--small"
							onClick={() => { this.openDecisionModal('reject'); }}
						/>
						<br />
						<br />
					</>}
				{this.state.portOpeningRequest?.isCancellable() &&
					<>
						<TreeNavigation.Item
							label="Cancel Request"
							className="em-c-btn em-c-btn--secondary em-c-btn--small"
							onClick={() => { this.openDecisionModal('cancel'); }}
						/>
						<br />
						<br />
					</>}
				{(this.state.portOpeningRequest?.canHaveTechnicalDecision() ||
					this.state.portOpeningRequest?.canSendToReview(this.state.subscription, this.state.portOpeningRequest?.workflowInstance)) &&
					<TreeNavigation.Item
						label="Send to Review"
						className="em-c-btn em-c-btn--secondary em-c-btn--small"
						onClick={() => { this.openDecisionModal('review'); }}
					/>}
			</TreeNavigation>)
	);


	editActions = (size: ButtonSize) => (
		<ButtonBar className='edit-button-bar'>
			{!this.state.operationInProgress &&
				<>
					<ButtonBar.Item>
						<Button
							size={size}
							variant="secondary"
							onClick={this.cancelEdit}
						>
							<XFilledIcon
								size={size}
							/>
							<span>Cancel</span>
						</Button>
					</ButtonBar.Item>
					<ButtonBar.Item separator />
				</>}
			<ButtonBar.Item>
				<Button
					size={size}
					variant="primary"
					disabled={!this.formModel.isValid() || this.state.operationInProgress}
					onClick={this.handleSave}
				>
					{(this.state.operationInProgress) ?
						<>
							<Loader className="icon-loader em-c-icon--small" />
							<span>Loading...</span>
						</>
						:
						<>
							<SaveDiskIcon
							    className='em-c-save-icon' 
								size={size}
							/>
							<span>Send to Review</span>
						</>
					}
				</Button>
			</ButtonBar.Item>
		</ButtonBar>
	);

	enableEdit = async () => {
		this.setState({
			editMode: true,
		});	
	};

	cancelEdit = () => {
		this.setState({ editMode: false });
		this.resetStateForm();
	};

	openDecisionModal = (type: 'approve' | 'reject' | 'review' | 'cancel') => {
		this.setState({
			decisionModalVisible: {
				[type]: true
			}
		});
	};

	closeDecisionModal = () => {
		this.setState({
			decisionModalVisible: {},
			modalComment: '',
		});
	};

	setEmbedAlertSuccess = (message) => {
		this.setState({
			submissionAlert: {
				variant: 'success',
				text: message,
				visible: true,
			}
		}, () => {
			setTimeout(this.handleSubmissionAlertClose, 5000);
		});
	};

	decisionModalConfirmationField = () => ({
		label: 'Please, provide the reason.',
		value: this.state.modalComment,
		validation: {
			required: true,
		},
		onChange: (ev) => {
			this.setState({
				modalComment: ev.target.value,
			});
		},
	});

	handleSave = () => {
		const operation = async () => {
			if (this.requiresApproverVerification(this.state.portOpeningRequest?.direction.name)) {
				const isVerified = await this.approverLevelVerified();
				if (!isVerified)
					return;
			}
			const model = this.formModel.create(UpdatePortOpeningRequest);
			await this.portOpeningRequestService.update(this.state.portOpeningRequest?.id, model);
			this.setState({
				editMode: false,
			});
		};

		this.handleOperationConfirmed({
			operation,
			inProgressMessage: 'Updating request...',
			successMessage: 'Your request was updated successfully.'
		});
	};

	handleApprove = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.portOpeningRequestService.approve(this.state.portOpeningRequest?.id);
			},
			inProgressMessage: 'Approving...',
			successMessage: 'Request approved sucessfully.'
		});
	};

	handleReject = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.portOpeningRequestService.reject(this.state.portOpeningRequest?.id, {
					rejectionReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Rejecting...',
			successMessage: 'Request rejected sucessfully.'
		});
	};

	handleReview = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.portOpeningRequestService.sendToReview(this.state.portOpeningRequest?.id, {
					reviewReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Sending to review...',
			successMessage: 'Request sent to review successfully.',
		});
	};

	handleCancellation = async () => {
		this.handleOperationConfirmed({
			operation: async () => {
				await this.portOpeningRequestService.cancel(this.state.portOpeningRequest?.id, {
					cancellationReason: this.state.modalComment,
				});
			},
			inProgressMessage: 'Cancelling...',
			successMessage: 'Request cancelled successfully.',
		});
	};

	handleOperationConfirmed = async ({
		operation,
		inProgressMessage,
		successMessage,
	}) => {
		this.setSubmissionAlertInProgress(inProgressMessage);
		this.closeDecisionModal();
		try {
			await operation();
			await this.loadRequest(this.state.portOpeningRequest?.id, this.state.subscription?.id);
			this.setEmbedAlertSuccess(successMessage);
		} catch (err) {
			const HTTP_INTERNAL_SERVER_ERROR_CODE = 500;
			if ((err.status === HTTP_INTERNAL_SERVER_ERROR_CODE) && err?.body?.message) {
				await this.loadRequest(this.state.portOpeningRequest?.id, this.state.subscription?.id);
			}
			this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
		} finally {
			this.setState({
				operationInProgress: false,
				modalComment: '',
			});
		}
	};

	render() {
		return (
			((!this.state.loading &&
				this.state.portOpeningRequest != null && 
				this.state.portOpeningRequest?.isEditable() &&
				this.state.portOpeningRequest?.workflowInstance.currentWorkflowInstanceState.workflowState.value === WorkflowState.Draft.value) ||
				(this.state.portOpeningRequest?.isAttestation &&
					this.state.portOpeningRequest?.workflowInstance.currentWorkflowInstanceState.workflowState.value === WorkflowState.PendingAttestation.value)
			) ?
				<PortOpeningRequestsCreate
					draftRequest={this.state.portOpeningRequest}
					validNets={this.state.subVirtualNetworks}
				/>
				:
				<>
					<WorkflowDetailWrapper
						title={this.state.portOpeningRequest?.isAttestation==true ? `Attestation Request #${this.state.portOpeningRequest?.id}` : `Port Opening Request #${this.state.portOpeningRequest?.id}`}
						workflowInstance={this.state.portOpeningRequest?.workflowInstance}
						loading={this.state.loading}
						linkTree={this.getLinkTree()}
						actions={this.state.editMode ? this.editActions('small') : this.actions()}
						fromPOR
					>
						{this.state.submissionAlert.visible &&
						<Alert
							variant={this.state.submissionAlert.variant}
							onClose={this.handleSubmissionAlertClose}
						>
							{this.state.submissionAlert.text}
						</Alert>}
						<Grid variant="3-up">
							<Grid.Item>
								<PORTypeInput
									scope={{
										value: this.state.portOpeningRequest?.direction.name,
										disabled: true
									}}
								/>
							</Grid.Item>
							{this.state.subscription?.tenant.id === TenantLabel.ExxonMobilTest &&
							<Grid.Item>
								<EnvironmentInput
									{...this.stateFormHandler().environment}
									disabled={this.state.operationInProgress || !this.state.editMode}
								/>
							</Grid.Item>}
							{this.state.portOpeningRequest?.implementationTicket() !== '' &&
							<Grid.Item>
								<ReadonlyField
									label="Implementation Ticket"
									text={this.state.portOpeningRequest?.implementationTicket()}
									copy
								/>
							</Grid.Item>}
						</Grid>
						<Grid variant="halves">
							<Grid.Item>
								<UserLookup
									label="Technical contact"
									{...this.stateFormHandler().contact}
									disabled={this.state.operationInProgress || !this.state.editMode}
								/>
							</Grid.Item>
							{this.state.portOpeningRequest?.direction.name !== PortOpeningRequestsDirection.SubscriptionToOnPrem.name &&
								<Grid.Item>
									<ApproverInput
										{...this.stateFormHandler().approver}
										disabled={this.state.operationInProgress || !this.state.editMode}
										scope={this.state.portOpeningRequest?.direction.name}
									/>
								</Grid.Item>}
						</Grid>
						{this.state.portOpeningRequest?.direction.name !== PortOpeningRequestsDirection.SubscriptionToOnPrem.name &&
						<ApproverDelegationInput
							hasApproverDelegation={this.stateFormHandler().hasDelegatedApprover}
							delegatedApprover={this.stateFormHandler().delegatedApprover}
							disabled={this.state.operationInProgress || !this.state.editMode}
							isRequired={this.state.portOpeningRequest?.direction.name !== PortOpeningRequestsDirection.SubscriptionToOnPrem.name}
						/>}
						<Grid variant="halves">
							<Grid.Item>
								<ToggleField
									{...this.stateFormHandler().custodianApproval}
									disabled={this.state.operationInProgress || !this.state.editMode}
									value={this.stateFormHandler().custodianApproval.value?.toString()}
									options={!this.state.operationInProgress && this.state.editMode ?
									[
										{ value: 'true', text: 'Yes' },
										{ value: 'false', text: 'No' }
									]:
									this.stateFormHandler().custodianApproval?.value=='true'?
									[ {value: 'true', text: 'Yes'} ]
									:
									[ {value: 'false', text: 'No'} ]
								}
								/>
							</Grid.Item>
							<Grid.Item>
								<ProgressBarBig
									workflowState={this.state.portOpeningRequest?.workflowInstance.currentWorkflowInstanceState.workflowState} porScope={this.state.portOpeningRequest?.direction.name}
								/>
							</Grid.Item>
						</Grid>
						<TextAreaField
							label="Business Justification"
							{...this.stateFormHandler().businessJustification}
							disabled={this.state.operationInProgress || !this.state.editMode}
						/>
						{this.state.portOpeningRequest?.isAttestation
							?
							<RulesAttestationTable
								{...this.stateFormHandler().ruleset}
								porDirection={this.state.portOpeningRequest?.direction}
								subscriptionRequestName={this.state.subscription?.createdSubscriptionName}
								subscriptionRequestId={this.state.subscription?.id}
								readonly={true}
								validVirtualNetworks={this.state.subVirtualNetworks}
							/>
							:
							<RulesTable
								{...this.stateFormHandler().ruleset}
								porDirection={this.state.portOpeningRequest?.direction}
								subscriptionRequestName={this.state.subscription?.createdSubscriptionName}
								readonly={this.state.operationInProgress || !this.state.editMode}
								validVirtualNetworks={this.state.subVirtualNetworks}
								loadingImplementedRules={false}
								currentWorkflowState={this.state.portOpeningRequest?.workflowInstance.currentWorkflowInstanceState.workflowState.value}
								fromDetails
								selectedFromExisting={this.state.selectedFromExisting}
								setSelectedFromExisting={this.setSelectedFromExisting}
							/>
						}
						{this.state.editMode && this.editActions('medium')}
					</WorkflowDetailWrapper>
					<AlertModal
						{...this.state.endModal}
						willTimeout={false}
						onClose={this.endAndRedirect}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.approve}
						title={`Approve POR #${this.state.portOpeningRequest?.id}`}
						question="Are you sure you want to approve this request?"
						onConfirm={this.handleApprove}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.cancel}
						title={`Cancel POR #${this.state.portOpeningRequest?.id}`}
						question="Are you sure you want to cancel this request? This action cannot be undone."
						confirmationField={this.decisionModalConfirmationField()}
						confirmButton={{
							label: 'Cancel Request',
							props: {
								variant: 'primary',
								color: 'negative'
							}
						}}
						onConfirm={() => this.handleCancellation()}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.reject}
						title={`Reject POR #${this.state.portOpeningRequest?.id}`}
						question="Are you sure you want to reject this request?"
						confirmationField={this.decisionModalConfirmationField()}
						onConfirm={() => this.handleReject()}
						onCancel={this.closeDecisionModal}
					/>
					<ConfirmModal
						visible={this.state.decisionModalVisible.review}
						title={`Confirm Revision for POR #${this.state.portOpeningRequest?.id}`}
						question="Are you sure you want to send this request to review?"
						confirmationField={this.decisionModalConfirmationField()}
						onConfirm={() => this.handleReview()}
						onCancel={this.closeDecisionModal}
					/>
				</>
		);
	}
}

const PortOpeningRequestsDetail = () => {

    return <PortOpeningRequestsDetailWithParams params={useParams()}/>;
}

export default PortOpeningRequestsDetail