import * as React from 'react';
import { Alert, Button, Grid, ProgressBar, ProgressTracker } from 'react-unity';
import SubscriptionRequest from '../../models/entities/SubscriptionRequest';
import { AlertBanner } from '../../models/interfaces/AlertBanner';
import { PolicyAssignmentResponse } from '../../models/viewModels/PolicyExemptionRequests/PolicyAssignmentResponse';
import SubmitPolicyExemptionRequest from '../../models/viewModels/PolicyExemptionRequests/SubmitPolicyExemptionRequest';
import DatePickerField from '../common/form-controls/DatePickerField';
import TextAreaField from '../common/form-controls/TextareaField';
import TextInputField from '../common/form-controls/TextInputField';
import AlertModal from '../common/modals/AlertModal';
import ConfirmModal from '../common/modals/ConfirmModal';
import RequestFormWrapper from '../common/wrappers/RequestFormWrapper';
import { AbstractPolicyExemptionRequestForm, AbstractPolicyExemptionRequestFormProps, AbstractPolicyExemptionRequestFormState } from './AbstractPolicyExemptionRequestForm';
import { PolicyAssignmentsTable } from './components/PolicyAssigmentsTable';
import { PolicyDefinitionsTable } from './components/PolicyDefinitionsTable';
import { PolicyGeneralInformation } from './components/PolicyGeneralInformation';
import { AzureResourceInput } from './components';
import './PolicyExemptionRequestCreate.css';
import SelectField from '../common/form-controls/SelectField';
import { useParams, useNavigate } from 'react-router-dom';

interface PolicyExemptionRequestsCreateWithParamsProps extends AbstractPolicyExemptionRequestFormProps { }

interface PolicyExemptionRequestsCreateWithParamsState extends AbstractPolicyExemptionRequestFormState {
    submissionAlert: AlertBanner;
    subscription: SubscriptionRequest;
    policyAssignments: PolicyAssignmentResponse[];
    operationInProgress: boolean;
	cancelModalVisible: boolean;
    currentStep: FormStep;
    loadingAssignments: boolean;
}

interface IStep {
	validation: () => boolean;
	validationMessage: string;
}

interface IStepDictionary {
	[stepId: string]: IStep;
}

enum FormStep {
    AssignmentStep = 'Select Assignment',
    DefinitionsStep = 'Select Definitions',
    SecurityAndControlsStep = 'Add S&C Data'
}

class PolicyExemptionRequestsCreateWithParams extends AbstractPolicyExemptionRequestForm
	<PolicyExemptionRequestsCreateWithParamsProps, PolicyExemptionRequestsCreateWithParamsState> {

	steps: IStepDictionary;

	constructor(props) {
		super(props);
		this.state = {
			subscription: null,
			operationInProgress: false,
			cancelModalVisible: false,
			stateForm: {
				displayName: '',
				businessJustification: '',
				riskAssessmentLink: '',
				durationRequested: '',
				policyDefinitions: [],
				policyAssignment: null,
				resourceGroup: null,
				resource: null
			},
			endModal: {
				visible: false,
			},
			submissionAlert: {
				visible: false,
			},
			submissionWarned: false,
			currentStep: FormStep.AssignmentStep,
			policyAssignments: [],
			loadingAssignments: false
		};

		this.steps = {};

		this.steps[FormStep.AssignmentStep] = {
			validation: () => this.state.stateForm.policyAssignment != null,
			validationMessage: 'Please select an assignment.'
		};
		this.steps[FormStep.DefinitionsStep] = {
			validation: () => this.state.stateForm.policyDefinitions?.length > 0,
			validationMessage: 'Please select the definitions of the exception.'
		};
		this.steps[FormStep.SecurityAndControlsStep] = {
			validation: () => this.formModel.isValid(),
			validationMessage: null
		};
    
		this.initFormModel();
	}

	async componentDidMount() {
		try {
			const subId = parseInt(this.props.params.id);
			this.setState({ 
				loadingAssignments: true
			});
			const [sub, assignments] = await Promise.all([
				this.getSubscription(subId),
				this.subscriptionRequestService.getSubscriptionPolicyAssignmentsById(subId)
			]);

			this.setState({ 
				subscription: sub,
				policyAssignments: assignments
			});
			
		} catch (err){
			this.setSubmissionAlertError(err?.body?.message ?? 'An error occured while retrieving the information. Please, try again later.');
		}
		this.setState({ 
			loadingAssignments: false
		});
	}

	initFormModel() {
		super.initFormModel();
		const form = this.state.stateForm;
		this.formModel.addField('assignmentId', {
			getValue: () => form.policyAssignment?.id
		});
		this.formModel.addField('policyDefinitionsIds', {
			getValue: () => form.policyDefinitions?.map(policyDefinition => policyDefinition.id)
		});
		this.formModel.addField('subscriptionRequestId', {
			getValue: () => this.state.subscription?.id
		});
	}

    handleCancel = () => {
    	this.props.navigate(this.returningRoute());
    };

    handleSubmit = async () => {
    	this.setSubmissionAlertInProgress('Submitting your request...');
    	const model = this.formModel.create(SubmitPolicyExemptionRequest);
    	try {
    		const requests = await this.policyExemptionRequestService.create(model);
    		const requestsIds = requests.map(req => `#${req.id}`).join(', ');
    		this.handleEndModal(
    			`Requests ${requestsIds} were submitted successfully. It may take up to a minute to be implemented.`,
    			'success',
    			10000,
    			this.returningRoute()
    		);
    	} catch (err) {
    		this.setSubmissionAlertError(err?.body?.message ?? 'An error occured when processing your request. Please, try again later.');
    	}

    	this.setState({
    		operationInProgress: false,
    	});
    };

    setSubmissionAlertInProgress = (message) => {
    	window.scroll(0, 0);
    	this.setState({
    		operationInProgress: true,
    		submissionAlert: {
    			visible: true,
    			text: message
    		}
    	});
    };

    setSubmissionAlertError = (message) => {
    	this.setState({
    		submissionAlert: {
    			visible: true,
    			text: message,
    			variant: 'error'
    		}
    	});
    };

    returningRoute = () => {
    	return `/subscriptions/${this.props.params.id}/policyExemptionRequests`;
    };

    handleSubmissionAlertClose = () => {
    	this.setState({
    		submissionAlert: {
    			visible: false
    		}
    	});
    };

    getStepsNames(): FormStep[] {
    	return Object.keys(FormStep).map(key => FormStep[key]);
    }

    userIsInLastStep(): boolean {
    	const stepsNames = this.getStepsNames();
    	return this.state.currentStep === stepsNames[stepsNames.length - 1];
    }

    userIsInFirstStep(): boolean {
    	return this.getStepsNames()[0] === this.state.currentStep;
    }

	isStepValid = () => {
		return this.steps[this.state.currentStep].validation();
	};

	getValidationMessage = () => {
		return this.steps[this.state.currentStep].validationMessage;
	};

	isStepComplete = (stepIndex: number) => {
		return this.getStepsNames().indexOf(this.state.currentStep) > stepIndex;
	};

	isStepClickable = (stepIndex: number) => {
		return this.isStepComplete(stepIndex)
		|| this.isStepValid();
	};

    moveSteps = (numberOfSteps: number) => {
    	const stepsNames = this.getStepsNames();
    	const newStepIndex = stepsNames.indexOf(this.state.currentStep) + numberOfSteps;
    	if (newStepIndex >= 0) {
    		const newStep = stepsNames[newStepIndex];
    		this.setState({ currentStep: newStep });
    	}
    };

    moveToNextStep = () => {
    	this.moveSteps(1);
    };

    moveToPreviousStep = () => {
    	this.moveSteps(-1);
    };

    userIsInStep(formStep: FormStep): boolean {
    	return this.state.currentStep === formStep;
    }

    getAssignmentDefinitions() {
    	const assignmentDefinitions = this.state.stateForm?.policyAssignment?.properties.policyDefinitions;
    	return assignmentDefinitions || [];
    }

    render() {
    	return (
    		<>
    			<RequestFormWrapper
    				title="New Policy Exception Request"
    				linkTree={this.getLinkTree()}
    			>
    				<PolicyGeneralInformation />
    				{this.state.loadingAssignments ?
    					<ProgressBar
    						className="em-u-margin-top-half"
    						indeterminate
    						hideValueLabel
    						label="Loading policy assignments and definitions..."
    					/>
    					:
    					<>
    						{this.state.submissionAlert.visible &&
								<Alert
									variant={this.state.submissionAlert.variant}
									onClose={this.handleSubmissionAlertClose}
								>
									{this.state.submissionAlert.text}
								</Alert>}

    						<ProgressTracker>
    							{this.getStepsNames().map((stepName, stepIndex) => (
    								<ProgressTracker.Item
    									label={stepName}
    									key={stepIndex}
    									complete={this.isStepComplete(stepIndex)}
    									active={this.state.currentStep === stepName}
    									className={this.isStepClickable(stepIndex) ? 'em-u-clickable' : ''}
    									onClick={this.isStepClickable(stepIndex) ?
    										(() => this.setState({ currentStep: stepName }))
    										: null}
    									title={this.isStepClickable(stepIndex) ? null : this.getValidationMessage()}
    								>
    									{stepIndex + 1}
    								</ProgressTracker.Item>
    							))}
    						</ProgressTracker>

    						<br />
    						<br />

    						{this.userIsInStep(FormStep.AssignmentStep) &&
								<>
									{!this.isStepValid() &&
									<Alert
										id='assignments-warning'
										variant="warning"
										className="no-alert-actions"
									>
										{this.getValidationMessage()}
									</Alert>}
									<PolicyAssignmentsTable
										policyAssignments={this.state.policyAssignments}
										{...this.stateFormHandler().policyAssignment}
									/>
								</>}
    						{this.userIsInStep(FormStep.DefinitionsStep) &&
								<>
									{!this.isStepValid() &&
									<Alert
										id='definitions-warning'
										variant="warning"
										className="no-alert-actions"
									>
										{this.getValidationMessage()}
									</Alert>}
									<PolicyDefinitionsTable
										assignmentName={this.stateFormHandler().policyAssignment.value?.properties.displayName}
										policyDefinitions={this.getAssignmentDefinitions()}
										{...this.stateFormHandler().policyDefinitions}
									/>
								</>}
    						{this.userIsInStep(FormStep.SecurityAndControlsStep) &&
								<>
									
									<Grid variant="halves">
										<Grid.Item>
											<TextInputField
												{...this.stateFormHandler().displayName}
												className='exemption-name'
												disabled={this.state.operationInProgress}
												note={`Exception Name: ${this.getExemptionAzureName()}`}
											/>
										</Grid.Item>
										<Grid.Item>
											<SelectField
												{...this.stateFormHandler().durationRequested}
												className="full-width"
											/>
										</Grid.Item>
									</Grid>
									<AzureResourceInput
										resourceGroup={this.stateFormHandler().resourceGroup}
										resource={this.stateFormHandler().resource}
										subscriptionId={this.state.subscription.id}
									/>
									<TextInputField
										{...this.stateFormHandler().riskAssessmentLink}
										disabled={this.state.operationInProgress}
									/>
									<TextAreaField
										{...this.stateFormHandler().businessJustification}
										disabled={this.state.operationInProgress}
									/>
								</>}

    						<Grid variant={this.userIsInFirstStep() ? 'halves' : '3-up'}>
    							<Grid.Item>
    								<Button
    									variant="secondary"
    									onClick={() => {
    										this.setState({
    											cancelModalVisible: true,
    											operationInProgress: true,
    										});
    									}}
    									disabled={this.state.operationInProgress}
    								>
								Cancel
    								</Button>
    							</Grid.Item>

    							{!this.userIsInFirstStep() &&
									<Grid.Item>
										<Button
											variant="secondary"
											onClick={this.moveToPreviousStep}
											className="z-index-0"
										>
											ᐊ Previous
										</Button>
									</Grid.Item>}
    							{!this.userIsInLastStep() &&
									<Grid.Item>
										<Button
											variant="secondary"
											onClick={this.moveToNextStep}
											className="z-index-0"
											disabled={!this.isStepValid()}
											title={this.isStepValid() ? '' : this.getValidationMessage()}
										>
											Next ᐅ
										</Button>
									</Grid.Item>}
    							{this.userIsInLastStep() &&
									<Grid.Item>
										<Button
											variant="primary"
											disabled={!this.formModel.isValid()}
											loading={this.state.operationInProgress}
											onClick={this.handleSubmit}
											className="z-index-0"
										>
											Submit
										</Button>
									</Grid.Item>}
    						</Grid>
    					</>}
    			</RequestFormWrapper>
    			<ConfirmModal
    				visible={this.state.cancelModalVisible}
    				title="Cancel Policy Exception Request"
    				question="You are about to cancel this request. You'll lose the data you've input. Are you sure?"
    				confirmButton={{
    					label: 'Cancel Request',
    					props: {
    						variant: 'primary',
    						color: 'negative'
    					}
    				}}
    				onConfirm={this.handleCancel}
    				onCancel={() => {
    					this.setState({
    						cancelModalVisible: false,
    						operationInProgress: false
    					});
    				}}
    			/>
    			<AlertModal
    				{...this.state.endModal}
    				willTimeout={false}
    				onClose={this.endAndRedirect}
    			/>
    		</>
    	);
    }
}

const PolicyExemptionRequestsCreate = () => {

    return <PolicyExemptionRequestsCreateWithParams params={useParams()} navigate={useNavigate()}/>;
}

export default PolicyExemptionRequestsCreate;