import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FileXlsxIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M5.329 10.625H3.81l-1.095-1.67-1.115 1.67H.147l1.8-2.564L.186 5.536H1.7L2.759 7.12l1.089-1.584H5.3L3.525 8.008zM10.151 10.625H6.48V5.536h1.313v4.1h2.358zM15.436 9.025a1.45 1.45 0 0 1-.639 1.222 2.854 2.854 0 0 1-1.73.47 4.839 4.839 0 0 1-1.1-.111 5.69 5.69 0 0 1-.881-.282V9.1h.144a3.04 3.04 0 0 0 .911.5 2.956 2.956 0 0 0 .969.174 3.138 3.138 0 0 0 .314-.021 1.268 1.268 0 0 0 .318-.068.818.818 0 0 0 .248-.154.361.361 0 0 0 .1-.273.37.37 0 0 0-.142-.289.988.988 0 0 0-.416-.187 13.792 13.792 0 0 0-.605-.128 4.647 4.647 0 0 1-.6-.152 1.884 1.884 0 0 1-.927-.562 1.391 1.391 0 0 1-.281-.887 1.365 1.365 0 0 1 .637-1.161 2.785 2.785 0 0 1 1.639-.449 5.061 5.061 0 0 1 .994.1 4.6 4.6 0 0 1 .849.244v1.171h-.14a2.71 2.71 0 0 0-.754-.412 2.589 2.589 0 0 0-.911-.166 2.376 2.376 0 0 0-.333.02 1.286 1.286 0 0 0-.312.083.606.606 0 0 0-.229.156.338.338 0 0 0-.1.237.354.354 0 0 0 .154.31 1.721 1.721 0 0 0 .58.2l.539.113a5.57 5.57 0 0 1 .555.15 1.851 1.851 0 0 1 .863.521 1.284 1.284 0 0 1 .286.855zM3 4H2V2.5A1.5 1.5 0 0 1 3.5 1h6.506a1.491 1.491 0 0 1 1.061.439l1.7 1.7-.707.707-1.7-1.7A.5.5 0 0 0 10.006 2H3.5a.5.5 0 0 0-.5.5zM12.5 15h-9A1.5 1.5 0 0 1 2 13.5V12h1v1.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V12h1v1.5a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 34H5a3 3 0 0 1-3-3V18a3 3 0 0 1 3-3h38a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3zM5 17a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1V18a1 1 0 0 0-1-1zM39 13.414l-7.527-7.527A3 3 0 0 0 29.34 5H9a1 1 0 0 0-1 1v7H6V6a3 3 0 0 1 3-3h20.34a4.983 4.983 0 0 1 3.547 1.473L40.414 12zM39 45H9a3 3 0 0 1-3-3v-6h2v6a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-6h2v6a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M8.717 29.086l2.51-3.224-2.365-3.037a1.01 1.01 0 0 1-.228-.575.726.726 0 0 1 .735-.75.757.757 0 0 1 .676.375l2.136 2.913 2.111-2.863a.829.829 0 0 1 .724-.424.693.693 0 0 1 .676.7.919.919 0 0 1-.23.588L13.1 25.837l2.486 3.213a.969.969 0 0 1 .228.575.725.725 0 0 1-.735.749A.755.755 0 0 1 14.4 30l-2.269-3.062L9.9 29.949a.843.843 0 0 1-.724.424.689.689 0 0 1-.676-.712.975.975 0 0 1 .217-.575zM17.555 22.262a.742.742 0 1 1 1.484 0v6.649h3.826a.7.7 0 0 1 0 1.4H18.3a.751.751 0 0 1-.748-.775zM24.811 29.4a.73.73 0 0 1-.29-.587.7.7 0 0 1 .7-.712.669.669 0 0 1 .434.137 3.59 3.59 0 0 0 2.366.9c.917 0 1.5-.45 1.5-1.1v-.024c0-.625-.338-.963-1.906-1.337-1.8-.45-2.812-1-2.812-2.613v-.025a2.617 2.617 0 0 1 2.879-2.539 4.533 4.533 0 0 1 2.678.813.715.715 0 1 1-.76 1.212 3.43 3.43 0 0 0-1.942-.663c-.869 0-1.376.462-1.376 1.037v.025c0 .675.386.975 2.015 1.375 1.786.45 2.7 1.112 2.7 2.562v.025c0 1.638-1.243 2.613-3.017 2.613a5.139 5.139 0 0 1-3.169-1.099zM32.4 29.086l2.51-3.224-2.36-3.037a1 1 0 0 1-.23-.575.727.727 0 0 1 .737-.75.756.756 0 0 1 .675.375l2.136 2.913 2.111-2.863a.829.829 0 0 1 .721-.425.694.694 0 0 1 .676.7.923.923 0 0 1-.229.588l-2.366 3.049 2.489 3.213a.965.965 0 0 1 .23.575.727.727 0 0 1-.737.749.757.757 0 0 1-.676-.374l-2.267-3.063-2.232 3.013a.844.844 0 0 1-.724.424.689.689 0 0 1-.676-.712.975.975 0 0 1 .212-.576z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M9.329 14.877H7.81l-1.095-1.67-1.115 1.67H4.147l1.8-2.564-1.761-2.525H5.7l1.059 1.584 1.089-1.584H9.3L7.525 12.26zM14.151 14.877H10.48V9.788h1.313v4.1h2.358zM19.436 13.278A1.45 1.45 0 0 1 18.8 14.5a2.854 2.854 0 0 1-1.73.47 4.839 4.839 0 0 1-1.1-.111 5.69 5.69 0 0 1-.881-.282v-1.22h.144a3.04 3.04 0 0 0 .911.5 2.956 2.956 0 0 0 .969.174 3.138 3.138 0 0 0 .314-.021 1.268 1.268 0 0 0 .318-.068.818.818 0 0 0 .248-.154.361.361 0 0 0 .1-.273.37.37 0 0 0-.142-.289.988.988 0 0 0-.416-.187 13.792 13.792 0 0 0-.605-.128 4.647 4.647 0 0 1-.6-.152 1.884 1.884 0 0 1-.925-.565 1.391 1.391 0 0 1-.281-.887 1.365 1.365 0 0 1 .637-1.161 2.785 2.785 0 0 1 1.63-.446 5.061 5.061 0 0 1 .994.1 4.6 4.6 0 0 1 .849.244v1.166h-.14a2.71 2.71 0 0 0-.754-.412 2.589 2.589 0 0 0-.911-.166 2.376 2.376 0 0 0-.327.022 1.286 1.286 0 0 0-.312.083.606.606 0 0 0-.229.156.338.338 0 0 0-.1.237.354.354 0 0 0 .154.31 1.721 1.721 0 0 0 .58.2l.539.113a5.57 5.57 0 0 1 .555.15 1.851 1.851 0 0 1 .863.521 1.284 1.284 0 0 1 .284.854z"
          fill="currentColor"
        />
        <path
          d="M23 19H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1zM2 17h20V8H2zM19.151 5.406L16.654 3.4a.879.879 0 0 1-.081-.073C16.265 3.013 16.175 3 16 3H5v2H3V3a1.979 1.979 0 0 1 2-2h11a2.609 2.609 0 0 1 1.949.87l2.457 1.978zM17.857 24H5.143A2.077 2.077 0 0 1 3 22v-2h2v2c0-.042.07 0 .143 0h12.714a.234.234 0 0 0 .159-.063L18 20h2v2a2.077 2.077 0 0 1-2.143 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FileXlsxIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FileXlsxIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FileXlsxIcon;
