import React from 'react';
import { Table, Tooltip, Checkbox, Field } from 'react-unity';
import { PolicyDefinitionResponse } from '../../../models/viewModels/PolicyExemptionRequests/PolicyDefinitionResponse';
import Paginator from '../../common/tables/Paginator';
import { PolicyDefinitionColumn, PolicyDefinitionsColumnsLayout } from './PolicyDefinitionsColumnsLayout';
import '../../common/form-controls/Checkbox.css';

interface PolicyDefinitionsTableProps {
	assignmentName: string;
	value: PolicyDefinitionResponse[];
	policyDefinitions: PolicyDefinitionResponse[];
	readonly?: boolean;
	onChange: (policyDefinitions: PolicyDefinitionResponse[]) => any;
}

interface PolicyDefinitionsTableState { 
	columns: PolicyDefinitionColumn[];
	pageRows: PolicyDefinitionResponse[];
    selectedDefinitions: PolicyDefinitionResponse[];
}

export class PolicyDefinitionsTable extends React.Component<PolicyDefinitionsTableProps, PolicyDefinitionsTableState> {

	constructor(props) {
		super(props);
		this.state = {
			columns: [],
			pageRows: [],
			selectedDefinitions: []
		};
	}

	componentDidMount() {
		this.setColumns();
	}

	setColumns() {
		this.setState({
			columns: PolicyDefinitionsColumnsLayout
		});
	}

    handlePageChange = (page: PolicyDefinitionResponse[]) => {
    	this.setState({
    		pageRows: page
    	});
    };

    changeSelectedDefinitionsList(policyDefinitions: PolicyDefinitionResponse[]) {
    	this.setState({ selectedDefinitions: policyDefinitions });
    	this.props.onChange(policyDefinitions);
    }

    addPolicyDefinition(policyDefinition: PolicyDefinitionResponse) {
    	this.changeSelectedDefinitionsList([...this.state.selectedDefinitions, policyDefinition]);
    }

    removePolicyDefinition(policyDefinition: PolicyDefinitionResponse) {
    	const definitions = this.state.selectedDefinitions.filter(definition => definition !== policyDefinition);
    	this.changeSelectedDefinitionsList(definitions);
    }

    render() {
    	const tableHeaders = this.state.columns?.map((column) => (
    		<Table.Head.Cell key={column.header}>
    			{column.header}
    		</Table.Head.Cell>
    	));
    	const tableRows = [];
    	const defaultLimit = 40;

    	this.state.pageRows.forEach((policyDefinition, rowIndex) => {
    		tableRows.push(
    			<Table.Body.Row
    				key={rowIndex}
    				onClick={() => this.props.value.includes(policyDefinition) ? this.removePolicyDefinition(policyDefinition) : this.addPolicyDefinition(policyDefinition)}
    			>
    				<Table.Body.Cell>
    					{this.props.policyDefinitions.indexOf(policyDefinition) + 1}
    				</Table.Body.Cell>
    				{this.state.columns?.map((column, colIndex) => (
    					<Table.Body.Cell
    						key={`${rowIndex}-${colIndex}`}
    						className="em-u-margin-right-auto"
    						title={(column.cell(policyDefinition) || '-')}
    					>
    						{column.cell(policyDefinition)?.length > (column.limit || defaultLimit) ?
    							(`${column.cell(policyDefinition).trim().substring(0, (column.limit || defaultLimit))}...`)
    							:
    							(column.cell(policyDefinition) || '-')}
    					</Table.Body.Cell>
    				))}
    				{!this.props.readonly &&
						<Table.Body.Cell>
							<Checkbox
								className="chk-no-borders"
								value={rowIndex}
								id={rowIndex.toString()}
								checked={this.props.value.includes(policyDefinition)}
								onChange={(checked) => checked ? this.addPolicyDefinition(policyDefinition) : this.removePolicyDefinition(policyDefinition)}
							/>
						</Table.Body.Cell>}
    			</Table.Body.Row>
    		);
    	});

    	return (
    		<>
    			<Field>
    				<Field.Label>
    					Assignment Name: <strong>{this.props.assignmentName}</strong>
    					<br />
    					<br />
						Policy Definitions
    				</Field.Label>
    				<Field.Body>
    					<Table
    						striped
    						footer={
    							<Paginator
    								data={this.props.policyDefinitions}
    								onPageChange={this.handlePageChange}									
    							/>
    						}
    					>
    						<Table.Head>
    							<Table.Head.Row>
    								<Table.Head.Cell />
    								{tableHeaders}
    								{!this.props.readonly &&
                                <Table.Head.Cell>Select</Table.Head.Cell>}
    							</Table.Head.Row>
    						</Table.Head>
    						<Table.Body>
    							{tableRows.length > 0 ?
    								tableRows
    								:
    								<Table.Body.Row>
    									<Table.Body.Cell
    										colSpan={this.state.columns?.length + 2 || 2}
    									>
    										<i>No policy definitions found.</i>
    									</Table.Body.Cell>
    								</Table.Body.Row>}
    						</Table.Body>
    					</Table>
    				</Field.Body>
    			</Field>
    		</>
    	);
    }
}