import * as React from 'react';
import { Modal, Button, Alert, XFilledIcon, Grid } from 'react-unity';
import TextInputField from '../../common/form-controls/TextInputField';
import TextAreaField from '../../common/form-controls/TextareaField';
import SelectField from '../../common/form-controls/SelectField';
import FormModel from '../../../utils/FormModel';
import ReactButton from '../../common/ReactButton';
import SubmitFeedback from '../../../models/viewModels/Feedback/SubmitFeedback';
import { authenticatedUser } from '../../../authentication/authModule';
import FeedbackService from '../../../services/FeedbackService';

interface CreateFeedbackModalProps {
	visible: boolean;
	onConfirm: (event: React.ChangeEvent<any>) => void;
	onCancel?: (event: React.ChangeEvent<any>) => void;
	onClose: (event: React.ChangeEvent<any>) => void;
}
interface CreateFeedbackModalState {
	title: string;
	description: string;
	category: string;
	loading: boolean;
	errorAlert: boolean;
}

export default class CreateFeedbackModal extends React.Component<
	CreateFeedbackModalProps,
	CreateFeedbackModalState
	> {
	form: FormModel;

	feedbackService: FeedbackService;

	constructor(props: CreateFeedbackModalProps) {
		super(props);
		this.state = {
			title: '',
			description: '',
			category: '',
			loading: false,
			errorAlert: false
		};
		this.feedbackService = new FeedbackService();
		this.form = new FormModel({
			title: {
				getValue: () => this.state.title,
				validation: {
					required: true,
				},
			},
			description: {
				getValue: () => this.state.description,
				validation: {
					required: true,
				},
			},
			category: {
				getValue: () => this.state.category,
				validation: {
					required: true,
				},
			}
		});
	}

	handleSubmitFeedback = async (e) => {

		const body = this.form.create(SubmitFeedback);

		body.submittedBy = authenticatedUser.userId;

		body.submitDate = new Date();

		this.setState({
			loading: true
		});
		this.feedbackService.SubmitFeedback(body).then(() => {
			this.setState({
				loading: false
			});
			this.props.onConfirm(e);
		}).catch(() => {
			this.setState({
				errorAlert: true,
				loading: false
			});
		});
	};

	componentDidUpdate = async (prevProps: CreateFeedbackModalProps) => {

		if (prevProps.visible == false && this.props.visible) {

			this.setState({ category: '', description: '', title: '' });
		}
	};

	render() {
		return (
			<Modal show={this.props.visible}
				onHide={() => { }}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>Contact Us</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<TextInputField
							label="Title"
							value={this.state.title}
							validation={this.form.fields.title.validation}
							onChange={(event) => {
								this.setState({
									title: event.target.value,
								});
							}}
						/>
						<SelectField
							label="Category"
							value={this.state.category}
							options={['Bug', 'Comment', 'Question', 'Suggestion']}
							className="full-width"
							onChange={(e) => this.setState({ category: e.target.value })}
							validation={this.form.fields.category.validation}
						/>
						<TextAreaField
							label="Description"
							value={this.state.description}
							validation={this.form.fields.description.validation}
							onChange={(event) => {
								this.setState({
									description: event.target.value,
								});
							}}
						/>
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Grid variant="2-up">
							<Grid.Item>
								<Button variant="secondary" disabled={this.state.loading} onClick={this.props.onCancel || this.props.onClose}>
									Cancel
								</Button>
							</Grid.Item>
							<Grid.Item>
								<ReactButton
									variant='secondary'
									loadingVariant='secondary'
									isLoading={this.state.loading}
									handleUpdateSubmit={this.handleSubmitFeedback}
									disabled={!this.form.isValid()}
									name='Submit'
									size={'medium'}
								>
								</ReactButton>
							</Grid.Item>
						</Grid>
						{this.state.errorAlert &&
							<Alert variant="error" onClose={() => this.setState({ errorAlert: false })}>
								Something went wrong when trying to update the entry.
							</Alert>}
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}
