/* eslint-disable no-useless-escape */
import * as React from 'react';
import {
	Modal,
	Button,
	Grid,
	XFilledIcon
} from 'react-unity';
import './RuleModal.css';
import TextAreaField from '../../../common/form-controls/TextareaField';
import ToggleField from '../../../common/form-controls/ToggleField';
import TextInputField from '../../../common/form-controls/TextInputField';
import { AbstractRulesForm, IRuleStateForm, RulesFormProps } from './AbstractRulesForm';
import { RuleAction } from '../../../../models/enums/POR/RuleAction';
import { RuleRegion } from '../../../../models/enums/POR/RuleRegion';
import SelectField from '../../../common/form-controls/SelectField';
import { RuleOperation } from '../../../../models/enums/POR/RuleOperation';
import { PortOpeningRequestsDirection } from '../../../../models/enums/POR/PortOpeningRequestsDirection';
import DatePickerField from '../../../common/form-controls/DatePickerField';
import moment from 'moment';

interface RuleModalSubToInternetProps extends RulesFormProps{
	subscriptionRequestName: string;
	subscriptionRequestId: number;
}

interface SubToInternetRuleStateForm extends IRuleStateForm {
	name: string;
	destinationURLs: string;
}
interface RuleModalSubToInternetState {
	stateForm: SubToInternetRuleStateForm;
}

export default class RuleModalSubToInternet extends AbstractRulesForm<
	RuleModalSubToInternetProps,
	RuleModalSubToInternetState
> {

	constructor(props: RuleModalSubToInternetProps) {
		super(props);
		this.state = {
			stateForm: this.props.isFromHistory ? this.getRuleCopy() : this.getDefaultForm(),
		};

		this.initFormModel();
	}

	isURLValid = (url) => /^(http(s)?:\/\/)?(www\.)?[a-zA-ZA-Z0-9-]+(\.[a-zA-ZA-Z0-9-]+)+(\/[^\s]*)?$/.test(url);

	initFormModel() {
		super.initFormModel();
		this.formModel.addField('name', {
			getValue: () => this.state.stateForm.name,
			validation: {
				required: true,
				rules:
					[
						{
							assert: () => this.state.stateForm.operation !== RuleOperation.Add
								|| this.state.stateForm.name?.length <= 24,
							message: 'Name cannot be longer than 24 characters.',
						},
						{
							assert: () => !this.state.stateForm.name?.includes(' '),
							message: 'Name cannot contain spaces.',
						},
						{
							assert: () => this.state.stateForm.operation !== RuleOperation.Add
							|| /^(?![-_])[a-zA-Z][a-zA-Z-_]*(?<![-_])$/.test(this.state.stateForm.name ?? ''),
							message: 'Only letters (uppercase and lowercase), dashes, underscores are allowed. No trailing or beginning dashes or underscores.',
						}
					]
			},
		});	

		this.formModel.fields.sourceIPs.validation.rules.push(
			this.IpInputValidationRule({
				onlyPrivateAddresses: false
			}),
			this.IpIsInAddressSpacesRule()
		);

		this.formModel.fields.destinationIPs.validation.required =
			() => !(this.state.stateForm.destinationURLs?.trim().length > 0);
		
		this.formModel.fields.destinationIPs.validation.rules.push(
			this.IpInputValidationRule({
				onlyPrivateAddresses: false
			})
		);

		const numberOfDestinationItemsValidation = {
			assert: () => {
				const numberOfIPs = this.state.stateForm.destinationIPs?.split(';').filter(s => s !== '').length ?? 0;
				const numberOfURLs = this.state.stateForm.destinationURLs?.split(';').filter(s => s !== '').length ?? 0;
				const numberOfDestinationItems = numberOfIPs + numberOfURLs;
				const maxNumberOfDestinationItems = 50;
				return numberOfDestinationItems <= maxNumberOfDestinationItems;
			},
			message: "Total Destination URLs and IPs can't be greater than 50"
		}

		this.formModel.addField('destinationURLs', {
			getValue: () => this.parseMultipleValues(this.state.stateForm.destinationURLs),
			validation: {
				required: () => !(this.state.stateForm.destinationIPs?.trim().length > 0),
				rules: [
					{
						assert: () => !this.hasEmptyValues(this.state.stateForm.destinationURLs),
						message: 'Empty values are not allowed.'
					},
					{
						assert: () => !(this.state.stateForm.destinationURLs.includes('https://')
						||this.state.stateForm.destinationURLs.includes('http://')),
						message: 'URL cannot have "http" or "https"'
					},
					{
						assert: (value) => {
							const urls = this.parseMultipleValues(value)?.split(';');
							return urls?.every(url => this.isURLValid(url));
						},
						message: (value) => {
							const invalidAddress = this.parseMultipleValues(value)?.split(';').find(url => !this.isURLValid(url));
							return `${invalidAddress} is an invalid URL.`;
						}
					},
					numberOfDestinationItemsValidation
				]
			}
		});

		this.formModel.fields.destinationIPs.validation.rules.push(numberOfDestinationItemsValidation);

		this.formModel.fields.destinationPorts.validation.rules.push(
			this.PortInputValidationRule({
				minimumPort: 1
			})
		);
	}

	// eslint-disable-next-line class-methods-use-this
	getDefaultFormState() {
		return {
			isTemporary: false,
			expirationDate: null,
			operation: RuleOperation.Add,
			name: '',
			destinationURLs: ''
		};
	}

	getRegionOptions(): RuleRegion[] {
		var subToInternetRegions = new RuleRegion().listByDirection(PortOpeningRequestsDirection.SubscriptionToInternet, this.props.validVirtualNetworks, this.props.subscriptionRequestId);
		const wwSubscriptionsNames = ['APPS_SharedSvcs_WW_DEV', 'APPS_SharedSvcs_WW_LAB', 'APPS_SharedSvcs_WW_PRD'];
		if(wwSubscriptionsNames.includes(this.props.subscriptionRequestName))
			subToInternetRegions.push(RuleRegion.WW);
		return subToInternetRegions;
	}

	wasEditted() {
		if(this.props.editingRule){
			return (
				this.props.editingRule.description != this.stateFormHandler().description.value
				|| this.props.editingRule.isTemporary != this.stateFormHandler().isTemporary.value
				|| this.props.editingRule.action.value != this.stateFormHandler().action.value
				|| this.props.editingRule.sourceIPs != this.stateFormHandler().sourceIPs.value
				|| this.props.editingRule.destinationIPs != this.stateFormHandler().destinationIPs.value
				|| this.props.editingRule.destinationPorts != this.stateFormHandler().destinationPorts.value
				|| this.props.editingRule.destinationURLs != this.state.stateForm.destinationURLs
				|| (this.props.editingRule.operation.value == RuleOperation.Add.value && (this.props.editingRule.name != this.state.stateForm.name))
		)}
		return true
	}

	render() {
		return (
			<Modal show={this.props.visible}
            onHide={() => { }}>
				<Modal.Window className="ruleModal">
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							{this.props.isReadOnly ? 'View Rule' : !this.isModalForNewRule() ? 'Edit Rule' : 'New Rule'}
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onClose}>
							<XFilledIcon size='small' />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						{ !this.isModalForNewRule() &&
							<ToggleField
							{...this.stateFormHandler().operation}
							label="Operation"
							disabled={this.props.isReadOnly}
							value={this.state.stateForm.operation?.value}
							options={this.props.isReadOnly ?
								[
									new RuleOperation().fromValue(this.stateFormHandler().operation.value)
								]
								:!this.stateFormHandler().isTemporary.value ?
								[
									RuleOperation.Modify,
									RuleOperation.Remove
								]:
								[
									RuleOperation.Remove
								]
							}
							/>
						}
						<Grid variant="4-up">
									<Grid.Item>
										<ToggleField
											{...this.stateFormHandler().isTemporary}
											value={this.state.stateForm.isTemporary?.toString()}
											options= {(this.formCanBeEdited())? [
												{ text: 'Temporary', value: 'true' },
												{ text: 'Permanent', value: 'false' },
												]
												:this.state.stateForm.isTemporary?
												[
												{ text: 'Temporary', value: 'true' }]
												:
												[{ text: 'Permanent', value: 'false' },
											]}
											label="Type"
											disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<DatePickerField style={{ visibility: this.stateFormHandler().expirationDate.value ? 'visible' : 'hidden' }}
											{...this.stateFormHandler().expirationDate}											
											value={ this.stateFormHandler().expirationDate.value ? moment(this.stateFormHandler().expirationDate.value).format('MM-YYYY-DD'):''}
											disabled= {true}
										/>
									</Grid.Item>
						</Grid>
						<Grid variant="2-up">
							<Grid.Item>
								<TextInputField
									label="Rule Name"
									value={this.state.stateForm.name}
									validation={this.formModel.fields.name.validation}
									onChange={
										(event) => {
											this.handleStateFormChange('name', event.target.value);
										}
									}
									disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
								/>
							</Grid.Item>
							<Grid.Item>
								<SelectField
									{...this.stateFormHandler().region}
									options={this.getRegionOptions()}
									disabled={this.state.stateForm.operation?.name !== RuleOperation.Add.name || !this.formCanBeEdited()}
								/>
							</Grid.Item>
						</Grid>
						{this.state.stateForm.operation?.name !== RuleOperation.Remove.name &&
							<>
								<Grid variant="2-up">
									<Grid.Item>
										<ToggleField
											{...this.stateFormHandler().action}
											options= { (this.formCanBeEdited())? [
												RuleAction.Allow,
												RuleAction.Block,
											]:this.stateFormHandler().action?.value?
											[RuleAction.Allow]
											:
											[RuleAction.Block]
											}
											label="Action"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item />
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().sourceIPs}
											label="Source (IPs / IP Range / CIDR)"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().destinationIPs}
											label="Destination (IPs / IP range / CIDR)"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().destinationPorts}
											label="Destination Port(s)"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item>
										<TextAreaField
											label="Destination (URLs)"
											value={this.state.stateForm.destinationURLs}
											validation={this.formModel.fields.destinationURLs.validation}
											onChange={
												(event) => {
													this.handleStateFormChange('destinationURLs', event.target.value);
												}
											}
											note='Multiple values are allowed, separated by semicolons.'
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
								</Grid>
								<Grid variant="2-up">
									<Grid.Item>
										<TextAreaField
											{...this.stateFormHandler().description}
											label="Description"
											disabled={!this.formCanBeEdited()}
										/>
									</Grid.Item>
									<Grid.Item />
								</Grid>
							</>}
					</Modal.Window.Body>
					{ !this.props.isReadOnly &&
						<Modal.Window.Footer>
							<Grid variant="2-up">
								<Grid.Item>
									<Button
										variant="primary"
										disabled={(!this.formModel.isValid() || !this.wasEditted()) && this.state.stateForm.operation.name !== RuleOperation.Remove.name}					
										onClick={this.handleRequest}
									>
										{this.props.editingRule ? 'Confirm' : 'Create'}
									</Button>
								</Grid.Item>
								<Grid.Item>
									<Button
										variant="secondary"
										onClick={this.handleCancel}
									>
										Cancel
									</Button>
								</Grid.Item>
							</Grid>
						</Modal.Window.Footer>
					}

				</Modal.Window>
			</Modal>
		);
	}
}