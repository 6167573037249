import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MapWPinIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 7A3.5 3.5 0 1 1 12 3.5 3.5 3.5 0 0 1 8.5 7zm0-6A2.5 2.5 0 1 0 11 3.5 2.5 2.5 0 0 0 8.5 1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 6h1v5H8z" />
        <path
          d="M.5 16a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .371-.483l3.483-.929.257.966L1 5.884v8.877l4.314-1.726a.5.5 0 0 1 .371 0l4.815 1.926 4.5-1.8V4.238l-1.841.736-.371-.929 2.526-1.01A.5.5 0 0 1 16 3.5v10a.5.5 0 0 1-.314.464l-5 2a.5.5 0 0 1-.371 0L5.5 14.039.686 15.964A.5.5 0 0 1 .5 16z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 20a9 9 0 1 1 9-9 9.01 9.01 0 0 1-9 9zm0-16a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 18h2v12h-2z" />
        <path
          d="M31.975 43a2 2 0 0 1-.648-.107l-15.3-5.259-12.292 4.822a2.023 2.023 0 0 1-1.875-.214 1.976 1.976 0 0 1-.86-1.63v-26.92a1.994 1.994 0 0 1 1.268-1.845.971.971 0 0 1 .118-.038l9.339-2.354.489 1.939-9.222 2.325L3 40.612l12.285-4.835a2.008 2.008 0 0 1 1.387-.036L31.975 41 45 35.885v-26.9a1.242 1.242 0 0 1-.139.061l-8.6 2.046-.461-1.948 8.543-2.034a2.02 2.02 0 0 1 1.8.242A1.976 1.976 0 0 1 47 8.982V35.9a1.994 1.994 0 0 1-1.268 1.845l-13.017 5.11a1.989 1.989 0 0 1-.74.145z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M15 23h2v14h-2zM31 30h2v12h-2z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M17 24.06a.994.994 0 0 1-.332-.06l-7.645-2.682-6.651 2.67A1 1 0 0 1 1 23.06V6.25a1 1 0 0 1 .691-.95l4.951-1.61.618 1.9L3 6.977v14.6l5.627-2.259a1.007 1.007 0 0 1 .7-.015l7.552 2.652L21 19.439V5.477l-1.557.623-.743-1.857 2.929-1.171A1 1 0 0 1 23 4v16a1 1 0 0 1-.478.853l-5 3.06a1 1 0 0 1-.522.147z"
          fill="currentColor"
        />
        <path
          d="M13 10a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M12 9h2v8h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MapWPinIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MapWPinIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MapWPinIcon;
