import UpdateDoNotDecryptRequestRule from './UpdateDoNotDecryptRequestRule';

export default class UpdateDoNotDecryptRequest {

	doNotDecryptRules!: UpdateDoNotDecryptRequestRule[]; 

	contactId!: string;

	emergency!: boolean;

	incidentNumber!: string;

	businessJustification!: string;

	comments!: string;

	customerCoordination!: boolean;

	custodianApproval!: boolean;

	sevenDayExemption!: boolean;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}