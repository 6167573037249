import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import "./Loader.css";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-loader--main";
    case "accent":
      return "em-c-loader--accent";
    case "action":
      return "em-c-loader--action";
    case "caution":
      return "em-c-loader--caution";
    case "negative":
      return "em-c-loader--negative";
    case "positive":
      return "em-c-loader--positive";
    case "white":
      return "em-c-loader--white";
    case "black":
      return "em-c-loader--black";
    case "blue":
    default:
      return "em-c-loader--main";
  }
}

/** Signifies that a page (or page element) is loading. */
const Loader = props => {
  const { className, color, ...other } = props;

  return (
    <span
      className={clsx("em-c-loader", getColor(color), className)}
      {...other}
    >
      <svg
        id="loader"
        width="100%"
        height="100%"
        viewBox="0 0 105 105"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="#225896"
      >
        <circle cx="12.5" cy="12.5" r="12.5" className="circle-1" />
        <circle cx="12.5" cy="52.5" r="12.5" className="circle-2" />
        <circle cx="52.5" cy="12.5" r="12.5" className="circle-3" />
        <circle cx="52.5" cy="52.5" r="12.5" className="circle-4" />
        <circle cx="92.5" cy="12.5" r="12.5" className="circle-5" />
        <circle cx="92.5" cy="52.5" r="12.5" className="circle-6" />
        <circle cx="12.5" cy="92.5" r="12.5" className="circle-7" />
        <circle cx="52.5" cy="92.5" r="12.5" className="circle-8" />
        <circle cx="92.5" cy="92.5" r="12.5" className="circle-9" />
      </svg>
    </span>
  );
};

Loader.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Theme color */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "caution",
    "positive",
    "negative",
    "blue",
    "white",
    "black"
  ])
};

Loader.defaultProps = {
  className: "",
  color: "blue"
};

export default Loader;
