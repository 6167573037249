import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ExpandIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 11h-5a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM6 10h4V6H6zM2 5H1V1.5a.5.5 0 0 1 .5-.5H5v1H2zM15 5h-1V2h-3V1h3.5a.5.5 0 0 1 .5.5zM14.5 15H11v-1h3v-3h1v3.5a.5.5 0 0 1-.5.5zM5 15H1.5a.5.5 0 0 1-.5-.5V11h1v3h3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M31 32H18a1 1 0 0 1-1-1V18a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1zm-12-2h11V19H19zM7 17H5V6a1 1 0 0 1 1-1h11v2H7zM44 17h-2V7H32V5h11a1 1 0 0 1 1 1zM43 44H32v-2h10V32h2v11a1 1 0 0 1-1 1zM17 44H6a1 1 0 0 1-1-1V32h2v10h10z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M15 16H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zm-6-2h5V9H9zM3 8H1V2a1 1 0 0 1 1-1h6v2H3zM22 8h-2V3h-5V1h6a1 1 0 0 1 1 1zM21 22h-6v-2h5v-5h2v6a1 1 0 0 1-1 1zM8 22H2a1 1 0 0 1-1-1v-6h2v5h5z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ExpandIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ExpandIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ExpandIcon;
