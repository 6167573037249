import * as React from 'react';
import SelectField from '../../common/form-controls/SelectField';
import FieldHandler from '../../../models/interfaces/FieldHandler';
import { Environment } from '../../../models/enums/Environment';

interface EnvironmentInputProps extends FieldHandler<Environment> { 
	onChange: (env: Environment) => any;
}

interface EnvironmentInputState {}

export class EnvironmentInput extends React.Component<EnvironmentInputProps, EnvironmentInputState> {
	render() {
		return (
			<SelectField
				label="Environment"
				className="full-width"
				options={
					[
						{ text: 'DEV', value: Environment.Development.value },
						{ text: 'LAB', value: Environment.Lab.value }
					]
				}
				{...this.props}
				value={this.props.value?.value}
				onChange={(event) => {
					const env = new Environment().fromValue(Number(event.target.value));
					this.props.onChange(env);
				}}
			/>
		);
	}
}