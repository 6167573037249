import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ModalWindowHeader from "./ModalWindowHeader";
import ModalWindowFooter from "./ModalWindowFooter";
import ModalWindowBody from "./ModalWindowBody";

const ModalWindow = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-modal__window", className)} {...other}>
      {children}
    </div>
  );
};

ModalWindow.Header = ModalWindowHeader;
ModalWindow.Footer = ModalWindowFooter;
ModalWindow.Body = ModalWindowBody;

ModalWindow.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

ModalWindow.defaultProps = {
  className: "",
  children: null
};

export default ModalWindow;
