import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SortUpIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M1 12h14v1H1zM3 8h10v1H3zM5 4h6v1H5z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M27 30h14v2H27zM3 40h42v2H3zM7 30h14v2H7zM27 20h8v2h-8zM13 20h8v2h-8zM23 8h2v30h-2z"
        />
        <path
          d="M31.02 11.414l-6.3-6.128a1.038 1.038 0 0 0-1.435 0l-6.3 6.127-1.399-1.433 6.3-6.128a3.053 3.053 0 0 1 4.222 0l6.3 6.129z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11 1h2v9h-2z" />
        <path
          d="M16 6.123l-4-4-4 4-1.414-1.414L10.722.574a1.72 1.72 0 0 1 2.507-.047l4.186 4.182zM2 20h20v2H2zM4 16h16v2H4zM7 12h10v2H7z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SortUpIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SortUpIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SortUpIcon;
