import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FilmstripIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 15h-13a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5zM2 14h12V3H2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M3 6h2v1H3zM3 8h2v1H3zM3 10h2v1H3zM3 12h2v1H3zM11 6h2v1h-2zM3 4h2v1H3zM11 4h2v1h-2zM11 8h2v1h-2zM11 10h2v1h-2zM11 12h2v1h-2zM10 14H9v-4H7v4H6V9.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5zM9.5 8h-3a.5.5 0 0 1-.5-.5V3h1v4h2V3h1v4.5a.5.5 0 0 1-.5.5z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 45H5a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h38a1 1 0 0 1 1 1v40a1 1 0 0 1-1 1zM6 43h36V5H6z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M8 11h4v2H8zM8 15h4v2H8zM8 19h4v2H8zM8 27h4v2H8zM8 23h4v2H8zM8 31h4v2H8zM8 35h4v2H8zM8 39h4v2H8zM36 15h4v2h-4zM36 11h4v2h-4zM8 7h4v2H8zM36 7h4v2h-4zM36 19h4v2h-4zM36 23h4v2h-4zM36 27h4v2h-4zM36 31h4v2h-4zM36 35h4v2h-4zM36 39h4v2h-4zM34 41h-2V27H16v14h-2V26a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1zM33 23H15a1 1 0 0 1-1-1V7h2v14h16V7h2v15a1 1 0 0 1-1 1z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M23 24H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v22a1 1 0 0 1-1 1zM2 22h20V2H2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M18 3h3v2h-3zM18 7h3v2h-3zM18 11h3v2h-3zM18 15h3v2h-3zM18 19h3v2h-3zM3 3h3v2H3zM3 7h3v2H3zM3 11h3v2H3zM3 15h3v2H3zM3 19h3v2H3zM17 21h-2v-6H9v6H7v-7a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zM16 11H8a1 1 0 0 1-1-1V3h2v6h6V3h2v7a1 1 0 0 1-1 1z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FilmstripIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FilmstripIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FilmstripIcon;
