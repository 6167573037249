import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DeviceTabletIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 15h-13A1.5 1.5 0 0 1 0 13.5v-10A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v10a1.5 1.5 0 0 1-1.5 1.5zM1.5 3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M11.5 13h-9a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM3 12h8V5H3z"
          fill="currentColor"
        />
        <circle cx={13.5} cy={8.5} r={0.5} fill="#112" />
        <path
          d="M13.5 8a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M45 44H3a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h42a3 3 0 0 1 3 3v34a3 3 0 0 1-3 3zM3 6a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M37 40H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h32a1 1 0 0 1 1 1v30a1 1 0 0 1-1 1zM6 38h30V10H6zM42 27a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 22H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2zm0-18H2v16h20z"
          fill="currentColor"
        />
        <path
          d="M17 19.066H4a1 1 0 0 1-1-1V5.934a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v12.132a1 1 0 0 1-1 1zm-12-2h11V6.934H5z"
          fill="currentColor"
        />
        <circle cx={20} cy={12} r={1} fill="#112" />
        <path d="M20 11a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" fill="currentColor" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DeviceTabletIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DeviceTabletIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DeviceTabletIcon;
