import * as React from 'react';
import { Container, TwoColumnLayout, TextPassage, Section, Well, ProgressBar, DefinitionList, TextLink, Tooltip, CircleExclamationMarkIcon, Badge } from 'react-unity';
import moment from 'moment';
import WorkflowInstance from '../../../models/WorkflowInstance';
import WorkflowStateBadge from '../WorkflowStateBadge';
import { LinkTree, LinkTreeItem } from '../LinkTree';
import './WorkflowDetailWrapper.css';
import serviceNow from '../../../services/serviceNow.config';


interface WorkflowDetailWrapperProps {
	title: string;
	loading?: boolean;
	workflowInstance?: WorkflowInstance;
	linkTree?: LinkTreeItem[];
	actions?: any;
	fromPOR?: boolean;
	fromDNDR?: boolean;
	children?: any;
}

export default class WorkflowDetailWrapper extends React.Component<WorkflowDetailWrapperProps> {
	hasAvailableActions = (): boolean => {
		return !!this.props.actions && this.props.actions?.props?.children?.some(action => !!action);
	};

	getTicketURL = (ticket: string) => {
		return ticket.toUpperCase().includes('CRQ') ?
			`https://itconsole.na.xom.com/#/${ticket}`
			: `https://${serviceNow.serviceNowEnv}.service-now.com/nav_to.do?uri=${ticket.toUpperCase().includes('RITM') ? 'sc_req_item' : 'change_request'}.do?sysparm_query=number=${ticket}&sysparm_limit=1`;
	};

	wasCreatedByAnApp = () => {
		if (this.props.workflowInstance?.createdBy.id != this.props.workflowInstance?.owner.id) {
			return this.props.workflowInstance?.createdBy.email == null ? true : false;
		}
		return false;
	};

	render() {
		const currentState = this.props.workflowInstance?.currentWorkflowInstanceState;
		return (
			<Container className="em-u-max-width-layout em-u-margin-top-double em-u-margin-bottom-double">
				{!this.props.loading && this.props.linkTree?.length > 0 &&
					<LinkTree links={this.props.linkTree} />}
				<TextPassage>
					<h2 className="em-u-margin-top-double">
						{
							!this.props.loading ? this.props.title
								:
								<ProgressBar indeterminate hideValueLabel label="Loading data..." />
						}
					</h2>
				</TextPassage>
				{!this.props.loading &&
					<TwoColumnLayout>
						<TwoColumnLayout.Main className="em-u-margin-right-quad">
							{this.props.children}
						</TwoColumnLayout.Main>
						<TwoColumnLayout.Secondary>
							<Well id="well" className="em-u-min-width-20 border-color">
								{this.hasAvailableActions() &&
									<Section id="actions-section" title="Actions" className="container-style">
										{this.props.actions}
									</Section>}
								<Section title="Request" className="container-style">
									<DefinitionList>
										{!this.props.fromPOR || !this.props.fromDNDR &&
											<DefinitionList.Item>
												<DefinitionList.Item.Key>Current Status</DefinitionList.Item.Key>
												<DefinitionList.Item.Value>
													<WorkflowStateBadge
														workflowState={currentState.workflowState}
													/>
												</DefinitionList.Item.Value>
											</DefinitionList.Item>
										}
										{this.wasCreatedByAnApp() ? <div className='small-margin '>
											<DefinitionList.Item>
												<DefinitionList.Item.Key>
													<Badge color="positive">Requested By an APP</Badge>
												</DefinitionList.Item.Key>
												<DefinitionList.Item.Value>
												</DefinitionList.Item.Value>
											</DefinitionList.Item>
											<DefinitionList.Item>
												<DefinitionList.Item.Key>Name</DefinitionList.Item.Key>
												<DefinitionList.Item.Value style={{ wordBreak: 'normal' }}>
													{this.props.workflowInstance?.createdBy.displayName}
												</DefinitionList.Item.Value>
											</DefinitionList.Item>
											<DefinitionList.Item>
												<DefinitionList.Item.Key>Id</DefinitionList.Item.Key>
												<DefinitionList.Item.Value style={{ wordBreak: 'normal' }}>
													{this.props.workflowInstance?.createdBy.id}
												</DefinitionList.Item.Value>
											</DefinitionList.Item>
											<DefinitionList.Item>
												<DefinitionList.Item.Key>Requested Date</DefinitionList.Item.Key>
												<DefinitionList.Item.Value>
													{moment(this.props.workflowInstance?.createdDate).format('MMM DD, YYYY')}
												</DefinitionList.Item.Value>
											</DefinitionList.Item>
										</div> : <div className='small-margin '>
											<DefinitionList.Item>
												<DefinitionList.Item.Key>Requested By</DefinitionList.Item.Key>
												<DefinitionList.Item.Value style={{ wordBreak: 'normal' }}>
													{this.props.workflowInstance?.createdBy.displayName}
												</DefinitionList.Item.Value>
											</DefinitionList.Item>
											<DefinitionList.Item>
												<DefinitionList.Item.Key>Requested Date</DefinitionList.Item.Key>
												<DefinitionList.Item.Value>
													{moment(this.props.workflowInstance?.createdDate).format('MMM DD, YYYY')}
												</DefinitionList.Item.Value>
											</DefinitionList.Item>
										</div>}
										{this.props.workflowInstance?.currentWorkflowInstanceState.ticketId !== null &&
											<DefinitionList.Item>
												<DefinitionList.Item.Key>Ticket #</DefinitionList.Item.Key>
												<DefinitionList.Item.Value>
													<TextLink
														external
														href={this.getTicketURL(this.props.workflowInstance?.currentWorkflowInstanceState.ticketId)}
														target="_blank"
														className="em-u-display-inline"
													>
														<span>{this.props.workflowInstance?.currentWorkflowInstanceState.ticketId}</span>&nbsp;
													</TextLink>
												</DefinitionList.Item.Value>
											</DefinitionList.Item>}
									</DefinitionList>
								</Section >
								{!!currentState.performedReason && 
								<Section title="Last Comment" className="container-style">
									<p>
										{currentState.performedReason}
									</p>
									<small>
										<i>
											{currentState.performedByUser.displayName}, {moment(currentState.performedOnDate).format('MM/DD/YYYY')}
										</i>
									</small>
								</Section>}
							</Well>
						</TwoColumnLayout.Secondary>
					</TwoColumnLayout>}
			</Container>
		);
	}
}