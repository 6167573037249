import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PictureCardBodyDescription = props => {
  const { className, children, ...other } = props;

  return (
    <p className={clsx("em-c-picture-card__desc", className)} {...other}>
      {children}
    </p>
  );
};

PictureCardBodyDescription.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

PictureCardBodyDescription.defaultProps = {
  className: "",
  children: null
};

export default PictureCardBodyDescription;
