import * as React from 'react';
import { TextArea, TextAreaProps } from 'react-unity';
import { Field } from './Field';
import './TextareaField.css';

export default class TextAreaField extends Field<TextAreaProps> {
	render() {
		return (
			<TextArea
				{...this.props}
				valid={this.isValid()}
				error={!this.isValid()}
				note={this.nonValidMessage() || this.props.note}
			/>
		);
	}
}
