import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TooltipContent from "./TooltipContent";
import TooltipPassage from "./TooltipPassage";

/** Tooltips let the user hover to get a message */
const Tooltip = props => {
  const { variant, color, error, className, children, ...other } = props;

  return (
    <span
      className={clsx(
        "em-c-tooltip",
        {
          "em-c-tooltip--light": color === "light",
          "em-c-tooltip--below": variant === "below",
          "em-c-tooltip--error": error
        },
        className
      )}
      {...other}
    >
      <span className="em-c-tooltip__trigger ">{children}</span>
    </span>
  );
};

Tooltip.propTypes = {
  /** placement of tooltip */
  variant: PropTypes.oneOf(["above", "below"]),
  /** Color of tooltip content */
  color: PropTypes.oneOf(["dark", "light"]),
  /** If the Tooltip is error state or not */
  error: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node
};

Tooltip.defaultProps = {
  variant: "above",
  color: "dark",
  error: false,
  className: "",
  children: null
};

Tooltip.Content = TooltipContent;
Tooltip.Passage = TooltipPassage;

export default Tooltip;
