import ApiClient from './ApiClient';
import SubscriptionRequest from '../models/entities/SubscriptionRequest';
import { AzureResourceModel, ImportSubscriptionModel, RequestSubscriptionModel, UpdateSubscriptionModel, GrantConsentIdentity } from '../models/viewModels/Subscriptions';
import RejectionModel from '../models/interfaces/RejectionModel';
import PolicyExemptionRequest from '../models/entities/PolicyExemptionRequest';
import { PolicyAssignmentResponse } from '../models/viewModels/PolicyExemptionRequests/PolicyAssignmentResponse';
import ChangeHistory from '../models/entities/ChangeHistory';
import EntityChangeHistoryService from '../models/interfaces/EntityChangeHistoryService';
import { VirtualNetwork } from '../models/entities/AzureResources/VirtualNetwork';
import { GrantResponse } from '../models/entities/GrantResponse';
import { DomainOperationRequest } from '../models/viewModels/Subscriptions/DomainOperationRequest';
import ResourceGroup from '../models/entities/ResourceGroup';

export default class SubscriptionRequestsService extends ApiClient implements EntityChangeHistoryService {
	async getAll() {
		return this.get<SubscriptionRequest[]>('SubscriptionRequests');
	}

	async getMySubscriptions() {
		return this.get<SubscriptionRequest[]>('SubscriptionRequests/me');
	}

	async getOwnedSubscriptions() {
		return this.get<SubscriptionRequest[]>('SubscriptionRequests/owned');
	}

	async getById(id: number) {
		return this.get<SubscriptionRequest>(`SubscriptionRequests/${id}`);
	}

	async getByIdValidateAccessPOR(id: number) {
		return this.get<SubscriptionRequest>(`SubscriptionRequests/${id}?includePORs=true`);
	}

	async getPORBySubscription(subscriptionId: number, porId: number) {
		if (isNaN(porId)) {
			return this.get<SubscriptionRequest>(`SubscriptionRequests/${subscriptionId}/por/`);
		}
		return this.get<SubscriptionRequest>(`SubscriptionRequests/${subscriptionId}/por/${porId}`);
	}

	async create(model: RequestSubscriptionModel) {
		return this.post<SubscriptionRequest>('SubscriptionRequests', model);
	}

	async createConnected(model: RequestSubscriptionModel) {
		return this.post<SubscriptionRequest>('SubscriptionRequests/requestConnected', model);
	}

	async import(model: ImportSubscriptionModel) {
		return this.post<SubscriptionRequest>('SubscriptionRequests/import', model);
	}

	async update(id: number, model: UpdateSubscriptionModel) {
		return this.patch<SubscriptionRequest>(`SubscriptionRequests/${id}`, model);
	}

	async updateMultiple(model: UpdateSubscriptionModel[]) {
		return this.patch<SubscriptionRequest>(`SubscriptionRequests/`, model);
	}

	async approve(id: number) {
		return this.patch<SubscriptionRequest>(`SubscriptionRequests/${id}/approval`, null);
	}

	async reject(id: number, model: RejectionModel) {
		return this.patch<null>(`SubscriptionRequests/${id}/rejection`, model);
	}
	
	async getAllResourceGroupsInSubscription(subscriptionId: number) {
		return this.get<AzureResourceModel[]>(`SubscriptionRequests/${subscriptionId}/ResourceGroups`);
	}

	async getAllResourcesInResourceGroup(subscriptionId: number,  resourceGroupId: string) {
		return this.get<AzureResourceModel[]>(`SubscriptionRequests/${subscriptionId}/Resources?resourceGroupId=${resourceGroupId}`);
	}

	async getAllResourcesWithIdentities(subscriptionId: number,  resourceGroupId: string) {
		return this.get<AzureResourceModel[]>(`SubscriptionRequests/${subscriptionId}/ResourcesWithIdentities?resourceGroupId=${resourceGroupId}`);
	}

	async getPolicyExemptionRequestsBySubsId(id: number) {
		return this.get<PolicyExemptionRequest[]>(`SubscriptionRequests/${id}/PolicyExemptions`);
	}

	async getSubscriptionPolicyAssignmentsById(id: number) {
		return this.get<PolicyAssignmentResponse[]>(`SubscriptionRequests/${id}/policiesDefinitions`);
	}

	async getChangeHistory(id: number) {
		return this.get<ChangeHistory[]>(`SubscriptionRequests/${id}/history`);
	}

	async getAddressSpaces(id: number) {
		return this.get<VirtualNetwork[]>(`SubscriptionRequests/${id}/virtualNetworks`);
	}

	async getAddrSpaces(id: string, tenant: string) {
		return this.get<VirtualNetwork[]>(`SubscriptionRequests/${id}/vNets?tenantName=${tenant}`);
	}

	async grantAdminConsent(subscriptionId: number, principalId: string, model: GrantConsentIdentity){
		return this.post<GrantResponse>(`SubscriptionRequests/${subscriptionId}/permissionsAndConsent/${principalId}`, model);
	}

	async getOrgAdminSubscriptions(){
		return this.get<SubscriptionRequest>('SubscriptionRequests/OrgAdminSubscriptions/me');
	}
}
